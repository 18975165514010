import { Maybe } from "util/maybe";
import { SortDirection, TableSort } from "util/sort";
import { ReactChildrenArray } from "util/react-children-array";
import { MvFilters } from "data/reducers/management-views";
import { MvColumnKey } from "memsql/mv-column-info";
import { State, DispatchFunction } from "data";
import { Router, State as RouteState } from "router5";
import { MvNodesSelection } from "data/selectors/management-views";
import { MvContext } from "view/monitor/mv-table";
import { FilterProps } from "view/monitor/mv-header";
import { ColumnId } from "view/components/super-table";

import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRoute } from "react-router5";
import _ from "lodash";

import { mvChangeFilters, mvSortNodes } from "data/actions/management-views";
import {
    mvNodesSelector,
    mvFilteredQueryTextSelector,
    selectMvContext,
} from "data/selectors/management-views";

import { selectRoute } from "data/selectors/routes";

import CenteringWrapper from "view/components/centering-wrapper";
import MvHeader from "view/monitor/mv-header";
import MvFeatureCard from "view/monitor/mv-feature-card";
import MvTable from "view/monitor/mv-table";
import ExtLink from "view/components/external-link";
import GeneralError from "view/common/general-error";
import { MenuItem } from "view/common/menu";
import MVRecord from "view/monitor/mv-record";

import { nextTableSort } from "util/sort";
import * as analytics from "util/segment";

import { NODE_COLUMNS } from "memsql/mv-column-info";

import "./page-nodes.scss";

type StateProps = {
    selection: MvNodesSelection;
    filters: MvFilters;
    sort: TableSort;
    context: MvContext;
    selectedStatsColumns: Array<MvColumnKey>;
    route: RouteState;
    queryText: Maybe<string>;
    error: Maybe<string>;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
    router: Router;
};

class MVNodesPage extends React.Component<Props> {
    componentWillMount() {
        this.updateFilters(this.props, true);
    }

    componentWillReceiveProps(nextProps: Props) {
        this.updateFilters(nextProps, false);
    }

    renderActions = (nodeId: string): ReactChildrenArray<typeof MenuItem> => {
        const {
            router,
            route: {
                params: { clusterId },
            },
        } = this.props;

        const nav = () => {
            router.navigate("cluster.activities", {
                clusterId,
                nodeId,
            });

            analytics.track("inspect-queries", {
                category: "management-views",
            });
        };
        return [
            <MenuItem key={0} action={nav}>
                View Running Queries
            </MenuItem>,
        ];
    };

    updateFilters = (props: Props, force: boolean) => {
        const activityName = props.route.params.activityName || undefined;
        const { activityName: activityNameFilter } = props.filters;

        // if the url params don't align with the current filters, then lets
        // update the current filters.
        if (force || activityName !== activityNameFilter) {
            props.dispatch(mvChangeFilters({ activityName }));
        }
    };

    handleSort = (columnId: ColumnId, sortDir: Maybe<SortDirection>) => {
        this.props.dispatch(
            mvSortNodes({
                sort: nextTableSort(columnId, sortDir),
                isUserCaused: true,
            })
        );
    };

    handleDisableActivityNameFilter = () => {
        const { router, route } = this.props;

        router.navigate(route.name, {
            ..._.omit(route.params, "activityName"),
        });

        analytics.track("disable-nodes-filter", {
            category: "management-views",
        });
    };

    renderPage = (inner: React.ReactNode) => {
        return (
            <div className="monitor-page-nodes">
                <MVRecord />
                {inner}
            </div>
        );
    };

    render() {
        const {
            selection,
            filters: { activityName: activityNameFilter },
            context,
            sort,
            selectedStatsColumns,
            queryText,
            error,
            dispatch,
        } = this.props;

        if (error) {
            return <GeneralError error={error} />;
        }

        const headerFilters: Array<FilterProps> = [];

        if (_.isEmpty(selection.nodes)) {
            return this.renderPage(
                <>
                    <MvHeader filters={headerFilters} />
                    <CenteringWrapper>
                        <MvFeatureCard
                            title="Profile Node Resource Usage"
                            iconName="profile-node"
                        >
                            To start profiling the resource usage of this
                            cluster's nodes, click the button below or in the
                            upper right corner.{" "}
                            <ExtLink
                                name="studio-resource-usage"
                                category="resource-usage"
                            >
                                Learn more.
                            </ExtLink>
                        </MvFeatureCard>
                    </CenteringWrapper>
                </>
            );
        }

        if (activityNameFilter) {
            headerFilters.push({
                id: "activity-filter",
                className: queryText ? "query-text" : undefined,
                description:
                    queryText ||
                    `Resource usage by Node for ${activityNameFilter}`,
                onDisable: this.handleDisableActivityNameFilter,
            });
        }

        return this.renderPage(
            <>
                <MvHeader filters={headerFilters} />
                <MvTable<"nodeId">
                    dispatch={dispatch}
                    rowIdColumn="nodeId"
                    onSort={this.handleSort}
                    sort={sort}
                    context={context}
                    columns={NODE_COLUMNS}
                    selectedStatsColumns={selectedStatsColumns}
                    rows={selection.nodes}
                    renderActions={this.renderActions}
                />
            </>
        );
    }
}

export default compose(
    withRoute,
    connect(
        (s: State): StateProps => ({
            selection: mvNodesSelector(s),
            filters: s.managementViews.filters,
            queryText: mvFilteredQueryTextSelector(s),
            sort: s.managementViews.nodesSort,
            context: selectMvContext(s),
            selectedStatsColumns: s.managementViews.selectedStatsColumns,
            route: selectRoute(s),
            error: s.managementViews.error,
        })
    )
)(MVNodesPage);
