import { Cluster } from "data/models";
import { ClustersAction, DeleteClusterAction } from "data/actions";
import {
    LSM,
    getInitialState,
    reduce as reduceLSM,
} from "util/loading-state-machine";

type Actions = ClustersAction | DeleteClusterAction;

export type ClustersState = {
    clusters: LSM<Array<Cluster>, string>;
    allowClusterMutations: boolean;
    managedService: boolean;
};

import _ from "lodash";

const initialState: ClustersState = {
    clusters: getInitialState(),
    allowClusterMutations: window.ALLOW_CLUSTER_MUTATIONS,
    managedService: window.MANAGED_SERVICE,
};

export default (state: ClustersState = initialState, action: Actions) => {
    switch (action.type) {
        case "CLUSTERS": {
            state = { ...state, clusters: reduceLSM(state.clusters, action) };

            break;
        }

        case "CLUSTERS_DELETE": {
            if (state.clusters.state !== "success") {
                throw new Error(
                    "Did not expect the clusters state machine to not be in the success state when deleting a cluster."
                );
            }

            if (!action.error) {
                const clusterId = action.payload;
                const newClusters = _.reject(
                    state.clusters.payload,
                    cluster => cluster.id === clusterId
                );

                state = {
                    ...state,
                    clusters: { ...state.clusters, payload: newClusters },
                };
            }

            break;
        }
    }

    return state;
};
