import { ResultsSelection } from "data/selectors/page-editor";
import {
    QueryEditorRowsResultsSuccess,
    QueryEditorRowsResults,
} from "data/reducers/query-editor";

import * as React from "react";

import { PER_QUERY_ROW_LIMIT } from "data/reducers/query-editor";

import { CustomScrollbar } from "view/components/custom-scrollbar";
import { Button } from "view/common/button";
import Tip from "view/components/tip";

import NumberFormatter from "util/number-formatter";
import { plural } from "util/string";

import "./results-table-header.scss";

type Props = {
    results: ResultsSelection;

    canSaveAsCSV: boolean;
    onClickSaveAsCSV: () => void;

    onClickError: () => void;
};

// This component renders the header of the Query Results table.
export default class ResultsTableHeader extends React.Component<Props> {
    getTimeInfo = (results: ResultsSelection) => {
        const {
            metrics,
        } = (results as QueryEditorRowsResults) as QueryEditorRowsResultsSuccess;

        if (metrics) {
            if (metrics.endTimeUnix) {
                const duration = NumberFormatter.formatDuration(
                    metrics.endTimeUnix - metrics.startTimeUnix
                );

                // The UI needs to display a ", " after the timing information
                // if it is being shown next to the paging info.
                const comma = results.type === "rows" ? ", " : undefined;

                return (
                    <div className="query-time">
                        {duration}
                        {comma}
                    </div>
                );
            }
        }
    };

    getRowCountInfo = (results: ResultsSelection) => {
        if (results.type === "rows") {
            const { totalRows } = results;

            return (
                <Tip
                    className="query-row-count"
                    direction="sw"
                    tooltip={`Results are capped to ${PER_QUERY_ROW_LIMIT} rows for performance reasons.`}
                    disabled={totalRows < PER_QUERY_ROW_LIMIT}
                >
                    Showing {totalRows} {plural("row", totalRows)}
                </Tip>
            );
        }
    };

    // This renders the query information in the header. There are 2 pieces of
    // information we would like to show our users about the current state of
    // the Query Editor:
    //
    // 1. Time Information (How long it took to complete, if it has already completed)
    // 2. Number of rows displayed, possibly with a tooltip about the row limit
    //
    // We only show paging information if the result.type is "rows".
    renderQueryInfo = (results: ResultsSelection) => {
        const queryTime = this.getTimeInfo(results);
        const queryRowCount = this.getRowCountInfo(results);

        return (
            <>
                {queryTime}
                {queryRowCount}
            </>
        );
    };

    renderInner() {
        const { results, canSaveAsCSV, onClickSaveAsCSV } = this.props;

        return (
            <div className="results-table-header">
                <Button onClick={onClickSaveAsCSV} disabled={!canSaveAsCSV}>
                    Save as CSV
                </Button>

                <div className="right">{this.renderQueryInfo(results)}</div>
            </div>
        );
    }

    render() {
        return (
            <div className="results-table-header-outer">
                <CustomScrollbar autoHide>{this.renderInner()}</CustomScrollbar>
            </div>
        );
    }
}
