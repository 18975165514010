import { MysqlError } from "mysqljs";
import { HandlerContext } from "worker/api";
import { EventsAction } from "data/actions";
import { EventSeverity, MemsqlEvent } from "data/models";

import { makeActionCreator } from "worker/api/helpers";

import { Observable } from "rxjs";

import { differenceInSeconds } from "date-fns";
import { select } from "util/query";
import { logError } from "util/logging";

// We default this limit to 1028 which is the maximum size of the events'
// circular buffer in MemSQL. We are just being careful in case that
// size ever increases.
const SQL_EVENTS_QUERY = (limit: number = 1028) => `
    SELECT
        EVENT_TIME AS eventTime,
        SEVERITY AS eventSeverity,
        EVENT_TYPE AS eventType,
        DETAILS AS eventDetails
    FROM
        INFORMATION_SCHEMA.LMV_EVENTS
    ORDER BY
        EVENT_TIME DESC
    LIMIT
        ${limit}
`;

type SQLEventsRow = {
    eventTime: Date;
    eventSeverity: string;
    eventType: string;
    eventDetails: string; // JSON
};

const getEventSeverity = (eventSeverity: string): EventSeverity => {
    switch (eventSeverity) {
        case "NOTICE":
        case "WARNING":
        case "ERROR":
            return eventSeverity;

        default:
            return "UNKNOWN";
    }
};

const createEventsAction = (
    rawEvents: Array<SQLEventsRow>,
    startDate: Date
): EventsAction => {
    let events: Array<MemsqlEvent> = [];

    for (let i = 0; i < rawEvents.length; i++) {
        const rawEvent = rawEvents[i];

        let eventDetails;
        try {
            eventDetails = JSON.parse(rawEvent.eventDetails);
        } catch (e) {
            return {
                type: "EVENTS",
                error: true,
                payload: {
                    message: e.message,
                },
            };
        }

        events.push({
            eventTime: rawEvent.eventTime,
            eventSeverity: getEventSeverity(rawEvent.eventSeverity),
            eventType: rawEvent.eventType,
            eventDetails,
        });
    }

    return {
        type: "EVENTS",
        payload: {
            loading: false,
            data: {
                events,
                deltaTimeS: differenceInSeconds(new Date(), startDate),
            },
        },
        error: false,
    };
};

export const queryEvents = makeActionCreator({
    name: "queryEvents",

    handle: (
        ctx: HandlerContext,
        { limit }: { limit?: number }
    ): Observable<EventsAction> => {
        const $loading = Observable.of<EventsAction>({
            type: "EVENTS",
            error: false,
            payload: { loading: true },
        });

        const startDate = new Date();

        const $compute = Observable.fromPromise(
            ctx.manager
                .getPooledConnection()
                .then(conn =>
                    select<SQLEventsRow>(conn, SQL_EVENTS_QUERY(limit))
                        .then(events => createEventsAction(events, startDate))
                        .finally(() => conn.release())
                )
                .catch(
                    (err: Error | MysqlError): EventsAction => {
                        logError(err);

                        return {
                            type: "EVENTS",
                            error: true,
                            payload: {
                                message: err.message,
                            },
                        };
                    }
                )
        );

        return Observable.merge($loading, $compute);
    },
});
