import _ from "lodash";
import BigNumber from "vendor/bignumber.js/bignumber";

import { BigNumberUtils } from "./bignumber";

export default class Stat {
    _values: Array<BigNumber>;

    constructor() {
        this._values = [];
    }

    static from(value: BigNumber | Stat): Stat {
        if (value instanceof Stat) {
            return value;
        }
        return new Stat().append(value);
    }

    append(value: BigNumber): Stat {
        this._values.push(value);
        return this;
    }

    max(): BigNumber {
        if (this._values.length === 0) {
            return new BigNumber(0);
        }
        return BigNumber.max(...this._values);
    }

    min(): BigNumber {
        if (this._values.length === 0) {
            return new BigNumber(0);
        }
        return BigNumber.min(...this._values);
    }

    sum(): BigNumber {
        if (this._values.length === 0) {
            return new BigNumber(0);
        }
        return BigNumber.sum(...this._values);
    }

    mean(): BigNumber {
        if (this._values.length === 0) {
            return new BigNumber(0);
        }

        return BigNumberUtils.mean(...this._values);
    }
}
