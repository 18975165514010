import { Maybe } from "util/maybe";

import { Action } from "data/actions";
import _ from "lodash";

export type Loading<T, M = void> = M extends void
    ? { loading: true } | { loading: false; data: T }
    : { loading: true; meta: M } | { loading: false; data: T };

type InitialState = {
    state: "initial";
    loading: boolean;
};

type SuccessState<P> = {
    state: "success";
    payload: P;
    lastUpdate: Date;
    loading: boolean;
};

type ErrorState<E> = {
    state: "error";
    error: E;
    loading: boolean;
};

export type LSM<P, E> = InitialState | SuccessState<P> | ErrorState<E>;

export function reduce<P, E, M>(
    state: LSM<P, E>,
    action: Action<string, Loading<P>, E, M>,
    transformer: (payload: P) => P = _.identity
): LSM<P, E> {
    if (action.error) {
        state = {
            state: "error",
            error: action.payload,
            loading: false,
        };
    } else if (action.payload.loading) {
        state = {
            ...state,
            loading: true,
        };
    } else {
        state = {
            state: "success",
            payload: transformer(action.payload.data),
            lastUpdate: new Date(),
            loading: false,
        };
    }

    return state;
}

export function updatePayload<P, E>(
    lsm: LSM<P, E>,
    update: (payload: P) => P
): LSM<P, E> {
    if (lsm.state === "success") {
        return {
            ...lsm,
            payload: update(lsm.payload),
        };
    }

    return lsm;
}

export function getInitialState(): InitialState {
    return { state: "initial", loading: false };
}

export const selectLoadingOrInitial = (s: LSM<unknown, unknown>): boolean =>
    s.loading || s.state === "initial";

export const selectLastUpdate = (s: LSM<unknown, unknown>): Maybe<Date> =>
    s.state === "success" ? s.lastUpdate : undefined;

export const selectIsInitial = (s: LSM<unknown, unknown>): boolean =>
    s.state === "initial";

export const selectIsSuccess = (s: LSM<unknown, unknown>): boolean =>
    s.state === "success";

export const selectIsError = (s: LSM<unknown, unknown>): boolean =>
    s.state === "error";

export function selectError<E>(s: LSM<unknown, E>): Maybe<E> {
    return s.state === "error" ? s.error : undefined;
}

export function selectPayload<P>(s: LSM<P, unknown>): Maybe<P> {
    return s.state === "success" ? s.payload : undefined;
}

// FIXME PLAT-2393
type NonVoid = string | number | boolean | {} | Array<Object> | RegExp;

type SuccessOutput<P extends NonVoid> = {
    payload: P;
    error: undefined;
    loading: false;
    lastUpdated: Date;
};

type LoadingOutput = {
    error: void;
    payload: undefined;
    lastUpdated: undefined;
    loading: true;
};

type ErrorOutput<E extends NonVoid> = {
    error: E;
    lastUpdated: undefined;
    payload: undefined;
    loading: false;
};

export type Output<P extends NonVoid, E extends NonVoid> =
    | SuccessOutput<P>
    | LoadingOutput
    | ErrorOutput<E>;

export function select<P extends NonVoid, E extends NonVoid>(
    s: LSM<P, E>
): Output<P, E> {
    if (s.state === "success") {
        const out: SuccessOutput<P> = {
            payload: s.payload,
            lastUpdated: s.lastUpdate,
            error: undefined,
            loading: false,
        };

        return out;
    } else if (s.loading || s.state === "initial") {
        const out: LoadingOutput = {
            payload: undefined,
            lastUpdated: undefined,
            loading: true,
            error: undefined,
        };

        return out;
    } else {
        const out: ErrorOutput<E> = {
            payload: undefined,
            lastUpdated: undefined,
            loading: false,
            error: s.error,
        };

        return out;
    }
}
