import { Maybe } from "util/maybe";

import * as React from "react";

import { ClusterProfile, formatClusterProfile } from "data/models";
import Icon from "view/components/icon";
import Tip from "view/components/tip";

import classnames from "classnames";

import { COLORS } from "util/colors";

import "./profile-icon.scss";

type Props = {
    profile: Maybe<ClusterProfile>;
    className?: string;
    description?: string;
    size?: "sm" | "xs" | "2x";
};

const CLUSTER_PROFILE_COLORS: { [profile in ClusterProfile]: string } = {
    PRODUCTION: COLORS["color-indigo-600"],
    STAGING: COLORS["color-yellow-800"],
    DEVELOPMENT: COLORS["color-purple-800"],
};

const CLUSTER_PROFILE_ICONS: { [profile in ClusterProfile]: string } = {
    PRODUCTION: "bolt",
    STAGING: "cogs",
    DEVELOPMENT: "flask",
};

export default class ClusterProfileIcon extends React.Component<Props> {
    render() {
        const { profile, description, className, size } = this.props;

        const color =
            (profile && CLUSTER_PROFILE_COLORS[profile]) ||
            COLORS["color-neutral-700"];
        const icon = (profile && CLUSTER_PROFILE_ICONS[profile]) || "question";

        const classes = classnames("cluster-profile-icon", size, className);

        let tooltipText: React.ReactNode;
        if (description) {
            tooltipText = (
                <div className="cluster-profile-tooltip-text">
                    <div className="cluster-profile-tooltip-title">
                        {formatClusterProfile(profile)}
                    </div>
                    <div className="cluster-profile-tooltip-description">
                        {description}
                    </div>
                </div>
            );
        } else {
            tooltipText = formatClusterProfile(profile);
        }

        return (
            <Tip
                direction="e"
                tooltip={tooltipText}
                className={classes}
                style={{ backgroundColor: color }}
            >
                <Icon className="icon" size={size} icon={icon} fixedWidth />
            </Tip>
        );
    }
}
