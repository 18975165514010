import { Maybe } from "util/maybe";
import { SimpleAction } from "./types";
import { DispatchFunction } from "data";

import { changeBottomPanelTab } from "data/actions/bottom-panel";
import { initQueryExecutor } from "worker/api/connection";
import { staticQueryGroup } from "worker/api/connection";
import { consoleQueryAction } from "data/actions/connection";

import { CONNECTION_ID } from "data/reducers/console";

export type ConnectConsoleAction = SimpleAction<"CONNECT_CONSOLE", {}>;

export const connectConsole = (): ConnectConsoleAction => {
    return {
        type: "CONNECT_CONSOLE",
        error: false,
        payload: {},
    };
};

export type ConsoleEditQueryAction = SimpleAction<
    "CONSOLE_EDIT_QUERY",
    { query: string }
>;

export const consoleEditQuery = (payload: {
    query: string;
}): ConsoleEditQueryAction => ({
    type: "CONSOLE_EDIT_QUERY",
    error: false,
    payload,
});

export type ConsoleShiftQueryAction = SimpleAction<
    "CONSOLE_SHIFT_QUERY",
    { direction: "UP" | "DOWN" }
>;

export const consoleShiftQuery = (payload: {
    direction: "UP" | "DOWN";
}): ConsoleShiftQueryAction => ({
    type: "CONSOLE_SHIFT_QUERY",
    error: false,
    payload,
});

// Opens up the bottom panel's console and runs a query in it.
// You have to pass in a string rather than a QueryRepr because we need to put
// the query into the console history.
export const consoleRunQuery = ({ query }: { query: string }) => {
    return (dispatch: DispatchFunction) => {
        dispatch(changeBottomPanelTab({ tab: "Console" }));

        dispatch(consoleQueryAction({ query, automated: true }));

        dispatch(
            staticQueryGroup({
                id: CONNECTION_ID,
                queryGroup: {
                    queries: [
                        {
                            text: query,
                            values: [],
                        },
                    ],

                    // These options are duplicated in the TabConsole component
                    onError: "SKIP",
                    limitAllButLast: false,
                },
            })
        );
    };
};

export const initConsoleQueryExecutor = (database: Maybe<string>) => {
    return initQueryExecutor({
        id: CONNECTION_ID,
        loggerType: "errorsOnly",
        database,
    });
};
