// The LoadingError is a utility type that is used by the schema models to
// represent 3 different states:
// * Loading (LELoading)
// * Success (LESuccess)
// * Error (LEError)
//
// It is a lightweight "loading state machine" that is useful in partial models
// such as any model that depends on columnar data.
export type LoadingError<T> = LESuccess<T> | LELoading<T> | LEError<T>;

export class LESuccess<T> {
    readonly value: T;

    constructor(value: T) {
        this.value = value;
    }

    isSuccess(this: LoadingError<T>): this is LESuccess<T> {
        return true;
    }

    isLoading(this: LoadingError<T>): this is LELoading<T> {
        return false;
    }

    isError(this: LoadingError<T>): this is LEError<T> {
        return false;
    }

    toJSON() {
        return {
            __type__: "LESuccess",
            __val__: this.value,
        };
    }
}

export class LELoading<T> {
    readonly loading = true;

    isSuccess(this: LoadingError<T>): this is LESuccess<T> {
        return false;
    }

    isLoading(this: LoadingError<T>): this is LELoading<T> {
        return true;
    }

    isError(this: LoadingError<T>): this is LEError<T> {
        return false;
    }

    toJSON() {
        return {
            __type__: "LELoading",
            __val__: undefined,
        };
    }
}

export class LEError<T> {
    readonly error = true;

    isSuccess(this: LoadingError<T>): this is LESuccess<T> {
        return false;
    }

    isLoading(this: LoadingError<T>): this is LELoading<T> {
        return false;
    }

    isError(this: LoadingError<T>): this is LEError<T> {
        return true;
    }

    toJSON() {
        return {
            __type__: "LEError",
            __val__: undefined,
        };
    }
}

export function updateLoadingError<A, B>(
    v: LoadingError<A>,
    f: (a: A) => LoadingError<B>
): LoadingError<B> {
    if (v.isError()) {
        return new LEError();
    } else if (v.isLoading()) {
        return new LELoading();
    } else {
        return f(v.value);
    }
}
