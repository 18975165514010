// This file's type definitions live in inner-join.d.ts, as we need to separate
// the type definition from the type implementation.

import _ from "lodash";

// All the arrays have to be sorted the same way. If not, the behavior
// of this function is *undefined*.
//
// A good visualization of this algorithm can be found here:
// http://sqlity.net/wp-content/uploads/2012/12/merge-join-algorithm.gif.
export default function innerJoin(comparator, merge, ...arrs) {
    const output = [];

    let iterators = _.fill(Array(arrs.length), 0);

    // We should only continue looping if none of the pointers is
    // at the end of their respective array. This is because we can
    // only match if we have a value from each array.
    const canContinue = () =>
        _.every(arrs, (arr, index) => iterators[index] < arr.length);

    // Gets all the current values by grabbing the value we are
    // currently pointing to for each array.
    const getAllValues = () =>
        _.map(arrs, (arr, index) => arr[iterators[index]]);

    // Tests whether all current values match by comparing all of them
    // against the first element.
    const allValuesEqual = () =>
        _.every(
            arrs,
            (arr, index) =>
                comparator(arrs[0][iterators[0]], arr[iterators[index]]) === 0
        );

    while (canContinue()) {
        const values = getAllValues();
        const comparison = allValuesEqual();

        if (comparison) {
            output.push(merge(...values));
            iterators = _.map(iterators, it => ++it);
        } else {
            let minimumIndex = 0;

            let i;
            for (i = 1; i < arrs.length; i++) {
                if (
                    comparator(
                        arrs[i][iterators[i]],
                        arrs[minimumIndex][iterators[minimumIndex]]
                    ) === -1
                ) {
                    minimumIndex = i;
                }
            }

            iterators[minimumIndex]++;
        }
    }

    return output;
}
