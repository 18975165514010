import * as React from "react";

type ResizableContainerProps = {
    height?: number;
    width?: number;
    className?: string;
    children: React.ReactNode;
};

export class ResizableContainer extends React.PureComponent<
    ResizableContainerProps
> {
    render() {
        const { height, width, className, children } = this.props;

        return (
            <div className={className} style={{ height, width }}>
                {children}
            </div>
        );
    }
}
