import { Maybe } from "util/maybe";
import { SortDirection, TableSort } from "util/sort";
import { ColumnId } from "view/components/super-table";

import { State, DispatchFunction } from "data";
import { InjectedRoute } from "react-router5";
import { DerivedNode, PartitionInstance, NodeRatioMetric } from "data/models";

import * as React from "react";
import { connect } from "react-redux";
import { withRoute } from "react-router5";

import Card from "view/components/card";
import GeneralTable from "view/components/general-table";
import InternalLink from "view/components/internal-link";
import { TableHeader } from "view/components/table-header";
import { renderLoadingError } from "view/components/render-loading-error";
import { RatioMetricCell } from "view/common/ratio-metric-cell";
import {
    TopContent,
    TopContentRow,
    TopContentHeader,
} from "view/components/top-content";
import IconTip from "view/components/icon-tip";

import { sortNodePartitionInstances } from "data/actions";
import {
    selectCurrentNode,
    selectNodePartitionInstancesSort,
    selectSortedNodePartitionInstances,
    selectIsPhysicalMonitoringEnabled,
} from "data/selectors";

import {
    humanizeNodeRole,
    formatNodeAddress,
    getNodeMemoryUsage,
    getNodeDiskUsage,
} from "data/models";
import { formatNodePartitionInstanceStatus } from "view/common/models/topology";
import { LONG_EM_DASH } from "util/symbols";
import { logError } from "util/logging";
import { nextTableSort } from "util/sort";
import NumberFormatter from "util/number-formatter";
import {
    NODE_PARTITION_INSTANCE_COLUMNS,
    NODE_DISK_USAGE_TIP,
    NODE_MEMORY_USAGE_TIP,
    NODE_CPU_USAGE_TIP,
} from "view/topology/columns-info";

import "./node.scss";

type StateProps = {
    node: Maybe<DerivedNode>;
    sort: TableSort;
    partitionInstances: Maybe<Array<PartitionInstance>>;
    physicalMonitoringEnabled: Maybe<boolean>;
    disableHosts: boolean;
};

type Props = StateProps &
    InjectedRoute & {
        dispatch: DispatchFunction;
    };

class NodePage extends React.Component<Props> {
    handleSort = (columnId: ColumnId, sortDir: Maybe<SortDirection>) => {
        this.props.dispatch(
            sortNodePartitionInstances(nextTableSort(columnId, sortDir))
        );
    };

    renderNodeCpuUsage = (cpuUsage: number) => {
        return (
            <RatioMetricCell
                barClassName="inline-cell-bar"
                labelPosition="right"
                value={cpuUsage}
            />
        );
    };

    renderNodeMemoryUsage = ({ ratio, used, total }: NodeRatioMetric) => {
        const bottomLabel = `${NumberFormatter.formatBytes(
            used
        )}/${NumberFormatter.formatBytes(total)}`;

        return (
            <RatioMetricCell
                barClassName="inline-cell-bar"
                labelPosition="right"
                value={ratio}
                bottomLabel={bottomLabel}
            />
        );
    };

    renderPhysicalMetrics = (node: DerivedNode) => {
        return (
            <>
                <TopContent
                    title={
                        <>
                            CPU Usage
                            <IconTip
                                iconProps={{
                                    leftMargin: true,
                                }}
                            >
                                {NODE_CPU_USAGE_TIP}
                            </IconTip>
                        </>
                    }
                    className="top-card"
                >
                    {renderLoadingError(
                        node.liveMonitoring.totalCpuUsagePercent,
                        this.renderNodeCpuUsage,
                        { cell: true }
                    )}
                </TopContent>

                <TopContent
                    title={
                        <>
                            Memory Usage
                            <IconTip
                                iconProps={{
                                    leftMargin: true,
                                }}
                            >
                                {NODE_MEMORY_USAGE_TIP}
                            </IconTip>
                        </>
                    }
                    className="top-card"
                >
                    {renderLoadingError(
                        getNodeMemoryUsage(node),
                        this.renderNodeMemoryUsage,
                        { cell: true }
                    )}
                </TopContent>

                <TopContent
                    title={
                        <>
                            Disk Usage
                            <IconTip
                                iconProps={{
                                    leftMargin: true,
                                }}
                            >
                                {NODE_DISK_USAGE_TIP}
                            </IconTip>
                        </>
                    }
                    className="top-card"
                >
                    {renderLoadingError(
                        getNodeDiskUsage(node),
                        this.renderNodeMemoryUsage,
                        { cell: true }
                    )}
                </TopContent>
            </>
        );
    };

    renderTopCard(node: DerivedNode) {
        const { physicalMonitoringEnabled, disableHosts } = this.props;

        let availabilityGroupCard;
        let availabilityPairCard;
        let availabilityPairContent;

        if (node.role === "LEAF") {
            availabilityGroupCard = (
                <TopContent title="Availability Group" className="top-card">
                    {node.availabilityGroup.toString()}
                </TopContent>
            );

            if (node.pairHost && node.pairPort) {
                availabilityPairContent = (
                    <InternalLink
                        routeInfo={{
                            name: "cluster.nodes.node",
                            params: {
                                nodeAddress: formatNodeAddress({
                                    host: node.pairHost,
                                    port: node.pairPort,
                                }),
                            },
                        }}
                        category="schema-explorer"
                        clusterLink
                        onClick={
                            /* stop propagation to the cell click event */ e =>
                                e.stopPropagation()
                        }
                    >
                        {node.pairHost}:{node.pairPort.toString()}
                    </InternalLink>
                );
            } else {
                availabilityPairContent = LONG_EM_DASH;
            }

            availabilityPairCard = (
                <TopContent title="Availability Pair" className="top-card">
                    {availabilityPairContent}
                </TopContent>
            );
        }

        let physicalMonitoringMetrics;
        if (physicalMonitoringEnabled) {
            physicalMonitoringMetrics = (
                <>
                    <TopContentHeader>MemSQL Usage</TopContentHeader>

                    <TopContentRow>
                        {this.renderPhysicalMetrics(node)}
                    </TopContentRow>
                </>
            );
        }

        let hostAddress;
        if (!disableHosts) {
            hostAddress = (
                <TopContent title="Host Address" className="top-card">
                    <InternalLink
                        routeInfo={{
                            name: "cluster.hosts.host",
                            params: {
                                hostId: node.host,
                            },
                        }}
                        category="schema-explorer"
                        clusterLink
                        onClick={
                            /* stop propagation to the cell click event */ e =>
                                e.stopPropagation()
                        }
                    >
                        {node.host}
                    </InternalLink>
                </TopContent>
            );
        }

        return (
            <Card className="node-info-card">
                <TopContentHeader>Node Info</TopContentHeader>

                <TopContentRow>
                    <TopContent title="Role" className="top-card">
                        {humanizeNodeRole(node.role)}
                    </TopContent>

                    <TopContent title="Node Address" className="top-card">
                        {node.host}:{node.port.toString()}
                    </TopContent>

                    {hostAddress}

                    {availabilityGroupCard}
                    {availabilityPairCard}

                    <TopContent
                        title="Node Partition Instance Status"
                        className="top-card"
                    >
                        {formatNodePartitionInstanceStatus(node)}
                    </TopContent>
                </TopContentRow>

                {physicalMonitoringMetrics}
            </Card>
        );
    }

    render() {
        const { node, sort, partitionInstances } = this.props;

        if (!node || !partitionInstances) {
            logError(
                new Error("NodePage is being rendered without valid data.")
            );
            return null;
        }

        let partitionInstancesNode;
        if (partitionInstances.length > 0) {
            partitionInstancesNode = (
                <GeneralTable
                    rows={partitionInstances}
                    onSort={this.handleSort}
                    columns={NODE_PARTITION_INSTANCE_COLUMNS}
                    sort={sort}
                />
            );
        } else {
            partitionInstancesNode = (
                <div className="partition-instances-empty-state">
                    There are no partition instances on this node.
                </div>
            );
        }

        return (
            <div className="node-page">
                {this.renderTopCard(node)}

                <TableHeader
                    title="Partition Instances"
                    count={partitionInstances.length}
                />

                {partitionInstancesNode}
            </div>
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        node: selectCurrentNode(s),
        sort: selectNodePartitionInstancesSort(s),
        partitionInstances: selectSortedNodePartitionInstances(s),
        physicalMonitoringEnabled: selectIsPhysicalMonitoringEnabled(s),
        disableHosts: s.hosts.disableHosts,
    })
)(withRoute(NodePage));
