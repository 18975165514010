const WEB_API_URL = "https://www.memsql.com/api/v1";

const apiPath = function(parts: Array<string>) {
    return `${parts.map(encodeURIComponent).join("/")}/`; // bifrost api paths have trailing slashes
};

const makeWebApiRequest = function(
    pathParts: Array<string>,
    data?: Object,
    method?: string
) {
    if (!method) {
        method = data ? "POST" : "GET";
    }

    return fetch(`${WEB_API_URL}/${apiPath(pathParts)}`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        method,
        body: JSON.stringify(data),
    }).then(
        res => res.json(),
        res => {
            throw new Error(res);
        }
    );
};

export const makeWebPing = (): Promise<{ success: boolean }> => {
    return makeWebApiRequest(["ping"]);
};
