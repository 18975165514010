import { ReactChildrenArray } from "util/react-children-array";

import * as React from "react";
import _ from "lodash";
import classnames from "classnames";

import Icon from "view/components/icon";
import Tip from "view/components/tip";
import Loading from "view/components/loading";

import NumberFormatter from "util/number-formatter";

import "./tab.scss";

export const TAB_HEIGHT = 40; // in pixels; sync with CSS

export type TabsProps = {
    nested?: boolean;
    activeTab: number;
    onChange: (value: number) => void;

    // a New Tab (+) button will be displayed if at least one of the following
    // two props is provided
    onNewTab?: () => void;
    newTabDisabledMessage?: string;

    titles: Array<React.ReactElement<TabTitleProps>>;

    // You can pass in either exactly 1 tab, in which case it will be shown, or
    // the same number of tabs as titles, in which case the tab with index
    // activeTab will be shown.
    children: ReactChildrenArray<Tab>;

    rightBarContent?: React.ReactNode;
    tabBarClassName?: string;
    borderTop?: boolean;
};

export class Tabs extends React.Component<TabsProps> {
    render() {
        const {
            nested,
            titles,
            activeTab,
            onChange,
            onNewTab,
            newTabDisabledMessage,
            children,
            rightBarContent,
            tabBarClassName,
            borderTop,
        } = this.props;

        const tabCount = React.Children.count(children);

        let tab;
        if (tabCount === 1) {
            tab = React.Children.only(children);
        } else if (tabCount === titles.length) {
            tab = React.Children.toArray(children)[activeTab];
        } else {
            throw new Error(
                "Expected number of tab titles to equal 1 or number of tab panes"
            );
        }

        const tabBarClass = classnames("tab-bar", tabBarClassName, {
            light: !nested,
            "border-top": borderTop,
            nested,
        });

        const mappedTitles = titles.map((title, idx: number) => {
            const titleClasses = classnames("tab-title", {
                active: activeTab === idx,
            });

            return (
                <div
                    className={titleClasses}
                    key={idx}
                    role="button"
                    onClick={() => onChange(idx)}
                >
                    {React.cloneElement<TabTitleProps>(title, {
                        badgeProps: {
                            ...title.props.badgeProps,
                            active: activeTab === idx,
                        },
                    })}
                </div>
            );
        });

        let newTabButton;
        if (onNewTab || newTabDisabledMessage) {
            const newTabClasses = classnames("new-tab-button", {
                disabled: Boolean(newTabDisabledMessage),
            });
            const tooltipDisabled = !newTabDisabledMessage;

            let handleClick = _.noop;
            if (onNewTab && !newTabDisabledMessage) {
                handleClick = onNewTab;
            }

            newTabButton = (
                <Tip
                    tooltip={newTabDisabledMessage}
                    disabled={tooltipDisabled}
                    direction="sw"
                    className={newTabClasses}
                    role="button"
                    onClick={handleClick}
                >
                    <Icon icon="plus" />
                </Tip>
            );
        }

        return (
            <div className="components-tabs">
                <div className={tabBarClass}>
                    {mappedTitles}
                    <div className="rest">
                        {newTabButton}
                        <div className="right">{rightBarContent}</div>
                    </div>
                </div>
                {tab}
            </div>
        );
    }
}

export type TabProps = {
    children: React.ReactNode;
};

export class Tab extends React.Component<TabProps> {
    render() {
        return this.props.children;
    }
}

export type TabBadgeProps = {
    count: number;
    active?: boolean;
    className?: string;
    rightMargin?: boolean;
    leftMargin?: boolean;
};

export class TabBadge extends React.Component<TabBadgeProps> {
    render() {
        const {
            count,
            className,
            leftMargin,
            rightMargin,
            active,
        } = this.props;

        const classes = classnames(
            "components-tab-badge",
            { active },
            { "left-margin": leftMargin },
            { "right-margin": rightMargin },
            className
        );

        const displayCount = NumberFormatter.compactInteger(
            count,
            undefined,
            0
        );

        return <div className={classes}>{displayCount}</div>;
    }
}

export type TabTitleProps = {
    title: React.ReactNode;
    badgeProps: TabBadgeProps;
    fixedWidth?: boolean;
    loading?: boolean;

    // a close button will be displayed if at least one of the following two
    // props is provided
    onClose?: () => void;
    closeDisabledMessage?: string;
};

export class TabTitle extends React.Component<TabTitleProps> {
    static defaultProps = {
        badgeProps: {
            count: 0,
        },
    };

    handleClose = (event: React.MouseEvent<HTMLDivElement>) => {
        const { onClose, closeDisabledMessage } = this.props;

        if (closeDisabledMessage) {
            return;
        }

        // The close button is inside the tab. When the user clicks the close
        // button, we don't want the same click to select the tab, so we stop
        // propagation.
        event.stopPropagation();

        if (onClose) {
            onClose();
        }
    };

    render() {
        const {
            title,
            badgeProps,
            fixedWidth,
            loading,
            onClose,
            closeDisabledMessage,
        } = this.props;

        const classes = classnames("components-tab-title", {
            "fixed-width": fixedWidth,
        });

        let badge;
        if (badgeProps.count > 0) {
            badge = <TabBadge {...badgeProps} />;
        }

        let loadingNode;
        if (loading) {
            loadingNode = (
                <div className="loading">
                    <Loading size="small" />
                </div>
            );
        }

        let close;
        if (onClose || closeDisabledMessage) {
            const closeClasses = classnames("close-button", {
                disabled: Boolean(closeDisabledMessage),
            });
            const tooltipDisabled = !closeDisabledMessage;

            close = (
                <Tip
                    tooltip={closeDisabledMessage}
                    disabled={tooltipDisabled}
                    direction="sw"
                    className={closeClasses}
                    role="button"
                    onClick={this.handleClose}
                >
                    <Icon icon="times" fixedWidth />
                </Tip>
            );
        }

        return (
            <div className={classes}>
                {title}
                {badge}
                <div className="right">
                    {loadingNode}
                    {close}
                </div>
            </div>
        );
    }
}
