import { Maybe } from "util/maybe";
import BigNumber from "vendor/bignumber.js/bignumber";
import { State } from "data";
import { Version } from "util/version";
import { LEError, LESuccess, LELoading } from "util/loading-error";
import {
    Partition,
    DerivedPartition,
    PartitionInstance,
    Node,
    DatabaseStatus,
    SyncState,
    DatabaseName,
} from "data/models";

import _ from "lodash";
import { createSelector } from "reselect";

import { selectRoute } from "data/selectors/routes";

import {
    PARTITION_COLUMNS,
    PARTITION_INSTANCE_COLUMNS,
} from "memsql/schema-column-info";
import { getPartitionName, isPartitionImpacted } from "data/models";

import {
    selectPayload,
    selectError,
    selectIsError,
} from "util/loading-state-machine";
import { buildRankDictionary } from "util/rank-dictionary";
import { calculateRateS } from "util/rate";

const selectCurrentDatabaseName = (s: State) =>
    selectRoute(s).params.databaseName;

export const selectHaEnabled = (s: State): Maybe<boolean> => {
    const payload = selectPayload(s.clusterMetadata.clusterStatistics);

    if (payload) {
        return payload.haEnabled;
    }
};

export const selectClusterStatisticsError = (s: State): Maybe<string> => {
    return selectError(s.clusterMetadata.clusterStatistics);
};

// We duplicate this selector from selectors/topology to avoid circular
// dependency issues.
const selectNodes = (s: State): Maybe<Array<Node>> => {
    const payload = selectPayload(s.topology.topology);

    if (payload) {
        return payload.nodes;
    }
};

export const selectMemsqlVersion = (s: State): Maybe<Version> => {
    return selectPayload(s.clusterMetadata.memsqlVersion);
};

export const selectPartitions = (s: State) => {
    const partitionsPayload = selectPayload(s.clusterMetadata.partitions);

    if (partitionsPayload) {
        return partitionsPayload.partitions;
    }
};

export const selectPartitionInstances = (s: State) => {
    const partitionsPayload = selectPayload(s.clusterMetadata.partitions);

    if (partitionsPayload) {
        return partitionsPayload.partitionInstances;
    }
};

export type PartitionsSelection = Array<DerivedPartition>;

export const selectPartitionsSort = (s: State) =>
    s.clusterMetadata.partitionsSort;

export function getPartitionInstances(
    partition: Partition | DerivedPartition,
    allPartitionInstances: Array<PartitionInstance>
) {
    return allPartitionInstances.filter(
        partitionInstance =>
            (partition.kind === "data" &&
                (partitionInstance.databaseName === partition.databaseName &&
                    partitionInstance.ordinal &&
                    partitionInstance.ordinal.eq(partition.ordinal))) ||
            (partition.kind === "reference" &&
                (partitionInstance.databaseName === partition.databaseName &&
                    partitionInstance.ordinal === undefined))
    );
}

// In this object, we rank slave partition instance states so as to be able to
// compare their states from "best" to "worst".
const RANK_SLAVE_STATE = buildRankDictionary<string>([
    "replicating",
    "provisioning",
    "transition",
    "pending",
    "recovering",
    "online",
    "unrecoverable",
    "offline",
]);

// `getWorstSlave` returns the "worst" slave for an array of slave partition
// instances. The worst slave is defined as being the slave partition in the
// "worst" state out of the N "best" slaves. The reason we only care about the N
// "best" slaves is that extra slaves are only consuming extra resources and
// aren't very helpful.
//
// N = expected number of slaves for the partition. For reference partitions, N
// is the number of online nodes - 1. For data partitions, this is 1 if HA is
// enabled and 0 if HA is disabled.
//
// Note that we sort by partition instance state and then retrieve the
// [expectedNumSlaves - 1] element since we are converting to an index.
export function getWorstSlave(
    partitionInstances: Array<PartitionInstance>,
    expectedNumSlaves: number
): Maybe<PartitionInstance> {
    return _.orderBy<PartitionInstance>(
        partitionInstances,
        [pi => RANK_SLAVE_STATE[pi.state]],
        "asc"
    )[expectedNumSlaves - 1];
}

function getExpectedNumSlaves(
    partition: Partition,
    nodes: Array<Node>,
    haEnabled: boolean
) {
    if (partition.kind === "reference") {
        return _.filter(nodes, node => node.state === "online").length - 1;
    } else {
        return haEnabled ? 1 : 0;
    }
}

// This function derives the status of a partition based on the state of its
// partition instances. This function basically owns 4 different tables that map
// partition instance state to partition status and it chooses between the 4
// different tables depending on some factors
//
// Case #1: DR is enabled for this partition and HA is enabled for the cluster
// Case #2: DR is enabled for this partition and HA is disabled for the cluster
// Case #3: DR is disabled for this partition and HA is enabled for the cluster
// Case #4: DR is disabled for this partition and HA is disabled for the cluster
//
// The 4 tables are all here:
// https://docs.google.com/spreadsheets/d/1v8Csw_7bbRx5EZ3XygrYvwJt-QxZ9D1RMJNfpbBUtx0/edit?pli=1#gid=0
//
// The HA enabled tables depend on the state of the master partition instance
// and on the state of the "worst" slave partition. The HA disabled tables
// depend only on the state of the master partition instance.
export function computePartitionStatus(
    masterState: string,
    worstSlaveState: string,
    drReplica: boolean,
    haEnabled: boolean
): DerivedPartition["status"] {
    if (drReplica && haEnabled) {
        switch (masterState) {
            case "online":
                return "offline_recovering";

            case "replicating":
                switch (worstSlaveState) {
                    case "online":
                        return "degraded_recovering";

                    case "replicating":
                        return "online";

                    case "provisioning":
                    case "transition":
                    case "pending":
                    case "recovering":
                        return "degraded_recovering";

                    case "unrecoverable":
                    case "offline":
                    case "nonexistent":
                        return "degraded_unrecoverable";

                    default:
                        return "unknown";
                }

            case "provisioning":
                return "offline_recovering";

            case "transition":
                switch (worstSlaveState) {
                    case "online":
                        return "degraded_recovering";

                    case "replicating":
                        return "online";

                    case "provisioning":
                    case "transition":
                    case "pending":
                    case "recovering":
                        return "degraded_recovering";

                    case "unrecoverable":
                    case "offline":
                    case "nonexistent":
                        return "degraded_unrecoverable";

                    default:
                        return "unknown";
                }

            case "pending":
            case "recovering":
                return "offline_recovering";

            case "unrecoverable":
            case "offline":
            case "nonexistent":
                return "offline";

            default:
                return "unknown";
        }
    } else if (drReplica && !haEnabled) {
        switch (masterState) {
            case "online":
                return "offline_recovering";

            case "replicating":
                return "online";

            case "provisioning":
            case "transition":
            case "pending":
            case "recovering":
                return "offline_recovering";

            case "unrecoverable":
            case "offline":
            case "nonexistent":
                return "offline";

            default:
                return "unknown";
        }
    } else if (haEnabled) {
        switch (masterState) {
            case "online":
                switch (worstSlaveState) {
                    case "online":
                        return "degraded_recovering";

                    case "replicating":
                        return "online";

                    case "provisioning":
                    case "transition":
                    case "pending":
                    case "recovering":
                        return "degraded_recovering";

                    case "unrecoverable":
                    case "offline":
                    case "nonexistent":
                        return "degraded_unrecoverable";

                    default:
                        return "unknown";
                }

            case "replicating":
                return "offline_recovering";

            case "provisioning":
                return "offline";

            case "transition":
                switch (worstSlaveState) {
                    case "online":
                        return "degraded_recovering";

                    case "replicating":
                        return "online";

                    case "provisioning":
                    case "transition":
                    case "pending":
                    case "recovering":
                        return "degraded_recovering";

                    case "unrecoverable":
                    case "offline":
                    case "nonexistent":
                        return "degraded_unrecoverable";

                    default:
                        return "unknown";
                }

            case "pending":
            case "recovering":
                return "offline_recovering";

            case "unrecoverable":
            case "offline":
            case "nonexistent":
                return "offline";

            default:
                return "unknown";
        }
    } else {
        switch (masterState) {
            case "online":
                return "online";

            case "replicating":
                return "offline_recovering";

            case "provisioning":
                return "offline";

            case "transition":
            case "pending":
            case "recovering":
                return "offline_recovering";

            case "unrecoverable":
            case "offline":
            case "nonexistent":
                return "offline";

            default:
                return "unknown";
        }
    }
}

function derivePartitionStatus(
    partition: Partition,
    partitionInstances: Array<PartitionInstance>,
    expectedNumSlaves: number,
    haEnabled: boolean
): DerivedPartition["status"] {
    const masterPartition = _.find(
        partitionInstances,
        pi => pi.role === "master"
    );
    const slavePartitions = _.filter(
        partitionInstances,
        pi => pi.role === "slave"
    );

    const worstSlave = getWorstSlave(slavePartitions, expectedNumSlaves);
    const worstSlaveState = worstSlave ? worstSlave.state : "nonexistent";
    const masterState = masterPartition ? masterPartition.state : "nonexistent";

    return computePartitionStatus(
        masterState,
        worstSlaveState,
        partition.drReplica,
        haEnabled
    );
}

// `getSyncState` returns the current sync state (synchronous or asynchronouse)
// for a given partition, based on its partition instances states. If there is
// at least one slave partition instance with its sync state set to "sync", the
// partition's sync state is considered to be "sync". If there is at least one
// slave partition instance with its sync state set to "async", the partition's
// sync state is considered to be "async". Otherwise, we can't tell what the
// current sync state is and return `undefined`.
function getSyncState(
    partitionInstances: Array<PartitionInstance>
): Maybe<SyncState> {
    const slavePartitions = _.filter(
        partitionInstances,
        pi => pi.role === "slave"
    );

    if (_.some(slavePartitions, pi => pi.syncState === "sync")) {
        return "sync";
    } else if (_.some(slavePartitions, pi => pi.syncState === "async")) {
        return "async";
    }
}

export const derivePartitions = createSelector(
    selectPartitions,
    selectPartitionInstances,
    selectHaEnabled,
    selectNodes,
    (
        partitions,
        allPartitionInstances,
        haEnabled,
        nodes
    ): Maybe<Array<DerivedPartition>> => {
        if (
            partitions &&
            allPartitionInstances &&
            haEnabled !== undefined &&
            nodes
        ) {
            return _.map(partitions, partition => {
                const partitionInstances = getPartitionInstances(
                    partition,
                    allPartitionInstances
                );

                const expectedNumSlaves = getExpectedNumSlaves(
                    partition,
                    nodes,
                    haEnabled
                );

                const syncState = getSyncState(partitionInstances);

                return {
                    ...partition,
                    partitionInstances,
                    expectedNumSlaves,
                    status: derivePartitionStatus(
                        partition,
                        partitionInstances,
                        expectedNumSlaves,
                        haEnabled
                    ),
                    syncState,
                };
            });
        }
    }
);

export const selectSortedPartitions = createSelector(
    selectCurrentDatabaseName,
    derivePartitions,
    selectPartitionsSort,
    (currentDatabase, partitions, sort): Maybe<PartitionsSelection> => {
        if (partitions) {
            const selectedPartitions = _.filter(
                partitions,
                partition => partition.databaseName === currentDatabase
            );

            if (sort) {
                const { direction, columnId } = sort;

                const columnInfo = _.find(
                    PARTITION_COLUMNS,
                    col => col.id === columnId
                );

                if (!columnInfo) {
                    throw new Error(
                        "Expected column to be defined when looking for partition sort information."
                    );
                }

                return _.orderBy<DerivedPartition>(
                    selectedPartitions,
                    [columnInfo.getValue],
                    direction
                );
            } else {
                return selectedPartitions;
            }
        }
    }
);

export const selectCurrentPartitionName = (s: State): Maybe<string> =>
    selectRoute(s).params.partitionName;

export const selectCurrentPartition = createSelector(
    derivePartitions,
    selectCurrentPartitionName,
    (partitions, partitionName): Maybe<DerivedPartition> => {
        if (partitions && partitionName) {
            return _.find(
                partitions,
                partition => getPartitionName(partition) === partitionName
            );
        }
    }
);

export const selectPartitionInstancesSort = (s: State) =>
    s.clusterMetadata.partitionInstancesSort;

export const selectSortedCurrentPartitionInstances = createSelector(
    selectPartitionInstances,
    selectCurrentPartition,
    selectPartitionInstancesSort,
    (partitionInstances, partition, sort): Maybe<Array<PartitionInstance>> => {
        if (partitionInstances && partition) {
            const selectedPartitionInstances = partition.partitionInstances;

            if (sort) {
                const { direction, columnId } = sort;

                const columnInfo = _.find(
                    PARTITION_INSTANCE_COLUMNS,
                    col => col.id === columnId
                );

                if (!columnInfo) {
                    throw new Error(
                        "Expected column to be defined when looking for partition instance sort information."
                    );
                }

                return _.orderBy<PartitionInstance>(
                    selectedPartitionInstances,
                    [columnInfo.getValue],
                    direction
                );
            } else {
                return selectedPartitionInstances;
            }
        }
    }
);

// This object ranks the state of partitions from "best" to "worst".
const RANK_PARTITION_STATUS = buildRankDictionary([
    "online",
    "degraded_recovering",
    "degraded_unrecoverable",
    "offline_recovering",
    "offline",
    "unknown",
]);

// The status of a database is computed as the status of its "worst" partition.
export function deriveDatabaseStatus(
    databaseName: string,
    dbPartitions: Array<DerivedPartition>
): DatabaseStatus {
    if (databaseName === "information_schema") {
        return "online";
    }

    if (dbPartitions.length === 0) {
        return "unknown";
    } else {
        const rankedPartitions = _.orderBy(
            dbPartitions,
            [p => RANK_PARTITION_STATUS[p.status]],
            "desc"
        );
        return rankedPartitions[0].status;
    }
}

export function deriveDatabaseImpacted(
    databaseName: DatabaseName,
    dbPartitions: Array<DerivedPartition>
) {
    if (databaseName === "information_schema") {
        return false;
    }

    return _.some(dbPartitions, isPartitionImpacted);
}

const selectRowsThroughput = (state: State) =>
    state.clusterMetadata.rowsThroughput;

export type DerivedRowsThroughputPerSecond = {
    derivedRowsReadPerSecond: BigNumber;
    derivedRowsWritePerSecond: BigNumber;
};

export const derivedRowsThroughputPerSecond = createSelector(
    selectRowsThroughput,
    rowsThroughput => {
        const isPreviousError = selectIsError(rowsThroughput.previous);
        const isCurrentError = selectIsError(rowsThroughput.current);
        const previousRowsThroughput = selectPayload(rowsThroughput.previous);
        const currentRowsThroughput = selectPayload(rowsThroughput.current);

        if (previousRowsThroughput && currentRowsThroughput) {
            const derivedRowsReadPerSecond = calculateRateS(
                previousRowsThroughput.rowsReturnedByReads,
                currentRowsThroughput.rowsReturnedByReads,
                previousRowsThroughput.readTime,
                currentRowsThroughput.readTime
            );

            const derivedRowsWritePerSecond = calculateRateS(
                previousRowsThroughput.rowsAffectedByWrites,
                currentRowsThroughput.rowsAffectedByWrites,
                previousRowsThroughput.readTime,
                currentRowsThroughput.readTime
            );

            return new LESuccess<DerivedRowsThroughputPerSecond>({
                derivedRowsReadPerSecond,
                derivedRowsWritePerSecond,
            });
        } else if (isPreviousError || isCurrentError) {
            return new LEError<DerivedRowsThroughputPerSecond>();
        } else {
            return new LELoading<DerivedRowsThroughputPerSecond>();
        }
    }
);

export function selectIsRowsThroughputEnabled(s: State) {
    return s.clusterMetadata.rowsThroughputEnabled;
}
