// Whenever any edits to this file are made, we should keep the following
// document up to date:
// https://docs.google.com/spreadsheets/d/1ec6F9TufbwUn1H3W61_0806Pjc34iNceaYQAZPicyho/edit#gid=0

import { Maybe } from "util/maybe";
import * as analytics from "util/segment";

import {
    SuperAction,
    StatisticsAction,
    ExpandTableAction,
    CollapseTableAction,
    SortEventsAction,
    SortNodesAction,
    ChangeBottomPanelTabAction,
    QueryGroupStartAction,
    QueryExecutorCancelAction,
    QueryGroupContinueAction,
    QueryGroupStopAction,
    SortResultsAction,
    ConnectAction,
    AddClusterErrorAction,
    SchemaRestoreAction,
    MvFullStartAction,
    MvFullAction,
    MvCancelRecordingAction,
    MvSortQueriesAction,
    MvSortNodesAction,
    ZoomAction,
    SelectExplainNodeAction,
    ExplainLayoutAction,
    ExplainSidebarClickAction,
    ChangeExplainTabAction,
    SortHostsAction,
    SortHostNodesAction,
    SortNodePartitionInstancesAction,
    ChangeTutorialMinimizedStateAction,
    ChangeTutorialClosedStateAction,
    ChangeTutorialLocationAction,
    ChangeTutorialStepAction,
    TopologyAction,
    PipelinesAction,
    EventsAction,
    MemsqlPartitionsAction,
    SummaryAction,
} from "data/actions";

import { TableKind } from "data/models/schema";

import * as logging from "util/logging";

import { CONNECTION_ID as CONSOLE_CONNECTION_ID } from "data/reducers/console";
import { CONNECTION_ID as QUERY_EDITOR_CONNECTION_ID } from "data/reducers/query-editor";

type AnalyticsEvent = {
    action: string;
    [trait: string]: Maybe<string | number>;
};

// Convert the kind of table-like to a suitable label.
const tableKindToLabel = (kind: TableKind): string => {
    switch (kind) {
        case "TABLE":
            return "table";

        case "VIEW":
            return "view";
    }

    throw new Error("Expected tableKindToLabel to be exhaustive");
};

// We always specifiy what the action type is in the following
// handlers, even if we don't use the action object itself. The
// reasoning for this is that we'd like to type check these handlers
// in the future. The goal would be to make sure that the handler's
// string key corresponds to the action's type (string).
const HANDLERS: {
    [key: string]: (action: SuperAction) => Maybe<AnalyticsEvent>;
} = {
    TOPOLOGY: (action: TopologyAction) => {
        if (action.error) {
            return;
        }

        if (!action.payload.loading) {
            return {
                action: "query-topology-end",
                delta: action.payload.data.deltaTimeS,
            };
        }
    },

    PIPELINES: (action: PipelinesAction) => {
        if (action.error) {
            return;
        }

        if (!action.payload.loading) {
            return {
                action: "query-pipelines-end",
                delta: action.payload.data.deltaTimeS,
            };
        }
    },

    EVENTS: (action: EventsAction) => {
        if (action.error) {
            return;
        }

        if (!action.payload.loading) {
            return {
                action: "query-events-end",
                delta: action.payload.data.deltaTimeS,
            };
        }
    },

    MEMSQL_PARTITIONS: (action: MemsqlPartitionsAction) => {
        if (action.error) {
            return;
        }

        if (!action.payload.loading) {
            return {
                action: "query-partitions-end",
                delta: action.payload.data.deltaTimeS,
            };
        }
    },

    SCHEMA_SUMMARY: (action: SummaryAction) => {
        if (action.error) {
            return;
        }

        if (!action.payload.loading) {
            return {
                action: "query-schema-summary-end",
                delta: action.payload.data.deltaTimeS,
            };
        }
    },

    SCHEMA_STATISTICS: (action: StatisticsAction) => {
        if (action.error) {
            return;
        }

        if (action.payload.loading) {
            return {
                category: "schema-explorer",
                action: "reload-loading-start",
            };
        } else {
            return {
                category: "schema-explorer",
                action: "reload-loading-end",
                delta: action.payload.data.deltaTimeS,
            };
        }
    },

    SCHEMA_TREE_EXPAND_DATABASE: () => {
        return {
            category: "schema-tree",
            action: "expand-entity",
            value: "database",
        };
    },

    SCHEMA_TREE_COLLAPSE_DATABASE: () => {
        return {
            category: "schema-tree",
            action: "collapse-entity",
            value: "database",
        };
    },

    SCHEMA_TREE_EXPAND_TABLE: (action: ExpandTableAction) => {
        return {
            category: "schema-tree",
            action: "expand-entity",
            value: tableKindToLabel(action.payload.kind),
        };
    },

    SCHEMA_TREE_COLLAPSE_TABLE: (action: CollapseTableAction) => {
        return {
            category: "schema-tree",
            action: "collapse-entity",
            value: tableKindToLabel(action.payload.kind),
        };
    },

    SORT_EVENTS: (action: SortEventsAction) => {
        return {
            category: "events",
            action: action.payload
                ? action.payload.direction === "asc"
                    ? "sort-column-asc"
                    : "sort-column-desc"
                : "unsort-column",
            column: action.payload && action.payload.columnId,
        };
    },

    SORT_NODES: (action: SortNodesAction) => {
        return {
            category: "topology",
            action: action.payload
                ? action.payload.direction === "asc"
                    ? "sort-column-asc"
                    : "sort-column-desc"
                : "unsort-column",
            column: action.payload && action.payload.columnId,
        };
    },

    SORT_NODE_PARTITION_INSTANCES: (
        action: SortNodePartitionInstancesAction
    ) => {
        return {
            category: "node-page",
            action: action.payload
                ? action.payload.direction === "asc"
                    ? "sort-column-asc"
                    : "sort-column-desc"
                : "unsort-column",
            column: action.payload && action.payload.columnId,
        };
    },

    SORT_HOSTS: (action: SortHostsAction) => {
        return {
            category: "hosts",
            action: action.payload
                ? action.payload.direction === "asc"
                    ? "sort-column-asc"
                    : "sort-column-desc"
                : "unsort-column",
            column: action.payload && action.payload.columnId,
        };
    },

    SORT_HOST_NODES: (action: SortHostNodesAction) => {
        return {
            category: "host-nodes",
            action: action.payload
                ? action.payload.direction === "asc"
                    ? "sort-column-asc"
                    : "sort-column-desc"
                : "unsort-column",
            column: action.payload && action.payload.columnId,
        };
    },

    CHANGE_BOTTOM_PANEL_TAB: (action: ChangeBottomPanelTabAction) => {
        return {
            category: "bottom-panel",
            action: "change-tab",
            tab: action.payload.tab,
        };
    },

    QUERY_GROUP_START: (action: QueryGroupStartAction) => {
        if (action.payload.id === CONSOLE_CONNECTION_ID) {
            return {
                category: "bottom-panel",
                action: "run-queries",
                count: action.payload.queryGroup.queries.length,
            };
        } else if (action.payload.id === QUERY_EDITOR_CONNECTION_ID) {
            return {
                category: "query-editor",
                action: "run-queries",
                count: action.payload.queryGroup.queries.length,
            };
        }

        logging.logError(
            new Error(
                "Only expect Query Editor or Console static connection IDs."
            )
        );
    },

    QUERY_EXECUTOR_CANCEL: (action: QueryExecutorCancelAction) => {
        if (action.payload.id === QUERY_EDITOR_CONNECTION_ID) {
            return {
                category: "query-editor",
                action: "cancel-query",
            };
        }

        logging.logError(
            new Error(
                "Only expect Query Editor or Console static connection IDs."
            )
        );
    },

    QUERY_GROUP_CONTINUE: (action: QueryGroupContinueAction) => {
        if (action.payload.id === QUERY_EDITOR_CONNECTION_ID) {
            return {
                category: "query-editor",
                action: action.payload.skip
                    ? "skip-all-queries-group"
                    : "skip-single-query-group",
            };
        }

        logging.logError(
            new Error(
                "Only expect Query Editor or Console static connection IDs."
            )
        );
    },

    QUERY_GROUP_STOP: (action: QueryGroupStopAction) => {
        if (action.payload.id === QUERY_EDITOR_CONNECTION_ID) {
            return {
                category: "query-editor",
                action: "stop-query-group",
            };
        }

        logging.logError(
            new Error(
                "Only expect Query Editor or Console static connection IDs."
            )
        );
    },

    QUERY_EDITOR_SORT: (_action: SortResultsAction) => {
        return {
            category: "query-editor",
            action: "sort-results-column",
        };
    },

    CONNECT: (action: ConnectAction) => {
        if (action.error) {
            return {
                category: "connect",
                action: "connect-cluster-error",
                code: action.payload.code || "unknown",
            };
        } else {
            return {
                category: "connect",
                action: "connect-cluster",
            };
        }
    },

    CLUSTERS_ADD_ERROR: (action: AddClusterErrorAction) => {
        return {
            category: "connect",
            action: "add-new-cluster-error",
            code: action.payload.code,
        };
    },

    SCHEMA_RESTORE: (_action: SchemaRestoreAction) => {
        return {
            category: "schema-explorer",
            action: "import-schema",
        };
    },

    MV_FULL_START: (action: MvFullStartAction) => {
        if (action.error) {
            return;
        }

        if (action.payload.loading) {
            return {
                category: "management-views",
                action: "full-start-loading-start",
            };
        } else {
            const deltaTimeS = action.payload.data.deltaTimeS;
            return {
                category: "management-views",
                action: "full-start-loading-end",
                delta: deltaTimeS,
            };
        }
    },

    MV_FULL: (action: MvFullAction) => {
        if (action.error) {
            return;
        }

        if (action.payload.loading) {
            return {
                category: "management-views",
                action: "full-stop-loading-start",
            };
        } else if (action.payload.data.deltaTimeS !== undefined) {
            const deltaTimeS = action.payload.data.deltaTimeS;
            return {
                category: "management-views",
                action: "full-stop-loading-end",
                delta: deltaTimeS,
            };
        } else {
            // If the payload doesn't have deltaTimeS, then it's an import from a file.
            return {
                category: "management-views",
                action: "import-data-json",
            };
        }
    },

    MV_CANCEL_RECORDING: (_action: MvCancelRecordingAction) => {
        return {
            category: "management-views",
            action: "cancel-recording",
        };
    },

    MV_SORT_QUERIES: (action: MvSortQueriesAction) => {
        if (!action.payload.isUserCaused || !action.payload.sort) {
            return;
        }

        switch (action.payload.sort.direction) {
            case "asc":
                return {
                    category: "management-views",
                    action: "sort-queries-column-asc",
                    column: action.payload.sort.columnId,
                };

            case "desc":
                return {
                    category: "management-views",
                    action: "sort-queries-column-desc",
                    column: action.payload.sort.columnId,
                };
        }
    },

    MV_SORT_NODES: (action: MvSortNodesAction) => {
        if (!action.payload.isUserCaused || !action.payload.sort) {
            return;
        }

        switch (action.payload.sort.direction) {
            case "asc":
                return {
                    category: "management-views",
                    action: "sort-nodes-column-asc",
                    column: action.payload.sort.columnId,
                };

            case "desc":
                return {
                    category: "management-views",
                    action: "sort-nodes-column-desc",
                    column: action.payload.sort.columnId,
                };
        }
    },

    ZOOM: (action: ZoomAction) => {
        switch (action.payload.zoomLevel) {
            case "large":
                return {
                    category: "visual-explain",
                    action: "zoom",
                    level: "large",
                };

            case "medium":
                return {
                    category: "visual-explain",
                    action: "zoom",
                    level: "medium",
                };

            case "small":
                return {
                    category: "visual-explain",
                    action: "zoom",
                    level: "small",
                };
        }

        logging.logError(
            new Error(
                `Unknown zoom level for Visual Explain Zoom action: ${
                    action.payload.zoomLevel
                }`
            )
        );
    },

    SELECT_EXPLAIN_NODE: (action: SelectExplainNodeAction) => {
        if (action.payload.index === undefined) {
            return {
                category: "visual-explain",
                action: "viewport-click",
            };
        } else {
            return {
                category: "visual-explain",
                action: "select-node",
                executor: action.payload.executor,
            };
        }
    },

    EXPLAIN_LAYOUT: (action: ExplainLayoutAction) => {
        if (action.error) {
            return {
                category: "visual-explain",
                action: "import-error",
            };
        } else {
            if (!action.payload.loading) {
                return {
                    category: "visual-explain",
                    action: "import",
                    "plan-type": action.payload.data.layout.planType,
                };
            }
        }
    },

    CHANGE_EXPLAIN_TAB: (action: ChangeExplainTabAction) => {
        switch (action.payload.tab) {
            case "Difference":
                return {
                    category: "visual-explain",
                    action: "change-explain-tab",
                    tab: "difference",
                };

            case "Estimated":
                return {
                    category: "visual-explain",
                    action: "change-explain-tab",
                    tab: "estimated",
                };

            case "Actual":
                return {
                    category: "visual-explain",
                    action: "change-explain-tab",
                    tab: "actual",
                };
        }

        logging.logError(
            new Error(
                `Unknown name for Visual Explain Explain Tab action: ${
                    action.payload.tab
                }`
            )
        );
    },

    EXPLAIN_SIDEBAR_CLICK: (action: ExplainSidebarClickAction) => {
        return {
            category: "visual-explain",
            action: "sidebar-select-node",
            executor: action.payload.executor,
        };
    },

    CHANGE_TUTORIAL_MINIMIZED_STATE: (
        action: ChangeTutorialMinimizedStateAction
    ) => {
        const { minimized } = action.payload;

        if (minimized) {
            return {
                category: "tutorial",
                action: "tutorial-minimized",
            };
        } else {
            return {
                category: "tutorial",
                action: "tutorial-expanded",
            };
        }
    },

    CHANGE_TUTORIAL_CLOSED_STATE: (action: ChangeTutorialClosedStateAction) => {
        const { closed } = action.payload;

        if (closed) {
            return {
                category: "tutorial",
                action: "tutorial-closed",
            };
        } else {
            return {
                category: "tutorial",
                action: "tutorial-opened",
            };
        }
    },

    CHANGE_TUTORIAL_LOCATION: (action: ChangeTutorialLocationAction) => {
        return {
            category: "tutorial",
            action: "change-tutorial-location",
            location: action.payload.location.toString(),
        };
    },

    CHANGE_TUTORIAL_STEP: (action: ChangeTutorialStepAction) => {
        return {
            category: "tutorial",
            action: "change-tutorial-location",
            location: `${action.payload.location}, step: ${
                action.payload.step
            }`,
        };
    },
};

export default () => (next: (action: SuperAction) => void) => (
    action: SuperAction
) => {
    const handler: (action: SuperAction) => Maybe<AnalyticsEvent> =
        HANDLERS[action.type];

    if (handler) {
        const event: Maybe<AnalyticsEvent> = handler(action);

        if (event) {
            const { action, ...parameters } = event;

            analytics.track(action, parameters);
        }
    }

    return next(action);
};
