import { State } from "data";
import { Maybe } from "util/maybe";
import {
    ExplainLayout,
    ExplainClusterInfo,
    ExplainQueryInfo,
    ExecutorInstance,
    PlanType,
} from "data/models";
import { Layout, ZoomLevel } from "data/explain/layout";
import { ExplainSourceType } from "data/actions";

import _ from "lodash";

import { createSelector } from "reselect";

import { selectPayload, selectError } from "util/loading-state-machine";

export const selectExplainLayout = (s: State): Maybe<ExplainLayout> => {
    const payload = selectPayload(s.explain.payload);

    if (payload) {
        return payload.layout;
    }
};

export const selectZoomLevel = (s: State): ZoomLevel => {
    return s.explain.zoomLevel;
};

export const selectExplainRawJSON = (s: State): Maybe<string> => {
    const payload = selectPayload(s.explain.payload);

    if (payload) {
        return payload.rawJSON;
    }
};

export const selectExplainLayoutLoading = (s: State): boolean =>
    s.explain.payload.loading;

export const selectExplainLayoutError = (s: State): Maybe<string> => {
    const error = selectError(s.explain.payload);
    return error ? error.message : undefined;
};

export const selectExplainNodes = createSelector(
    selectExplainLayout,
    selectZoomLevel,
    (layout: Maybe<ExplainLayout>, zoomLevel: ZoomLevel) => {
        if (layout) {
            return _.map(layout.layout[zoomLevel].nodes, node => node.data);
        }
    }
);

export const selectLayout = (s: State): Maybe<Layout<ExecutorInstance>> => {
    const payload = selectPayload(s.explain.payload);

    if (payload) {
        return payload.layout.layout[s.explain.zoomLevel];
    }
};

export const selectExplainLayoutWarnings = (s: State) => {
    const explain = selectPayload(s.explain.payload);

    if (explain) {
        return explain.warnings;
    }
};

export const selectExplainSource = (s: State): Maybe<string> => {
    const explain = selectPayload(s.explain.payload);

    if (explain) {
        return explain.source;
    }
};

export const selectExplainSourceType = (s: State): Maybe<ExplainSourceType> => {
    const explain = selectPayload(s.explain.payload);

    if (explain) {
        return explain.sourceType;
    }
};

export const selectPlanType = (s: State): Maybe<PlanType> => {
    const payload = selectPayload(s.explain.payload);

    if (payload) {
        return payload.layout.planType;
    }
};

export const selectExplainQueryInfo = (s: State): Maybe<ExplainQueryInfo> => {
    const payload = selectPayload(s.explain.payload);

    if (payload) {
        return payload.queryInfo;
    }
};

export const selectActiveExecutorInstance = (
    s: State
): Maybe<ExecutorInstance> => {
    const layout = selectLayout(s);
    const selectedIndex = s.explain.selectedIndex;

    if (layout && selectedIndex !== undefined) {
        return layout.nodes[selectedIndex].data;
    }
};

export const selectNumOperations = createSelector(
    selectExplainNodes,
    (nodes: Maybe<Array<ExecutorInstance>>) => {
        if (nodes) {
            return nodes.length;
        }
    }
);

export const selectTotalTimeMs = createSelector(
    selectExplainNodes,
    (nodes: Maybe<Array<ExecutorInstance>>) => {
        if (nodes) {
            // To calculate the total actual exec time, we take the actual
            // total time of each node and add them up. If none of the nodes
            // has actual_total_time set, then we return `undefined` (so as not
            // to show in this value in the User Interface for EXPLAIN outputs).

            let allUndefined = true;
            let totalTimeMs = 0;

            for (let i = 0; i < nodes.length; i++) {
                const nodeTotalTimeMs = nodes[i].metrics.totalTimeMs;

                if (nodeTotalTimeMs !== undefined) {
                    allUndefined = false;
                    totalTimeMs += nodeTotalTimeMs.value;
                }
            }

            if (allUndefined) {
                return undefined;
            } else {
                return totalTimeMs;
            }
        }
    }
);

export const selectTotalMemoryUsage = createSelector(
    selectExplainNodes,
    (nodes: Maybe<Array<ExecutorInstance>>) => {
        if (nodes) {
            // To calculate the total memory usage, we take the memory
            // usage of each node and add them up. If none of the nodes
            // has memory usage set, then we return `undefined` (so as not
            // to show in this value in the User Interface for EXPLAIN outputs).

            let allUndefined = true;
            let totalMemoryUsage = 0;

            for (let i = 0; i < nodes.length; i++) {
                const nodeMemoryUsage = nodes[i].metrics.memoryUsage;

                if (nodeMemoryUsage !== undefined) {
                    allUndefined = false;
                    totalMemoryUsage += nodeMemoryUsage.value;
                }
            }

            if (allUndefined) {
                return undefined;
            } else {
                return totalMemoryUsage;
            }
        }
    }
);

export const selectClusterInfo = (s: State): Maybe<ExplainClusterInfo> => {
    const payload = selectPayload(s.explain.payload);

    if (payload && payload.clusterInfo) {
        return payload.clusterInfo;
    }
};
