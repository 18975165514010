import { Maybe } from "util/maybe";

import { State as RouteState } from "router5";
import { DispatchFunction, State } from "data";
import { Cluster, LicenseInfo } from "data/models";

import { FeedbackModalStatus } from "view/common/feedback-modal";
import { QueryExecutorState } from "worker/net/query-executor";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { endsWithSegment, includesSegment } from "router5-helpers";

import { memsqlPing, memsqlPingStop } from "worker/api/connection";
import { queryLicense } from "worker/api/license";
import { queryMemsqlVersion } from "worker/api/cluster-metadata";
import { connectConsole } from "data/actions/console";
import { connectQueryEditor } from "data/actions/query-editor";

import { selectLicenseInfo } from "data/selectors/license";
import { selectCurrentCluster } from "data/selectors/clusters";
import { selectMemsqlOnline } from "data/selectors/system-status";

import FeedbackModal from "view/common/feedback-modal";
import NotificationsContainer from "view/common/notification-manager/container";
import TutorialContainer from "view/tutorial/tutorial-container";

import ActivitiesPage from "view/monitor/page-activities";
import ActiveProcessesPage from "view/active-processes/page-active";
import NodesPage from "view/monitor/page-nodes";
import EditorPage from "view/editor/page-editor";
import ExplainPage from "view/explain/page-explain";
import SchemaController from "view/controllers/schema";
import DashboardPage from "view/dashboard/page-dashboard";
import EventsPage from "view/events";
import PipelinesPage from "view/pipelines";
import AboutPage from "view/about";
import HostsController from "view/controllers/hosts";
import TopologyController from "view/controllers/topology";
import ResizeDetector from "view/components/resize-detector";

import ClusterProfileIcon from "view/cluster/profile-icon";
import ClusterSwitcher from "view/cluster/switcher";
import BottomPanel from "view/bottom-panel/panel";

import DropdownButton from "view/components/dropdown-button";
import { Menu, MenuItem } from "view/common/menu";

import {
    Layout,
    Sidebar,
    SidebarSection,
    SidebarLink,
} from "view/layouts/main";

import { createRouteNodeSelector } from "redux-router5";
import { changeTutorialClosedState } from "data/actions";

import "./cluster-layout.scss";

type StateProps = {
    user: Maybe<string>;
    cluster: Maybe<Cluster>;
    consoleConnectionState: Maybe<QueryExecutorState>;
    memsqlOnline: boolean;
    licenseInfo: Maybe<LicenseInfo>;
    selectedDatabase: Maybe<string>;
    route: RouteState;
    disableHosts: boolean;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

type ClusterLayoutState = {
    feedbackModalStatus: FeedbackModalStatus;
    // Track the height of the BottomNav parent element.
    maxBottomPanelHeight: number;
};

class ClusterLayout extends React.Component<Props, ClusterLayoutState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            feedbackModalStatus: "CLOSED",
            maxBottomPanelHeight: 0,
        };
    }

    componentDidMount() {
        this.props.dispatch(memsqlPing());

        // The reason we dispatch `queryLicense` here is because
        // the analytics listener depends on the license ID
        // being set in the Redux state in order to send it
        // to Analytics.
        this.props.dispatch(queryLicense());

        // The reason we dispatch `queryMemsqlVersion` here is because
        // we want to log which memsql version is the user using
        // to Analytics.
        this.props.dispatch(queryMemsqlVersion());

        this.props.dispatch(connectConsole());
        this.props.dispatch(connectQueryEditor());
    }

    componentWillUnmount() {
        this.props.dispatch(memsqlPingStop());
    }

    handleResize = _.debounce((__, height: number) => {
        this.setState({
            maxBottomPanelHeight: height,
        });
    }, 50);

    handleChangeFeedbackModalStatus = (status: FeedbackModalStatus) => {
        this.setState({ feedbackModalStatus: status });
    };

    handleOpenFeedbackModal = () => {
        this.handleChangeFeedbackModalStatus("OPEN");
    };

    handleChangeTutorial = ({ closed }: { closed: boolean }) => {
        this.props.dispatch(changeTutorialClosedState({ closed }));
    };

    handleOpenTutorial = () => this.handleChangeTutorial({ closed: false });

    handleCloseTutorial = () => this.handleChangeTutorial({ closed: true });

    render() {
        const { cluster, route, user, licenseInfo, disableHosts } = this.props;
        const { feedbackModalStatus, maxBottomPanelHeight } = this.state;

        if (!cluster) {
            throw new Error(
                "Expected cluster to exist when rendering ClusterLayout"
            );
        }

        const testRoute = endsWithSegment(route);

        let page;
        if (testRoute("activities")) {
            page = <ActivitiesPage />;
        } else if (testRoute("activities.nodes")) {
            page = <NodesPage />;
        } else if (testRoute("active-processes")) {
            page = <ActiveProcessesPage />;
        } else if (testRoute("editor")) {
            page = <EditorPage />;
        } else if (testRoute("explain")) {
            page = <ExplainPage />;
        } else if (testRoute("dashboard")) {
            page = <DashboardPage />;
        } else if (testRoute("events")) {
            page = <EventsPage />;
        } else if (testRoute("cluster.pipelines")) {
            // We have to be extra careful here ^ because other routes end in
            // "pipelines".
            page = <PipelinesPage />;
        } else if (testRoute("about")) {
            page = <AboutPage />;
        } else if (includesSegment(route)("hosts") && !disableHosts) {
            page = <HostsController />;
        } else if (includesSegment(route)("databases")) {
            page = <SchemaController />;
        } else if (includesSegment(route)("nodes")) {
            page = <TopologyController />;
        } else {
            page = "Please select a page in the sidebar";
        }

        const icon = (
            <ClusterProfileIcon
                size="sm"
                profile={cluster.profile}
                description={cluster.description}
            />
        );

        const routeParams = { clusterId: cluster && cluster.id };
        const routeInfo = {
            name: "cluster.dashboard",
            params: { clusterId: routeParams.clusterId },
        };

        const footer = (
            <div className="cluster-layout-sidebar-footer">
                <DropdownButton
                    ghost
                    icon="question-circle"
                    iconType="regular"
                    direction="ne"
                    className="sidebar-footer-dropdown-btn"
                >
                    <Menu>
                        <MenuItem
                            action={{
                                kind: "internal-link",
                                category: "sidebar",
                                routeInfo: {
                                    name: "cluster.about",
                                    params: routeParams,
                                },
                            }}
                        >
                            About
                        </MenuItem>
                        <MenuItem
                            action={{
                                kind: "external-link",
                                category: "sidebar",
                                name: "forums",
                            }}
                        >
                            Forums
                        </MenuItem>
                        {/* eslint-disable-next-line react/jsx-handler-names */}
                        <MenuItem action={this.handleOpenFeedbackModal}>
                            Feedback
                        </MenuItem>
                        {/* eslint-disable-next-line react/jsx-handler-names */}
                        <MenuItem action={this.handleOpenTutorial}>
                            Show Tutorial
                        </MenuItem>
                    </Menu>
                </DropdownButton>
            </div>
        );

        let hostsLink;
        if (!disableHosts) {
            hostsLink = (
                <SidebarLink
                    name="Hosts"
                    icon="host"
                    routeName="cluster.hosts"
                    routeParams={routeParams}
                />
            );
        }

        const sidebar = (
            <Sidebar
                title={cluster.name}
                routeInfo={routeInfo}
                subtitle={user}
                icon={icon}
                titleMenu={<ClusterSwitcher />}
                licenseInfo={licenseInfo}
                footer={footer}
            >
                <SidebarSection title="Overview">
                    <SidebarLink
                        name="Dashboard"
                        icon="dashboard"
                        routeName="cluster.dashboard"
                        routeParams={routeParams}
                    />
                    <SidebarLink
                        name="Events"
                        icon="bell"
                        routeName="cluster.events"
                        routeParams={routeParams}
                    />
                    {hostsLink}
                    <SidebarLink
                        name="Nodes"
                        icon="nodes"
                        routeName="cluster.nodes"
                        routeParams={routeParams}
                    />
                    <SidebarLink
                        name="Databases"
                        icon="database"
                        routeName="cluster.databases"
                        routeParams={routeParams}
                    />
                </SidebarSection>
                <SidebarSection title="Query">
                    <SidebarLink
                        name="SQL Editor"
                        icon="query"
                        routeName="cluster.editor"
                        routeParams={routeParams}
                    />
                    <SidebarLink
                        name="Resource Usage"
                        icon="tachometer"
                        routeName="cluster.activities"
                        routeParams={routeParams}
                    />
                    <SidebarLink
                        name="Active Processes"
                        icon="clock"
                        routeName="cluster.active-processes"
                        routeParams={routeParams}
                    />
                    <SidebarLink
                        name="Visual Explain"
                        icon="sitemap"
                        routeName="cluster.explain"
                        routeParams={routeParams}
                    />
                </SidebarSection>
                <SidebarSection title="Ingest">
                    <SidebarLink
                        name="Pipelines"
                        icon="pipelines"
                        routeName="cluster.pipelines"
                        routeParams={routeParams}
                    />
                </SidebarSection>
            </Sidebar>
        );

        return (
            <Layout
                sidebar={sidebar}
                bottomPanel={
                    <>
                        <ResizeDetector onResize={this.handleResize} />
                        <BottomPanel maxHeight={maxBottomPanelHeight} />
                    </>
                }
                notifications={<NotificationsContainer />}
            >
                {page}
                <FeedbackModal
                    status={feedbackModalStatus}
                    onStatusChange={this.handleChangeFeedbackModalStatus}
                />
                <TutorialContainer onTutorialClose={this.handleCloseTutorial} />
            </Layout>
        );
    }
}

export default connect(
    (s: State): StateProps => {
        const {
            state: { config },
        } = s.connection;

        let user;
        if (config && config.user) {
            user = config.user;
        }

        const routeNodeSelector = createRouteNodeSelector("cluster");

        return {
            user,
            cluster: selectCurrentCluster(s),
            consoleConnectionState: s.console.queryExecutorState,
            memsqlOnline: selectMemsqlOnline(s),
            licenseInfo: selectLicenseInfo(s),
            selectedDatabase: s.console.selectedDatabase,

            route: routeNodeSelector(s).route,

            disableHosts: s.hosts.disableHosts,
        };
    }
)(ClusterLayout);
