import * as React from "react";

import { Button } from "view/common/button";

type Props = {
    onMinimize: () => void;
    onRestore: () => void;
    onMaximize: () => void;

    // Fraction of maximum possible height.
    // If (<=) 0, show restore and maximize.
    // If between 0 and 1, show minimize and maximize.
    // If (>=) 1, show minimize and restore.
    height: number;

    className?: string;
};

export class ResizeButtons extends React.PureComponent<Props> {
    handleMinimize = (e: React.MouseEvent) => {
        // We stop propagation in all our handlers because our resize buttons
        // can be put on bars where clicking the bar does something that we no
        // longer want to trigger.
        e.stopPropagation();

        this.props.onMinimize();
    };

    handleRestore = (e: React.MouseEvent) => {
        // See above.
        e.stopPropagation();

        this.props.onRestore();
    };

    handleMaximize = (e: React.MouseEvent) => {
        // See above.
        e.stopPropagation();

        this.props.onMaximize();
    };

    render() {
        const { height, className } = this.props;

        let minimizeButton;
        let restoreButton;
        let maximizeButton;

        if (height > 0) {
            minimizeButton = (
                <Button
                    ghost
                    small
                    icon="window-minimize"
                    onMouseDown={this.handleMinimize}
                />
            );
        }

        if (height <= 0 || height >= 1) {
            restoreButton = (
                <Button
                    ghost
                    small
                    icon="window-restore"
                    onMouseDown={this.handleRestore}
                />
            );
        }

        if (height < 1) {
            maximizeButton = (
                <Button
                    ghost
                    small
                    icon="window-maximize"
                    onMouseDown={this.handleMaximize}
                />
            );
        }

        return (
            <div className={className}>
                {minimizeButton}
                {restoreButton}
                {maximizeButton}
            </div>
        );
    }
}
