import { Maybe } from "util/maybe";
import { State } from "data/reducers";

import {
    Database,
    Table,
    Column,
    DatabaseName,
    TableName,
    View,
    ViewColumn,
    TableLike,
    ColumnLike,
    AbsoluteTableName,
    FunctionLike,
} from "data/models";

import _ from "lodash";
import { createSelector } from "reselect";

// This can be a table or a view.
export type TableLikeSelection = {
    tableLike: TableLike;
    expanded: boolean;
    columns: Array<ColumnLike>;
};

export type FunctionLikeSelection = {
    functionLike: FunctionLike;
};

export type DatabaseSelection = {
    database: Database;
    expanded: boolean;
    tables: Array<TableLikeSelection>;
    functions: Array<FunctionLikeSelection>;
};

export type OverallSelection = {
    databases: Array<DatabaseSelection>;
};

const selectDatabases = (state: State): Array<Database> =>
    state.schema.structure.databases;

const selectTables = (state: State): Array<Table> =>
    state.schema.structure.tables;

const selectViews = (state: State): Array<View> => state.schema.structure.views;

const selectUserDefinedFunctions = (state: State) =>
    state.schema.structure.userDefinedFunctions;

const selectStoredProcedures = (state: State) =>
    state.schema.structure.storedProcedures;

const selectAggregates = (state: State) => state.schema.structure.aggregates;

const selectFunctions = createSelector(
    selectUserDefinedFunctions,
    selectStoredProcedures,
    selectAggregates,
    (userDefinedFunctions, storedProcedures, aggregates) => {
        return _.concat<FunctionLike>(
            userDefinedFunctions,
            storedProcedures,
            aggregates
        );
    }
);

const selectColumns = (state: State): Array<Column> =>
    state.schema.structure.columns;

const selectSortedColumns = createSelector(
    selectColumns,
    (columns): Array<Column> =>
        _.orderBy(columns, [col => col.ordinalPosition], "asc")
);

const selectViewColumns = (state: State): Array<ViewColumn> =>
    state.schema.structure.viewColumns;

const selectSortedViewColumns = createSelector(
    selectViewColumns,
    (viewColumns): Array<ViewColumn> =>
        _.orderBy(viewColumns, [col => col.ordinalPosition], "asc")
);

const selectExpandedDatabases = (state: State): Array<DatabaseName> =>
    state.schemaTree.expandedDatabases;

const selectExpandedTables = (state: State): Array<AbsoluteTableName> =>
    state.schemaTree.expandedTables;

const selectSearchQuery = (state: State): string =>
    state.schemaTree.searchQuery;

export const selectSchemaTree = createSelector(
    selectDatabases,
    selectTables,
    selectViews,
    selectSortedColumns,
    selectSortedViewColumns,
    selectFunctions,
    selectExpandedDatabases,
    selectExpandedTables,
    selectSearchQuery,
    (
        databases,
        tables,
        views,
        columns,
        viewColumns,
        functions,
        expandedDatabases,
        expandedTables,
        searchQuery
    ): OverallSelection => {
        // Escape regex special characters.
        const searchEscaped = _.escapeRegExp(searchQuery);
        const searchRegex = new RegExp(searchEscaped, "i");

        // If needsMatch is true, each of the getX functions will only return
        // columns, tables, or functions that either match the regex or have a
        // descendant that matches the regex. Otherwise they will get all
        // descendants.

        const getTableColumns = (
            databaseName: DatabaseName,
            tableName: TableName,
            { needsMatch }: { needsMatch: boolean }
        ): Array<ColumnLike> => {
            return _(columns)
                .concat<ColumnLike>(viewColumns)
                .filter(
                    (c: ColumnLike) =>
                        c.databaseName === databaseName &&
                        c.tableName === tableName &&
                        (!needsMatch ||
                            Boolean(c.columnName.match(searchRegex)))
                )
                .value();
        };

        const getDatabaseTables = (
            databaseName: DatabaseName,
            {
                needsMatch,
                fullyExpanded, // if true, get all columns of all tables
            }: { needsMatch: boolean; fullyExpanded: boolean }
        ): Array<TableLikeSelection> => {
            return _(tables)
                .concat<TableLike>(views)
                .filter((t: TableLike) => t.databaseName === databaseName)
                .map(
                    (tableLike: TableLike): Maybe<TableLikeSelection> => {
                        const tableName = tableLike.tableName;

                        if (needsMatch) {
                            // include the table only if it matches or at least
                            // one child column matches

                            const tableMatches = tableName.match(searchRegex);
                            const columns = getTableColumns(
                                databaseName,
                                tableName,
                                { needsMatch: !tableMatches }
                            );

                            if (tableMatches || columns.length) {
                                return {
                                    tableLike,
                                    expanded: true,
                                    columns,
                                };
                            }
                        } else {
                            // include the table with all columns; may or may
                            // not be expanded

                            const expanded =
                                fullyExpanded ||
                                _.some(expandedTables, {
                                    tableName,
                                    databaseName,
                                });

                            return {
                                tableLike,
                                expanded,
                                columns: getTableColumns(
                                    databaseName,
                                    tableName,
                                    { needsMatch: false }
                                ),
                            };
                        }
                    }
                )
                .filter((t): t is TableLikeSelection => Boolean(t))
                .orderBy(
                    [
                        ({ tableLike }: TableLikeSelection) =>
                            tableLike.tableName,
                    ],
                    "asc"
                )
                .value();
        };

        const getDatabaseFunctions = (
            databaseName: DatabaseName,
            { needsMatch }: { needsMatch: boolean }
        ): Array<FunctionLikeSelection> => {
            return _(functions)
                .filter(
                    (f: FunctionLike) =>
                        f.databaseName === databaseName &&
                        (!needsMatch || Boolean(f.name.match(searchRegex)))
                )
                .map(functionLike => ({ functionLike }))
                .orderBy(
                    [
                        ({ functionLike }: FunctionLikeSelection) =>
                            functionLike.kind,
                        ({ functionLike }: FunctionLikeSelection) =>
                            functionLike.name,
                    ],
                    "asc"
                )
                .value();
        };

        return {
            databases: _(databases)
                .map(
                    (database: Database): Maybe<DatabaseSelection> => {
                        if (searchQuery) {
                            // include the database only if it matches or at
                            // least one child table/function matches (tables
                            // can match via their columns matching)

                            const { databaseName } = database;
                            const databaseMatches = Boolean(
                                databaseName.match(searchRegex)
                            );
                            const tables = getDatabaseTables(
                                database.databaseName,
                                {
                                    needsMatch: !databaseMatches,
                                    fullyExpanded: databaseMatches,
                                }
                            );
                            const functions = getDatabaseFunctions(
                                database.databaseName,
                                { needsMatch: !databaseMatches }
                            );

                            if (
                                databaseMatches ||
                                tables.length ||
                                functions.length
                            ) {
                                return {
                                    database,
                                    expanded: true,
                                    tables,
                                    functions,
                                };
                            }
                        } else {
                            // include the database with all tables and
                            // functions; may or may not be expanded

                            const expanded = _.includes(
                                expandedDatabases,
                                database.databaseName
                            );

                            return {
                                database,
                                expanded,
                                tables: getDatabaseTables(
                                    database.databaseName,
                                    {
                                        needsMatch: false,
                                        fullyExpanded: false,
                                    }
                                ),
                                functions: getDatabaseFunctions(
                                    database.databaseName,
                                    { needsMatch: false }
                                ),
                            };
                        }
                    }
                )
                .filter((d): d is DatabaseSelection => Boolean(d))
                .value(),
        };
    }
);
