import { TableSort } from "util/sort";
import { SimpleAction, Action } from "./types";
import { Loading } from "util/loading-state-machine";

import { Pipeline } from "data/models";

export type PipelinesPayload = {
    pipelines: Array<Pipeline>;
    deltaTimeS: number;
};

export type PipelinesAction = Action<
    "PIPELINES",
    Loading<PipelinesPayload>,
    { message: string }
>;

export type SortPipelinesAction = SimpleAction<"SORT_PIPELINES", TableSort>;

export const sortPipelines = (payload: TableSort): SortPipelinesAction => ({
    type: "SORT_PIPELINES",
    error: false,
    payload,
});
