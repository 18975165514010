import { Maybe } from "util/maybe";
import { Cluster, ClusterId } from "data/models";
import { OnSubmitSuccess } from "view/controllers/connect";
import { State as ReduxState, DispatchFunction } from "data";
import { Router } from "router5";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { withRoute } from "react-router5";

import { ClustersListItem } from "view/connect/clusters-list";
import Loading from "view/components/loading";

import { getClusters } from "worker/api/clusters";

import { selectClusters, selectClustersError } from "data/selectors/clusters";
import { decodeClusterJwt } from "data/models";

import "./connect-to-cluster.scss";

type StateProps = {
    clusters: Maybe<Array<Cluster>>;
    error: Maybe<string>;
};

type RouterProps = {
    router: Router;
};

type OwnProps = {
    onSubmitSuccess: OnSubmitSuccess;
    clusterId: ClusterId; // Could be a JWT or a regular cluster ID.
};

type Props = StateProps &
    OwnProps &
    RouterProps & {
        dispatch: DispatchFunction;
    };

class ConnectToClusterPage extends React.Component<Props> {
    componentDidMount() {
        this.props.dispatch(getClusters());
    }

    renderErrorMsg = (errorMsg: string) => (
        <div className="error-msg">{errorMsg}</div>
    );

    render() {
        const {
            clusters,
            error: errorMsg,
            clusterId,
            onSubmitSuccess,
        } = this.props;

        // If the cluster ID is a cluster JWT, then we are dealing with a JWT
        // connection.
        const jwtCluster = decodeClusterJwt(clusterId);
        if (jwtCluster) {
            return (
                <ClustersListItem
                    onSubmitSuccess={onSubmitSuccess}
                    cluster={jwtCluster}
                    expanded
                />
            );
        }

        let inner: React.ReactNode = null;
        if (errorMsg) {
            inner = this.renderErrorMsg(errorMsg);
        } else if (!clusters) {
            inner = <Loading size="large" />;
        } else {
            const cluster = _.find(
                clusters,
                cluster => cluster.id === clusterId
            );

            if (!cluster) {
                inner = this.renderErrorMsg(
                    `No cluster with ID "${clusterId}" found.`
                );
            } else {
                // The reason we pass an `onMouseDown` to the InternalLink component is
                // to bypass a potential form blur event that happens when the link is
                // clicked and the connect to cluster form is focused.

                inner = (
                    <>
                        <ClustersListItem
                            onSubmitSuccess={onSubmitSuccess}
                            cluster={cluster}
                            expanded
                        />
                    </>
                );
            }
        }

        return <div className="connect-to-cluster-page">{inner}</div>;
    }
}

export default connect(
    (s: ReduxState): StateProps => ({
        clusters: selectClusters(s),
        error: selectClustersError(s),
    })
)(withRoute(ConnectToClusterPage));
