import { Maybe } from "util/maybe";
import { PartitionInstance } from "data/models";
import {
    LoadingError,
    LELoading,
    LEError,
    LESuccess,
} from "util/loading-error";

import BigNumber from "vendor/bignumber.js/bignumber";

export type NodeRole = "LEAF" | "MASTER_AGGREGATOR" | "AGGREGATOR";

export type NodeLiveMonitoringMetrics = {
    totalCpuUsagePercent: LoadingError<number>; // this value is cgroup-aware
    usedMemoryB: LoadingError<BigNumber>;
    memoryLimitB: LoadingError<BigNumber>;
    totalDiskB: LoadingError<BigNumber>;
    usedDiskB: LoadingError<BigNumber>;
};

type NodeBase = {
    host: string;
    port: BigNumber;
    state: "detached" | "online" | "offline" | "attaching" | string;
    openedConnections: BigNumber;
    averageLatency: Maybe<number>; // average roundtrip latency in milliseconds

    liveMonitoring: NodeLiveMonitoringMetrics;
};

export type Leaf = NodeBase & {
    availabilityGroup: BigNumber;
    role: "LEAF";
    pairHost: Maybe<string>;
    pairPort: Maybe<BigNumber>;
};

export type Aggregator = NodeBase & {
    role: "MASTER_AGGREGATOR" | "AGGREGATOR";
};

export type Node = Leaf | Aggregator;

export type NodePartitionInstanceStatus =
    | "PARTITIONS_ONLINE"
    | "PARTITIONS_OFFLINE_RECOVERING"
    | "PARTITIONS_OFFLINE"
    | "PARTITIONS_UNKNOWN";

export type DerivedNode = Node & {
    partitionInstances: Array<PartitionInstance>;
    partitionInstanceStatus: NodePartitionInstanceStatus;
};

export const humanizeNodeRole = (role: string) => {
    if (role === "LEAF") {
        return "Leaf";
    } else if (role === "AGGREGATOR") {
        return "Child";
    } else if (role === "MASTER_AGGREGATOR") {
        return "Master";
    }

    return role;
};

export const abbreviateNodeRole = (role: string) => {
    if (role === "LEAF") {
        return "L";
    } else if (role === "AGGREGATOR") {
        return "CA";
    } else if (role === "MASTER_AGGREGATOR") {
        return "MA";
    }

    return role;
};

export const humanizeNodePartitionInstanceStatus = (node: DerivedNode) => {
    if (node.partitionInstanceStatus === "PARTITIONS_ONLINE") {
        return "Partitions Online";
    } else if (
        node.partitionInstanceStatus === "PARTITIONS_OFFLINE_RECOVERING"
    ) {
        return "Partitions Offline Recovering";
    } else if (node.partitionInstanceStatus === "PARTITIONS_OFFLINE") {
        return "Partitions Offline";
    } else if (node.partitionInstanceStatus === "PARTITIONS_UNKNOWN") {
        return "Unknown Partitions Status";
    }

    return node.partitionInstanceStatus;
};

// Takes in a node and returns its pair in the `host:port`
// format. If the node is not a leaf or it does not have
// a pair, then this returns undefined.
export const getNodePair = (node: Node) => {
    if (node.role === "LEAF" && node.pairHost && node.pairPort) {
        return `${node.pairHost}:${node.pairPort}`;
    }
};

export function formatNodeAddress({
    host,
    port,
}: {
    host: string;
    port: BigNumber;
}) {
    return `${host}:${port}`;
}

export function parseNodeAddress(
    nodeAddress: string
): Maybe<{ host: string; port: BigNumber }> {
    const splitString = nodeAddress.split(":");

    if (splitString.length !== 2) {
        return undefined;
    }

    const [host, port] = splitString;

    return { host, port: new BigNumber(port) };
}

export function getEmptyNodeLiveMonitoringMetrics(): NodeLiveMonitoringMetrics {
    return {
        totalCpuUsagePercent: new LELoading(),
        usedMemoryB: new LELoading(),
        memoryLimitB: new LELoading(),
        usedDiskB: new LELoading(),
        totalDiskB: new LELoading(),
    };
}

export type NodeRatioMetric = {
    ratio: number;
    total: BigNumber;
    used: BigNumber;
};

export const getNodeMemoryUsage = (
    node: DerivedNode
): LoadingError<NodeRatioMetric> => {
    const {
        liveMonitoring: { memoryLimitB, usedMemoryB },
    } = node;

    if (usedMemoryB.isLoading() && memoryLimitB.isLoading()) {
        return new LELoading();
    } else if (
        memoryLimitB.isLoading() ||
        usedMemoryB.isLoading() ||
        usedMemoryB.isError() ||
        memoryLimitB.isError() ||
        memoryLimitB.value.isZero()
    ) {
        return new LEError();
    } else {
        const percentValue = usedMemoryB.value.dividedBy(memoryLimitB.value);

        return new LESuccess({
            ratio: percentValue.toNumber(),
            total: memoryLimitB.value,
            used: usedMemoryB.value,
        });
    }
};

export const getNodeDiskUsage = (
    node: DerivedNode
): LoadingError<NodeRatioMetric> => {
    const {
        liveMonitoring: { usedDiskB, totalDiskB },
    } = node;

    if (usedDiskB.isLoading() && totalDiskB.isLoading()) {
        return new LELoading();
    } else if (
        totalDiskB.isLoading() ||
        usedDiskB.isLoading() ||
        usedDiskB.isError() ||
        totalDiskB.isError() ||
        totalDiskB.value.isZero()
    ) {
        return new LEError();
    } else {
        const percentValue = usedDiskB.value.dividedBy(totalDiskB.value);

        return new LESuccess({
            ratio: percentValue.toNumber(),
            total: totalDiskB.value,
            used: usedDiskB.value,
        });
    }
};
