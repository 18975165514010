import { SimpleAction } from "./types";

import { TabTitle } from "data/reducers/bottom-panel";

export type ChangeBottomPanelHeightAction = SimpleAction<
    "CHANGE_BOTTOM_PANEL_HEIGHT",
    {
        height: number;
    }
>;

export const changeBottomPanelHeight = (payload: {
    height: number;
}): ChangeBottomPanelHeightAction => ({
    type: "CHANGE_BOTTOM_PANEL_HEIGHT",
    error: false,
    payload,
});

export type ChangeBottomPanelTabAction = SimpleAction<
    "CHANGE_BOTTOM_PANEL_TAB",
    {
        tab: TabTitle;
    }
>;

export const changeBottomPanelTab = (payload: {
    tab: TabTitle;
}): ChangeBottomPanelTabAction => ({
    type: "CHANGE_BOTTOM_PANEL_TAB",
    error: false,
    payload,
});
