import * as React from "react";

import FullScreen from "view/components/fullscreen";
import InternalLink from "view/components/internal-link";

import BANNER_LOGO from "assets/images/banner-logo.svg";

import "./wrapper.scss";

type Props = {
    children: React.ReactNode;
};

// This component is wrapped around some of the /connect pages, namely the
// clusters list page and the connect-to-cluster page.
export default function ConnectWrapper({ children }: Props) {
    const logo = <img src={BANNER_LOGO} alt="MemSQL Studio" />;

    // When `window.ROOT_REDIRECT` is defined, the top banner logo points to
    // that instead of the home page (which doesn't exist).
    let logoWrapper: React.ReactNode;
    if (window.ROOT_REDIRECT) {
        logoWrapper = <a href={window.ROOT_REDIRECT}>{logo}</a>;
    } else {
        logoWrapper = (
            <InternalLink routeInfo={{ name: "connect" }} category="connect">
                {logo}
            </InternalLink>
        );
    }

    return (
        <FullScreen className="connect-wrapper">
            <div className="banner">{logoWrapper}</div>

            <div className="children-container">{children}</div>
        </FullScreen>
    );
}
