import * as React from "react";

import DashboardHeader from "view/dashboard/header";

import ClusterHealthCard from "view/dashboard/cluster-health-card";
import PipelinesCard from "view/dashboard/pipelines-card";
import DatabaseUsageCard from "view/dashboard/database-usage-card";
import ClusterUsageCard from "view/dashboard/cluster-usage-card";
import FeedbackCard from "view/dashboard/feedback-card";

import "./page-dashboard.scss";

type Props = {};

export default class DashboardPage extends React.Component<Props> {
    render() {
        return (
            <div className="dashboard-page">
                <DashboardHeader />

                <div className="cards">
                    <ClusterHealthCard className="card" />
                    <ClusterUsageCard className="card" />
                    <PipelinesCard className="card" />
                    <DatabaseUsageCard className="card" />
                    <FeedbackCard />
                </div>
            </div>
        );
    }
}
