import { Maybe } from "util/maybe";
import { LicenseInfoAction } from "data/actions";
import { LicenseInfo } from "data/models";

import { LSM } from "util/loading-state-machine";

import {
    getInitialState,
    reduce as reduceLoadingStateMachine,
} from "util/loading-state-machine";

type Actions = LicenseInfoAction;

export type LicenseState = {
    licenseInfo: LSM<Maybe<LicenseInfo>, { message: string }>;
};

const initialState = {
    licenseInfo: getInitialState(),
};

export default (state: LicenseState = initialState, action: Actions) => {
    switch (action.type) {
        case "LICENSE_INFO":
            {
                state = {
                    ...state,
                    licenseInfo: reduceLoadingStateMachine(
                        state.licenseInfo,
                        action
                    ),
                };
            }

            break;
    }

    return state;
};
