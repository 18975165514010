import { Maybe } from "util/maybe";

import { DispatchFunction, State as ReduxState } from "data";
import { QueryExecutorState } from "worker/net/query-executor";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { selectMemsqlOnline } from "data/selectors/system-status";

import {
    changeEditorHeight,
    changeEditorWidth,
} from "data/actions/query-editor";
import { DEFAULT_EDITOR_HEIGHT_FRACTION } from "data/reducers/query-editor";

import ResizeDetector from "view/components/resize-detector";
import { ResizableContainer } from "view/components/resizable-container";
import EditorSchemaTreeContainer from "view/editor/schema-tree";
import EditorBufferContainer from "view/editor/buffer-container";
import HeaderContainer from "view/editor/header/header-container";
import EditorOutputContainer from "view/editor/results-output/output-container";
import QueryGroupModalContainer from "view/editor/query-group-modal";
import { TAB_HEIGHT } from "view/components/tab";

import "./page-editor.scss";

// The default allocation of widths is 70% editor and output area (left pane),
// 30% schema tree (right pane)...
const DEFAULT_EDITOR_WIDTH_FRACTION = 0.7;
// except that the schema tree width is capped by this many pixels.
const DEFAULT_MAX_SCHEMA_TREE_WIDTH = 275;

// The schema tree can never be resized to be narrower than this.
const MIN_SCHEMA_TREE_WIDTH = 32;

// The schema tree can never be resized to be wider than this.
const ABSOLUTE_MAX_SCHEMA_TREE_WIDTH = 400;

// When the schema tree is narrower than this, it is rendered as collapsed, and
// is snapped to the collapsed state if the user releases the drag handle.
const SCHEMA_TREE_COLLAPSE_THRESHOLD = 150;

const isSchemaTreeCollapsed = (schemaTreeWidth: number) =>
    schemaTreeWidth < SCHEMA_TREE_COLLAPSE_THRESHOLD;

// Given the inner page width, compute an editor width fraction that opens the
// schema tree to a good default width.

// If the page is so narrow that the default width is not enough to open the
// schema tree, but there is enough room to open the schema tree AND preferOpen
// is true, return an editor width fraction that's just enough to open the
// schema tree. Otherwise (if even all the room is not enough to open the
// schema tree, or if preferOpen is false), collapse the schema tree.
const defaultEditorWidthFraction = ({
    innerPageWidth,
    preferOpen,
}: {
    innerPageWidth: number;
    preferOpen: boolean;
}) => {
    if (!innerPageWidth) {
        return DEFAULT_EDITOR_WIDTH_FRACTION;
    }

    const editorWidth = Math.max(
        DEFAULT_EDITOR_WIDTH_FRACTION * innerPageWidth,
        innerPageWidth - DEFAULT_MAX_SCHEMA_TREE_WIDTH
    );

    // Is this insufficient to open the schema tree?
    if (isSchemaTreeCollapsed(innerPageWidth - editorWidth)) {
        // Do we strongly prefer to open the schema tree, and is there enough
        // room to to open the schema tree at all?
        if (preferOpen && !isSchemaTreeCollapsed(innerPageWidth)) {
            // If so, give it just enough width to open it.
            return 1 - SCHEMA_TREE_COLLAPSE_THRESHOLD / innerPageWidth;
        }

        // Otherwise don't bother; just keep the schema tree collapsed and minimized.
        return 1;
    }

    return editorWidth / innerPageWidth;
};

type StateProps = {
    bottomPanelHeight: number;
    connectionState: Maybe<QueryExecutorState>;
    memsqlOnline: boolean;
    selectedDatabase: Maybe<string>;

    // 0 .. 1, fraction of panes' width; undefined when this page first loads
    editorWidthFraction: Maybe<number>;

    // 0 .. 1, fraction of panes' height minus table header height
    editorHeightFraction: number;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

type State = {
    // Width of the panes div.
    innerPageWidth: number;

    // Height of the content div, excluding the header and the bottom panel.
    contentHeight: number;
};

class EditorPage extends React.Component<Props, State> {
    state: State = {
        innerPageWidth: 0,
        contentHeight: 0,
    };

    // When something causes the height of the Monaco Editor to change, we need
    // to dispatch a fake window resize event since the Monaco Editor only
    // listens to that in order to recalculate its dimensions.
    componentDidUpdate(prevProps: Props) {
        if (prevProps.bottomPanelHeight !== this.props.bottomPanelHeight) {
            this.fakeWindowResize();
        }
    }

    // To make the Monaco editor (and other components, but mainly the editor)
    // recalculate dimensions more quickly when we mount, we pass the leading
    // argument when debouncing, so that the first calls to handlePaneResize
    // and fakeWindowResize will cause a resize without extra delay.
    fakeWindowResize = _.debounce(
        () => {
            window.dispatchEvent(new Event("resize"));
        },
        100,
        { leading: true }
    );

    handlePaneResize = _.debounce(
        (innerPageWidth: number, _innerPageHeight: number) => {
            if (innerPageWidth !== this.state.innerPageWidth) {
                if (
                    innerPageWidth &&
                    this.props.editorWidthFraction === undefined
                ) {
                    // Initialize width fraction using measured width when this
                    // page first loads.
                    this.props.dispatch(
                        changeEditorWidth({
                            width: defaultEditorWidthFraction({
                                innerPageWidth,
                                preferOpen: false,
                            }),
                        })
                    );
                }

                this.setState({
                    innerPageWidth,
                });

                // Emit fake window resize event, since Monaco Editor needs
                // such an event to recalculate its dimensions.
                this.fakeWindowResize();
            }
        },
        50,
        { leading: true }
    );

    handleContentResize = _.debounce(
        (_contentWidth: number, contentHeight: number) => {
            if (contentHeight !== this.state.contentHeight) {
                this.setState({
                    contentHeight,
                });

                // Emit fake window resize event, since Monaco Editor needs
                // such an event to recalculate its dimensions.
                this.fakeWindowResize();
            }
        },
        50,
        { leading: true }
    );

    handleOutputResizeHeightFraction = (outputHeightFraction: number) => {
        this.props.dispatch(
            changeEditorHeight({ height: 1 - outputHeightFraction })
        );

        _.defer(() => this.fakeWindowResize());
    };

    handleOutputResizeHeight = (heightPixels: number) => {
        const heightFraction = _.clamp(
            1 -
                (heightPixels - TAB_HEIGHT) /
                    (this.state.contentHeight - TAB_HEIGHT),
            0,
            1
        );

        this.props.dispatch(changeEditorHeight({ height: heightFraction }));

        this.fakeWindowResize();
    };

    handleSchemaTreeExpand = () => {
        const { innerPageWidth } = this.state;

        this.props.dispatch(
            changeEditorWidth({
                width: defaultEditorWidthFraction({
                    innerPageWidth,
                    preferOpen: true,
                }),
            })
        );

        _.defer(this.fakeWindowResize);
    };

    handleSchemaTreeCollapse = () => {
        this.props.dispatch(changeEditorWidth({ width: 1 }));

        _.defer(this.fakeWindowResize);
    };

    handleSchemaTreeResizeWidth = (widthPixels: number) => {
        const clampedWidthPixels = Math.min(
            widthPixels,
            ABSOLUTE_MAX_SCHEMA_TREE_WIDTH
        );

        const editorWidthFraction = _.clamp(
            1 - clampedWidthPixels / this.state.innerPageWidth,
            0,
            1
        );

        this.props.dispatch(changeEditorWidth({ width: editorWidthFraction }));

        this.fakeWindowResize();
    };

    handleSchemaTreeResizeComplete = (movedConsiderableAmount: boolean) => {
        const { innerPageWidth } = this.state;
        const { schemaTreeWidth } = this.computePaneWidths();
        const collapsed = isSchemaTreeCollapsed(schemaTreeWidth);

        if (movedConsiderableAmount) {
            if (collapsed) {
                this.props.dispatch(changeEditorWidth({ width: 1 }));
            }
        } else {
            if (collapsed) {
                this.props.dispatch(
                    changeEditorWidth({
                        width: defaultEditorWidthFraction({
                            innerPageWidth,
                            preferOpen: true,
                        }),
                    })
                );
            } else {
                this.props.dispatch(changeEditorWidth({ width: 1 }));
            }
        }

        this.fakeWindowResize();
    };

    computePaneHeights = () => {
        const { editorHeightFraction } = this.props;
        const { contentHeight } = this.state;

        const bufferHeight =
            editorHeightFraction * Math.max(contentHeight - TAB_HEIGHT, 0);
        const outputHeight = contentHeight - bufferHeight;

        return { bufferHeight, outputHeight };
    };

    computePaneWidths = () => {
        const { editorWidthFraction } = this.props;
        const { innerPageWidth } = this.state;

        const frac =
            editorWidthFraction === undefined
                ? defaultEditorWidthFraction({
                      innerPageWidth,
                      preferOpen: false,
                  })
                : editorWidthFraction;
        const editorWidth = _.clamp(
            frac * innerPageWidth,
            innerPageWidth - ABSOLUTE_MAX_SCHEMA_TREE_WIDTH,
            innerPageWidth - MIN_SCHEMA_TREE_WIDTH
        );
        const schemaTreeWidth = innerPageWidth - editorWidth;

        return { editorWidth, schemaTreeWidth };
    };

    render() {
        const { innerPageWidth } = this.state;
        const { bufferHeight, outputHeight } = this.computePaneHeights();
        const { editorWidth, schemaTreeWidth } = this.computePaneWidths();

        const outputHeightFraction = 1 - this.props.editorHeightFraction;

        return (
            <div className="editor-page-editor">
                <div className="panes">
                    <ResizeDetector onResize={this.handlePaneResize} />

                    <ResizableContainer
                        className="editor-pane"
                        width={editorWidth}
                    >
                        <HeaderContainer />

                        <div className="editor-content">
                            <ResizeDetector
                                onResize={this.handleContentResize}
                            />

                            {/* We repeat the width because the Monaco editor needs
                            an exact width and height on its parent element to
                            behave nicely. */}
                            <ResizableContainer
                                className="buffer-container"
                                height={bufferHeight}
                                width={editorWidth}
                            >
                                <EditorBufferContainer />
                            </ResizableContainer>
                            <ResizableContainer
                                height={outputHeight}
                                width={editorWidth}
                            >
                                <EditorOutputContainer
                                    heightFraction={outputHeightFraction}
                                    restoreHeightFraction={
                                        1 - DEFAULT_EDITOR_HEIGHT_FRACTION
                                    }
                                    onResizeHeight={
                                        this.handleOutputResizeHeight
                                    }
                                    onResizeHeightFraction={
                                        this.handleOutputResizeHeightFraction
                                    }
                                />
                            </ResizableContainer>
                        </div>
                    </ResizableContainer>

                    <ResizableContainer
                        className="schema-tree-pane"
                        width={schemaTreeWidth}
                    >
                        <EditorSchemaTreeContainer
                            onExpand={this.handleSchemaTreeExpand}
                            onCollapse={this.handleSchemaTreeCollapse}
                            onResizeWidth={this.handleSchemaTreeResizeWidth}
                            onResizeComplete={
                                this.handleSchemaTreeResizeComplete
                            }
                            collapsed={isSchemaTreeCollapsed(schemaTreeWidth)}
                            canExpand={
                                defaultEditorWidthFraction({
                                    innerPageWidth,
                                    preferOpen: true,
                                }) < 1
                            }
                        />
                    </ResizableContainer>
                </div>

                <QueryGroupModalContainer />
            </div>
        );
    }
}

export default connect(
    (s: ReduxState): StateProps => ({
        bottomPanelHeight: s.bottomPanel.height,
        connectionState: s.queryEditor.connectionState,
        memsqlOnline: selectMemsqlOnline(s),
        selectedDatabase: s.queryEditor.selectedDatabase,
        editorWidthFraction: s.queryEditor.dimensions.editorWidthFraction,
        editorHeightFraction: s.queryEditor.dimensions.editorHeightFraction,
    })
)(EditorPage);
