import { Maybe } from "util/maybe";
import { ConnectAction } from "data/actions";
import { State as Route, Router } from "router5";
import { ClusterId, Cluster } from "data/models";
import { State as ReduxState, DispatchFunction } from "data";

import * as React from "react";
import _ from "lodash";
import { endsWithSegment } from "router5-helpers";
import { compose } from "redux";
import { createRouteNodeSelector } from "redux-router5";
import { connect } from "react-redux";
import { withRouter } from "react-router5";

import { initConnection } from "worker/api/connection";
import { selectClusters } from "data/selectors";

import ClustersList from "view/connect/clusters-list";
import AddNewClusterPage from "view/connect/add-new-cluster";
import ConnectToCluster from "view/connect/connect-to-cluster";
import ConnectWrapper from "view/connect/wrapper";

import Sentry from "util/sentry";
import { readStudioConfig } from "util/studio-config";
import * as analytics from "util/segment";

type StateProps = {
    allowClusterMutations: boolean;
    route: Route;
    clusters: Maybe<Array<Cluster>>;
};

type Props = StateProps & {
    router: Router;
    dispatch: DispatchFunction;
};

export type OnSubmitSuccess = (id: ClusterId) => void;

class ConnectController extends React.Component<Props> {
    componentWillMount() {
        const {
            dispatch,
            route: {
                params: { clusterId },
            },
        } = this.props;

        if (clusterId) {
            // Check if there are auto-login credentials in the studio config.
            // If there are, use them to try to auto-connect, and ignore any
            // errors.

            const config = readStudioConfig();
            const clusterConfig = config.clusters[clusterId];

            if (clusterConfig && clusterConfig.autoLogin) {
                const { username, password } = clusterConfig.autoLogin;

                Promise.resolve(
                    dispatch(initConnection({
                        kind: "CONNECT_CONFIG",
                        clusterId,
                        user: username,
                        password,
                    }) as ConnectAction)
                ).then((a: ConnectAction) => {
                    if (!a.error) {
                        this.handleConnect(clusterId);
                    }
                });
            }
        }
    }

    componentDidMount() {
        // We need to reset the state of every cluster-specific reducer to
        // its initial state.
        this.props.dispatch({
            type: "RESET_STATE",
            error: false,
            payload: {},
        });
    }

    // To be executed after a successful connection to a cluster or the successful
    // creation of a cluster.
    handleConnect = (clusterId: string) => {
        const {
            router,
            clusters,
            route: {
                params: { redirect },
            },
        } = this.props;

        if (clusters) {
            const cluster = _.find(clusters, { id: clusterId });

            if (cluster) {
                Sentry.setClusterHostname(cluster.hostname);
                Sentry.setClusterPort(cluster.port);
            }
        }

        if (redirect) {
            const redirectInfo = JSON.parse(redirect);
            if (redirectInfo && redirectInfo.name && redirectInfo.params) {
                router.navigate(redirectInfo.name, redirectInfo.params);
                return;
            }
        }

        router.navigate("cluster.dashboard", { clusterId });
    };

    handleAddClusterCancel = () => {
        const { router } = this.props;

        router.navigate("connect");

        analytics.trackInternalLinkClick("connect");
    };

    render() {
        const {
            route,
            route: {
                params: { clusterId },
            },
            allowClusterMutations,
        } = this.props;

        const testRoute = endsWithSegment(route);
        let page: React.ReactNode = null;

        // Creating a new cluster is only valid if allowClusterMutations is set
        // to true.
        if (testRoute("new") && allowClusterMutations) {
            return (
                <AddNewClusterPage
                    onSubmitSuccess={this.handleConnect}
                    onCancel={this.handleAddClusterCancel}
                />
            );
        } else if (testRoute("cluster")) {
            page = (
                <ConnectToCluster
                    clusterId={clusterId}
                    onSubmitSuccess={this.handleConnect}
                />
            );
        } else if (testRoute("connect")) {
            page = <ClustersList onSubmitSuccess={this.handleConnect} />;
        } else {
            page = (
                <div>
                    <h1>404 not found</h1>
                </div>
            );
        }

        return <ConnectWrapper>{page}</ConnectWrapper>;
    }
}

export default compose(
    withRouter,
    connect(
        (s: ReduxState): StateProps => {
            const routeNodeSelector = createRouteNodeSelector("connect");

            return {
                allowClusterMutations: s.clusters.allowClusterMutations,
                route: routeNodeSelector(s).route,
                clusters: selectClusters(s),
            };
        }
    )
)(ConnectController);
