import { NotificationLevel } from "view/common/notification-manager/types";

import * as React from "react";
import classnames from "classnames";

import Icon from "view/components/icon";

import "./notification-bar.scss";

const ICONS: { [k in NotificationLevel]: string } = {
    CRITICAL: "exclamation-triangle",
    INFO: "info-circle",
    WARNING: "info-circle",
};

type Props = {
    level: NotificationLevel;
    message: React.ReactNode;
    onClose?: () => void;
};

const NotificationBar = (props: Props) => {
    const level = props.level.toLowerCase();

    const classes = classnames("common-notification-bar", level);

    return (
        <div className={classes}>
            <div className="notification-bar-inner">
                <Icon
                    rightMargin
                    icon={ICONS[props.level]}
                    iconType="regular"
                />
                {props.message}
            </div>

            {props.onClose && (
                <span
                    onClick={props.onClose}
                    className="icon-close"
                    role="button"
                >
                    <Icon iconType="regular" icon="times" />
                </span>
            )}
        </div>
    );
};

export default NotificationBar;
