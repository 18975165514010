import { Maybe } from "util/maybe";

import { StaticConnectionId } from "worker/net/connection-manager";

import {
    QueryExecutorMsg,
    QueryExecutorState,
} from "worker/net/query-executor";

import {
    ConnectConsoleAction,
    ConsoleQueryAction,
    ConsoleEmptyQueryAction,
    ConsoleEditQueryAction,
    ConsoleShiftQueryAction,
    InitQueryExecutorAction,
    QueryExecutorMessageAction,
} from "data/actions";

import { FieldList } from "mysqljs";

import _ from "lodash";

import {
    truncateBuffer,
    bufferWithQuery,
    bufferWithFields,
    bufferWithRow,
    bufferWithError,
    bufferWithSuccessMetrics,
    cleanUpLastQuery,
} from "util/sql-buffer";

import assign from "util/assign";

import { isVerticalQuery } from "util/sql-query";

type Actions =
    | ConnectConsoleAction
    | ConsoleEmptyQueryAction
    | ConsoleQueryAction
    | InitQueryExecutorAction
    | QueryExecutorMessageAction
    | ConsoleEditQueryAction
    | ConsoleShiftQueryAction;

// Metadata about the currently running query
export type CurrentQueryInfo = {
    fields?: FieldList;
    longestFieldLength?: Maybe<number>;
    vertical: boolean;
    rowsPrinted: number;
    fieldsPrinted: boolean;
};

export type ConsoleState = {
    connectionId: StaticConnectionId;

    // While this is true, a listener will try to keep the query executor
    // connected by initializing as needed.
    shouldTryConnect: boolean;

    initQueryExecutorError: Maybe<Error>;
    queryExecutorState: Maybe<QueryExecutorState>;
    buffer: string;
    history: Array<string>;
    activeHistory: Array<string>;
    editingQueryIndex: number;

    currentQuery?: CurrentQueryInfo;

    selectedDatabase?: string;
};

// Once the buffer is longer than this length we will trim lines off the
// beginning until we reach this length.
const MAX_SCROLLBACK_LENGTH = 100000;

// Don't keep around more than this many
const MAX_HISTORY_LENGTH = 500;

export const CONNECTION_ID = "CONSOLE";

const initialState: ConsoleState = {
    connectionId: CONNECTION_ID,
    buffer: "",
    shouldTryConnect: false,
    initQueryExecutorError: undefined,
    queryExecutorState: undefined,
    history: [],
    activeHistory: [""],
    editingQueryIndex: 0,
};

const appendHistory = (history: Array<string>, query: string) => {
    // If the user runs the same query multiple times don't steamroll the
    // history.
    if (history[0] === query) {
        return history;
    }

    history = [query, ...history];
    if (history.length > MAX_HISTORY_LENGTH) {
        history = history.slice(0, MAX_HISTORY_LENGTH);
    }
    return history;
};

export default (state: ConsoleState = initialState, action: Actions) => {
    switch (action.type) {
        case "CONNECT_CONSOLE": {
            state = { ...state, shouldTryConnect: true };
            break;
        }

        case "CONSOLE_EDIT_QUERY": {
            const { query } = action.payload;
            const { activeHistory, editingQueryIndex } = state;
            activeHistory[editingQueryIndex] = query;
            state = assign(state, { activeHistory });
            break;
        }

        case "CONSOLE_SHIFT_QUERY": {
            const { direction } = action.payload;
            const { activeHistory, editingQueryIndex } = state;
            const newIdx = Math.max(
                0,
                Math.min(
                    activeHistory.length - 1,
                    editingQueryIndex + (direction === "UP" ? 1 : -1)
                )
            );
            state = assign(state, { editingQueryIndex: newIdx });
            break;
        }

        case "CONSOLE_EMPTY_QUERY": {
            state = assign(state, {
                activeHistory: ["", ...state.history],
                editingQueryIndex: 0,
                buffer: truncateBuffer(
                    bufferWithQuery(state.buffer, ""),
                    MAX_SCROLLBACK_LENGTH
                ),
            });
            break;
        }

        case "CONSOLE_QUERY": {
            const { query, automated } = action.payload;

            // If this query was automated (i.e. not run by the user), then
            // we restore whatever the user had written in the console so
            // that they don't lose it.
            let savedQuery = "";
            if (automated) {
                savedQuery = state.activeHistory[state.editingQueryIndex];
            }

            const history = appendHistory(state.history, query);

            state = assign(state, {
                history,
                activeHistory: [savedQuery, ...history],
                editingQueryIndex: 0,
                buffer: truncateBuffer(
                    bufferWithQuery(state.buffer, query),
                    MAX_SCROLLBACK_LENGTH
                ),
            });
            break;
        }

        case "INIT_QUERY_EXECUTOR": {
            if (action.meta.id !== state.connectionId) {
                break;
            }

            state = {
                ...state,
                initQueryExecutorError: action.error
                    ? action.payload
                    : undefined,
            };

            break;
        }

        case "QUERY_EXECUTOR_MESSAGE": {
            if (action.payload.id !== state.connectionId) {
                break;
            }

            state = handleQueryExecutorMessage(state, action.payload.msg);

            break;
        }
    }

    return state;
};

const handleQueryExecutorMessage = (
    state: ConsoleState,
    msg: QueryExecutorMsg
): ConsoleState => {
    switch (msg.kind) {
        case "queryExecutorState": {
            let buffer = state.buffer;
            if (msg.errorMsg) {
                buffer = truncateBuffer(
                    state.buffer + "\n" + msg.errorMsg,
                    MAX_SCROLLBACK_LENGTH
                );
            }

            state = assign(state, {
                queryExecutorState: msg.state,
                buffer,
            });

            break;
        }

        case "queryRun": {
            state = assign(state, {
                currentQuery: {
                    vertical: isVerticalQuery(msg.query.text),
                    rowsPrinted: 0,
                    fieldsPrinted: false,
                },
            });
            break;
        }

        case "queryMessage": {
            switch (msg.queryMsg.kind) {
                case "resultSet": {
                    if (!state.currentQuery) {
                        throw new Error(
                            "Expected currentQuery to exist in the state."
                        );
                    }

                    const currentQuery = state.currentQuery;
                    const { fields } = msg.queryMsg;

                    const longestFieldLength = _(fields)
                        .map(field => field.name.length)
                        .max();

                    const buffer = cleanUpLastQuery(state.buffer, currentQuery);

                    const newQueryInfo = {
                        ...currentQuery,
                        fields,
                        longestFieldLength,
                        rowsPrinted: 0,
                        fieldsPrinted: false,
                    };

                    state = {
                        ...state,
                        currentQuery: newQueryInfo,
                        buffer,
                    };

                    break;
                }

                case "rows": {
                    if (!state.currentQuery) {
                        throw new Error(
                            "Expected currentQuery to exist in the state."
                        );
                    }

                    const currentQuery = state.currentQuery;

                    // Add the fields that we have yet to print to the buffer
                    // before adding the rows.
                    let buffer = state.buffer;
                    if (!currentQuery.fieldsPrinted) {
                        buffer = truncateBuffer(
                            bufferWithFields(buffer, state.currentQuery),
                            MAX_SCROLLBACK_LENGTH
                        );
                    }

                    const bufferWithRows = _.reduce(
                        msg.queryMsg.rows,
                        (buf, row, idx) =>
                            bufferWithRow(
                                buf,
                                row,
                                currentQuery,
                                currentQuery.rowsPrinted + idx
                            ),
                        buffer
                    );

                    state = {
                        ...state,
                        currentQuery: assign(currentQuery, {
                            rowsPrinted: currentQuery.rowsPrinted + 1,
                            fieldsPrinted: true,
                        }),
                        buffer: truncateBuffer(
                            bufferWithRows,
                            MAX_SCROLLBACK_LENGTH
                        ),
                    };

                    break;
                }

                case "queryEnd": {
                    if (!state.currentQuery) {
                        throw new Error(
                            "Expected currentQuery to exist in the state."
                        );
                    }

                    const { metrics, status } = msg.queryMsg;

                    if (status.state === "error") {
                        state = _.omit(
                            {
                                ...state,
                                buffer: truncateBuffer(
                                    bufferWithError(
                                        state.buffer,
                                        status,
                                        state.currentQuery
                                    ),
                                    MAX_SCROLLBACK_LENGTH
                                ),
                            },
                            "currentQuery"
                        );
                    } else {
                        state = _.omit(
                            {
                                ...state,
                                buffer: truncateBuffer(
                                    bufferWithSuccessMetrics(
                                        state.buffer,
                                        state.currentQuery,
                                        metrics
                                    ),
                                    MAX_SCROLLBACK_LENGTH
                                ),
                            },
                            "currentQuery"
                        );
                    }

                    break;
                }
            }

            break;
        }

        case "selectedDatabaseChanged": {
            state = {
                ...state,
                selectedDatabase: msg.database,
            };

            break;
        }
    }

    return state;
};
