import { Maybe } from "util/maybe";

export const requiredFieldValidator = (value: any): Maybe<string> =>
    value ? undefined : "Field is required.";

export const maxLengthFieldValidator = (maxLength: number) => (
    value: string
): Maybe<string> =>
    value.length > maxLength
        ? `The maximum length is ${maxLength} characters.`
        : undefined;

export function regexFieldValidator(regex: RegExp, message: string) {
    return function(value: string): Maybe<string> {
        return !regex.test(value) ? message : undefined;
    };
}

// validating email is controversial; we will err towards accepting inputs. for
// fun reading, see
// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
export const optionalEmailValidator = (value: string): Maybe<string> => {
    if (value && value.indexOf("@") === -1) {
        return "Please enter a valid email address.";
    }
};

export function combineValidators<T>(
    ...validators: Array<(t: T) => Maybe<string>>
): (value: T) => Maybe<string> {
    return val => {
        for (let i = 0; i < validators.length; i++) {
            const result = validators[i](val);

            if (result) {
                return result;
            }
        }

        // If no validators raised an error, then return undefined because that is what
        // Redux Form expects.
    };
}
