import { TableSort } from "util/sort";
import { SimpleAction, Action } from "./types";
import { Loading } from "util/loading-state-machine";

import { Node, NodeLiveMonitoringMetrics } from "data/models";

export type TopologyPayload = {
    nodes: Array<Node>;
    deltaTimeS: number;
};

export type TopologyAction = Action<
    "TOPOLOGY",
    Loading<TopologyPayload>,
    string
>;

export const buildTopologyAction = (
    payload: TopologyPayload
): TopologyAction => ({
    type: "TOPOLOGY",
    payload: {
        loading: false,
        data: payload,
    },
    error: false,
});

export type SortNodesAction = SimpleAction<"SORT_NODES", TableSort>;

export const sortNodes = (payload: TableSort): SortNodesAction => ({
    type: "SORT_NODES",
    error: false,
    payload,
});

export type SortNodePartitionInstancesAction = SimpleAction<
    "SORT_NODE_PARTITION_INSTANCES",
    TableSort
>;

export const sortNodePartitionInstances = (
    payload: TableSort
): SortNodePartitionInstancesAction => ({
    type: "SORT_NODE_PARTITION_INSTANCES",
    error: false,
    payload,
});

// The live monitoring action may have partial results which will be fixed by
// the reducer.
export type NodesLiveMonitoringActionPayload = {
    [nodeAddress: string]: Partial<NodeLiveMonitoringMetrics>;
};

export type NodesLiveMonitoringAction = Action<
    "NODES_LIVE_MONITORING",
    NodesLiveMonitoringActionPayload,
    string
>;

export type NodesLiveMonitoringStopAction = SimpleAction<
    "NODES_LIVE_MONITORING_STOP",
    {}
>;
