import { Connection } from "mysqljs";

import { Version } from "util/version";
import { select } from "util/query";

export const GET_MEMSQL_VERSION = `SHOW GLOBAL VARIABLES LIKE 'memsql_version'`;

export type SQLGetMemSQLVersionRow = {
    Variable_name: "memsql_version";
    Value: string;
};

export function fetchMemsqlVersion(conn: Connection): Promise<Version> {
    return select(conn, GET_MEMSQL_VERSION).then(
        (sqlGetMemSQLVersionRow: Array<SQLGetMemSQLVersionRow>) => {
            if (sqlGetMemSQLVersionRow.length !== 1) {
                throw new Error(
                    "This MemSQL version query should only return one row."
                );
            }

            return Version.fromString(sqlGetMemSQLVersionRow[0].Value);
        }
    );
}
