import BigNumber from "vendor/bignumber.js/bignumber";

export const BigNumberUtils = {
    // https://github.com/MikeMcl/bignumber.js/wiki/Add-ceil,-floor,-round-and-trunc
    roundBigNumber: (num: BigNumber) => {
        return num.integerValue(BigNumber.ROUND_HALF_CEIL);
    },

    mean: (...nums: Array<BigNumber>) => {
        if (nums.length === 0) {
            return new BigNumber(0);
        }

        return BigNumber.sum(...nums).dividedBy(nums.length);
    },
};
