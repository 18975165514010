import { TipProps } from "view/components/tip";

import React from "react";
import _ from "lodash";

import classnames from "classnames";

import Tip from "view/components/tip";

import "./radio-toggle-button.scss";

type ToggleEntry<ToggleValue> = {
    value: ToggleValue;
    text?: string;
    tipProps?: Partial<TipProps> & {
        tooltip: string;
    };
};

type Props<ToggleValue> = {
    entries: Array<ToggleEntry<ToggleValue>>;
    value: ToggleValue;
    onChange: (value: ToggleValue) => void;
    className?: string;
};

export default class RadioToggleButton<
    ToggleValue extends string
> extends React.PureComponent<Props<ToggleValue>> {
    render() {
        const {
            entries,
            value: currentValue,
            onChange,
            className,
        } = this.props;

        const classes = classnames("components-radio-toggle", className);

        return (
            <div className={classes}>
                {_.map(entries, ({ text, value, tipProps }) => {
                    const itemClasses = classnames(
                        {
                            selected: value === currentValue,
                        },
                        "toggle-item"
                    );

                    if (tipProps) {
                        return (
                            <Tip
                                {...tipProps}
                                key={value}
                                className={itemClasses}
                                tabIndex={0}
                                onClick={() => onChange(value)}
                            >
                                {text || value}
                            </Tip>
                        );
                    } else {
                        return (
                            <div
                                key={value}
                                className={itemClasses}
                                tabIndex={0}
                                onClick={() => onChange(value)}
                            >
                                {text || value}
                            </div>
                        );
                    }
                })}
            </div>
        );
    }
}
