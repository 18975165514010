import { Maybe } from "util/maybe";
import { DispatchFunction } from "data";

import { CursorInfo } from "data/models";
import * as Monaco from "monaco-editor";

import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { RowColumnRange, AbsoluteRange } from "util/range";

import MonacoEditorComponent from "react-monaco-editor";

import { language as memsqlSqlSyntax } from "memsql/memsql-sql-syntax";
import { logError } from "util/logging";
import { COLORS } from "util/colors";

import "./sql-editor.scss";

type Props = {
    value: string;
    onChange: (value: string) => void;
    highlightRange?: AbsoluteRange;
    autoFocus?: boolean;
    // We increment focusCount by 1 when we want to manually tell the sql editor
    // to focus (e.g., after appending a query to sql editor's buffer).
    focusCount: number;
    onCursorChange?: (cursor: CursorInfo) => void;
    onRunQuery?: () => void;
    onRunQueryInNewTab?: () => void;
};

type EditorProps = Props & {
    dispatch: DispatchFunction;
};

type State = {
    decorations: Maybe<Array<string>>;
};

Monaco.languages.register({ id: "memsql-sql" });

// We have to ignore an error in the `setMonarchTokensProvider` call because
// there is a bug in the type definitions for monaco-editor, as it should
// support the keywords and operators properties. Note that even the official
// Monarch language definitions don't match the type definitions.
// https://github.com/Microsoft/monaco-editor/issues/1008
// @ts-ignore
Monaco.languages.setMonarchTokensProvider("memsql-sql", memsqlSqlSyntax);

Monaco.editor.defineTheme("memsql", {
    base: "vs",
    inherit: true,
    rules: [
        { token: "keyword", foreground: COLORS["color-purple-900"] },
        { token: "predefined.sql", foreground: COLORS["color-indigo-600"] },
    ],
    colors: {
        "editorLineNumber.foreground": COLORS["color-neutral-700"],
    },
});

// This RegEx is used to count the number of newlines in a string and figure out
// where to set the highlight when appending a query.
const RE_NEWLINE_MATCH = /\r\n|\r|\n/g;

// This represents the minimum number of newlines to show the minimap.
const MIN_LINES_MINIMAP = 30;

// This method helps us handle Monaco Editor ranges that are 1-indexed (as
// opposed to all of our internal math which is 0-indexed). We treat a
// Monaco.Position as a range that starts and ends in the same place.
const monacoRangeToRowColRange = (
    monacoPosition: Monaco.Selection | Monaco.Position
) => {
    const startLineNumber =
        (monacoPosition as Monaco.Selection).startLineNumber ||
        (monacoPosition as Monaco.Position).lineNumber;
    const startColumn =
        (monacoPosition as Monaco.Selection).startColumn ||
        (monacoPosition as Monaco.Position).column;
    const endLineNumber =
        (monacoPosition as Monaco.Selection).endLineNumber ||
        (monacoPosition as Monaco.Position).lineNumber;
    const endColumn =
        (monacoPosition as Monaco.Selection).endColumn ||
        (monacoPosition as Monaco.Position).column;

    return new RowColumnRange(
        startLineNumber - 1,
        startColumn - 1,
        endLineNumber - 1,
        endColumn - 1
    );
};

function rowColRangeToMonacoRange(rowColRange: RowColumnRange) {
    return new Monaco.Range(
        rowColRange.startRow + 1,
        rowColRange.startColumn + 1,
        rowColRange.endRow + 1,
        rowColRange.endColumn + 1
    );
}

class SQLEditor extends React.Component<EditorProps, State> {
    $editor: Maybe<MonacoEditorComponent>;

    state: State = {
        decorations: undefined,
    };

    handleChange = (value: string) => {
        this.props.onChange(value);
        this.handleCursorChange();
    };

    handleCursorChange = () => {
        const { onCursorChange } = this.props;

        if (onCursorChange && this.$editor) {
            const editor = this.$editor.editor;
            const buffer = editor.getValue();

            const selection = editor.getSelection();
            let selectedRange: Maybe<AbsoluteRange>;
            if (selection && !selection.isEmpty()) {
                selectedRange = monacoRangeToRowColRange(selection).toAbsolute(
                    buffer
                );
            }

            const cursor = editor.getPosition();

            // we only need to compute the actual index if we don't have an
            // explicit selection
            let index = 0;
            if (!selectedRange && cursor) {
                index = monacoRangeToRowColRange(cursor).toAbsolute(buffer)
                    .start;
            }

            onCursorChange({
                index,
                selectedRange,
            });
        }
    };

    handleRunQuery = () => {
        if (this.props.onRunQuery) {
            this.props.onRunQuery();
        }
    };

    handleRunQueryInNewTab = () => {
        if (this.props.onRunQueryInNewTab) {
            this.props.onRunQueryInNewTab();
        }
    };

    updateDimensions = () => {
        if (this.$editor) {
            this.$editor.editor.layout();
        }
    };

    updateSelectionAndFocus() {
        const { highlightRange, value, focusCount } = this.props;
        const editor = this.$editor && this.$editor.editor;

        if (focusCount > 0) {
            if (editor) {
                // these calculations need to be in sync with how we append text
                // (i.e., whether we add spacing before/after queries we append)
                // see appendText in data/actions/query-editor

                // if we have a query we're highlighting, we store the index at
                // which the highlight range starts. we expect the highlight
                // range to be defined.
                let highlightStart;
                if (highlightRange) {
                    highlightStart = highlightRange.start;
                } else {
                    logError(
                        new Error(
                            "Expected highlight range to be defined when updating editor selection."
                        )
                    );
                    return;
                }

                // we also store the number of lines in the buffer.
                const numBufferLines = value.split(RE_NEWLINE_MATCH).length;

                // we calculate the number of lines from the start of the buffer
                // until the start of our highlight range. this is the first
                // line of where we will highlight.
                const startLine = value
                    .substring(0, highlightStart)
                    .split(RE_NEWLINE_MATCH).length;

                // we want to highlight from the `startLine` to the end of the
                // buffer because we append queries at the end. we subtract one
                // because we tack on a new line at the end of appended queries.
                const endLine = numBufferLines - 1;

                // we take the second to last line in the buffer because we tack
                // on a new line at the end of the query, so the actual last
                // string is empty. we want the length of the last line in our
                // query, so we can highlight that entire line.
                const lastString = value.split(RE_NEWLINE_MATCH)[
                    numBufferLines - 2
                ];

                // we add one to lastString.length because Monaco columns start
                // at 1.
                const endColumn = lastString.length + 1;

                editor.setSelection(
                    new Monaco.Range(startLine, 1, endLine, endColumn)
                );
                editor.revealLine(endLine);
                editor.focus();
            }
        }
    }

    onEditorDidMount = (editor: Monaco.editor.IStandaloneCodeEditor) => {
        const { autoFocus, highlightRange } = this.props;

        window.addEventListener("resize", this.updateDimensions);

        editor.onDidChangeCursorPosition(this.handleCursorChange);
        editor.onDidChangeCursorSelection(this.handleCursorChange);

        // The last argument is the context, which is a string to be evaluated
        // as a boolean representing "when can this command be be run". We just
        // pass empty string.
        editor.addCommand(
            Monaco.KeyMod.CtrlCmd | Monaco.KeyCode.Enter,
            this.handleRunQuery,
            ""
        );

        editor.addCommand(
            Monaco.KeyMod.CtrlCmd | Monaco.KeyMod.Shift | Monaco.KeyCode.Enter,
            this.handleRunQueryInNewTab,
            ""
        );

        if (highlightRange) {
            this.updateEditorDecorations(editor, highlightRange);
        }

        if (autoFocus) {
            editor.focus();
        }

        this.updateMinimap();
    };

    componentDidMount() {
        // Immediately call the function inside the debounce, so that the
        // minimap is up-to-date when the editor loads.
        this.updateMinimap.flush();

        this.updateSelectionAndFocus();
    }

    updateEditorDecorations = (
        editor: Monaco.editor.IStandaloneCodeEditor,
        highlightRange: AbsoluteRange
    ) => {
        const { value } = this.props;
        const { decorations } = this.state;

        const range = rowColRangeToMonacoRange(
            highlightRange.toRowColumn(value)
        );

        const newDecorations = [
            {
                range,
                options: {
                    inlineClassName: "selected-query",
                },
            },
        ];

        // Note that Monaco Editor has no declarative way of stating what the
        // current inline decorations are, clients have to imperatively tell it
        // what the old and new decorations are.
        this.setState({
            decorations: editor.deltaDecorations(
                /* oldDecorations*/ decorations || [],
                newDecorations
            ),
        });
    };

    componentWillUnmount() {
        this.props.dispatch({
            type: "QUERY_EDITOR_RESET_FOCUS_COUNT",
            error: false,
            payload: {},
        });

        window.removeEventListener("resize", this.updateDimensions);

        this.updateMinimap.cancel();
    }

    // This function updates the minimap such that it is shown only when the
    // number of lines is greater than a certain value.
    updateMinimap = _.debounce(() => {
        const { value } = this.props;

        const numNewlines = (value.match(RE_NEWLINE_MATCH) || []).length;

        if (this.$editor) {
            this.$editor.editor.updateOptions({
                minimap: { enabled: numNewlines > MIN_LINES_MINIMAP },
            });
        }
    }, 1000);

    componentDidUpdate(prevProps: Props) {
        const { highlightRange, value } = this.props;

        if (highlightRange && this.$editor) {
            // Whether the highlightRange was just set.
            const highlightRangeSet =
                !prevProps.highlightRange && highlightRange;

            // Whether the highlightRange was modified.
            const highlightRangeChanged =
                prevProps.highlightRange &&
                !highlightRange.compare(prevProps.highlightRange);

            if (highlightRangeChanged || highlightRangeSet) {
                this.updateEditorDecorations(
                    this.$editor.editor,
                    highlightRange
                );
            }
        }

        if (value !== prevProps.value) {
            this.updateMinimap();
        }

        if (prevProps.focusCount !== this.props.focusCount) {
            this.updateSelectionAndFocus();
        }
    }

    render() {
        const { value } = this.props;

        return (
            <MonacoEditorComponent
                ref={$editor => {
                    this.$editor = $editor || undefined;
                }}
                language="memsql-sql"
                theme="memsql"
                value={value}
                editorDidMount={this.onEditorDidMount}
                options={{
                    extraEditorClassName: "sql-editor",
                    fontFamily: "Inconsolata",
                    fontSize: 16,
                    selectionHighlight: false,
                    occurrencesHighlight: false,
                    contextmenu: false,
                    renderLineHighlight: "none",
                }}
                onChange={this.handleChange}
            />
        );
    }
}

export default connect()(SQLEditor);
