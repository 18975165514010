import { Maybe } from "util/maybe";

import { DispatchFunction } from "data";
import { State } from "data/reducers";
import { MvFullRepr } from "data/actions/management-views";

import * as React from "react";
import { connect } from "react-redux";

import { mvFull } from "data/actions/management-views";
import { logFocusError } from "data/actions/log";
import { saveMvData, validateMvDataFile } from "util/mv-data";

import FileLoader from "view/common/file-loader";
import DropdownButton from "view/components/dropdown-button";
import { Menu, MenuItem } from "view/common/menu";

import { selectMvFullRepr } from "data/selectors/management-views";

import { jsonReviver } from "util/json";
import * as analytics from "util/segment";

type StateProps = {
    mvData: MvFullRepr;
    mvLastUpdate: Maybe<Date>;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

class MVLoadSaveData extends React.Component<Props> {
    $fileLoader: Maybe<FileLoader>;

    handleLoadFile = (text: string, filename: string) => {
        const { dispatch } = this.props;

        let repr: MvFullRepr;

        try {
            repr = JSON.parse(text, jsonReviver);
        } catch (e) {
            dispatch(
                logFocusError(
                    `The file "${filename}" is not valid JSON: ${e.message}`
                )
            );

            return;
        }

        const mvDataError: Maybe<string> = validateMvDataFile(repr);

        if (mvDataError) {
            dispatch(
                logFocusError(
                    `The file "${filename}" is not valid: ${mvDataError}.`
                )
            );
            return;
        }

        dispatch(mvFull({ repr }));
    };

    onClickImportProfile = () => {
        if (this.$fileLoader) {
            this.$fileLoader.show();
        }
    };

    onExportProfile = () => {
        const { mvData } = this.props;

        saveMvData(mvData);

        analytics.track("export-management-views-json");
    };

    render() {
        const { mvLastUpdate } = this.props;

        return (
            <>
                <FileLoader
                    onLoadFile={this.handleLoadFile}
                    ref={$fileLoader => {
                        this.$fileLoader = $fileLoader || undefined;
                    }}
                />
                <DropdownButton
                    ghost
                    icon="ellipsis-v"
                    direction="sw"
                    className="load-save-btn"
                    children={
                        <Menu>
                            <MenuItem
                                action={this.onExportProfile}
                                disabled={!mvLastUpdate}
                            >
                                Export Profile
                            </MenuItem>
                            <MenuItem action={this.onClickImportProfile}>
                                Import Profile
                            </MenuItem>
                        </Menu>
                    }
                />
            </>
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        mvData: selectMvFullRepr(s),
        mvLastUpdate: s.managementViews.lastUpdate,
    })
)(MVLoadSaveData);
