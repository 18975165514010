import { Maybe } from "util/maybe";
import { State as RouteState } from "router5";
import { State, DispatchFunction } from "data";
import { DerivedHost } from "data/models";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";
import { createRouteNodeSelector } from "redux-router5";
import { endsWithSegment } from "router5-helpers";

import GeneralError from "view/common/general-error";
import Loading from "view/components/loading";
import HostsHeader from "view/hosts/header";
import HostsPage from "view/hosts";
import HostPage from "view/hosts/host";

import {
    selectHostsLoading,
    selectHostsError,
    deriveHosts,
    selectCurrentHostAddress,
} from "data/selectors/hosts";

import { logError } from "util/logging";

import "./hosts.scss";

type StateProps = {
    loading: boolean;
    error: Maybe<string>;
    hosts: Maybe<Array<DerivedHost>>;
    currentHostAddress: string;
    route: RouteState;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

class HostsController extends React.Component<Props> {
    renderContent() {
        const { route, loading, error, hosts, currentHostAddress } = this.props;

        if (error) {
            return (
                <GeneralError
                    errorHeader="An error occurred while loading the data:"
                    error={error}
                />
            );
        }

        if (loading) {
            return <Loading size="large" />;
        }

        if (
            currentHostAddress &&
            !_.some(
                hosts,
                (host: DerivedHost) => host.address === currentHostAddress
            )
        ) {
            return (
                <GeneralError
                    error={`Could not find a host with address ${currentHostAddress}.`}
                />
            );
        }

        const testRoute = endsWithSegment(route);
        let page;
        if (testRoute("hosts")) {
            page = <HostsPage />;
        } else if (testRoute("host")) {
            page = <HostPage />;
        }

        return page;
    }

    render() {
        return (
            <div className="controller-hosts">
                <HostsHeader />
                {this.renderContent()}
            </div>
        );
    }
}

export default compose(
    connect(
        (s: State): StateProps => {
            const routeNodeSelector = createRouteNodeSelector("cluster.hosts");

            if (s.hosts.disableHosts) {
                logError(
                    new Error(
                        "Hosts controller being rendered with `disableHosts` set to true."
                    )
                );
            }

            return {
                loading: selectHostsLoading(s),
                error: selectHostsError(s),
                hosts: deriveHosts(s),
                currentHostAddress: selectCurrentHostAddress(s),

                route: routeNodeSelector(s).route,
            };
        }
    )
)(HostsController);
