import { GeneralTableColumn } from "view/components/general-table";

import * as React from "react";

export default function<T>({
    formatter,
}: {
    formatter: (r: T) => React.ReactNode;
}): GeneralTableColumn<T> {
    return {
        id: "showDefinition",
        title: "",
        // Original this value was set to 60px. Because we are rendering a
        // tooltip on top of the `showDefinition` button and the tooltip itself
        // renders a `inline-block` div (Overlay component), we need this extra
        // space to avoid ellipsis even knowing that the button width is no
        // greater than the cell one.
        defaultMaxWidth: 63,
        defaultMinWidth: 63,
        sort: "DISABLED",
        revealOnHover: true,
        formatter,
        getValue: () => undefined,
        nonResizable: true,
    };
}
