import { Maybe } from "util/maybe";
import { Column, OnSortHandler } from "view/components/super-table";
import { ActiveProcess } from "data/models";

import * as React from "react";
import _ from "lodash";
import classnames from "classnames";

import SuperTable from "view/components/super-table";

import { ActiveProcessId } from "data/models";

import "./active-processes-table.scss";

export type ActiveProcessesContext = {
    maxTime: number;
};

export type ActiveProcessesColumn = Column & {
    formatter: (
        ctx: ActiveProcessesContext,
        r: ActiveProcess
    ) => React.ReactNode;
    getValue: (r: ActiveProcess) => string | number | boolean | undefined;
};

export type ActiveProcessWithState = ActiveProcess & {
    selected: boolean;
    killed: boolean;
};

type Props = {
    activeProcesses: Array<ActiveProcessWithState>;
    columns: Array<ActiveProcessesColumn>;
    onSort: OnSortHandler;
    onToggle: (id: ActiveProcessId, selected: boolean) => void;
};

export class ActiveProcessesTable extends React.Component<Props> {
    handleClickCell = (key: Maybe<string>) => {
        const { activeProcesses } = this.props;

        if (key !== undefined) {
            const process = _.find(
                activeProcesses,
                p => ActiveProcessId.fromActiveProcess(p).toKey() === key
            );

            if (process) {
                this.props.onToggle(
                    ActiveProcessId.fromActiveProcess(process),
                    !process.selected
                );
            }
        }
    };

    render() {
        const { activeProcesses, columns, onSort } = this.props;

        const ctx = {
            maxTime: _.max(activeProcesses.map(row => row.elapsedTime)) || 0,
        };

        return (
            <SuperTable
                className="active-processes-table"
                onSort={onSort}
                columns={columns}
                rows={activeProcesses.map(process => ({
                    id: ActiveProcessId.fromActiveProcess(process).toKey(),
                    cells: columns.map(c => c.formatter(ctx, process)),
                    selected: process.selected,
                    onClickCell: this.handleClickCell,
                    className: classnames({
                        "killed-process": process.killed,
                    }),
                }))}
                rowHeight={60}
                verticallyAlignCells
            />
        );
    }
}
