export const SQL_TPCH_CREATE_DATABASE = `DROP DATABASE IF EXISTS tpch;
CREATE DATABASE tpch;
USE tpch;

CREATE TABLE \`customer\` (
    \`c_custkey\` int(11) NOT NULL,
    \`c_name\` varchar(25) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`c_address\` varchar(40) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`c_nationkey\` int(11) NOT NULL,
    \`c_phone\` char(15) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`c_acctbal\` decimal(15,2) NOT NULL,
    \`c_mktsegment\` char(10) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`c_comment\` varchar(117) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    SHARD KEY (\`c_custkey\`) USING CLUSTERED COLUMNSTORE
);

CREATE TABLE \`lineitem\` (
    \`l_orderkey\` bigint(11) NOT NULL,
    \`l_partkey\` int(11) NOT NULL,
    \`l_suppkey\` int(11) NOT NULL,
    \`l_linenumber\` int(11) NOT NULL,
    \`l_quantity\` decimal(15,2) NOT NULL,
    \`l_extendedprice\` decimal(15,2) NOT NULL,
    \`l_discount\` decimal(15,2) NOT NULL,
    \`l_tax\` decimal(15,2) NOT NULL,
    \`l_returnflag\` char(1) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`l_linestatus\` char(1) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`l_shipdate\` date NOT NULL,
    \`l_commitdate\` date NOT NULL,
    \`l_receiptdate\` date NOT NULL,
    \`l_shipinstruct\` char(25) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`l_shipmode\` char(10) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`l_comment\` varchar(44) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    SHARD KEY (\`l_orderkey\`) USING CLUSTERED COLUMNSTORE
);

CREATE TABLE \`nation\` (
    \`n_nationkey\` int(11) NOT NULL,
    \`n_name\` char(25) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`n_regionkey\` int(11) NOT NULL,
    \`n_comment\` varchar(152) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    SHARD KEY (\`n_nationkey\`) USING CLUSTERED COLUMNSTORE
);

CREATE TABLE \`orders\` (
    \`o_orderkey\` bigint(11) NOT NULL,
    \`o_custkey\` int(11) NOT NULL,
    \`o_orderstatus\` char(1) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`o_totalprice\` decimal(15,2) NOT NULL,
    \`o_orderdate\` date NOT NULL,
    \`o_orderpriority\` char(15) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`o_clerk\` char(15) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`o_shippriority\` int(11) NOT NULL,
    \`o_comment\` varchar(79) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    SHARD KEY (\`o_orderkey\`) USING CLUSTERED COLUMNSTORE
);

CREATE TABLE \`part\` (
    \`p_partkey\` int(11) NOT NULL,
    \`p_name\` varchar(55) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`p_mfgr\` char(25) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`p_brand\` char(10) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`p_type\` varchar(25) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`p_size\` int(11) NOT NULL,
    \`p_container\` char(10) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`p_retailprice\` decimal(15,2) NOT NULL,
    \`p_comment\` varchar(23) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    SHARD KEY (\`p_partkey\`) USING CLUSTERED COLUMNSTORE
);

CREATE TABLE \`partsupp\` (
    \`ps_partkey\` int(11) NOT NULL,
    \`ps_suppkey\` int(11) NOT NULL,
    \`ps_availqty\` int(11) NOT NULL,
    \`ps_supplycost\` decimal(15,2) NOT NULL,
    \`ps_comment\` varchar(199) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    SHARD KEY(\`ps_partkey\`),
    KEY (\`ps_partkey\`,\`ps_suppkey\`)  USING CLUSTERED COLUMNSTORE
);

CREATE TABLE \`region\` (
    \`r_regionkey\` int(11) NOT NULL,
    \`r_name\` char(25) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`r_comment\` varchar(152) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    SHARD KEY (\`r_regionkey\`) USING CLUSTERED COLUMNSTORE
);

CREATE TABLE \`supplier\` (
    \`s_suppkey\` int(11) NOT NULL,
    \`s_name\` char(25) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`s_address\` varchar(40) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`s_nationkey\` int(11) NOT NULL,
    \`s_phone\` char(15) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    \`s_acctbal\` decimal(15,2) NOT NULL,
    \`s_comment\` varchar(101) CHARACTER SET utf8 COLLATE utf8_general_ci NOT NULL,
    SHARD KEY (\`s_suppkey\`) USING CLUSTERED COLUMNSTORE
);`;

export const SQL_TPCH_LOAD_DATA = `use tpch;

CREATE OR REPLACE PIPELINE tpch_100_lineitem
AS LOAD DATA S3 'memsql-tpch-dataset/sf_100/lineitem/'
config '{"region":"us-east-1"}'
SKIP DUPLICATE KEY ERRORS
INTO TABLE lineitem
FIELDS TERMINATED BY '|'
LINES TERMINATED BY '|\n';

CREATE OR REPLACE PIPELINE tpch_100_customer
AS LOAD DATA S3 'memsql-tpch-dataset/sf_100/customer/'
config '{"region":"us-east-1"}'
SKIP DUPLICATE KEY ERRORS
INTO TABLE customer
FIELDS TERMINATED BY '|'
LINES TERMINATED BY '|\n';

CREATE OR REPLACE PIPELINE tpch_100_nation
AS LOAD DATA S3 'memsql-tpch-dataset/sf_100/nation/'
config '{"region":"us-east-1"}'
SKIP DUPLICATE KEY ERRORS
INTO TABLE nation
FIELDS TERMINATED BY '|'
LINES TERMINATED BY '|\n';

CREATE OR REPLACE PIPELINE tpch_100_orders
AS LOAD DATA S3 'memsql-tpch-dataset/sf_100/orders/'
config '{"region":"us-east-1"}'
SKIP DUPLICATE KEY ERRORS
INTO TABLE orders
FIELDS TERMINATED BY '|'
LINES TERMINATED BY '|\n';

CREATE OR REPLACE PIPELINE tpch_100_part
AS LOAD DATA S3 'memsql-tpch-dataset/sf_100/part/'
config '{"region":"us-east-1"}'
SKIP DUPLICATE KEY ERRORS
INTO TABLE part
FIELDS TERMINATED BY '|'
LINES TERMINATED BY '|\n';

CREATE OR REPLACE PIPELINE tpch_100_partsupp
AS LOAD DATA S3 'memsql-tpch-dataset/sf_100/partsupp/'
config '{"region":"us-east-1"}'
SKIP DUPLICATE KEY ERRORS
INTO TABLE partsupp
FIELDS TERMINATED BY '|'
LINES TERMINATED BY '|\n';

CREATE OR REPLACE PIPELINE tpch_100_region
AS LOAD DATA S3 'memsql-tpch-dataset/sf_100/region/'
config '{"region":"us-east-1"}'
SKIP DUPLICATE KEY ERRORS
INTO TABLE region
FIELDS TERMINATED BY '|'
LINES TERMINATED BY '|\n';

CREATE OR REPLACE PIPELINE tpch_100_supplier
AS LOAD DATA S3 'memsql-tpch-dataset/sf_100/supplier/'
config '{"region":"us-east-1"}'
SKIP DUPLICATE KEY ERRORS
INTO TABLE supplier
FIELDS TERMINATED BY '|'
LINES TERMINATED BY '|\n';`;

export const SQL_TPCH_START_PIPELINES = `use tpch;
START ALL PIPELINES;`;

export const SQL_TPCH_PRICING_QUERY = `use tpch;
select
    l_returnflag,
    l_linestatus,
    sum(l_quantity) as sum_qty,
    sum(l_extendedprice) as sum_base_price,
    sum(l_extendedprice * (1 - l_discount)) as sum_disc_price,
    sum(l_extendedprice * (1 - l_discount) * (1 + l_tax)) as sum_charge,
    avg(l_quantity) as avg_qty,
    avg(l_extendedprice) as avg_price,
    avg(l_discount) as avg_disc,
    count(*) as count_order
from
    lineitem
where
    l_shipdate <= date('1998-12-01' - interval '90' day)
group by
    l_returnflag,
    l_linestatus
order by
    l_returnflag,
    l_linestatus;`;

export const SQL_TPCH_SHIPPING_QUERY = `use tpch;
select
    l_orderkey,
    sum(l_extendedprice * (1 - l_discount)) as revenue,
    o_orderdate,
    o_shippriority
from
    customer,
    orders,
    lineitem
where
    c_mktsegment = 'BUILDING'
    and c_custkey = o_custkey
    and l_orderkey = o_orderkey
    and o_orderdate < date('1995-03-15')
    and l_shipdate > date('1995-03-15')
group by
    l_orderkey,
    o_orderdate,
    o_shippriority
order by
    revenue desc,
    o_orderdate
limit 10;`;

export const SQL_TPCH_RETURNED_QUERY = `use tpch;
select
    c_custkey,
    c_name,
    sum(l_extendedprice * (1 - l_discount)) as revenue,
    c_acctbal,
    n_name,
    c_address,
    c_phone,
    c_comment
from
    customer,
    orders,
    lineitem,
    nation
where
    c_custkey = o_custkey
    and l_orderkey = o_orderkey
    and o_orderdate >= date('1993-10-01')
    and o_orderdate < date('1993-10-01') + interval '3' month
    and l_returnflag = 'R'
    and c_nationkey = n_nationkey
group by
    c_custkey,
    c_name,
    c_acctbal,
    c_phone,
    n_name,
    c_address,
    c_comment
order by
    revenue desc
limit 20;`;

export const SQL_TPCH_PROFIT_QUERY = `use tpch;
select
    nation,
    o_year,
    sum(amount) as sum_profit
from
    (
        select
            n_name as nation,
            extract(year from o_orderdate) as o_year,
            l_extendedprice * (1 - l_discount) - ps_supplycost * l_quantity as amount
        from
            part,
            supplier,
            lineitem,
            partsupp,
            orders,
            nation
        where
            s_suppkey = l_suppkey
            and ps_suppkey = l_suppkey
            and ps_partkey = l_partkey
            and p_partkey = l_partkey
            and o_orderkey = l_orderkey
            and s_nationkey = n_nationkey
            and p_name like '%green%'
    ) as profit
group by
    nation,
    o_year
order by
    nation,
    o_year desc;`;
