import { Maybe } from "util/maybe";

import { DispatchFunction, State } from "data";
import { TableLike } from "data/models";
import { State as RouteState } from "router5";

import * as React from "react";
import { connect } from "react-redux";

import Header from "view/common/header";
import { Button } from "view/common/button";
import { Breadcrumbs, Breadcrumb } from "view/common/breadcrumbs";
import LastUpdated from "view/components/last-updated";
import SchemaMenu from "view/schema/menu";

import { querySchemaStatistics } from "worker/api/schema";
import { queryTopology } from "worker/api/topology";
import {
    queryClusterStatistics,
    queryPartitionData,
} from "worker/api/cluster-metadata";
import { queryPipelines } from "worker/api/pipelines";

import {
    selectSchemaExplorerLoaded,
    selectSchemaExplorerLoading,
    selectSchemaExplorerNeedsLoading,
    selectCurrentTableLike,
} from "data/selectors/schema";

import { selectRoute } from "data/selectors/routes";

import { getTableLikeIcon } from "memsql/schema-column-info";
import * as analytics from "util/segment";

import "./header.scss";

type StateProps = {
    lastStatisticsUpdate: Maybe<Date>;
    loading: boolean;
    route: RouteState;
    loaded: boolean;
    needsLoading: boolean;
    currentTableLike: Maybe<TableLike>;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

class SchemaHeader extends React.Component<Props> {
    handleReload = () => {
        this.props.dispatch(querySchemaStatistics());
        this.props.dispatch(queryTopology());
        this.props.dispatch(queryClusterStatistics());
        this.props.dispatch(queryPipelines());
        this.props.dispatch(queryPartitionData());
    };

    handleClickReload = () => {
        this.handleReload();

        analytics.trackReload("schema");
    };

    componentDidMount() {
        const { needsLoading } = this.props;
        if (needsLoading) {
            this.handleReload();
        }
    }

    getBreadcrumbs = () => {
        const { route, currentTableLike } = this.props;
        const {
            name,
            params: { clusterId, tableName, databaseName, partitionName },
        } = route;

        const breadcrumbs = [
            <Breadcrumb
                key="cluster"
                name="Databases"
                routeInfo={{
                    name: "cluster.databases",
                    params: { clusterId },
                }}
            />,
        ];

        if (databaseName) {
            if (name.includes("partition")) {
                breadcrumbs.push(
                    <Breadcrumb
                        key="database-partition"
                        name={databaseName}
                        subName="Partitions"
                        iconRepr={{ icon: "database" }}
                        routeInfo={{
                            name: "cluster.databases.partitions",
                            params: { clusterId, databaseName },
                        }}
                    />
                );
            } else {
                breadcrumbs.push(
                    <Breadcrumb
                        key="database-schema"
                        name={databaseName}
                        subName="Schema"
                        iconRepr={{ icon: "database" }}
                        routeInfo={{
                            name: "cluster.databases.tables",
                            params: { clusterId, databaseName },
                        }}
                    />
                );
            }
        }

        if (tableName) {
            let tableIcon;
            if (currentTableLike) {
                tableIcon = { icon: getTableLikeIcon(currentTableLike) };
            }

            breadcrumbs.push(
                <Breadcrumb
                    key="table"
                    name={tableName}
                    iconRepr={tableIcon}
                    routeInfo={{
                        name: "cluster.databases.columns",
                        params: { clusterId, databaseName, tableName },
                    }}
                />
            );
        }

        if (partitionName) {
            breadcrumbs.push(
                <Breadcrumb
                    key="partition"
                    name={partitionName}
                    iconRepr={{ icon: "chart-pie" }}
                    routeInfo={{
                        name: "cluster.databases.partition",
                        params: { clusterId, databaseName, partitionName },
                    }}
                />
            );
        }

        return breadcrumbs;
    };

    render() {
        const { lastStatisticsUpdate, loading } = this.props;

        let lastUpdated;
        if (!loading && lastStatisticsUpdate) {
            lastUpdated = (
                <span className="last-updated">
                    <LastUpdated date={lastStatisticsUpdate} />
                </span>
            );
        }

        return (
            <Header
                className="schema-header"
                left={<Breadcrumbs>{this.getBreadcrumbs()}</Breadcrumbs>}
                right={
                    <>
                        {lastUpdated}

                        <SchemaMenu className="schema-menu" />

                        <Button
                            disabled={loading}
                            small
                            icon="sync-alt"
                            onClick={this.handleClickReload}
                        />
                    </>
                }
            />
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        lastStatisticsUpdate: s.schema.lastStatisticsUpdate,
        loading: selectSchemaExplorerLoading(s),
        needsLoading: selectSchemaExplorerNeedsLoading(s),
        loaded: selectSchemaExplorerLoaded(s),
        route: selectRoute(s),
        currentTableLike: selectCurrentTableLike(s),
    })
)(SchemaHeader);
