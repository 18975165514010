import { Maybe } from "util/maybe";

import { State } from "data";

import _ from "lodash";
import { createSelector } from "reselect";

import {
    selectLoadingOrInitial,
    selectLastUpdate,
} from "util/loading-state-machine";

import {
    selectDerivedNodesLoading,
    selectDerivedNodesLastUpdate,
} from "data/selectors/topology";

import {
    selectPipelinesLoading,
    selectPipelinesLastUpdate,
} from "data/selectors/pipelines";

import {
    selectSchemaSummaryLoading,
    selectSchemaSummaryLastUpdate,
} from "data/selectors/schema";

import {
    selectLicenseLoading,
    selectLicenseLastUpdate,
} from "data/selectors/license";

export const selectClusterMetadataLoading = (s: State): boolean =>
    selectLoadingOrInitial(s.clusterMetadata.clusterStatistics);

const selectClusterMetadataLastUpdate = (s: State): Maybe<Date> =>
    selectLastUpdate(s.clusterMetadata.clusterStatistics);

// We return `true` if ANY of the stores that ANY of the cards
// depend on are in the loading state.
export const isDashboardLoading = createSelector(
    selectDerivedNodesLoading,
    selectClusterMetadataLoading,
    selectPipelinesLoading,
    selectSchemaSummaryLoading,
    selectLicenseLoading,
    (
        nodesLoading,
        clusterMetadataLoading,
        pipelinesLoading,
        schemaSummaryLoading,
        licenseLoading
    ): boolean => {
        return (
            nodesLoading ||
            clusterMetadataLoading ||
            pipelinesLoading ||
            schemaSummaryLoading ||
            licenseLoading
        );
    }
);

// We look at all of the stores that all of the cards depend on and
// return the date that is more recent.
export const lastDashboardUpdate = createSelector(
    selectDerivedNodesLastUpdate,
    selectClusterMetadataLastUpdate,
    selectPipelinesLastUpdate,
    selectSchemaSummaryLastUpdate,
    selectLicenseLastUpdate,
    (
        nodesLastUpdate,
        clusterMetadataLastUpdate,
        pipelinesLastUpdate,
        schemaSummaryLastUpdate,
        licenseLastUpdate
    ): Maybe<Date> => {
        return _.max([
            nodesLastUpdate,
            clusterMetadataLastUpdate,
            pipelinesLastUpdate,
            schemaSummaryLastUpdate,
            licenseLastUpdate,
        ]);
    }
);
