// The type definitions for this file live in ./query.d.ts.

import _ from "lodash";

export function select(conn, sql, values) {
    return new Promise((resolve, reject) =>
        conn.query(
            sql,
            values,
            (error /*: Nullable<Error>*/, rows /*: Array<RowResult>*/) =>
                error ? reject(error) : resolve(rows)
        )
    );
}

export function multiSelect(conn, ...queries) {
    return _.reduce(
        queries,
        (chain, query) =>
            chain.then(result =>
                select(conn, query.sql, query.params).then(rows => [
                    ...result,
                    rows,
                ])
            ),
        Promise.resolve([])
    );
}

// `multiSelectWithErrors` is similar to `multiSelect` but instead of rejecting
// on the first error, it returns errors to the caller along with rows.
export function multiSelectWithErrors(conn, ...queries) {
    return _.reduce(
        queries,
        (chain, query) =>
            chain.then(result =>
                select(conn, query.sql, query.params)
                    .then(rows => [...result, rows])
                    .catch(err => [...result, err])
            ),
        Promise.resolve([])
    );
}

// Map the type 'MultiSelectErrorResult' into the result type and throws an
// exception when an error occurs. A callback 'handleError' can be passed
// whenever a custom error handling logic is needed
export function mapMultiSelectErrorResult(payload, handleError) {
    if (_.isError(payload)) {
        if (handleError) {
            return handleError(payload);
        } else {
            throw payload;
        }
    } else {
        return payload;
    }
}
