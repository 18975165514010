import { Maybe } from "util/maybe";
import { SortDirection, TableSort } from "util/sort";

import { State, DispatchFunction } from "data";
import { Router, State as RouteState } from "router5";
import { DerivedHost } from "data/models";
import { ColumnId } from "view/components/super-table";

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRoute } from "react-router5";

import Loading from "view/components/loading";
import GeneralTable from "view/components/general-table";
import GeneralError from "view/common/general-error";

import { sortHosts } from "data/actions";
import {
    selectHostsLoading,
    selectHostsSort,
    selectHostsError,
    selectSortedHosts,
    selectIsPhysicalMonitoringEnabled,
} from "data/selectors/hosts";

import { nextTableSort } from "util/sort";

import { getHostsColumns } from "./columns-info";

import "./index.scss";

type StateProps = {
    loading: boolean;
    error: Maybe<string>;
    hosts: Maybe<Array<DerivedHost>>;
    sort: TableSort;
    physicalMonitoringEnabled: Maybe<boolean>;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
    router: Router;
    route: RouteState;
};

class HostsPage extends React.Component<Props> {
    handleSort = (columnId: ColumnId, sortDir: Maybe<SortDirection>) => {
        this.props.dispatch(sortHosts(nextTableSort(columnId, sortDir)));
    };

    handleClickCell = (host: DerivedHost) => {
        const {
            router,
            route: {
                params: { clusterId },
            },
        } = this.props;

        router.navigate("cluster.hosts.host", {
            clusterId,
            hostId: host.address,
        });
    };

    render() {
        const {
            hosts,
            error,
            sort,
            loading,
            physicalMonitoringEnabled,
        } = this.props;

        let inner;
        if (loading || physicalMonitoringEnabled === undefined) {
            inner = <Loading size="large" />;
        } else if (error) {
            inner = <GeneralError error={error} />;
        } else if (hosts) {
            inner = (
                <GeneralTable
                    onSort={this.handleSort}
                    columns={getHostsColumns(physicalMonitoringEnabled)}
                    rows={hosts}
                    sort={sort}
                    onClickCell={this.handleClickCell}
                    rowHeight={physicalMonitoringEnabled ? 70 : undefined}
                    verticallyAlignCells
                />
            );
        } else {
            // Should not happen
            inner = (
                <GeneralError
                    errorHeader="Error loading hosts page"
                    error={error || "Unknown error, please contact support."}
                />
            );
        }

        return <div className="hosts-page">{inner}</div>;
    }
}

export default compose(
    withRoute,
    connect(
        (s: State): StateProps => ({
            loading: selectHostsLoading(s),
            error: selectHostsError(s),
            hosts: selectSortedHosts(s),
            sort: selectHostsSort(s),
            physicalMonitoringEnabled: selectIsPhysicalMonitoringEnabled(s),
        })
    )
)(HostsPage);
