import { GeneralErrorProps } from "view/common/general-error";

import * as React from "react";

import GeneralError from "view/common/general-error";

import { logError } from "util/logging";

import "./error-boundary.scss";

type Props = { generalErrorProps: GeneralErrorProps };

type State = {
    error: boolean;
};

export class ErrorBoundary extends React.Component<Props> {
    static defaultProps = {
        generalErrorProps: {
            errorHeader: "An error occurred.",
            error: "Please contact the support team.",
        },
    };

    state: State = {
        error: false,
    };

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.setState({
            error: true,
        });
        logError(
            error,
            `
                stack: ${error.stack}
                componentStack: ${info.componentStack}
            `
        );
    }

    render() {
        const { generalErrorProps, children } = this.props;

        if (this.state.error) {
            return (
                <div className="components-common-error-boundary">
                    <GeneralError
                        errorHeader={generalErrorProps.errorHeader}
                        error={generalErrorProps.error}
                    />
                </div>
            );
        } else {
            return children;
        }
    }
}
