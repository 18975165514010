import * as React from "react";

import CircleIcon from "view/components/circle-icon";
import { getRunQueryShortcut } from "view/editor/keyboard-shortcuts";

import { PER_QUERY_ROW_LIMIT } from "data/reducers/query-editor";

import "./empty-results-placeholder.scss";

export const EmptyResultsPlaceholder = () => {
    return (
        <div className="results-placeholder">
            <CircleIcon size="large" name="empty-table" className="icon" />
            <div className="title">Empty Results</div>
            <div className="description">
                Select one or more queries and hit{" "}
                <span className="shortcut">{getRunQueryShortcut()}</span> to run
                them. Results are limited to {PER_QUERY_ROW_LIMIT} rows.
            </div>
        </div>
    );
};
