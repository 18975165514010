
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}><MDXTag name="h4" components={components}>{`Introduction`}</MDXTag>
<MDXTag name="p" components={components}>{`MemSQL supports ingesting data from S3 buckets in parallel using a tool called pipelines. Creating and running a pipeline on an S3 bucket will ingest all the data in the selected bucket, and then will monitor the bucket for changes and continuously ingest and update the data in the database.`}</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  