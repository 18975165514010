import { Maybe } from "util/maybe";
import { TableSort } from "util/sort";
import { GeneralTableColumn } from "view/components/general-table";

import { State } from "data";
import { Pipeline } from "data/models";

import _ from "lodash";
import { createSelector } from "reselect";

import PIPELINES_COLUMNS from "view/pipelines/columns-info";
import {
    selectLastUpdate,
    selectLoadingOrInitial,
    selectPayload,
    selectError,
} from "util/loading-state-machine";

export const selectPipelines = (s: State): Maybe<Array<Pipeline>> => {
    const payload = selectPayload(s.pipelines.pipelines);

    if (payload) {
        return payload.pipelines;
    }
};

export const selectPipelinesLoading = (s: State): boolean =>
    selectLoadingOrInitial(s.pipelines.pipelines);

export const selectPipelinesError = (s: State): Maybe<string> => {
    const error = selectError(s.pipelines.pipelines);

    if (error) {
        return error.message;
    }
};

export const selectPipelinesLastUpdate = (s: State): Maybe<Date> =>
    selectLastUpdate(s.pipelines.pipelines);

export const selectPipelinesSort = (s: State): TableSort => s.pipelines.sort;

export const selectSortedPipelines = createSelector(
    selectPipelines,
    selectPipelinesSort,
    (pipelines, sort): Maybe<Array<Pipeline>> => {
        if (sort && pipelines) {
            const { direction, columnId } = sort;

            const columnInfo = _.find(
                PIPELINES_COLUMNS,
                (col: GeneralTableColumn<unknown>) => col.id === columnId
            );

            if (!columnInfo) {
                throw new Error("Expected column to be defined.");
            }

            return _.orderBy<Pipeline>(
                pipelines,
                [columnInfo.getValue],
                direction
            );
        } else {
            return pipelines;
        }
    }
);
