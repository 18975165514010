import * as React from "react";

type Props = {
    check: boolean;
    children: JSX.Element;
};

const Condition: React.SFC<Props> = ({ check, children }: Props) =>
    check ? children : null;

export default Condition;
