import { Maybe } from "util/maybe";

import { State as RouteState } from "router5";
import { State, DispatchFunction } from "data";

import { Database, DatabaseName, Table, View, TableName } from "data/models";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { createRouteNodeSelector } from "redux-router5";
import { endsWithSegment } from "router5-helpers";

import GeneralError from "view/common/general-error";
import Loading from "view/components/loading";

import DatabasesPage from "view/schema/page-databases";
import TablePage from "view/schema/page-table";
import TablesPage from "view/schema/page-tables";
import ViewPage from "view/schema/page-view";
import SchemaHeader from "view/schema/header";
import SchemaView from "view/schema/schema-view";
import PartitionPage from "view/schema/page-partition";

import {
    selectCurrentDatabaseName,
    selectCurrentTableName,
    selectDatabases,
    selectTables,
    selectViews,
    selectSchemaExplorerLoaded,
    selectSchemaExplorerError,
} from "data/selectors/schema";

import { genTableId } from "memsql/db-object-id";

type StateProps = {
    loaded: boolean;
    error: Maybe<string>;

    databases: Array<Database>;
    currentDatabase: Maybe<DatabaseName>;

    tables: Array<Table>;
    views: Array<View>;
    currentTable: Maybe<TableName>;

    route: RouteState;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

class SchemaController extends React.Component<Props> {
    renderContent() {
        const { route, loaded, error } = this.props;

        if (error) {
            return (
                <GeneralError
                    errorHeader="An error occurred while loading the data:"
                    error={error}
                />
            );
        }

        if (!loaded) {
            return <Loading size="large" />;
        }

        const {
            currentDatabase,
            currentTable,
            databases,
            tables,
            views,
        } = this.props;

        if (
            currentDatabase &&
            !_.some(
                databases,
                (d: Database) => d.databaseName === currentDatabase
            )
        ) {
            return (
                <GeneralError error="The database you are looking for does not exist." />
            );
        }

        let tableExists, viewExists;
        if (currentDatabase && currentTable) {
            const currentTableId = genTableId({
                databaseName: currentDatabase,
                tableName: currentTable,
            });

            tableExists = _.some(
                tables,
                (t: Table) => t.tableId === currentTableId
            );

            viewExists = _.some(
                views,
                (v: View) => v.tableId === currentTableId
            );

            if (!tableExists && !viewExists) {
                return (
                    <GeneralError error="The table or view you are looking for does not exist." />
                );
            }
        }

        const testRoute = endsWithSegment(route);
        let page;
        if (testRoute("columns")) {
            if (tableExists) {
                page = <TablePage tab="Columns" />;
            } else {
                page = <ViewPage tab="Columns" />;
            }
        } else if (testRoute("indexes")) {
            page = <TablePage tab="Indexes" />;
        } else if (testRoute("sample")) {
            if (tableExists) {
                page = <TablePage tab="Sample Data" />;
            } else {
                page = <ViewPage tab="Sample Data" />;
            }
        } else if (testRoute("tables")) {
            page = <TablesPage tab="Tables" />;
        } else if (testRoute("views")) {
            page = <TablesPage tab="Views" />;
        } else if (testRoute("procedures")) {
            page = <TablesPage tab="Procedures" />;
        } else if (testRoute("functions")) {
            page = <TablesPage tab="Functions" />;
        } else if (testRoute("aggregates")) {
            page = <TablesPage tab="Aggregates" />;
        } else if (testRoute("pipelines")) {
            page = <TablesPage tab="Pipelines" />;
        } else if (testRoute("partitions")) {
            page = <TablesPage tab="Partitions" />;
        } else if (testRoute("partition")) {
            page = <PartitionPage />;
        } else if (testRoute("databases")) {
            page = <DatabasesPage />;
        }

        return <SchemaView>{page || null}</SchemaView>;
    }

    render() {
        return (
            <div className="schema-page">
                <SchemaHeader />
                {this.renderContent()}
            </div>
        );
    }
}

export default connect(
    (s: State): StateProps => {
        const routeNodeSelector = createRouteNodeSelector("clusters.databases");

        return {
            loaded: selectSchemaExplorerLoaded(s),
            error: selectSchemaExplorerError(s),

            databases: selectDatabases(s),
            currentDatabase: selectCurrentDatabaseName(s),

            tables: selectTables(s),
            views: selectViews(s),
            currentTable: selectCurrentTableName(s),

            route: routeNodeSelector(s).route,
        };
    }
)(SchemaController);
