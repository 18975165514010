import { Maybe } from "util/maybe";
import { RouteInfo } from "router/types";

import { State, DispatchFunction } from "data";
import { InjectedRoute } from "react-router5";

import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import classnames from "classnames";

import { withRoute, Link } from "react-router5";

import { selectCurrentRouteClusterId } from "data/selectors/routes";
import * as analytics from "util/segment";

import "./internal-link.scss";

type StateProps = {
    currentClusterId: Maybe<string>;
};

type Props = InjectedRoute &
    StateProps &
    React.HTMLAttributes<HTMLAnchorElement> & {
        routeInfo: RouteInfo;
        className?: string;
        clusterLink?: boolean;
        children: React.ReactNode;
        dispatch: DispatchFunction;
        underlineOnHover?: boolean;

        // Optional string representing the location of this link, for analytics.
        // By default it will be the cleaned current route, but it can be set to
        // distinguish e.g. links in the sidebar from links in the main content.
        category?: string;
    };

class InternalLink extends React.Component<Props> {
    static defaultProps = {
        underlineOnHover: true,
    };

    successCallback = () => {
        const {
            route,
            routeInfo: { name },
            category,
        } = this.props;

        if (!route) {
            return;
        }

        if (category) {
            analytics.trackInternalLinkClick(category, { url: name });
        }
    };

    render() {
        // We hold on to `dispatch` and `category` so as not to pass it to `...rest`.
        const {
            routeInfo: { name, params },
            className,
            clusterLink,
            currentClusterId,
            children,
            underlineOnHover,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            dispatch,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            category,
            ...rest
        } = this.props;

        let routeParams = params;
        if (clusterLink && params) {
            routeParams = {
                ...params,
                clusterId: currentClusterId,
            };
        }

        const classes = classnames("components-internal-link", className, {
            "no-underline": !underlineOnHover,
        });

        return (
            <Link
                routeName={name}
                routeParams={routeParams}
                className={classes}
                successCallback={this.successCallback}
                {...rest}
            >
                {children}
            </Link>
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        currentClusterId: selectCurrentRouteClusterId(s),
    })
)(withRoute(InternalLink));
