import { Maybe } from "util/maybe";
import { CSSProperties } from "react";
import { CustomScrollbarRef } from "view/components/custom-scrollbar";

import { QueryExecutorState } from "worker/net/query-executor";

import React from "react";
import _ from "lodash";
import classnames from "classnames";

import { CustomScrollbar } from "view/components/custom-scrollbar";
import Icon from "view/components/icon";
import Key from "util/key";

import "./console.scss";

const HORIZONTAL_TRACK_TOP = 2; // px
const HORIZONTAL_TRACK_LEFT = 2; // px
const HORIZONTAL_TRACK_RIGHT = 2; // px
const HORIZONTAL_TRACK_BOTTOM = 2; // px
const HORIZONTAL_TRACK_HEIGHT = 6; // px

type Props = {
    handleQuery: (query: string) => void;
    handleQueryChange: (query: string) => void;
    handleQueryShift: (dir: "UP" | "DOWN") => void;
    queryExecutorState: QueryExecutorState;
    buffer: string;
    currentQuery: string;
};

type State = {
    clickStartPosition: [number, number];
    horizontalScrollbarVisible: boolean;
};

export default class Console extends React.Component<Props, State> {
    scrollback: React.RefObject<CustomScrollbarRef> = React.createRef();
    queryInput: Maybe<HTMLInputElement>;
    scrollToBottomLeft: () => void;

    constructor(props: Props) {
        super(props);
        this.state = {
            clickStartPosition: [0, 0],
            horizontalScrollbarVisible: false,
        };

        // we throttle scrolling to at-most once per 100ms otherwise we
        // introduce performance issues (redraw) when we are streaming rows.
        this.scrollToBottomLeft = _.throttle(
            () => {
                const { horizontalScrollbarVisible } = this.state;

                if (this.scrollback.current && !horizontalScrollbarVisible) {
                    if (
                        this.scrollback.current.getScrollWidth() >
                        this.scrollback.current.getClientWidth()
                    ) {
                        this.setState({ horizontalScrollbarVisible: true });
                    }
                }

                // Avoid race conditions. Using `_.defer` reduces the
                // chance of getting the buffer not completely at the
                // bottom. This happens when the user types fast/hits enter
                // fast.
                _.defer(() => {
                    if (this.scrollback.current) {
                        this.scrollback.current.scrollToLeft();
                        this.scrollback.current.scrollToBottom();
                    }
                });
            },
            100,
            { trailing: true, leading: false }
        );
    }

    componentDidMount() {
        if (this.queryInput) {
            this.queryInput.focus();
        }

        this.scrollToBottomLeft();
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.buffer !== nextProps.buffer) {
            this.scrollToBottomLeft();
        }
    }

    startQuery = () => {
        const { handleQuery, currentQuery } = this.props;
        handleQuery(currentQuery);
    };

    handleChange = (evt: React.SyntheticEvent<HTMLInputElement>) => {
        const { queryExecutorState, handleQueryChange } = this.props;
        if (queryExecutorState === "IDLE") {
            handleQueryChange(evt.currentTarget.value);
        }
    };

    handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
        const { queryExecutorState } = this.props;
        if (queryExecutorState === "IDLE") {
            if (evt.keyCode === Key.ENTER) {
                this.startQuery();
            } else if (evt.keyCode === Key.UP) {
                this.props.handleQueryShift("UP");
            } else if (evt.keyCode === Key.DOWN) {
                this.props.handleQueryShift("DOWN");
            }
        }
    };

    handleMouseDown = (evt: React.MouseEvent<HTMLDivElement>) => {
        this.setState({
            clickStartPosition: [evt.pageX, evt.pageY],
        });
    };

    handleMouseUp = (evt: React.MouseEvent<HTMLDivElement>) => {
        const { clickStartPosition } = this.state;
        const position = [evt.pageX, evt.pageY];
        const delta = [
            Math.abs(position[0] - clickStartPosition[0]),
            Math.abs(position[1] - clickStartPosition[1]),
        ];
        // only focus the input if the mouse moves less than a small amount
        // between mouseDown and mouseUp
        if (delta[0] < 5 && delta[1] < 5 && this.queryInput) {
            this.queryInput.focus();
        }
    };

    renderView({
        style,
        ...props
    }: { style: CSSProperties } & React.HTMLProps<HTMLDivElement>) {
        return (
            <div
                style={{
                    ...style,
                    padding: "0px 10px",
                }}
                {...props}
            />
        );
    }

    renderThumb({
        style,
        ...props
    }: { style: CSSProperties } & React.HTMLProps<HTMLDivElement>) {
        return (
            <div
                style={{
                    ...style,
                    borderRadius: "3px",

                    // should match sidebar.scss sidebar-thumb bgColor
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                }}
                {...props}
            />
        );
    }

    // Copied from react-custom-scrollbars codebase - the default track style
    renderTrackHorizontal({
        style,
        ...props
    }: { style: CSSProperties } & React.HTMLProps<HTMLDivElement>) {
        const finalStyle = {
            ...style,
            right: HORIZONTAL_TRACK_RIGHT,
            bottom: HORIZONTAL_TRACK_BOTTOM,
            left: HORIZONTAL_TRACK_LEFT,
            height: HORIZONTAL_TRACK_HEIGHT,
            borderRadius: 3,
        };

        return <div style={finalStyle} {...props} />;
    }

    render() {
        const { buffer, currentQuery, queryExecutorState } = this.props;
        const { horizontalScrollbarVisible } = this.state;

        const inputBlockClasses = classnames({
            "terminal-input-block": true,
            disabled: queryExecutorState !== "IDLE",
        });

        let bufferStyle;
        if (horizontalScrollbarVisible) {
            // We need this padding so we have space to render an
            // horizontal scrollbar if needed.
            const paddingBottom =
                HORIZONTAL_TRACK_TOP +
                HORIZONTAL_TRACK_HEIGHT +
                HORIZONTAL_TRACK_BOTTOM;

            bufferStyle = {
                paddingBottom: `${paddingBottom}px`,
            };
        }

        return (
            <div
                className="bottom-panel-console"
                onMouseDown={this.handleMouseDown}
                onMouseUp={this.handleMouseUp}
            >
                <CustomScrollbar
                    autoHide
                    renderThumbVertical={this.renderThumb}
                    renderThumbHorizontal={this.renderThumb}
                    renderTrackHorizontal={this.renderTrackHorizontal}
                    ref={this.scrollback}
                    renderView={this.renderView}
                >
                    <pre
                        className="scrollback"
                        style={bufferStyle}
                        children={buffer}
                    />
                </CustomScrollbar>

                <div className={inputBlockClasses}>
                    <Icon icon="angle-right" className="angle-icon" />

                    <input
                        className="terminal-input"
                        type="text"
                        value={currentQuery}
                        onKeyDown={this.handleKeyDown}
                        onChange={this.handleChange}
                        ref={input => {
                            this.queryInput = input || undefined;
                        }}
                    />
                </div>
            </div>
        );
    }
}
