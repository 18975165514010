import BigNumber from "vendor/bignumber.js/bignumber";

import { Nullable } from "util/nullable";

const SQL_ORDER_BY = `ORDER BY pipelineId ASC`;

export const SQL_PIPELINES_QUERY = `
    SELECT
        CONCAT(DATABASE_NAME, '.', PIPELINE_NAME) AS pipelineId,
        PIPELINES.DATABASE_NAME AS databaseName,
        PIPELINES.PIPELINE_NAME AS pipelineName,
        ANY_VALUE(STATE) AS state,
        ANY_VALUE(CONFIG_JSON::$source_type) AS sourceType
    FROM
        INFORMATION_SCHEMA.PIPELINES
    GROUP BY
        PIPELINES.DATABASE_NAME,
        PIPELINES.PIPELINE_NAME
    ${SQL_ORDER_BY};
`;

export type SQLPipelinesRow = {
    pipelineId: string;
    databaseName: string;
    pipelineName: string;
    state: string;
    sourceType: Nullable<Uint8Array>;
};

export const SQL_PIPELINES_FAILED_BATCHES_QUERY = `
    SELECT
        CONCAT(DATABASE_NAME, '.', PIPELINE_NAME) AS pipelineId,
        COUNT(*) AS numFailedBatches
    FROM
        INFORMATION_SCHEMA.PIPELINES_BATCHES_METADATA
    WHERE
        BATCH_STATE = 'Failed'
    GROUP BY
        DATABASE_NAME,
        PIPELINE_NAME
    ${SQL_ORDER_BY};
`;

export type SQLPipelinesFailedBatchesRow = {
    pipelineId: string;
    numFailedBatches: BigNumber;
};

// We convert the Milliseconds Unix Epoch to a standard seconds Unix epoch
// so that it can be easily parsed by date-fns.
export const SQL_PIPELINES_LAST_BATCH_QUERY = `
    SELECT
        CONCAT(DATABASE_NAME, '.', PIPELINE_NAME) AS pipelineId,
        sub.BATCH_STATE AS lastBatchState,
        FROM_UNIXTIME(sub.BATCH_START_UNIX_TIMESTAMP) AS lastBatchTimestamp,
        IFNULL(sub.BATCH_ROWS_WRITTEN, 0) AS lastBatchRowsWritten
    FROM (
        SELECT
            DATABASE_NAME,
            PIPELINE_NAME,
            BATCH_STATE,
            BATCH_START_UNIX_TIMESTAMP,
            BATCH_ROWS_WRITTEN,
            ROW_NUMBER() OVER (
                PARTITION BY DATABASE_NAME,
                PIPELINE_NAME
                ORDER BY BATCH_START_UNIX_TIMESTAMP DESC
            ) AS r
        FROM
            INFORMATION_SCHEMA.PIPELINES_BATCHES_METADATA
        WHERE
            BATCH_STATE NOT IN ('No Data', 'In Progress')
        ) sub
    WHERE
        r = 1
    ${SQL_ORDER_BY};
`;

export type SQLPipelinesLastBatchRow = {
    pipelineId: string;
    lastBatchState: Nullable<string>;
    lastBatchTimestamp: Nullable<string>;
    lastBatchRowsWritten: BigNumber;
};
