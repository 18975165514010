import { Maybe } from "util/maybe";
import { ReactChildrenArray } from "util/react-children-array";
import { IconType } from "view/components/icon";

import * as React from "react";
import _ from "lodash";
import classnames from "classnames";

import { CustomScrollbar } from "view/components/custom-scrollbar";
import Tip from "view/components/tip";
import Icon from "view/components/icon";
import DropdownButton from "view/components/dropdown-button";
import { Menu, MenuItem } from "view/common/menu";
import ResizeDetector from "view/components/resize-detector";

import "./nested-tree.scss";

type TreeItemId = string;

export type TreeItem = {
    id: TreeItemId;
    label: string;
    icon?: string;
    iconType?: IconType;

    // If this is specified, it overides the `label` as the tooltip for the
    // TreeItem.
    tooltip?: React.ReactNode;

    // Whether to render this TreeItem as active or not.
    active: Maybe<boolean>;

    // Note that not passing in `children` and passing in
    // an empty array as `children` is different. An empty
    // array will mean that the item is expandable (an icon
    // to expand the item will be visible). Not passing in
    // `children` means that this item is not expandable at
    // all.
    children?: Array<TreeItem>;

    actions: Maybe<ReactChildrenArray<typeof MenuItem>>;

    onClick?: () => void;

    expanded?: boolean;
    onExpandCollapse?: () => void;
};

type FlatTreeItem = TreeItem & {
    indentLevel: number;
    paddingLeft: number;
};

const flattenTreeItems = (
    items: Array<TreeItem>,
    indentLevel: number,
    paddingLeft: number
): Array<FlatTreeItem> => {
    return _.flatMap(items, item => {
        let children = (item.expanded && item.children) || [];
        let childrenLeft = paddingLeft + LEVEL_INDENT;

        if (item.icon) {
            childrenLeft += ICON_WIDTH;
        }

        return [
            { ...item, indentLevel, paddingLeft },
            ...flattenTreeItems(children, indentLevel + 1, childrenLeft),
        ];
    });
};

// All of these are in pixels.
const LEVEL_INDENT = 24;
// These are shared with the CSS!
const CARET_WIDTH = 10;
const CARET_MARGIN = 5;
const ICON_WIDTH = 15;
const ITEM_HEIGHT = 30;

type ItemProps = {
    treeItem: FlatTreeItem;
    disableClick?: boolean;
    disableExpansion?: boolean;
};

class NestedTreeItem extends React.Component<ItemProps> {
    handleExpandCollapse = (e: React.MouseEvent) => {
        const {
            treeItem: { onExpandCollapse },
        } = this.props;

        e.stopPropagation();

        if (onExpandCollapse) {
            return onExpandCollapse();
        }
    };

    render() {
        const {
            treeItem: {
                id,
                label,
                icon,
                iconType,
                tooltip,
                expanded,
                onClick,
                children,
                actions,
                active,
                indentLevel,
                paddingLeft: basePaddingLeft,
            },
            disableClick,
            disableExpansion,
        } = this.props;

        const expandable = Boolean(children);
        const showCaret = expandable && !disableExpansion;

        let paddingLeft = basePaddingLeft;
        if (expandable && disableExpansion) {
            paddingLeft += CARET_WIDTH + CARET_MARGIN;
        }

        let actionsMenu;
        if (actions) {
            actionsMenu = (
                <div className="actions">
                    <DropdownButton
                        small
                        ghost
                        icon="ellipsis-v"
                        direction="sw"
                        children={<Menu>{actions}</Menu>}
                    />
                </div>
            );
        }

        return (
            <Tip
                key={id}
                className={classnames("item", {
                    "top-level": indentLevel === 0,
                    expanded,
                    clickable: !disableClick && Boolean(onClick),
                    active,
                })}
                tooltip={tooltip || label}
                direction="w"
                onClick={disableClick ? undefined : onClick}
                style={{ paddingLeft }}
            >
                {showCaret && (
                    <div
                        className="caret-container"
                        onClick={this.handleExpandCollapse}
                    >
                        <Icon icon="caret-right" size="sm" className="caret" />
                    </div>
                )}

                {icon && (
                    <Icon
                        className="icon"
                        fixedWidth
                        icon={icon}
                        iconType={iconType}
                    />
                )}

                <div className="name">{label}</div>

                {actionsMenu}
            </Tip>
        );
    }
}

type TreeProps = {
    items: Array<TreeItem>;
    emptyState: React.ReactNode;
    error?: React.ReactNode;
    loadingState: React.ReactNode;
    loading: boolean;
    className?: string;

    // For virtualization, the number of extra items to render on both sides of
    // the viewport, to ensure scrolling isn't too jittery. Defaults to 5.
    overscanCount: number;

    disableClick?: boolean;
    disableExpansion?: boolean;
};

type TreeState = {
    scrollTop: number;
    height: number;
};

// We memoize the flattening of tree items, because virtualization means we
// have to rerender on scroll events, and walking the full tree to flatten it
// on every rerender would be expensive enough to defeat the purpose of
// virtualization. We only memoize the most recent array of TreeItems because
// arrays are built from scratch each time, so no arguments other than the most
// recent one will ever be reused (and because the input and output arrays can
// be large, so memoizing all of them would cause a memory leak). We do the
// memoization here (instead of, say, in a Redux selector) because it is a
// view-layer-only concern. See the React docs for another exampmle:
// https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
// We follow it in creating a memoized function per instance, but without using
// a library; the code is simple enough that it isn't worth it.
//
// Note that Lodash has a memoize function, but it memoizes all past arguments,
// which would cause a memory leak if applied here, as mentioned above.
// Although it can be made to use a WeakMap, which resolves that issue, the API
// for doing so involves setting a global variable to WeakMap, which is
// questionable.
function makeMemoizedFlattenTreeItems(): ((
    items: Array<TreeItem>
) => Array<FlatTreeItem>) {
    let cachedItems: Array<TreeItem> = [];
    let cachedResult: Array<FlatTreeItem> = [];

    return (items: Array<TreeItem>) => {
        if (items === cachedItems) {
            return cachedResult;
        }

        cachedItems = items;
        cachedResult = flattenTreeItems(items, 0, LEVEL_INDENT);
        return cachedResult;
    };
}

export default class NestedTree extends React.Component<TreeProps, TreeState> {
    static defaultProps = {
        overscanCount: 5,
    };

    constructor(props: TreeProps) {
        super(props);

        this.state = {
            scrollTop: 0,
            height: 0,
        };
    }

    componentWillUnmount() {
        this.handleResize.cancel();
    }

    flattenTreeItems = makeMemoizedFlattenTreeItems();

    // This will be called immediately when ResizeDetector mounts
    handleResize = _.debounce(
        (_width: number, height: number) => {
            if (height !== this.state.height) {
                this.setState({ height });
            }
        },
        50,
        { leading: true }
    );

    handleScroll = ({ scrollTop }: { scrollTop: number }) => {
        this.setState({ scrollTop });
    };

    render() {
        const {
            items,
            emptyState,
            error,
            className,
            loading,
            loadingState,
            overscanCount,
            disableClick,
            disableExpansion,
        } = this.props;

        const { height, scrollTop } = this.state;

        let content;
        if (loading) {
            content = loadingState;
        } else if (error) {
            content = error;
        } else if (items.length === 0) {
            content = emptyState;
        } else {
            const flatTreeItems = this.flattenTreeItems(items);

            // Figure out the range of items to render, based on the overscan.
            // (The range includes firstItemIndex and excludes lastItemIndex.)
            const firstItemIndex = Math.max(
                0,
                Math.floor(scrollTop / ITEM_HEIGHT) - overscanCount
            );
            const lastItemIndex = Math.min(
                flatTreeItems.length,
                Math.ceil((scrollTop + height) / ITEM_HEIGHT) + overscanCount
            );
            const renderedItems = [];

            for (let i = firstItemIndex; i < lastItemIndex; i++) {
                renderedItems.push(
                    <NestedTreeItem
                        treeItem={flatTreeItems[i]}
                        key={i}
                        disableClick={disableClick}
                        disableExpansion={disableExpansion}
                    />
                );
            }

            // If we don't put a tabIndex, this happens in Chrome: A user
            // clicks in the list on some item and tries to scroll the list
            // with the arrow keys. Once the clicked item is sufficiently
            // off-screen, it is no longer rendered due to virtualization, the
            // tree loses focus, and arrow keys stop scrolling.
            content = (
                <CustomScrollbar onScrollFrame={this.handleScroll}>
                    <div
                        className="tree-inner"
                        style={{ height: flatTreeItems.length * ITEM_HEIGHT }}
                        tabIndex={0}
                    >
                        <div
                            className="items"
                            style={{ top: firstItemIndex * ITEM_HEIGHT }}
                        >
                            {renderedItems}
                        </div>
                    </div>
                </CustomScrollbar>
            );
        }

        return (
            <div className={classnames("components-nested-tree", className)}>
                {content}

                <ResizeDetector onResize={this.handleResize} />
            </div>
        );
    }
}
