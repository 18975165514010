import { Maybe } from "util/maybe";
import { SortDirection, TableSort } from "util/sort";
import { ReactChildrenArray } from "util/react-children-array";
import { MvFilters } from "data/reducers/management-views";
import { MvActivityType, MvNode, MvNodeId } from "data/models";
import { State, DispatchFunction } from "data";
import { Router, State as RouteState } from "router5";
import { MvActivitiesSelection } from "data/selectors/management-views";
import { TabProps } from "view/monitor/mv-header";
import { MvContext } from "view/monitor/mv-table";
import { FilterProps } from "view/monitor/mv-header";
import { MvColumnKey } from "memsql/mv-column-info";
import { ColumnId } from "view/components/super-table";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRoute } from "react-router5";

import { mvChangeFilters, mvSortQueries } from "data/actions/management-views";
import {
    mvActivitiesSelector,
    selectMvNodes,
    selectMvContext,
} from "data/selectors/management-views";
import { selectRoute } from "data/selectors/routes";

import CenteringWrapper from "view/components/centering-wrapper";
import MvHeader from "view/monitor/mv-header";
import MvTable from "view/monitor/mv-table";
import ExtLink from "view/components/external-link";
import GeneralError from "view/common/general-error";
import MvFeatureCard from "view/monitor/mv-feature-card";
import { MenuItem } from "view/common/menu";
import MVRecord from "view/monitor/mv-record";
import { nextTableSort } from "util/sort";

import {
    ACTIVITIES_COLUMNS,
    ACTIVITIES_COLUMNS_ADVANCED,
} from "memsql/mv-column-info";

import assign from "util/assign";
import * as analytics from "util/segment";

import "./page-activities.scss";

type StateProps = {
    selection: MvActivitiesSelection;
    lastUpdate: Maybe<Date>;
    context: MvContext;
    nodes: { [id in MvNodeId]: MvNode };
    filters: MvFilters;
    sort: TableSort;
    selectedStatsColumns: Array<MvColumnKey>;
    route: RouteState;
    error: Maybe<string>;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
    router: Router;
};

type HeaderTab = {
    type: Maybe<MvActivityType>;
    name: string;
    advanced: boolean;
};

const HEADER_TABS: Array<HeaderTab> = [
    { type: "System", name: "System", advanced: true },
    { type: "Database", name: "Database", advanced: true },
    { type: undefined, name: "All", advanced: true },
];

const DEFAULT_ACTIVITY_TYPE: MvActivityType = "Query";

function activityTypeToParam(type: Maybe<MvActivityType>): Maybe<string> {
    if (!type) {
        return "All";
    }
    if (type === DEFAULT_ACTIVITY_TYPE) {
        return undefined;
    }
    return type;
}

function paramToActivityType(param: Maybe<string>): Maybe<MvActivityType> {
    switch (param) {
        case undefined:
            return DEFAULT_ACTIVITY_TYPE;

        case "All":
            return undefined;

        case "System":
        case "Query":
        case "Database":
            return param;

        default:
            return DEFAULT_ACTIVITY_TYPE;
    }
}

class Activities extends React.Component<Props> {
    componentWillMount() {
        this.updateFilters(this.props, true);
    }

    componentWillReceiveProps(nextProps: Props) {
        this.updateFilters(nextProps, false);
    }

    renderActions = (
        activityName: string
    ): ReactChildrenArray<typeof MenuItem> => {
        const {
            router,
            route: {
                params: { clusterId },
            },
        } = this.props;

        const nav = () => {
            router.navigate("cluster.activities.nodes", {
                clusterId,
                activityName,
            });

            analytics.track("inspect-nodes", {
                category: "management-views",
            });
        };

        return [
            <MenuItem key={0} action={nav}>
                Inspect Nodes
            </MenuItem>,
        ];
    };

    updateFilters = (props: Props, force: boolean) => {
        const { nodeId, activityType: activityTypeParam } = props.route.params;
        const activityType = paramToActivityType(activityTypeParam);
        const {
            nodeId: nodeIdFilter,
            activityType: activityTypeFilter,
        } = props.filters;

        // if the url params don't align with the current filters, then lets
        // update the current filters.
        if (
            force ||
            nodeId !== nodeIdFilter ||
            activityType !== activityTypeFilter
        ) {
            const changeFiltersAction: MvFilters = {
                nodeId,
                activityType,
            };

            props.dispatch(mvChangeFilters(changeFiltersAction));
        }
    };

    handleFilterActivityType = (activityType: Maybe<MvActivityType>) => {
        const { params } = this.props.route;
        this.props.router.navigate(
            "cluster.activities",
            assign(params, {
                activityType: activityTypeToParam(activityType),
            })
        );
    };

    handleSort = (columnId: ColumnId, sortDir: Maybe<SortDirection>) => {
        this.props.dispatch(
            mvSortQueries({
                sort: nextTableSort(columnId, sortDir),
                isUserCaused: true,
            })
        );
    };

    handleDisableNodeIdFilter = () => {
        const { router, route } = this.props;

        router.navigate(route.name, {
            ..._.omit(route.params, "nodeId"),
        });

        analytics.track("disable-queries-filter", {
            category: "management-views",
        });
    };

    renderPage = (inner: React.ReactNode) => {
        return (
            <div className="monitor-page-activities">
                <MVRecord />
                {inner}
            </div>
        );
    };

    render() {
        const {
            selection,
            lastUpdate,
            filters: { nodeId: nodeIdFilter, activityType: activityTypeFilter },
            sort,
            selectedStatsColumns,
            context,
            nodes,
            route: {
                params: { advanced: advancedFlag },
            },
            error,
            dispatch,
        } = this.props;

        if (error) {
            return <GeneralError error={error} />;
        }

        const headerTabs: Array<TabProps<Maybe<MvActivityType>>> = HEADER_TABS
            // Hide non-advanced attributes by default; show them if the
            // advanced query parameter is used.
            .filter(tab => advancedFlag || !tab.advanced)
            .map(tab => ({
                id: tab.type,
                name: tab.name,
                active: activityTypeFilter === tab.type,
                onClick: this.handleFilterActivityType,
            }));

        let headerFilters: Array<FilterProps> = [];
        if (nodeIdFilter) {
            const node = nodes[nodeIdFilter];
            const nodeName = node
                ? `${node.ipAddr}:${node.port}`
                : nodeIdFilter;

            headerFilters.push({
                id: "node-filter",
                description: `Queries running on ${nodeName}`,
                onDisable: this.handleDisableNodeIdFilter,
            });
        }

        const header = <MvHeader tabs={headerTabs} filters={headerFilters} />;

        if (!lastUpdate) {
            return this.renderPage(
                <>
                    {header}
                    <CenteringWrapper>
                        <MvFeatureCard
                            title="Profile Query Resource Usage"
                            iconName="profile-query"
                        >
                            To profile the queries running on this cluster,
                            click the button below or in the upper right corner.{" "}
                            <ExtLink
                                name="studio-resource-usage"
                                category="resource-usage"
                            >
                                Learn more.
                            </ExtLink>
                        </MvFeatureCard>
                    </CenteringWrapper>
                </>
            );
        }

        const columns = advancedFlag
            ? ACTIVITIES_COLUMNS_ADVANCED
            : ACTIVITIES_COLUMNS;

        return this.renderPage(
            <>
                {header}
                <MvTable<"activityName">
                    dispatch={dispatch}
                    rowIdColumn="activityName"
                    onSort={this.handleSort}
                    sort={sort}
                    context={context}
                    columns={columns}
                    selectedStatsColumns={selectedStatsColumns}
                    rows={selection.highLevel}
                    nestedRows={selection.lowLevel}
                    renderActions={this.renderActions}
                />
            </>
        );
    }
}

export default compose(
    withRoute,
    connect(
        (s: State): StateProps => ({
            selection: mvActivitiesSelector(s),
            lastUpdate: s.managementViews.lastUpdate,
            filters: s.managementViews.filters,
            context: selectMvContext(s),
            sort: s.managementViews.queriesSort,
            selectedStatsColumns: s.managementViews.selectedStatsColumns,
            nodes: selectMvNodes(s),
            route: selectRoute(s),
            error: s.managementViews.error,
        })
    )
)(Activities);
