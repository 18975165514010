import { Nullable } from "util/nullable";

import BigNumber from "vendor/bignumber.js/bignumber";

import SqlString from "sqlstring";

export const SQL_LEAVES_QUERY = `
    SELECT
        HOST AS host,
        PORT AS port,
        AVAILABILITY_GROUP AS availabilityGroup,
        PAIR_HOST AS pairHost,
        PAIR_PORT AS pairPort,
        STATE AS state,
        OPENED_CONNECTIONS AS openedConnections,
        AVERAGE_ROUNDTRIP_LATENCY AS averageLatency
    FROM
        INFORMATION_SCHEMA.LEAVES;
`;

export type SQLLeavesRow = {
    host: string;
    port: BigNumber;
    availabilityGroup: BigNumber;
    pairHost: string;
    pairPort: Nullable<BigNumber>;
    state: string;
    openedConnections: BigNumber;
    averageLatency: Nullable<number>;
};

export const SQL_AGGREGATORS_QUERY = `
    SELECT
        HOST AS host,
        PORT AS port,
        MASTER_AGGREGATOR AS masterAggregator,
        STATE AS state,
        OPENED_CONNECTIONS AS openedConnections,
        AVERAGE_ROUNDTRIP_LATENCY AS averageLatency
    FROM
        INFORMATION_SCHEMA.AGGREGATORS;
`;

export type SQLAggregatorsRow = {
    host: string;
    port: BigNumber;
    masterAggregator: BigNumber;
    state: string;
    openedConnections: BigNumber;
    averageLatency: Nullable<number>;
};

function SQL_WHERE_IP_ADDR(ipAddress?: string) {
    if (ipAddress) {
        return SqlString.format("WHERE IP_ADDR = ?", [ipAddress]);
    }
}

// We read CFS_QUOTA_NS or CFS_PERIOD_NS as unsigned since the engine doesn't
// support unsigned big ints in information_schema causing this column to return
// negative numbers for really large ints.
export const SQL_NODES_LIVE_CPU = (ipAddress?: string) => `
    SELECT
        IP_ADDR AS ipAddress,
        CONCAT(IP_ADDR, ":", PORT) AS nodeAddress,
        MEMSQL_TOTAL_CUMULATIVE_NS AS memsqlTotalUsedCumulativeNs,
        CAST(CFS_QUOTA_NS AS UNSIGNED) AS cgroupQuotaNs,
        CAST(CFS_PERIOD_NS AS UNSIGNED) AS cgroupPeriodNs,
        TIMESTAMP_NS AS timestampNs,
        NUM_CPUS AS numCpus
    FROM
        INFORMATION_SCHEMA.MV_SYSINFO_CPU
    ${SQL_WHERE_IP_ADDR(ipAddress)}
`;

export type SQLNodesLiveCPURow = {
    nodeAddress: string;
    memsqlTotalUsedCumulativeNs: Nullable<BigNumber>;
    cgroupQuotaNs: Nullable<BigNumber>;
    cgroupPeriodNs: Nullable<BigNumber>;
    timestampNs: Nullable<BigNumber>;
    numCpus: Nullable<BigNumber>;
};

// We use `MEMSQL_B` as opposed to `HOST_USED_B` since we need per-node memory
// used.
// We read CGROUP_TOTAL_B as unsigned since the engine doesn't support unsigned
// big ints in information_schema causing this column to return negative numbers
// for really large ints.
export const SQL_NODES_LIVE_MEMORY = (ipAddress?: string) => `
    SELECT
        IP_ADDR AS ipAddress,
        CONCAT(IP_ADDR, ":", PORT) AS nodeAddress,
        MEMSQL_B AS usedMemoryB,
        HOST_TOTAL_B AS totalMemoryB,
        CAST(CGROUP_TOTAL_B AS UNSIGNED) AS cgroupMemoryLimitB,
        TIMESTAMP_NS AS timestampNs
    FROM
        INFORMATION_SCHEMA.MV_SYSINFO_MEM
    ${SQL_WHERE_IP_ADDR(ipAddress)}
`;

export type SQLNodesLiveMemoryRow = {
    nodeAddress: string;
    usedMemoryB: Nullable<BigNumber>;
    totalMemoryB: Nullable<BigNumber>;
    cgroupMemoryLimitB: Nullable<BigNumber>;
    timestampNs: Nullable<BigNumber>;
};

export const SQL_NODES_LIVE_DISK = (ipAddress?: string) => `
    SELECT
        IP_ADDR AS ipAddress,
        PORT AS port,
        CONCAT(IP_ADDR, ":", PORT) AS nodeAddress,
        SUM(MOUNT_TOTAL_B) AS totalDiskB,
        SUM(MOUNT_USED_B) AS usedDiskB
    FROM
        INFORMATION_SCHEMA.MV_SYSINFO_DISK
    ${SQL_WHERE_IP_ADDR(ipAddress)}
    GROUP BY
        ipAddress, port
`;

export type SQLNodesLiveDiskRow = {
    nodeAddress: string;
    totalDiskB: Nullable<BigNumber>;
    usedDiskB: Nullable<BigNumber>;
};
