import { Maybe } from "util/maybe";
import { Level } from "view/util/level";

import { RouteInfo } from "router/types";
import { ExternalLinkInfo } from "view/components/external-link";
import { InlineSVG } from "view/components/icon";

import * as React from "react";
import _ from "lodash";
import classnames from "classnames";

import InternalLink from "view/components/internal-link";
import Card from "view/components/card";
import Loading from "view/components/loading";
import ExtLink from "view/components/external-link";
import Icon from "view/components/icon";
import { FakeClick } from "view/common/fake-click";

import { logError } from "util/logging";
import { ReactChildrenArray } from "util/react-children-array";

import "./dashboard-card.scss";

type BlockProps = {
    title: React.ReactNode;
    label: React.ReactNode;
    status: Level;
    leftMargin?: boolean;
    rightMargin?: boolean;
    fullWidth?: boolean;
};

export class Block extends React.PureComponent<BlockProps> {
    render() {
        const { title, label, status, fullWidth } = this.props;

        const classes = classnames("block", status, {
            "full-width": fullWidth,
        });

        return (
            <FakeClick name="dashboard-block" className={classes}>
                <div className="title">{title}</div>
                <div className="label">{label}</div>
            </FakeClick>
        );
    }
}

type MetricsBlockProps = {
    children: ReactChildrenArray<typeof Block>;
};

export class MetricsBlock extends React.PureComponent<MetricsBlockProps> {
    render() {
        return (
            <div className="common-dashboard-card-metrics">
                {this.props.children}
            </div>
        );
    }
}

type BaseProps = {
    title: string;
    pageRouteInfo?: RouteInfo;
};

type CardHeaderProps = BaseProps;

class CardHeader extends React.PureComponent<CardHeaderProps> {
    render() {
        const { title, pageRouteInfo } = this.props;

        let pageLink;
        if (pageRouteInfo) {
            pageLink = (
                <InternalLink
                    category="dashboard"
                    routeInfo={pageRouteInfo}
                    className="view-all"
                >
                    View All
                </InternalLink>
            );
        }

        return (
            <div className="dashboard-card-header">
                <div className="card-title">{title}</div>
                {pageLink}
            </div>
        );
    }
}

type ErrorStateProps = BaseProps & {
    error: string;
    className?: string;
};

class ErrorCard extends React.PureComponent<ErrorStateProps> {
    render() {
        const { title, error, pageRouteInfo, className } = this.props;
        const classes = classnames("error-container", className);

        return (
            <Card className="dashboard-card error">
                <CardHeader title={title} pageRouteInfo={pageRouteInfo} />

                <div className={classes}>
                    <Icon
                        icon="exclamation-circle"
                        iconType="regular"
                        size="4x"
                        className="icon"
                    />

                    <div className="title">Unable to load</div>

                    <div className="message-container">
                        An error occured while loading this card's data:
                        <div className="message">{error}</div>
                    </div>
                </div>
            </Card>
        );
    }
}

type LoadingStateProps = BaseProps;

class LoadingCard extends React.PureComponent<LoadingStateProps> {
    render() {
        const { title, pageRouteInfo } = this.props;

        return (
            <Card className="dashboard-card loading">
                <CardHeader title={title} pageRouteInfo={pageRouteInfo} />

                <div className="loading-container">
                    <Loading outlineOnly size="medium" />
                    Loading
                </div>
            </Card>
        );
    }
}

type EmptyStateProps = {
    emptyTitle: string;
    emptyDescription: string;
    emptyHelpLink: ExternalLinkInfo;
    icon: InlineSVG;
    className?: string;
};

export class EmptyCard extends React.PureComponent<EmptyStateProps> {
    render() {
        const {
            emptyTitle,
            emptyDescription,
            emptyHelpLink,
            className,
            icon: DashboardCardIcon,
        } = this.props;

        const classes = classnames("dashboard-card", "empty", className);

        return (
            <Card className={classes}>
                <div className="empty-container">
                    <DashboardCardIcon />

                    <div className="title">{emptyTitle}</div>
                    <div className="description">{emptyDescription}</div>

                    <ExtLink {...emptyHelpLink} className="help-link">
                        Learn More
                    </ExtLink>
                </div>
            </Card>
        );
    }
}

type SuccessStateProps = BaseProps & {
    content: React.ReactNode;
    docsRouteInfo: ExternalLinkInfo;
};

class SuccessCard extends React.PureComponent<SuccessStateProps> {
    render() {
        const { title, content, pageRouteInfo, docsRouteInfo } = this.props;

        return (
            <Card className="dashboard-card success">
                <CardHeader title={title} pageRouteInfo={pageRouteInfo} />
                <div className="content">
                    {content}
                    <div className="card-footer">
                        <ExtLink {...docsRouteInfo} className="docs-link">
                            <Icon
                                icon="external-link"
                                iconType="solid"
                                rightMargin
                            />
                            Learn More
                        </ExtLink>
                    </div>
                </div>
            </Card>
        );
    }
}

type CommonProps = {
    title: string;
    pageRouteInfo?: RouteInfo;
    className?: string;

    loading: boolean;

    // success state
    content: Maybe<React.ReactNode>;
    docsRouteInfo: ExternalLinkInfo;

    // error state
    error: Maybe<string>;
    errorClassName?: string;
};

type PropsEmpty = CommonProps & {
    hasEmptyState: true;
    emptyTitle: string;
    emptyDescription: string;
    icon: InlineSVG;
    emptyHelpLink: ExternalLinkInfo;
    emptyClassName?: string;
};

type PropsNotEmpty = CommonProps & { hasEmptyState: false };

// The Dashboard Card has a loading state, success state, and error state. The
// empty state is optional — not all of the dashboard cards have one. Thus,
// clients of the Dashboard component have to specify whether they have an empty
// state or not.
export type DashboardCardProps = PropsEmpty | PropsNotEmpty;

export class DashboardCard extends React.Component<DashboardCardProps> {
    render() {
        const {
            loading,
            error,
            title,
            content,
            pageRouteInfo,
            docsRouteInfo,
            className,
            errorClassName,
        } = this.props;

        const classes = classnames(
            "common-dashboard-card-container",
            className
        );

        let card;
        if (loading) {
            card = <LoadingCard title={title} pageRouteInfo={pageRouteInfo} />;
        } else if (error) {
            card = (
                <ErrorCard
                    title={title}
                    error={error}
                    pageRouteInfo={pageRouteInfo}
                    className={errorClassName}
                />
            );
        } else if (content) {
            card = (
                <SuccessCard
                    title={title}
                    content={content}
                    pageRouteInfo={pageRouteInfo}
                    docsRouteInfo={docsRouteInfo}
                />
            );
        } else if (this.props.hasEmptyState) {
            const {
                emptyTitle,
                emptyDescription,
                emptyHelpLink,
                emptyClassName,
                icon,
            } = this.props;

            card = (
                <EmptyCard
                    emptyTitle={emptyTitle}
                    emptyDescription={emptyDescription}
                    emptyHelpLink={emptyHelpLink}
                    icon={icon}
                    className={emptyClassName}
                />
            );
        } else {
            logError(
                new Error(
                    "Unexpected error while rendering the dashboard card: Unknown error."
                )
            );

            card = (
                <ErrorCard
                    title={title}
                    error="Unknown error: Please contact the support team."
                    pageRouteInfo={pageRouteInfo}
                />
            );
        }

        return <div className={classes}>{card}</div>;
    }
}
