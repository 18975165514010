// This file has to be in sync with colors.scss.

export const COLORS = {
    "color-neutral-999": "#000000",
    "color-neutral-900": "#1B1A21",
    "color-neutral-800": "#4C4A57",
    "color-neutral-700": "#777582",
    "color-neutral-500": "#C2C1C7",
    "color-neutral-300": "#E6E5EA",
    "color-neutral-200": "#F3F3F5",
    "color-neutral-100": "#FAFAFA",
    "color-neutral-0": "#FFFFFF",

    "color-red-900": "#C41337",
    "color-red-800": "#F4346F",
    "color-red-600": "#F85B8A",
    "color-red-400": "#FFC2D4",
    "color-red-200": "#FFEBF2",

    "color-yellow-900": "#9E6D00",
    "color-yellow-800": "#FFB000",
    "color-yellow-600": "#FFCF64",
    "color-yellow-400": "#FFE694",
    "color-yellow-200": "#FFFADE",

    "color-green-900": "#00873F",
    "color-green-800": "#00CA68",
    "color-green-600": "#00E676",
    "color-green-400": "#A8FFCE",
    "color-green-200": "#E6FCEE",

    "color-cyan-900": "#00408F",
    "color-cyan-800": "#0081D7",
    "color-cyan-600": "#00F5FE",
    "color-cyan-400": "#A6FCFF",
    "color-cyan-200": "#D9FEFF",

    "color-indigo-900": "#1E0A78",
    "color-indigo-800": "#311B92",
    "color-indigo-600": "#4F34C7",
    "color-indigo-400": "#C0B7EB",
    "color-indigo-200": "#E8E6F5",
    "color-indigo-100": "#F3F2FC",

    "color-purple-900": "#8800CC",
    "color-purple-800": "#AA00FF",
    "color-purple-600": "#C550FF",
    "color-purple-400": "#ECC7FF",
    "color-purple-200": "#F9EDFF",

    "color-magenta-900": "#B300B3",
    "color-magenta-800": "#FF00FF",
    "color-magenta-600": "#FF7BFF",
    "color-magenta-400": "#FFBFFF",
    "color-magenta-200": "#FFEBFF",

    "color-orange": "#FF7438",
};
