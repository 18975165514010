import {
    LatestMemsqlVersionAction,
    LatestMemsqlVersionPayload,
    LatestStudioVersionAction,
    LatestStudioVersionPayload,
} from "data/actions";

import { Version } from "util/version";
import { logError, NonLoggableError } from "util/logging";
import { Observable } from "rxjs";
import { makeActionCreator } from "worker/api/helpers";

const LATEST_URL =
    "https://release.memsql.com/production/index/memsqlserver/latest.json";

const LATEST_STUDIO_URL =
    "https://release.memsql.com/production/index/memsqlstudio/latest.json";

function fetchLatestMemsqlVersion(): Promise<LatestMemsqlVersionPayload> {
    return fetch(LATEST_URL)
        .then((response: Response) => response.json())
        .then(
            json => ({
                version: Version.fromString(json.version),
            }),
            res => {
                throw new NonLoggableError(res);
            }
        );
}

function fetchLatestStudioVersion(): Promise<LatestStudioVersionPayload> {
    return fetch(LATEST_STUDIO_URL)
        .then((response: Response) => response.json())
        .then(
            json => ({
                version: Version.fromString(json.version),
            }),
            res => {
                throw new NonLoggableError(res);
            }
        );
}

const createLatestMemsqlVersionAction = (
    data: LatestMemsqlVersionPayload
): LatestMemsqlVersionAction => {
    return {
        type: "LATEST_MEMSQL_VERSION",
        error: false,
        payload: {
            loading: false,
            data,
        },
    };
};

const createLatestStudioVersionAction = (
    data: LatestStudioVersionPayload
): LatestStudioVersionAction => {
    return {
        type: "LATEST_STUDIO_VERSION",
        error: false,
        payload: {
            loading: false,
            data,
        },
    };
};

export const queryLatestMemsqlVersion = makeActionCreator({
    name: "queryLatestMemsqlVersion",

    handle: (): Observable<LatestMemsqlVersionAction> => {
        const $loading = Observable.of<LatestMemsqlVersionAction>({
            type: "LATEST_MEMSQL_VERSION",
            error: false,
            payload: { loading: true },
        });

        const $compute: Observable<
            LatestMemsqlVersionAction
        > = Observable.fromPromise(
            fetchLatestMemsqlVersion()
                .then(createLatestMemsqlVersionAction)
                .catch(
                    (
                        err: Error | NonLoggableError
                    ): LatestMemsqlVersionAction => {
                        logError(err);

                        return {
                            type: "LATEST_MEMSQL_VERSION",
                            error: true,
                            payload: err.message,
                        };
                    }
                )
        );

        return Observable.merge($loading, $compute);
    },
});

export const queryLatestStudioVersion = makeActionCreator({
    name: "queryLatestStudioVersion",

    handle: (): Observable<LatestStudioVersionAction> => {
        const $loading = Observable.of<LatestStudioVersionAction>({
            type: "LATEST_STUDIO_VERSION",
            error: false,
            payload: { loading: true },
        });

        const $compute: Observable<
            LatestStudioVersionAction
        > = Observable.fromPromise(
            fetchLatestStudioVersion()
                .then(createLatestStudioVersionAction)
                .catch(
                    (
                        err: Error | NonLoggableError
                    ): LatestStudioVersionAction => {
                        logError(err);

                        return {
                            type: "LATEST_STUDIO_VERSION",
                            error: true,
                            payload: err.message,
                        };
                    }
                )
        );

        return Observable.merge($loading, $compute);
    },
});
