import { Maybe } from "util/maybe";

import { State as ReduxState, DispatchFunction } from "data";
import { SnapshotMetaData } from "data/reducers/management-views";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Button } from "view/common/button";
import Modal from "view/components/modal";
import Loading from "view/components/loading";
import TimeDelta from "view/components/time-delta";
import ExtLink from "view/components/external-link";

import { mvFullStop } from "worker/api/management-views";
import { cancelMvRecording } from "data/actions/management-views";

import { addSeconds, differenceInSeconds, format } from "date-fns";

import "./mv-record.scss";

type StateProps = {
    recording: boolean;
    startSnapshot?: SnapshotMetaData;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

const STATE_WAITING_START = "waiting-start";
const STATE_RECORDING = "recording";
const STATE_WAITING_STOP = "waiting-stop";
const STATE_CLOSED = "closed";

type ModalState = "waiting-start" | "recording" | "waiting-stop" | "closed";

class MVRecord extends React.Component<Props> {
    getModalState = (
        startSnapshot: Maybe<SnapshotMetaData>,
        recording: boolean
    ): ModalState => {
        if (recording && !startSnapshot) {
            // state #1
            return STATE_WAITING_START;
        } else if (startSnapshot && recording) {
            // state #2
            return STATE_RECORDING;
        } else if (startSnapshot && !recording) {
            // state #3 — (!recording && startSnapshot)
            return STATE_WAITING_STOP;
        }

        return STATE_CLOSED;
    };

    handleStopRecording = () => {
        const { startSnapshot, dispatch } = this.props;

        if (startSnapshot && !startSnapshot.fixedInterval) {
            dispatch(
                mvFullStop({
                    startActivities: startSnapshot.activities,
                    startTime: startSnapshot.snapshotTime,
                })
            );
        }
    };

    handleCancelRecording = () => {
        const { dispatch } = this.props;

        dispatch(cancelMvRecording());
    };

    formatTimer = (now: Date, date: Date, startSnapshot: SnapshotMetaData) => {
        // If the recording is happening over a fixed interval, then we show a
        // countdown instead of how long has passed.
        if (startSnapshot.fixedInterval) {
            const endTime = addSeconds(date, startSnapshot.deltaTimeS);

            // We clamp the number of seconds left to 0 so that we don't ever
            // show a negative number.
            const secondsLeft = Math.max(differenceInSeconds(endTime, now), 0);

            return format(addSeconds(new Date(0), secondsLeft), "mm:ss");
        } else {
            return format(now.getTime() - date.getTime(), "mm:ss");
        }
    };

    render() {
        const { startSnapshot, recording } = this.props;

        // There are 3 possible states for this modal:
        // 1. user has requested Start, waiting on first snapshot ("waiting-start")
        // 2. recording ("recording")
        // 3. user has requested Finish, waiting on second snapshot ("waiting-stop")

        let title, inner, actions;

        const modalState = this.getModalState(startSnapshot, recording);

        if (modalState === STATE_RECORDING) {
            // state #2

            if (!(startSnapshot && recording)) {
                throw new Error(
                    "Expected startSnapshot to exist and recording to be true."
                );
            }

            title = "Recording…";

            let helpText;
            if (startSnapshot.fixedInterval) {
                helpText = `Query execution activities performed during
                the recorded time will then be available for inspection.
                During recording, other Studio features are available to
                use.`;
            } else {
                helpText = `Click Finish when you want to stop recording cluster
                activity. Query execution activities performed during
                the recorded time will then be available for inspection.
                During recording, other Studio features are available to
                use.`;
            }

            inner = (
                <>
                    <div className="timer">
                        <TimeDelta
                            date={startSnapshot.snapshotTime}
                            format={(first, second) =>
                                this.formatTimer(first, second, startSnapshot)
                            }
                        />
                    </div>

                    <div className="text">{helpText}</div>
                </>
            );

            if (!startSnapshot.fixedInterval) {
                actions = (
                    <>
                        <Button
                            large
                            onClick={this.handleCancelRecording}
                            className="cancel-btn"
                        >
                            Cancel
                        </Button>

                        <Button
                            primary
                            large
                            onClick={this.handleStopRecording}
                        >
                            Finish
                        </Button>
                    </>
                );
            }
        } else if (modalState === STATE_WAITING_START) {
            title = "Preparing to Record";

            inner = (
                <>
                    <Loading
                        outlineOnly
                        size="medium"
                        className="loading-spinner"
                    />

                    <div className="text">
                        <div>
                            This may take a while if your cluster is large.
                        </div>
                        <div>
                            <ExtLink
                                category="resource-usage"
                                name="studio-resource-usage"
                            >
                                Learn more about Resource Usage.
                            </ExtLink>
                        </div>
                    </div>
                </>
            );

            actions = (
                <Button
                    large
                    onClick={this.handleCancelRecording}
                    className="cancel-btn"
                >
                    Cancel
                </Button>
            );
        } else if (modalState === STATE_WAITING_STOP) {
            title = "Processing Data";

            inner = (
                <>
                    <Loading
                        outlineOnly
                        size="medium"
                        className="loading-spinner"
                    />

                    <div className="text">
                        <div>
                            This may take a while if your cluster is large.
                        </div>
                        <div>
                            <ExtLink
                                category="resource-usage"
                                name="studio-resource-usage"
                            >
                                Learn more about Resource Usage.
                            </ExtLink>
                        </div>
                    </div>
                </>
            );

            actions = (
                <Button
                    large
                    onClick={this.handleCancelRecording}
                    className="cancel-btn"
                >
                    Cancel
                </Button>
            );
        }

        return (
            <Modal
                active={!!inner}
                inline
                className="mv-record-modal"
                actions={actions}
                innerContentPadding
            >
                <div className="mv-record-content">
                    <div className="title">{title}</div>
                    <div className="inner">{inner}</div>
                </div>
            </Modal>
        );
    }
}

export default connect(
    (s: ReduxState): StateProps => ({
        startSnapshot: s.managementViews.startSnapshot,
        recording: s.managementViews.recording,
    })
)(MVRecord);
