import { Maybe } from "util/maybe";

import * as React from "react";

import Icon from "view/components/icon";
import Portal from "view/components/portal";

import "./toast.scss";

type Props = {
    // Delay in ms for toast to become visible on the page
    delay: number;

    // Whether or not to render the toast.
    active?: boolean;

    // Time in ms that the toast will be visible on the page
    duration: number;

    children: React.ReactNode;
    iconName: string;

    onHide?: () => void;
};

type State = {
    visible: boolean;
};

export default class Toast extends React.Component<Props, State> {
    static defaultProps = {
        delay: 500,
        duration: 5000,
    };

    state: State = {
        visible: false,
    };

    showTimeout: Maybe<number>;

    hideTimeout: Maybe<number>;

    componentDidMount() {
        if (this.props.active) {
            this.showTimeout = window.setTimeout(this.show, this.props.delay);
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.showTimeout) {
            window.clearTimeout(this.showTimeout);
        }

        if (this.props.active !== nextProps.active && nextProps.active) {
            this.showTimeout = window.setTimeout(this.show, this.props.delay);
        }
    }

    componentWillUnmount() {
        if (this.showTimeout) {
            window.clearTimeout(this.showTimeout);
        }

        if (this.hideTimeout) {
            window.clearTimeout(this.hideTimeout);
        }

        if (this.state.visible && this.props.onHide) {
            this.props.onHide();
        }
    }

    show = () => {
        this.setState({ visible: true }, () => {
            this.hideTimeout = window.setTimeout(
                this.hide,
                this.props.duration
            );
        });
    };

    hide = () => {
        const { onHide } = this.props;

        this.setState({ visible: false }, () => {
            if (onHide) {
                onHide();
            }
        });
    };

    render() {
        const { iconName, active, children } = this.props;
        const { visible } = this.state;

        return (
            <Portal active={active}>
                {visible ? (
                    <div className="components-toast-wrapper">
                        <div className="components-toast">
                            <Icon icon={iconName} className="toast-icon" />
                            {children}
                        </div>
                    </div>
                ) : null}
            </Portal>
        );
    }
}
