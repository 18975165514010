import * as React from "react";
import classnames from "classnames";

import "./top-content.scss";

type TopContentHeaderProps = {
    children: React.ReactNode;
};

type TopContentProps = {
    title: React.ReactNode;
    children: React.ReactNode;
    className?: string;
};

type TopContentRowProps = {
    children: React.ReactNode;
    className?: string;
};

export const TopContentHeader = ({ children }: TopContentHeaderProps) => (
    <div className="components-top-content-header">{children}</div>
);

export const TopContentRow = ({ children }: TopContentRowProps) => (
    <div className="components-top-content-row">{children}</div>
);

export const TopContent = ({ title, children, className }: TopContentProps) => {
    const contentClassses = classnames("inner", className);

    return (
        <div className="components-top-content">
            <div className="title">{title}</div>

            <div className={contentClassses}>{children}</div>
        </div>
    );
};
