import { Maybe } from "util/maybe";

// Performs a 2-way outer join given 2 arrays which are
// sorted the same way. The resulting array can have
// elements which are of either of the types that were
// passed in (T or T2), but also of the "merged" type T3.
// This is because elements are returned whether they can
// be merged or not.
export default function outerJoin<T, T2, T3>(
    comparator: (first: T, second: T2) => -1 | 0 | 1,
    // Both of the arguments to `merge` are optional because
    // it can either take BOTH of them or ONLY one of them
    // (with the other being `undefined`). It will NEVER be
    // called without either of them. If this function
    // ever returns `undefined`, we will not push that to the
    // resulting array.
    merge: (l: Maybe<T>, r: Maybe<T2>) => Maybe<T3>,
    arr1: Array<T>,
    arr2: Array<T2>
): Array<T3> {
    const output: Array<T3> = [];

    let iterator1 = 0;
    let iterator2 = 0;

    const pushToOutput = (val: Maybe<T3>) => {
        if (val) {
            output.push(val);
        }
    };

    while (iterator1 < arr1.length && iterator2 < arr2.length) {
        const comparison = comparator(arr1[iterator1], arr2[iterator2]);

        if (comparison === 0) {
            pushToOutput(merge(arr1[iterator1], arr2[iterator2]));
            iterator1++;
            iterator2++;
        } else {
            if (comparison === -1) {
                pushToOutput(merge(arr1[iterator1], undefined));
                iterator1++;
            } else if (comparison === 1) {
                pushToOutput(merge(undefined, arr2[iterator2]));
                iterator2++;
            }
        }
    }

    // We want to keep going even if we reached the end of one
    // of the arrays.

    while (iterator1 < arr1.length) {
        pushToOutput(merge(arr1[iterator1], undefined));
        iterator1++;
    }

    while (iterator2 < arr2.length) {
        pushToOutput(merge(undefined, arr2[iterator2]));
        iterator2++;
    }

    return output;
}
