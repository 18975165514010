import { Maybe } from "util/maybe";

import { StaticConnectionId } from "worker/net/connection-manager";
import { DispatchFunction, State } from "data";
import { QueryExecutorState } from "worker/net/query-executor";

import * as React from "react";
import { connect } from "react-redux";

import { staticQueryGroup } from "worker/api/connection";
import { consoleEditQuery, consoleShiftQuery } from "data/actions/console";

import { extractQueries } from "util/sql-query";

import Loading from "view/components/loading";
import Console from "view/bottom-panel/console";

type StateProps = {
    connectionId: StaticConnectionId;
    queryExecutorState: Maybe<QueryExecutorState>;
    buffer: string;
    currentQuery: string;
};

type Props = {
    dispatch: DispatchFunction;
};

class TabConsole extends React.Component<Props & StateProps> {
    onQueryChange = (query: string) => {
        this.props.dispatch(consoleEditQuery({ query }));
    };

    onQueryShift = (direction: "UP" | "DOWN") => {
        this.props.dispatch(consoleShiftQuery({ direction }));
    };

    onQuery = (queryText: string) => {
        const id = this.props.connectionId;

        if (queryText.trim() === "") {
            this.props.dispatch({
                type: "CONSOLE_EMPTY_QUERY",
                error: false,
                payload: { id },
            });
        } else {
            this.props.dispatch({
                type: "CONSOLE_QUERY",
                error: false,
                payload: { id, query: queryText },
            });
        }

        this.props.dispatch(
            staticQueryGroup({
                id: this.props.connectionId,
                queryGroup: {
                    queries: extractQueries(queryText).map(query => ({
                        text: query,
                        values: [],
                    })),

                    // These options are duplicated in the actions/console.js file
                    onError: "SKIP",
                    limitAllButLast: false,
                },
            })
        );
    };

    render() {
        const { queryExecutorState, buffer, currentQuery } = this.props;

        if (queryExecutorState) {
            return (
                <Console
                    queryExecutorState={queryExecutorState}
                    buffer={buffer}
                    currentQuery={currentQuery}
                    handleQuery={this.onQuery}
                    handleQueryChange={this.onQueryChange}
                    handleQueryShift={this.onQueryShift}
                />
            );
        } else {
            return <Loading size="large" />;
        }
    }
}

export default connect(
    (s: State): StateProps => ({
        queryExecutorState: s.console.queryExecutorState,
        buffer: s.console.buffer,
        currentQuery: s.console.activeHistory[s.console.editingQueryIndex],
        connectionId: s.console.connectionId,
    })
)(TabConsole);
