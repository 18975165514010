import { Action, SimpleAction } from "data/actions/types";
import { TableSort } from "util/sort";
import { Loading } from "util/loading-state-machine";
import { ActiveProcess } from "data/models";

import { ActiveProcessId } from "data/models";

export type ActiveProcessesPayload = Array<ActiveProcess>;

export type ActiveProcessesAction = Action<
    "ACTIVE_PROCESSES",
    Loading<ActiveProcessesPayload>,
    string
>;

export type SortActiveProcessesAction = SimpleAction<
    "SORT_ACTIVE_PROCESSES",
    TableSort
>;

export const sortActiveProcesses = (
    payload: TableSort
): SortActiveProcessesAction => ({
    type: "SORT_ACTIVE_PROCESSES",
    error: false,
    payload,
});

export type ActiveProcessesEnabledAction = SimpleAction<
    "ACTIVE_PROCESSES_ENABLED",
    boolean
>;

export type ChangeActiveProcessSelectedPayload = {
    processId: ActiveProcessId;
    selected: boolean;
};

export type ChangeActiveProcessSelectedAction = SimpleAction<
    "CHANGE_ACTIVE_PROCESS_SELECTED",
    ChangeActiveProcessSelectedPayload
>;

export const changeActiveProcessSelected = (
    payload: ChangeActiveProcessSelectedPayload
): ChangeActiveProcessSelectedAction => ({
    type: "CHANGE_ACTIVE_PROCESS_SELECTED",
    error: false,
    payload,
});

export type KilledActiveProcessIdsPayload = {
    processIds: Array<ActiveProcessId>;
};

// Note that this action is dispatched after a query to kill active processes,
// and causes the active processes to be displayed as killed. It is not
// involved in killing the active processes.
export type KilledActiveProcessIdsAction = SimpleAction<
    "KILLED_ACTIVE_PROCESS_IDS",
    KilledActiveProcessIdsPayload
>;

export const killedActiveProcessIds = (
    payload: KilledActiveProcessIdsPayload
): KilledActiveProcessIdsAction => ({
    type: "KILLED_ACTIVE_PROCESS_IDS",
    error: false,
    payload,
});
