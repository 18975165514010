import { Maybe } from "util/maybe";

import { GeneralTableColumn } from "view/components/general-table";

import { Pipeline, PipelineState, Batch, PipelineSource } from "data/models";

import * as React from "react";

import Icon from "view/components/icon";
import ConsoleQueryButton from "view/common/console-query-button";

import { COLORS } from "util/colors";
import qualifiedName from "memsql/qualified-name";
import showDefinitionColumn from "util/show-definition-column";
import { LONG_EM_DASH } from "util/symbols";
import { formatBatchTimestamp } from "view/common/models/pipelines";
import getTime from "date-fns/get_time";

import "./columns-info.scss";

const formatPipelineState = (state: PipelineState): React.ReactNode => {
    let iconColor;
    switch (state) {
        case "Running":
            iconColor = "success";
            break;

        case "Stopped":
            iconColor = "warning";
            break;

        case "Error":
        default:
            iconColor = "error";
            break;
    }

    return (
        <div className="pipeline-state">
            <Icon
                className="icon"
                icon="circle"
                size="xs"
                {...{ [iconColor]: true }}
            />
            {state}
        </div>
    );
};

const formatLastBatchState = (batch: Maybe<Batch>): React.ReactNode => {
    if (!batch) {
        return "—";
    }

    let color;
    switch (batch.state) {
        case "Failed":
            color = COLORS["color-red-900"];
            break;

        case "Canceled":
            color = COLORS["color-yellow-900"];
            break;

        default:
            break;
    }

    return <span style={{ color }}>{batch.state}</span>;
};

const getSourceTypeIcon = (sourceType: PipelineSource) => {
    switch (sourceType) {
        case "S3":
            return "s3";

        case "KAFKA":
            return "kafka";

        case "AZURE":
            return "azure";

        case "FS":
            return "filesystem";

        case "HDFS":
            return "hdfs";

        default:
            return "question";
    }
};

const PIPELINES_COLUMNS: Array<GeneralTableColumn<Pipeline>> = [
    {
        id: "pipelineName",
        title: "Name",
        formatter: (pipeline: Pipeline) => (
            <>
                <Icon
                    icon={getSourceTypeIcon(pipeline.sourceType)}
                    size="sm"
                    className="name-icon"
                />
                {pipeline.pipelineName}
            </>
        ),
        getValue: (pipeline: Pipeline) => pipeline.pipelineName,
    },
    {
        id: "databaseName",
        title: "Database Name",
        formatter: (pipeline: Pipeline) => pipeline.databaseName,
        getValue: (pipeline: Pipeline) => pipeline.databaseName,
    },
    {
        id: "pipelineState",
        title: "State",
        formatter: (pipeline: Pipeline) => formatPipelineState(pipeline.state),
        getValue: (pipeline: Pipeline) => pipeline.state,
    },
    {
        id: "pipelineFailedBatches",
        title: "Failed Batches",
        textAlign: "right",
        formatter: (pipeline: Pipeline) => pipeline.numFailedBatches.toString(),
        getValue: (pipeline: Pipeline) => pipeline.numFailedBatches.toNumber(), // PLAT-3189
    },
    {
        id: "pipelineLastBatchState",
        title: "Last Batch State",
        formatter: (pipeline: Pipeline) =>
            formatLastBatchState(pipeline.lastBatch),
        getValue: (pipeline: Pipeline) =>
            pipeline.lastBatch ? pipeline.lastBatch.state : undefined,
    },
    {
        id: "pipelineLastBatchTimestamp",
        title: "Last Batch Time",
        formatter: (pipeline: Pipeline) =>
            pipeline.lastBatch
                ? formatBatchTimestamp(pipeline.lastBatch)
                : LONG_EM_DASH,
        getValue: (pipeline: Pipeline) => {
            if (pipeline.lastBatch && pipeline.lastBatch.timestamp) {
                return getTime(pipeline.lastBatch.timestamp);
            }
        },
    },
    {
        id: "pipelineLastBatchRowsWritten",
        title: "Last Batch Rows Written",
        textAlign: "right",
        formatter: (pipeline: Pipeline) => {
            if (pipeline.lastBatch) {
                return pipeline.lastBatch.rowsWritten === undefined
                    ? LONG_EM_DASH
                    : pipeline.lastBatch.rowsWritten.toString();
            } else {
                return LONG_EM_DASH;
            }
        },
        getValue: (pipeline: Pipeline) =>
            pipeline.lastBatch && pipeline.lastBatch.rowsWritten
                ? pipeline.lastBatch.rowsWritten.toNumber()
                : undefined, // PLAT-3189
    },
    showDefinitionColumn({
        formatter: ({ databaseName, pipelineName }: Pipeline) => (
            <ConsoleQueryButton
                tipProps={{
                    direction: "sw",
                    tooltip:
                        "Get the SHOW CREATE PIPELINE output for this pipeline.",
                }}
                query={`SHOW CREATE PIPELINE ${qualifiedName({
                    databaseName,
                    name: pipelineName,
                })} \\G`}
                category="schema-explorer"
                action="show-create-pipeline"
            />
        ),
    }),
];

export default PIPELINES_COLUMNS;
