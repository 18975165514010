import { Maybe } from "util/maybe";
import { Omit } from "util/omit";
import { State } from "data/reducers";
import {
    CollapsableFormProps,
    CollapsableFormError,
} from "view/common/collapsable-form";
import { SectionValuesList } from "view/common/collapsable-form/section";

import {
    ConnectAction,
    AddClusterErrorAction,
    DispatchFunction,
} from "data/actions";

import { ClusterProfile } from "data/models";
import { ClusterRequest } from "worker/net/backend";

import * as React from "react";
import _ from "lodash";

import {
    Field,
    formValueSelector,
    getFormMeta,
    getFormSyncErrors,
    reduxForm,
    SubmissionError,
} from "redux-form";

import FormElement from "view/components/form-element";
import { FormSelect } from "view/components/form-select";

import CollapsableForm from "view/common/collapsable-form";

import {
    combineValidators,
    maxLengthFieldValidator,
    regexFieldValidator,
    requiredFieldValidator,
} from "util/form";

import { capitalize } from "humanize-plus";

import { addCluster } from "worker/api/clusters";
import * as analytics from "util/segment";

export const ADD_NEW_CLUSTER_FORM_NAME = "ADD_NEW_CLUSTER_FORM_NAME";

const selector = formValueSelector(ADD_NEW_CLUSTER_FORM_NAME);
const errorSelector = getFormSyncErrors(ADD_NEW_CLUSTER_FORM_NAME);
const metaSelector = getFormMeta(ADD_NEW_CLUSTER_FORM_NAME);

const validatePortField = combineValidators(
    requiredFieldValidator,
    regexFieldValidator(/^\d+$/, "Ports can only contain digits")
);

const validateClusterNameField = combineValidators(
    requiredFieldValidator,
    maxLengthFieldValidator(40)
);

const validateClusterDescriptionField = combineValidators(
    requiredFieldValidator,
    maxLengthFieldValidator(100)
);

const trackAddNewClusterBlur = analytics.trackBlurEvent("add-new-cluster");

const handleBlur = (
    _event: unknown,
    _newValue: unknown,
    _value: unknown,
    name: string
) => {
    // TODO - PLAT-3610 - send success/error according form validations
    trackAddNewClusterBlur(name);
};

const LocateSection = () => {
    return (
        <>
            <Field
                name="hostname"
                component={FormElement}
                label="Hostname"
                placeholder="Enter the hostname"
                type="text"
                onBlur={handleBlur}
                autoFocus
            />

            <Field
                name="port"
                label="Port"
                placeholder="Enter the port"
                type="text"
                onBlur={handleBlur}
                component={FormElement}
            />
        </>
    );
};

const CredentialsSection = () => {
    return (
        <>
            <Field
                name="username"
                label="Username"
                placeholder="Enter your username"
                type="text"
                component={FormElement}
                onBlur={handleBlur}
                autoFocus
            />

            <Field
                name="password"
                label="Password"
                placeholder="Enter your password"
                type="password"
                onBlur={handleBlur}
                component={FormElement}
            />
        </>
    );
};

const ProfileSection = () => {
    return (
        <>
            <Field
                name="profile"
                label="Type"
                component={FormElement}
                type={FormSelect}
                onBlur={handleBlur}
                autoFocus
            >
                <option disabled value="">
                    Select Environment Type
                </option>
                <option value="DEVELOPMENT">Development</option>
                <option value="STAGING">Staging</option>
                <option value="PRODUCTION">Production</option>
            </Field>

            <Field
                name="clusterName"
                label="Cluster Name"
                type="text"
                onBlur={handleBlur}
                placeholder="Enter the cluster name"
                component={FormElement}
            />

            <Field
                name="description"
                label="Description"
                type="text"
                onBlur={handleBlur}
                placeholder="Descriptions should be less than 100 characters"
                component={FormElement}
            />
        </>
    );
};

const renderFieldValue = (
    error: Maybe<string>,
    value: string,
    touched: boolean
) => {
    if (error && touched) {
        return <span className="error">{error}</span>;
    } else {
        return <span>{value || "Undefined"}</span>;
    }
};

type AddNewClusterFormValues = {
    locate: {
        hostname: string;
        port: string;
    };
    credentials: {
        username: string;
        password: string;
    };
    profile: {
        clusterName: string;
        profile: Maybe<ClusterProfile>;
        description: string;
    };
};

const setFieldError = (
    errors: Object,
    field: Array<string>,
    error: Maybe<string>
) => {
    if (error) {
        _.set(errors, field, error);
    }
};

// DISCLAIMER
// Currently duplicated from "util/handle-mysql-error". We should only
// think about refactoring/cleaning this when we have more use cases for
// this so that we don't over-abstract too early.
const handleFormConnectErrors = (code: Maybe<string>, message: string) => {
    switch (code) {
        case "PROTOCOL_CONNECTION_LOST":
            throw new SubmissionError({
                _error: {
                    section: "locate",
                    error:
                        "We are unable to connect to your cluster. Please check your hostname and port and try again.",
                },
            });

        case "ER_ACCESS_DENIED_ERROR":
            throw new SubmissionError({
                _error: {
                    section: "credentials",
                    error:
                        "The credentials are incorrect. Please verify them and try again.",
                },
            });

        default:
            throw new SubmissionError({
                _error: `An error occurred: ${message}`,
            });
    }
};

const validateForm = (values: AddNewClusterFormValues) => {
    const errors = {};

    setFieldError(
        errors,
        ["locate", "hostname"],
        requiredFieldValidator(values.locate.hostname)
    );
    setFieldError(
        errors,
        ["locate", "port"],
        validatePortField(values.locate.port)
    );

    setFieldError(
        errors,
        ["credentials", "username"],
        requiredFieldValidator(values.credentials.username)
    );

    setFieldError(
        errors,
        ["profile", "clusterName"],
        validateClusterNameField(values.profile.clusterName)
    );
    setFieldError(
        errors,
        ["profile", "profile"],
        requiredFieldValidator(values.profile.profile)
    );
    setFieldError(
        errors,
        ["profile", "description"],
        validateClusterDescriptionField(values.profile.description)
    );

    return errors;
};

const initialValues: AddNewClusterFormValues = {
    locate: {
        hostname: "",
        port: "",
    },
    credentials: {
        username: "",
        password: "",
    },
    profile: {
        clusterName: "",
        profile: undefined,
        description: "",
    },
};

const formSections = [
    {
        name: "locate",
        title: "Locate Cluster",
        description: "The hostname and port of the Master Aggregator",
        renderFields: LocateSection,
        getFieldValues: (
            state: State,
            sectionName: string
        ): SectionValuesList => {
            const fieldErrors = _.get(errorSelector(state), sectionName) || {};
            const fieldMetas = _.get(metaSelector(state), sectionName) || {};

            return [
                {
                    name: "hostname",
                    label: "Hostname",
                    value: renderFieldValue(
                        fieldErrors["hostname"],
                        selector(state, `${sectionName}.hostname`),
                        fieldMetas.hostname
                            ? fieldMetas.hostname.touched
                            : false
                    ),
                },
                {
                    name: "port",
                    label: "Port",
                    value: renderFieldValue(
                        fieldErrors["port"],
                        selector(state, `${sectionName}.port`),
                        fieldMetas.port ? fieldMetas.port.touched : false
                    ),
                },
            ];
        },
    },
    {
        name: "credentials",
        title: "Enter Credentials",
        description: "Enter your username and password to verify your access.",
        renderFields: CredentialsSection,
        getFieldValues: (
            state: State,
            sectionName: string
        ): SectionValuesList => {
            const fieldErrors = _.get(errorSelector(state), sectionName) || {};
            const fieldMetas = _.get(metaSelector(state), sectionName) || {};

            return [
                {
                    name: "username",
                    label: "Username",
                    value: renderFieldValue(
                        fieldErrors["username"],
                        selector(state, `${sectionName}.username`),
                        fieldMetas.username
                            ? fieldMetas.username.touched
                            : false
                    ),
                },
                {
                    name: "password",
                    label: "Password",
                    value: "•••••",
                },
            ];
        },
    },
    {
        name: "profile",
        title: "Create Cluster Profile",
        description: "Create and store profile of the cluster for your team.",
        renderFields: ProfileSection,
        getFieldValues: (
            state: State,
            sectionName: string
        ): SectionValuesList => {
            const fieldErrors = _.get(errorSelector(state), sectionName) || {};
            const fieldMetas = _.get(metaSelector(state), sectionName) || {};

            let profileValue = selector(state, `${sectionName}.profile`);

            if (profileValue) {
                profileValue = capitalize(profileValue, true);
            }

            return [
                {
                    name: "profile",
                    label: "Profile",
                    value: renderFieldValue(
                        fieldErrors["profile"],
                        profileValue,
                        fieldMetas.profile ? fieldMetas.profile.touched : false
                    ),
                },
                {
                    name: "clusterName",
                    label: "Cluster Name",
                    value: renderFieldValue(
                        fieldErrors["clusterName"],
                        selector(state, `${sectionName}.clusterName`),
                        fieldMetas.clusterName
                            ? fieldMetas.clusterName.touched
                            : false
                    ),
                },
                {
                    name: "description",
                    label: "Description",
                    value: renderFieldValue(
                        fieldErrors["description"],
                        selector(state, `${sectionName}.description`),
                        fieldMetas.description
                            ? fieldMetas.description.touched
                            : false
                    ),
                },
            ];
        },
    },
];

const Form = reduxForm<
    AddNewClusterFormValues,
    CollapsableFormProps,
    CollapsableFormError
>({
    form: ADD_NEW_CLUSTER_FORM_NAME,

    initialValues,

    // We implement a form-level validation as recommended here:
    // https://github.com/erikras/redux-form/issues/2451, for forms
    // that have hidden fields.
    validate: (values: AddNewClusterFormValues) => {
        return validateForm(values);
    },

    destroyOnUnmount: false,

    persistentSubmitErrors: true,

    onSubmit(
        values: AddNewClusterFormValues,
        dispatch: DispatchFunction,
        props
    ) {
        const profile = values.profile.profile;
        if (!profile) {
            throw new Error("Expected profile to be defined.");
        }

        const newClusterRequestObj: ClusterRequest = {
            hostname: values.locate.hostname.trim(),
            port: Number(values.locate.port),
            username: values.credentials.username.trim(),
            password: values.credentials.password,
            name: values.profile.clusterName.trim(),
            profile,
            description: values.profile.description.trim(),
        };

        return Promise.resolve(
            dispatch(addCluster(newClusterRequestObj) as
                | ConnectAction
                | AddClusterErrorAction)
        ).then((action: ConnectAction | AddClusterErrorAction) => {
            if (action.type === "CONNECT") {
                if (action.error) {
                    handleFormConnectErrors(
                        action.payload.code,
                        action.payload.message
                    );
                } else {
                    const { config } = action.payload;
                    if (!config) {
                        throw new Error(
                            "Got non-error connect action with no config"
                        );
                    }
                    props.destroy();
                    return { clusterId: config.clusterId };
                }
            } else if (action.type === "CLUSTERS_ADD_ERROR") {
                handleFormConnectErrors(
                    action.payload.code,
                    action.payload.message
                );
            }

            throw new Error(
                "Only the CONNECT and the CLUSTERS_ADD_ERROR actions are expected here."
            );
        });
    },
})(CollapsableForm);

// We export a wrapper around the Form component (which is a decorated
// CollapsableForm instance defined above) with formSections set.

// We leave the rest of the props to the client of this form, in order to
// maintain a proper separation of concerns between the form and the page
// that renders the form.
export function AddNewClusterForm(
    props: Omit<React.ComponentProps<typeof Form>, "formSections">
) {
    return <Form {...props} formSections={formSections} />;
}
