import { NodeID, Tree, TreeNode } from "util/tree";
import { LinkName } from "urls";
import {
    ExecutorInstance,
    ExplainTree,
    PlanType,
    ExecutorMeta,
    ExplainWarning,
    ExplainQueryInfo,
    ExplainClusterInfo,
} from "data/models";
import { Maybe } from "util/maybe";

import _ from "lodash";

import { NewTree, mapTree } from "util/tree";

type RawExplainMetric = {
    value: number;
    avg?: number;
    stddev?: number;
    max?: number;
    maxPartition?: number;
};

// The engine can supply strings, numbers, arrays of strings and
// differently shaped objects for various metrics and properties
// of EXPLAIN/PROFILE nodes. Thus, we simply don't type it and are
// careful with this from the parsing algorithm to the UI.
type RawExplainValue = any;

type RawExplainNode = {
    executor: string;

    inputs: Array<RawExplainNode>;

    subselects?: Array<{
        alias: string;
        correlated: string;
        input: RawExplainNode;
    }>;

    db?: string;
    table?: string;

    actual_total_time?: RawExplainMetric;
    actual_row_count?: RawExplainMetric;

    [key: string]: RawExplainValue;
};

type RawPlanType = RawExplainNode | Array<RawExplainNode>;

// Starting in MemSQL 6.7, query plans generated by EXPLAIN and PROFILE have an
// associated version number. This is used to identify a formatting change to
// the JSON or any other changes that will effectively break Studio's Visual
// Explain. For that reason, each version of MemSQL Studio supports a certain
// set of plan versions.
//
// Note that we assume version=0 for query plans generated before 6.7.
const MAX_SUPPORTED_PLAN_VERSION = 4;

export const parseRawExplain = (rawPlan: RawPlanType): ExplainTree => {
    const throwParseError = (msg: string): never => {
        throw new Error(
            `Error while parsing the provided JSON as an EXPLAIN or PROFILE output file: ${msg}`
        );
    };

    // See DB-33499.
    let version = 0;
    if (!Array.isArray(rawPlan) && rawPlan.version) {
        // If we find a plan version (>= 1), then we save it and parse the
        // standard versioned plan format. If it is not a supported version,
        // then we throw an error that is shown to users.

        version = Number(rawPlan.version);

        if (version > MAX_SUPPORTED_PLAN_VERSION) {
            throwParseError(
                "This query plan cannot be loaded by this version of MemSQL Studio. Please upgrade to a newer version of Studio."
            );
        }

        if (rawPlan.explain) {
            rawPlan = rawPlan.explain[0];
        } else if (rawPlan.profile) {
            rawPlan = rawPlan.profile[0];
        } else {
            throwParseError(
                "Invalid plan: missing 'explain' or 'profile' key. Try to upgrade to a newer version of Studio."
            );
        }
    } else {
        // If we don't find a plan version, then we are dealing
        // with a MemSQL <= 6.5 generated plan (unversioned).

        if (Array.isArray(rawPlan)) {
            rawPlan = rawPlan[0].Plan;
        }
    }

    let nodeId: NodeID = 0;
    const getNextNodeId = () => {
        return nodeId++;
    };

    let tree: Tree<ExecutorInstance> = NewTree(-1, {}); // temporary
    let planType: PlanType = "EXPLAIN"; // temporary

    const parseNode = (node: RawExplainNode): NodeID => {
        // TODO: Come up with better criteria for how to tell if some JSON is an
        // EXPLAIN or a PROFILE, possibly when the engine gives us a better
        // signal. This is currently not written in a more generic way, e.g.
        // using visit from util/tree, because there's a good chance that the
        // better criteria won't involve any kind of tree traversal.
        if (node.actual_row_count || node.actual_total_time) {
            planType = "PROFILE";
        }

        let children: Array<NodeID> = [];

        if (!Array.isArray(node.inputs)) {
            throwParseError("Expected the inputs property to be an array.");
        }

        // We always parse children in input files in a right-to-left order.
        for (let i = node.inputs.length - 1; i >= 0; i--) {
            children.push(parseNode(node.inputs[i]));
        }

        // In plan files with version 4 or higher, input children are ordered
        // right to left. In plan files with version 3 or lower, the children of
        // UnionAll are ordered left-to-right which means that we have to
        // reverse them.
        if (version <= 3 && node.executor === "UnionAll") {
            children.reverse();
        }

        if (node.subselects) {
            // Subselect children have an `input` node as well as some subselect
            // metadata. We pass the subselect metadata to the `input` node
            // and treat it as one thing in our tree.

            children = children.concat(
                _.map(node.subselects, subselect =>
                    parseNode({
                        ...subselect.input,
                        ...subselect,
                    })
                )
            );
        }

        const executorInstance: ExecutorInstance = {
            executor: node.executor,
            metrics: {},
            meta: {
                // This will be updated in the next pass through the tree
                isTerminal: false,
            },
            raw: _.omit(node, ["inputs", "subselects", "executor"]),
        };

        const id = getNextNodeId();

        const treeNode: TreeNode<ExecutorInstance> = {
            data: executorInstance,
            children,
        };

        tree.nodes[id] = treeNode;

        return id;
    };

    // Step 1: We parse and build the overall structure of the tree.
    tree.root = parseNode(rawPlan as RawExplainNode);

    // Step 2: We go through the tree and calculate some accumulate
    // metrics (to be used in Step 3) and parse the `metrics` and the
    // `meta` object in each executor instance.
    let totalTimeMs = 0;

    let maxRowCount = -Infinity;
    let maxTotalTimeMs = -Infinity;
    let maxEstimatedRowCount = -Infinity;
    let maxAbsDeltaRowCount = -Infinity;

    tree = mapTree(tree, (nodeId: number, node: TreeNode<ExecutorInstance>) => {
        const {
            data: { raw },
        } = node;

        const meta: ExecutorMeta = {};

        if (node.children.length === 0) {
            meta.isTerminal = true;
        }

        if (raw.table) {
            meta.tableName = raw.table;
        }

        if (raw.db) {
            meta.databaseName = raw.db;
        }

        const metrics = node.data.metrics;

        // The `isMax` and `percent` metrics will be set in
        // the next pass through the tree of the parser.

        if (raw.actual_row_count) {
            metrics.actualRowCount = {
                value: raw.actual_row_count.value,
                percent: 0,
                isMax: false,
            };

            maxRowCount = Math.max(maxRowCount, metrics.actualRowCount.value);
        }

        if (raw.est_table_rows !== undefined || raw.est_rows !== undefined) {
            metrics.estimatedRowCount = {
                value: Number(raw.est_table_rows || raw.est_rows),
                percent: 0,
                isMax: false,
            };

            maxEstimatedRowCount = Math.max(
                maxEstimatedRowCount,
                metrics.estimatedRowCount.value
            );
        }

        if (metrics.actualRowCount && metrics.estimatedRowCount) {
            metrics.deltaRowCount = {
                value:
                    metrics.actualRowCount.value -
                    metrics.estimatedRowCount.value,
                percent: 0,
                isMax: false,
            };

            maxAbsDeltaRowCount = Math.max(
                maxAbsDeltaRowCount,
                Math.abs(metrics.deltaRowCount.value)
            );
        }

        if (raw.actual_total_time) {
            // The engine doesn't include `network_time` in `actual_total_time` so we
            // manually add network_time to actual_total_time if we have it. Moreover,
            // we always extract `actual_total_time` into `actual_cpu_time` (a new fake
            // metric that Studio creates) since that's what it actually means.

            raw.actual_cpu_time = _.clone(raw.actual_total_time);

            if (raw.network_time) {
                raw.actual_total_time.value += raw.network_time.value;
            }

            metrics.totalTimeMs = {
                value: raw.actual_total_time.value,
                percent: 0,
                isMax: false,
            };

            totalTimeMs += metrics.totalTimeMs.value;
            maxTotalTimeMs = Math.max(
                maxTotalTimeMs,
                metrics.totalTimeMs.value
            );
        }

        if (raw.memory_usage) {
            metrics.memoryUsage = {
                value: raw.memory_usage.value,
                percent: 0,
                isMax: false,
            };
        }

        return {
            data: {
                ...node.data,
                meta,
                raw: _.omit(node.data.raw, ["table", "db"]),
            },
            children: node.children,
        };
    });

    // Step 3: We go through the tree and set some metrics
    // such as percents and maximums.
    tree = mapTree(tree, (nodeId, node) => {
        const metrics = node.data.metrics;

        if (metrics.actualRowCount) {
            metrics.actualRowCount = {
                ...metrics.actualRowCount,
                percent: metrics.actualRowCount.value / maxRowCount,
                isMax: metrics.actualRowCount.value === maxRowCount,
            };
        }

        if (metrics.estimatedRowCount) {
            metrics.estimatedRowCount = {
                ...metrics.estimatedRowCount,
                percent: metrics.estimatedRowCount.value / maxEstimatedRowCount,
                isMax: metrics.estimatedRowCount.value === maxEstimatedRowCount,
            };
        }

        if (metrics.deltaRowCount) {
            const absDifference = Math.abs(metrics.deltaRowCount.value);

            metrics.deltaRowCount = {
                ...metrics.deltaRowCount,
                percent: absDifference / maxAbsDeltaRowCount,
                isMax: absDifference === maxAbsDeltaRowCount,
            };
        }

        if (metrics.totalTimeMs) {
            metrics.totalTimeMs = {
                ...metrics.totalTimeMs,
                percent:
                    totalTimeMs === 0
                        ? 0
                        : metrics.totalTimeMs.value / totalTimeMs,
                isMax: metrics.totalTimeMs.value === maxTotalTimeMs,
            };
        }

        return {
            data: {
                ...node.data,
                metrics,
            },
            children: node.children,
        };
    });

    return {
        tree,
        planType,
        version,
    };
};

export const parseClusterInfo = (
    rawPlan: RawPlanType
): Maybe<ExplainClusterInfo> => {
    let clusterInfo;
    // If plan is an array it doesn't contain cluster info. See DB-33499 for
    // more details.
    if (!Array.isArray(rawPlan) && rawPlan.info) {
        const {
            memsql_version,
            num_online_aggs,
            num_online_leaves,
            context_database,
            memsql_version_hash,
        } = rawPlan.info;

        clusterInfo = {
            memsqlVersion: memsql_version,
            numOnlineAggs: num_online_aggs,
            numOnlineLeaves: num_online_leaves,
            contextDatabase: context_database,
            memsqlVersionHash: memsql_version_hash,
        };
    }

    return clusterInfo;
};

export const parseQueryInfo = (
    rawPlan: RawPlanType
): Maybe<ExplainQueryInfo> => {
    let queryInfo;
    // If plan is an array it doesn't contain query info. See DB-33499 for
    // more details.
    if (!Array.isArray(rawPlan) && rawPlan.query_info) {
        const { query_text } = rawPlan.query_info;

        queryInfo = {
            queryText: query_text,
        };
    }

    return queryInfo;
};

const generateStatsWarning = (
    dbName: string,
    tableName: string,
    columns: string
): ExplainWarning => ({
    description: `WARNING: Statistics have not been collected on the following tables and columns. Consider running the following commands to collect them: ANALYZE TABLE ${dbName}.${tableName} COLUMNS ${columns} ENABLE;`,
    linkName: "explain-stats-warning",
});

type MissingStats = {
    [key: string]: { [key: string]: Array<string> };
};

type PlanWarnings = {
    [key: string]: Array<{ description: string }>;
};

type WarningLinks = {
    [key: string]: LinkName;
};

// The keys in this object need to match with the keys in plan_warnings
const KNOWN_WARNINGS_DOCS_LINKS: WarningLinks = {
    type_mismatched_comparisons: "explain-mismatched-types-warning",
};

export const parseExplainWarnings = (
    rawPlan: RawPlanType
): Array<ExplainWarning> => {
    const warnings: Array<ExplainWarning> = [];

    // Missing stats is the only warning not in plan_warnings
    if ("missing_stats" in rawPlan) {
        const missingStats: MissingStats = rawPlan["missing_stats"];
        const dbName = Object.keys(missingStats)[0];
        const tableName = Object.keys(missingStats[dbName])[0];
        const columns = missingStats[dbName][tableName].join(",");

        warnings.push(generateStatsWarning(dbName, tableName, columns));
    }

    // Every other warning is nested in an object under plan_warnings. The
    // object's key is the category and the value is an array of warning objects
    if ("plan_warnings" in rawPlan) {
        const planWarnings: PlanWarnings = rawPlan["plan_warnings"];

        for (let warningCategory in planWarnings) {
            for (let i = 0; i < planWarnings[warningCategory].length; i++) {
                const warning = {
                    description: planWarnings[warningCategory][i].description,
                    linkName: KNOWN_WARNINGS_DOCS_LINKS[warningCategory],
                };

                warnings.push(warning);
            }
        }
    }

    return warnings;
};
