import { State as ReduxState, DispatchFunction } from "data";
import { MvStatisticsColumnInfo, MvColumnKey } from "memsql/mv-column-info";

import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { CustomScrollbar } from "view/components/custom-scrollbar";
import classnames from "classnames";

import { mvChangeStatsColumns } from "data/actions/management-views";
import { COLUMNS } from "memsql/mv-column-info";

import DropdownButton from "view/components/dropdown-button";
import Card from "view/components/card";
import Icon from "view/components/icon";
import Tip from "view/components/tip";
import ExtLink from "view/components/external-link";

import * as analytics from "util/segment";

import "./mv-column-picker.scss";

type SelectState = "ALL" | "PARTIAL" | "NONE";

type PickerOptionProps = {
    name: React.ReactNode;
    selected: SelectState;
    advanced?: boolean;
    disabled?: boolean;
    onClick: () => void;
};

const ADVANCED_COLUMN_MSG =
    "This column is an advanced column and Read Advanced Counters is disabled. Click for more info.";

const PickerOption = ({
    name,
    selected,
    disabled,
    onClick,
}: PickerOptionProps) => {
    let icon;
    if (selected === "ALL") {
        icon = <Icon className="picker-icon" icon="check" fixedWidth />;
    } else if (selected === "PARTIAL") {
        icon = <Icon className="picker-icon" icon="minus" fixedWidth />;
    } else {
        icon = <div className="picker-icon" />;
    }

    const classes = classnames({
        "picker-option": true,
        disabled,
    });

    return (
        <div className={classes} onClick={disabled ? undefined : onClick}>
            {icon}
            <div className="name">{name}</div>
            {disabled ? (
                <Tip
                    className="info-icon-overlay"
                    direction="sw"
                    tooltip={ADVANCED_COLUMN_MSG}
                    minWidth={300}
                >
                    <ExtLink name="advanced-counters" category="resource-usage">
                        <Icon icon="info-circle" className="info-icon" />
                    </ExtLink>
                </Tip>
            ) : (
                undefined
            )}
        </div>
    );
};

type StateProps = {
    selectedStatsColumns: Array<MvColumnKey>;

    // whether READ_ADVANCED_COUNTERS is ON
    advancedCounters: boolean;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

class MVColumnPicker extends React.Component<Props> {
    handleClick = (column: string) => {
        const { dispatch, selectedStatsColumns } = this.props;
        const isRemove = _.includes(selectedStatsColumns, column);

        dispatch(
            mvChangeStatsColumns({
                column,
                op: isRemove ? "REMOVE" : "ADD",
            })
        );

        const action = isRemove ? "remove-column" : "add-column";

        analytics.track(action, { column, category: "management-views" });
    };

    isColumnDisabled = (column: MvStatisticsColumnInfo): boolean => {
        const { advancedCounters } = this.props;
        return column.advanced && !advancedCounters;
    };

    isColumnSelected = (column: MvStatisticsColumnInfo): boolean => {
        const { selectedStatsColumns } = this.props;
        return selectedStatsColumns.indexOf(column.id) >= 0;
    };

    categorySelected = (category: string): SelectState => {
        // filter all columns beloging to `category` which are not disabled
        const states = _(COLUMNS)
            .filter(c => c.category === category && !this.isColumnDisabled(c))
            .map(this.isColumnSelected)
            .value();

        if (_.every(states)) {
            return "ALL";
        } else if (_.some(states)) {
            return "PARTIAL";
        } else {
            return "NONE";
        }
    };

    allSelected = (): SelectState => {
        const numEnabledColumns = _(COLUMNS)
            .reject(this.isColumnDisabled)
            .keys()
            .value().length;

        switch (this.props.selectedStatsColumns.length) {
            case 0:
                return "NONE";

            case numEnabledColumns:
                return "ALL";

            default:
                return "PARTIAL";
        }
    };

    handleClickAll = () => {
        const { dispatch } = this.props;

        const isRemove = this.allSelected() === "ALL";
        const op = isRemove ? "REMOVE" : "ADD";
        _(COLUMNS)
            .reject(this.isColumnDisabled)
            .forEach(c => dispatch(mvChangeStatsColumns({ column: c.id, op })));

        const action = isRemove ? "remove-category" : "add-category";

        analytics.track(action, {
            category: "management-views",
            value: "all",
        });
    };

    handleClickCategory = (category: string) => {
        const { dispatch } = this.props;

        const isRemove = this.categorySelected(category) === "ALL";
        const op = isRemove ? "REMOVE" : "ADD";
        _(COLUMNS)
            .filter(c => c.category === category)
            .reject(this.isColumnDisabled)
            .forEach(c => dispatch(mvChangeStatsColumns({ column: c.id, op })));

        const action = isRemove ? "remove-category" : "add-category";

        analytics.track(action, {
            category: "management-views",
            value: category,
        });
    };

    render() {
        const columns: React.ReactNode = _(COLUMNS)
            .sortBy(c => c.id)
            .map(column => (
                <PickerOption
                    key={column.id}
                    name={column.title}
                    selected={this.isColumnSelected(column) ? "ALL" : "NONE"}
                    disabled={this.isColumnDisabled(column)}
                    onClick={() => this.handleClick(column.id)}
                />
            ))
            .value();

        const categories: React.ReactNode = _(COLUMNS)
            .map(c => c.category)
            .sortBy()
            .sortedUniq()
            .map(category => (
                <PickerOption
                    key={category}
                    name={category}
                    selected={this.categorySelected(category)}
                    onClick={() => this.handleClickCategory(category)}
                />
            ))
            .value();

        const picker = (
            <Card className="monitor-mv-column-picker">
                <CustomScrollbar className="scroll">
                    <div className="group-header">Categories</div>
                    <div className="categories">
                        <PickerOption
                            name="all"
                            selected={this.allSelected()}
                            onClick={this.handleClickAll}
                        />
                        {categories}
                    </div>
                    <div className="group-header">Columns</div>
                    {columns}
                </CustomScrollbar>
            </Card>
        );

        return (
            <DropdownButton
                small
                ghost
                direction="sw"
                spacing={5}
                icon="plus-circle"
                children={picker}
            />
        );
    }
}

export default connect(
    (s: ReduxState): StateProps => ({
        selectedStatsColumns: s.managementViews.selectedStatsColumns,
        advancedCounters: s.managementViews.advancedCounters,
    })
)(MVColumnPicker);
