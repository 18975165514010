import { InjectedFormProps } from "redux-form";
import { Maybe } from "util/maybe";

import * as React from "react";
import { compose } from "redux";
import { reduxForm, Field } from "redux-form";

import Modal from "view/components/modal";
import { Button } from "view/common/button";
import Form from "view/components/form";
import FormElement from "view/components/form-element";
import SimpleModalBody from "view/components/simple-modal-body";
import { FormSelect } from "view/components/form-select";
import { Alert } from "view/components/alert";

import { mvFullStart } from "worker/api/management-views";
import { mvFullFixedInterval } from "worker/api/management-views";

import { formValues } from "redux-form";
import {
    combineValidators,
    regexFieldValidator,
    requiredFieldValidator,
} from "util/form";

import "./mv-record.scss";

const customIntervalFieldValidation = combineValidators(
    requiredFieldValidator,
    regexFieldValidator(/^\d+$/, "Interval can only contain digits"),
    (value: Number): Maybe<string> =>
        value <= 0 ? "Interval must be greater than 0" : undefined
);

const fixedIntervalFieldValidation = combineValidators(
    (value: string): Maybe<string> =>
        value ? undefined : "Please select an interval"
);

type OwnProps = {
    onClose: () => void;
};

// This type corresponds to the subset of the form values which are selected by
// the form itself for conditional rendering. See
// https://redux-form.com/8.2.2/docs/api/formvalues.md/
type SelectedFormValues = {
    duration: "10" | "30" | "60" | "300" | "600" | "CUSTOM";
    durationType: "CHOOSE_DURATION" | "UNTIL_STOPPED";
};

type FormValues = {
    deltaTime?: string;
    durationUnit?: "sec" | "min";
} & SelectedFormValues;

type Props = OwnProps &
    InjectedFormProps<FormValues, OwnProps> &
    SelectedFormValues;

class MvRecordForm extends React.Component<Props> {
    renderCustomDurationFormField() {
        const { duration } = this.props;

        if (duration === "CUSTOM") {
            return (
                <div className="custom-duration">
                    <Field
                        name="deltaTime"
                        className="interval-input"
                        component={FormElement}
                        validate={customIntervalFieldValidation}
                        placeholder="10"
                        type="text"
                    />
                    <Field
                        name="durationUnit"
                        component={FormElement}
                        type={FormSelect}
                        className="duration-unit"
                    >
                        <option value="sec">sec</option>
                        <option value="min">min</option>
                    </Field>
                </div>
            );
        }
    }

    renderFixedDurationFormFields() {
        const { durationType } = this.props;

        if (durationType === "CHOOSE_DURATION") {
            return (
                <div className="choose-duration">
                    <Field
                        name="duration"
                        label="Duration"
                        component={FormElement}
                        type={FormSelect}
                        validate={fixedIntervalFieldValidation}
                        autoFocus
                    >
                        <option disabled value="">
                            Select Duration
                        </option>
                        <option value="10">10 sec</option>
                        <option value="30">30 sec</option>
                        <option value="60">1 min</option>
                        <option value="300">5 min</option>
                        <option value="600">10 min</option>
                        <option value="CUSTOM">Custom</option>
                    </Field>

                    {this.renderCustomDurationFormField()}
                </div>
            );
        }
    }

    render() {
        const { handleSubmit, onClose, durationType } = this.props;

        let warning;
        if (durationType === "UNTIL_STOPPED") {
            warning = (
                <Alert
                    message="This will not work on clusters with a large history of queries."
                    status="warning"
                />
            );
        }

        return (
            <Modal
                active
                onClose={onClose}
                innerContentPadding
                actions={
                    <>
                        <Button large onClick={onClose}>
                            Cancel
                        </Button>
                        <Button primary large onClick={handleSubmit}>
                            Start
                        </Button>
                    </>
                }
            >
                <Form onSubmit={handleSubmit} className="forms-mv-record">
                    <SimpleModalBody
                        title="Profile Resource Usage"
                        description="How long do you want to profile for?"
                    />

                    <div className="fields">
                        <Field
                            name="durationType"
                            value="CHOOSE_DURATION"
                            component={FormElement}
                            type="radio"
                            label="Choose a duration"
                        />

                        {this.renderFixedDurationFormFields()}

                        <Field
                            name="durationType"
                            value="UNTIL_STOPPED"
                            component={FormElement}
                            type="radio"
                            label="Actively profile until manually stopped"
                        />

                        {warning}
                    </div>
                </Form>
            </Modal>
        );
    }
}

export const MvRecord = compose(
    reduxForm<FormValues, OwnProps>({
        onSubmit(values, dispatch, props) {
            if (values.durationType === "CHOOSE_DURATION") {
                let deltaTimeS = Number(values.deltaTime);

                if ("duration" in values && values.duration !== "CUSTOM") {
                    deltaTimeS = Number(values.duration);
                } else if (
                    "durationUnit" in values &&
                    values.durationUnit === "min"
                ) {
                    deltaTimeS *= 60;
                }

                dispatch(mvFullFixedInterval({ deltaTimeS }));
                props.onClose();
            } else {
                dispatch(mvFullStart());
                props.onClose();
            }
        },
    }),
    formValues({ duration: "duration", durationType: "durationType" })
)(MvRecordForm);
