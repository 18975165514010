
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import TutorialLoadSQLButton from "view/tutorial/tutorial-load-sql-button";
import {
    SQL_MOST_TRADED_STOCKS,
    SQL_VOLATILE_STOCKS,
    SQL_VOLATILE_STOCKS_TIMED,
    SQL_PORTFOLIO_AGG
} from "./stock-queries";

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h4" components={components}>{`Step 5: Run analytic queries`}</MDXTag>
<MDXTag name="p" components={components}>{`In the SQL editor window, you can run the following analytical queries while `}<MDXTag name="inlineCode" components={components} parentName="p">{`iter_stocks`}</MDXTag>{` inserts records in the background.`}</MDXTag>
<MDXTag name="h5" components={components}>{`Query 1`}</MDXTag>
<MDXTag name="p" components={components}>{`Finds the most traded stocks.`}</MDXTag>
<TutorialLoadSQLButton id="stock-most-traded" query={SQL_MOST_TRADED_STOCKS} text="Paste Query 1" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
<MDXTag name="h5" components={components}>{`Query 2`}</MDXTag>
<MDXTag name="p" components={components}>{`Finds the most "volatile" stocks (highest variance in prices).`}</MDXTag>
<TutorialLoadSQLButton id="stock-most-volatile" query={SQL_VOLATILE_STOCKS} text="Paste Query 2" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
<MDXTag name="h5" components={components}>{`Query 3`}</MDXTag>
<MDXTag name="p" components={components}>{`Finds the most "volatile" stocks (highest variance in prices) in the last 5 seconds.`}</MDXTag>
<TutorialLoadSQLButton id="stock-timed-volatile" query={SQL_VOLATILE_STOCKS_TIMED} text="Paste Query 3" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
<MDXTag name="h5" components={components}>{`Query 4`}</MDXTag>
<MDXTag name="p" components={components}>{`This is a portfolio aggregation query that uses Common Table Expression (CTE), JOIN, and window functions. It also computes min, max, standard deviation, weighted average, and percentiles for each company stock.`}</MDXTag>
<TutorialLoadSQLButton id="stock-portfolio-aggregation" query={SQL_PORTFOLIO_AGG} text="Paste Query 4" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  