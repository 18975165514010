import { Maybe } from "util/maybe";
import { TableSort, SortDirection } from "util/sort";
import { Column, ColumnId, RowId } from "view/components/super-table";

import SuperTable, { addSort } from "view/components/super-table";

import * as React from "react";
import _ from "lodash";
import classnames from "classnames";

export type GeneralTableColumn<T> = Column & {
    formatter: (row: T) => React.ReactNode;
    getValue: (row: T) => string | number | boolean | undefined | null;
};

type Props<T> = {
    className?: string;

    sort: TableSort;
    onSort: (columnId: ColumnId, sortDir: Maybe<SortDirection>) => void;
    columns: Array<GeneralTableColumn<T>>;
    rows: Array<T>;
    rowHeight: number;
    verticallyAlignCells?: boolean;

    getRowId?: (row: T) => RowId;
    onClickCell?: (row: Maybe<T>, column: Column) => void;
};

export default class GeneralTable<T> extends React.Component<Props<T>> {
    static defaultProps = {
        rowHeight: 40,
    };

    onClickCell = (row: T) => (__: unknown, column: Column) => {
        const { onClickCell } = this.props;

        if (onClickCell) {
            onClickCell(row, column);
        }
    };

    render() {
        const {
            onSort,
            columns,
            rows,
            sort,
            getRowId,
            className,
            onClickCell,
            rowHeight,
            verticallyAlignCells,
        } = this.props;

        const classes = classnames(className, "general-table");

        const tableColumns = sort ? _.map(columns, addSort(sort)) : columns;

        return (
            <SuperTable
                className={classes}
                onSort={onSort}
                clickable={!!onClickCell}
                columns={tableColumns}
                rows={_.map(rows, r => ({
                    cells: _.map(columns, c => {
                        return c.formatter(r);
                    }),
                    id: getRowId ? getRowId(r) : undefined,
                    onClickCell: this.onClickCell(r),
                }))}
                rowHeight={rowHeight}
                verticallyAlignCells={verticallyAlignCells}
            />
        );
    }
}
