import { Maybe } from "util/maybe";
import { ExplainSectionName } from "data/reducers/explain";

import { State } from "data";
import {
    ExecutorInstance,
    ExplainValue,
    ExplainClusterInfo,
    PlanType,
} from "data/models";
import { DispatchFunction, ExplainSourceType } from "data/actions";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { ExplainSection } from "view/explain/section";
import SectionHeader from "view/components/section-header";
import Tip from "view/components/tip";
import IconTip from "view/components/icon-tip";
import { CustomScrollbar } from "view/components/custom-scrollbar";

import { expandSection, collapseSection } from "data/actions";
import { isSectionExpanded } from "view/explain/section";
import {
    selectTotalMemoryUsage,
    selectTotalTimeMs,
    selectExplainNodes,
    selectPlanType,
    selectNumOperations,
    selectActiveExecutorInstance,
    selectClusterInfo,
    selectExplainSourceType,
} from "data/selectors/explain";

import NumberFormatter from "util/number-formatter";
import { logError } from "util/logging";
import isNumeric from "util/is-numeric";

import "./sidebar.scss";

type ExecutorListingProps = {
    executor: string;
    metric?: React.ReactNode;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    onClick: () => void;
};

const ExecutorListing = ({
    executor,
    metric,
    onMouseEnter,
    onMouseLeave,
    onClick,
}: ExecutorListingProps) => {
    let metricDiv;
    if (metric !== undefined) {
        metricDiv = <div className="metric">{metric}</div>;
    }

    return (
        <div
            className="operator-listing"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
        >
            <div className="executor">{executor}</div>
            {metricDiv}
        </div>
    );
};

type InfoValueProps = {
    label: React.ReactNode;
    value: React.ReactNode;
};

const SectionElement = ({ label, value }: InfoValueProps) => (
    <div className="section-element">
        <div className="section-label">{label}</div>
        <div className="section-value">{value}</div>
    </div>
);

type StateProps = {
    activeInstance: Maybe<ExecutorInstance>;
    nodes: Maybe<Array<ExecutorInstance>>;
    planType: PlanType;
    numOperators: Maybe<number>;
    totalTimeMs: Maybe<number>;
    totalMemoryUsage: Maybe<number>;
    clusterInfo: Maybe<ExplainClusterInfo>;
    expandedSections: Array<ExplainSectionName>;
    sourceType: Maybe<ExplainSourceType>;
};

type Props = StateProps & {
    onHoverInstance: (idx?: number) => void;
    onClickInstance: (idx: number) => void;
    dispatch: DispatchFunction;
};

type Metric = {
    label: React.ReactNode;
    value: Maybe<React.ReactNode>;

    // Known metrics have special formatting and unknown
    // metrics are displayed "raw". Known metrics have
    // `formatted` set to true and unknown metrics have
    // `formatted` set to false.
    formatted: boolean;
};

// This function takes in a raw/loose explain value
// and tries to stringify it as best as possible.
// If basic techniques fail, we try to format the
// value with JSON. If that fails too, we return
// undefined which will cause the UI to not render
// this metric. It may throw an error while formatting
// the value as JSON.
export const stringifyUnknownValue = (value: ExplainValue): React.ReactNode => {
    if (typeof value === "string") {
        if (isNumeric(value)) {
            return NumberFormatter.formatNumber(Number(value));
        } else {
            return value;
        }
    } else if (typeof value === "number") {
        return NumberFormatter.formatNumber(value);
    } else if (value === null || value === undefined) {
        return "null";
    } else if (Array.isArray(value)) {
        if (value.length === 0) {
            return "[]";
        } else if (_.every(value, _.isString) || _.every(value, _.isNumber)) {
            return value.join(", ");
        }
    } else if (typeof value === "object") {
        if (value.value !== undefined) {
            if (typeof value.value === "number") {
                return NumberFormatter.formatNumber(value.value);
            } else {
                return value.value;
            }
        }
    }

    return JSON.stringify(value);
};

const parseUnknownVariable = (rawValue: ExplainValue, name: string): Metric => {
    let stringifiedValue;
    try {
        stringifiedValue = stringifyUnknownValue(rawValue);
    } catch (e) {
        logError(
            new Error(
                `Failed to stringify unknown metric value named ${name}. The raw value is ${rawValue}.`
            )
        );
    }

    return {
        label: name,
        value: stringifiedValue,
        formatted: false,
    };
};

// This function handles raw EXPLAIN metrics by
// checking for known metrics first and then
// fallbacking to a heuristic approach when we
// don't know of a particular metric.
const parseRawExplainMetric = (
    rawValue: ExplainValue,
    name: string
): Metric => {
    switch (name) {
        case "memory_usage": {
            return {
                label: "Memory Usage",
                value: NumberFormatter.formatBytes(rawValue.value),
                formatted: true,
            };
        }

        case "network_time": {
            return {
                label: "Network Time",
                value: NumberFormatter.formatDuration(rawValue.value),
                formatted: true,
            };
        }

        case "actual_row_count": {
            return {
                label: "Actual Row Count",
                value: NumberFormatter.formatNumber(rawValue.value),
                formatted: true,
            };
        }

        case "actual_total_time": {
            return {
                label: "Actual Total Time",
                value: NumberFormatter.formatDuration(rawValue.value),
                formatted: true,
            };
        }

        case "actual_cpu_time": {
            return {
                label: "Actual CPU Time",
                value: NumberFormatter.formatDuration(rawValue.value),
                formatted: true,
            };
        }

        case "start_time": {
            return {
                label: "Start Time",
                value: NumberFormatter.formatDuration(rawValue.value),
                formatted: true,
            };
        }

        case "end_time": {
            return {
                label: "End Time",
                value: NumberFormatter.formatDuration(rawValue.value),
                formatted: true,
            };
        }

        default: {
            return parseUnknownVariable(rawValue, name);
        }
    }
};

const ExecutorDetail = ({ metric }: { metric: Metric }): JSX.Element => (
    <div className="detail">
        <SectionHeader>{metric.label}</SectionHeader>
        <div className="detail-value">{metric.value || null}</div>
    </div>
);

const SectionElementRowsContainer = ({
    children,
}: {
    children: React.ReactNode;
}) => <div className="section-element-rows-container">{children}</div>;

const SectionElementRow = ({ metric }: { metric: Metric }): JSX.Element => (
    <div className="section-element-row">
        <Tip direction="w" tooltip={metric.label} className="label">
            {metric.label}
        </Tip>
        <Tip direction="w" tooltip={metric.value || null} className="value">
            {metric.value || null}
        </Tip>
    </div>
);

type SidebarNode = {
    node: ExecutorInstance;
    index: number;
    metric?: number;
    metricDisplay?: string;
};

class ExplainSidebar extends React.Component<Props> {
    handleExpandCollapseSection = (sectionName: ExplainSectionName) => {
        const { expandedSections, dispatch } = this.props;

        const expanded = isSectionExpanded(expandedSections, sectionName);

        if (expanded) {
            dispatch(collapseSection({ sectionName }));
        } else {
            dispatch(expandSection({ sectionName }));
        }
    };

    renderGlobalDetails = (nodes: Array<ExecutorInstance>): React.ReactNode => {
        const {
            onHoverInstance,
            onClickInstance,
            planType,
            expandedSections,
        } = this.props;
        const isProfile = planType === "PROFILE";

        // We need to keep the indexes of nodes in the layout array attached to
        // the nodes for the callbacks.
        let sidebarNodes: Array<SidebarNode> = nodes.map((node, index) => ({
            node,
            index,
        }));
        let metricTitle;

        if (isProfile) {
            metricTitle = <div className="metric-title">Total Time</div>;

            sidebarNodes = _.orderBy<SidebarNode>(
                sidebarNodes.map(({ node, index }) => {
                    let metric, metricDisplay;

                    const { totalTimeMs } = node.metrics;
                    if (totalTimeMs) {
                        metric = totalTimeMs.value;
                        metricDisplay = NumberFormatter.formatDuration(metric);
                    } else {
                        metricDisplay = "—";
                    }

                    return { node, index, metric, metricDisplay };
                }),
                [({ metric }) => (metric === undefined ? -Infinity : metric)],
                ["desc"]
            );
        }

        const expanded = isSectionExpanded(expandedSections, "Details");

        return (
            <div className="global-details">
                <ExplainSection
                    title="Details"
                    sectionName="Details"
                    expanded={expanded}
                    onExpandCollapse={this.handleExpandCollapseSection}
                >
                    <div className="operator-list">
                        <div className="header">
                            <div className="operator-title">Operator</div>
                            {metricTitle}
                        </div>

                        {sidebarNodes.map(({ node, index, metricDisplay }) => {
                            return (
                                <ExecutorListing
                                    key={index}
                                    executor={node.executor}
                                    metric={metricDisplay}
                                    onMouseEnter={() => onHoverInstance(index)}
                                    onMouseLeave={() => onHoverInstance()}
                                    onClick={() => onClickInstance(index)}
                                />
                            );
                        })}
                    </div>
                </ExplainSection>
            </div>
        );
    };

    renderClusterInfo = () => {
        const { clusterInfo, expandedSections } = this.props;
        const expanded = isSectionExpanded(expandedSections, "Cluster Info");

        if (clusterInfo) {
            return (
                <ExplainSection
                    title="Cluster Info"
                    sectionName="Cluster Info"
                    expanded={expanded}
                    onExpandCollapse={this.handleExpandCollapseSection}
                >
                    <div className="section-row">
                        <SectionElement
                            label="Server Version"
                            value={clusterInfo.memsqlVersion}
                        />
                    </div>

                    <div className="section-row">
                        <SectionElement
                            label="Aggregators"
                            value={clusterInfo.numOnlineAggs}
                        />
                        <SectionElement
                            label="Leaves"
                            value={clusterInfo.numOnlineLeaves}
                        />
                    </div>
                </ExplainSection>
            );
        }
    };

    renderExecutorDetails = (
        executorInstance: ExecutorInstance
    ): React.ReactNode => {
        const { expandedSections } = this.props;

        const details: Array<Metric> = _.filter(
            [
                {
                    label: "Executor",
                    value: executorInstance.executor,
                    formatted: true,
                },
                {
                    label: "Database Name",
                    value: executorInstance.meta.databaseName,
                    formatted: true,
                },
                {
                    label: "Table Name",
                    value: executorInstance.meta.tableName,
                    formatted: true,
                },
                {
                    label: "Condition",
                    value: executorInstance.raw.condition,
                    formatted: true,
                },
            ],
            metric => metric.value !== undefined
        );

        let detailsNode;
        if (details.length) {
            detailsNode = _.map(details, (metric, index) => (
                <ExecutorDetail metric={metric} key={index} />
            ));
        } else {
            detailsNode = <div className="not-applicable">—</div>;
        }

        const detailsExpanded = isSectionExpanded(
            expandedSections,
            "Node Details"
        );
        return (
            <ExplainSection
                title="Details"
                sectionName="Node Details"
                expanded={detailsExpanded}
                onExpandCollapse={this.handleExpandCollapseSection}
            >
                {detailsNode}
            </ExplainSection>
        );
    };

    renderExecutorMetrics = (
        executorInstance: ExecutorInstance
    ): React.ReactNode => {
        const { expandedSections } = this.props;

        const metrics: Array<Metric> = _(executorInstance.raw)
            .omit(["condition", "keyId", "out"])
            .map(parseRawExplainMetric)
            .orderBy(
                [
                    (metric: Metric) => {
                        if (metric.formatted) {
                            return 1;
                        } else {
                            return 0;
                        }
                    },
                ],
                ["desc"]
            )
            .filter(metric => metric.value !== undefined)
            .value();

        let metricsNode;
        if (metrics.length) {
            metricsNode = _.map(metrics, (metric, index: number) => (
                <SectionElementRow metric={metric} key={index} />
            ));
        } else {
            metricsNode = <div className="not-applicable">—</div>;
        }

        const metricsExpanded = isSectionExpanded(expandedSections, "Metrics");

        return (
            <ExplainSection
                title="Metrics"
                sectionName="Metrics"
                expanded={metricsExpanded}
                onExpandCollapse={this.handleExpandCollapseSection}
            >
                <SectionElementRowsContainer>
                    {metricsNode}
                </SectionElementRowsContainer>
            </ExplainSection>
        );
    };

    renderExecutorProjections = (
        executorInstance: ExecutorInstance
    ): React.ReactNode => {
        const projectionMetricRaw = executorInstance.raw["out"];
        const projectionsExpanded = isSectionExpanded(
            this.props.expandedSections,
            "Projections"
        );
        if (projectionMetricRaw) {
            let projectionRows;
            // If it's an array we can assume it's the new projection format
            // format: [{alias: string, projection: string}]
            if (Array.isArray(projectionMetricRaw)) {
                const projectionsMetrics: Array<
                    Metric
                > = projectionMetricRaw.map(
                    (rawMetric: { alias: string; projection: string }) => ({
                        label: rawMetric.alias || rawMetric.projection,
                        value: rawMetric.projection,
                        formatted: true,
                    })
                );

                projectionRows = projectionsMetrics.map((metric, index) => (
                    <SectionElementRow metric={metric} key={index} />
                ));
            } else {
                // This means the `out` property is not parseable and so we fallback to showing
                // it raw. See https://memsql.atlassian.net/browse/DB-33473.
                const oldProjectionsFormatMetric = parseUnknownVariable(
                    projectionMetricRaw,
                    "out"
                );
                projectionRows = (
                    <SectionElementRow metric={oldProjectionsFormatMetric} />
                );
            }

            return (
                <ExplainSection
                    title="Projections"
                    sectionName="Projections"
                    expanded={projectionsExpanded}
                    onExpandCollapse={this.handleExpandCollapseSection}
                    description="Mapping of output expressions to result column names."
                >
                    <SectionElementRowsContainer>
                        {projectionRows}
                    </SectionElementRowsContainer>
                </ExplainSection>
            );
        } else {
            return null;
        }
    };

    renderGlobalSummary = () => {
        const {
            totalTimeMs,
            totalMemoryUsage,
            numOperators,
            expandedSections,
        } = this.props;

        let totalTimeMsSummary;
        if (totalTimeMs !== undefined) {
            totalTimeMsSummary = (
                <SectionElement
                    label={
                        <>
                            Execution Time
                            <IconTip
                                iconProps={{
                                    icon: "question-circle",
                                    leftMargin: true,
                                }}
                                tipProps={{ direction: "sw" }}
                                className="help-icon"
                            >
                                <div className="sidebar-help-tooltip">
                                    This value is the sum of each operation's
                                    total execution time. This is usually lower
                                    than the query's total wall clock time
                                    because not all work is accounted for.
                                </div>
                            </IconTip>
                        </>
                    }
                    value={NumberFormatter.formatDuration(totalTimeMs)}
                />
            );
        }

        let totalMemoryUsageSummary;
        if (totalMemoryUsage !== undefined) {
            totalMemoryUsageSummary = (
                <SectionElement
                    label={
                        <>
                            Memory Usage
                            <IconTip
                                iconProps={{ leftMargin: true }}
                                tipProps={{ direction: "sw" }}
                                className="help-icon"
                            >
                                <div className="sidebar-help-tooltip">
                                    This value is the sum of each operation's
                                    memory usage. This may be higher than the
                                    maximum memory usage because earlier
                                    operations may deallocate memory to be
                                    reused by later operations.
                                </div>
                            </IconTip>
                        </>
                    }
                    value={NumberFormatter.formatBytes(totalMemoryUsage)}
                />
            );
        }

        let numOperatorsSummary;
        if (numOperators !== undefined) {
            numOperatorsSummary = (
                <div className="section-row">
                    <SectionElement
                        label="Total Operations"
                        value={numOperators}
                    />
                </div>
            );
        }

        let measurementsRow;
        if (totalMemoryUsageSummary || totalTimeMsSummary) {
            measurementsRow = (
                <div className="section-row">
                    {totalTimeMsSummary}
                    {totalMemoryUsageSummary}
                </div>
            );
        }

        const expanded = isSectionExpanded(expandedSections, "Summary");

        return (
            <ExplainSection
                title="Summary"
                sectionName="Summary"
                expanded={expanded}
                onExpandCollapse={this.handleExpandCollapseSection}
            >
                {measurementsRow}
                {numOperatorsSummary}
            </ExplainSection>
        );
    };

    render() {
        const { nodes, activeInstance, sourceType } = this.props;

        // If a layout has not been loaded, the sidebar should not
        // render at all.
        if (!nodes) {
            return null;
        }

        let sidebarMainContent;
        if (activeInstance) {
            sidebarMainContent = (
                <>
                    {this.renderExecutorDetails(activeInstance)}
                    {this.renderExecutorMetrics(activeInstance)}
                    {this.renderExecutorProjections(activeInstance)}
                </>
            );
        } else {
            sidebarMainContent = this.renderGlobalDetails(nodes);
        }

        let clusterInfo;
        if (sourceType === "FILE") {
            clusterInfo = this.renderClusterInfo();
        }

        return (
            <CustomScrollbar className="explain-sidebar">
                {clusterInfo}
                {this.renderGlobalSummary()}

                {sidebarMainContent}
            </CustomScrollbar>
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        activeInstance: selectActiveExecutorInstance(s),
        nodes: selectExplainNodes(s),
        planType: selectPlanType(s) || "EXPLAIN",
        numOperators: selectNumOperations(s),
        totalTimeMs: selectTotalTimeMs(s),
        sourceType: selectExplainSourceType(s),
        totalMemoryUsage: selectTotalMemoryUsage(s),
        clusterInfo: selectClusterInfo(s),
        expandedSections: s.explain.expandedSections,
    })
)(ExplainSidebar);
