import { InjectedFormProps, FormErrors } from "redux-form";
import { DispatchFunction } from "data/actions";
import { Maybe } from "util/maybe";

import { ClusterId, ClusterProfile, formatClusterProfile } from "data/models";

import * as React from "react";
import { reduxForm, SubmissionError, Field } from "redux-form";

import Loading from "view/components/loading";
import Modal from "view/components/modal";
import SimpleModalBody from "view/components/simple-modal-body";
import { Button } from "view/common/button";
import FormElement from "view/components/form-element";

import { deleteCluster } from "worker/api/clusters";

import "./delete-cluster.scss";

type FormValues = {
    agreement: boolean;
};

type OwnProps = {
    clusterId: Maybe<ClusterId>;
    clusterProfile: Maybe<ClusterProfile>;
    onClose: () => void;
};

type Props = OwnProps & InjectedFormProps<FormValues, OwnProps>;

class DeleteClusterForm extends React.Component<Props> {
    render() {
        const {
            submitting,
            onClose,
            handleSubmit,
            error,
            clusterProfile,
        } = this.props;

        let formError;
        if (error) {
            formError = <div className="form-error">{error}</div>;
        }

        let inner;
        if (submitting) {
            inner = <Loading size="large" className="loading-icon" />;
        } else {
            inner = (
                <>
                    {formError}
                    Are you sure you want to remove this cluster from your
                    clusters list?
                    <Field
                        name="agreement"
                        id="agreement"
                        type="checkbox"
                        component={FormElement}
                        className="agreement-field"
                        label={
                            <>
                                Please confirm that you really want to delete
                                this{" "}
                                <span className="cluster-profile">
                                    {formatClusterProfile(clusterProfile)}
                                </span>{" "}
                                cluster.
                            </>
                        }
                    />
                </>
            );
        }

        return (
            <Modal
                active
                onClose={onClose}
                innerContentPadding
                actions={
                    <>
                        <Button large onClick={onClose}>
                            Cancel
                        </Button>
                        <Button primary large onClick={handleSubmit}>
                            Remove
                        </Button>
                    </>
                }
            >
                <div className="delete-cluster-form">
                    <SimpleModalBody
                        title="Confirm Cluster Removal"
                        description={inner}
                    />
                </div>
            </Modal>
        );
    }
}

export default reduxForm<FormValues, OwnProps>({
    onSubmit(_values: FormValues, dispatch: DispatchFunction, props: Props) {
        const { clusterId } = props;

        return Promise.resolve(dispatch(deleteCluster(clusterId))).then(
            action => {
                if (action.error) {
                    throw new SubmissionError({
                        _error: action.payload,
                    });
                } else {
                    return action;
                }
            }
        );
    },
    validate(values: FormValues): FormErrors {
        const errors = {
            agreement: "",
        };

        if (!values.agreement) {
            errors.agreement = "This field is required";
        }

        return errors;
    },
})(DeleteClusterForm);
