import * as React from "react";

import NotificationsManager from "view/common/notification-manager/container";
import { Sidebar } from "view/layouts/main/sidebar";

import "./layout.scss";

type LayoutProps = {
    children: React.ReactNode;
    sidebar: React.ReactElement<typeof Sidebar>;
    notifications?: React.ReactElement<typeof NotificationsManager>;
    bottomPanel?: React.ReactNode;
};

export default class MainLayout extends React.Component<LayoutProps> {
    render() {
        const { children, sidebar, bottomPanel, notifications } = this.props;

        return (
            <div className="layout-main">
                {notifications}

                <div className="flex-content">
                    {sidebar}

                    <div className="layout-main-content">
                        <div className="layout-main-page">{children}</div>

                        {bottomPanel}
                    </div>
                </div>
            </div>
        );
    }
}
