import { State, DispatchFunction } from "data";
import { Maybe } from "util/maybe";

import { Column, ColumnId, RowId } from "view/components/super-table";
import { SortDirection, TableSort } from "util/sort";
import { SchemaEntityKind } from "data/models";

import SuperTable, { addSort } from "view/components/super-table";

import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { schemaSort } from "data/actions";

import { nextTableSort } from "util/sort";

export type SchemaColumn<T> = Column & {
    formatter: (r: T) => React.ReactNode;
    getValue: (r: T) => string | number | boolean | undefined | null;
};

type StateProps = {
    sort: TableSort;
};

type OwnProps<T> = {
    entityKind: SchemaEntityKind;
    columns: Array<SchemaColumn<T>>;
    rows: Array<T>;

    getRowId: (r: T) => RowId;
    onClickCell?: (id: Maybe<RowId>, c: Column) => void;
};

type ReduxProps = {
    dispatch: DispatchFunction;
};

type Props<T> = OwnProps<T> & StateProps & ReduxProps;

class SchemaTable extends React.Component<Props<any>> {
    handleSort = (columnId: ColumnId, direction: Maybe<SortDirection>) => {
        const { entityKind, dispatch } = this.props;

        dispatch(
            schemaSort({
                entityKind,
                sort: nextTableSort(columnId, direction),
            })
        );
    };

    render() {
        const { columns, rows, sort, onClickCell, getRowId } = this.props;

        return (
            <SuperTable
                className="schema-table"
                onSort={this.handleSort}
                columns={sort ? _.map(columns, addSort(sort)) : columns}
                rows={_.map(rows, r => ({
                    cells: _.map(columns, c => {
                        return c.formatter(r);
                    }),
                    id: getRowId(r),
                    onClickCell,
                }))}
                rowHeight={40}
                clickable={!!onClickCell}
            />
        );
    }
}

export default connect(
    (s: State, ownProps: OwnProps<unknown>): StateProps => ({
        sort: s.schema.sort[ownProps.entityKind],
    })
)(SchemaTable);
