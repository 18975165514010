import {
    LatestMemsqlVersion,
    LatestStudioVersion,
} from "data/models/remote-version";

import {
    LatestMemsqlVersionAction,
    LatestStudioVersionAction,
} from "data/actions/remote-version";

import { LSM } from "util/loading-state-machine";

import {
    getInitialState,
    reduce as reduceLoadingStateMachine,
} from "util/loading-state-machine";

type Actions = LatestMemsqlVersionAction | LatestStudioVersionAction;

export type RemoteVersionState = {
    latestMemsqlVersion: LSM<LatestMemsqlVersion, string>;
    latestStudioVersion: LSM<LatestStudioVersion, string>;
};

const initialState: RemoteVersionState = {
    latestMemsqlVersion: getInitialState(),
    latestStudioVersion: getInitialState(),
};

export default (state: RemoteVersionState = initialState, action: Actions) => {
    switch (action.type) {
        case "LATEST_MEMSQL_VERSION":
            {
                state = {
                    ...state,
                    latestMemsqlVersion: reduceLoadingStateMachine(
                        state.latestMemsqlVersion,
                        action
                    ),
                };
            }

            break;

        case "LATEST_STUDIO_VERSION":
            {
                state = {
                    ...state,
                    latestStudioVersion: reduceLoadingStateMachine(
                        state.latestStudioVersion,
                        action
                    ),
                };
            }

            break;
    }

    return state;
};
