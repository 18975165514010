import { Maybe } from "util/maybe";
import { State as ReduxState } from "data";
import { NotificationRepr } from "view/common/notification-manager/types";

declare var STUDIO_VERSION: string;
declare var DISABLE_UPGRADE_NOTIFICATIONS: boolean;

import * as React from "react";
import _ from "lodash";

import ExtLink from "view/components/external-link";

import {
    selectError,
    selectPayload,
    selectLoadingOrInitial,
} from "util/loading-state-machine";
import { queryLatestStudioVersion } from "worker/api/remote-version";
import { Version } from "util/version";
import * as logging from "util/logging";

const currentStudioVersion = Version.fromString(STUDIO_VERSION);

type Payload = { lastClosedVersion: string };

function shouldShowStudioNotification(
    state: ReduxState,
    storagePayload: Maybe<Payload>
): Maybe<boolean> {
    if (DISABLE_UPGRADE_NOTIFICATIONS) {
        return false;
    }

    if (selectError(state.remoteVersion.latestStudioVersion)) {
        return false;
    } else if (
        selectLoadingOrInitial(state.remoteVersion.latestStudioVersion)
    ) {
        return undefined;
    } else {
        const payload = selectPayload(state.remoteVersion.latestStudioVersion);

        if (payload === undefined) {
            throw new Error(
                "s.remoteVersion.latestStudioVersion LSM is in an invalid state"
            );
        }

        const latestVersion = payload.version;

        if (storagePayload) {
            try {
                const lastClosedVersion = Version.fromString(
                    storagePayload.lastClosedVersion
                );

                // If the notification was closed before show it if:
                // * Studio latest version is greater than the current one
                // * Studio latest version is greater than the stored value
                return (
                    latestVersion.gt(currentStudioVersion) &&
                    latestVersion.gt(lastClosedVersion)
                );
            } catch (e) {
                logging.log(
                    "error",
                    `An error occurred while parsing the last closed version of a studio upgrade notification from local storage: ${
                        e.message
                    }`
                );
                return false;
            }
        } else {
            // If the notification was never closed before show it if:
            // * Studio latest version is greater than the current one
            return latestVersion.gt(currentStudioVersion);
        }
    }
}

const NOTIFICATION_ID = "upgrade-studio";

export const studioUpgradeNotificationGenerator = (
    state: ReduxState,
    payload: Maybe<Payload>
): NotificationRepr<typeof NOTIFICATION_ID, Payload> => {
    return {
        id: NOTIFICATION_ID,
        level: "INFO",
        closable: true,
        shouldShow: shouldShowStudioNotification(state, payload),
        actionDependencies: [queryLatestStudioVersion()],
        renderMessage: () => {
            const latestVersion = selectPayload(
                state.remoteVersion.latestStudioVersion
            );

            if (latestVersion === undefined) {
                throw new Error(
                    "Error rendering studio-upgrade notification. Studio last version should be defined."
                );
            }

            return (
                <>
                    This cluster is running an outdated version of MemSQL
                    Studio.&nbsp;
                    <ExtLink name="upgrade-studio" category="notifications">
                        Upgrade MemSQL Studio to{" "}
                        {latestVersion.version.toString()}
                    </ExtLink>
                    .
                </>
            );
        },
        writeLocalStorage: () => {
            const latestVersion = selectPayload(
                state.remoteVersion.latestStudioVersion
            );

            if (latestVersion === undefined) {
                throw new Error(
                    "Error saving studio-upgrade notification payload. Studio last version should be defined."
                );
            }

            if (latestVersion) {
                return {
                    lastClosedVersion: latestVersion.version.toString(),
                };
            }
        },
    };
};
