import { Maybe } from "util/maybe";

import { State } from "data";
import { Cluster, ClusterId } from "data/models";

import _ from "lodash";
import { createSelector } from "reselect";

import { selectCurrentClusterId } from "data/selectors/connection";
import { decodeClusterJwt } from "data/models";

import { selectPayload, selectError } from "util/loading-state-machine";

export const selectClusters = (s: State): Maybe<Array<Cluster>> =>
    selectPayload(s.clusters.clusters);

export const selectClustersError = (s: State): Maybe<string> =>
    selectError(s.clusters.clusters);

export const selectCurrentCluster = createSelector(
    selectCurrentClusterId,
    selectClusters,
    (
        clusterId: Maybe<ClusterId>,
        clusters: Maybe<Array<Cluster>>
    ): Maybe<Cluster> => {
        if (clusterId) {
            // Try to parse the Cluster ID as a JWT. If it works, we're dealing
            // with a JWT connection. Otherwise, try to find the regular cluster
            // ID in the list of clusters from the API.
            const jwtCluster = decodeClusterJwt(clusterId);
            if (jwtCluster) {
                return jwtCluster;
            }

            if (clusters) {
                return _.find(clusters, (c: Cluster) => c.id === clusterId);
            }
        }

        return undefined;
    }
);
