import { Observable } from "rxjs";

import { makeActionCreator } from "worker/api/helpers";
import { HandlerContext } from "worker/api";

import backend from "worker/net/backend";

import { ProxyPingAction, ProxyPingStopAction } from "data/actions";
import {
    logSilentError,
    logFocusConnectionError,
    LogMessageAction,
} from "data/actions/log";

const PROXY_SAGA_ID = "proxy-ping";

export const proxyPing = makeActionCreator({
    name: "proxyPing",

    handle(
        ctx: HandlerContext
    ): Observable<ProxyPingAction | LogMessageAction> {
        return ctx.sagas.register(PROXY_SAGA_ID, observer => {
            let running = true;
            let timeoutId: NodeJS.Timeout;
            let lastError = false;

            const emit = (action: ProxyPingAction | LogMessageAction) =>
                running && observer.next(action);

            const ping = () => {
                return backend
                    .ping()
                    .then(() => {
                        emit({
                            type: "PROXY_PING",
                            error: false,
                            payload: {},
                        });

                        lastError = false;
                    })
                    .catch((err: Error) => {
                        emit({
                            type: "PROXY_PING",
                            error: true,
                            payload: { message: err.toString() },
                        });

                        const msg = `Proxy ping failed: ${err.toString()}`;
                        // We want to focus the Connection tab on a ping error
                        // to alert the user that something is wrong, but only
                        // on the first error, if the ping last succeeded
                        // (otherwise, once the ping starts failing, we'd focus
                        // the tab every five seconds and the user wouldn't be
                        // able to close the bottom panel or check other tabs.)
                        if (lastError) {
                            emit(logSilentError(msg));
                        } else {
                            emit(logFocusConnectionError(msg));
                        }

                        lastError = true;
                    })
                    .finally(() => {
                        if (running) {
                            timeoutId = setTimeout(ping, 5000);
                        }
                    });
            };

            ping();

            return () => {
                running = false;

                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
            };
        });
    },
});

export const proxyPingStop = makeActionCreator({
    name: "proxyPingStop",

    handle(ctx: HandlerContext): Observable<ProxyPingStopAction> {
        ctx.sagas.stop(PROXY_SAGA_ID);

        return Observable.of<ProxyPingStopAction>({
            type: "PROXY_PING_STOP",
            error: false,
            payload: {},
        });
    },
});
