import { Observable } from "rxjs";
import { State } from "data/reducers";

// This listener dispatches fake window resize events whenever the bottom panel
// height changes. The reason for this is that certain components (like Monaco
// Editor) listen to window resize events to recalculate their own dimensions.
export default (state$: Observable<State>) => {
    const bottomPanelHeightChanged$ = state$
        .map(
            (state: State): number => {
                return state.bottomPanel.height;
            }
        )
        .distinctUntilChanged()
        .debounceTime(100);

    bottomPanelHeightChanged$.subscribe(() => {
        window.dispatchEvent(new Event("resize"));
    });
};
