import { Maybe } from "util/maybe";
import { TableSort } from "util/sort";
import { FieldList } from "mysqljs";
import { State } from "data/reducers";
import {
    QueryEditorRowsResults,
    QueryEditorExecResults,
    QueryResults,
    QueryEditorUnknownResults,
    QueryEditorErrorResults,
    TabState,
} from "data/reducers/query-editor";

import { Row } from "worker/net/static-connection";
import { QueryExecutorState } from "worker/net/query-executor";

import _ from "lodash";
import { createSelector } from "reselect";
import { typecast } from "memsql/typecast";

import { EDITOR_MAX_TABS } from "view/editor/constants";

import { parseQueries, extractQueries } from "util/sql-query";
import { AbsoluteRange } from "util/range";

export const selectConnectionState = (
    state: State
): Maybe<QueryExecutorState> => state.queryEditor.connectionState;

export const selectTabs = (state: State): Array<TabState> =>
    state.queryEditor.tabs;

export const selectCanOpenNewTab = createSelector(
    selectTabs,
    tabs => tabs.length < EDITOR_MAX_TABS
);

export const selectActiveTabId = (state: State): number =>
    state.queryEditor.activeTabId;

export const selectConnectionTabId = (state: State): Maybe<number> =>
    state.queryEditor.connectionTabId;

export const selectActiveTabState = createSelector(
    selectTabs,
    selectActiveTabId,
    (tabs, activeTabId) => {
        const tab = _.find(tabs, { id: activeTabId });

        if (!tab) {
            throw new Error(`No active tab with id ${activeTabId} found`);
        }

        return tab;
    }
);

export const selectActiveTabIndex = createSelector(
    selectTabs,
    selectActiveTabId,
    (tabs, activeTabId) => {
        const index = _.findIndex(tabs, { id: activeTabId });

        if (index === -1) {
            throw new Error(`No active tab with id ${activeTabId} found`);
        }

        return index;
    }
);

export const selectConnectionTabState = createSelector(
    selectTabs,
    selectConnectionTabId,
    (tabs, connectionTabId): Maybe<TabState> => {
        // Note that connectionTabId may be undefined and, even if defined, may
        // correspond to a tab that still exists. Those are not errors; in
        // those cases we just return undefined.
        return _.find(tabs, { id: connectionTabId });
    }
);

// The tab that is actively using the connection, if one exists.
export const selectRunningTabId = createSelector(
    selectConnectionState,
    selectConnectionTabId,
    (state, connectionTabId) =>
        state === "ACTIVE" ? connectionTabId : undefined
);

export const selectActiveTabIsRunning = createSelector(
    selectActiveTabId,
    selectRunningTabId,
    (activeTabId, connectionTabId) => activeTabId === connectionTabId
);

// Return if the active tab is idle, i.e. does not have an ongoing query. True
// if it's not the connection tab, or if it's the connection tab but the
// connection is idle.
export const selectActiveTabIsIdle = createSelector(
    selectActiveTabId,
    selectConnectionTabId,
    selectConnectionState,
    (activeTabId, connectionTabId, state) =>
        activeTabId !== connectionTabId || state === "IDLE"
);

export const selectStateResults = createSelector(
    selectActiveTabState,
    state => (state.empty ? undefined : state.results)
);

export const selectCanceling = (state: State): boolean =>
    state.queryEditor.canceling;

export const selectSort = createSelector(
    selectActiveTabState,
    state => (state.empty ? undefined : state.sort)
);

export const selectQueryGroup = createSelector(
    selectActiveTabState,
    state => (state.empty ? undefined : state.queryGroup)
);

export const selectQueryStatuses = createSelector(
    selectActiveTabState,
    state => (state.empty ? undefined : state.queryStatuses)
);

export const selectQuerySuccessCount = createSelector(
    selectQueryStatuses,
    (statuses): Maybe<number> => {
        if (statuses) {
            return statuses.filter(s => s.status === "success").length;
        }
    }
);

export const selectQueryErrorCount = createSelector(
    selectQueryStatuses,
    (statuses): Maybe<number> => {
        if (statuses) {
            return statuses.filter(s => s.status === "error").length;
        }
    }
);

export const selectQuerySkippedCount = createSelector(
    selectActiveTabIsIdle,
    selectQueryGroup,
    selectQueryStatuses,
    (idle, group, statuses): Maybe<number> => {
        if (idle && group && statuses) {
            return group.queries.length - statuses.length;
        }
    }
);

export const selectActiveSectionTab = createSelector(
    selectActiveTabState,
    state => (state.empty ? undefined : state.activeSectionTab)
);

export const selectConnectionQueryGroup = createSelector(
    selectConnectionTabState,
    state => (state && !state.empty ? state.queryGroup : undefined)
);

export const selectConnectionQueryStatuses = createSelector(
    selectConnectionTabState,
    state => (state && !state.empty ? state.queryStatuses : undefined)
);

export const selectCursorIndex = (state: State): number =>
    state.queryEditor.cursorInfo.index;

export const selectCursorSelection = (state: State): Maybe<AbsoluteRange> =>
    state.queryEditor.cursorInfo.selectedRange;

export const selectBuffer = (state: State): string => state.queryEditor.buffer;

type QueryEditorRowsResultsSelection = QueryEditorRowsResults & {
    totalRows: number;
};

export type ResultsSelection =
    | QueryEditorRowsResultsSelection
    | QueryEditorExecResults
    | QueryEditorUnknownResults
    | QueryEditorErrorResults;

export type ResultsExport = {
    fields: FieldList;
    rows: Array<Row>;
};

const sortResultRows = (results: QueryEditorRowsResults, sort: TableSort) => {
    const { rows, fields } = results;

    if (sort) {
        // The ID for the columns of the SuperTable in Query Results
        // is simply the stringified index of that column.
        const sortColumnIndex = Number(sort.columnId);

        const fieldType = fields[sortColumnIndex].type;

        return _.orderBy(
            rows,
            [(r: Row) => typecast(r[sortColumnIndex], fieldType)],
            sort ? sort.direction : "desc"
        );
    }

    return rows;
};

export const selectResultsToExport = createSelector(
    selectStateResults,
    selectSort,
    (results: Maybe<QueryResults>, sort: TableSort): Maybe<ResultsExport> => {
        if (canExportResultsAsCSV(results)) {
            return {
                fields: results.fields,
                rows: sortResultRows(results, sort),
            };
        }
    }
);

export const selectCurrentEditorResults = createSelector(
    selectStateResults,
    selectSort,
    (
        results: Maybe<QueryResults>,
        sort: TableSort
    ): Maybe<ResultsSelection> => {
        if (!results) {
            return;
        }

        if (results.type === "exec") {
            return results;
        }

        if (results.type === "unknown") {
            return results;
        }

        if (results.type === "error") {
            return results;
        }

        const rows = sortResultRows(results, sort);

        return {
            ...results,
            rows,
            totalRows: results.rows.length,
        };
    }
);

function canExportResultsAsCSV(
    results: Maybe<QueryResults>
): results is QueryEditorRowsResultsSelection {
    return Boolean(results && results.type === "rows" && !results.loading);
}

// This selector returns whether the SQL editor results are of type "rows" and
// if they have finished. This is the only circumstance where the results can be
// exported as a CSV file.
export const selectCanExportResultsAsCSV = createSelector(
    selectStateResults,
    (results): boolean => {
        return canExportResultsAsCSV(results);
    }
);

export const selectQueryRanges = createSelector(selectBuffer, parseQueries);

export type ActiveQueryRange = {
    range: AbsoluteRange;
    userSelected: boolean;
};

export const selectActiveQueryRange = createSelector(
    selectQueryRanges,
    selectBuffer,
    selectCursorIndex,
    selectCursorSelection,
    (
        queryRanges: Array<AbsoluteRange>,
        buffer: string,
        cursorIdx: number,
        selectedRange: Maybe<AbsoluteRange>
    ): Maybe<ActiveQueryRange> => {
        // if the user is selecting anything - we run that verbatim
        if (selectedRange) {
            return { range: selectedRange, userSelected: true };
        }

        // if there are no query ranges there is nothing to select
        if (queryRanges.length === 0) {
            return undefined;
        }

        // check to see which query the user's cursor is closest to
        let closest, candidate;
        for (let i = 0, l = queryRanges.length; i < l; ++i) {
            candidate = queryRanges[i];
            // check to see if the cursor is inside of this candidate
            if (candidate.start <= cursorIdx && cursorIdx <= candidate.end) {
                // we got a winner!
                closest = candidate;
                break;
            }
            // check to see if the cursor is on the same line as candidate.end,
            // but before the next candidates start
            const nextCandidate = queryRanges[i + 1];
            const nextCandidateStart = nextCandidate
                ? nextCandidate.start
                : Infinity;
            if (cursorIdx > candidate.end && cursorIdx < nextCandidateStart) {
                const newlineIdx = buffer
                    .slice(candidate.end, nextCandidateStart)
                    .indexOf("\n");
                /*
                 * case 1:
                 *      no newline between candidates, so treat the cursor as
                 *      being in the candidate
                 *
                 * case 2:
                 *      if the cursor is to the left of the newline, then treat
                 *      the cursor as being in the candidate
                 */
                if (
                    newlineIdx === -1 ||
                    cursorIdx <= candidate.end + newlineIdx
                ) {
                    closest = candidate;
                    break;
                }
            }

            // If the cursor is ahead of the candidate treat it as the closest
            // query we have seen so far provided the cursor isn't before an
            // already set closest query.
            if (
                !(closest && cursorIdx < closest.end) &&
                cursorIdx < candidate.start
            ) {
                closest = candidate;
            }
        }

        const range = closest || candidate;
        if (range) {
            return { range, userSelected: false };
        }
    }
);

export const selectEditorConnectionCanRunQuery = createSelector(
    selectConnectionState,
    (connectionState): boolean => {
        return connectionState === "IDLE";
    }
);

// We only support running EXPLAIN or PROFILE of a single query at a time.
export const selectEditorCanExplainQuery = createSelector(
    selectActiveQueryRange,
    selectEditorConnectionCanRunQuery,
    selectBuffer,
    (activeQueryRange, canRunQuery, buffer): boolean => {
        // If there is no active query range, there are no queries in the
        // buffer.
        if (activeQueryRange && canRunQuery) {
            const { range, userSelected } = activeQueryRange;

            const queryText = range.extract(buffer);
            const queries = extractQueries(queryText);

            // If the user is selecting queries, then we only want to let them
            // run EXPLAIN if they are currently selecting only 1 query. If the
            // user is not selecting any queries, then the SQL Editor will
            // choose the first one based on the cursor position so it's okay if
            // there are multiple queries in the buffer. Either way, the query
            // that will be EXPLAIN'ed cannot be empty.
            if (userSelected) {
                return queries.length === 1;
            } else {
                return queries.length >= 1;
            }
        }

        return false;
    }
);

export const selectFirstSelection = createSelector(
    selectActiveQueryRange,
    selectBuffer,
    (activeQueryRange, buffer): Maybe<string> => {
        if (activeQueryRange) {
            const { range, userSelected } = activeQueryRange;
            const queryText = range.extract(buffer);

            if (userSelected) {
                return extractQueries(queryText)[0];
            } else {
                return queryText;
            }
        }
    }
);

export const selectEditorCanRunQuery = createSelector(
    selectFirstSelection,
    selectEditorConnectionCanRunQuery,
    (firstSelection, canRunQuery): boolean => {
        return Boolean(firstSelection && canRunQuery);
    }
);

export const selectEditorCanRunQueryInNewTab = createSelector(
    selectEditorCanRunQuery,
    selectCanOpenNewTab,
    (canRunQuery, canOpenNewTab): boolean => canRunQuery && canOpenNewTab
);
