import { Maybe } from "util/maybe";
import { LoadingError } from "util/loading-error";
import { Pipeline, DatabaseStatus } from "data/models";

import _ from "lodash";
import BigNumber from "vendor/bignumber.js/bignumber";

export type DatabaseName = string;

export type Database = {
    kind: "DATABASE";

    databaseName: DatabaseName;

    statistics?: DatabaseStatistics;
};

type DatabaseStatistics = {
    partitionCount: BigNumber;
    syncRepl: Maybe<boolean>;

    // This is the database name from which this database is replicated
    // (undefined if this database is not a DR replica).
    remoteName: Maybe<string>;
};

export type DerivedDatabaseStatistics = {
    tableCount: number;
    dataPartitionInstanceNodeCount: number;
    totalLeafCount: number;

    memoryUsage: BigNumber;
    // 0-1 value relative to the database with the highest memory usage for the
    // entire cluster
    memoryUsagePercent: number;

    diskUsage: LoadingError<BigNumber>; // sum of compressed sizes

    // 0-1 value relative to the database with the highest disk usage for the
    // entire cluster
    diskUsagePercent: LoadingError<number>;
    uncompressedSize: LoadingError<BigNumber>;

    status: DatabaseStatus;
    impacted: boolean;
};

export type DatabaseSummary = {
    databaseName: DatabaseName;

    status: DatabaseStatus;
    impacted: boolean;
};

export type DerivedDatabase = {
    database: Database;
    derived: DerivedDatabaseStatistics;
};

export type TableName = string;
export type TableID = string;

// Used by the Table and ColumnStatistics models.
export type TableStorage = "INMEMORY_ROWSTORE" | "COLUMNSTORE" | "UNKNOWN";

export type TableLikeBase = {
    tableId: TableID;
    tableName: TableName;
    databaseName: DatabaseName;
};

export type TableType = "TEMPORARY" | "BASE" | "UNKNOWN";

export type Table = TableLikeBase & {
    kind: "TABLE";

    tableType: TableType;

    isSharded: boolean;
    tableStorage: TableStorage;

    statistics?: TableStatistics;
};

export type TableStatistics = {
    memoryUse: BigNumber;
    rowCount: BigNumber;
};

type DerivedTableStatistics = {
    diskUsage: LoadingError<BigNumber>; // sum of compressed sizes
    uncompressedSize: LoadingError<BigNumber>;

    // 0-1 value relative to the table with the highest disk usage for each
    // database
    diskUsagePercent: LoadingError<number>;

    // 0-1 value relative to the table with the highest memory usage for each
    // database
    memoryUsagePercent: number;
};

export type DerivedTable = {
    table: Table;
    derived: DerivedTableStatistics;
};

export type View = TableLikeBase & {
    kind: "VIEW";
};

export type TableLike = Table | View;

export type TableKind = TableLike["kind"];

export type DerivedViewStatistics = {
    columnCount: number;
};

export type DerivedView = {
    view: View;
    derived: DerivedViewStatistics;
};

export type ColumnName = string;
export type ColumnID = string;

export type FunctionsStructure = {
    storedProcedures: Array<StoredProcedure>;
    userDefinedFunctions: Array<UserDefinedFunction>;
};

export type SchemaStructure = {
    databases: Array<Database>; // sorted by databaseName ASC
    tables: Array<Table>; // sorted by tableId ASC
    views: Array<View>; // sorted by tableId ASC
    columns: Array<Column>; // sorted by columnId ASC
    viewColumns: Array<ViewColumn>; // sorted by columnId ASC

    aggregates: Array<UserDefinedAggregate>;
} & FunctionsStructure;

// See https://docs.memsql.com/sql-reference/v6.0/datatypes/.

export const DATA_TYPE_TO_CATEGORY = {
    bool: "number",
    bit: "number",
    tinyint: "number",
    smallint: "number",
    mediumint: "number",
    int: "number",
    bigint: "number",
    double: "number",
    float: "number",
    decimal: "number",

    date: "datetime",
    time: "datetime",
    datetime: "datetime",
    year: "datetime",
    timestamp: "datetime",

    char: "string",
    binary: "string",
    varchar: "string",
    varbinary: "string",
    longtext: "string",
    longblog: "string",
    mediumblob: "string",
    blob: "string",
    tinyblob: "string",
    mediumtext: "string",
    text: "string",
    tinytext: "string",

    json: "json",

    geographypoint: "geospatial",
    geospatial: "geospatial",

    enum: "enum",
};

export type DataType = keyof typeof DATA_TYPE_TO_CATEGORY;

const getDataTypesForCategory = (category: string): Array<DataType> =>
    _(DATA_TYPE_TO_CATEGORY)
        .pickBy(v => v === category)
        .keys()
        .uniq()
        .value() as Array<DataType>;

export const CATEGORY_TO_DATA_TYPE: { [key: string]: Array<DataType> } = {
    number: getDataTypesForCategory("number"),
    datetime: getDataTypesForCategory("datetime"),
    string: getDataTypesForCategory("string"),
    json: getDataTypesForCategory("json"),
    geospatial: getDataTypesForCategory("geospatial"),
};

export type DataTypeCategory = keyof typeof CATEGORY_TO_DATA_TYPE;

export type ColumnLikeBase = {
    columnName: ColumnName;
    databaseName: DatabaseName;
    tableName: TableName;
    columnId: ColumnID;

    ordinalPosition: number;
    dataType: DataType; // varchar, int, …
    subType: string; // varchar(11), int(11), …

    isNullable: boolean;
    autoIncrement: boolean;
};

export type ViewColumn = ColumnLikeBase & {
    kind: "VIEW_COLUMN";
};

export type Column = ColumnLikeBase & {
    computed: boolean;
    defaultValueSQL: Maybe<string>;
    columnStorage: TableStorage;

    kind: "TABLE_COLUMN";

    statistics?: ColumnStatistics;
};

type DerivedColumnStatistics = {
    // 0-1 value relative to the column with the highest disk usage for each
    // table
    diskUsagePercent: LoadingError<number>;
    // 0-1 value relative to the column with the highest memory usage for each
    // table
    memoryUsagePercent: number;
};

export type DerivedColumn = {
    column: Column;
    derived: DerivedColumnStatistics;
};

export type ColumnLike = Column | ViewColumn;

export type ColumnStatistics = {
    memoryUse: Maybe<BigNumber>;

    compressedSize: LoadingError<BigNumber>;
    uncompressedSize: LoadingError<BigNumber>;
};

export type IndexName = string;
export type IndexID = string;

export type Index = {
    kind: "INDEX";

    indexId: IndexID;

    databaseName: DatabaseName;
    tableName: TableName;
    indexName: IndexName;

    memoryUse: BigNumber;
    diskUse: Maybe<BigNumber>;
    indexType: string;
    columnNames: Array<ColumnName>;
    unique: boolean;
};

// Returns the ID for a given SchemaEntity.
export const getSchemaEntityID = (entity: SchemaEntity) => {
    switch (entity.kind) {
        case "DATABASE":
            return entity.databaseName;

        case "TABLE":
        case "VIEW":
            return entity.tableId;

        case "USER_DEFINED_FUNCTION":
        case "STORED_PROCEDURE":
        case "USER_DEFINED_AGGREGATE":
            return entity.functionId;

        case "TABLE_COLUMN":
        case "VIEW_COLUMN":
            return entity.columnId;

        case "INDEX":
            return entity.indexId;
    }
};

// Returns true if they are the same schema entity, false otherwise. We
// simply compare the entity's IDs which is safe because it is guaranteed
// that two different types of entities will never have the saame ID.
export const compareSchemaEntities = (
    left: SchemaEntity,
    right: SchemaEntity
) => getSchemaEntityID(left) === getSchemaEntityID(right);

export type SummaryDatabaseEntry = {
    name: DatabaseName;
    diskUsage: BigNumber;
};

// TODO PLAT-1877: fix summary types to support LoadingError pattern for
// columnar data as well.
export type ResourceUsageSummary = {
    memoryUsage: BigNumber;
    diskUsage: BigNumber;

    topDiskDatabases: Array<SummaryDatabaseEntry>;

    databaseNames: Array<DatabaseName>;

    deltaTimeS: number;
};

export type AbsoluteTableName = {
    tableName: TableName;
    databaseName: DatabaseName;
    kind: TableKind;
};

export type FunctionID = string;

export type UserDefinedSVF = {
    kind: "USER_DEFINED_SCALAR_VALUED_FUNCTION";

    functionId: FunctionID;
    databaseName: DatabaseName;
    name: string;
    definer: string;
};

export type UserDefinedFunction = {
    kind: "USER_DEFINED_FUNCTION";

    functionId: FunctionID;
    databaseName: DatabaseName;
    name: string;
    definer: string;
    type: "SCALAR_VALUED_FUNCTION" | "TABLE_VALUED_FUNCTION";
};

export type StoredProcedure = {
    kind: "STORED_PROCEDURE";

    functionId: FunctionID;
    databaseName: DatabaseName;
    name: string;
    definer: string;
};

export type UserDefinedAggregate = {
    kind: "USER_DEFINED_AGGREGATE";

    functionId: FunctionID;
    databaseName: DatabaseName;
    name: string;
};

export type FunctionLike =
    | UserDefinedFunction
    | StoredProcedure
    | UserDefinedAggregate;

export type SchemaEntity =
    | Database
    | TableLike
    | ColumnLike
    | FunctionLike
    | Pipeline
    | Index;

export type SchemaEntityKind = SchemaEntity["kind"];
