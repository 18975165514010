import { Maybe } from "util/maybe";

import { ExecutorInstance, ExplainMetric, ExecutorType } from "data/models";
import { ZoomLevel } from "data/explain/layout";
import { State } from "data";
import { ExplainTab } from "data/actions";

import { Entry } from "view/components/inline-bar";

import * as React from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import Icon from "view/components/icon";
import InlineBar from "view/components/inline-bar";
import ExplainIcon from "view/explain/icon";
import Tip from "view/components/tip";

import { TABLE_SCAN_FRACTION } from "data/explain/dimensions";

import { COLORS } from "util/colors";
import NumberFormatter from "util/number-formatter";
import { logError } from "util/logging";
import { plural } from "util/string";

import "./node.scss";

type StateProps = {
    explainTab: ExplainTab;
};

type Props = StateProps & {
    data: ExecutorInstance;
    zoomLevel: ZoomLevel;
    highlighted?: boolean;
    onClick?: (e: React.MouseEvent<unknown>) => void;
};

// This regex matches before each capital letter that isn't at the start of a
// word. \B matches a non-word-boundary, and (?= ) is a lookahead assertion that
// only matches if the pattern inside matches after the current position in the
// string, but doesn't put that pattern into the match.
const RE_CAMEL_CASE_BOUNDARY = /\B(?=[A-Z])/g;

// Insert zero-width spaces before each capital letter that isn't already at the
// start of a word, to allow line breaks there.
export const breakCamelCase = (s: string): string =>
    s.replace(RE_CAMEL_CASE_BOUNDARY, "\u200b");

const STRIPED_FILL = `repeating-linear-gradient(-45deg, ${
    COLORS["color-neutral-300"]
} 0, ${COLORS["color-neutral-300"]} 5px, ${COLORS["color-neutral-100"]} 5px, ${
    COLORS["color-neutral-100"]
} 10px, ${COLORS["color-neutral-300"]} 10px)`;

const tableScanIcon = (executor: ExecutorType): string => {
    if (executor === "ColumnStoreScan") {
        return "column";
    } else if (executor === "TableScan") {
        return "row";
    } else {
        return "table";
    }
};

class ExplainNode extends React.Component<Props> {
    computeTimeEntries = (totalTimeMs: Maybe<ExplainMetric>): Array<Entry> => {
        if (!totalTimeMs) {
            return [{ value: 1, fill: STRIPED_FILL }];
        }

        const { percent, isMax } = totalTimeMs;

        return [
            {
                value: percent,
                fill: isMax
                    ? COLORS["color-red-900"]
                    : COLORS["color-indigo-600"],
            },
            { value: 1 - percent, fill: COLORS["color-neutral-200"] },
        ];
    };

    computeRowEntries = (rowCount: Maybe<ExplainMetric>): Array<Entry> => {
        if (!rowCount) {
            return [{ value: 1, fill: STRIPED_FILL }];
        }

        const { percent } = rowCount;

        return [
            {
                value: percent,
                fill: COLORS["color-neutral-500"],
            },
            { value: 1 - percent, fill: "transparent" },
        ];
    };

    renderTableScan() {
        const {
            data: {
                executor,
                meta: { databaseName, tableName, isTerminal },
            },
            zoomLevel,
        } = this.props;

        if (databaseName && tableName && isTerminal) {
            let tableScanContent;

            const fullTableName = `${databaseName}.${tableName}`;

            if (zoomLevel !== "small") {
                const iconName = tableScanIcon(executor);

                tableScanContent = (
                    <>
                        <Icon icon={iconName} className="table-icon" />
                        <div className="table-name">{fullTableName}</div>
                    </>
                );
            }

            const tableScanStyle = {
                height: `${TABLE_SCAN_FRACTION * 100}%`,
            };

            return (
                <Tip
                    className="table-scan"
                    style={tableScanStyle}
                    tooltip={fullTableName}
                    direction="s"
                >
                    {tableScanContent || null}
                </Tip>
            );
        }

        return null;
    }

    renderBars() {
        const {
            data: {
                metrics: {
                    totalTimeMs,
                    actualRowCount,
                    estimatedRowCount,
                    deltaRowCount,
                },
            },
            zoomLevel,
            explainTab,
        } = this.props;

        let rowCount,
            rowCountSigned = false;
        switch (explainTab) {
            case "Actual": {
                rowCount = actualRowCount;
                break;
            }

            case "Estimated": {
                rowCount = estimatedRowCount;
                break;
            }

            case "Difference": {
                rowCount = deltaRowCount;
                rowCountSigned = true;
                break;
            }

            default: {
                logError(
                    new Error(
                        `The tab in a Visual Explain node is not known: ${explainTab ||
                            ""}.`
                    )
                );

                // In the unexpected scenario that the explain tab isn't known,
                // just default to estimatedRowCount so as not to crash the UI.
                rowCount = estimatedRowCount;
            }
        }

        let rowCountText = "—";
        let rowsText = "rows";
        // {rowCountText} {rowsText} should be properly pluralized
        if (rowCount) {
            const value = rowCount.value;

            if (rowCountSigned) {
                rowCountText = NumberFormatter.compactInteger(value, true);
            } else {
                rowCountText = NumberFormatter.compactInteger(value);
            }

            rowsText = plural("row", Math.abs(value));
        }

        switch (zoomLevel) {
            case "small": {
                return (
                    <div className="bars">
                        <InlineBar
                            size="extra-small"
                            entries={this.computeTimeEntries(totalTimeMs)}
                        />

                        <InlineBar
                            size="extra-small"
                            entries={this.computeRowEntries(rowCount)}
                        />
                    </div>
                );
            }

            case "medium": {
                return (
                    <div className="bars">
                        <div className="bar-group">
                            <div className="stat">
                                {totalTimeMs
                                    ? NumberFormatter.formatPercent(
                                          totalTimeMs.percent
                                      )
                                    : "—"}
                            </div>

                            <InlineBar
                                size="small"
                                entries={this.computeTimeEntries(totalTimeMs)}
                            />
                        </div>

                        <div className="bar-group">
                            <div className="stat">{rowCountText}</div>

                            <InlineBar
                                size="small"
                                entries={this.computeRowEntries(rowCount)}
                            />
                        </div>
                    </div>
                );
            }

            case "large": {
                return (
                    <div className="bars">
                        <div className="bar-group">
                            <div className="statline">
                                <span className="stat">
                                    {totalTimeMs
                                        ? NumberFormatter.formatPercent(
                                              totalTimeMs.percent
                                          )
                                        : "—"}
                                </span>{" "}
                                total time
                            </div>

                            <InlineBar
                                entries={this.computeTimeEntries(totalTimeMs)}
                            />
                        </div>

                        <div className="bar-group">
                            <div className="statline">
                                <span className="stat">{rowCountText}</span>{" "}
                                {rowsText}
                            </div>

                            <InlineBar
                                entries={this.computeRowEntries(rowCount)}
                            />
                        </div>
                    </div>
                );
            }
        }

        throw new Error("Expected zoomLevel to be exhaustive");
    }

    renderExecutorLabel() {
        const {
            data: { executor },
            zoomLevel,
        } = this.props;

        if (zoomLevel === "large") {
            const labelClasses = classnames("label");

            return (
                <div className={labelClasses}>{breakCamelCase(executor)}</div>
            );
        }

        return null;
    }

    render() {
        const {
            data: { executor },
            zoomLevel,
            highlighted,
            onClick,
        } = this.props;

        const tableScan = this.renderTableScan();
        const bodyStyle = {
            height: tableScan ? `${(1 - TABLE_SCAN_FRACTION) * 100}%` : "100%",
        };

        const bars = this.renderBars();

        const label = this.renderExecutorLabel();

        const nodeClasses = classnames("explain-node", zoomLevel, {
            highlighted,
            "with-table-scan": !!tableScan,
        });

        return (
            <div className={nodeClasses} onClick={onClick}>
                <div className="body" style={bodyStyle}>
                    <div className="type">
                        <ExplainIcon
                            zoomLevel={zoomLevel}
                            executor={executor}
                        />
                        {label}
                    </div>
                    {bars}
                </div>
                {tableScan}
            </div>
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        explainTab: s.explain.tab,
    })
)(ExplainNode);
