import { Maybe } from "util/maybe";

import {
    ClusterStatisticsPayload,
    TopologyPayload,
    MemsqlPartitionsPayload,
} from "data/actions";
import { SchemaStructure, Index } from "data/models";

import _ from "lodash";

export type SchemaFullPayload = {
    structure: SchemaStructure;
    clusterStatistics: ClusterStatisticsPayload;
    indexes: Array<Index>;
    topology: TopologyPayload;
    partitions: MemsqlPartitionsPayload;
};

// This is not super comprehensive but it should cover some basic CI's that
// users can run into.
export const validateSchemaDataFile = (schemaData: any): Maybe<string> => {
    if (!_.isPlainObject(schemaData)) {
        return "Not a valid JSON object";
    }

    // schemaData can be safely assumed to be an Object

    const structure = schemaData.structure;
    const topology = schemaData.topology;
    const indexes = schemaData.indexes;
    const clusterStatistics = schemaData.clusterStatistics;

    if (!_.isArray(indexes)) {
        return "No indexes data found";
    } else if (!_.isObject(structure) || _.size(structure) === 0) {
        return "No structure data found";
    } else if (!_.isObject(topology) || _.size(topology) === 0) {
        return "No topology data found";
    } else if (
        !_.isObject(clusterStatistics) ||
        _.size(clusterStatistics) === 0
    ) {
        return "No cluster statistics data found";
    }

    // implicit undefined return which implies no errors
};
