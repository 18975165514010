import { FeedbackModalStatus } from "view/common/feedback-modal";

import * as React from "react";

import { Button } from "view/common/button";
import { EmptyCard } from "view/common/info-card";
import SvgFeedback from "view/components/svg/feedback.inline.svg";
import FeedbackFormModal from "view/common/feedback-modal";

import "./feedback-card.scss";

type Props = {};

type State = {
    feedbackModalStatus: FeedbackModalStatus;
};

export default class FeedbackCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            feedbackModalStatus: "CLOSED",
        };
    }

    handleChangeFeedbackModalStatus = (status: FeedbackModalStatus) => {
        this.setState({
            feedbackModalStatus: status,
        });
    };

    handleOpenFeedbackModal = () => {
        this.handleChangeFeedbackModalStatus("OPEN");
    };

    render() {
        const { feedbackModalStatus } = this.state;

        return (
            <>
                <EmptyCard
                    className="feedback-card"
                    icon={SvgFeedback}
                    emptyTitle="Suggestions"
                    emptyDescription="What kind of insights would you like to see here?"
                    content={
                        <Button
                            className="feedback-button"
                            onClick={this.handleOpenFeedbackModal}
                            large
                        >
                            Send Feedback
                        </Button>
                    }
                />

                <FeedbackFormModal
                    status={feedbackModalStatus}
                    onStatusChange={this.handleChangeFeedbackModalStatus}
                />
            </>
        );
    }
}
