import { ProxyPingAction } from "data/actions/proxy";
import { MemsqlPingAction } from "data/actions/connection";
import { WebPingAction } from "data/actions/web-api";

import assign from "util/assign";

export type ConnectionStatus =
    | { active: true; date: Date }
    | { active: false; date: Date; lastError: string };

export type SystemStatusState = {
    proxyConnection?: ConnectionStatus;
    memsqlConnection?: ConnectionStatus;
    webConnection?: ConnectionStatus;
};

type Actions = ProxyPingAction | MemsqlPingAction | WebPingAction;

const initialState = {};

export default (state: SystemStatusState = initialState, action: Actions) => {
    switch (action.type) {
        case "PROXY_PING": {
            let proxyConnection: ConnectionStatus;
            if (action.error) {
                proxyConnection = {
                    active: false,
                    date: new Date(),
                    lastError: action.payload.message,
                };
            } else {
                proxyConnection = {
                    active: true,
                    date: new Date(),
                };
            }

            state = assign(state, { proxyConnection });

            break;
        }

        case "MEMSQL_PING": {
            let memsqlConnection: ConnectionStatus;
            if (action.error) {
                memsqlConnection = {
                    active: false,
                    date: new Date(),
                    lastError: action.payload.message,
                };
            } else {
                memsqlConnection = {
                    active: true,
                    date: new Date(),
                };
            }

            state = assign(state, { memsqlConnection });

            break;
        }

        case "WEB_PING": {
            let webConnection: ConnectionStatus;
            if (action.error) {
                webConnection = {
                    active: false,
                    date: action.payload.date,
                    lastError: action.payload.message,
                };
            } else {
                webConnection = {
                    active: true,
                    date: action.payload.date,
                };
            }

            state = { ...state, webConnection };

            break;
        }
    }

    return state;
};
