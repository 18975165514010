import { DispatchFunction, State as ReduxState } from "data";
import { State as RouteState } from "router5";

import * as React from "react";
import { connect } from "react-redux";

import Icon from "view/components/icon";
import { Button } from "view/common/button";
import { MvRecord } from "view/forms/mv-record";

import { selectRoute } from "data/selectors";

import "./mv-record-button.scss";

type Props = {
    dispatch: DispatchFunction;
    children?: React.ReactNode;
    small?: boolean;
    large?: boolean;
    route: RouteState;
};

type State = {
    fixedIntervalFormOpen: boolean;
};

class MVRecordButton extends React.Component<Props, State> {
    state: State = {
        fixedIntervalFormOpen: false,
    };

    handleClick = () => {
        this.setState({
            fixedIntervalFormOpen: true,
        });
    };

    handleCloseForm = () => {
        this.setState({
            fixedIntervalFormOpen: false,
        });
    };

    render() {
        const { children, small, large } = this.props;
        const { fixedIntervalFormOpen } = this.state;

        const iconClasses = children ? "with-right-margin" : undefined;

        let fixedIntervalForm: React.ReactNode = null;
        if (fixedIntervalFormOpen) {
            fixedIntervalForm = (
                <MvRecord form="MvRecordForm" onClose={this.handleCloseForm} />
            );
        }

        return (
            <>
                {fixedIntervalForm}

                <Button
                    small={small}
                    large={large}
                    className="mv-record-btn"
                    onClick={this.handleClick}
                >
                    <Icon fixedWidth icon="circle" className={iconClasses} />
                    {children}
                </Button>
            </>
        );
    }
}

export default connect((s: ReduxState) => ({
    route: selectRoute(s),
}))(MVRecordButton);
