import { Nullable } from "util/nullable";

import BigNumber from "vendor/bignumber.js/bignumber";

export const SQL_DISTRIBUTED_PARTITIONS = `
    SELECT
        HOST AS host,
        PORT AS port,
        DATABASE_NAME AS databaseName,
        ORDINAL AS ordinal,
        ROLE AS role,
        IS_OFFLINE AS isOffline,
        STATE AS syncState
    FROM
        INFORMATION_SCHEMA.DISTRIBUTED_PARTITIONS
`;

export type SQLDistributedPartitionsRow = {
    host: Nullable<string>;
    port: Nullable<BigNumber>;
    databaseName: string;
    ordinal: BigNumber;
    role: Nullable<string>;
    isOffline: Nullable<BigNumber>;
    syncState: Nullable<string>;
};

export const SQL_MV_CLUSTER_STATUS = `
    SELECT
        HOST AS host,
        PORT AS port,
        DATABASE_NAME AS databaseName,
        ROLE AS role,
        POSITION AS position,
        STATE AS state,
        "METADATA ROLE" AS metadataRole,
        DETAILS AS details
    FROM
        INFORMATION_SCHEMA.MV_CLUSTER_STATUS
`;

export type SQLClusterStatusRow = {
    host: Nullable<string>;
    port: Nullable<BigNumber>;
    databaseName: Nullable<string>;
    role: Nullable<string>;
    position: Nullable<string>;
    state: Nullable<string>;
    metadataRole: Nullable<string>;
    details: Nullable<string>;
};

export const SQL_DISTRIBUTED_DATABASES = `
    SELECT
        DATABASE_NAME AS databaseName,
        NUM_PARTITIONS AS numPartitions,
        REMOTE_NAME != "" AS drReplica,
        IS_SYNC AS syncReplicated
    FROM
        INFORMATION_SCHEMA.DISTRIBUTED_DATABASES
`;

export type SQLDistributedDatabasesRow = {
    databaseName: string;
    numPartitions: BigNumber;
    drReplica: BigNumber; // 0 or 1
    syncReplicated: BigNumber; // 0 or 1
};

// We only need to count the metrics from the agreggators. The rows will always
// pass through an aggregator, so a row will be counted once in the leaf and
// once in the aggregator.
export const SQL_CLUSTER_THROUGHPUT = `
    SELECT 
        VARIABLE_NAME AS variableName, 
        SUM(cast(VARIABLE_VALUE as UNSIGNED)) AS variableValue, 
        NOW(6) AS readTime
    FROM
        INFORMATION_SCHEMA.MV_GLOBAL_STATUS 
    WHERE 
        (
            variable_name = 'Rows_affected_by_writes' OR
            variable_name = 'Rows_returned_by_reads'
        ) AND 
        (
            NODE_TYPE = 'MA' OR
            NODE_TYPE = 'CA'
        )
    GROUP BY
        VARIABLE_NAME;
`;

export type SQLRowsThroughputRow = {
    variableName: "Rows_affected_by_writes" | "Rows_returned_by_reads";
    variableValue: BigNumber;
    readTime: Date;
};

// The table MV_GLOBAL_STATUS was added on 7.0. We can remove this check whenever
// we no longer support versions bellow 7 in studio
export const SQL_CLUSTER_THROUGHPUT_ENABLED = `
    SELECT 
        count(*) AS isRowsTroughputEnabled
    FROM 
        INFORMATION_SCHEMA.COLUMNS
    WHERE 
        TABLE_NAME = 'MV_GLOBAL_STATUS' AND 
        COLUMN_NAME = 'NODE_TYPE';
`;

export type SQLClusterTroughputEnabledRow = {
    isRowsTroughputEnabled: BigNumber;
};
