import { TableSort } from "util/sort";
import { Maybe } from "util/maybe";
import {
    ClusterStatisticsAction,
    MemsqlVersionAction,
    MemsqlPartitionsPayload,
    MemsqlPartitionsAction,
    SortPartitionInstancesAction,
    SortPartitionsAction,
    RowsThroughputEnabledAction,
    RowsThroughputAction,
} from "data/actions";

import { ClusterStatistics, RowsThroughputPayload } from "data/models";

import { LSM } from "util/loading-state-machine";

import {
    getInitialState,
    reduce as reduceLoadingStateMachine,
} from "util/loading-state-machine";

import { Version } from "util/version";

type Actions =
    | ClusterStatisticsAction
    | MemsqlVersionAction
    | MemsqlPartitionsAction
    | SortPartitionsAction
    | SortPartitionInstancesAction
    | RowsThroughputAction
    | RowsThroughputEnabledAction;

export type ClusterMetadataState = {
    clusterStatistics: LSM<ClusterStatistics, string>;
    memsqlVersion: LSM<Version, { message: string }>;

    partitions: LSM<MemsqlPartitionsPayload, string>;
    partitionsSort: TableSort;
    partitionInstancesSort: TableSort;

    rowsThroughputEnabled: Maybe<boolean>;
    rowsThroughput: {
        previous: LSM<RowsThroughputPayload, string>;
        current: LSM<RowsThroughputPayload, string>;
    };
};

const initialState: ClusterMetadataState = {
    clusterStatistics: getInitialState(),
    memsqlVersion: getInitialState(),
    partitions: getInitialState(),

    partitionsSort: undefined,
    partitionInstancesSort: {
        columnId: "partitionInstanceRole",
        direction: "desc",
    },

    rowsThroughputEnabled: undefined,
    rowsThroughput: {
        previous: getInitialState(),
        current: getInitialState(),
    },
};

export default (
    state: ClusterMetadataState = initialState,
    action: Actions
) => {
    switch (action.type) {
        case "CLUSTER_STATISTICS":
            {
                state = {
                    ...state,
                    clusterStatistics: reduceLoadingStateMachine(
                        state.clusterStatistics,
                        action
                    ),
                };
            }
            break;

        case "MEMSQL_VERSION":
            {
                state = {
                    ...state,
                    memsqlVersion: reduceLoadingStateMachine(
                        state.memsqlVersion,
                        action
                    ),
                };
            }
            break;

        case "MEMSQL_PARTITIONS":
            {
                state = {
                    ...state,
                    partitions: reduceLoadingStateMachine(
                        state.partitions,
                        action
                    ),
                };
            }

            break;

        case "SORT_PARTITIONS": {
            state = {
                ...state,
                partitionsSort: action.payload,
            };

            break;
        }

        case "SORT_PARTITION_INSTANCES": {
            state = {
                ...state,
                partitionInstancesSort: action.payload,
            };

            break;
        }

        case "ROWS_THROUGHPUT": {
            state = {
                ...state,
                rowsThroughput: {
                    previous: state.rowsThroughput.current,
                    current: reduceLoadingStateMachine(
                        state.rowsThroughput.current,
                        action
                    ),
                },
            };
            break;
        }

        case "ROWS_THROUGHPUT_ENABLED": {
            state = {
                ...state,
                rowsThroughputEnabled: action.payload,
            };
            break;
        }
    }

    return state;
};
