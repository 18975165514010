import { Maybe } from "util/maybe";
import { LabelPosition } from "view/components/labeled-inline-bar";

import * as React from "react";
import classnames from "classnames";
import _ from "lodash";

import LabeledInlineBar from "view/components/labeled-inline-bar";

import { COLORS } from "util/colors";
import NumberFormatter from "util/number-formatter";

import "./ratio-metric-cell.scss";

type Props = {
    // Label position relative to the inline bar
    labelPosition: LabelPosition;
    barClassName?: string;
    value: number;
    bottomLabel?: string;
};

type MetricStatus = "critical";

const getMetricStatus = (value: number): Maybe<MetricStatus> => {
    if (value >= 0.9) {
        return "critical";
    }
};

const BAR_COLOR: { [k in MetricStatus]: string } = {
    critical: COLORS["color-red-900"],
};

const DEFAULT_BAR_COLOR = COLORS["color-indigo-600"];

// This component should be used in the host(s)/node(s) page.
// In order to make the code easier to write/understand we make two assumptions:
// * If the label position relative to the inline bar is north (used in tables),
// we make the label bold and colored, and we add a margin top of 4px to the
// bottom label.
// * If the label position relative to the inline bar is east (used in each page
// top content) the label has no extra styling and there is no need for a margin
// in the bottom label.
export const RatioMetricCell = ({
    value,
    labelPosition,
    bottomLabel,
    barClassName,
}: Props) => {
    const status = getMetricStatus(value);
    const color = status ? BAR_COLOR[status] : DEFAULT_BAR_COLOR;

    value = _.clamp(value, 0, 1);

    const entries = [
        { value, fill: color },
        { value: 1 - value, fill: COLORS["color-neutral-200"] },
    ];

    const classes = classnames(
        "components-common-ratio-metric-cell",
        labelPosition,
        status
    );

    return (
        <div className={classes}>
            <LabeledInlineBar
                barClassName={barClassName}
                label={
                    <div className="cell-label">
                        {NumberFormatter.formatPercent(value)}
                    </div>
                }
                entries={entries}
                labelPosition={labelPosition}
            />
            {/* We always render a &nbsp; to make sure we have 1-line height
                after the bar, even if the bottomLabel is undefined. This is
                usefull to keep height consistency when the component is
                renderer multiple times in the same parent */}
            <div className="cell-bottom-label">{bottomLabel} &nbsp;</div>
        </div>
    );
};
