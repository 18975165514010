import { Maybe } from "util/maybe";
import { TableSort } from "util/sort";
import { LoadingError, LELoading, LESuccess } from "util/loading-error";
import { State } from "data";

import {
    Database,
    DatabaseName,
    Table,
    DerivedTable,
    View,
    DerivedView,
    TableLike,
    ViewColumn,
    TableName,
    DerivedColumn,
    Column,
    DerivedDatabase,
    TableID,
    Index,
    StoredProcedure,
    UserDefinedFunction,
    UserDefinedAggregate,
    SchemaEntityKind,
    Pipeline,
    DatabaseSummary,
} from "data/models";

import { SchemaColumn } from "view/schema/schema-table";
import { GeneralTableColumn } from "view/components/general-table";

import { SchemaFullPayload } from "util/schema/data";

import { TableLikeSamplePayload } from "data/actions";

import { createSelector } from "reselect";
import _ from "lodash";
import assign from "util/assign";
import BigNumber from "vendor/bignumber.js/bignumber";

import { genTableId } from "memsql/db-object-id";

import { selectRoute } from "data/selectors/routes";
import { selectPayload } from "util/loading-state-machine";
import {
    selectTopologyLastUpdate,
    selectTopologyLoading,
    selectTopologyError,
    deriveNodes,
} from "data/selectors/topology";
import {
    selectPipelines,
    selectPipelinesError,
} from "data/selectors/pipelines";
import {
    selectClusterStatisticsError,
    deriveDatabaseStatus,
    deriveDatabaseImpacted,
    derivePartitions,
} from "data/selectors/cluster-metadata";

import { formatNodeAddress } from "data/models";

import {
    selectLoadingOrInitial,
    selectLastUpdate,
    selectIsSuccess,
    selectIsInitial,
    selectIsError,
    selectError,
} from "util/loading-state-machine";
import { updateLoadingError } from "util/loading-error";
import { count } from "util/count";

import {
    DATABASE_COLUMNS,
    TABLE_COLUMNS,
    VIEW_COLUMNS,
    COLUMN_COLUMNS,
    INDEX_COLUMNS,
    VIEW_COLUMN_COLUMNS,
    STORED_PROCEDURE_COLUMNS,
    UDF_COLUMNS,
    UDA_COLUMNS,
} from "memsql/schema-column-info";

import PIPELINES_COLUMNS from "view/pipelines/columns-info";

export type DatabasesSelection = Array<DerivedDatabase>;
export type TablesSelection = Array<DerivedTable>;
export type ColumnsSelection = Array<DerivedColumn>;
export type ViewsSelection = Array<DerivedView>;
export type ViewColumnsSelection = Array<ViewColumn>;
export type IndexesSelection = Array<Index>;
export type StoredProceduresSelection = Array<StoredProcedure>;
export type UDFsSelection = Array<UserDefinedFunction>;
export type UDAsSelection = Array<UserDefinedAggregate>;
export type PipelinesSelection = Array<Pipeline>;

const selectSchemaSort = (kind: SchemaEntityKind) => (s: State): TableSort =>
    s.schema.sort[kind];

export const selectColumns = (s: State): Array<Column> =>
    s.schema.structure.columns;

export const selectViewColumns = (s: State): Array<ViewColumn> =>
    s.schema.structure.viewColumns;

export const selectIndexes = (s: State): Array<Index> => s.schema.indexes;

export const selectDatabases = (s: State): Array<Database> => {
    return s.schema.structure.databases;
};

export function selectDatabaseNames(s: State) {
    const schemaSummary = selectPayload(s.schema.summary);

    if (schemaSummary) {
        return schemaSummary.databaseNames;
    }
}

export const selectTables = (s: State): Array<Table> =>
    s.schema.structure.tables;

export const selectViews = (s: State): Array<View> => s.schema.structure.views;

export const selectStoredProcedures = (s: State): Array<StoredProcedure> =>
    s.schema.structure.storedProcedures;

export const selectUDFs = (s: State): Array<UserDefinedFunction> =>
    s.schema.structure.userDefinedFunctions;

export const selectUDAs = (s: State): Array<UserDefinedAggregate> =>
    s.schema.structure.aggregates;

export const selectCurrentDatabaseName = (s: State): Maybe<DatabaseName> =>
    selectRoute(s).params.databaseName;

export const selectCurrentTableName = (s: State): Maybe<TableName> =>
    selectRoute(s).params.tableName;

export const selectCurrentDatabase = createSelector(
    selectCurrentDatabaseName,
    selectDatabases,
    (currentDatabaseName, databases): Maybe<Database> => {
        if (currentDatabaseName) {
            return _.find(
                databases,
                db => db.databaseName === currentDatabaseName
            );
        }
    }
);

export const selectCurrentTableLike = createSelector(
    selectCurrentDatabaseName,
    selectCurrentTableName,
    selectTables,
    selectViews,
    (
        currentDatabaseName,
        currentTableName,
        tables,
        views
    ): Maybe<TableLike> => {
        if (currentTableName && currentDatabaseName) {
            return _.find(
                _.concat<TableLike>(tables, views),
                t =>
                    t.databaseName === currentDatabaseName &&
                    t.tableName === currentTableName
            );
        }
    }
);

export const selectCurrentTable = (s: State): Maybe<TableName> =>
    selectRoute(s).params.tableName;

export const deriveColumns = createSelector(
    selectColumns,
    (columns): Array<DerivedColumn> => {
        const maxDiskUsagePerTable: {
            [id in TableID]: LoadingError<BigNumber>
        } = _.reduce(
            columns,
            (
                maxPerTable: { [id in TableID]: LoadingError<BigNumber> },
                column: Column
            ) => {
                const tableId = genTableId(column);
                const columnDiskUsage = column.statistics
                    ? column.statistics.compressedSize
                    : new LELoading<BigNumber>();

                if (tableId in maxPerTable) {
                    maxPerTable[tableId] = updateLoadingError(
                        maxPerTable[tableId],
                        currentMax =>
                            updateLoadingError(
                                columnDiskUsage,
                                columnDiskUsage => {
                                    if (columnDiskUsage.gt(currentMax)) {
                                        return new LESuccess(columnDiskUsage);
                                    } else {
                                        return new LESuccess(currentMax);
                                    }
                                }
                            )
                    );
                } else {
                    maxPerTable[tableId] = columnDiskUsage;
                }

                return maxPerTable;
            },
            {}
        );

        const maxMemoryUsagePerTable: { [id in TableID]: BigNumber } = _.reduce(
            columns,
            (maxPerTable: { [id in TableID]: BigNumber }, column: Column) => {
                const tableId = genTableId(column);
                const columnMemoryUsage = column.statistics
                    ? column.statistics.memoryUse
                    : new BigNumber(0);

                if (maxPerTable[tableId]) {
                    if (
                        columnMemoryUsage &&
                        columnMemoryUsage.gt(maxPerTable[tableId])
                    ) {
                        maxPerTable[tableId] = columnMemoryUsage;
                    }
                } else if (columnMemoryUsage) {
                    maxPerTable[tableId] = columnMemoryUsage;
                } else {
                    maxPerTable[tableId] = new BigNumber(0);
                }

                return maxPerTable;
            },
            {}
        );

        return _.map(
            columns,
            (column: Column): DerivedColumn => {
                const maxMemoryUsage =
                    maxMemoryUsagePerTable[genTableId(column)] ||
                    new BigNumber(0);

                let diskUsagePercent: LoadingError<number> = new LELoading();
                let memoryUsagePercent = 0;

                if (column.statistics) {
                    diskUsagePercent = updateLoadingError(
                        column.statistics.compressedSize,
                        compressedSize => {
                            return updateLoadingError(
                                maxDiskUsagePerTable[genTableId(column)],
                                maxDiskUsage => {
                                    if (maxDiskUsage.isZero()) {
                                        return new LESuccess(0);
                                    } else {
                                        return new LESuccess(
                                            compressedSize
                                                .dividedBy(maxDiskUsage)
                                                .toNumber()
                                        );
                                    }
                                }
                            );
                        }
                    );

                    if (maxMemoryUsage.isZero()) {
                        memoryUsagePercent = 0;
                    } else {
                        memoryUsagePercent = (
                            column.statistics.memoryUse || new BigNumber(0)
                        )
                            .dividedBy(maxMemoryUsage)
                            .toNumber();
                    }
                }

                return {
                    column,
                    derived: {
                        diskUsagePercent,
                        memoryUsagePercent,
                    },
                };
            }
        );
    }
);

export const deriveTables = createSelector(
    selectTables,
    selectColumns,
    (tables, columns): Array<DerivedTable> => {
        const derivedTables: { [id in TableID]: DerivedTable } = {};

        for (let i = 0; i < tables.length; i++) {
            const table = tables[i];

            derivedTables[table.tableId] = {
                table,
                derived: {
                    diskUsage: new LESuccess(new BigNumber(0)), // compressed size
                    uncompressedSize: new LESuccess(new BigNumber(0)),

                    diskUsagePercent: new LELoading(),
                    memoryUsagePercent: 0,
                },
            };
        }

        for (let i = 0; i < columns.length; i++) {
            const column = columns[i];
            const tableId = genTableId(column);
            const { derived } = derivedTables[tableId];

            if (column.statistics) {
                const diskUsage = updateLoadingError(
                    column.statistics.compressedSize,
                    columnDiskUsage => {
                        return updateLoadingError(
                            derived.diskUsage,
                            currentDiskUsage => {
                                return new LESuccess(
                                    currentDiskUsage.plus(columnDiskUsage)
                                );
                            }
                        );
                    }
                );

                const uncompressedSize = updateLoadingError(
                    column.statistics.uncompressedSize,
                    columnUncompressedSize => {
                        return updateLoadingError(
                            derived.uncompressedSize,
                            currentUncompressedSize => {
                                return new LESuccess(
                                    currentUncompressedSize.plus(
                                        columnUncompressedSize
                                    )
                                );
                            }
                        );
                    }
                );

                derivedTables[tableId].derived = assign(derived, {
                    diskUsage,
                    uncompressedSize,
                });
            }
        }

        const maxDiskUsagePerDatabase: {
            [name in DatabaseName]: LoadingError<BigNumber>
        } = _.reduce(
            derivedTables,
            (
                maxPerDatabase: {
                    [name in DatabaseName]: LoadingError<BigNumber>
                },
                { table, derived }: DerivedTable
            ) => {
                const databaseName = table.databaseName;
                const tableDiskUsage = derived.diskUsage;

                const currentMax: LoadingError<BigNumber> =
                    maxPerDatabase[databaseName];

                if (databaseName in maxPerDatabase) {
                    maxPerDatabase[databaseName] = updateLoadingError(
                        maxPerDatabase[databaseName],
                        currentMax =>
                            updateLoadingError(
                                tableDiskUsage,
                                tableDiskUsage => {
                                    if (tableDiskUsage.gt(currentMax)) {
                                        return new LESuccess(tableDiskUsage);
                                    } else {
                                        return new LESuccess(currentMax);
                                    }
                                }
                            )
                    );
                } else {
                    maxPerDatabase[databaseName] = tableDiskUsage;
                }

                if (
                    currentMax &&
                    currentMax.isSuccess() &&
                    tableDiskUsage &&
                    tableDiskUsage.isSuccess()
                ) {
                    if (tableDiskUsage.value.gt(currentMax.value)) {
                        maxPerDatabase[databaseName] = tableDiskUsage;
                    }
                } else if (currentMax === undefined) {
                    maxPerDatabase[databaseName] = tableDiskUsage;
                }

                return maxPerDatabase;
            },
            {}
        );

        const maxMemoryUsagePerDatabase: {
            [name in DatabaseName]: BigNumber
        } = _.reduce(
            derivedTables,
            (
                maxPerDatabase: { [name in DatabaseName]: BigNumber },
                { table }: DerivedTable
            ) => {
                const databaseName = table.databaseName;
                const tableMemoryUsage = table.statistics
                    ? table.statistics.memoryUse
                    : new BigNumber(0);

                if (maxPerDatabase[databaseName]) {
                    maxPerDatabase[databaseName] = BigNumber.max(
                        maxPerDatabase[databaseName],
                        tableMemoryUsage
                    );
                } else {
                    maxPerDatabase[databaseName] = tableMemoryUsage;
                }

                return maxPerDatabase;
            },
            {}
        );

        return _.map(
            derivedTables,
            ({ table, derived }: DerivedTable): DerivedTable => {
                const maxMemoryUsage =
                    maxMemoryUsagePerDatabase[table.databaseName] ||
                    new BigNumber(0);

                const diskUsagePercent = updateLoadingError(
                    derived.diskUsage,
                    tableDiskUsage => {
                        // We special case tables with 0 disk usage and
                        // immediately know that their diskUsagePercent is 0.
                        if (tableDiskUsage.isZero()) {
                            return new LESuccess(0);
                        }

                        return updateLoadingError(
                            maxDiskUsagePerDatabase[table.databaseName],
                            maxDiskUsage => {
                                if (maxDiskUsage.isZero()) {
                                    return new LESuccess(0);
                                } else {
                                    return new LESuccess(
                                        tableDiskUsage
                                            .dividedBy(maxDiskUsage)
                                            .toNumber()
                                    );
                                }
                            }
                        );
                    }
                );

                let memoryUsagePercent = 0;
                if (table.statistics) {
                    memoryUsagePercent = maxMemoryUsage.isZero()
                        ? 0
                        : table.statistics.memoryUse
                              .dividedBy(maxMemoryUsage)
                              .toNumber();
                }

                return {
                    table,
                    derived: {
                        ...derived,
                        diskUsagePercent,
                        memoryUsagePercent,
                    },
                };
            }
        );
    }
);

export const selectSortedTables = createSelector(
    selectCurrentDatabaseName,
    selectSchemaSort("TABLE"),
    deriveTables,
    (databaseName, sort, tables): TablesSelection => {
        const filteredTables = _.filter(
            tables,
            ({ table }) => table.databaseName === databaseName
        );

        if (sort) {
            const { direction, columnId } = sort;

            const columnInfo = _.find(
                TABLE_COLUMNS,
                (col: SchemaColumn<unknown>) => col.id === columnId
            );

            if (!columnInfo) {
                throw new Error("Expected column to be defined.");
            }

            return _.orderBy<DerivedTable>(
                filteredTables,
                [columnInfo.getValue],
                direction
            );
        } else {
            return filteredTables;
        }
    }
);

const deriveViews = createSelector(
    selectViews,
    selectViewColumns,
    (views, viewColumns): Array<DerivedView> => {
        const derivedViews: { [id in TableID]: DerivedView } = {};

        for (let i = 0; i < views.length; i++) {
            const view = views[i];

            derivedViews[view.tableId] = {
                view,
                derived: {
                    columnCount: 0,
                },
            };
        }

        for (let i = 0; i < viewColumns.length; i++) {
            const viewColumn = viewColumns[i];
            const viewId = genTableId(viewColumn);
            const { derived } = derivedViews[viewId];

            derivedViews[viewId].derived = {
                columnCount: derived.columnCount + 1,
            };
        }

        return _.map(derivedViews);
    }
);

export const selectSortedViews = createSelector(
    selectCurrentDatabaseName,
    deriveViews,
    selectSchemaSort("VIEW"),
    (currentDatabase, views, sort): ViewsSelection => {
        const selectedViews = _.filter(
            views,
            ({ view }: DerivedView) => view.databaseName === currentDatabase
        );

        if (sort) {
            const { direction, columnId } = sort;

            const columnInfo = _.find(
                VIEW_COLUMNS,
                (col: SchemaColumn<unknown>) => col.id === columnId
            );

            if (!columnInfo) {
                throw new Error("Expected column to be defined.");
            }

            return _.orderBy<DerivedView>(
                selectedViews,
                [columnInfo.getValue],
                direction
            );
        } else {
            return selectedViews;
        }
    }
);

export const selectSortedStoredProcedures = createSelector(
    selectCurrentDatabaseName,
    selectStoredProcedures,
    selectSchemaSort("STORED_PROCEDURE"),
    (currentDatabase, storedProcedures, sort): StoredProceduresSelection => {
        const selectedSortedProcedures = _.filter(
            storedProcedures,
            sp => sp.databaseName === currentDatabase
        );

        if (sort) {
            const { direction, columnId } = sort;

            const columnInfo = _.find(
                STORED_PROCEDURE_COLUMNS,
                (col: SchemaColumn<unknown>) => col.id === columnId
            );

            if (!columnInfo) {
                throw new Error("Expected column to be defined.");
            }

            return _.orderBy<StoredProcedure>(
                selectedSortedProcedures,
                [columnInfo.getValue],
                direction
            );
        } else {
            return selectedSortedProcedures;
        }
    }
);

export const selectSortedUDFs = createSelector(
    selectCurrentDatabaseName,
    selectUDFs,
    selectSchemaSort("USER_DEFINED_FUNCTION"),
    (currentDatabase, userDefinedFunctions, sort): UDFsSelection => {
        const selectedUDFs = _.filter(
            userDefinedFunctions,
            udf => udf.databaseName === currentDatabase
        );

        if (sort) {
            const { direction, columnId } = sort;

            const columnInfo = _.find(
                UDF_COLUMNS,
                (col: SchemaColumn<unknown>) => col.id === columnId
            );

            if (!columnInfo) {
                throw new Error("Expected column to be defined.");
            }

            return _.orderBy<UserDefinedFunction>(
                selectedUDFs,
                [columnInfo.getValue],
                direction
            );
        } else {
            return selectedUDFs;
        }
    }
);

export const selectSortedUDAs = createSelector(
    selectCurrentDatabaseName,
    selectUDAs,
    selectSchemaSort("USER_DEFINED_AGGREGATE"),
    (currentDatabase, aggregates, sort): UDAsSelection => {
        const selectedUDAs = _.filter(
            aggregates,
            aggregate => aggregate.databaseName === currentDatabase
        );

        if (sort) {
            const { direction, columnId } = sort;

            const columnInfo = _.find(
                UDA_COLUMNS,
                (col: SchemaColumn<unknown>) => col.id === columnId
            );

            if (!columnInfo) {
                throw new Error("Expected column to be defined.");
            }

            return _.orderBy<UserDefinedAggregate>(
                selectedUDAs,
                [columnInfo.getValue],
                direction
            );
        } else {
            return selectedUDAs;
        }
    }
);

export const deriveDatabases = createSelector(
    selectDatabases,
    deriveTables,
    derivePartitions,
    deriveNodes,
    (
        databases,
        tables,
        derivedPartitions,
        derivedNodes
    ): Maybe<Array<DerivedDatabase>> => {
        if (derivedPartitions) {
            const derivedDatabases: {
                [name in DatabaseName]: DerivedDatabase
            } = {};

            for (let i = 0; i < databases.length; i++) {
                const database = databases[i];

                const databasePartitions = _.filter(derivedPartitions, {
                    databaseName: database.databaseName,
                });

                let dataPartitionInstanceNodeCount = 0;
                let totalLeafCount = 0;

                if (derivedNodes) {
                    const dataPartitions = _.filter(
                        databasePartitions,
                        part => part.kind === "data"
                    );

                    const dataPartitionInstances = _.flatMap(
                        dataPartitions,
                        dataPart => dataPart.partitionInstances
                    );

                    dataPartitionInstanceNodeCount = _.uniqBy(
                        dataPartitionInstances,
                        formatNodeAddress
                    ).length;

                    totalLeafCount = _.filter(
                        derivedNodes,
                        node => node.role === "LEAF"
                    ).length;
                }

                derivedDatabases[database.databaseName] = {
                    database,
                    derived: {
                        tableCount: 0,
                        dataPartitionInstanceNodeCount,
                        totalLeafCount,

                        memoryUsage: new BigNumber(0),
                        memoryUsagePercent: 0,

                        // Default these to 0 for the case where a database has no tables.
                        diskUsage: new LESuccess(new BigNumber(0)),
                        diskUsagePercent: new LESuccess(0),
                        uncompressedSize: new LESuccess(new BigNumber(0)),

                        status: deriveDatabaseStatus(
                            database.databaseName,
                            databasePartitions
                        ),
                        impacted: deriveDatabaseImpacted(
                            database.databaseName,
                            databasePartitions
                        ),
                    },
                };
            }

            // Derive statistics for every database from their derived tables
            for (let i = 0; i < tables.length; i++) {
                const table = tables[i];
                const databaseName = table.table.databaseName;
                const { derived } = derivedDatabases[databaseName];
                const tableStatistics = table.table.statistics;

                const diskUsage = updateLoadingError(
                    table.derived.diskUsage,
                    tableDiskUsage =>
                        updateLoadingError(
                            derived.diskUsage,
                            currentDiskUsage =>
                                new LESuccess(
                                    currentDiskUsage.plus(tableDiskUsage)
                                )
                        )
                );

                const uncompressedSize = updateLoadingError(
                    table.derived.uncompressedSize,
                    tableUncompressedSize =>
                        updateLoadingError(
                            derived.uncompressedSize,
                            currentUncompressedSize =>
                                new LESuccess(
                                    currentUncompressedSize.plus(
                                        tableUncompressedSize
                                    )
                                )
                        )
                );

                const memoryUsage = derived.memoryUsage.plus(
                    tableStatistics ? tableStatistics.memoryUse : 0
                );

                derivedDatabases[databaseName].derived = assign(derived, {
                    diskUsage,
                    uncompressedSize,
                    memoryUsage,
                    tableCount:
                        derived.tableCount +
                        (table.table.tableType === "BASE" ? 1 : 0),
                });
            }

            let maxDiskUsage: LoadingError<BigNumber> = new LESuccess(
                new BigNumber(0)
            );
            _.forEach(derivedDatabases, ({ derived }) => {
                maxDiskUsage = updateLoadingError(
                    derived.diskUsage,
                    databaseDiskUsage =>
                        updateLoadingError(maxDiskUsage, currentMax => {
                            if (databaseDiskUsage.gt(currentMax)) {
                                return new LESuccess(databaseDiskUsage);
                            } else {
                                return new LESuccess(currentMax);
                            }
                        })
                );
            });

            let maxMemoryUsage = new BigNumber(0);
            _.forEach(derivedDatabases, ({ derived }) => {
                if (derived.memoryUsage.gt(maxMemoryUsage)) {
                    maxMemoryUsage = derived.memoryUsage;
                }
            });

            return _.map(
                derivedDatabases,
                ({ database, derived }: DerivedDatabase): DerivedDatabase => {
                    const diskUsagePercent = updateLoadingError(
                        derived.diskUsage,
                        databaseDiskUsage => {
                            // We special case databases with 0 disk usage and
                            // immediately know that their diskUsagePercent is
                            // 0.
                            if (databaseDiskUsage.isZero()) {
                                return new LESuccess(0);
                            }

                            return updateLoadingError(
                                maxDiskUsage,
                                maxDiskUsage => {
                                    if (maxDiskUsage.isZero()) {
                                        return new LESuccess(0);
                                    } else {
                                        return new LESuccess(
                                            databaseDiskUsage
                                                .dividedBy(maxDiskUsage)
                                                .toNumber()
                                        );
                                    }
                                }
                            );
                        }
                    );

                    const memoryUsagePercent = maxMemoryUsage.isZero()
                        ? 0
                        : derived.memoryUsage
                              .dividedBy(maxMemoryUsage)
                              .toNumber();

                    return {
                        database,
                        derived: {
                            ...derived,
                            diskUsagePercent,
                            memoryUsagePercent,
                        },
                    };
                }
            );
        }
    }
);

export const selectSortedDatabases = createSelector(
    selectSchemaSort("DATABASE"),
    deriveDatabases,
    (sort, databases): Maybe<DatabasesSelection> => {
        if (sort) {
            const { direction, columnId } = sort;

            const databaseInfo = _.find(
                DATABASE_COLUMNS,
                (col: SchemaColumn<unknown>) => col.id === columnId
            );

            if (!databaseInfo) {
                throw new Error("Expected column to be defined.");
            }

            return _.orderBy<DerivedDatabase>(
                databases,
                [databaseInfo.getValue],
                direction
            );
        } else {
            return databases;
        }
    }
);

export const selectSortedColumns = createSelector(
    selectSchemaSort("TABLE_COLUMN"),
    deriveColumns,
    selectCurrentDatabaseName,
    selectCurrentTableName,
    (sort, columns, currentDatabase, currentTable): ColumnsSelection => {
        const selectedColumns = _.filter(
            columns,
            ({ column }) =>
                column.databaseName === currentDatabase &&
                column.tableName === currentTable
        );

        if (sort) {
            const { direction, columnId } = sort;

            const columnInfo = _.find(
                COLUMN_COLUMNS,
                (col: SchemaColumn<unknown>) => col.id === columnId
            );

            if (!columnInfo) {
                throw new Error("Expected column to be defined.");
            }

            return _.orderBy<DerivedColumn>(
                selectedColumns,
                [columnInfo.getValue],
                direction
            );
        } else {
            return selectedColumns;
        }
    }
);

export const selectSortedViewColumns = createSelector(
    selectSchemaSort("VIEW_COLUMN"),
    selectViewColumns,
    selectCurrentDatabaseName,
    selectCurrentTableName,
    (sort, columns, currentDatabase, currentTable): ViewColumnsSelection => {
        const selectedColumns = _.filter(
            columns,
            column =>
                column.databaseName === currentDatabase &&
                column.tableName === currentTable
        );

        if (sort) {
            const { direction, columnId } = sort;

            const columnInfo = _.find(
                VIEW_COLUMN_COLUMNS,
                (col: SchemaColumn<unknown>) => col.id === columnId
            );

            if (!columnInfo) {
                throw new Error("Expected column to be defined.");
            }

            return _.orderBy<ViewColumn>(
                selectedColumns,
                [columnInfo.getValue],
                direction
            );
        } else {
            return selectedColumns;
        }
    }
);

export const selectSortedIndexes = createSelector(
    selectSchemaSort("INDEX"),
    selectIndexes,
    selectCurrentDatabaseName,
    selectCurrentTableName,
    (sort, indexes, currentDatabase, currentTable): IndexesSelection => {
        const selectedIndexes = _.filter(
            indexes,
            index =>
                index.databaseName === currentDatabase &&
                index.tableName === currentTable
        );

        if (sort) {
            const { direction, columnId } = sort;

            const columnInfo = _.find(
                INDEX_COLUMNS,
                (col: SchemaColumn<unknown>) => col.id === columnId
            );

            if (!columnInfo) {
                throw new Error("Expected column to be defined.");
            }

            return _.orderBy<Index>(
                selectedIndexes,
                [columnInfo.getValue],
                direction
            );
        } else {
            return selectedIndexes;
        }
    }
);

export const selectCurrentDerivedTable = createSelector(
    selectCurrentDatabaseName,
    selectCurrentTableName,
    deriveTables,
    (databaseName, tableName, tables) => {
        return _.find(
            tables,
            ({ table }) =>
                table.databaseName === databaseName &&
                table.tableName === tableName
        );
    }
);

export const selectCurrentDerivedDatabase = createSelector(
    selectCurrentDatabaseName,
    deriveDatabases,
    (databaseName, databases) => {
        return _.find(
            databases,
            ({ database }) => database.databaseName === databaseName
        );
    }
);

export const selectCurrentView = createSelector(
    selectCurrentDatabaseName,
    selectCurrentTableName,
    selectViews,
    (databaseName, tableName, views) => {
        return _.find(
            views,
            view =>
                view.databaseName === databaseName &&
                view.tableName === tableName
        );
    }
);

// We return `true` if ANY of the stores that the Schema Explorer
// depends on are in the loading state.
export const selectSchemaExplorerLoading = (s: State): boolean =>
    s.schema.structureLoading ||
    s.schema.statisticsLoading ||
    selectTopologyLoading(s) ||
    selectLoadingOrInitial(s.clusterMetadata.clusterStatistics) ||
    selectLoadingOrInitial(s.pipelines.pipelines) ||
    selectLoadingOrInitial(s.clusterMetadata.partitions);

// We return `true` if ANY of the stores that the Schema Explorer
// depends on are in the initial or error state, because that means we should
// issue a reload action.
export const selectSchemaExplorerNeedsLoading = (s: State): boolean =>
    !(s.schema.structureLoading || s.schema.structureLoaded) ||
    !(s.schema.statisticsLoading || s.schema.statisticsLoaded) ||
    selectIsInitial(s.topology.topology) ||
    selectIsError(s.topology.topology) ||
    selectIsInitial(s.clusterMetadata.clusterStatistics) ||
    !!selectError(s.clusterMetadata.clusterStatistics) ||
    selectIsInitial(s.pipelines.pipelines) ||
    selectIsInitial(s.clusterMetadata.partitions);

// We return `true` if and only if all of the stores that the Schema
// Explorer depends on have been loaded.
export const selectSchemaExplorerLoaded = (s: State): boolean =>
    s.schema.structureLoaded &&
    s.schema.statisticsLoaded &&
    selectIsSuccess(s.topology.topology) &&
    selectIsSuccess(s.clusterMetadata.clusterStatistics) &&
    selectIsSuccess(s.pipelines.pipelines) &&
    selectIsSuccess(s.clusterMetadata.partitions);

// We return the first error we find that may have happened while getting the
// information that the SChema Explorer depends on.
export const selectSchemaExplorerError = (s: State): Maybe<string> =>
    s.schema.structureError ||
    s.schema.statisticsError ||
    selectPipelinesError(s) ||
    selectTopologyError(s) ||
    selectClusterStatisticsError(s) ||
    selectError(s.clusterMetadata.partitions);

// We look at all of the stores that the Schema Explorer depends on and
// return the date that is more recent.
export const selectSchemaExplorerLastUpdate = (s: State): Maybe<Date> =>
    _.max([
        s.schema.lastStatisticsUpdate,
        selectTopologyLastUpdate(s),
        selectLastUpdate(s.clusterMetadata.clusterStatistics),
        selectLastUpdate(s.clusterMetadata.partitions),
    ]);

export const selectSchemaSummaryLoading = (s: State) =>
    selectLoadingOrInitial(s.schema.summary);

export const selectSchemaSummaryLastUpdate = (s: State) =>
    selectLastUpdate(s.schema.summary);

export const selectSchemaSummaryError = (s: State) =>
    selectError(s.schema.summary);

// This method returns the entire state that the Schema Explorer depends
// on. If the schema explorer data has not yet been loaded, then it returns
// `undefined`.
export const selectSchemaFullPayload = (s: State): Maybe<SchemaFullPayload> => {
    const clusterStatistics = selectPayload(
        s.clusterMetadata.clusterStatistics
    );

    const topology = selectPayload(s.topology.topology);

    const partitions = selectPayload(s.clusterMetadata.partitions);

    if (
        !clusterStatistics ||
        !topology ||
        !selectSchemaExplorerLoaded(s) ||
        !partitions
    ) {
        return;
    }

    return {
        structure: s.schema.structure,
        clusterStatistics,
        indexes: s.schema.indexes,
        topology,
        partitions,
    };
};

export const selectSampleData = (s: State): Maybe<TableLikeSamplePayload> => {
    return selectPayload(s.schema.sampleData);
};

export const selectSampleDataError = (s: State): Maybe<{ message: string }> => {
    return selectError(s.schema.sampleData);
};

export const selectSchemaPipelinesSort = selectSchemaSort("PIPELINE");

export const selectSchemaSortedPipelines = createSelector(
    selectCurrentDatabaseName,
    selectPipelines,
    selectSchemaPipelinesSort,
    (currentDatabase, pipelines, sort): PipelinesSelection => {
        if (pipelines) {
            const selectedPipelines = _.filter(
                pipelines,
                pipeline => pipeline.databaseName === currentDatabase
            );

            if (sort) {
                const { direction, columnId } = sort;

                const columnInfo = _.find(
                    PIPELINES_COLUMNS,
                    (col: GeneralTableColumn<unknown>) => col.id === columnId
                );

                if (!columnInfo) {
                    throw new Error("Expected column to be defined.");
                }

                return _.orderBy<Pipeline>(
                    selectedPipelines,
                    [columnInfo.getValue],
                    direction
                );
            } else {
                return selectedPipelines;
            }
        }

        return [];
    }
);

export const deriveDatabasesMetadata = createSelector(
    selectDatabaseNames,
    derivePartitions,
    (databaseNames, derivedPartitions): Maybe<Array<DatabaseSummary>> => {
        if (databaseNames && derivedPartitions) {
            return _.map(databaseNames, databaseName => {
                const databasePartitions = _.filter(derivedPartitions, {
                    databaseName,
                });

                return {
                    databaseName,
                    status: deriveDatabaseStatus(
                        databaseName,
                        databasePartitions
                    ),
                    impacted: deriveDatabaseImpacted(
                        databaseName,
                        databasePartitions
                    ),
                };
            });
        }
    }
);

export const selectAnyDatabasesOfflineWithoutPartitions = createSelector(
    deriveDatabases,
    derivePartitions,
    (derivedDatabases, derivedParititons) => {
        const dataPartitions = _.filter(
            derivedParititons,
            partition =>
                partition.kind === "data" &&
                partition.partitionInstances.length > 0
        );

        const withPartitions = _.uniqBy(dataPartitions, "databaseName").map(
            partition => partition.databaseName
        );

        if (derivedDatabases) {
            const numWithoutPartitionsAndOffline = count(
                derivedDatabases,
                db =>
                    db.derived.status === "offline" &&
                    !withPartitions.includes(db.database.databaseName)
            );

            return numWithoutPartitionsAndOffline > 0;
        }

        return false;
    }
);
