import { Maybe } from "util/maybe";

import NumberFormatter from "util/number-formatter";

import { plural } from "util/string";

export type LicenseType = "developer" | "enterprise" | "free" | "unknown";

export type LicenseCapacity = number | "unlimited";

export type LicenseInfo = {
    usedCapacity: number; // bytes or units
    maximumCapacity: LicenseCapacity; // bytes or units

    unitPricing: boolean;

    type: LicenseType;
    expiration: Date | "unlimited";
    key: string;

    version: number;

    // present if version is 4
    license?: string;
};

export function formatLicenseUsage({
    usedCapacity,
    maximumCapacity,
    unitPricing,
}: LicenseInfo) {
    if (unitPricing) {
        if (maximumCapacity === "unlimited") {
            return `${usedCapacity} ${plural(
                "unit",
                usedCapacity
            )} allocated of unlimited available`;
        } else {
            return `${usedCapacity} ${plural(
                "unit",
                usedCapacity
            )} allocated of ${maximumCapacity} available`;
        }
    } else {
        if (maximumCapacity === "unlimited") {
            const usedCapacityBytes = NumberFormatter.formatBytes(usedCapacity);

            return `${usedCapacityBytes} allocated of unlimited available`;
        } else {
            const usedCapacityBytes = NumberFormatter.formatBytes(usedCapacity);

            const maximumCapacityBytes = NumberFormatter.formatBytes(
                maximumCapacity
            );

            return `${usedCapacityBytes} allocated of ${maximumCapacityBytes} available`;
        }
    }
}

export function formatLicenseType(licenseType: Maybe<LicenseType>) {
    switch (licenseType) {
        case "free":
            return "Free";

        case "enterprise":
            return "Enterprise";

        case "developer":
            return "Developer";

        default:
            return "Unknown";
    }
}
