import { Maybe } from "util/maybe";
import { Column, ColumnSort } from "view/components/super-table";

import * as React from "react";
import _ from "lodash";

import { Checkbox } from "view/components/checkbox";

// C = Context
// D = Data
export type CheckboxColumn<C, D> = Column & {
    formatter: (ctx: C, data: D) => React.ReactNode;
    getValue: () => undefined;
};

export const makeCheckboxColumn = <C, D>(
    getState: (ctx: C, data: D) => Maybe<{ checked: boolean }>,
    sort?: ColumnSort
): CheckboxColumn<C, D> => ({
    id: "checkbox",
    title: "",
    defaultMinWidth: 50,
    defaultMaxWidth: 50,
    sort,
    formatter: (ctx, data) => {
        const state = getState(ctx, data);

        if (state) {
            const { checked } = state;

            // Pass an `onChange` no-op callback to Checkbox so that we don't
            // get an error related to setting `checked` on an input component
            // without passing in an onChange handler.
            return <Checkbox inputProps={{ checked, onChange: _.noop }} />;
        } else {
            return null;
        }
    },
    getValue: () => undefined,
    nonResizable: true,
});
