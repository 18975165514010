import { Direction } from "view/components/overlay";

import * as React from "react";
import * as ReactDOM from "react-dom";
import classnames from "classnames";

import Overlay from "view/components/overlay";

import "./tip.scss";

export type TipProps = {
    className?: string;
    children: React.ReactNode;
    tooltip: React.ReactNode;
    visible?: boolean;
    disabled?: boolean;
    [key: string]: any;
};

type DefaultProps = {
    readonly spacing: number;
    readonly direction: Direction;
};

type TipState = {
    visible: boolean;
};

export default class Tip extends React.Component<TipProps, TipState> {
    static defaultProps: DefaultProps = {
        direction: "se",
        spacing: 10,
    };

    constructor(props: TipProps) {
        super(props);
        this.state = { visible: false };
    }

    getBounds = () => {
        let $el = ReactDOM.findDOMNode(this);
        if ($el && $el instanceof HTMLElement) {
            return $el.getBoundingClientRect();
        }
    };

    handleMove = (evt: MouseEvent) => {
        let bounds = this.getBounds();
        if (bounds) {
            if (evt.clientX > bounds.left && evt.clientX < bounds.right) {
                if (evt.clientY > bounds.top && evt.clientY < bounds.bottom) {
                    this.handleEnter();
                }
            }
        }
    };

    handleEnter = () => {
        this.setState({ visible: true });
    };

    handleLeave = () => {
        this.setState({ visible: false });
    };

    render() {
        const {
            className,
            children,
            visible: forceVisible,
            disabled,
            tooltip,
            direction,
            spacing,
            ...otherProps
        } = this.props;

        const { visible } = this.state;

        const eventHandlers = {
            onMouseMove: this.handleMove,
            onMouseEnter: this.handleEnter,
            onMouseOut: this.handleLeave,
        };

        return (
            <Overlay
                className={classnames("components-tip", className)}
                overlayClassName="components-tip-overlay"
                visible={!disabled && (visible || forceVisible)}
                direction={direction}
                spacing={spacing}
                overlay={<div className="tooltip">{tooltip}</div>}
                layer="tooltip"
                {...otherProps}
                {...eventHandlers}
            >
                {children}
            </Overlay>
        );
    }
}
