declare var STUDIO_VERSION: string;
declare var STUDIO_COMMIT_HASH: string;

import { Maybe } from "util/maybe";

import { DispatchFunction, State } from "data";
import { Node, LicenseInfo } from "data/models";
import { ConnectionStatus } from "data/reducers/system-status";
import { ClusterConnectConfig } from "worker/net/connection-manager";

import * as React from "react";
import { connect } from "react-redux";

import { CustomScrollbar } from "view/components/custom-scrollbar";
import { Version } from "util/version";
import format from "date-fns/format";

import { queryMemsqlVersion } from "worker/api/cluster-metadata";
import { queryTopology } from "worker/api/topology";
import { queryLicense } from "worker/api/license";

import { selectMemsqlVersion } from "data/selectors/cluster-metadata";
import { selectMasterAggregator } from "data/selectors/topology";
import { selectLicenseInfo } from "data/selectors/license";
import Icon from "view/components/icon";
import Loading from "view/components/loading";

import "./tab-connection.scss";

function InfoDiv({ children }: { children: React.ReactNode }) {
    return <div className="info-div">{children}</div>;
}

const online = (
    <span className="online">
        <Icon className="icon" icon="check-circle" /> Online
    </span>
);
const offline = (
    <span className="offline">
        <Icon className="icon" icon="exclamation-circle" /> Offline
    </span>
);

type PingerStatusProps = {
    status?: ConnectionStatus;
};

class PingerStatus extends React.Component<PingerStatusProps> {
    render() {
        const { status } = this.props;

        if (status) {
            return (
                <>
                    <InfoDiv>
                        Status: {status.active ? online : offline} (last ping:{" "}
                        {format(status.date, "h:mm:ss A")})
                    </InfoDiv>
                    {!status.active && (
                        <InfoDiv>Last Error: {status.lastError}</InfoDiv>
                    )}
                </>
            );
        } else {
            return <InfoDiv>Status: Loading</InfoDiv>;
        }
    }
}

type StateProps = {
    proxyConnection?: ConnectionStatus;
    memsqlConnection?: ConnectionStatus;
    config: Maybe<ClusterConnectConfig>;
    masterAggregator: Maybe<Node>;
    memsqlVersion: Maybe<Version>;
    licenseInfo: Maybe<LicenseInfo>;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

class TabConnection extends React.Component<Props> {
    componentDidMount() {
        // fetch data if not present in the store
        if (!this.props.masterAggregator) {
            this.props.dispatch(queryTopology());
        }

        if (!this.props.licenseInfo) {
            this.props.dispatch(queryLicense());
        }

        if (!this.props.memsqlVersion) {
            this.props.dispatch(queryMemsqlVersion());
        }
    }

    render() {
        const {
            proxyConnection,
            memsqlConnection,
            config,
            masterAggregator,
            memsqlVersion,
            licenseInfo,
        } = this.props;

        if (proxyConnection === undefined) {
            return <Loading size="large" />;
        }

        let configNode, masterAggregatorNode, memsqlVersionNode, licenseNode;
        if (config) {
            configNode = (
                <>
                    <InfoDiv>Cluster ID: {config.clusterId}</InfoDiv>
                    <InfoDiv>User: {config.user}</InfoDiv>
                </>
            );
        }
        if (masterAggregator) {
            masterAggregatorNode = (
                <InfoDiv>
                    Host: {masterAggregator.host}:
                    {masterAggregator.port.toString()}
                </InfoDiv>
            );
        }
        if (memsqlVersion) {
            memsqlVersionNode = (
                <InfoDiv>MemSQL Version: {memsqlVersion.toString()}</InfoDiv>
            );
        }
        if (licenseInfo && licenseInfo.key) {
            licenseNode = <InfoDiv>License Key: {licenseInfo.key}</InfoDiv>;
        }

        return (
            <CustomScrollbar dark>
                <div className="tab-connection">
                    <div className="section">
                        <div className="section-title">Proxy Connection</div>

                        <PingerStatus status={proxyConnection} />
                        <InfoDiv>Studio Version: {STUDIO_VERSION}</InfoDiv>
                        <InfoDiv>
                            Studio Commit Hash: {STUDIO_COMMIT_HASH}
                        </InfoDiv>
                    </div>

                    <div className="section">
                        <div className="section-title">MemSQL Connection</div>

                        <PingerStatus status={memsqlConnection} />
                        {configNode}
                        {masterAggregatorNode}
                        {memsqlVersionNode}
                        {licenseNode}
                    </div>
                </div>
            </CustomScrollbar>
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        proxyConnection: s.systemStatus.proxyConnection,
        memsqlConnection: s.systemStatus.memsqlConnection,
        config: s.connection.state.config,
        masterAggregator: selectMasterAggregator(s),
        memsqlVersion: selectMemsqlVersion(s),
        licenseInfo: selectLicenseInfo(s),
    })
)(TabConnection);
