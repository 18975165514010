import { Maybe } from "util/maybe";
import { State as ReduxState, DispatchFunction } from "data";
import {
    NotificationRepr,
    NotificationGenerator,
    NotificationID,
    NotificationPayload,
    NotificationsConfig,
} from "view/common/notification-manager/types";
import { State as ContainerState } from "view/common/notification-manager/container";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";

import Notification from "view/common/notification-manager/notification-bar";

import { logError } from "util/logging";
import { studioUpgradeNotificationGenerator } from "view/common/notification-manager/upgrade-studio";
import * as analytics from "util/segment";

type NotificationMeta<I> = {
    priority: number;
    id: I;
    generator: NotificationGenerator<I, unknown>;
};

// Util function to make sure we are defining the NOTIFICATIONS object with the
// correct IDs as keys.
// Source: https://stackoverflow.com/a/56172482/996056
const asNotifications = <T extends { [K in keyof T]: NotificationMeta<K> }>(
    t: T
) => t;

export const NOTIFICATIONS = asNotifications({
    "upgrade-studio": {
        id: "upgrade-studio",
        priority: 0,
        generator: studioUpgradeNotificationGenerator,
    },
});

type StateProps<I, P> = {
    notificationReprs: Array<NotificationRepr<I, P>>;
};

type OwnProps<I, P> = ContainerState & {
    writeLocalStorage: (id: I, value: P) => void;
};

// NotificationRenderer props is a combination of
// * StateProps: Array of NotificationRepr that holds the notifications
// * ContainerState: Object that maps notification ids to the respective
// LocalStorage value
// * Props: dispatch & function to write local storage with new notification
// values
type Props<I extends NotificationID, P = NotificationPayload<I>> = StateProps<
    I,
    P
> &
    OwnProps<I, P> & {
        dispatch: DispatchFunction;
    };

type State<I extends NotificationID, P = NotificationPayload<I>> = {
    activeNotification: Maybe<NotificationRepr<I, P>>;
};

class NotificationsRenderer<I extends NotificationID> extends React.Component<
    Props<I>,
    State<I>
> {
    state: State<I> = {
        activeNotification: undefined,
    };

    componentDidMount() {
        this.props.notificationReprs.forEach(notificationRepr => {
            notificationRepr.actionDependencies.forEach(action => {
                this.props.dispatch(action);
            });
        });
    }

    static getDerivedStateFromProps(
        props: Props<NotificationID, NotificationPayload<NotificationID>>
    ) {
        // Only render notifications if every `notification.shouldShow` is a
        // boolean.
        // `notification.shouldShow` is undefined when the notification is not
        // ready for rendering (i.e. waiting for necessary data).
        if (
            _.every<NotificationRepr<unknown, unknown>>(
                props.notificationReprs,
                ({ shouldShow }) => typeof shouldShow === "boolean"
            )
        ) {
            // Find the notification to render, relying on order to find the
            // most important one.
            const activeNotification = _.find(props.notificationReprs, {
                shouldShow: true,
            });

            return {
                activeNotification,
            };
        }

        return null;
    }

    shouldComponentUpdate(nextProps: Props<I>) {
        const mapShouldShow = (e: NotificationRepr<unknown, unknown>) =>
            e.shouldShow;

        return !_.isEqual(
            nextProps.notificationReprs.map(mapShouldShow),
            this.props.notificationReprs.map(mapShouldShow)
        );
    }

    componentDidUpdate(__: unknown, prevState: State<I>) {
        const { activeNotification } = this.state;

        if (
            prevState.activeNotification !== activeNotification &&
            activeNotification
        ) {
            analytics.track("notification-opened", {
                category: "notifications",
                id: activeNotification.id,
            });
        }
    }

    handleClose = (
        notificationRepr: NotificationRepr<I, NotificationPayload<I>>
    ) => {
        const { writeLocalStorage } = this.props;

        if (notificationRepr.closable) {
            writeLocalStorage(
                notificationRepr.id,
                notificationRepr.writeLocalStorage()
            );

            analytics.track("notification-closed", {
                category: "notifications",
                id: notificationRepr.id,
            });

            this.setState({ activeNotification: undefined });
        } else {
            logError(
                new Error(
                    `Cannot close unclosable notification: ${
                        notificationRepr.id
                    }`
                )
            );
        }
    };

    render() {
        const { activeNotification } = this.state;

        if (activeNotification) {
            const onClose = activeNotification.closable
                ? () => this.handleClose(activeNotification)
                : undefined;

            return (
                <Notification
                    level={activeNotification.level}
                    message={activeNotification.renderMessage()}
                    onClose={onClose}
                />
            );
        }

        return null;
    }
}

// Temp type to avoid noise in the following util functions
type MetaArray = Array<NotificationMeta<NotificationID>>;

const mapNotificationMeta = (notifications: typeof NOTIFICATIONS): MetaArray =>
    _.map(notifications, v => v);

const orderNotifications = (notificationsMeta: MetaArray): MetaArray =>
    _.orderBy(notificationsMeta, ({ priority }) => priority);

const mapGenerators = (s: ReduxState, storage: NotificationsConfig) => (
    notificationsMeta: MetaArray
) => _.map(notificationsMeta, ({ generator, id }) => generator(s, storage[id]));

const buildNotifications = (
    s: ReduxState,
    storage: NotificationsConfig
): Array<NotificationRepr<NotificationID, unknown>> =>
    compose(
        // Build NotificationRepr using redux state and local storage
        mapGenerators(s, storage),
        // Order notifications by priority
        orderNotifications,
        // Extract meta object from NOTIFICATIONS object
        mapNotificationMeta
    )(NOTIFICATIONS);

// Redux `connect`-ing generic components is not currently possible/easy to do
// in a clean way so we leverage a type assertion.
export default connect(
    (
        s: ReduxState,
        ownProps: OwnProps<unknown, unknown>
    ): StateProps<unknown, unknown> => {
        return {
            notificationReprs: buildNotifications(s, ownProps.storage),
        };
    }
)(NotificationsRenderer as React.ComponentType<Props<NotificationID>>);
