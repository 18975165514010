import { Store } from "data/store";

import observableFromStore from "util/observable-from-store";

import analytics from "./analytics";
import blockNavigation from "./block-navigation";
import bottomPanel from "./bottom-panel";
import { reconnectConsole, reconnectQueryEditor } from "./reconnect";

export default function subscribeAll(store: Store) {
    const state$ = observableFromStore(store);

    analytics(state$);
    blockNavigation(state$);
    bottomPanel(state$);

    reconnectConsole(state$, store);
    reconnectQueryEditor(state$, store);
}
