import { ZoomLevel } from "data/explain/layout";

import * as React from "react";

import Icon from "view/components/icon";

import classnames from "classnames";

import "./icon.scss";

type Props = {
    executor: string;
    className?: string;

    // size options
    zoomLevel: ZoomLevel;
};

const getIcon = (executor: string) => {
    switch (executor) {
        case "Aggregate":
            return "ve-operators-aggregate";

        case "BloomFilter":
            return "ve-operators-bloom-filter";

        case "Broadcast":
            return "ve-operators-broadcast";

        case "ChoosePlan":
            return "ve-operators-choose-plan";

        case "ColumnStoreScan":
            return "ve-operators-columnstore-scan";

        case "Delete":
            return "ve-operators-delete";

        case "DistinctRow":
            return "ve-operators-distinct";

        case "Filter":
            return "ve-operators-filter";

        case "Gather":
            return "ve-operators-gather";

        case "GatherMerge":
            return "ve-operators-gather-merge";

        case "HashGroupBy":
            return "ve-operators-hash-group-by";

        case "HashJoin":
            return "ve-operators-hash-join";

        case "HashTableBuild":
            return "ve-operators-hash-table-build";

        case "IndexRangeScan":
            return "ve-operators-index-range-scan";

        case "IndexSeek":
            return "ve-operators-index-seek";

        case "InsertInto":
            return "ve-operators-insert-into";

        case "MergeJoin":
            return "ve-operators-merge-join";

        case "NestedLoopJoin":
            return "ve-operators-nested-loop-join";

        case "OrderedColumnStoreScan":
            return "ve-operators-ordered-column-store-scan";

        case "Partition":
            return "ve-operators-partition";

        case "Project":
            return "ve-operators-project";

        case "Repartition":
            return "ve-operators-repartition";

        case "ShuffleGroupBy":
            return "ve-operators-shuffle-group-by";

        case "StreamingGroupBy":
            return "ve-operators-streaming-group-by";

        case "TableScan":
            return "ve-operators-table-scan";

        case "Top":
            return "ve-operators-top";

        case "TopSort":
            return "ve-operators-top-sort";

        case "UnionAll":
            return "ve-operators-union-all";

        case "Update":
            return "ve-operators-update";

        case "Window":
            return "ve-operators-window";
    }
};

export default class ExplainIcon extends React.PureComponent<Props> {
    render() {
        const { className, zoomLevel, executor } = this.props;

        const iconName = getIcon(executor);
        const classes = classnames(
            "explain-icon",
            {
                [zoomLevel]: true,
                unknown: !iconName,
            },
            className
        );

        if (iconName) {
            return (
                <div className={classes}>
                    <div>
                        <Icon icon={iconName} />
                    </div>
                </div>
            );
        } else {
            // First two capital letters
            const shortExecutor = executor
                .split("")
                .filter(c => /[A-Z]/.test(c))
                .join("")
                .substr(0, 2);
            return <div className={classes}>{shortExecutor}</div>;
        }
    }
}
