import { Maybe } from "util/maybe";

import { RouteInfo } from "router/types";
import { ExternalLinkInfo } from "view/components/external-link";
import { InlineSVG } from "view/components/icon";

import * as React from "react";
import _ from "lodash";
import classnames from "classnames";

import InternalLink from "view/components/internal-link";
import Card from "view/components/card";
import Loading from "view/components/loading";
import ExtLink from "view/components/external-link";
import Icon from "view/components/icon";
import IconLink from "view/components/icon-link";

import "./info-card.scss";

export const Highlight = ({ children }: { children: React.ReactNode }) => (
    <div className="highlight">{children}</div>
);

type BaseProps = {
    title: string;
    description: React.ReactNode;
    helpLink: ExternalLinkInfo;
};

type ErrorStateProps = BaseProps & {
    error: string;
};

class ErrorCard extends React.PureComponent<ErrorStateProps> {
    render() {
        const { title, description, error, helpLink } = this.props;

        return (
            <Card className="info-card error">
                <div className="card-title">{title}</div>
                <div className="card-description">{description}</div>
                <div className="card-help-link">
                    <IconLink link={helpLink} />
                </div>

                <div className="error-container">
                    <Icon
                        icon="exclamation-circle"
                        iconType="regular"
                        size="4x"
                        className="icon"
                    />

                    <div className="title">Unable to load</div>

                    <div className="message-container">
                        An error occured while loading this card's data:
                        <div className="message">{error}</div>
                    </div>
                </div>
            </Card>
        );
    }
}

type LoadingStateProps = BaseProps;

class LoadingCard extends React.PureComponent<LoadingStateProps> {
    render() {
        const { title, description, helpLink } = this.props;

        return (
            <Card className="info-card loading">
                <div className="card-title">{title}</div>
                <div className="card-description">{description}</div>
                <div className="card-help-link">
                    <IconLink link={helpLink} />
                </div>

                <div className="loading-container">
                    <div>
                        <Loading outlineOnly size="medium" />
                        <div>Loading</div>
                    </div>
                </div>
            </Card>
        );
    }
}

type EmptyStateProps = {
    emptyTitle: string;
    emptyDescription: string;
    icon: InlineSVG;
    content: React.ReactNode;
    className?: string;
};

export class EmptyCard extends React.PureComponent<EmptyStateProps> {
    render() {
        const {
            emptyTitle,
            emptyDescription,
            content,
            className,
            icon: InfoCardIcon,
        } = this.props;

        const classes = classnames("info-card", "empty", className);

        return (
            <Card className={classes}>
                <div className="empty-container">
                    <div className="icon">
                        <InfoCardIcon />
                    </div>

                    <div className="title">{emptyTitle}</div>
                    <div className="description">{emptyDescription}</div>

                    {content}
                </div>
            </Card>
        );
    }
}

// Necessary information to render the "View All" (View More) link at the bottom of the
// Success Card.
type BottomLink = {
    routeInfo: RouteInfo;
    linkText: string;
};

type SuccessStateProps = BaseProps & {
    content: React.ReactNode;
    bottomLink?: BottomLink;
};

class SuccessCard extends React.PureComponent<SuccessStateProps> {
    render() {
        const {
            title,
            description,
            content,
            helpLink,
            bottomLink,
        } = this.props;

        let bottomLinkNode;
        if (bottomLink) {
            const { routeInfo, linkText } = bottomLink;

            bottomLinkNode = (
                <div className="view-all">
                    <InternalLink
                        category="success-card"
                        routeInfo={routeInfo}
                        className="view-all"
                    >
                        {linkText}
                    </InternalLink>
                </div>
            );
        }

        return (
            <Card className="info-card success">
                <div className="top-container">
                    <div className="card-title">{title}</div>
                    <div className="card-description">{description}</div>
                    <div className="card-help-link">
                        <IconLink link={helpLink} />
                    </div>

                    <div className="content">{content}</div>
                </div>

                {bottomLinkNode}
            </Card>
        );
    }
}

type CommonProps = {
    title: string;
    description: React.ReactNode;
    helpLink: ExternalLinkInfo;

    // success state
    content: Maybe<React.ReactNode>;
    bottomLink?: BottomLink;

    loading: boolean;

    // error state
    error: Maybe<string>;
};

type PropsEmpty = CommonProps & {
    hasEmptyState: true;
    emptyTitle: string;
    emptyDescription: string;
    icon: InlineSVG;
    emptyHelpLink: ExternalLinkInfo;
};

type PropsNotEmpty = CommonProps & { hasEmptyState: false };

// The InfoCard's empty state is optional — not all of the info
// cards have one. Thus, clients of the InfoCard component have to
// specify whether they have an empty state or not.
export type InfoCardProps = PropsEmpty | PropsNotEmpty;

export class InfoCard extends React.Component<InfoCardProps> {
    render() {
        const {
            loading,
            error,
            title,
            description,
            content,
            bottomLink,
            helpLink,
        } = this.props;

        if (loading) {
            return (
                <LoadingCard
                    title={title}
                    description={description}
                    helpLink={helpLink}
                />
            );
        } else if (error) {
            return (
                <ErrorCard
                    title={title}
                    description={description}
                    helpLink={helpLink}
                    error={error}
                />
            );
        } else if (content) {
            return (
                <SuccessCard
                    title={title}
                    description={description}
                    helpLink={helpLink}
                    content={content}
                    bottomLink={bottomLink}
                />
            );
        } else if (this.props.hasEmptyState) {
            const {
                emptyTitle,
                emptyDescription,
                emptyHelpLink,
                icon,
            } = this.props;

            return (
                <EmptyCard
                    emptyTitle={emptyTitle}
                    emptyDescription={emptyDescription}
                    icon={icon}
                    content={
                        <ExtLink {...emptyHelpLink} className="help-link">
                            Learn More
                        </ExtLink>
                    }
                />
            );
        }

        return null;
    }
}
