import { TabTitle } from "data/reducers/bottom-panel";

import * as React from "react";
import _ from "lodash";
import classnames from "classnames";

import "./tab-switcher.scss";

type Props = {
    activeTabTitle: TabTitle;
    onTabChange: (tabTitle: TabTitle) => void;

    // We keep track of whether the bottom panel has ever opened so that we
    // highlight the active tab only after the user has opened the panel at
    // least once.
    everOpened: boolean;
};

const TAB_TITLES: Array<TabTitle> = ["Console", "Connection", "Log"];

export default class TabSwitcher extends React.Component<Props> {
    onMouseDown = (title: TabTitle) => (e: React.MouseEvent) => {
        // prevent onMouseDown of parent div
        e.stopPropagation();

        // avoid collision with input console focus
        e.preventDefault();

        this.props.onTabChange(title);
    };

    render() {
        const { activeTabTitle, everOpened } = this.props;

        return (
            <div className="bottom-panel-tab-switcher">
                {_.map(TAB_TITLES, title => {
                    const classes = classnames(
                        {
                            active: title === activeTabTitle && everOpened,
                        },
                        "tab-title"
                    );

                    return (
                        <div
                            className={classes}
                            key={title}
                            onMouseDown={this.onMouseDown(title)}
                            role="button"
                            tabIndex={0}
                        >
                            {title}
                        </div>
                    );
                })}
            </div>
        );
    }
}
