import { Maybe } from "util/maybe";
import { TableSort } from "util/sort";

import { State } from "data/reducers";
import { MvFullRepr } from "data/actions/management-views";
import { MvContext, MvColumn } from "view/monitor/mv-table";

import {
    MvNodeId,
    MvNode,
    MvNodeWithStats,
    MvActivityType,
    MvActivityName,
    MvActivityHighLevel,
    MvActivityLowLevel,
    MvStatistics,
    MvStatisticsColumn,
    MvAggregatedStatistics,
} from "data/models";
import { MvStatisticsColumnInfo } from "memsql/mv-column-info";

import { createSelector } from "reselect";
import _ from "lodash";
import BigNumber from "vendor/bignumber.js/bignumber";

import {
    COLUMNS,
    ACTIVITIES_COLUMNS,
    NODE_COLUMNS,
    ACTIVITIES_COLUMNS_ADVANCED,
} from "memsql/mv-column-info";

import Stat from "util/stat";

const selectActivities = (state: State): Array<MvActivityLowLevel> =>
    state.managementViews.activities;

export const selectMvNodes = (state: State): { [id in MvNodeId]: MvNode } =>
    state.managementViews.nodes;

const selectAdvancedCounters = (state: State): boolean =>
    state.managementViews.advancedCounters;

const selectFilterNodeId = (state: State): Maybe<MvNodeId> =>
    state.managementViews.filters.nodeId;

const selectFilterActivityName = (state: State): Maybe<MvActivityName> =>
    state.managementViews.filters.activityName;

const selectFilterActivityType = (state: State): Maybe<MvActivityType> =>
    state.managementViews.filters.activityType;

const selectQueriesSort = (state: State): TableSort =>
    state.managementViews.queriesSort;

const selectNodesSort = (state: State): TableSort =>
    state.managementViews.nodesSort;

const selectFilteredActivities = createSelector(
    selectActivities,
    selectFilterActivityName,
    selectFilterActivityType,
    selectFilterNodeId,
    (
        activities,
        activityName,
        activityType,
        nodeId
    ): Array<MvActivityLowLevel> =>
        _.filter(
            activities,
            activity =>
                (!activityName ||
                    activity.aggregatorActivityName === activityName) &&
                (!activityType || activity.activityType === activityType) &&
                (!nodeId || activity.nodeId === nodeId)
        )
);

const selectFilteredNodes = createSelector(
    selectMvNodes,
    selectFilterNodeId,
    (nodes, nodeId): { [id in MvNodeId]: MvNode } =>
        _.pickBy(nodes, (node: MvNode) => !nodeId || node.nodeId === nodeId)
);

const newMvAggregatedStatistics = (): MvAggregatedStatistics => ({
    cpuUsage: new Stat(),
    cpuTime: new Stat(),
    cpuWaitTime: new Stat(),

    elapsedTimeMs: new Stat(),

    memoryB: new Stat(),
    memoryMajorFaults: new Stat(),

    lockTime: new Stat(),
    lockRowTime: new Stat(),

    logBufferTime: new Stat(),
    logFlushTime: new Stat(),
    logBufferWriteB: new Stat(),

    networkTime: new Stat(),
    networkLogicalRecvB: new Stat(),
    networkLogicalSendB: new Stat(),
    networkB: new Stat(),

    diskTime: new Stat(),
    diskLogicalReadB: new Stat(),
    diskLogicalWriteB: new Stat(),
    diskPhysicalReadB: new Stat(),
    diskPhysicalWriteB: new Stat(),
    diskB: new Stat(),
});

const accumulateMvStatistics = (
    left: MvAggregatedStatistics,
    right: MvStatistics
): MvAggregatedStatistics =>
    _.mapValues(left, (agg: Stat, key: MvStatisticsColumn) => {
        return agg.append(right[key] || new BigNumber(0));
    }) as MvAggregatedStatistics;

// This function takes in a column ID and returns the
// `getValue` function for that column so that it can
// be used by the _.orderBy function to sort the row.
function findGetValue<T>(columnId: string, columns: Array<MvColumn<T>>) {
    const entityColumn = _.find(columns, col => col.id === columnId);

    const statsColumn: MvStatisticsColumnInfo = _.get(COLUMNS, columnId);

    const columnInfo = entityColumn || statsColumn;

    if (!columnInfo) {
        throw new Error(`Failed to find column with id ${columnId}`);
    }

    return columnInfo.getValue;
}

export type MvNodesSelection = {
    nodes: Array<MvNodeWithStats>;
};

export const mvNodesSelector = createSelector(
    selectFilteredActivities,
    selectFilteredNodes,
    selectNodesSort,
    (
        activities: Array<MvActivityLowLevel>,
        nodes: { [id in MvNodeId]: MvNode },
        sort
    ): MvNodesSelection => {
        let nodeCache: { [id in MvNodeId]: MvNodeWithStats } = {};

        const getNode = (nodeId: MvNodeId): MvNodeWithStats => {
            if (!nodeCache[nodeId]) {
                const node = nodes[nodeId];

                if (!node) {
                    throw new Error("No node with id: " + nodeId);
                }

                nodeCache[nodeId] = {
                    ...node,
                    statistics: newMvAggregatedStatistics(),
                };
            }

            return nodeCache[nodeId];
        };

        for (let i = 0; i < activities.length; ++i) {
            const act = activities[i];
            const node = getNode(act.nodeId);

            node.statistics = accumulateMvStatistics(
                node.statistics,
                act.statistics
            );
        }

        const getSortValue = findGetValue(
            (sort && sort.columnId) || "endpoint",
            _.concat<MvColumn<unknown>>(ACTIVITIES_COLUMNS, NODE_COLUMNS)
        );

        return {
            nodes: _.orderBy(
                nodeCache,
                [getSortValue],
                sort ? sort.direction : "desc"
            ),
        };
    }
);

export type MvActivitiesSelection = {
    highLevel: Array<MvActivityHighLevel>;
    lowLevel: { [name in MvActivityName]: Array<MvActivityLowLevel> };
};

export const mvActivitiesSelector = createSelector(
    selectFilteredActivities,
    selectQueriesSort,
    (
        activities: Array<MvActivityLowLevel>,
        sort: TableSort
    ): MvActivitiesSelection => {
        let highCache: { [name in MvActivityName]: MvActivityHighLevel } = {};

        const getHighActivity = (
            act: MvActivityLowLevel
        ): MvActivityHighLevel => {
            const activityName = act.aggregatorActivityName;

            if (!highCache[activityName]) {
                highCache[activityName] = {
                    kind: "HIGH_LEVEL",
                    activityName,
                    activityType: act.activityType,
                    databaseName: act.databaseName,
                    runCount: new BigNumber(0),
                    successCount: new BigNumber(0),
                    failureCount: new BigNumber(0),
                    queryText: undefined,

                    statistics: newMvAggregatedStatistics(),
                };
            }

            return highCache[activityName];
        };

        for (let i = 0; i < activities.length; ++i) {
            const act = activities[i];
            const highAct = getHighActivity(act);

            highAct.statistics = accumulateMvStatistics(
                highAct.statistics,
                act.statistics
            );

            highAct.runCount = BigNumber.max(highAct.runCount, act.runCount);
            highAct.successCount = BigNumber.max(
                highAct.successCount,
                act.successCount
            );
            highAct.failureCount = BigNumber.max(
                highAct.failureCount,
                act.failureCount
            );

            if (act.activityName === highAct.activityName) {
                highAct.queryText = act.queryText;
            }
        }

        const getSortValue = findGetValue(
            (sort && sort.columnId) || "activityName",
            ACTIVITIES_COLUMNS_ADVANCED
        );

        return {
            highLevel: _.orderBy(
                highCache,
                [getSortValue],
                sort ? sort.direction : "desc"
            ),
            lowLevel: _(activities)
                .groupBy(a => a.aggregatorActivityName)
                .mapValues(acts =>
                    _.orderBy<MvActivityLowLevel>(
                        acts,
                        [getSortValue],
                        sort ? sort.direction : "desc"
                    )
                )
                .value(),
        };
    }
);

export const mvFilteredQueryTextSelector = createSelector(
    selectActivities,
    selectFilterActivityName,
    (activities, activityName): Maybe<string> => {
        if (activityName) {
            const a = _.find(
                activities,
                a => a.aggregatorActivityName === activityName
            );
            return a ? a.queryText : undefined;
        }
    }
);

export const selectMvFullRepr = createSelector(
    selectActivities,
    selectMvNodes,
    selectAdvancedCounters,
    (activities, nodes, advancedCounters): MvFullRepr => {
        return {
            activities,
            nodes,
            advancedCounters,
        };
    }
);

export const selectMvContext = createSelector(
    selectMvNodes,
    (nodes): MvContext => ({
        nodes,
    })
);
