import { Maybe } from "util/maybe";

import { DispatchFunction, State as ReduxState } from "data";
import { StaticConnectionId } from "worker/net/connection-manager";
import { QueryExecutorState } from "worker/net/query-executor";

import { cancelQuery } from "worker/api/connection";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import Header from "view/common/header";
import EditorMenu from "view/editor/header/menu";
import ExplainMenu from "view/editor/header/explain-menu";
import { Button } from "view/common/button";
import { Menu, MenuItem } from "view/common/menu";
import DatabaseSelect from "./database-select";
import { getShortRunQueryShortcut } from "view/editor/keyboard-shortcuts";

import { runQuery } from "data/actions/query-editor";
import { EDITOR_MAX_TABS_EXPLANATION } from "view/editor/constants";

import {
    selectCanceling,
    selectEditorCanExplainQuery,
    selectEditorCanRunQuery,
    selectEditorCanRunQueryInNewTab,
} from "data/selectors/page-editor";

import "./header-container.scss";

type StateProps = {
    connectionId: StaticConnectionId;
    connectionState: Maybe<QueryExecutorState>;
    canceling: Maybe<boolean>;
    canExplainQuery: boolean;
    canRunQuery: boolean;
    canRunQueryInNewTab: boolean;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

class HeaderContainer extends React.Component<Props> {
    handleRunQuery = () => {
        this.props.dispatch(runQuery());
    };

    handleRunInNewTab = () => {
        this.props.dispatch(runQuery({ newTab: true }));
    };

    handleCancelQuery = () => {
        const { dispatch, connectionId, connectionState } = this.props;

        if (connectionState === "ACTIVE") {
            dispatch(cancelQuery({ id: connectionId }));
        }
    };

    renderRunCancelButton = () => {
        const {
            connectionState,
            canceling,
            canRunQuery,
            canRunQueryInNewTab,
        } = this.props;

        let label, onClick, disabled, dropdownContent;

        if (connectionState === "ACTIVE") {
            // Cancel Button

            if (canceling) {
                label = "Canceling";
                onClick = _.noop;
                disabled = true;
            } else {
                label = "Cancel";
                onClick = this.handleCancelQuery;
                disabled = false;
            }
        } else if (connectionState === "PAUSED") {
            label = "Paused";
            disabled = true;
            onClick = _.noop;
        } else if (connectionState === "INITIALIZING") {
            label = "Initializing";
            disabled = true;
            onClick = _.noop;
        } else {
            // Run Button (but possibly disabled depending on activeQueryRange and connectionState)

            label = (
                <span>
                    Run{" "}
                    <span className="shortcut">
                        {getShortRunQueryShortcut()}
                    </span>
                </span>
            );
            onClick = this.handleRunQuery;
            disabled = !canRunQuery;

            let newTabTipProps;
            if (!canRunQueryInNewTab) {
                newTabTipProps = {
                    tooltip: EDITOR_MAX_TABS_EXPLANATION,
                    direction: "sw",
                };
            }

            dropdownContent = (
                <Menu>
                    <MenuItem
                        /* eslint-disable-next-line react/jsx-handler-names */
                        action={this.handleRunInNewTab}
                        disabled={!canRunQueryInNewTab}
                        tipProps={newTabTipProps}
                    >
                        Run & Show Result in New Tab
                    </MenuItem>
                </Menu>
            );
        }

        return (
            <Button
                large
                primary
                className="run-cancel-btn"
                disabled={disabled}
                onClick={onClick}
                dropdownContent={dropdownContent}
            >
                {label}
            </Button>
        );
    };

    render() {
        const { canExplainQuery } = this.props;

        const actions = (
            <div className="actions">
                <EditorMenu />
                <ExplainMenu disabled={!canExplainQuery} />
                {this.renderRunCancelButton()}
            </div>
        );

        return (
            <Header
                className="schema-header"
                left={<DatabaseSelect className="database-select" />}
                right={actions}
            />
        );
    }
}

export default connect(
    (s: ReduxState): StateProps => ({
        connectionId: s.queryEditor.connectionId,
        canceling: selectCanceling(s),
        connectionState: s.queryEditor.connectionState,
        canRunQuery: selectEditorCanRunQuery(s),
        canRunQueryInNewTab: selectEditorCanRunQueryInNewTab(s),
        canExplainQuery: selectEditorCanExplainQuery(s),
    })
)(HeaderContainer);
