import { Maybe } from "util/maybe";

import { State } from "data";
import { ResourceUsageSummary } from "data/models";
import { State as RouteState } from "router5";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { DashboardCard } from "view/common/dashboard-card";

import SvgSchema from "view/components/svg/schema.inline.svg";
import HorizontalBarChart from "view/components/horizontal-bar-chart";
import { FakeClick } from "view/common/fake-click";
import SectionHeader from "view/components/section-header";

import { selectPayload } from "util/loading-state-machine";
import {
    selectSchemaSummaryLoading,
    selectSchemaSummaryError,
} from "data/selectors/schema";
import { selectRoute } from "data/selectors/routes";

import NumberFormatter from "util/number-formatter";
import { COLORS } from "util/colors";
import { LONG_EM_DASH } from "util/symbols";

import "./database-usage-card.scss";

type StateProps = {
    loading: boolean;
    schemaSummary: Maybe<ResourceUsageSummary>;
    schemaError: Maybe<string>;
    route: RouteState;
};

type DatabaseUsageCardProps = StateProps & {
    className?: string;
};

class DatabaseUsageCard extends React.Component<DatabaseUsageCardProps> {
    renderContent = (): Maybe<React.ReactNode> => {
        const { schemaSummary } = this.props;

        if (!schemaSummary) {
            throw new Error("Expected schemaSummary to be defined.");
        }

        let topDatabasesView: React.ReactNode = LONG_EM_DASH;
        if (schemaSummary.topDiskDatabases.length > 0) {
            topDatabasesView = (
                <div className="top-databases-view">
                    <HorizontalBarChart
                        entries={_.map(schemaSummary.topDiskDatabases, db => ({
                            name: <div className="db-name">{db.name}</div>,
                            value: db.diskUsage,
                            formatter: NumberFormatter.formatBytes,
                            icon: "database",
                            routeInfo: {
                                name: "cluster.databases.tables",
                                params: {
                                    databaseName: db.name,
                                },
                            },
                        }))}
                        color={COLORS["color-indigo-600"]}
                    />
                </div>
            );
        }

        return (
            <div className="database-usage-card-content">
                <FakeClick name="usage-top-disk-by-database">
                    <SectionHeader>Top Databases by Disk Usage</SectionHeader>

                    {topDatabasesView}
                </FakeClick>
            </div>
        );
    };

    render() {
        const {
            loading,
            schemaError,
            schemaSummary,
            route: {
                params: { clusterId },
            },
            className,
        } = this.props;

        let content;

        // If there is at least 1 byte of either disk or memory usage
        // in the cluster, then we render the card's normal content.
        if (
            !schemaError &&
            !loading &&
            (schemaSummary &&
                (schemaSummary.memoryUsage.gt(0) ||
                    schemaSummary.diskUsage.gt(0)))
        ) {
            content = this.renderContent();
        }

        return (
            <DashboardCard
                loading={loading}
                content={content}
                pageRouteInfo={{
                    name: "cluster.databases",
                    params: { clusterId },
                }}
                icon={SvgSchema}
                emptyHelpLink={{
                    name: "create-database",
                    category: "dashboard",
                }}
                error={schemaError}
                hasEmptyState
                emptyTitle="No data yet"
                emptyDescription="There is no data in your cluster."
                title="Database Usage"
                docsRouteInfo={{
                    name: "memory-management",
                    category: "dashboard",
                }}
                className={className}
            />
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        loading: selectSchemaSummaryLoading(s),
        schemaSummary: selectPayload(s.schema.summary),
        schemaError: selectSchemaSummaryError(s),
        route: selectRoute(s),
    })
)(DatabaseUsageCard);
