import { AbsoluteTableName } from "data/models";

import qualifiedName from "memsql/qualified-name";

export default function({ databaseName, tableName, kind }: AbsoluteTableName) {
    let showCreateCommand;
    if (
        (kind === "VIEW" && databaseName === "information_schema") ||
        kind === "TABLE"
    ) {
        // The engine considiers MemSQL system views to be tables for some
        // things but views for some others; it happens that for running
        // SHOW CREATE to see column types, system views are like tables.
        showCreateCommand = "SHOW CREATE TABLE";
    } else {
        showCreateCommand = "SHOW CREATE VIEW";
    }

    return `${showCreateCommand} ${qualifiedName({
        databaseName,
        name: tableName,
    })} \\G`;
}
