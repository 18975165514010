import { DispatchFunction, State } from "data";
import { CursorInfo } from "data/models";
import { ActiveQueryRange } from "data/selectors/page-editor";
import { Maybe } from "util/maybe";

import * as React from "react";
import { connect } from "react-redux";

import SQLEditor from "view/common/sql-editor";

import { editBuffer, setCursorInfo } from "data/actions";
import { runQuery } from "data/actions/query-editor";
import { selectActiveQueryRange } from "data/selectors/page-editor";

type StateProps = {
    buffer: string;
    activeQueryRange: Maybe<ActiveQueryRange>;
    focusCount: number;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

class EditorBufferContainer extends React.Component<Props> {
    handleBufferChange = (buffer: string) => {
        this.props.dispatch(editBuffer({ buffer }));
    };

    handleCursorInfoChange = (info: CursorInfo) => {
        this.props.dispatch(setCursorInfo(info));
    };

    handleRunQuery = () => {
        this.props.dispatch(runQuery());
    };

    handleRunQueryInNewTab = () => {
        this.props.dispatch(runQuery({ newTab: true }));
    };

    render() {
        const { buffer, focusCount, activeQueryRange } = this.props;

        const highlightRange = activeQueryRange
            ? activeQueryRange.range
            : undefined;

        return (
            <SQLEditor
                autoFocus
                value={buffer}
                focusCount={focusCount}
                onRunQuery={this.handleRunQuery}
                onRunQueryInNewTab={this.handleRunQueryInNewTab}
                onChange={this.handleBufferChange}
                onCursorChange={this.handleCursorInfoChange}
                highlightRange={highlightRange}
            />
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        buffer: s.queryEditor.buffer,
        activeQueryRange: selectActiveQueryRange(s),
        focusCount: s.queryEditor.focus,
    })
)(EditorBufferContainer);
