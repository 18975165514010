import { Maybe } from "util/maybe";
import { SortDirection, TableSort } from "util/sort";
import { ColumnId } from "view/components/super-table";
import { DerivedHost, Node, HostRatioMetric } from "data/models";

import { State as ReduxState, DispatchFunction } from "data";
import { InjectedRoute } from "react-router5";

import * as React from "react";
import { connect } from "react-redux";
import { withRoute } from "react-router5";

import Card from "view/components/card";
import {
    TopContent,
    TopContentRow,
    TopContentHeader,
} from "view/components/top-content";
import GeneralTable from "view/components/general-table";
import { TableHeader } from "view/components/table-header";
import { RatioMetricCell } from "view/common/ratio-metric-cell";
import { renderLoadingError } from "view/components/render-loading-error";
import IconTip from "view/components/icon-tip";

import { sortHostNodes } from "data/actions";
import {
    selectCurrentHost,
    selectSortedHostNodes,
    selectHostNodesSort,
    selectIsPhysicalMonitoringEnabled,
} from "data/selectors/hosts";
import {
    formatHostMemory,
    getHostMemoryUsage,
    getHostDiskUsage,
} from "data/models";

import { getHostPageColumns } from "view/topology/columns-info";

import { nextTableSort } from "util/sort";
import { logError } from "util/logging";
import { LONG_EM_DASH } from "util/symbols";
import NumberFormatter from "util/number-formatter";
import {
    nodesLiveMonitoringStart,
    nodesLiveMonitoringStop,
} from "worker/api/topology";
import { HOST_DISK_USAGE_TIP } from "view/hosts/columns-info";

import "./host.scss";

type StateProps = {
    host: Maybe<DerivedHost>;
    sort: TableSort;
    nodes: Maybe<Array<Node>>;
    physicalMonitoringEnabled: Maybe<boolean>;
};

type Props = StateProps &
    InjectedRoute & {
        dispatch: DispatchFunction;
    };

type State = {
    // Whether this component has called `nodesLiveMonitoringStart` or not.
    // (Note that this call may fail and this variable will still be `true`)
    startedLiveMonitoring: boolean;
};

class HostPage extends React.Component<Props> {
    state: State = {
        startedLiveMonitoring: false,
    };

    componentDidUpdate() {
        // If Physical Monitoring is enabled in this cluster, we start live
        // monitoring of physical monitoring data for all its nodes. We only do
        // this once per render of HostPage.
        if (
            this.props.physicalMonitoringEnabled &&
            !this.state.startedLiveMonitoring &&
            this.props.host
        ) {
            const { host } = this.props;

            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(
                {
                    startedLiveMonitoring: true,
                },
                () => {
                    this.props.dispatch(
                        nodesLiveMonitoringStart({
                            ipAddress: host.address,
                        })
                    );
                }
            );
        }
    }

    componentWillUnmount() {
        if (this.state.startedLiveMonitoring) {
            this.props.dispatch(nodesLiveMonitoringStop());
        }
    }

    handleSort = (columnId: ColumnId, sortDir: Maybe<SortDirection>) => {
        this.props.dispatch(sortHostNodes(nextTableSort(columnId, sortDir)));
    };

    renderHostDiskUsage = ({ used, total, ratio }: HostRatioMetric) => {
        const bottomLabel = `${NumberFormatter.formatBytes(
            used
        )}/${NumberFormatter.formatBytes(total)}`;

        return (
            <RatioMetricCell
                barClassName="inline-cell-bar"
                labelPosition="right"
                value={ratio}
                bottomLabel={bottomLabel}
            />
        );
    };

    renderHostCpuUsage = (cpuUsage: number) => {
        return (
            <RatioMetricCell
                barClassName="inline-cell-bar"
                labelPosition="right"
                value={cpuUsage}
            />
        );
    };

    renderHostMemoryUsage = ({ ratio, used, total }: HostRatioMetric) => {
        const bottomLabel = `${NumberFormatter.formatBytes(
            used
        )}/${NumberFormatter.formatBytes(total)}`;

        return (
            <RatioMetricCell
                barClassName="inline-cell-bar"
                labelPosition="right"
                value={ratio}
                bottomLabel={bottomLabel}
            />
        );
    };

    renderPhysicalMetrics = (host: DerivedHost) => {
        const { physicalMonitoringEnabled } = this.props;

        if (physicalMonitoringEnabled) {
            return (
                <>
                    <TopContent title="Host CPU Usage" className="top-card">
                        {renderLoadingError(
                            host.liveMonitoring.totalCpuUsagePercentRate,
                            this.renderHostCpuUsage,
                            { cell: true }
                        )}
                    </TopContent>

                    <TopContent title="Host Memory Usage" className="top-card">
                        {renderLoadingError(
                            getHostMemoryUsage(host),
                            this.renderHostMemoryUsage,
                            { cell: true }
                        )}
                    </TopContent>

                    <TopContent
                        title={
                            <>
                                MemSQL Disk Usage
                                <IconTip
                                    iconProps={{
                                        leftMargin: true,
                                    }}
                                >
                                    {HOST_DISK_USAGE_TIP}
                                </IconTip>
                            </>
                        }
                        className="top-card"
                    >
                        {renderLoadingError(
                            getHostDiskUsage(host),
                            this.renderHostDiskUsage,
                            { cell: true }
                        )}
                    </TopContent>

                    <TopContent title="MemSQL Disk I/O" className="top-card">
                        <div className="disk-io">
                            <div className="disk-io-entry">
                                <div>Read</div>
                                <div>
                                    {renderLoadingError(
                                        host.liveMonitoring.diskReadRateBpS,
                                        val =>
                                            `${NumberFormatter.formatBytes(
                                                val
                                            )} / s`
                                    )}
                                </div>
                            </div>

                            <div className="disk-io-entry">
                                <div>Write</div>
                                <div>
                                    {renderLoadingError(
                                        host.liveMonitoring.diskWriteRateBpS,
                                        val =>
                                            `${NumberFormatter.formatBytes(
                                                val
                                            )} / s`
                                    )}
                                </div>
                            </div>
                        </div>
                    </TopContent>

                    <TopContent title="Network I/O" className="top-card">
                        <div className="network-io">
                            <div className="network-io-entry">
                                <div>Received</div>
                                <div>
                                    {renderLoadingError(
                                        host.liveMonitoring.netReceivedRateBpS,
                                        val =>
                                            `${NumberFormatter.formatBytes(
                                                val
                                            )} / s`
                                    )}
                                </div>
                            </div>

                            <div className="network-io-entry">
                                <div>Transmitted</div>
                                <div>
                                    {renderLoadingError(
                                        host.liveMonitoring
                                            .netTransmittedRateBpS,
                                        val =>
                                            `${NumberFormatter.formatBytes(
                                                val
                                            )} / s`
                                    )}
                                </div>
                            </div>
                        </div>
                    </TopContent>
                </>
            );
        } else {
            return (
                <TopContent title="Host Memory" className="top-card">
                    {formatHostMemory(host)}
                </TopContent>
            );
        }
    };

    render() {
        const { nodes, sort, host, physicalMonitoringEnabled } = this.props;

        if (!host || !nodes) {
            logError(new Error("HostPage was rendered without valid data."));

            return null;
        }

        if (physicalMonitoringEnabled === undefined) {
            return null;
        }

        return (
            <div className="host-page">
                <Card className="host-info-card">
                    <TopContentHeader>Host Info</TopContentHeader>

                    <TopContentRow>
                        <TopContent title="Host Address" className="top-card">
                            {host.address}
                        </TopContent>

                        <TopContent title="CPU Cores" className="top-card">
                            {host.cpuCount
                                ? host.cpuCount.toString()
                                : LONG_EM_DASH}
                        </TopContent>

                        {this.renderPhysicalMetrics(host)}
                    </TopContentRow>
                </Card>

                <TableHeader title="Nodes" count={nodes.length} />

                <GeneralTable
                    rows={nodes}
                    onSort={this.handleSort}
                    columns={getHostPageColumns(physicalMonitoringEnabled)}
                    sort={sort}
                    rowHeight={physicalMonitoringEnabled ? 70 : undefined}
                    verticallyAlignCells
                />
            </div>
        );
    }
}

export default connect(
    (s: ReduxState): StateProps => ({
        host: selectCurrentHost(s),
        sort: selectHostNodesSort(s),
        nodes: selectSortedHostNodes(s),
        physicalMonitoringEnabled: selectIsPhysicalMonitoringEnabled(s),
    })
)(withRoute(HostPage));
