import { Maybe } from "util/maybe";
import { State, DispatchFunction } from "data";

import * as React from "react";
import { connect } from "react-redux";

import FileLoader from "view/common/file-loader";
import DropdownButton from "view/components/dropdown-button";
import { Menu, MenuItem } from "view/common/menu";

import { parseExplainString } from "worker/api/explain";

import { selectExplainRawJSON } from "data/selectors/explain";

import saveData from "util/save-data";
import * as analytics from "util/segment";

type StateProps = {
    rawJSON: Maybe<string>;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

class ExplainMenu extends React.Component<Props> {
    $fileLoader: Maybe<FileLoader>;

    handleLoadFile = (rawExplain: string, filename: string) => {
        this.props.dispatch(
            parseExplainString({
                rawExplain,
                source: filename,
                sourceType: "FILE",
            })
        );
    };

    onClickImportData = () => {
        if (this.$fileLoader) {
            this.$fileLoader.show();
        }
    };

    onClickExportData = () => {
        if (this.props.rawJSON) {
            saveData({ data: this.props.rawJSON, baseName: "plan" });

            analytics.track("export-visual-explain-json");
        }
    };

    render() {
        const menuItems = [
            <MenuItem key="import-data" action={this.onClickImportData}>
                Import Explain/Profile
            </MenuItem>,
            <MenuItem
                key="export-data"
                action={this.onClickExportData}
                disabled={!this.props.rawJSON}
            >
                Export
            </MenuItem>,
        ];

        return (
            <>
                <FileLoader
                    ref={$fileLoader => {
                        this.$fileLoader = $fileLoader || undefined;
                    }}
                    onLoadFile={this.handleLoadFile}
                />
                <DropdownButton
                    ghost
                    icon="ellipsis-v"
                    direction="sw"
                    children={<Menu>{menuItems}</Menu>}
                />
            </>
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        rawJSON: selectExplainRawJSON(s),
    })
)(ExplainMenu);
