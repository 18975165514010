import { Maybe } from "util/maybe";

import { DispatchFunction } from "data";
import { State as ReduxState } from "data/reducers";

import * as React from "react";
import { connect } from "react-redux";

import FileLoader from "view/common/file-loader";
import { Button } from "view/common/button";
import Modal from "view/components/modal";
import SimpleModalBody from "view/components/simple-modal-body";
import DropdownButton from "view/components/dropdown-button";
import { Menu, MenuItem } from "view/common/menu";

import { editBuffer } from "data/actions/query-editor";

import saveData from "util/save-data";
import * as analytics from "util/segment";

import "./load-sql.scss";

type StateProps = {
    buffer: string;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

type State = {
    confirmBuffer: Maybe<string>;
};

class EditorMenu extends React.Component<Props, State> {
    $fileLoader: Maybe<FileLoader>;

    constructor(props: Props) {
        super(props);

        this.state = { confirmBuffer: undefined };
    }

    handleRequestLoadFile = (result: string) => {
        const { buffer } = this.props;

        if (buffer) {
            this.setState({
                confirmBuffer: result,
            });
        } else {
            this.handleLoadFile(result);
        }
    };

    onClickLoadSQL = () => {
        if (this.$fileLoader) {
            this.$fileLoader.show();
        }
    };

    onClickSaveSQL = () => {
        const { buffer } = this.props;

        saveData({ data: buffer, baseName: "sql", extension: "sql" });

        analytics.track("export-query-editor-buffer-sql");
    };

    handleCancel = () => {
        this.setState({ confirmBuffer: undefined });
    };

    handleLoadFile = (buffer: string) => {
        const { dispatch } = this.props;

        dispatch(editBuffer({ buffer }));

        analytics.track("import-sql", { category: "query-editor" });
    };

    handleOverwrite = () => {
        const { confirmBuffer } = this.state;

        if (confirmBuffer !== undefined) {
            this.handleLoadFile(confirmBuffer);
            this.setState({ confirmBuffer: undefined });
        }
    };

    renderModal() {
        const { confirmBuffer } = this.state;

        const actions = (
            <>
                <Button onClick={this.handleCancel} large>
                    Cancel
                </Button>
                <Button onClick={this.handleOverwrite} large primary>
                    Overwrite
                </Button>
            </>
        );

        return (
            <Modal
                active={confirmBuffer !== undefined}
                innerContentPadding
                onClose={this.handleCancel}
                actions={actions}
            >
                <SimpleModalBody
                    title="Overwrite current query?"
                    description="There is already content in the SQL editor."
                />
            </Modal>
        );
    }

    render() {
        const { buffer } = this.props;

        return (
            <>
                <FileLoader
                    onLoadFile={this.handleRequestLoadFile}
                    ref={$fileLoader => {
                        this.$fileLoader = $fileLoader || undefined;
                    }}
                />
                <DropdownButton
                    ghost
                    icon="ellipsis-v"
                    direction="sw"
                    className="load-save-btn"
                    children={
                        <Menu>
                            <MenuItem action={this.onClickLoadSQL}>
                                Load SQL File
                            </MenuItem>

                            <MenuItem
                                action={this.onClickSaveSQL}
                                disabled={!buffer}
                            >
                                Save SQL File
                            </MenuItem>
                        </Menu>
                    }
                />

                {this.renderModal()}
            </>
        );
    }
}

export default connect(
    (s: ReduxState): StateProps => ({
        buffer: s.queryEditor.buffer,
    })
)(EditorMenu);
