import { Maybe } from "util/maybe";

const iterRowCol = (
    buffer: string,
    fn: (i: number, row: number, col: number) => Maybe<boolean>
) => {
    let row = 0;
    let col = 0;
    let i = 0;
    for (let l = buffer.length; i < l; ++i) {
        if (fn(i, row, col)) {
            return;
        }
        if (buffer[i] === "\n") {
            row++;
            col = 0;
        } else {
            col++;
        }
    }
    // this is only called if we don't return from the inside loop
    fn(i, row, col);
};

export class RowColumnRange {
    startRow: number;
    startColumn: number;
    endRow: number;
    endColumn: number;

    constructor(
        startRow: number,
        startColumn: number,
        endRow: number,
        endColumn: number
    ) {
        if (startRow > endRow) {
            throw new Error("RowColumnRange: startRow should be <= endRow");
        }

        if (startRow === endRow) {
            if (startColumn > endColumn) {
                throw new Error(
                    "RowColumnRange: (single row) startColumn should be <= endColumn"
                );
            }
        }

        this.startRow = startRow;
        this.startColumn = startColumn;
        this.endRow = endRow;
        this.endColumn = endColumn;
    }

    toAbsolute(buffer: string): AbsoluteRange {
        const out = new AbsoluteRange(0, 0);
        let lastIdx = 0;
        let setStart = false,
            setEnd = false;

        iterRowCol(buffer, (i, row, col) => {
            lastIdx = i;

            // we are exactly at the start position
            if (row === this.startRow && col === this.startColumn) {
                out.start = i;
                setStart = true;
            }
            // we are exactly at the end position
            if (row === this.endRow && col === this.endColumn) {
                out.end = i;
                setEnd = true;
                return true;
            }
        });

        if (!setStart) {
            out.start = lastIdx;
        }
        if (!setEnd) {
            out.end = lastIdx;
        }

        return out;
    }

    extract(buffer: string): string {
        return this.toAbsolute(buffer).extract(buffer);
    }
}

export class AbsoluteRange {
    start: number;
    end: number;

    constructor(start: number, end: number) {
        if (start < 0) {
            throw new Error("AbsoluteRange: start should be >= 0");
        }

        if (start > end) {
            throw new Error("AbsoluteRange: start should be <= end");
        }

        this.start = start;
        this.end = end;
    }

    compare(range: AbsoluteRange) {
        return range.start === this.start && range.end === this.end;
    }

    toRowColumn(buffer: string): RowColumnRange {
        const out = new RowColumnRange(-1, -1, -1, -1);
        let lastRow = 0,
            lastCol = 0;
        let setStart = false,
            setEnd = false;

        iterRowCol(buffer, (i, row, col) => {
            lastRow = row;
            lastCol = col;
            if (i === this.start) {
                setStart = true;
                out.startRow = row;
                out.startColumn = col;
            }
            if (i === this.end) {
                setEnd = true;
                out.endRow = row;
                out.endColumn = col;
                return true;
            }
        });

        if (!setStart) {
            out.startRow = lastRow;
            out.startColumn = lastCol;
        }
        if (!setEnd) {
            out.endRow = lastRow;
            out.endColumn = lastCol;
        }

        return out;
    }

    extract(buffer: string): string {
        return buffer.slice(this.start, this.end);
    }
}
