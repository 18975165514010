import { MysqlError } from "mysqljs";
import { Maybe } from "util/maybe";
import { HandlerContext } from "worker/api";
import { LicenseInfoAction } from "data/actions";
import { LicenseInfo, LicenseType, LicenseCapacity } from "data/models";

import { makeActionCreator } from "worker/api/helpers";

import _ from "lodash";
import { Observable } from "rxjs";

import { select } from "util/query";
import { logError } from "util/logging";

const MEGABYTE_TO_BYTES = 1024 * 1024; // 1 mebibyte in bytes
const SECOND_TO_MS = 1000; // 1 second in milliseconds

const SQL_STATUS_QUERY = `
    SHOW GLOBAL STATUS
`;

type SQLGlobalStatusRow = {
    Variable_name: string;
    Value: string;
};

const getLicenseType = (type: string): LicenseType => {
    switch (type) {
        case "developer":
        case "enterprise":
        case "free":
            return type;

        default:
            return "unknown";
    }
};

type SQLLicense = {
    Maximum_cluster_capacity?: string;
    Used_cluster_capacity?: string;
    License_expiration?: string;
    License_key?: string;
    License_type?: string;
    License_version?: string;
    License?: string;
};

// We export this so we can test it.
export const getLicenseInfo = (
    sqlGlobalStatus: Array<SQLGlobalStatusRow>
): Maybe<LicenseInfo> => {
    const sqlLicense = _(sqlGlobalStatus)
        .filter(
            (statusRow: SQLGlobalStatusRow) =>
                statusRow.Variable_name === "Maximum_cluster_capacity" ||
                statusRow.Variable_name === "Used_cluster_capacity" ||
                statusRow.Variable_name === "License_expiration" ||
                statusRow.Variable_name === "License_key" ||
                statusRow.Variable_name === "License_type" ||
                statusRow.Variable_name === "License_version" ||
                statusRow.Variable_name === "License"
        )
        .keyBy("Variable_name")
        .mapValues("Value")
        .value() as SQLLicense;

    const {
        Maximum_cluster_capacity,
        Used_cluster_capacity,
        License_expiration,
        License_key: key,
        License_type: licenseType,
        License_version,
        License: license,
    } = sqlLicense;

    if (
        Maximum_cluster_capacity === undefined ||
        Used_cluster_capacity === undefined ||
        License_expiration === undefined ||
        licenseType === undefined ||
        key === undefined ||
        License_version === undefined
    ) {
        return undefined;
    }

    const rawMaxCapacity = Number(Maximum_cluster_capacity.split(" ")[0]); // megabytes
    const rawUsedCapacity = Number(Used_cluster_capacity.split(" ")[0]); // megabytes

    const version = Number(License_version); // 3, 4, …
    const expiration = Number(License_expiration);

    const unitPricing = _.includes(Used_cluster_capacity, "unit");

    let maximumCapacity: LicenseCapacity;
    if (rawMaxCapacity > Number.MAX_SAFE_INTEGER) {
        maximumCapacity = "unlimited";
    } else {
        if (unitPricing) {
            maximumCapacity = rawMaxCapacity;
        } else {
            maximumCapacity = rawMaxCapacity * MEGABYTE_TO_BYTES;
        }
    }

    let usedCapacity: number;
    if (unitPricing) {
        usedCapacity = rawUsedCapacity;
    } else {
        usedCapacity = rawUsedCapacity * MEGABYTE_TO_BYTES;
    }

    return {
        maximumCapacity,
        usedCapacity,
        unitPricing,
        expiration:
            expiration === 0
                ? "unlimited"
                : new Date(expiration * SECOND_TO_MS),
        key,
        license,
        type: getLicenseType(licenseType),
        version,
    };
};

const createLicenseAction = (
    sqlGlobalStatus: Array<SQLGlobalStatusRow>
): LicenseInfoAction => {
    return {
        type: "LICENSE_INFO",
        payload: {
            loading: false,
            data: getLicenseInfo(sqlGlobalStatus),
        },
        error: false,
    };
};

export const queryLicense = makeActionCreator({
    name: "queryLicense",

    handle: (ctx: HandlerContext): Observable<LicenseInfoAction> => {
        const $loading = Observable.of<LicenseInfoAction>({
            type: "LICENSE_INFO",
            error: false,
            payload: { loading: true },
        });

        const $compute = Observable.fromPromise(
            ctx.manager
                .getPooledConnection()
                .then(conn =>
                    select(conn, SQL_STATUS_QUERY)
                        .then(createLicenseAction)
                        .finally(() => conn.release())
                )
                .catch(
                    (err: Error | MysqlError): LicenseInfoAction => {
                        logError(err);

                        return {
                            type: "LICENSE_INFO",
                            error: true,
                            payload: {
                                message: err.message,
                            },
                        };
                    }
                )
        );

        return Observable.merge($loading, $compute);
    },
});
