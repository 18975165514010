import { TableSort } from "util/sort";
import {
    PipelinesAction,
    SortPipelinesAction,
    PipelinesPayload,
} from "data/actions";
import { LSM } from "util/loading-state-machine";

import {
    getInitialState,
    reduce as reduceLoadingStateMachine,
} from "util/loading-state-machine";

type Actions = PipelinesAction | SortPipelinesAction;

export type PipelinesState = {
    pipelines: LSM<PipelinesPayload, { message: string }>;
    sort: TableSort;
};

const initialState: PipelinesState = {
    pipelines: getInitialState(),

    // default order for pipelines table
    sort: {
        columnId: "pipelineState",
        direction: "desc",
    },
};

export default (state: PipelinesState = initialState, action: Actions) => {
    switch (action.type) {
        case "PIPELINES":
            {
                state = {
                    ...state,
                    pipelines: reduceLoadingStateMachine(
                        state.pipelines,
                        action
                    ),
                };
            }

            break;

        case "SORT_PIPELINES": {
            state = {
                ...state,
                sort: action.payload,
            };

            break;
        }
    }

    return state;
};
