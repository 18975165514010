export const SQL_CREATE_STOCK_TABLES = `CREATE DATABASE IF NOT EXISTS trades;
USE trades;

DROP TABLE IF EXISTS trade;
CREATE TABLE trade(
    id BIGINT NOT NULL, 
    stock_symbol CHAR(5) NOT NULL,
    shares DECIMAL(18,4) NOT NULL,
    share_price DECIMAL(18,4) NOT NULL,
    trade_time DATETIME(6) NOT NULL,
    KEY(stock_symbol) USING CLUSTERED COLUMNSTORE,
    SHARD KEY(stock_symbol)
);

DROP PIPELINE IF EXISTS companylist;
DROP TABLE IF EXISTS company;
CREATE TABLE company(
    symbol CHAR(5) NOT NULL,
    name VARCHAR(500),
    last_sale VARCHAR(10),
    market_cap VARCHAR(15),
    IPO_year FLOAT,
    sector VARCHAR(80),
    industry VARCHAR(80),
    summary_quote VARCHAR(50),
    extra VARCHAR(50)
);`;

export const SQL_LOAD_STOCK_DATA = `CREATE or REPLACE PIPELINE companylist
AS LOAD DATA S3 'download.memsql.com/first-time/'
CONFIG '{"region": "us-east-1"}'
INTO TABLE \`company\`
FIELDS TERMINATED BY ',' ENCLOSED BY '"';
START PIPELINE companylist FOREGROUND;`;

export const SQL_MARKETCAP_DATA_GEN = `DELIMITER //
CREATE OR REPLACE FUNCTION marketcap_to_DECIMAL(s VARCHAR(15)) 
    RETURNS DECIMAL(18,2) AS
DECLARE 
    m CHAR(1) = SUBSTR(s, LENGTH(s), 1); -- M or B
    raw_v DECIMAL(18,2) = SUBSTR(s, 2, LENGTH(s) - 1);
    v DECIMAL(18,2) = NULL;
BEGIN
    IF m = "B" THEN 
        v = raw_v * 1000;
    ELSE
        v = raw_v;
    END IF;
    RETURN v;
END //
DELIMITER ;`;

export const SQL_SEED_TRADES_PROCEDURE = `DELIMITER //
CREATE OR REPLACE PROCEDURE seed_trades(num_trades INT) RETURNS INT AS
DECLARE
    ranked_companies ARRAY(RECORD(symbol CHAR(5), _rank INT)); 
DECLARE
    q QUERY(symbol CHAR(5), _rank INT) =
        SELECT symbol, rank() OVER (ORDER BY marketcap_to_DECIMAL(market_cap)) AS _rank
        FROM company
        WHERE LENGTH(symbol) < 5
        ORDER BY _rank DESC LIMIT 200;
    i INT = 0;
    rank_num INT;
    next_id INT = 1;
    sym CHAR(5);
    price_base DECIMAL(18,4);
    current_prices ARRAY(INT);
    l ARRAY(RECORD(symbol CHAR(5), _rank INT));
BEGIN
    l = collect(q);
    FOR r IN l LOOP
        i += 1;
        rank_num = r._rank;
        sym = r.symbol;
        price_base = FLOOR(rand() * 50) + 50;
        FOR j IN 1..((rank_num / 10) + RAND() * 10) LOOP
            INSERT trade VALUES(
                next_id, 
                sym, 
                FLOOR(1 + RAND() * 10) * 100, -- shares
                price_base,      -- share_price
                DATE_ADD(NOW(), INTERVAL RAND() * 6 HOUR)); -- random time during trading day, roughly
        next_id += 1;
        IF next_id > num_trades THEN RETURN(next_id); END IF;
        END LOOP;
    END LOOP;
    RETURN(next_id);
END //
DELIMITER ;`;

export const SQL_ITER_STOCKS_PROCEDURE = `DELIMITER //
CREATE OR REPLACE PROCEDURE iter_stocks(iterations INT) as
DECLARE
    tickers ARRAY(CHAR(5));
    prices ARRAY(DECIMAL(18,4));
    last_ids ARRAY(bigINT);
    counts ARRAY(INT);
    next_id bigINT = 1;
    ticker CHAR(5);
    price DECIMAL(18,4);
    c INT;
    rand DECIMAL(18,4);
    tickers_q QUERY(t CHAR(5), p DECIMAL(18,4), lid BIGINT, c INT) = SELECT stock_symbol, share_price, MIN(id), COUNT(*) FROM trade GROUP BY stock_symbol;
    q ARRAY(RECORD(t CHAR(5), p DECIMAL(18,4), lid bigINT, c INT));
    q_count QUERY(c INT) = SELECT COUNT(*) FROM trade;
    total_c INT;
BEGIN
    q = COLLECT(tickers_q);
    tickers = CREATE_ARRAY(LENGTH(q));
    prices = CREATE_ARRAY(LENGTH(q));
    last_ids = CREATE_ARRAY(LENGTH(q));
    counts = CREATE_ARRAY(LENGTH(q));
    total_c = SCALAR(q_count);
    FOR r IN 0..LENGTH(q)-1 LOOP
        tickers[r] = q[r].t;
        prices[r] = q[r].p;
        last_ids[r] = q[r].lid;
        counts[r] = q[r].c;
    END LOOP;
    FOR j IN 0..(iterations-1) LOOP
        FOR i IN 0..LENGTH(tickers)-1 LOOP
            ticker = tickers[i];
            price = prices[i];
            next_id = last_ids[i];
            c = counts[i];
            rand = POW(-1, FLOOR(RAND()*2)) * RAND();
            INSERT INTO trade 
            SELECT id + total_c, stock_symbol, shares, share_price + rand, trade_time FROM trade WHERE stock_symbol = ticker AND id >= next_id;
            prices[i] = price + rand;
            last_ids[i] = next_id + total_c;
        END LOOP;
    END LOOP;
END //
DELIMITER ;`;

export const SQL_CALL_SEED_TRADES_PROCEDURE = `CALL seed_trades(100000);`;

export const SQL_CALL_ITER_STOCK_PROCEDURE = `CALL iter_stocks(100);`;

export const SQL_MOST_TRADED_STOCKS = `SELECT stock_symbol, COUNT(*) AS c 
    FROM trade 
    GROUP BY stock_symbol 
    ORDER BY c DESC limit 10;`;

export const SQL_VOLATILE_STOCKS = `SELECT stock_symbol, VARIANCE(share_price) var 
    FROM trade 
    GROUP BY stock_symbol 
    ORDER BY var DESC;`;

export const SQL_VOLATILE_STOCKS_TIMED = `SELECT stock_symbol, VARIANCE(share_price) var 
    FROM trade 
    WHERE trade_time * 1 > ( NOW() - 5 ) 
    GROUP BY stock_symbol 
    ORDER BY var DESC;`;

export const SQL_PORTFOLIO_AGG = `WITH folio AS (
    SELECT id, stock_symbol, shares, share_price, trade_time
    FROM trade 
  ),
AggCalcs AS (
    SELECT
        stock_symbol AS ACsymb,
        MAX(share_price) AS pmax,
        MIN(share_price) AS pmin,
        STD(share_price) AS pstd,
        SUM(share_price*shares)/SUM(shares) AS avg_pps,  ## Weighted Average
        SUM(share_price*shares) AS total_pvalue
    FROM trade 
    GROUP BY 1
)
SELECT 
    DISTINCT folio.stock_symbol,
    avg_pps,
    pmin,
    pmax,
    percentile_cont(.25) WITHIN group (ORDER BY share_price) OVER (PARTITION BY stock_symbol) AS Q1,
    percentile_cont(.5) WITHIN group (ORDER BY share_price) OVER (PARTITION BY stock_symbol)  AS median,
    percentile_cont(.75) WITHIN group (ORDER BY share_price) OVER (PARTITION BY stock_symbol) AS Q3
FROM folio
JOIN AggCalcs ON (folio.stock_symbol = ACsymb)
ORDER BY folio.stock_symbol;`;
