//
// C Context type
// I Type of handler's args
// A Type of the actions being returned
//

import { Action, SuperAction } from "data/actions";
import { Observable } from "rxjs";

type PromiseOrObservable<T> = Promise<T> | Observable<T>;

export type ActionHandler<C, I, A> = (
    ctx: C,
    args?: I
) => PromiseOrObservable<A>;

export type ActionCreator<C, I, A extends SuperAction> = {
    <T extends string, P>(input?: I): Action<T, P>;
    handler: { actionType: string; handle: ActionHandler<C, I, A> };
};

const assertHandlerNameIsUnique = (() => {
    const h: { [key: string]: boolean } = {};
    return (name: string) => {
        if (h[name]) {
            throw new Error(`Worker API handler name is not unique ${name}`);
        }

        h[name] = true;
    };
})();

export const makeActionCreator = <C, I, A extends SuperAction>({
    name,
    handle,
}: {
    name: string;
    handle: ActionHandler<C, I, A>;
}): ActionCreator<C, I, A> => {
    assertHandlerNameIsUnique(name);
    const actionType = `actionHandler.${name}`;
    let actionCreator = (args: I): Action<string, unknown> => ({
        type: actionType,
        payload: args,
        error: false,
    });
    (actionCreator as ActionCreator<C, I, A>).handler = { actionType, handle };
    return actionCreator as ActionCreator<C, I, A>;
};
