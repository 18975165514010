import { Entry } from "view/components/inline-bar";

import * as React from "react";
import classnames from "classnames";

import InlineBar from "view/components/inline-bar";
import { LoadingBar } from "view/components/loading-bar";

import "./labeled-inline-bar.scss";

export type LabelPosition = "top" | "right";

type Props = {
    entries: Array<Entry> | "loading";
    label: React.ReactNode;
    // Label position relative to the inline bar
    labelPosition: LabelPosition;
    barClassName?: string;
};

export default class LabeledInlineBar extends React.Component<Props> {
    static defaultProps = {
        labelPosition: "right",
    };

    render() {
        const { entries, label, labelPosition, barClassName } = this.props;

        const classes = classnames(
            "components-labeled-inline-bar",
            labelPosition
        );

        let bar;
        if (entries === "loading") {
            bar = <LoadingBar width="100%" />;
        } else {
            bar = <InlineBar entries={entries} className={barClassName} />;
        }

        return (
            <div className={classes}>
                {bar}
                <div className="label">{label}</div>
            </div>
        );
    }
}
