import { Params } from "router/types";

import _ from "lodash";

// Since some of MemSQL identifiers can have symbols like $,!,(),{},etc...
// We use this regex when defining path parameters in router5 routes to avoid
// crashing the page when some parameter has symbols.
// `/:databaseName<.*>` means we are expecting the database name in the first
// route parameter and the content is not contrained by any rule.
const ALLOW_ALL_REGEX = ".*";

const encodeDecodeOptions = {
    encodeParams: (params: Params) => _.mapValues(params, encodeURIComponent),
    decodeParams: (params: Params) => _.mapValues(params, decodeURIComponent),
};

const connectRoute = {
    name: "connect",
    path: "/connect",
    children: [
        { name: "new", path: "/new" },
        { name: "cluster", path: "/:clusterId?:redirect" },
    ],
};

const clusterRoute = {
    name: "cluster",
    path: "/cluster/:clusterId",
    children: [
        { name: "dashboard", path: "/dashboard" },
        {
            name: "databases",
            path: "/databases?:advanced",
            children: [
                {
                    name: "tables",
                    path: `/:databaseName<${ALLOW_ALL_REGEX}>/tables`,
                    ...encodeDecodeOptions,
                },
                {
                    name: "views",
                    path: `/:databaseName<${ALLOW_ALL_REGEX}>/views`,
                    ...encodeDecodeOptions,
                },
                {
                    name: "procedures",
                    path: `/:databaseName<${ALLOW_ALL_REGEX}>/procedures`,
                    ...encodeDecodeOptions,
                },
                {
                    name: "functions",
                    path: `/:databaseName<${ALLOW_ALL_REGEX}>/functions`,
                    ...encodeDecodeOptions,
                },
                {
                    name: "aggregates",
                    path: `/:databaseName<${ALLOW_ALL_REGEX}>/aggregates`,
                    ...encodeDecodeOptions,
                },
                {
                    name: "pipelines",
                    path: `/:databaseName<${ALLOW_ALL_REGEX}>/pipelines`,
                    ...encodeDecodeOptions,
                },
                {
                    name: "partitions",
                    path: `/:databaseName<${ALLOW_ALL_REGEX}>/partitions`,
                    ...encodeDecodeOptions,
                },
                {
                    name: "partition",
                    path: `/:databaseName<${ALLOW_ALL_REGEX}>/:partitionName<${ALLOW_ALL_REGEX}>/instances`,
                    ...encodeDecodeOptions,
                },

                // table routes
                {
                    name: "columns",
                    path: `/:databaseName<${ALLOW_ALL_REGEX}>/:tableName<${ALLOW_ALL_REGEX}>/columns`,
                    ...encodeDecodeOptions,
                },
                {
                    name: "indexes",
                    path: `/:databaseName<${ALLOW_ALL_REGEX}>/:tableName<${ALLOW_ALL_REGEX}>/indexes`,
                    ...encodeDecodeOptions,
                },
                {
                    name: "sample",
                    path: `/:databaseName<${ALLOW_ALL_REGEX}>/:tableName<${ALLOW_ALL_REGEX}>/sample`,
                    ...encodeDecodeOptions,
                },
            ],
        },
        {
            name: "activities",
            path: "/activities?:nodeId&:activityType&:advanced",
            children: [
                {
                    name: "nodes",
                    path: "/nodes?:activityName",
                },
            ],
        },
        { name: "active-processes", path: "/active-processes?:advanced" },
        { name: "events", path: "/events" },
        {
            name: "nodes",
            path: "/nodes",
            children: [{ name: "node", path: "/:nodeAddress" }],
        },
        { name: "pipelines", path: "/pipelines" },
        { name: "about", path: "/about" },
        { name: "editor", path: "/editor" },
        { name: "explain", path: "/explain?:advanced" },
    ],
};

// Add cluster host routes to on-premise users.
if (!window.MANAGED_SERVICE) {
    const clusterHostsRoutes = {
        name: "hosts",
        path: "/hosts",
        children: [{ name: "host", path: "/:hostId" }],
    };

    clusterRoute.children.push(clusterHostsRoutes);
}

export default [connectRoute, clusterRoute];
