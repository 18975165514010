import _ from "lodash";

type VersionTuple = [number, number, number];

const compareVals = (l: number, r: number) => (l < r ? -1 : l > r ? 1 : 0);

const compareVersions = (left: Version, right: Version) => {
    return _.reduce(
        left.version,
        (memo: number, leftElement: number, i: number) => {
            const rightElement = right.version[i];

            // once memo is non-zero, we can never change it
            return memo === 0 ? compareVals(leftElement, rightElement) : memo;
        },
        0
    );
};

// exported for testing
export const INVALID_VERSION_STRING =
    "Invalid version string, expected `major.minor.patch[-tag]`.";

const stripTagFromVersionString = (version: string) => {
    const match = version.split("-");

    return match[0];
};

export class Version {
    version: VersionTuple;

    constructor(versionTuple: VersionTuple) {
        this.version = versionTuple;
    }

    gt(cmp: Version) {
        return compareVersions(this, cmp) === 1;
    }

    ge(cmp: Version) {
        return compareVersions(this, cmp) >= 0;
    }

    eq(cmp: Version) {
        return compareVersions(this, cmp) === 0;
    }

    lt(cmp: Version) {
        return compareVersions(this, cmp) === -1;
    }

    le(cmp: Version) {
        return compareVersions(this, cmp) <= 0;
    }

    toString() {
        return this.version.join(".");
    }

    toJSON() {
        return {
            __type__: "Version",
            __val__: this.version,
        };
    }

    static fromString = (
        version: string,
        stripTag: boolean = true
    ): Version => {
        if (stripTag) {
            version = stripTagFromVersionString(version);
        }

        const split = version.split(".");

        if (split.length !== 3) {
            throw new Error(INVALID_VERSION_STRING);
        }

        const parseVersionElement = (versionElement: string) => {
            const num = Number(versionElement);

            if (isNaN(num)) {
                throw new Error(INVALID_VERSION_STRING);
            } else {
                return num;
            }
        };

        return new Version([
            parseVersionElement(split[0]),
            parseVersionElement(split[1]),
            parseVersionElement(split[2]),
        ]);
    };
}
