import { DatabaseName, AbsoluteTableName } from "data/models";

import {
    ExpandDatabaseAction,
    CollapseDatabaseAction,
    ExpandTableAction,
    CollapseTableAction,
    SchemaTreeChangeSearchAction,
} from "data/actions/schema-tree";

import _ from "lodash";
import assign from "util/assign";
import removeFromSet from "util/remove-from-set";

type Actions =
    | ExpandDatabaseAction
    | CollapseDatabaseAction
    | ExpandTableAction
    | CollapseTableAction
    | SchemaTreeChangeSearchAction;

export type SchemaTreeState = {
    expandedDatabases: Array<DatabaseName>;
    expandedTables: Array<AbsoluteTableName>;
    searchQuery: string;
};

const initialState = {
    expandedDatabases: [],
    expandedTables: [],
    searchQuery: "",
};

export default (state: SchemaTreeState = initialState, action: Actions) => {
    switch (action.type) {
        case "SCHEMA_TREE_EXPAND_DATABASE": {
            state = assign(state, {
                expandedDatabases: [
                    ...state.expandedDatabases,
                    action.payload.databaseName,
                ],
            });

            break;
        }

        case "SCHEMA_TREE_COLLAPSE_DATABASE": {
            state = assign(state, {
                expandedDatabases: removeFromSet(
                    state.expandedDatabases,
                    action.payload.databaseName
                ),
            });

            break;
        }

        case "SCHEMA_TREE_EXPAND_TABLE": {
            state = assign(state, {
                expandedTables: [...state.expandedTables, action.payload],
            });

            break;
        }

        case "SCHEMA_TREE_COLLAPSE_TABLE": {
            state = assign(state, {
                expandedTables: removeFromSet(
                    state.expandedTables,
                    action.payload
                ),
            });

            break;
        }

        case "SCHEMA_TREE_CHANGE_SEARCH": {
            state = {
                ...state,
                searchQuery: action.payload,
            };

            break;
        }
    }

    return state;
};
