import { Maybe } from "util/maybe";

import { AbsoluteRange } from "util/range";

const RE_SLASH_G = /\\G\s*$/;
const RE_LEADING_WHITESPACE = /^\s*/;
const RE_WHITESPACE_CHAR = /^\s$/;

// For now we only handle delimiter statements of the form
//  delimiter
//  [whitespace+]
//  [non-space-separated string maxlen=6]
//  [whitespace|eof]
//
//  Basically a delimiter has to
//      * be at the beginning of a line
//      * only receive a single argument
//      * be followed by either a whitespace character or EOF
const RE_DELIMITER = /^delimiter +([^\s]{1,6})(\s|$)/i;

export function isVerticalQuery(query: string): boolean {
    return query.match(RE_SLASH_G) !== null;
}

export function stripSlashG(query: string): string {
    return query.replace(RE_SLASH_G, "");
}

/**
 * consumeUntil consumes characters in the buffer until it reaches a
 * substring matching `target` or the end of the buffer.
 * `target` can be one or two chars.
 *
 * Returns a new position located at the index immediately following `target`.
 *
 * ex:
 *      buffer: this is an example
 *      target: an
 *      start:  this is an example
 *                   ^ (5)
 *      end:    this is an example
 *                        ^ (10)
 *      consumed: is an
 *
 * if ignoreEscaped is true consumeUntil will ignore escaped versions of the
 * provided target - but the target *must* be a single character.
 *
 * Exported for testing.
 */
export const consumeUntil = (
    buffer: string,
    pos: number,
    target: string,
    ignoreEscaped: boolean = false
): number => {
    const len = buffer.length;
    if (target.length === 2) {
        if (!!ignoreEscaped) {
            throw new Error(
                "can't use multi-character target with ignoreEscaped"
            );
        }
        const c1 = target[0];
        const c2 = target[1];
        while (!(buffer[pos] === c1 && buffer[pos + 1] === c2) && pos < len) {
            pos++;
        }
        // we need to increment the pointer twice so we return the
        // cursor starting *after* the second character
        pos += 2;
    } else if (ignoreEscaped) {
        while (pos < len) {
            const char = buffer[pos];
            if (char === target && buffer[pos - 1] !== "\\") {
                pos++;
                break;
            }
            pos++;
        }
    } else {
        while (buffer[pos++] !== target && pos < len) {}
    }
    return pos;
};

// Breaks a string into component SQL queries, and returns AbsoluteRanges with
// the indices at which individual queries start and end.
export function parseQueries(buffer: string): Array<AbsoluteRange> {
    const out: Array<AbsoluteRange> = [];
    const len = buffer.length;
    let pos: number = 0;
    let queryStart: Maybe<number>;
    let delimiter = ";";

    const maybeConsumeDelimiter = (): number => {
        const match = buffer.slice(pos).match(RE_DELIMITER);
        if (match) {
            delimiter = match[1] || ";";
            return pos + match[0].length;
        }

        return pos;
    };

    const maybeStartInfo = () => {
        if (queryStart === undefined) {
            queryStart = pos;
        }
    };

    const endInfo = () => {
        if (queryStart === undefined) {
            throw new Error("queryStart should be defined");
        }
        out.push(new AbsoluteRange(queryStart, Math.min(pos, len)));
        queryStart = undefined;
    };

    const consumeQuote = (quote: string): number => {
        maybeStartInfo();
        // pos + 1 to skip the quote char we are currently on
        return consumeUntil(buffer, pos + 1, quote, true);
    };

    while (pos < len) {
        // if we aren't in a query, skip forward over whitespace
        if (queryStart === undefined) {
            const match = buffer.slice(pos).match(RE_LEADING_WHITESPACE);
            if (match) {
                pos += match[0].length;
            }
        }

        const char = buffer[pos];

        // Comments
        //

        if (char === "/" && buffer[pos + 1] === "*") {
            // handle `/*...*/` style comment
            pos = consumeUntil(buffer, pos, "*/");
            continue;
        }

        if (char === `-` && buffer[pos + 1] === "-") {
            // handle `--` style comment
            pos = consumeUntil(buffer, pos, "\n");
            continue;
        }

        // Quotes
        //

        if (char === `"`) {
            pos = consumeQuote(`"`);
            continue;
        }

        if (char === `'`) {
            pos = consumeQuote(`'`);
            continue;
        }

        if (char === `\``) {
            pos = consumeQuote(`\``);
            continue;
        }

        // Statements
        //

        // parse and handle a delimiter statement
        if (
            (char === "d" || char === "D") &&
            (pos === 0 || buffer[pos - 1] === "\n")
        ) {
            const nextPos = maybeConsumeDelimiter();
            if (pos !== nextPos) {
                // delimiter was consumed
                pos = nextPos;
                continue;
            }
        }

        // Check to see if we are at the end of a statement
        if (char === delimiter[0]) {
            // fast case for when the delimiter is a single character (default)
            if (delimiter.length === 1) {
                maybeStartInfo();
                endInfo();
                pos++;
                continue;
            } else if (buffer.indexOf(delimiter, pos) === pos) {
                maybeStartInfo();
                endInfo();
                pos += delimiter.length;
                continue;
            }
        }

        // only start tracking a potential statement if we don't see a
        // whitespace character
        if (char && !char.match(RE_WHITESPACE_CHAR)) {
            maybeStartInfo();
        }

        // nothing matched - consume this character and try again
        pos++;
    }

    if (queryStart !== undefined) {
        endInfo();
    }

    return out;
}

// Breaks a string into component SQL queries, and returns the text of each
// individual query.
export function extractQueries(buffer: string): Array<string> {
    return parseQueries(buffer)
        .map(range => range.extract(buffer))
        .filter(Boolean);
}
