import { WrappedFieldProps } from "redux-form";

import * as React from "react";

import { Select } from "./select";

type FormSelectProps = WrappedFieldProps & {
    children: React.ReactNode;
};

export class FormSelect extends React.Component<FormSelectProps> {
    handleChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.input.onChange(evt.target.value);
    };

    render() {
        const { children, input, ...rest } = this.props;

        /* eslint-disable react/jsx-handler-names */
        return (
            <Select
                required
                onChange={this.handleChange}
                value={input.value}
                onBlur={input.onBlur}
                {...rest}
            >
                {children}
            </Select>
        );
    }
}
