import { DispatchFunction } from "data";

import * as React from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import { Button } from "view/common/button";
import InlineBar from "view/components/inline-bar";

import { changeTutorialStep } from "data/actions";

import { COLORS } from "util/colors";
import * as analytics from "util/segment";

import "./tutorial-stepper.scss";

type Props = {
    currentStep: number; // starts from 0, like array index
    stepCount: number; // like array length
    location: Array<string>; // for analytics
};

type StepperProps = Props & {
    dispatch: DispatchFunction;
};

class TutorialStepper extends React.Component<StepperProps> {
    handleClickNextStep = () => {
        const { dispatch, location, currentStep } = this.props;

        dispatch(changeTutorialStep({ location, step: currentStep + 1 }));

        analytics.track("next-step", { category: "tutorial" });
    };

    handleClickPreviousStep = () => {
        const { dispatch, location, currentStep } = this.props;

        dispatch(changeTutorialStep({ location, step: currentStep - 1 }));

        analytics.track("previous-step", { category: "tutorial" });
    };

    render() {
        const { currentStep, stepCount } = this.props;

        let backButton;
        let forwardButton;
        const maxStep = stepCount - 1;
        let labelClasses = classnames("label", { hidden: currentStep === 0 });

        if (currentStep > 0) {
            backButton = (
                <Button
                    className="back-button"
                    onClick={this.handleClickPreviousStep}
                >
                    Back
                </Button>
            );
        }

        if (currentStep < maxStep) {
            const forwardText = currentStep === 0 ? "Start" : "Next";

            forwardButton = (
                <Button
                    primary
                    className="next-button"
                    onClick={this.handleClickNextStep}
                >
                    {forwardText}
                </Button>
            );
        }

        const stepRatio = currentStep / maxStep;
        const progressBarEntries = [
            {
                fill: COLORS["color-indigo-600"],
                value: stepRatio,
            },
            {
                fill: COLORS["color-neutral-200"],
                value: 1 - stepRatio,
            },
        ];

        return (
            <>
                <InlineBar
                    className="tutorial-progress-bar"
                    size="small"
                    entries={progressBarEntries}
                    squaredCorners
                />
                <div className="tutorial-stepper">
                    {backButton}

                    <div className={labelClasses}>
                        Step {currentStep} of {maxStep}
                    </div>

                    {forwardButton}
                </div>
            </>
        );
    }
}

export default connect()(TutorialStepper);
