import { Maybe } from "util/maybe";

import { State } from "data";
import { LicenseInfo, LicenseCapacity } from "data/models";

import {
    selectLoadingOrInitial,
    selectLastUpdate,
    selectError,
    selectPayload,
} from "util/loading-state-machine";

export const selectLicenseLoading = (s: State): boolean =>
    selectLoadingOrInitial(s.license.licenseInfo);

export const selectLicenseLastUpdate = (s: State): Maybe<Date> =>
    selectLastUpdate(s.license.licenseInfo);

export const selectLicenseError = (s: State): Maybe<string> => {
    const error = selectError(s.license.licenseInfo);

    if (error) {
        return error.message;
    }
};

export const selectLicenseInfo = (s: State): Maybe<LicenseInfo> =>
    selectPayload(s.license.licenseInfo);

export const selectLicenseUsage = (s: State): Maybe<LicenseCapacity> => {
    const licenseInfo = selectPayload(s.license.licenseInfo);

    if (licenseInfo) {
        const { usedCapacity, maximumCapacity } = licenseInfo;

        if (maximumCapacity === "unlimited" || maximumCapacity === 0) {
            return "unlimited";
        }

        return usedCapacity / maximumCapacity;
    }
};
