import { Maybe } from "util/maybe";
import { LSM } from "util/loading-state-machine";

import {
    ExplainLayoutPayload,
    ExplainLayoutAction,
    ZoomAction,
    SelectExplainNodeAction,
    ExplainViewportOffsetAction,
    ExplainTab,
    ChangeExplainTabAction,
    ExpandSectionAction,
    CollapseSectionAction,
} from "data/actions";

import _ from "lodash";

import { ZoomLevel } from "data/explain/layout";
import { Vector } from "util/vector";

import {
    getInitialState,
    reduce as reduceLoadingStateMachine,
} from "util/loading-state-machine";
import removeFromSet from "util/remove-from-set";

export type ExplainSectionName =
    | "Summary"
    | "Cluster Info"
    | "Details"
    | "Node Details"
    | "Metrics"
    | "Warnings"
    | "Projections";

const DEFAULT_ZOOM_LEVEL: ZoomLevel = "large";

const DEFAULT_EXPANDED_SECTIONS: Array<ExplainSectionName> = [
    // Global sections
    "Summary",
    "Details",
    "Warnings",
    // Active node sections
    "Node Details",
    "Metrics",
    "Projections",
];

export type ExplainState = {
    payload: LSM<ExplainLayoutPayload, { message: string }>;
    zoomLevel: ZoomLevel;
    viewportOffset: Maybe<Vector>;
    selectedIndex: Maybe<number>;
    tab: ExplainTab;
    expandedSections: Array<ExplainSectionName>;
};

type Actions =
    | ExplainLayoutAction
    | ZoomAction
    | SelectExplainNodeAction
    | ExplainViewportOffsetAction
    | ChangeExplainTabAction
    | ExpandSectionAction
    | CollapseSectionAction;

const initialState: ExplainState = {
    payload: getInitialState(),
    zoomLevel: DEFAULT_ZOOM_LEVEL,
    viewportOffset: undefined,
    selectedIndex: undefined,
    tab: "Estimated",
    expandedSections: DEFAULT_EXPANDED_SECTIONS,
};

// Figure out which tab should be active when the explain layout state changes.
// In particular, the tab should be Actual when the state becomes a new PROFILE
// layout and Estimated when the state becomes a new EXPLAIN layout.
const explainTabFor = (action: ExplainLayoutAction) => {
    if (
        !action.error &&
        !action.payload.loading &&
        action.payload.data.layout.planType === "PROFILE"
    ) {
        return "Actual";
    }
    return "Estimated";
};
export default (state: ExplainState = initialState, action: Actions) => {
    switch (action.type) {
        case "EXPLAIN_LAYOUT": {
            state = {
                ...state,
                payload: reduceLoadingStateMachine(state.payload, action),
                zoomLevel: DEFAULT_ZOOM_LEVEL,
                selectedIndex: undefined,
                tab: explainTabFor(action),
                expandedSections: DEFAULT_EXPANDED_SECTIONS,
            };
            break;
        }

        case "ZOOM": {
            state = {
                ...state,
                zoomLevel: action.payload.zoomLevel,
            };
            break;
        }

        case "SELECT_EXPLAIN_NODE": {
            state = {
                ...state,
                selectedIndex: action.payload.index,
            };
            break;
        }

        case "EXPLAIN_VIEWPORT_OFFSET": {
            state = {
                ...state,
                viewportOffset: action.payload.offset,
            };
            break;
        }

        case "CHANGE_EXPLAIN_TAB": {
            state = {
                ...state,
                tab: action.payload.tab,
            };
            break;
        }

        case "EXPAND_EXPLAIN_SECTION": {
            state = {
                ...state,
                expandedSections: [
                    ...state.expandedSections,
                    action.payload.sectionName,
                ],
            };

            break;
        }

        case "COLLAPSE_EXPLAIN_SECTION": {
            state = {
                ...state,
                expandedSections: removeFromSet(
                    state.expandedSections,
                    action.payload.sectionName
                ),
            };

            break;
        }
    }

    return state;
};
