import { Maybe } from "util/maybe";
import { SortDirection, TableSort } from "util/sort";

import * as React from "react";
import _ from "lodash";
import classnames from "classnames";

import { CustomScrollbar } from "view/components/custom-scrollbar";
import Icon from "view/components/icon";
import Tip from "view/components/tip";
import ResizeDetector from "view/components/resize-detector";
import GeneralError from "view/common/general-error";

import { globalDeselect } from "util/global-deselect";
import { logError } from "util/logging";

import "./super-table.scss";

const COLUMN_HEIGHT = 50;
const COMPACT_COLUMN_HEIGHT = 40;

export type ColumnId = string;
export type ColumnSort = "DISABLED" | SortDirection;
export type RowId = string;

export type OnSortHandler = (
    columnId: ColumnId,
    direction: Maybe<SortDirection>
) => void;

// I = Column ID Type
type BaseColumn<I> = {
    id: I;
    title: React.ReactNode;
    // `subTitle` shows up below the title for this column
    subTitle?: React.ReactNode;
    // `description` shows up in the tooltip for this column. Setting a
    // `description` also adds an info circle icon in the column header.
    description?: React.ReactNode;
    // If `disableDescriptionIcon` is true, then the info circle icon for the
    // description in the column header is not shown.
    disableDescriptionIcon?: boolean;
    sort?: ColumnSort;
    textAlign?: "left" | "center" | "right";

    // `revealOnHover` states whether the cells in this column should only be
    // revealed on hover.
    revealOnHover?: boolean;

    // By default, all columns are resizable. This property overrides that and
    // can be used to make a column non-resizable.
    nonResizable?: boolean;
};

// I = Column ID Type
export type Column<I extends string = ColumnId> = BaseColumn<I> & {
    defaultMinWidth?: number;
    defaultMaxWidth?: number;
};

// I = Column ID Type
type StateColumn<I extends string = ColumnId> = BaseColumn<I> & {
    minWidth?: number;
    maxWidth?: number;
};

export type Row = {
    id?: RowId;
    className?: string;
    onClickCell?: (rowId: Maybe<RowId>, c: Column) => void;
    cells: React.ReactNode;
    selected?: boolean;
};

export type RowWithHeight = Row & {
    rowHeight: number;
};

type ColumnPosition = {
    left: number;
    width: number;
};

type ColumnPositions = Array<ColumnPosition>;

const bounded = (n: number, min: number = 0, max: number = Infinity) =>
    Math.max(min, Math.min(max, n));

function propColumnsToStateColumns(columns: Array<Column>): Array<StateColumn> {
    return _.map(columns, (c: Column) => {
        const { defaultMaxWidth, defaultMinWidth } = c;

        return {
            ..._.omit(c, ["defaultMinWidth", "defaultMaxWidth"]),
            minWidth: defaultMinWidth,
            maxWidth: defaultMaxWidth,
        };
    });
}

// This represents the minimum width a column can take from a resize.
const MIN_RESIZE_COLUMN_WIDTH = 75;

const computeColumnPositions = (
    columns: Array<StateColumn>,
    tableWidth: number
): ColumnPositions => {
    if (!columns.length) {
        return [];
    }

    const contentMinWidth = _.sumBy(columns, c => c.minWidth || 0);
    const defaultColumnWidth = tableWidth / columns.length;
    const preferredWidths = _.map(columns, c =>
        bounded(defaultColumnWidth, c.minWidth, c.maxWidth)
    );

    let widths: Array<number>;
    if (contentMinWidth >= tableWidth) {
        // In this case, we can't go any smaller so we will need to use all of the
        // columns minWidths. This will cause the table to scroll.

        widths = _.map(columns, (c, i) => c.minWidth || preferredWidths[i]);
    } else {
        // In this case, we need to make some of the columns larger to fill the
        // tableWidth
        const fillDelta = tableWidth - contentMinWidth;

        // What we are doing here is distribute the `fillDelta` space that we
        // have to occupy with a little bit from each column that has the
        // "potential" to grow (i.e. columns that either have a larger
        // preferredWidth than minWidth OR columns that have an EQUAL
        // preferredWidth as minWidth BUT their maxWidth is not the same as
        // minWidth).

        const ratios = _.map(
            columns,
            (c, i) =>
                preferredWidths[i] - (c.minWidth || 0) ||
                (c.minWidth === c.maxWidth ? 0 : 1)
        );
        const ratiosSum = _.sum(ratios);

        // We then take each ratio and set the width of each column to be their
        // minimum width plus their "ratio" of `fillDelta`.
        const deltas = _.map(ratios, r => {
            if (ratiosSum === 0) {
                return 0;
            } else {
                return fillDelta * (r / ratiosSum);
            }
        });

        widths = _.map(columns, (c, i) => (c.minWidth || 0) + deltas[i]);
    }

    let left = 0;
    return _.map(widths, width => {
        let out = { width, left };
        left += width;
        return out;
    });
};

type TableInnerProps = React.HTMLAttributes<HTMLDivElement> & {
    columnsStyle: React.CSSProperties;
    renderedColumns: Array<React.ReactElement<unknown>>;
    rowsStyle: React.CSSProperties;
    renderedRows: Array<React.ReactElement<SuperRow>>;
    innerRef?: React.Ref<HTMLDivElement>;
    compact?: boolean;
};

// This component ensures that we have the same DOM structure between the real
// table and the table we use to render columns offscreen for measuring.
const TableInner = (props: TableInnerProps) => {
    const {
        columnsStyle,
        renderedColumns,
        rowsStyle,
        renderedRows,
        className,
        innerRef,
        compact,
        ...rest
    } = props;

    const classes = classnames("table-inner", className);
    const columnsClasses = classnames("columns", { compact });
    const rowsClasses = classnames("rows", { compact });

    return (
        <div className={classes} ref={innerRef} {...rest}>
            <div className={columnsClasses} style={columnsStyle}>
                {renderedColumns}
            </div>

            <div className={rowsClasses} style={rowsStyle}>
                {renderedRows}
            </div>
        </div>
    );
};

type TableProps = {
    className?: string;
    columns: Array<Column>;
    onSort?: OnSortHandler;

    // The `clickable` prop states whether a super table should have its rows
    // highlighted on hover with the cursor pointer or not.
    clickable: boolean;

    // If true, native tooltips will be added to any cells whose contents are
    // simply a string using the title attribute.
    addNativeTooltipToStrings?: boolean;

    // For virtualization, the number of extra items to render on both sides of
    // the viewport, to ensure scrolling isn't too jittery. Defaults to 5.
    overscanCount: number;

    verticallyAlignCells?: boolean;

    // Compact all the texts
    compact?: boolean;
} & (
    | {
          rows: Array<Row>;
          rowHeight: number;
      }
    | {
          rows: Array<RowWithHeight>;
      });

type TableState = {
    width: number;
    height: number;
    columnPositions: ColumnPositions;

    // this acts as a cache of columns
    // we update it when props.columns changes deeply (rather than just using
    // referential equality)
    columns: Array<StateColumn>;

    // This represents the current resize and only exists if there is a resize
    // going on.
    columnResize?: {
        index: number;
        startX: number;
        width: number;
    };

    scrollTop: number;

    // Index of the column we're measuring the width of, or undefined if we are
    // not measuring any column at the moment. When the table element we use to
    // measure finishes rendering, the column will be resized to fit it.
    columnIndexToMeasure: Maybe<number>;
};

export default class SuperTable extends React.Component<
    TableProps,
    TableState
> {
    static defaultProps = {
        clickable: false,
        overscanCount: 5,
    };

    constructor(props: TableProps) {
        super(props);

        const stateColumns = propColumnsToStateColumns(props.columns);

        this.state = {
            width: 0,
            height: 0,
            columns: stateColumns,
            columnPositions: computeColumnPositions(stateColumns, 0),
            scrollTop: 0,
            columnIndexToMeasure: undefined,
        };
    }

    componentDidMount() {
        document.addEventListener("mousemove", this.handleMouseMove);
        document.addEventListener("mouseup", this.handleMouseUp);
    }

    // Since `handleTableResize` is debounced, if the SuperTable is unmounted at
    // the right time, the handleTableResize call might happen *after* the
    // component is unmounted. This results in wasted cycles but also in a React
    // warning as the `setState` call in `handleTableResize` will fail due to
    // the component being unmounted.
    //
    // To avoid this problem, we *cancel* the debounce when the SuperTable is
    // unmounted.
    componentWillUnmount() {
        this.handleTableResize.cancel();

        document.removeEventListener("mousemove", this.handleMouseMove);
        document.removeEventListener("mouseup", this.handleMouseUp);
    }

    componentWillReceiveProps(nextProps: TableProps) {
        // We update the columns cache if they changed deeply. We recalculate
        // the positions of the columns if the parent changed the `id` of any
        // of the columns or the length of the columns. We ignore changes to the
        // `minWidth` or `maxWidth` of any column otherwise because the SuperTable
        // owns those from the first render onwards.

        const removeColumnsWidths = (columns: Array<Column>) => {
            return _.map(columns, column =>
                _.omit(column, ["minWidth", "maxWidth"])
            );
        };

        const getColumnsIds = (columns: Array<Column>) => {
            return _.map(columns, ({ id }) => ({ id }));
        };

        // If the parent changed the minWidth or maxWidth of the columns, we
        // ignore it since the SuperTable owns the minWidth and maxWidth of
        // every column from the first render onwards.

        const previousColumns = removeColumnsWidths(this.props.columns);
        const nextColumns = removeColumnsWidths(nextProps.columns);

        const previousColumnsIds = getColumnsIds(this.props.columns);
        const nextColumnsIds = getColumnsIds(nextProps.columns);

        if (!_.isEqual(previousColumns, nextColumns)) {
            if (
                !_.isEqual(previousColumnsIds, nextColumnsIds) ||
                previousColumns.length !== nextColumns.length
            ) {
                const columns = propColumnsToStateColumns(nextProps.columns);
                const columnPositions = computeColumnPositions(
                    columns,
                    this.state.width
                );

                this.setState({
                    columns,
                    columnPositions,
                });
            } else {
                // At this point, we know that the length of the columns is the same, so we just
                // update all the properties about columns that changed with the exception of
                // `minWidth` and `maxWidth`.
                this.setState({
                    columns: _.map(nextProps.columns, (c, i) => ({
                        ...c,
                        minWidth: this.state.columns[i].minWidth,
                        maxWidth: this.state.columns[i].maxWidth,
                    })),
                });
            }
        }
    }

    handleResizeColumnMouseDown = (index: number) => (
        evt: React.MouseEvent
    ) => {
        const { columnPositions } = this.state;

        const position = columnPositions[index];

        globalDeselect();

        this.setState({
            columnResize: {
                index,
                startX: evt.clientX - position.width,
                width: position.width,
            },
        });
    };

    handleMouseMove = (e: MouseEvent) => {
        const { columnResize } = this.state;

        if (columnResize) {
            globalDeselect();

            const width = Math.max(
                MIN_RESIZE_COLUMN_WIDTH,
                e.clientX - columnResize.startX
            );

            this.setState({
                columnResize: {
                    ...columnResize,
                    width,
                },
            });
        }
    };

    handleMouseUp = () => {
        const { columnResize } = this.state;

        if (columnResize) {
            this.updateColumnWidth(columnResize.index, columnResize.width);

            this.setState({
                columnResize: undefined,
            });
        }
    };

    handleResizeColumnDoubleClick = (index: number) => () => {
        if (this.state.columnIndexToMeasure === undefined) {
            this.setState({
                columnIndexToMeasure: index,
            });
        } else {
            logError(
                new Error(
                    "Received double click while already trying to measure column."
                )
            );
        }
    };

    // This function takes the `index` of a column and a `width` and updates its
    // size to that `width`. It may or may not update the width of the column
    // immediately afterwards.
    updateColumnWidth = (index: number, width: number) => {
        const { columns, columnPositions } = this.state;

        // Loop through the columns and mutate each column's minWidth and
        // maxWidth. After these changes, we call `computeColumnPositions` to
        // recalculate the position of each column taking into account the new
        // minWidth/maxWidth constraints.

        let newColumns: Array<StateColumn> = [];
        for (let i = 0; i < columns.length; i++) {
            if (i === index) {
                newColumns.push({
                    ...columns[i],
                    minWidth: width,
                    maxWidth: width,
                });
            } else {
                // Fix the `minWidth` and `maxWidth` of every column that isn't
                // the resized column or the one immediately afterwards.

                newColumns.push({
                    ...columns[i],
                    minWidth: columnPositions[i].width,
                    maxWidth: columnPositions[i].width,
                });
            }
        }

        this.setState({
            columns: newColumns,
            columnPositions: computeColumnPositions(
                newColumns,
                this.state.width
            ),
        });
    };

    // This will be called immediately when ResizeDetector mounts
    handleTableResize = _.debounce((width: number, height: number) => {
        const newPositions = computeColumnPositions(this.state.columns, width);

        if (
            width !== this.state.width ||
            height !== this.state.height ||
            !_.isEqual(newPositions, this.state.columnPositions)
        ) {
            this.setState({
                width,
                height,
                columnPositions: newPositions,
            });
        }
    }, 50);

    handleScroll = ({ scrollTop }: { scrollTop: number }) => {
        this.setState({ scrollTop });
    };

    doneMeasuring = (div: HTMLDivElement) => {
        const { columnIndexToMeasure, width } = this.state;

        if (columnIndexToMeasure !== undefined && div) {
            const measuredWidth = div.getBoundingClientRect().width;

            this.updateColumnWidth(
                columnIndexToMeasure,
                Math.max(Math.min(Math.ceil(measuredWidth), width * 0.9), 100)
            );
            this.setState({
                columnIndexToMeasure: undefined,
            });
        }
    };

    // Utility functions to get rows with their heights when it doesn't matter
    // if it's fixed-height or variable-height.
    getRowsWithHeights(): Array<RowWithHeight> {
        if ("rowHeight" in this.props) {
            const { rowHeight } = this.props;

            return this.props.rows.map(row => ({ ...row, rowHeight }));
        } else {
            return this.props.rows;
        }
    }

    getRowWithHeight(i: number): RowWithHeight {
        if ("rowHeight" in this.props) {
            const { rowHeight } = this.props;

            return { ...this.props.rows[i], rowHeight };
        } else {
            return this.props.rows[i];
        }
    }

    getColumnHeight = () => {
        const { compact } = this.props;

        if (compact) {
            return COMPACT_COLUMN_HEIGHT;
        } else {
            return COLUMN_HEIGHT;
        }
    };

    renderMeasuringColumn() {
        // Render an offscreen table with only cells in the particular column
        // we're trying to measure (if any). The offscreen table should have
        // the same DOM structure and class names as the real table as much as
        // possible, because we need the same CSS rules to apply to the cells
        // in order for the width measurements to be accurate.

        const { columnIndexToMeasure } = this.state;
        if (columnIndexToMeasure === undefined) {
            return null;
        }

        const { columns, compact } = this.props;
        const column = columns[columnIndexToMeasure];

        // This is the only column in its array, but we need to pass a key
        // anyway or React will complain.
        const renderedColumn = (
            <SuperColumn
                {...column}
                key={column.id}
                onSort={undefined}
                position={undefined}
                className="measuring"
            />
        );

        const renderedRows = this.getRowsWithHeights().map((row, rowIndex) => {
            const cells = row.cells;
            if (!Array.isArray(cells)) {
                throw new Error("cells must be Array");
            }
            const cell = cells[columnIndexToMeasure];

            const classes = classnames("super-row", row.className);

            return (
                <div
                    className={classes}
                    style={{ height: row.rowHeight }}
                    key={rowIndex}
                >
                    <Cell
                        rowId={undefined}
                        column={column}
                        position={undefined}
                        title={undefined}
                        className="measuring"
                    >
                        {cell}
                    </Cell>
                </div>
            );
        });

        return (
            <TableInner
                className="offscreen"
                innerRef={this.doneMeasuring}
                columnsStyle={{
                    height: this.getColumnHeight(),
                }}
                renderedColumns={[renderedColumn]}
                rowsStyle={{}}
                renderedRows={renderedRows}
                compact={compact}
            />
        );
    }

    computeVirtualizationInfo(): {
        firstRowIndex: number;
        lastRowIndex: number;
        totalHeight: number;
        topOffset: number;
    } {
        // Figure out the range of rows to render, based on the overscan.
        // (The range includes firstRowIndex and excludes lastRowIndex.)
        // Also figure out the table's full height and the offset that should
        // be above the first rendered row.

        // Note: we have to extract rows from this.props inside the if
        // statement instead of here to get its type refined by the if
        // statement.
        const { overscanCount } = this.props;
        const { scrollTop, height } = this.state;
        const scrollBottom = scrollTop + height;

        if ("rowHeight" in this.props) {
            // Constant row height; O(1)
            const { rows, rowHeight } = this.props;

            const firstRowIndex = Math.max(
                0,
                Math.floor(scrollTop / rowHeight) - overscanCount
            );
            const lastRowIndex = Math.min(
                rows.length,
                Math.ceil(scrollBottom / rowHeight) + overscanCount
            );

            return {
                firstRowIndex,
                lastRowIndex,
                totalHeight: rows.length * rowHeight,
                topOffset: firstRowIndex * rowHeight,
            };
        } else {
            // Variable row height; O(n)
            const { rows } = this.props;
            let cumulativeHeight = 0;
            let firstRowIndex: Maybe<number>;
            let lastRowIndex: Maybe<number>;
            let topOffset: Maybe<number>;

            // To virtualize, we need to find the first and last visible rows
            // in the viewport, so we sum up row heights until we reach
            // scrollTop and scrollBottom. Then we subtract/add overscanCount
            // to figure out which range of rows to render. We also need to sum
            // up all the row heights along the way, as well as the row heights
            // before the first rendered row.
            rows.forEach(({ rowHeight }, i) => {
                cumulativeHeight += rowHeight;

                if (
                    firstRowIndex === undefined &&
                    cumulativeHeight > scrollTop
                ) {
                    // Row i is the first row that will be visible. Start
                    // rendering from the row that's `overscanCount` rows
                    // before it.
                    firstRowIndex = Math.max(0, i - overscanCount);

                    // Figure out how much vertical space is above the first
                    // row we just picked out. Assuming overscanCount is
                    // relatively small, it will typically be faster to compute
                    // this by subtracting the last few row heights from the
                    // total height so far.
                    topOffset = cumulativeHeight;
                    for (let j = firstRowIndex; j <= i; j++) {
                        topOffset -= rows[j].rowHeight;
                    }
                }

                if (
                    lastRowIndex === undefined &&
                    cumulativeHeight >= scrollBottom
                ) {
                    // Row i is the last row that will be visible. Render up to
                    // the row that's `overscanCount` rows after it. (+1
                    // because lastRowIndex is exclusive.)
                    lastRowIndex = Math.min(rows.length, i + 1 + overscanCount);
                }
            });

            // Shouldn't happen if everything were mathematically perfect, but
            // might happen due to rounding errors?
            if (firstRowIndex === undefined) {
                firstRowIndex = 0;
            }
            if (topOffset === undefined) {
                topOffset = 0;
            }
            // Might happen if the rows are just really short relative to the
            // table height
            if (lastRowIndex === undefined) {
                lastRowIndex = rows.length;
            }

            return {
                firstRowIndex,
                lastRowIndex,
                totalHeight: cumulativeHeight,
                topOffset,
            };
        }
    }

    render() {
        const {
            className,
            rows,
            onSort,
            clickable,
            addNativeTooltipToStrings,
            verticallyAlignCells,
            compact,
        } = this.props;
        const {
            width,
            height,
            columnPositions,
            columns,
            columnResize,
        } = this.state;

        const classes = classnames("components-super-table", className, {
            hidden: width === 0,
        });

        const renderedColumns = columns.map((c: Column, i: number) => {
            const position = columnPositions[i];

            // The ResizeHandle is enabled if:
            // * The column is resizable
            // * There is more than 1 column in this table
            const enabled = !columns[i].nonResizable && columns.length > 1;

            let resizing = columnResize && columnResize.index === i;

            return (
                <React.Fragment key={c.id}>
                    <SuperColumn
                        {...c}
                        key={c.id}
                        onSort={onSort}
                        position={position}
                    />

                    <ResizeHandle
                        onMouseDown={this.handleResizeColumnMouseDown(i)}
                        onMouseUp={this.handleMouseUp}
                        onDoubleClick={this.handleResizeColumnDoubleClick(i)}
                        resizing={!!resizing}
                        enabled={enabled}
                        fullHeight={height}
                        left={
                            position.left +
                            (columnResize && resizing
                                ? columnResize.width
                                : position.width)
                        }
                        last={i === columns.length - 1}
                    />
                </React.Fragment>
            );
        });

        const {
            firstRowIndex,
            lastRowIndex,
            totalHeight,
            topOffset,
        } = this.computeVirtualizationInfo();
        const renderedRows = [];

        for (let i = firstRowIndex; i < lastRowIndex; i++) {
            const row = this.getRowWithHeight(i);

            if (
                !(
                    Array.isArray(row.cells) &&
                    columns.length === row.cells.length
                )
            ) {
                const error =
                    "Row has different number of cells from the number of columns.";

                logError(new Error(error));

                return (
                    <div className={classes}>
                        <CustomScrollbar
                            className="error-scrollbars"
                            renderView={props => (
                                <div {...props} className="error-view" />
                            )}
                        >
                            <GeneralError error={error} />
                        </CustomScrollbar>
                    </div>
                );
            }

            renderedRows.push(
                <SuperRow
                    {...row}
                    key={row.id || i}
                    columns={columns}
                    columnPositions={columnPositions}
                    height={row.rowHeight}
                    clickable={clickable}
                    addNativeTooltipToStrings={addNativeTooltipToStrings}
                    striped={(rows.length - i) % 2 === 1}
                    verticallyAlignCells={verticallyAlignCells}
                />
            );
        }

        const totalColumnWidth = _.sumBy(columnPositions, "width");

        // NOTE: the anon div wrapping columns and rows in the below JSX block
        // is *needed* for firefox and chrome to have the same scrolling
        // behavior.  It wraps the two "child" elements and causes them to be
        // scrolled together rather than separately.
        return (
            <div className={classes}>
                <CustomScrollbar
                    renderTrackVertical={({ style }) => (
                        <div
                            style={{
                                ...style,
                                top: this.getColumnHeight() + 2,
                                bottom: 2,
                                right: 2,
                                borderRadius: 3,
                            }}
                        />
                    )}
                    onScrollFrame={this.handleScroll}
                >
                    {/* tabIndex is a strange attribute to have here, but if we
                    don't put a tabIndex, this happens in Chrome: A user clicks
                    on a row and tries to scroll the table with the arrow keys.
                    Once the clicked row is sufficiently off-screen, it is no
                    longer rendered due to virtualization, the table loses
                    focus, and arrow keys stop scrolling. */}
                    <TableInner
                        style={{ height: totalHeight }}
                        tabIndex={0}
                        columnsStyle={{
                            width: totalColumnWidth,
                            height: this.getColumnHeight(),
                        }}
                        renderedColumns={renderedColumns}
                        rowsStyle={{
                            width: totalColumnWidth,
                            top: topOffset,
                        }}
                        renderedRows={renderedRows}
                        compact={compact}
                    />

                    {this.renderMeasuringColumn()}
                </CustomScrollbar>

                <ResizeDetector onResize={this.handleTableResize} />
            </div>
        );
    }
}

type ResizeHandleProps = React.HTMLProps<HTMLDivElement> & {
    resizing: boolean;
    enabled: boolean;
    fullHeight: number;
    left: number;
    last?: boolean;
};

const RESIZE_HANDLE_WIDTH = 16;

function ResizeHandle({
    resizing,
    enabled,
    fullHeight,
    left,
    last,
    ...rest
}: ResizeHandleProps) {
    const classes = classnames("resize-handle", { resizing, last });
    let computedLeft;
    let computedBarMarginLeft;

    // We render regular resize handles with half of its width
    // to the left of the column separator and the other half
    // to the right. The resize handle of the last column on a
    // table has to be fully rendered to the left of the separator.
    if (last) {
        computedLeft = left - RESIZE_HANDLE_WIDTH;
        computedBarMarginLeft = RESIZE_HANDLE_WIDTH;
    } else {
        computedLeft = left - RESIZE_HANDLE_WIDTH / 2;
        computedBarMarginLeft = RESIZE_HANDLE_WIDTH / 2;
    }

    if (!enabled) {
        return null;
    }

    return (
        <div
            {...rest}
            className={classes}
            style={{
                left: computedLeft,
                width: RESIZE_HANDLE_WIDTH,
                height: resizing ? fullHeight : undefined,
            }}
        >
            <div
                className="bar"
                style={{ marginLeft: computedBarMarginLeft }}
            />
        </div>
    );
}

type ColumnProps = Column & {
    onSort: Maybe<OnSortHandler>;

    // undefined if rendering a cell to measure its width
    position: Maybe<ColumnPosition>;

    className?: string;
};

class SuperColumn extends React.PureComponent<ColumnProps> {
    render() {
        const {
            onSort,
            sort,
            id,
            description,
            title,
            position,
            subTitle,
            textAlign,
            className,
            disableDescriptionIcon,
        } = this.props;

        let handleSort, sortIcon;

        // If there is a sorting handler, handle it
        if (onSort && sort !== "DISABLED") {
            handleSort = () => onSort(id, sort);
        }

        // If there is a current sort state, show it in this column.
        switch (sort) {
            case "asc":
                sortIcon = "caret-up";
                break;

            case "desc":
                sortIcon = "caret-down";
                break;
        }

        const classes = classnames("column", className, {
            sortable: !!handleSort,
        });

        const style = {
            textAlign: textAlign || "left",
            ...position,
        };

        let descriptionIcon;
        if (description && !disableDescriptionIcon) {
            descriptionIcon = <Icon leftMargin icon="info-circle" />;
        }

        return (
            <Tip
                className={classes}
                style={style}
                onClick={handleSort}
                tooltip={description || null}
                disabled={!description}
                direction="s"
            >
                <div className="titles-container">
                    <div className="title">
                        {title}
                        {descriptionIcon}
                    </div>
                    <div className="subtitle">{subTitle}</div>
                </div>

                {sortIcon && (
                    <Icon className="icon-sort" fixedWidth icon={sortIcon} />
                )}
            </Tip>
        );
    }
}

type RowProps = Row & {
    columns: Array<Column>;
    columnPositions: ColumnPositions;
    height: number;
    clickable: boolean;
    addNativeTooltipToStrings?: boolean;
    striped?: boolean;
    verticallyAlignCells?: boolean;
};

class SuperRow extends React.PureComponent<RowProps> {
    render() {
        const {
            id: rowId,
            className,
            cells,
            columns,
            columnPositions,
            onClickCell,
            height,
            clickable,
            addNativeTooltipToStrings,
            striped,
            selected,
            verticallyAlignCells,
        } = this.props;

        if (
            !(
                columns &&
                Array.isArray(cells) &&
                columns.length === cells.length
            )
        ) {
            throw new Error("Must provide as many cells as there are columns.");
        }

        const children = React.Children.map(cells, (c, i: number) => {
            let nativeTooltip;
            if (addNativeTooltipToStrings && typeof c === "string") {
                nativeTooltip = c;
            }

            return (
                <Cell
                    key={i}
                    rowId={rowId}
                    column={columns[i]}
                    position={columnPositions[i]}
                    onClick={onClickCell}
                    title={nativeTooltip}
                    verticallyAlignCells={verticallyAlignCells}
                >
                    {c}
                </Cell>
            );
        });

        const classes = classnames("super-row", className, {
            clickable,
            striped,
            selected,
        });

        return (
            <div className={classes} style={{ height }}>
                {children}
            </div>
        );
    }
}

type CellProps = {
    rowId: Maybe<RowId>;
    column: Column;
    children: React.ReactNode;
    onClick?: (id: Maybe<RowId>, c: Column) => void;

    // undefined if rendering a cell to measure its width
    position: Maybe<ColumnPosition>;

    title: Maybe<string>;
    verticallyAlignCells?: boolean;

    className?: string;
};

class Cell extends React.PureComponent<CellProps> {
    render() {
        const {
            rowId,
            children,
            column,
            position,
            onClick,
            title,
            verticallyAlignCells,
            className,
        } = this.props;

        const style = {
            textAlign: column.textAlign || "left",
            ...position,
        };

        const classes = classnames("cell", column.id, className, {
            "reveal-on-hover": column.revealOnHover,
            "vertical-align": verticallyAlignCells,
        });

        // We append a single space after every cell's content to
        // prevent a problem where adjacent cells would all be copied
        // when the user double clicked one of them. This happens when
        // there is no whitespace between 2 absolutely positioned DOM
        // elements.
        //
        // By adding a non-selectable space after cells, we make sure
        // that browsers don't select multiple cells on double click. At
        // the same time, we are not messing with what the user is
        // selecting because the space cannot be selected.
        //
        // Read more about this issue here:
        // * https://stackoverflow.com/questions/2876424/html-double-click-selection-oddity
        // * https://stackoverflow.com/questions/15809239/user-text-selection-in-floating-element-in-chrome-webkit-selects-more-text
        return (
            <div
                className={classes}
                style={style}
                onClick={onClick && (() => onClick(rowId, column))}
                title={title}
            >
                {children}
                <div className="non-selectable-whitespace" />
            </div>
        );
    }
}

export const addSort = (sort: TableSort) => <T extends Column>(column: T) => {
    // avoid sorting non-sortable columns
    if (column.sort === "DISABLED") {
        return column;
    }

    return {
        ...column,
        sort: sort && sort.columnId === column.id ? sort.direction : undefined,
    };
};
