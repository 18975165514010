import * as React from "react";
import classnames from "classnames";

import Icon from "view/components/icon";

import "./checkbox.scss";

type Props = {
    inputProps: React.HTMLProps<HTMLInputElement>;
    className?: string;
};

export class Checkbox extends React.Component<Props> {
    render() {
        const { inputProps, className } = this.props;
        const { name } = inputProps;

        const classes = classnames("components-checkbox", className);

        return (
            <div className={classes}>
                <input
                    className="checkbox-hidden"
                    type="checkbox"
                    id={name}
                    {...inputProps}
                />

                <div className="checkbox-styled">
                    <Icon
                        icon="check"
                        className="check-icon"
                        iconType="regular"
                        size="xs"
                    />
                </div>
            </div>
        );
    }
}
