import * as React from "react";

import ExtLink from "view/components/external-link";
import Modal from "view/components/modal";
import { Button } from "view/common/button";
import Icon from "view/components/icon";

import "./feedback-modal.scss";

export type FeedbackModalStatus = "CLOSED" | "OPEN";

type Props = {
    onStatusChange: (state: FeedbackModalStatus) => void;
    status: FeedbackModalStatus;
};

export default class FeedbackModal extends React.Component<Props> {
    handleClose = () => {
        this.props.onStatusChange("CLOSED");
    };

    render() {
        const { status } = this.props;

        return (
            <Modal
                className="feedback-modal"
                active={status === "OPEN"}
                onClose={this.handleClose}
                innerContentPadding
                actions={
                    <Button large primary externalLink="forums-feedback">
                        <Icon
                            icon="comments"
                            size="sm"
                            className="btn-icon"
                            rightMargin
                        />
                        Send feedback in Community Forums
                    </Button>
                }
            >
                <div className="feedback-modal-inner">
                    <div className="title">Send Feedback</div>
                    <div className="description">
                        Send feedback, issue reports or feature ideas to the
                        community forums, or if you have Enterprise Edition,
                        submit a{" "}
                        <ExtLink
                            name="memsql-support"
                            category="feedback-modal"
                        >
                            support request
                        </ExtLink>
                        .
                    </div>
                </div>
            </Modal>
        );
    }
}
