import { LogMessageAction } from "data/actions/log";
import { TabTitle } from "data/reducers/bottom-panel";

import _ from "lodash";
import assign from "util/assign";

export type LogMessageLevel = "error" | "info" | "debug" | "warn";

export type LogMessage = {
    timestamp: Date;
    msg: string;
    level: LogMessageLevel;
    focusTab?: TabTitle; // if present, indicates this message should cause the bottom panel to open to a tab
};

export type LogMessageWithID = {
    id: number;
    msg: LogMessage;
};

export type LogState = {
    nextId: number;
    messages: Array<LogMessageWithID>;
};

type Actions = LogMessageAction;

const MAX_LOG_MESSAGES = 1000;

const initialState = {
    nextId: 0,
    messages: [],
};

export default (state: LogState = initialState, action: Actions) => {
    switch (action.type) {
        case "LOG_MESSAGE": {
            const id = state.nextId;
            state = assign(state, {
                nextId: id + 1,
                messages: _.takeRight(
                    [...state.messages, { id, msg: action.payload }],
                    MAX_LOG_MESSAGES
                ),
            });

            break;
        }
    }

    return state;
};
