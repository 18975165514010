import { saveAs } from "file-saver";

import format from "date-fns/format";

// This function is a helper utility to save text files in MemSQL Studio.
// It uses the `save-as` polyfill to save a file onto the browser's host.
// It also creates the file with with a consistently formatted name.
export default function saveData({
    data,
    baseName,
    extension = "json",
}: {
    data: string;
    baseName: string;
    extension?: string;
}) {
    const blob = new Blob([data], {
        type: "text/plain;charset=utf-8",
    });

    const fileName = `${baseName}-${format(
        new Date(),
        "YYYY-MM-DDTHHmmss"
    )}.${extension}`;

    saveAs(blob, fileName);
}
