import { LicenseInfo } from "data/models";

import { distanceInWordsToNow } from "date-fns";
import { capitalize } from "humanize-plus";

export const getLicenseExpirationText = (licenseInfo: LicenseInfo) => {
    if (licenseInfo.expiration === "unlimited") {
        return "Never";
    } else {
        const licenseText = `${distanceInWordsToNow(
            licenseInfo.expiration
        )} until expiration`;

        return capitalize(licenseText);
    }
};
