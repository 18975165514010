import { DispatchFunction } from "data";

import * as React from "react";
import { connect } from "react-redux";

import { Button } from "view/common/button";

import { appendText } from "data/actions";

import * as analytics from "util/segment";

type Props = {
    query: string;
    text: string;
    id: string; // this is for analytics
};

type TutorialLoadSQLButtonProps = Props & {
    dispatch: DispatchFunction;
};

class TutorialLoadSQLButton extends React.Component<
    TutorialLoadSQLButtonProps
> {
    handleClick = () => {
        const { dispatch, query, id } = this.props;
        dispatch(appendText({ text: query }));

        analytics.track("load-sql-into-editor", {
            category: "tutorial",
            id,
        });
    };

    render() {
        const { text } = this.props;

        return <Button onClick={this.handleClick}>{text}</Button>;
    }
}

export default connect()(TutorialLoadSQLButton);
