import { MysqlError } from "mysqljs";
import { Maybe } from "util/maybe";
import { Nullable } from "util/nullable";
import { RowResult, FieldList } from "mysqljs";
import { Observer } from "rxjs";

import {
    StructureAction,
    StatisticsAction,
    ClusterStatisticsPayload,
    ClusterStatisticsAction,
    SummaryAction,
    TableLikeSampleAction,
    ColumnarSegmentsAction,
} from "data/actions";

import {
    SQLStructureDatabasesRow,
    SQLStructureShowTablesRow,
    SQLStructureTablesSingleQueryRow,
    SQLStructureColumnsRow,
    SQLStatisticsDatabase,
    SQLStatisticsColumnsMemory,
    SQLStatisticsTable,
    SQLStatisticsIndex,
    SQLStatisticsIndexColumnar,
    SQLStatisticsHA,
    SQLTotalMemoryUsageRow,
    SQLDatabaseDiskUsageRow,
    SQLStructureFunctionRow,
    SQLStructureAggregateRow,
    SQLStructureProceduresRow,
    SQLStatisticsColumnsColumnar,
    SQLDatabaseNameRow,
} from "worker/api/schema-queries";

import {
    TableType,
    TableStorage,
    Index,
    View,
    TableLike,
    Table,
    TableID,
    SummaryDatabaseEntry,
    DatabaseName,
    TableName,
    SchemaStructure,
    UserDefinedAggregate,
    UserDefinedFunction,
    StoredProcedure,
    Database,
    ViewColumn,
    Column,
} from "data/models";

import { HandlerContext } from "worker/api";
import { Connection } from "mysqljs";

import _ from "lodash";
import BigNumber from "vendor/bignumber.js/bignumber";

import SqlString from "sqlstring";
import { makeActionCreator } from "worker/api/helpers";
import { fetchMemsqlVersion } from "worker/api/version-query";
import {
    select,
    multiSelect,
    multiSelectWithErrors,
    mapMultiSelectErrorResult,
} from "util/query";
import { Observable } from "rxjs";

import {
    SQL_STRUCTURE_DATABASES,
    SQL_STRUCTURE_SHOW_TABLES,
    SQL_STRUCTURE_TABLES_SINGLE_QUERY,
    SQL_STRUCTURE_TABLES_SINGLE_QUERY_MIN_VERSION,
    SQL_STRUCTURE_COLUMNS,
    SQL_STATISTICS_DATABASE,
    SQL_STATISTICS_COLUMNS_MEMORY,
    SQL_STATISTICS_TABLE,
    SQL_STATISTICS_INDEXES,
    SQL_STATISTICS_INDEXES_COLUMNAR,
    SQL_STATISTICS_HA,
    SQL_TOTAL_MEMORY_USAGE,
    SQL_DATABASE_DISK_USAGE,
    SQL_TABLE_LIKE_SAMPLE,
    SQL_STRUCTURE_FUNCTIONS,
    SQL_STRUCTURE_AGGREGATES,
    SQL_STATISTICS_COLUMNS_COLUMNAR,
    SQL_STRUCTURE_STORED_PROCEDURES,
    SQL_DATABASE_NAMES,
} from "worker/api/schema-queries";

import { differenceInSeconds } from "date-fns";
import { genTableId, genFunctionId } from "memsql/db-object-id";
import { rawResultToArray } from "util/sql-results";
import { logFocusError } from "data/actions";
import { logError } from "util/logging";
import { nullableToMaybe } from "util/nullable";
import { Version } from "util/version";

const SQLIdent = (value: string) => SqlString.raw(SqlString.escapeId(value));

function sortTables<T extends { tableId: TableID }>(
    tables: Array<T>
): Array<T> {
    return _.orderBy(tables, [table => table.tableId], "asc");
}

const createStructureAction = (data: SchemaStructure): StructureAction => {
    return {
        type: "SCHEMA_STRUCTURE",
        error: false,
        payload: {
            loading: false,
            data,
        },
    };
};

const getTableType = (value: Maybe<string>): TableType => {
    switch (value) {
        case "BASE TABLE":
            return "BASE";

        case "TEMPORARY TABLE":
            return "TEMPORARY";

        default:
            return "UNKNOWN";
    }
};

const getTableStorage = (value: Maybe<string>): TableStorage => {
    switch (value) {
        case "INMEMORY_ROWSTORE":
            return value;

        case "COLUMNSTORE":
            return value;

        default:
            return "UNKNOWN";
    }
};

function parseSqlStructureTable({
    databaseName,
    tableName,
    isSharded,
    tableType,
    tableStorage,
}: {
    databaseName: string;
    tableName: string;
    isSharded: boolean;
    tableType: Maybe<string>;
    tableStorage: Maybe<string>;
}): TableLike {
    const id = genTableId({
        databaseName,
        tableName,
    });

    if (tableType === "VIEW" || tableType === "SYSTEM VIEW") {
        return {
            tableId: id,
            tableName,
            databaseName,
            kind: "VIEW",
        };
    }

    return {
        tableId: id,
        databaseName,
        tableName,
        kind: "TABLE",

        isSharded,
        tableType: getTableType(tableType),
        tableStorage: getTableStorage(tableStorage),
    };
}

function parseTableShowTables(
    databaseName: string,
    row: SQLStructureShowTablesRow
): TableLike {
    // the table name column is called "Tables_in_${databaseName}"
    const tableName = row[`Tables_in_${databaseName}`];

    return parseSqlStructureTable({
        databaseName,
        tableName,
        isSharded: Boolean(row.distributed),
        tableType: row.Table_type,
        tableStorage: row.Storage_type,
    });
}

function parseTableSingleQuery({
    databaseName,
    tableName,
    tableType,
    isSharded,
    tableStorage,
}: SQLStructureTablesSingleQueryRow): TableLike {
    return parseSqlStructureTable({
        databaseName,
        tableName,
        tableType,
        isSharded: isSharded.gt(0),
        tableStorage: nullableToMaybe(tableStorage),
    });
}

const queryTables = (conn: Connection) => ([databases, columns]: [
    Array<SQLStructureDatabasesRow>,
    Array<SQLStructureColumnsRow>
]): Promise<
    [
        Array<SQLStructureDatabasesRow>,
        Array<SQLStructureColumnsRow>,
        Array<TableLike>
    ]
> => {
    // On newer versions of memSQL, we can obtain the information we want for
    // all tables in a single query joining two INFORMATION_SCHEMA tables. On
    // older versions, we have to run a separate query for each database.

    // We check the version instead of doing feature detection because this is
    // a performance optimization and querying INFORMATION_SCHEMA tables to do
    // the feature detection is too expensive, but querying the version is
    // cheap.

    return fetchMemsqlVersion(conn)
        .then(
            (version: Version): Promise<Array<TableLike>> => {
                if (version.ge(SQL_STRUCTURE_TABLES_SINGLE_QUERY_MIN_VERSION)) {
                    return select(conn, SQL_STRUCTURE_TABLES_SINGLE_QUERY).then(
                        sqlStructureTables =>
                            _.map(sqlStructureTables, parseTableSingleQuery)
                    );
                } else {
                    const tableQueries = _.map(databases, db => ({
                        sql: SQL_STRUCTURE_SHOW_TABLES,
                        params: [SQLIdent(db.databaseName)],
                    }));

                    return multiSelectWithErrors<SQLStructureShowTablesRow>(
                        conn,
                        ...tableQueries
                    ).then(results =>
                        _.flatMap(
                            results,
                            (rows, idx): Array<TableLike> => {
                                if (_.isError(rows)) {
                                    if (
                                        rows.code === "ER_DBACCESS_DENIED_ERROR"
                                    ) {
                                        return [];
                                    } else {
                                        throw rows;
                                    }
                                }

                                return _.map(rows, row =>
                                    parseTableShowTables(
                                        databases[idx].databaseName,
                                        row
                                    )
                                );
                            }
                        )
                    );
                }
            }
        )
        .then(
            (
                tables: Array<TableLike>
            ): [
                Array<SQLStructureDatabasesRow>,
                Array<SQLStructureColumnsRow>,
                Array<TableLike>
            ] => [databases, columns, tables]
        );
};

const queryFunctions = (conn: Connection) => (
    previousResults: [
        Array<SQLStructureDatabasesRow>,
        Array<SQLStructureColumnsRow>,
        Array<TableLike>
    ]
) => {
    const databases = previousResults[0];

    const functionQueries = _.map(databases, db => ({
        sql: SQL_STRUCTURE_FUNCTIONS,
        params: [SQLIdent(db.databaseName)],
    }));

    return multiSelectWithErrors<SQLStructureFunctionRow>(
        conn,
        ...functionQueries
    ).then(resultSets => {
        const userDefinedFunctions: Array<UserDefinedFunction> = [];

        // `resultSets` is an array of result sets, and each result set is an
        // array of rows.
        _.each(resultSets, (rows, idx) => {
            if (_.isError(rows)) {
                if (rows.code === "ER_DBACCESS_DENIED_ERROR") {
                    return;
                } else {
                    throw rows;
                }
            }

            for (let i = 0; i < rows.length; i++) {
                const r = rows[i];

                // the table name column is called "Functions_in_${databaseName}"
                const databaseName = databases[idx].databaseName;
                const name = r[`Functions_in_${databaseName}`];
                const functionId = genFunctionId({
                    databaseName,
                    functionName: name,
                });
                const definer = r.Definer;

                switch (r["Function Type"]) {
                    case "User Defined Function": {
                        userDefinedFunctions.push({
                            kind: "USER_DEFINED_FUNCTION",
                            databaseName,
                            name,
                            type: "SCALAR_VALUED_FUNCTION",
                            functionId,
                            definer,
                        });

                        break;
                    }

                    case "Table Valued Function": {
                        userDefinedFunctions.push({
                            kind: "USER_DEFINED_FUNCTION",
                            databaseName,
                            name,
                            type: "TABLE_VALUED_FUNCTION",
                            functionId,
                            definer,
                        });

                        break;
                    }

                    case "Stored Procedure": {
                        // Ignore stored procedures shown in the output of SHOW
                        // FUNCTIONS since we grab those from SHOW STORED
                        // PROCEDURES. Only version of MemSQL older than 6.7.11
                        // show Stored Procedures in the output of SHOW
                        // FUNCTIONS.

                        break;
                    }

                    default: {
                        logError(
                            new Error(
                                `Found a function with unknown type: ${
                                    r["Function Type"]
                                }`
                            )
                        );
                    }
                }
            }
        });

        return [
            previousResults[0],
            previousResults[1],
            previousResults[2],
            userDefinedFunctions,
        ];
    });
};

const queryAggregates = (conn: Connection) => (
    previousResults: [
        Array<SQLStructureDatabasesRow>,
        Array<SQLStructureColumnsRow>,
        Array<TableLike>,
        Array<UserDefinedFunction>
    ]
) => {
    const databases = previousResults[0];

    const aggregateQueries = _.map(databases, db => ({
        sql: SQL_STRUCTURE_AGGREGATES,
        params: [SQLIdent(db.databaseName)],
    }));

    return multiSelectWithErrors<SQLStructureAggregateRow>(
        conn,
        ...aggregateQueries
    ).then(
        (
            resultSets
        ): [
            Array<SQLStructureDatabasesRow>,
            Array<SQLStructureColumnsRow>,
            Array<TableLike>,
            Array<UserDefinedFunction>,
            Array<UserDefinedAggregate>
        ] => {
            const userDefinedAggregates = _.flatMap(
                resultSets,
                (rows, idx): Array<UserDefinedAggregate> => {
                    if (_.isError(rows)) {
                        if (rows.code === "ER_DBACCESS_DENIED_ERROR") {
                            return [];
                        } else {
                            throw rows;
                        }
                    }

                    return _.map(
                        rows,
                        (row): UserDefinedAggregate => {
                            const databaseName = databases[idx].databaseName;

                            // the table name column is called "Aggregates_in_${databaseName}"
                            const name = row[`Aggregates_in_${databaseName}`];

                            const functionId = genTableId({
                                databaseName,
                                tableName: name,
                            });

                            return {
                                kind: "USER_DEFINED_AGGREGATE",

                                functionId,
                                databaseName,
                                name,
                            };
                        }
                    );
                }
            );

            return [
                previousResults[0],
                previousResults[1],
                previousResults[2],
                previousResults[3],
                userDefinedAggregates,
            ];
        }
    );
};

const queryStoredProcedures = (conn: Connection) => (
    previousResults: [
        Array<SQLStructureDatabasesRow>,
        Array<SQLStructureColumnsRow>,
        Array<TableLike>,
        Array<UserDefinedFunction>,
        Array<UserDefinedAggregate>
    ]
) => {
    const databases = previousResults[0];

    const storedProcedureQueries = _.map(databases, db => ({
        sql: SQL_STRUCTURE_STORED_PROCEDURES,
        params: [SQLIdent(db.databaseName)],
    }));

    return multiSelectWithErrors<SQLStructureProceduresRow>(
        conn,
        ...storedProcedureQueries
    ).then(
        (
            resultSets
        ): [
            Array<SQLStructureDatabasesRow>,
            Array<SQLStructureColumnsRow>,
            Array<TableLike>,
            Array<UserDefinedFunction>,
            Array<UserDefinedAggregate>,
            Array<StoredProcedure>
        ] => {
            const storedProcedures = _.flatMap(
                resultSets,
                (rows, idx): Array<StoredProcedure> => {
                    if (_.isError(rows)) {
                        if (rows.code === "ER_DBACCESS_DENIED_ERROR") {
                            return [];
                        } else {
                            throw rows;
                        }
                    }

                    const storedProcedures: Array<StoredProcedure> = [];

                    _.each(
                        rows,
                        (row): Maybe<StoredProcedure> => {
                            const databaseName = databases[idx].databaseName;

                            // the table name column is called "Procedures_in_${databaseName}" or
                            // "Functions_in_${databaseName}" depending on the MemSQL version
                            const name =
                                row[`Procedures_in_${databaseName}`] ||
                                row[`Functions_in_${databaseName}`];

                            // On versions prior to 6.7.11, SHOW PROCEDURES can
                            // actually return UDFs so we need to check if the
                            // "Function Type" column exists and if it does, we
                            // need to disregard this row if its type is not
                            // "Stored Procedure".
                            if (
                                row["Function Type"] &&
                                row["Function Type"] !== "Stored Procedure"
                            ) {
                                return;
                            }

                            const functionId = genTableId({
                                databaseName,
                                tableName: name,
                            });

                            storedProcedures.push({
                                kind: "STORED_PROCEDURE",
                                databaseName,
                                definer: row.Definer,
                                name,
                                functionId,
                            });
                        }
                    );

                    return storedProcedures;
                }
            );

            return [
                previousResults[0],
                previousResults[1],
                previousResults[2],
                previousResults[3],
                previousResults[4],
                storedProcedures,
            ];
        }
    );
};

function fetchSchemaStructure(conn: Connection): Promise<SchemaStructure> {
    return multiSelect<SQLStructureDatabasesRow, SQLStructureColumnsRow>(
        conn,
        { sql: SQL_STRUCTURE_DATABASES },
        { sql: SQL_STRUCTURE_COLUMNS }
    )
        .then(queryTables(conn))
        .then(queryFunctions(conn))
        .then(queryAggregates(conn))
        .then(queryStoredProcedures(conn))
        .then(
            ([
                sqlDatabases,
                sqlColumns,
                tableLikes,
                userDefinedFunctions,
                aggregates,
                storedProcedures,
            ]): SchemaStructure => {
                const views = _.filter(
                    tableLikes,
                    (tableLike): tableLike is View => tableLike.kind === "VIEW"
                );
                const tables = _.filter(
                    tableLikes,
                    (tableLike): tableLike is Table =>
                        tableLike.kind === "TABLE"
                );

                const columns: Array<Column> = [];
                const viewColumns: Array<ViewColumn> = [];

                // Loop through columns and split view columns from regular columns.
                for (let i = 0; i < sqlColumns.length; i++) {
                    const column = sqlColumns[i];

                    const view: Maybe<View> = _.find(
                        views,
                        (view: View) =>
                            view.tableName === column.tableName &&
                            view.databaseName === column.databaseName
                    );

                    if (view) {
                        // view column

                        viewColumns.push({
                            databaseName: column.databaseName,
                            tableName: view.tableName,
                            columnName: column.columnName,
                            columnId: column.columnId,

                            kind: "VIEW_COLUMN",

                            subType: column.subType,
                            dataType: column.dataType,
                            // We cast ordinal position to number since it will never exceed 2^53.
                            ordinalPosition: column.ordinalPosition.toNumber(),

                            isNullable: column.isNullable.eq(1),
                            autoIncrement: column.autoIncrement.eq(1),
                        });
                    } else {
                        // regular column

                        const table = _.find(
                            tables,
                            (table: Table) =>
                                table.tableName === column.tableName &&
                                table.databaseName === column.databaseName
                        );

                        if (!table) {
                            // We found a column for which there is neither a
                            // view nor a table. This means that either:
                            // - this column corresponds to a table that was
                            //   deleted while the Schema Explorer was issuing
                            // - this column corresponds to a TVF function since
                            //   MemSQL will add TVF function columns to
                            //   `information_schema.columns` and TVF functions
                            //   as tables in `information_schema.tables` but
                            //   not to `SHOW TABLES IN <> EXTENDED` which is
                            //   what Studio uses to read the list of tables and
                            //   views. In either case, we want to ignore this
                            //   column and just keep going without pushing
                            //   anything into either the `columns` or
                            //   `viewColumns` arrays.

                            continue;
                        }

                        columns.push({
                            databaseName: column.databaseName,
                            tableName: column.tableName,
                            columnName: column.columnName,
                            columnId: column.columnId,

                            kind: "TABLE_COLUMN",

                            dataType: column.dataType,
                            // We cast ordinal position to number since it will never exceed 2^53.
                            ordinalPosition: column.ordinalPosition.toNumber(),
                            subType: column.subType,
                            computed: column.computed.eq(1),
                            defaultValueSQL: nullableToMaybe(
                                column.defaultValueSQL
                            ),
                            columnStorage: table.tableStorage,

                            isNullable: column.isNullable.eq(1),
                            autoIncrement: column.autoIncrement.eq(1),
                        });
                    }
                }

                return {
                    databases: _.map(
                        sqlDatabases,
                        db =>
                            ({
                                databaseName: db.databaseName,
                                kind: "DATABASE",
                            } as Database)
                    ),
                    tables: sortTables(tables),
                    views,
                    columns,
                    viewColumns,
                    aggregates,
                    userDefinedFunctions,
                    storedProcedures,
                };
            }
        );
}

export const queryStructure = makeActionCreator({
    name: "queryStructure",

    handle: (ctx: HandlerContext): Observable<StructureAction> => {
        const $loading = Observable.of<StructureAction>({
            type: "SCHEMA_STRUCTURE",
            error: false,
            payload: { loading: true },
        });

        const $compute = Observable.fromPromise(
            ctx.manager
                .getPooledConnection()
                .then(conn =>
                    fetchSchemaStructure(conn)
                        .then(createStructureAction)
                        .finally(() => conn.release())
                )
                .catch(
                    (err: Error | MysqlError): StructureAction => {
                        logError(err);

                        return {
                            type: "SCHEMA_STRUCTURE",
                            error: true,
                            payload: {
                                message: err.message,
                            },
                        };
                    }
                )
        );

        return Observable.merge($loading, $compute);
    },
});

function fetchClusterStatistics(
    conn: Connection
): Promise<ClusterStatisticsPayload> {
    return select(conn, SQL_STATISTICS_HA).then(
        (sqlClusterHA: Array<SQLStatisticsHA>) => {
            if (sqlClusterHA.length !== 1) {
                throw new Error("This query should only return one row");
            }

            return {
                haEnabled: Number(sqlClusterHA[0].Value) >= 2, // boolean
            };
        }
    );
}

const createClusterStatisticsAction = (
    data: ClusterStatisticsPayload
): ClusterStatisticsAction => {
    return {
        type: "CLUSTER_STATISTICS",
        error: false,
        payload: {
            loading: false,
            data,
        },
    };
};

export type StatisticsColumn = {
    columnId: string;
    memoryUse: Maybe<BigNumber>;
};

// See PLAT-508 for more details.
const filterIndexes = (
    sqlIndexesStats: Array<SQLStatisticsIndex>,
    sqlColumnarIndexesStats: Array<SQLStatisticsIndexColumnar>
) => {
    let indexes: { [key: string]: Index } = {};

    for (let i = 0; i < sqlIndexesStats.length; i++) {
        const index = sqlIndexesStats[i];
        const hashedIndex = [
            index.databaseName,
            index.tableName,
            index.indexName,
            index.indexType,
            index.nonUnique,
        ].join(".");

        const savedIndex = indexes[hashedIndex];

        const columnarIndex = sqlColumnarIndexesStats.filter(
            sqlColumnarIndex =>
                index.databaseName === sqlColumnarIndex.databaseName &&
                index.tableName === sqlColumnarIndex.tableName &&
                index.indexName === sqlColumnarIndex.indexName
        );

        let diskUse = undefined;
        if (columnarIndex.length > 1) {
            // This is not supposed to happen. For the combination databaseName,
            // tableName, indexName only one entrance should be found in the
            // table MV_COLUMNAR_SEGMENT_INDEX
            logError(
                new Error(
                    `Multiple entries were found for a column hash index with the following combination: databaseName, tableName, indexName`
                )
            );
        } else if (columnarIndex.length === 1) {
            diskUse = columnarIndex[0].totalSize;
        }

        if (savedIndex) {
            savedIndex.columnNames.push(index.columnName);
        } else {
            indexes[hashedIndex] = {
                kind: "INDEX",

                indexId: hashedIndex,

                databaseName: index.databaseName,
                tableName: index.tableName,
                indexName: index.indexName,

                memoryUse: index.memoryUse,
                diskUse,
                indexType: index.indexType,
                columnNames: [index.columnName],

                // if non unique is false, then unique is true
                unique: index.nonUnique.isZero(),
            };
        }
    }

    // SHARD indices can appear in information_schema.STATISTICS with the
    // same name as a different index; if this is the case, they actually
    // are just an alias for the other index, and therefore the SHARD
    // index's memory use should be 0.

    indexes = _.mapValues(
        indexes,
        (index: Index): Index => {
            if (index.indexType === "SHARD") {
                if (
                    _.find(
                        indexes,
                        (otherIndex: Index) =>
                            otherIndex.databaseName === index.databaseName &&
                            otherIndex.tableName === index.tableName &&
                            otherIndex.indexName === index.indexName &&
                            otherIndex.indexType !== index.indexType
                    )
                ) {
                    return {
                        ...index,
                        memoryUse: new BigNumber(0),
                    };
                }
            }

            return index;
        }
    );

    return _.values(indexes);
};

function createColumnarSegmentsAction(
    sqlColumnarStatistics: Array<SQLStatisticsColumnsColumnar>
): ColumnarSegmentsAction {
    return {
        type: "SCHEMA_COLUMNAR_SEGMENTS",
        error: false,
        payload: sqlColumnarStatistics,
    };
}

function queryColumnarSegments(conn: Connection) {
    return select(conn, SQL_STATISTICS_COLUMNS_COLUMNAR);
}

const createStatisticsAction = (
    [
        sqlDatabaseStats,
        sqlColumnMemoryStats,
        sqlTableMemoryStats,
        sqlIndexesStats,
        sqlColumnarIndexesStats,
    ]: [
        Array<SQLStatisticsDatabase>,
        Array<SQLStatisticsColumnsMemory>,
        Array<SQLStatisticsTable>,
        Array<SQLStatisticsIndex>,
        Array<SQLStatisticsIndexColumnar>
    ],
    startDate: Date
): StatisticsAction => {
    return {
        type: "SCHEMA_STATISTICS",
        error: false,
        payload: {
            loading: false,
            data: {
                databaseStats: sqlDatabaseStats,
                columnStats: _.map(sqlColumnMemoryStats, row => ({
                    columnId: row.columnId,
                    memoryUse:
                        row.memoryUse === null ? undefined : row.memoryUse,
                })),
                tableStats: sortTables(sqlTableMemoryStats),
                indexes: filterIndexes(
                    sqlIndexesStats,
                    sqlColumnarIndexesStats
                ),
                deltaTimeS: differenceInSeconds(new Date(), startDate),
            },
        },
    };
};

export const querySchemaStatistics = makeActionCreator({
    name: "querySchemaStatistics",

    handle(
        ctx: HandlerContext
    ): Observable<
        | StructureAction
        | StatisticsAction
        | ClusterStatisticsAction
        | ColumnarSegmentsAction
    > {
        const $loadingClusterStatistics = Observable.of<
            ClusterStatisticsAction
        >({
            type: "CLUSTER_STATISTICS",
            error: false,
            payload: { loading: true },
        });

        const $loadingSchemaStatistics = Observable.of<StatisticsAction>({
            type: "SCHEMA_STATISTICS",
            error: false,
            payload: { loading: true },
        });

        const startDate = new Date();

        // TODO Promise.all these promises
        const $compute = Observable.create((observer: Observer<unknown>) => {
            ctx.manager
                .getPooledConnection()
                .then(conn =>
                    fetchClusterStatistics(conn)
                        .then(createClusterStatisticsAction)
                        .then(out => {
                            observer.next(out);
                        })
                        .then(() => fetchSchemaStructure(conn))
                        .then(createStructureAction)
                        .then(out => {
                            // emit the structure action
                            observer.next(out);

                            // start schema statistics queries
                            return multiSelectWithErrors<
                                SQLStatisticsDatabase,
                                SQLStatisticsColumnsMemory,
                                SQLStatisticsTable,
                                SQLStatisticsIndex,
                                SQLStatisticsIndexColumnar
                            >(
                                conn,

                                { sql: SQL_STATISTICS_DATABASE },

                                { sql: SQL_STATISTICS_COLUMNS_MEMORY },

                                { sql: SQL_STATISTICS_TABLE },

                                { sql: SQL_STATISTICS_INDEXES },
                                { sql: SQL_STATISTICS_INDEXES_COLUMNAR }
                            );
                        })
                        .then(results => {
                            // Map all the results into the real result and
                            // throw an exception if an error happened. The last
                            // result SQLStatisticsIndexColumnar has a special
                            // threatment when the table doesn't exist, so we
                            // can support engines < 7.0.
                            const mappedResults: [
                                Array<SQLStatisticsDatabase>,
                                Array<SQLStatisticsColumnsMemory>,
                                Array<SQLStatisticsTable>,
                                Array<SQLStatisticsIndex>,
                                Array<SQLStatisticsIndexColumnar>
                            ] = [
                                mapMultiSelectErrorResult(results[0]),
                                mapMultiSelectErrorResult(results[1]),
                                mapMultiSelectErrorResult(results[2]),
                                mapMultiSelectErrorResult(results[3]),
                                mapMultiSelectErrorResult(results[4], error => {
                                    if (
                                        "code" in error &&
                                        error.code === "ER_NO_SUCH_TABLE"
                                    ) {
                                        return [];
                                    } else {
                                        throw error;
                                    }
                                }),
                            ];

                            return createStatisticsAction(
                                mappedResults,
                                startDate
                            );
                        })
                        .catch((err: Error | MysqlError) => {
                            logError(err);

                            observer.next({
                                type: "SCHEMA_STATISTICS",
                                error: true,
                                payload: {
                                    message: err.message,
                                },
                            });
                        })
                        .then(out => {
                            observer.next(out);

                            return queryColumnarSegments(conn);
                        })
                        .then(createColumnarSegmentsAction)
                        .then(out => {
                            observer.next(out);
                        })
                        .catch((err: Error | MysqlError) => {
                            logError(err);

                            observer.next(
                                logFocusError(
                                    `An error occured while querying information_schema.columnar_segments: ${
                                        err.message
                                    }`
                                )
                            );

                            observer.next({
                                type: "SCHEMA_COLUMNAR_SEGMENTS",
                                error: true,
                                payload: err.message,
                            });
                        })
                        .finally(() => conn.release())
                )
                .catch((err: Error | MysqlError) => {
                    logError(err);

                    observer.next({
                        type: "SCHEMA_STATISTICS",
                        error: true,
                        payload: {
                            message: err.message,
                        },
                    });
                })
                .finally(() => observer.complete());
        });

        return Observable.merge(
            $loadingClusterStatistics,
            $loadingSchemaStatistics,
            $compute
        );
    },
});

const createSchemaSummaryAction = (
    [sqlTotalMemoryUsage, sqlDatabasesDiskUsage, sqlDatabaseNames]: [
        Array<SQLTotalMemoryUsageRow>,
        Array<SQLDatabaseDiskUsageRow>,
        Array<SQLDatabaseNameRow>
    ],
    startDate: Date
): SummaryAction => {
    if (sqlTotalMemoryUsage.length !== 1) {
        throw new Error(
            "Expected query to fetch total memory usage to be a single row."
        );
    }

    let diskUsage = new BigNumber(0);
    _.forEach(sqlDatabasesDiskUsage, row => {
        diskUsage = diskUsage.plus(row.diskUsage);
    });

    const topDiskDatabases: Array<SummaryDatabaseEntry> = _(
        sqlDatabasesDiskUsage
    )
        .take(3)
        .filter(d => d.diskUsage.gt(0))
        .map(d => ({
            name: d.databaseName,
            diskUsage: d.diskUsage,
        }))
        .value();

    return {
        type: "SCHEMA_SUMMARY",
        error: false,
        payload: {
            loading: false,
            data: {
                memoryUsage: sqlTotalMemoryUsage[0].memoryUsage,
                diskUsage,
                topDiskDatabases,
                databaseNames: _.map(
                    sqlDatabaseNames,
                    ({ databaseName }) => databaseName
                ),
                deltaTimeS: differenceInSeconds(new Date(), startDate),
            },
        },
    };
};

export const querySchemaSummary = makeActionCreator({
    name: "querySchemaSummary",

    handle(ctx: HandlerContext): Observable<SummaryAction> {
        const $loadingSummary = Observable.of<SummaryAction>({
            type: "SCHEMA_SUMMARY",
            error: false,
            payload: { loading: true },
        });

        const startDate = new Date();

        const $compute = Observable.fromPromise(
            ctx.manager
                .getPooledConnection()
                .then(conn =>
                    multiSelect<
                        SQLTotalMemoryUsageRow,
                        SQLDatabaseDiskUsageRow,
                        SQLDatabaseNameRow
                    >(
                        conn,

                        { sql: SQL_TOTAL_MEMORY_USAGE },
                        { sql: SQL_DATABASE_DISK_USAGE },
                        { sql: SQL_DATABASE_NAMES }
                    )
                        .then(results =>
                            createSchemaSummaryAction(results, startDate)
                        )
                        .finally(() => conn.release())
                )
                .catch(
                    (err: Error | MysqlError): SummaryAction => {
                        logError(err);

                        return {
                            type: "SCHEMA_SUMMARY",
                            error: true,
                            payload: err.message,
                        };
                    }
                )
        );

        return Observable.merge($loadingSummary, $compute);
    },
});

export const queryTableLikeSample = makeActionCreator({
    name: "queryTableLikeSample",

    handle(
        ctx: HandlerContext,
        {
            databaseName,
            tableName,
        }: { databaseName: DatabaseName; tableName: TableName }
    ): Observable<TableLikeSampleAction> {
        const $loading = Observable.of<TableLikeSampleAction>({
            type: "TABLE_LIKE_SAMPLE",
            error: false,
            payload: { loading: true },
        });

        const $compute = Observable.fromPromise(
            ctx.manager
                .getPooledConnection()
                .then(conn => {
                    return new Promise<TableLikeSampleAction>(
                        (resolve, reject) =>
                            conn.query(
                                {
                                    sql: SQL_TABLE_LIKE_SAMPLE,
                                    nestTables: true,
                                    values: [databaseName, tableName],
                                    // We don't want to typecast the output of this query since we
                                    // just display the results as strings directly to the user.
                                    typeCast: false,
                                },
                                (
                                    error: Nullable<Error>,
                                    rows: Array<RowResult>,
                                    fields: Maybe<FieldList>
                                ) => {
                                    if (error) {
                                        return reject(error);
                                    } else if (!fields) {
                                        logError(
                                            new Error(
                                                "Got back no fields while querying for sample data from a table."
                                            )
                                        );

                                        return reject({
                                            type: "TABLE_LIKE_SAMPLE",
                                            error: true,
                                            payload: {
                                                message:
                                                    "An error occured while fetching sample data from this table.",
                                            },
                                        });
                                    } else {
                                        return resolve({
                                            type: "TABLE_LIKE_SAMPLE",
                                            error: false,
                                            payload: {
                                                loading: false,
                                                data: {
                                                    sampleRows: _.map(
                                                        rows,
                                                        row =>
                                                            rawResultToArray(
                                                                fields,
                                                                row
                                                            )
                                                    ),
                                                    fields,
                                                },
                                            },
                                        });
                                    }
                                }
                            )
                    ).finally(() => conn.release());
                })
                .catch(
                    (err: Error | MysqlError): TableLikeSampleAction => {
                        logError(err);

                        return {
                            type: "TABLE_LIKE_SAMPLE",
                            error: true,
                            payload: {
                                message: err.message,
                            },
                        };
                    }
                )
        );

        return Observable.merge($loading, $compute);
    },
});
