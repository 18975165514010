import * as React from "react";

import CenteringWrapper from "view/components/centering-wrapper";
import Icon from "view/components/icon";

import "./general-error.scss";

export type GeneralErrorProps = {
    error: string;
    errorHeader: string;
};

export default class GeneralError extends React.PureComponent<
    GeneralErrorProps
> {
    static defaultProps = {
        errorHeader: "An error occurred:",
    };

    render() {
        const { error, errorHeader } = this.props;

        return (
            <CenteringWrapper className="common-general-error">
                <div className="icon-wrapper">
                    <Icon icon="exclamation-triangle" size="4x" />
                    <div className="caption">Error</div>
                </div>
                <div className="notes">
                    <div>{errorHeader}</div>
                    <div className="error-message">{error}</div>
                </div>
            </CenteringWrapper>
        );
    }
}
