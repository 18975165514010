import { InjectedFormProps } from "redux-form";
import { DispatchFunction, ConnectAction } from "data/actions";

import * as React from "react";
import classnames from "classnames";
import { Field, reduxForm, SubmissionError } from "redux-form";

import { initConnection } from "worker/api/connection";
import Form from "view/components/form";
import FormElement from "view/components/form-element";
import Loading from "view/components/loading";
import { Alert } from "view/components/alert";
import { Button } from "view/common/button";

import { requiredFieldValidator } from "util/form";
import { getErrorMessage } from "util/handle-mysql-error";

import "./connect-to-cluster.scss";

type FormValues = {
    username: string;
    password: string;
};

type OwnProps = {
    clusterId: string;
    managedService: boolean;
};

type Props = OwnProps & InjectedFormProps<FormValues, OwnProps>;

class ConnectToClusterForm extends React.Component<Props> {
    render() {
        const { submitting, handleSubmit, error, managedService } = this.props;

        let formError;
        if (error) {
            formError = <div className="form-error">{error}</div>;
        }

        // We are extremely careful to render the loading spinner
        // **on top** of the actual form content (using CSS absolute
        // positioning) so as not to unmount the actual redux form
        // fields or the form. If we unmount the fields or the form,
        // then the form is unregistered from the Redux state and
        // we lose its state.

        const formContentClasses = classnames("form-content", { submitting });

        return (
            <Form onSubmit={handleSubmit} className="connect-to-cluster-form">
                {managedService && (
                    <Alert
                        className="form-message"
                        message="The database login credentials are not the same as your Portal login credentials. You can reset this password in the cluster's details page."
                        status="info"
                    />
                )}

                <div className={formContentClasses}>
                    {submitting && (
                        <Loading className="form-loading" size="large" />
                    )}

                    <div className="form-controls">
                        <Field
                            name="username"
                            label="Username"
                            placeholder="Enter your username"
                            type="text"
                            component={FormElement}
                            validate={requiredFieldValidator}
                            autoFocus
                        />

                        <Field
                            name="password"
                            label="Password"
                            placeholder="Enter your password"
                            type="password"
                            component={FormElement}
                        />

                        {formError}

                        <Button
                            primary
                            large
                            fullWidth
                            className="submit-btn"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </div>

                    <button
                        type="submit"
                        className="hidden-submit-button"
                        tabIndex={-1}
                    />
                </div>
            </Form>
        );
    }
}

export default reduxForm<FormValues, OwnProps>({
    onSubmit(
        values: FormValues,
        dispatch: DispatchFunction,
        props: OwnProps & InjectedFormProps<FormValues, OwnProps>
    ) {
        const { clusterId } = props;
        const { username, password } = values;

        if (!username) {
            throw new Error(
                "Expected username to be defined as the form validators should ensure that."
            );
        }

        return Promise.resolve(
            dispatch(initConnection({
                clusterId,
                user: username,
                password,
                kind: "CONNECT_CONFIG",
            }) as ConnectAction)
        ).then((action: ConnectAction) => {
            if (action.error) {
                throw new SubmissionError({
                    _error: getErrorMessage(
                        action.payload.code,
                        action.payload.message
                    ),
                });
            } else {
                return action;
            }
        });
    },
})(ConnectToClusterForm);
