import { GeneralTableColumn } from "view/components/general-table";

import { MemsqlEvent } from "data/models";

import * as React from "react";

import Tip from "view/components/tip";

import format from "date-fns/format";
import getTime from "date-fns/get_time";

import "./columns-info.scss";

const EVENT_COLUMNS: Array<GeneralTableColumn<MemsqlEvent>> = [
    {
        id: "eventTime",
        title: "Time",
        formatter: (event: MemsqlEvent) =>
            format(event.eventTime, "YYYY/MM/DD HH:mm:ss"),
        getValue: (event: MemsqlEvent) => getTime(event.eventTime),
    },
    {
        id: "eventSeverity",
        title: "Event Level",
        formatter: (event: MemsqlEvent) => event.eventSeverity,
        getValue: (event: MemsqlEvent) => event.eventSeverity,
    },
    {
        id: "eventName",
        title: "Event Name",
        formatter: (event: MemsqlEvent) => event.eventType,
        getValue: (event: MemsqlEvent) => event.eventType,
    },
    {
        id: "eventDetails",
        title: "Details",
        formatter: (event: MemsqlEvent) => (
            <Tip
                tooltip={
                    <pre className="event-details-tooltip">
                        {JSON.stringify(event.eventDetails, null, 4)}
                    </pre>
                }
                direction="w"
                className="event-details"
            >
                {JSON.stringify(event.eventDetails)}
            </Tip>
        ),
        getValue: (event: MemsqlEvent) => JSON.stringify(event.eventDetails),
    },
];

export default EVENT_COLUMNS;
