import { Observable } from "rxjs";
import { State } from "data/reducers";

export default (state$: Observable<State>) => {
    if (process.env.NODE_ENV === "development") {
        return;
    }

    const didChangeConnectionStatus$ = state$
        .map((state: State) => state.connection.state.connected)
        .distinctUntilChanged();

    // https://stackoverflow.com/questions/1119289/how-to-show-the-are-you-sure-you-want-to-navigate-away-from-this-page-when-ch/1119324#1119324
    didChangeConnectionStatus$.subscribe(
        (connected: boolean) =>
            (window.onbeforeunload = connected ? () => true : null)
    );
};
