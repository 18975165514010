export type NodeID = number;

export type TreeNode<T> = {
    data: T;
    children: Array<NodeID>;
};

export type Tree<T> = {
    nodes: { [id: number]: TreeNode<T> };
    root: NodeID;
};

export function NewTree<T>(
    rootId: NodeID,
    nodes: { [id: number]: TreeNode<T> }
): Tree<T> {
    return {
        root: rootId,
        nodes,
    };
}

// Clients of `visit` should not rely on the visit order.
// It is implemented as a DFS right now but it may change.
export function visit<T>(
    tree: Tree<T>,
    cb: (id: NodeID, node: TreeNode<T>) => void
) {
    // This is a stack (LIFO) where we keep the IDs
    // of the nodes that we have yet to visit.
    const toVisit: Array<NodeID> = [];

    toVisit.push(tree.root);

    while (toVisit.length > 0) {
        // We know nextNodeId exists because we only pop if the queue has any elements.
        const nextNodeId = toVisit.pop() as number;
        const nextNode = tree.nodes[nextNodeId];

        cb(nextNodeId, nextNode);

        for (let i = 0; i < nextNode.children.length; i++) {
            toVisit.push(nextNode.children[i]);
        }
    }
}

// Clients of `mapTree` cannot modify the structure of the tree.
// This function's purpose is to create a new tree with the same
// structure as the input tree.
export function mapTree<T>(
    tree: Tree<T>,
    mapper: (nodeId: NodeID, node: TreeNode<T>) => TreeNode<T>
): Tree<T> {
    const newTree = NewTree(tree.root, tree.nodes);

    visit(newTree, (nodeId, node) => {
        newTree.nodes[nodeId] = mapper(nodeId, node);
    });

    return newTree;
}
