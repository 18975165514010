import { ActiveProcessesColumn } from "view/active-processes/active-processes-table";
import { ButtonClickEvent } from "view/common/button";

import * as React from "react";

import LabeledInlineBar from "view/components/labeled-inline-bar";
import InternalLink from "view/components/internal-link";
import Tip from "view/components/tip";
import CopyToClipboardToast from "view/components/copy-to-clipboard-toast";
import { Button } from "view/common/button";

import { formatNodeAddress } from "data/models";
import NumberFormatter from "util/number-formatter";
import { COLORS } from "util/colors";
import { LONG_EM_DASH } from "util/symbols";
import format from "date-fns/format";
import { capitalize } from "humanize-plus";

import "./columns-info.scss";

export const ACTIVE_PROCESSES_COLUMNS: Array<ActiveProcessesColumn> = [
    {
        id: "queryText",
        title: "Query Text",
        defaultMinWidth: 270,
        formatter: (_ctx, process) => {
            const { queryText } = process;

            if (queryText) {
                return (
                    <Tip
                        direction="se"
                        className="active-process-query"
                        tooltip={
                            <div className="active-process-tooltip">
                                {queryText}
                            </div>
                        }
                    >
                        <div className="query">{queryText}</div>
                        <CopyToClipboardToast toastMessage="Copied query to clipboard.">
                            {copy => (
                                <Button
                                    ghost
                                    className="button"
                                    icon="copy"
                                    iconType="regular"
                                    marginLeft
                                    onClick={(e: ButtonClickEvent) => {
                                        // stop propagation to the cell click
                                        // event
                                        e.stopPropagation();
                                        copy(queryText);
                                    }}
                                />
                            )}
                        </CopyToClipboardToast>
                    </Tip>
                );
            } else {
                return LONG_EM_DASH;
            }
        },
        getValue: process => process.queryText || "",
    },
    {
        id: "processId",
        title: "Process ID",
        defaultMinWidth: 100,
        formatter: (_ctx, process) => process.processId.toString(),
        getValue: process => process.processId.toNumber(), // PLAT-3189
    },
    {
        id: "elapsedTime",
        title: "Time",
        defaultMinWidth: 100,
        formatter: (ctx, process) => {
            const value = ctx.maxTime ? process.elapsedTime / ctx.maxTime : 0;

            return (
                <LabeledInlineBar
                    label={NumberFormatter.formatDuration(
                        process.elapsedTime * 1000
                    )}
                    entries={[
                        {
                            value,
                            fill: COLORS["color-indigo-600"],
                        },
                        {
                            value: 1 - value,
                            fill: COLORS["color-neutral-200"],
                        },
                    ]}
                    labelPosition="top"
                />
            );
        },
        getValue: process => process.elapsedTime,
    },
    {
        id: "status",
        title: "Status",
        defaultMinWidth: 100,
        formatter: (_ctx, process) =>
            process.state ? capitalize(process.state) : LONG_EM_DASH,
        getValue: process => process.state || "",
    },
    {
        id: "user",
        title: "User",
        defaultMinWidth: 110, // fit the common user "distributed"
        formatter: (_ctx, process) => process.user || LONG_EM_DASH,
        getValue: process => process.user || "",
    },
    {
        id: "database",
        title: "Database",
        defaultMinWidth: 100,
        formatter: (_ctx, process) => {
            const { databaseName } = process;

            if (databaseName) {
                return (
                    <InternalLink
                        routeInfo={{
                            name: "cluster.databases.tables",
                            params: { databaseName },
                        }}
                        category="schema-explorer"
                        clusterLink
                    >
                        {databaseName}
                    </InternalLink>
                );
            } else {
                return LONG_EM_DASH;
            }
        },
        getValue: process => process.databaseName || "",
    },
    {
        id: "submitted",
        title: "Submitted",
        defaultMinWidth: 100,
        formatter: (_ctx, process) => (
            <div className="active-process-submitted">
                {format(process.submitted, "YYYY/MM/DD HH:mm:ss")}
            </div>
        ),
        getValue: process => process.submitted.toString(),
    },
    {
        id: "runningOn",
        title: "Running On",
        defaultMinWidth: 110, // fit the column header
        formatter: (_ctx, process) => {
            const nodeAddress = formatNodeAddress({
                host: process.nodeHost,
                port: process.nodePort,
            });

            return (
                <InternalLink
                    routeInfo={{
                        name: "cluster.nodes.node",
                        params: { nodeAddress },
                    }}
                    category="schema-explorer"
                    clusterLink
                >
                    {nodeAddress}
                </InternalLink>
            );
        },
        getValue: process =>
            formatNodeAddress({
                host: process.nodeHost,
                port: process.nodePort,
            }),
    },
];
