
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import TutorialLoadSQLButton from "view/tutorial/tutorial-load-sql-button";
import { 
    SQL_TPCH_PRICING_QUERY,
    SQL_TPCH_SHIPPING_QUERY,
    SQL_TPCH_RETURNED_QUERY,
    SQL_TPCH_PROFIT_QUERY,
} from "./tpch-queries";
import ExtLink from "view/components/external-link";

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>

<MDXTag name="h4" components={components}>{`Step 4: Run Several Queries on Your Data`}</MDXTag>
<br />
<MDXTag name="h5" components={components}>{`Query 1: Pricing Summary Report`}</MDXTag>
<MDXTag name="p" components={components}>{`This query reports the amount of business that was billed, shipped, and returned within 60 - 120 days of the greatest ship data contained in the database.`}</MDXTag>
<TutorialLoadSQLButton id="tpch-pricing-query" query={SQL_TPCH_PRICING_QUERY} text="Paste Query 1" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`You can run the query again and observe the execution time difference. You will see a dramatic improvement in query execution time. This is due to MemSQL’s `}<ExtLink name="code-generation" category="tutorial">{`Code Generation technology`}</ExtLink>{`.`}</MDXTag></MDXTag>
<MDXTag name="h5" components={components}>{`Query 2: Shipping Priority`}</MDXTag>
<MDXTag name="p" components={components}>{`This query retrieves the 10 unshipped orders with the highest value.`}</MDXTag>
<TutorialLoadSQLButton id="tpch-shipping-query" query={SQL_TPCH_SHIPPING_QUERY} text="Paste Query 2" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
<MDXTag name="h5" components={components}>{`Query 3: Returned Item Reporting`}</MDXTag>
<MDXTag name="p" components={components}>{`This query identifies top 20 customers who might be having problems with the parts that are shipped to them.`}</MDXTag>
<TutorialLoadSQLButton id="tpch-returned-query" query={SQL_TPCH_RETURNED_QUERY} text="Paste Query 3" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
<MDXTag name="h5" components={components}>{`Query 4: Product Type Profit Measure`}</MDXTag>
<MDXTag name="p" components={components}>{`This query determines how much profit is made on a given line of parts, broken out by supplier nation and year.`}</MDXTag>
<TutorialLoadSQLButton id="tpch-profit-query" query={SQL_TPCH_PROFIT_QUERY} text="Paste Query 4" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
<MDXTag name="p" components={components}>{`Want more queries? `}<ExtLink name="tpch-benchmark" category="tutorial">{`Click here`}</ExtLink>{` to get a full set of the 22 queries for a complete TPC-H workload.`}</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  