import { TableSort } from "util/sort";
import { Action, SimpleAction } from "./types";
import { Loading } from "util/loading-state-machine";
import { DispatchFunction } from "data";
import { FieldList } from "mysqljs";

import {
    Index,
    ResourceUsageSummary,
    SchemaStructure,
    SchemaEntityKind,
} from "data/models";

import {
    SQLStatisticsDatabase,
    SQLStatisticsTable,
    SQLStatisticsColumnsColumnar,
} from "worker/api/schema-queries";

import { StatisticsColumn } from "worker/api/schema";

import { SchemaFullPayload } from "util/schema/data";

import {
    buildClusterStatisticsAction,
    buildMemsqlPartitionsAction,
} from "data/actions/cluster-metadata";
import { buildTopologyAction } from "data/actions/topology";

export type StructureAction = Action<
    "SCHEMA_STRUCTURE",
    Loading<SchemaStructure>,
    { message: string }
>;

export type StatisticsPayload = {
    databaseStats: Array<SQLStatisticsDatabase>;
    columnStats: Array<StatisticsColumn>;
    tableStats: Array<SQLStatisticsTable>;
    indexes: Array<Index>;
    deltaTimeS: number;
};

export type StatisticsAction = Action<
    "SCHEMA_STATISTICS",
    Loading<StatisticsPayload>,
    { message: string }
>;

type ColumnarSegmentsPayload = Array<SQLStatisticsColumnsColumnar>;

export type ColumnarSegmentsAction = Action<
    "SCHEMA_COLUMNAR_SEGMENTS",
    ColumnarSegmentsPayload,
    string
>;

// This is the action that the Dashboard's Resource
// Usage card depends on.
export type SummaryAction = Action<
    "SCHEMA_SUMMARY",
    Loading<ResourceUsageSummary>,
    string
>;

export type SchemaSortPayload = {
    sort: TableSort;
    entityKind: SchemaEntityKind;
};

export type SchemaSortAction = SimpleAction<"SCHEMA_SORT", SchemaSortPayload>;

export const schemaSort = (payload: SchemaSortPayload): SchemaSortAction => ({
    type: "SCHEMA_SORT",
    error: false,
    payload,
});

export type SchemaRestoreAction = SimpleAction<
    "SCHEMA_RESTORE",
    SchemaFullPayload
>;

// This method restores the entire state in the Schema Explorer
// based on a given SchemaFullPayload object. This action is just
// a thunk that emits 3 different actions that the Schema Explorer
// state depends on.
export const schemaRestore = (payload: SchemaFullPayload) => {
    return (dispatch: DispatchFunction) => {
        dispatch({
            type: "SCHEMA_RESTORE",
            error: false,
            payload,
        });

        dispatch(buildClusterStatisticsAction(payload.clusterStatistics));

        dispatch(buildTopologyAction(payload.topology));

        dispatch(buildMemsqlPartitionsAction(payload.partitions));
    };
};

export type TableLikeSamplePayload = {
    sampleRows: Array<Array<string>>;
    fields: FieldList;
};

export type TableLikeSampleAction = Action<
    "TABLE_LIKE_SAMPLE",
    Loading<TableLikeSamplePayload>,
    { message: string }
>;

export type ClearTableLikeSampleAction = SimpleAction<
    "CLEAR_TABLE_LIKE_SAMPLE",
    {}
>;

export const clearTableLikeSample = (): ClearTableLikeSampleAction => ({
    type: "CLEAR_TABLE_LIKE_SAMPLE",
    error: false,
    payload: Object.freeze({}),
});
