import { Vector } from "util/vector";

import { NewVector, add, subtract, scale } from "util/vector";

export type Rectangle = {
    position: Vector;
    dimensions: Vector;
};

export const center = ({ position, dimensions }: Rectangle): Vector =>
    add(position, scale(0.5, dimensions));

export const centerTop = ({
    position,
    dimensions: { x: width },
}: Rectangle): Vector => add(position, { x: width / 2, y: 0 });

export const centerBottom = ({
    position,
    dimensions: { x: width, y: height },
}: Rectangle): Vector => add(position, { x: width / 2, y: height });

// Construct the rectangle between the given top-left corner and bottom-right
// corner.
export const rectangleBetweenCorners = (
    { x: x1, y: y1 }: Vector,
    { x: x2, y: y2 }: Vector
) => ({
    position: { x: x1, y: y1 },
    dimensions: { x: x2 - x1, y: y2 - y1 },
});

// Convert the vector to a relative coordinate space defined by the rectangle,
// where the rectangle's top-left corner is (0, 0) and its bottom-right corner
// is (1, 1). This vector is in a very different coordinate space, so it
// shouldn't be used like other vectors! That's why we're trying not to export
// it.
const toRelative = ({ position, dimensions }: Rectangle, vec: Vector) => {
    const { x, y } = subtract(vec, position);
    const { x: width, y: height } = dimensions;
    return NewVector(x / width, y / height);
};

// Invert the above operation.
const fromRelative = (
    { position, dimensions }: Rectangle,
    { x, y }: Vector
) => {
    const { x: width, y: height } = dimensions;
    return add(position, NewVector(x * width, y * height));
};

// Given a source rectangle, a target rectangle, and a vector V, find the vector
// that would be in the same relative position in the target rectangle as V is
// in the source rectangle.
export const changeRelativeBasis = (
    sourceRectangle: Rectangle,
    targetRectangle: Rectangle,
    v: Vector
) => {
    return fromRelative(targetRectangle, toRelative(sourceRectangle, v));
};

// Test if two rectangles intersect, including intersecting only at an edge or a
// corner. (Assumes that the rectangles have nonnegative widths and heights.)
export const intersect = (r1: Rectangle, r2: Rectangle): boolean => {
    const {
        position: { x: x1, y: y1 },
        dimensions: { x: w1, y: h1 },
    } = r1;
    const {
        position: { x: x2, y: y2 },
        dimensions: { x: w2, y: h2 },
    } = r2;
    return x1 + w1 >= x2 && x2 + w2 >= x1 && y1 + h1 >= y2 && y2 + h2 >= y1;
};
