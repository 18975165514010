import _ from "lodash";

import { TOC } from "view/tutorial/content/toc";

export type TocItemNode = TocNode & {
    description: string;
    icon: string;
};

type BaseTocNode = { id: string };

// This node only renders a component
type ContentTocNode = BaseTocNode & {
    breadcrumb?: string;
    title?: string;
    component: JSX.Element;
};

// This node renders a component and a list of items below it
type MenuTocNode = BaseTocNode & {
    breadcrumb: string;
    title: React.ReactNode;
    component: JSX.Element;
    items: Array<TocItemNode>;
};

// This node is the parent node for stepper nodes
type StepTocNode = BaseTocNode & {
    breadcrumb: string;
    title: React.ReactNode;
    steps: Array<ContentTocNode>;
};

export type TocNode = MenuTocNode | ContentTocNode | StepTocNode;

const findChildInNode = (node: TocNode, id: string): TocNode => {
    // Only `MenuTocNode` type of nodes have searchable childs (using the
    // `items` key)
    if (!("items" in node)) {
        throw new Error(`Invalid node to search for child nodes - ${node.id}`);
    }

    // Find child node in a `MenuTocNode`
    const childNode = _.find(node.items, { id });

    if (!childNode) {
        throw new Error(
            `Could not find "${id}" child node in "${node.id}" node.`
        );
    }

    return childNode;
};

// This function throws an error when the location is an empty array or if we're
// passed an invalid location (i.e., no node with id matching a string in
// location array)
export function getNodeFromLocation(location: Array<string>): TocNode {
    const rootNode = TOC;

    if (location.length === 0) {
        throw new Error("Empty tutorial location.");
    } else if (location.length === 1) {
        // Means we are starting the tutorial
        return rootNode;
    }

    let currNode = rootNode;
    for (let i = 1; i < location.length; i++) {
        currNode = findChildInNode(currNode, location[i]);
    }

    return currNode;
}

// This function throws an error when the location is an empty array or if we're
// passed an invalid location (i.e., no node with id matching a string in
// location array)
export function getBreadcrumbs(location: Array<string>): Array<string> {
    const breadcrumbs = [];

    for (let i = 0; i < location.length; i++) {
        const tocNode = getNodeFromLocation(location.slice(0, i + 1));

        if (tocNode.breadcrumb) {
            breadcrumbs.push(tocNode.breadcrumb);
        }
    }

    return breadcrumbs;
}
