import * as React from "react";

import "./schema-view.scss";

type Props = {
    children: React.ReactNode;
};

export default class SchemaView extends React.Component<Props> {
    render() {
        const { children } = this.props;

        return <div className="schema-view">{children}</div>;
    }
}
