import {
    ClustersAction,
    AddClusterErrorAction,
    DeleteClusterAction,
    ConnectAction,
} from "data/actions";

import { makeActionCreator } from "worker/api/helpers";
import backend from "worker/net/backend";

import { MysqlError } from "mysqljs";
import { Cluster, ClusterId } from "data/models";
import { HandlerContext } from "worker/api";
import { ClusterRequest } from "worker/net/backend";

import { initConnection } from "worker/api/connection";
import { logError } from "util/logging";

export const getClusters = makeActionCreator({
    name: "getClusters",
    handle(): Promise<ClustersAction> {
        return backend
            .getClusters()
            .then(
                (data): ClustersAction => ({
                    type: "CLUSTERS",
                    error: false,
                    payload: {
                        loading: false,
                        data,
                    },
                })
            )
            .catch(
                (err: Error): ClustersAction => {
                    logError(err);

                    return {
                        type: "CLUSTERS",
                        error: true,
                        payload: err.message,
                    };
                }
            );
    },
});

export const addCluster = makeActionCreator({
    name: "addCluster",
    handle(
        ctx: HandlerContext,
        clusterObj: ClusterRequest
    ): Promise<ConnectAction | AddClusterErrorAction> {
        return ctx.manager
            .testConnect({
                kind: "TEST_CONNECT_CONFIG",
                hostname: clusterObj.hostname,
                port: clusterObj.port,
                user: clusterObj.username,
                password: clusterObj.password,
            })
            .then(() => backend.addCluster(clusterObj))
            .then(
                (cluster: Cluster) =>
                    initConnection.handler.handle(ctx, {
                        kind: "CONNECT_CONFIG",
                        clusterId: cluster.id,
                        user: clusterObj.username,
                        password: clusterObj.password,
                    }) as Promise<ConnectAction>
            )
            .catch(
                (err: MysqlError | Error): AddClusterErrorAction => {
                    logError(err);

                    let payload;
                    if ("code" in err) {
                        payload = {
                            code: err.code,
                            message: err.toString(),
                        };
                    } else {
                        payload = {
                            message: err.toString(),
                        };
                    }

                    return {
                        type: "CLUSTERS_ADD_ERROR",
                        error: true,
                        payload,
                    };
                }
            );
    },
});

export const deleteCluster = makeActionCreator({
    name: "deleteCluster",
    handle(
        ctx: HandlerContext,
        clusterId: ClusterId
    ): Promise<DeleteClusterAction> {
        return backend
            .deleteCluster(clusterId)
            .then(
                (): DeleteClusterAction => ({
                    type: "CLUSTERS_DELETE",
                    error: false,
                    payload: clusterId,
                })
            )
            .catch(
                (err: Error): DeleteClusterAction => {
                    logError(err);

                    return {
                        type: "CLUSTERS_DELETE",
                        error: true,
                        payload: err.message,
                    };
                }
            );
    },
});
