import { ClusterId } from "data/models";
import { Maybe } from "util/maybe";
import { NotificationsConfig } from "view/common/notification-manager/types";

import _ from "lodash";

export type ClusterConfig = {
    autoLogin?: {
        username: string;
        password: "";
    };
    queryEditor: {
        buffer: string;
    };
};

export type StudioConfig = {
    clusters: { [id in ClusterId]: Maybe<ClusterConfig> };
    notificationManager: NotificationsConfig;
    tutorialState: { closed: boolean; minimized: boolean };
};

export const STUDIO_CONFIG_KEY = "studioConfig";

export const DEFAULT_STUDIO_CONFIG: StudioConfig = {
    clusters: Object.freeze({}),
    notificationManager: Object.freeze({}),
    tutorialState: { closed: false, minimized: false },
};

export const readStudioConfig = (): StudioConfig => {
    try {
        const configString = window.localStorage.getItem(STUDIO_CONFIG_KEY);

        if (configString) {
            const config = JSON.parse(configString);

            let data = DEFAULT_STUDIO_CONFIG;

            // Build the StudioConfig object (we do a basic sanity check that
            // each key looks like what we expect)
            if (config) {
                if (_.isObject(config.clusters)) {
                    data = { ...data, clusters: config.clusters };
                }

                if (_.isObject(config.notificationManager)) {
                    data = {
                        ...data,
                        notificationManager: config.notificationManager,
                    };
                }

                if (_.isObject(config.tutorialState)) {
                    data = {
                        ...data,
                        tutorialState: config.tutorialState,
                    };
                }
            }

            return data;
        }
    } catch (error) {
        // could be caused by JSON failing to parse, or user configuration to
        // deny localStorage access
        console.error(error);
    }

    return DEFAULT_STUDIO_CONFIG;
};

// Similar to the setState of React. Receives a function that returns a
// new StudioConfig partial to update the local storage config
export const setStudioConfig = (
    updater: (oldConfig: StudioConfig) => Partial<StudioConfig>
): StudioConfig => {
    const oldConfig: StudioConfig = readStudioConfig();
    const updatedConfig: StudioConfig = {
        ...oldConfig,
        ...updater(oldConfig),
    };

    try {
        window.localStorage.setItem(
            STUDIO_CONFIG_KEY,
            JSON.stringify(updatedConfig)
        );
    } catch (error) {
        // could be caused by user configuration to deny localStorage access
        console.error(error);
        // if the update of localstorage fails, let's return the current (old) configuration
        return oldConfig;
    }

    return updatedConfig;
};
