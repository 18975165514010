import {
    NotificationsConfig,
    NotificationID,
    NotificationPayload,
} from "view/common/notification-manager/types";

import * as React from "react";
import _ from "lodash";

import NotificationRenderer from "view/common/notification-manager/renderer";

import { readStudioConfig, setStudioConfig } from "util/studio-config";

export type State = {
    storage: NotificationsConfig;
};

// Wrapper for NotificationRenderer that holds the
// `localStorage.notificationsManager` value as state.
export default class NotificationsStorageContainer<
    I extends NotificationID,
    P extends NotificationPayload<I>
> extends React.Component<{}, State> {
    state: State = {
        storage: readStudioConfig().notificationManager,
    };

    writeLocalStorage = (id: I, value: P) => {
        const newStudioConfig = setStudioConfig(oldConfig => {
            const storage = {
                ...oldConfig.notificationManager,
                [id]: value,
            };

            return {
                notificationManager: storage,
            };
        });

        this.setState({ storage: newStudioConfig.notificationManager });
    };

    render() {
        return (
            <NotificationRenderer
                storage={this.state.storage}
                writeLocalStorage={this.writeLocalStorage}
            />
        );
    }
}
