import { Maybe } from "util/maybe";

import * as React from "react";
import classnames from "classnames";

import { CustomScrollbar } from "view/components/custom-scrollbar";
import Portal from "view/components/portal";
import Condition from "view/components/condition";
import Icon from "view/components/icon";
import ResizeDetector from "view/components/resize-detector";

import Key from "util/key";

import "./modal.scss";

type Props = {
    className?: string;
    viewClassName?: string;

    // The contents of the modal
    children: React.ReactNode;

    // Whether or not to render the Modal.
    active?: boolean;

    // If onClose is defined:
    // * the modal will render a close button in the top
    //   right and set this function as it's click handler.
    // * the modal can be closed when clicked on the surrounding overlay
    onClose?: () => void;

    // The contents of the modal's action bar
    actions?: React.ReactNode;

    // If `true`, the component will be rendered in the same DOM tree position
    // as it is inserted in. If false, this will be rendered normally in the
    // main `body` Portal.
    //
    // Defaults to `false`.
    inline: boolean;

    // Whether to apply padding to the inner content or not.
    innerContentPadding: boolean;
};

type State = {
    height: Maybe<number>;
};

// This component is rendered in absolute positioning so you might want
// to wrap it within a position relative element.
export default class Modal extends React.Component<Props, State> {
    $wrapper: Maybe<HTMLElement>;

    static defaultProps = {
        inline: false,
        innerContentPadding: false,
    };

    state: State = {
        height: undefined,
    };

    handleKeyDown = (evt: React.KeyboardEvent) => {
        const { onClose } = this.props;

        if (evt.keyCode === Key.ESC && onClose) {
            evt.preventDefault();
            onClose();
        }
    };

    handleClickWrapper = (evt: React.MouseEvent) => {
        // Ignore secondary mouse click.
        // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
        if (evt.button === 2) {
            return;
        }

        if (this.props.onClose) {
            if (evt.target === this.$wrapper) {
                this.props.onClose();
            }
        }
    };

    render() {
        const {
            className,
            viewClassName,
            children,
            onClose,
            active,
            actions,
            inline,
            innerContentPadding,
        } = this.props;

        const { height } = this.state;

        if (!active) {
            return null;
        }

        const classes = classnames(
            {
                "components-modal": true,
            },
            className
        );

        // The reason the following two event listeners are "onMouseDown" is
        // because mousedown triggers blur on form events inside a Modal which
        // means that the user will see a "Field is required" flash if they
        // didn't fill one of the fields. Using onMouseDown on every Modal-close
        // event prevent this behavior.

        const viewClasses = classnames(viewClassName, "modal-content-inner", {
            "with-padding": innerContentPadding,
        });

        const maxModalHeight = height && 0.8 * height;

        const modal = (
            <div
                ref={el => {
                    this.$wrapper = el || undefined;
                }}
                className={classes}
                onKeyDown={this.handleKeyDown}
                onMouseDown={this.handleClickWrapper}
                role="dialog"
            >
                <ResizeDetector
                    onResize={(__, height) => {
                        this.setState({
                            height,
                        });
                    }}
                />

                <div className="modal-inner">
                    <Condition check={!!onClose}>
                        <div onMouseDown={onClose} className="close-button">
                            <Icon icon="times" />
                        </div>
                    </Condition>

                    <CustomScrollbar
                        autoHeight
                        autoHeightMin={200}
                        autoHeightMax={maxModalHeight}
                    >
                        <div className={viewClasses}>
                            <div className="children">{children}</div>

                            {actions && (
                                <div className="actions">{actions}</div>
                            )}
                        </div>
                    </CustomScrollbar>
                </div>
            </div>
        );

        if (inline) {
            return modal;
        }

        return <Portal active>{modal}</Portal>;
    }
}
