import { TipProps } from "view/components/tip";
import { RouteInfo } from "router/types";
import { ExternalLinkInfo } from "view/components/external-link";

import _ from "lodash";
import * as React from "react";
import classnames from "classnames";

import Card from "view/components/card";
import Tip from "view/components/tip";
import InternalLink from "view/components/internal-link";
import ExtLink from "view/components/external-link";
import { DropdownContext } from "view/components/dropdown";

import "./menu.scss";

type MenuProps = {
    children: React.ReactNode;
    className?: string;
    small?: boolean;
};

export const Menu = ({ children, className, small }: MenuProps) => (
    <Card className={classnames("components-menu", className, { small })}>
        {children}
    </Card>
);

type MenuItemOnClickAction = ((evt: React.MouseEvent<HTMLDivElement>) => void);

type MenuItemInternalLinkAction = {
    kind: "internal-link";
    routeInfo: RouteInfo;
    category?: string;
};

export type MenuItemExternalLinkAction = {
    kind: "external-link";
} & ExternalLinkInfo;

type MenuItemProps = {
    className?: string;
    action:
        | MenuItemOnClickAction
        | MenuItemInternalLinkAction
        | MenuItemExternalLinkAction;
    tipProps?: Partial<TipProps> & { tooltip: string };
    children: React.ReactNode;
    disabled?: boolean;
    onPostClick?: () => void;
};

const MenuItemInternal = (props: MenuItemProps) => {
    const { children, className, action, disabled, onPostClick } = props;

    const classes = classnames("components-menu-item", { disabled }, className);

    let content;
    if ("kind" in action && action.kind === "internal-link") {
        content = (
            <InternalLink
                routeInfo={action.routeInfo}
                category={action.category}
                className={classes}
                onClick={onPostClick}
            >
                {children}
            </InternalLink>
        );
    } else if ("kind" in action && action.kind === "external-link") {
        content = (
            <ExtLink
                className={classes}
                category={action.category}
                name={action.name}
                onClick={onPostClick}
            >
                {children}
            </ExtLink>
        );
    } else {
        let handleClick;
        if (!disabled) {
            handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
                action(event);
                onPostClick && onPostClick();
            };
        }

        content = (
            <div className={classes} onClick={handleClick} role="button">
                {children}
            </div>
        );
    }

    if (props.tipProps) {
        return (
            <Tip {...props.tipProps} className="tip-overlay">
                {content}
            </Tip>
        );
    } else {
        return content;
    }
};

export const MenuItem = (props: MenuItemProps) => {
    return (
        <DropdownContext.Consumer>
            {({ close }) => (
                <MenuItemInternal onPostClick={close || _.noop} {...props} />
            )}
        </DropdownContext.Consumer>
    );
};

export const MenuSeparator = () => {
    return <hr className="components-menu-separator" />;
};
