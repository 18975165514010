import { SimpleAction } from "./types";
import { LogMessage, LogMessageLevel } from "data/reducers/log";
import { TabTitle } from "data/reducers/bottom-panel";

export type LogMessageAction = SimpleAction<"LOG_MESSAGE", LogMessage>;

const logMsgCreator = (level: LogMessageLevel) => {
    return (msg: string, focusTab?: TabTitle): LogMessageAction => ({
        type: "LOG_MESSAGE",
        error: false,
        payload: {
            msg,
            level,
            timestamp: new Date(),
            focusTab,
        },
    });
};

export const logDebug = logMsgCreator("debug");
export const logInfo = logMsgCreator("info");
export const logWarn = logMsgCreator("warn");
const logError = logMsgCreator("error");

export const logSilentError = logError;
export const logFocusError = (msg: string) => logError(msg, "Log");
export const logFocusConnectionError = (msg: string) =>
    logError(msg, "Connection");
