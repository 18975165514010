// Helper type to use across the app
export type Level = "success" | "warning" | "error";

const LevelMap: { [k in Level]: number } = {
    success: 1,
    warning: 2,
    error: 3,
};

export const getWorseLevel = (level1: Level, level2: Level): Level =>
    LevelMap[level1] > LevelMap[level2] ? level1 : level2;
