import { ExecutorInstance } from "data/models";
import { ZoomLevel, LayoutSpacing } from "data/explain/layout";

import { Vector } from "util/vector";

import { NewVector } from "util/vector";

// Fraction of the height of a full node taken up by a table scan; also fraction
// by which height shrinks without a table scan.
export const TABLE_SCAN_FRACTION = 0.25;

export const executorInstanceToDimensions = (
    data: ExecutorInstance,
    zoomLevel: ZoomLevel
): Vector => {
    const isTableScan = data.meta.tableName && data.meta.isTerminal;

    const heightMultiplier = isTableScan ? 1 : 1 - TABLE_SCAN_FRACTION;

    switch (zoomLevel) {
        case "small":
            return NewVector(76, 40 * heightMultiplier);

        case "medium":
            return NewVector(152, 80 * heightMultiplier);

        case "large":
            return NewVector(228, 120 * heightMultiplier);
    }

    throw new Error("Expected size to be exhaustive");
};

export const getSpacing = (zoomLevel: ZoomLevel): LayoutSpacing => {
    switch (zoomLevel) {
        case "small":
            return {
                singleEdgeHeight: 6,
                bracketHeight: 12,
                bracketYOffset: 6,
                horizontalGap: 12,
            };

        case "medium":
            return {
                singleEdgeHeight: 6,
                bracketHeight: 12,
                bracketYOffset: 6,
                horizontalGap: 16,
            };

        case "large":
            return {
                singleEdgeHeight: 10,
                bracketHeight: 20,
                bracketYOffset: 10,
                horizontalGap: 20,
            };
    }

    throw new Error("Expected size to be exhaustive");
};
