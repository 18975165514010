import {
    MvStatisticsColumn,
    MvNode,
    MvActivityLowLevel,
    MvActivityHighLevel,
    MvWithStats,
} from "data/models";
import { Omit } from "util/omit";

import { MvColumn, MvContext } from "view/monitor/mv-table";

import * as React from "react";
import _ from "lodash";
import classnames from "classnames";
import BigNumber from "vendor/bignumber.js/bignumber";

import Stat from "util/stat";
import NumberFormatter from "util/number-formatter";
import { COLORS } from "util/colors";
import { humanizeNodeRole, abbreviateNodeRole } from "data/models";

import Tip from "view/components/tip";

import "./mv-column-info.scss";

export type MvStatisticsColumnInfo = MvColumn<MvWithStats> & {
    category: string;
    advanced: boolean;
};

type BaseStatisticsColumnInfo = Omit<
    MvStatisticsColumnInfo,
    "getValue" | "formatter"
>;

const defineStatsColumn = (
    column: BaseStatisticsColumnInfo,
    getValue: (stat: Stat) => number,
    formatter: (num: number) => string
): MvStatisticsColumnInfo => ({
    ...column,
    // PLAT-3189
    getValue: (r: MvWithStats) =>
        getValue(Stat.from(_.get(r.statistics, column.id) || new BigNumber(0))),
    formatter: (_ctx: MvContext, r: MvWithStats) =>
        formatter(
            getValue(
                Stat.from(_.get(r.statistics, column.id) || new BigNumber(0))
            )
        ),
});

const percentColumn = (
    c: BaseStatisticsColumnInfo,
    getValue: (stat: Stat) => number
) => defineStatsColumn(c, getValue, NumberFormatter.formatPercent);

const bytesColumn = (
    c: BaseStatisticsColumnInfo,
    getValue: (stat: Stat) => number
) => defineStatsColumn(c, getValue, NumberFormatter.formatBytes);

const numberColumn = (
    c: BaseStatisticsColumnInfo,
    getValue: (stat: Stat) => number
) => defineStatsColumn(c, getValue, NumberFormatter.formatNumber);

export const COLUMNS: { [column: string]: MvStatisticsColumnInfo } = {
    cpuUsage: percentColumn(
        {
            category: "cpu",
            id: "cpuUsage",
            title: "Total CPU",
            subTitle: "Percent",
            description:
                "The percentage of one CPU worth of run time used, summed over all executions during the recording period and divided by the recording period.",
            disableDescriptionIcon: true,
            advanced: false,
        },
        v => v.sum().toNumber() // PLAT-3189
    ),

    memoryB: bytesColumn(
        {
            category: "memory",
            id: "memoryB",
            title: "Memory",
            subTitle: "Bytes",
            description:
                "Average memory footprint over recording period, summed over all executions during the recording period.",
            disableDescriptionIcon: true,
            advanced: false,
        },
        v => v.sum().toNumber() // PLAT-3189
    ),

    memoryMajorFaults: numberColumn(
        {
            category: "memory",
            id: "memoryMajorFaults",
            title: "Memory Faults",
            subTitle: "Faults / Sec",
            description:
                "Number of page faults which required disk I/O to resolve, summed over all executions during the recording period and divided by the recording period.",
            disableDescriptionIcon: true,
            advanced: true,
        },
        v => v.sum().toNumber() // PLAT-3189
    ),

    logBufferWriteB: bytesColumn(
        {
            category: "log",
            id: "logBufferWriteB",
            title: "Log Write",
            subTitle: "Bytes / Sec",
            description:
                "Bytes written to the transaction log buffer, summed over all executions during the recording period and divided by the recording period.",
            disableDescriptionIcon: true,
            advanced: false,
        },
        v => v.sum().toNumber() // PLAT-3189
    ),

    networkLogicalRecvB: bytesColumn(
        {
            category: "network",
            id: "networkLogicalRecvB",
            title: "Net Read",
            subTitle: "Bytes / Sec",
            description:
                "Bytes received from a socket, summed over all executions during the recording period and divided by the recording period.",
            disableDescriptionIcon: true,
            advanced: false,
        },
        v => v.sum().toNumber() // PLAT-3189
    ),

    networkLogicalSendB: bytesColumn(
        {
            category: "network",
            id: "networkLogicalSendB",
            title: "Net Write",
            subTitle: "Bytes / Sec",
            description:
                "Bytes written to a socket, summed over all executions during the recording period and divided by the recording period.",
            disableDescriptionIcon: true,
            advanced: false,
        },
        v => v.sum().toNumber() // PLAT-3189
    ),

    networkB: bytesColumn(
        {
            category: "network",
            id: "networkB",
            title: "Network",
            subTitle: "Bytes / Sec",
            description:
                "Total bytes written to and received from a socket, summed over all executions during the recording period and divided by the recording period.",
            disableDescriptionIcon: true,
            advanced: false,
        },
        v => v.sum().toNumber() // PLAT-3189
    ),

    diskLogicalReadB: bytesColumn(
        {
            category: "disk",
            id: "diskLogicalReadB",
            title: "Disk Read",
            subTitle: "Bytes / Sec",
            description:
                'Bytes read from the filesystem, summed over all executions during the recording period and divided by the recording period. This figure is "logical" and may include reads satisfied by caches rather than device reads.',
            disableDescriptionIcon: true,
            advanced: false,
        },
        v => v.sum().toNumber() // PLAT-3189
    ),

    diskLogicalWriteB: bytesColumn(
        {
            category: "disk",
            id: "diskLogicalWriteB",
            title: "Disk Write",
            subTitle: "Bytes / Sec",
            description:
                "Bytes written to the filesystem, summed over all executions during the recording period and divided by the recording period.",
            disableDescriptionIcon: true,
            advanced: false,
        },
        v => v.sum().toNumber() // PLAT-3189
    ),

    diskPhysicalReadB: bytesColumn(
        {
            category: "disk",
            id: "diskPhysicalReadB",
            title: "Device Read",
            subTitle: "Bytes / Sec",
            description:
                "Bytes read from the physical device(s), summed over all executions during the recording period and divided by the recording period.",
            disableDescriptionIcon: true,
            advanced: true,
        },
        v => v.sum().toNumber() // PLAT-3189
    ),

    diskPhysicalWriteB: bytesColumn(
        {
            category: "disk",
            id: "diskPhysicalWriteB",
            title: "Device Write",
            subTitle: "Bytes / Sec",
            description:
                "Bytes written to the physical device(s), summed over all executions during the recording period and divided by the recording period.",
            disableDescriptionIcon: true,
            advanced: true,
        },
        v => v.sum().toNumber() // PLAT-3189
    ),

    diskB: bytesColumn(
        {
            category: "disk",
            id: "diskB",
            title: "Disk",
            subTitle: "Bytes / Sec",
            description:
                "Total filesystem bytes read and written, summed over all executions during the recording period and divided by the recording period.",
            disableDescriptionIcon: true,
            advanced: false,
        },
        v => v.sum().toNumber() // PLAT-3189
    ),
};

export type MvColumnKey = keyof typeof COLUMNS;

type MvResourceUsageColumn = {
    id: MvStatisticsColumn;
    title: string;
    description: string;
    getValue: (row: MvWithStats) => number;
    formatter: (row: MvWithStats) => string;
    color: string;
};

type BaseColumn = {
    id: MvStatisticsColumn;
    title: string;
    description: string;
    color: string;
};

const defineResourceUsageColumn = (
    column: BaseColumn
): MvResourceUsageColumn => {
    // All of the fields in the resource usage column are
    // aggregated as a mean.
    const getValue = (r: MvWithStats) =>
        Stat.from(r.statistics[column.id] || new BigNumber(0))
            .mean()
            .toNumber(); // PLAT-3189

    return {
        ...column,
        getValue,
        formatter: r => NumberFormatter.formatDuration(getValue(r)),
    };
};

export const RESOURCE_USAGE_COLUMNS: Array<MvResourceUsageColumn> = [
    defineResourceUsageColumn({
        id: "cpuTime",
        title: "Running on CPU",
        description: "Percentage of time using CPU.",
        color: COLORS["color-indigo-600"],
    }),

    defineResourceUsageColumn({
        id: "cpuWaitTime",
        title: "Waiting for CPU",
        description:
            "Percentage of time waiting for a CPU to become available.",
        color: COLORS["color-cyan-800"],
    }),

    defineResourceUsageColumn({
        id: "lockRowTime",
        title: "Waiting for row locks",
        description: "Percentage of time spent waiting on table row locks.",
        color: COLORS["color-purple-900"],
    }),

    defineResourceUsageColumn({
        id: "logBufferTime",
        title: "Waiting for log buffer",
        description:
            "Percentage of time spent waiting for space in the transaction log buffer.",
        color: COLORS["color-magenta-900"],
    }),

    defineResourceUsageColumn({
        id: "logFlushTime",
        title: "Waiting for log flush",
        description:
            "Percentage of time spent waiting for transaction log records to be flushed to disk.",
        color: COLORS["color-red-800"],
    }),

    defineResourceUsageColumn({
        id: "diskTime",
        title: "Waiting for disk I/O",
        description:
            "Percentage of time spent waiting for physical disk I/O to complete.",
        color: COLORS["color-yellow-800"],
    }),

    defineResourceUsageColumn({
        id: "networkTime",
        title: "Waiting for network I/O",
        description:
            "Percentage of time spent waiting for sockets to be ready to send or receive data.",
        color: COLORS["color-green-800"],
    }),

    defineResourceUsageColumn({
        id: "lockTime",
        title: "Waiting for non-rowlock locks",
        description: "Percentage of time waiting for non-rowlock locks.",
        color: COLORS["color-orange"],
    }),
];

export const NODE_COLUMNS: Array<MvColumn<MvNode>> = [
    {
        id: "endpoint",
        title: "Node",
        defaultMinWidth: 150,
        getValue: (row: MvNode) => `${row.ipAddr}:${row.port}`,
        formatter: (ctx: MvContext, row: MvNode) => `${row.ipAddr}:${row.port}`,
    },

    {
        id: "role",
        title: "Role",
        defaultMinWidth: 100,
        defaultMaxWidth: 100,
        getValue: (row: MvNode) => humanizeNodeRole(row.role),
        formatter: (ctx: MvContext, row: MvNode) => humanizeNodeRole(row.role),
    },
];

const getActivityInstanceCount = (
    row: MvActivityHighLevel | MvActivityLowLevel
) => {
    return row.runCount
        .plus(row.successCount)
        .plus(row.failureCount)
        .toNumber(); // PLAT-3189
};

export const ACTIVITIES_COLUMNS: Array<
    MvColumn<MvActivityLowLevel | MvActivityHighLevel>
> = [
    {
        id: "activityName",
        title: "Name",
        defaultMinWidth: 250,
        description: "Name of the query or its text, if applicable.",
        disableDescriptionIcon: true,

        formatter: (ctx: MvContext, row, expanded) => {
            // See PLAT-774 for more details about this function.
            let tooltip, cell;

            // low level activity
            if (row.kind === "LOW_LEVEL") {
                let activityLocation, nodeData;

                let node = ctx.nodes[row.nodeId];

                if (node) {
                    const { ipAddr: hostname, port, role } = node;
                    const { partitionId } = row;

                    // Set `activityLocation` (where the activity is running - host:port or host:port:partitionId)
                    if (partitionId !== undefined) {
                        activityLocation = `${hostname}:${port}:${partitionId}`;
                    } else {
                        activityLocation = `${hostname}:${port}`;
                    }

                    nodeData = (
                        <div>
                            <div>Role: {humanizeNodeRole(role)}</div>
                            <div>Host: {hostname}</div>
                            <div>Port: {port.toString()}</div>
                            {partitionId ? (
                                <div>Partition: {partitionId.toString()}</div>
                            ) : (
                                undefined
                            )}
                        </div>
                    );

                    cell = `${abbreviateNodeRole(role)} ${activityLocation}`;
                } else {
                    cell = row.activityName;
                }

                let queryTextNode;
                if (row.queryText) {
                    queryTextNode = (
                        <div className="mv-tooltip-query mv-tooltip-code">
                            {row.queryText}
                        </div>
                    );
                }

                tooltip = (
                    <div>
                        {nodeData}
                        <div>Activity Name: {row.activityName}</div>
                        {queryTextNode}
                    </div>
                );
            } else {
                // high level activity
                tooltip = (
                    <div>
                        <div>Activity Name: {row.activityName}</div>

                        <div className="mv-tooltip-query mv-tooltip-code">
                            {row.queryText || null}
                        </div>
                    </div>
                );

                cell = row.queryText || row.activityName;
            }

            return (
                <Tip
                    direction="se"
                    tooltip={tooltip}
                    className={classnames("mv-name-cell", { expanded })}
                >
                    {cell}
                </Tip>
            );
        },

        getValue: (row: MvActivityHighLevel | MvActivityLowLevel) =>
            row.queryText || row.activityName,
    },
    {
        id: "queryCount",
        title: "Query Count",
        description:
            "Number of instances of this query which ran during the recording period.",
        disableDescriptionIcon: true,
        defaultMinWidth: 150,

        getValue: getActivityInstanceCount,
        formatter: (
            _ctx: MvContext,
            row: MvActivityHighLevel | MvActivityLowLevel
        ) => {
            return getActivityInstanceCount(row).toString();
        },
    },
];

export const ACTIVITIES_COLUMNS_ADVANCED = [
    ...ACTIVITIES_COLUMNS,
    {
        id: "activityType",
        title: "Type",
        defaultMinWidth: 150,

        getValue: (row: MvActivityHighLevel | MvActivityLowLevel) =>
            row.activityType,
        formatter: (
            _ctx: MvContext,
            row: MvActivityHighLevel | MvActivityLowLevel
        ) => row.activityType,
    },
];
