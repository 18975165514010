import { LoadingError } from "util/loading-error";

import * as React from "react";

import Icon from "view/components/icon";
import Loading from "view/components/loading";
import { LoadingBar } from "view/components/loading-bar";

import "./render-loading-error.scss";

export const ERROR_NODE = (
    <div className="loading-error-error">
        <Icon icon="exclamation-triangle" error rightMargin />
        Unknown
    </div>
);

export const LOADING_NODE = <Loading size="small" />;

export const LOADING_CELL_NODE = (
    <div className="loading-bar-cell">
        <LoadingBar width={100} />
    </div>
);

export function renderLoadingError<T>(
    data: LoadingError<T>,
    renderPayload: (t: T) => React.ReactNode,
    { cell }: { cell?: boolean } = {}
) {
    if (data.isError()) {
        return ERROR_NODE;
    } else if (data.isLoading()) {
        if (cell) {
            return LOADING_CELL_NODE;
        } else {
            return LOADING_NODE;
        }
    } else {
        return renderPayload(data.value);
    }
}
