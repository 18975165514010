import { ConnectionManagerState } from "worker/net/connection-manager";
import { ConnectAction } from "data/actions";

type Actions = ConnectAction;

export type ConnectionState = {
    state: ConnectionManagerState;
};

const initialState: ConnectionState = {
    state: { connected: false, config: undefined },
};

export default (state: ConnectionState = initialState, action: Actions) => {
    switch (action.type) {
        case "CONNECT": {
            if (action.error) {
                return state;
            }

            state = {
                ...state,
                state: action.payload,
            };

            break;
        }
    }

    return state;
};
