
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import ExtLink from "view/components/external-link";

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h3" components={components}>{`Getting to know MemSQL Studio`}</MDXTag>
<MDXTag name="p" components={components}>{`MemSQL Studio allows you to easily monitor, debug and interact with your MemSQL clusters. `}<ExtLink name="about-studio" category="tutorial">{`Learn More`}</ExtLink>{`.`}</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  