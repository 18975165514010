import { Maybe } from "util/maybe";

import { State as ReduxState } from "data/reducers";
import { DispatchFunction } from "data/actions/types";
import { TreeItem } from "view/components/nested-tree";
import { ReactChildrenArray } from "util/react-children-array";
import {
    OverallSelection,
    DatabaseSelection,
    TableLikeSelection,
    FunctionLikeSelection,
} from "data/selectors/schema-tree";

import { AbsoluteTableName } from "data/models";

import { SchemaEntity, ColumnLike, DatabaseName } from "data/models";

import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import NestedTree from "view/components/nested-tree";
import { MenuItem } from "view/common/menu";
import Loading from "view/components/loading";
import ExtLink from "view/components/external-link";
import { Button } from "view/common/button";
import LastUpdated from "view/components/last-updated";
import Icon from "view/components/icon";
import Input from "view/components/text-input";
import Tip from "view/components/tip";

import { selectSchemaTree } from "data/selectors/schema-tree";
import { queryStructure } from "worker/api/schema";
import { getTableLikeIcon } from "memsql/schema-column-info";
import { getColumnIcon } from "view/common/models/schema";

import {
    expandDatabase,
    collapseDatabase,
    expandTable,
    collapseTable,
    schemaTreeChangeSearch,
} from "data/actions/schema-tree";

import { compareSchemaEntities } from "data/models/schema";

import * as analytics from "util/segment";

import "./schema-tree.scss";

type ColumnLikeTooltipProps = {
    columnLike: ColumnLike;
};

class ColumnLikeTooltip extends React.PureComponent<ColumnLikeTooltipProps> {
    render() {
        const { columnLike } = this.props;
        const { columnName, subType, autoIncrement, isNullable } = columnLike;

        const iconTexts = [{ icon: getColumnIcon(columnLike), text: subType }];

        if (autoIncrement) {
            iconTexts.push({
                icon: "sort-numeric-up-alt",
                text: "AUTO_INCREMENT",
            });
        }

        if (!isNullable) {
            iconTexts.push({ icon: "circle-notch", text: "NOT NULL" });
        }

        return (
            <div className="column-tooltip">
                <div className="column-name">{columnName}</div>
                <div className="column-icon-texts">
                    {iconTexts.map(({ icon, text }, i) => (
                        <div className="column-icon-text" key={i}>
                            <Icon
                                size="sm"
                                className="icon"
                                fixedWidth
                                rightMargin
                                icon={icon}
                                iconType="regular"
                            />
                            <div className="text">{text}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

type StateProps = {
    loading: boolean;
    selection: OverallSelection;
    structureLoaded: boolean;
    structureLoading: boolean;
    structureError?: string;
    lastStructureUpdate?: Date;
    searchQuery: string;
};

type EntityState = {
    active: boolean;
    expanded: boolean;
};

type Props = StateProps & {
    dispatch: DispatchFunction;

    getActions?: (
        entity: SchemaEntity
    ) => Maybe<ReactChildrenArray<typeof MenuItem>>;

    // Clicking on any SchemaTree expandable entity (any entity but columns) will
    // call this callback with the entity and its current state (whether it is active
    // and whether it is expanded). This callback can perform actions in response
    // to the click, and it can return a boolean saying whether we should toggle the
    // expanded/contracted state of the entity. This prop is ignored for columns.
    onClickShouldExpand: (entity: SchemaEntity, state: EntityState) => boolean;

    // The client of SchemaTree can choose to highlight a certain
    // element in the tree by setting it as the active entity.
    activeEntity: Maybe<SchemaEntity>;

    topRightExtraContent?: React.ReactNode;

    // analytics category - which section of the app is rendering the SchemaTree
    category: string;
};

const FUNCTION_KIND_ICON: { [kind: string]: string } = {
    USER_DEFINED_FUNCTION: "udf",
    USER_DEFINED_AGGREGATE: "aggregate",
    STORED_PROCEDURE: "stored-procedure",
};

type State = {
    // We update the query text in our component state immediately when the
    // user types anything, but debounce updating the Redux state based on
    // this because changing the search query is somewhat expensive.
    liveQuery: string;
};

class SchemaTree extends React.Component<Props, State> {
    static defaultProps = {
        activeEntity: undefined,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            liveQuery: props.searchQuery,
        };
    }

    componentDidMount() {
        const { structureLoaded, structureLoading, dispatch } = this.props;

        if (!structureLoaded && !structureLoading) {
            dispatch(queryStructure());
        }
    }

    componentWillUnmount() {
        this.dispatchChangeSearch.flush();
    }

    handleReload = () => {
        const { dispatch, category } = this.props;

        dispatch(queryStructure());

        analytics.trackReload(`${category}-schema-tree`);
    };

    expandCollapseDatabase = (
        databaseName: DatabaseName,
        expanded: boolean
    ) => {
        const { dispatch } = this.props;

        if (expanded) {
            dispatch(collapseDatabase({ databaseName }));
        } else {
            dispatch(expandDatabase({ databaseName }));
        }
    };

    expandCollapseTable = (table: AbsoluteTableName, expanded: boolean) => {
        const { dispatch } = this.props;

        if (expanded) {
            dispatch(collapseTable(table));
        } else {
            dispatch(expandTable(table));
        }
    };

    renderEmptyState = () => {
        const { searchQuery } = this.props;

        if (searchQuery) {
            return (
                <div className="empty-state">
                    No databases, tables, functions, or columns matched your
                    query.
                </div>
            );
        } else {
            return (
                <div className="empty-state">
                    <div className="title">
                        This cluster contains no databases.
                    </div>
                    Learn how to{" "}
                    <ExtLink name="create-database" category="schema-tree">
                        create a database.
                    </ExtLink>
                </div>
            );
        }
    };

    renderError = () => {
        const { structureError } = this.props;

        if (structureError) {
            return (
                <div className="error-state">
                    <div className="title">
                        Error fetching cluster's schema.
                    </div>
                    {structureError}
                </div>
            );
        }
    };

    getColumns = (tableLikeSelection: TableLikeSelection): Array<TreeItem> => {
        const { getActions, activeEntity } = this.props;

        return _.map(tableLikeSelection.columns, (columnLike: ColumnLike) => {
            const active =
                activeEntity !== undefined &&
                compareSchemaEntities(activeEntity, columnLike);

            return {
                id: columnLike.columnId,
                label: columnLike.columnName,
                tooltip: <ColumnLikeTooltip columnLike={columnLike} />,
                active,
                icon: getColumnIcon(columnLike),
                iconType: "regular",
                actions: getActions ? getActions(columnLike) : undefined,
            } as TreeItem;
        });
    };

    getTables = (databaseSelection: DatabaseSelection): Array<TreeItem> => {
        const { getActions, onClickShouldExpand, activeEntity } = this.props;

        return _.map(
            databaseSelection.tables,
            (tableLikeSelection: TableLikeSelection) => {
                const {
                    tableLike,
                    tableLike: { tableId, databaseName, tableName, kind },
                    expanded,
                } = tableLikeSelection;

                const onExpandCollapse = () =>
                    this.expandCollapseTable(
                        { databaseName, tableName, kind },
                        expanded
                    );

                const active =
                    activeEntity !== undefined &&
                    compareSchemaEntities(activeEntity, tableLike);

                const onClick = () => {
                    if (onClickShouldExpand(tableLike, { active, expanded })) {
                        onExpandCollapse();
                    }
                };

                return {
                    id: tableId,
                    label: tableName,
                    icon: getTableLikeIcon(tableLike),
                    active,
                    expanded,
                    onExpandCollapse,
                    children: this.getColumns(tableLikeSelection),
                    actions: getActions ? getActions(tableLike) : undefined,
                    onClick,
                };
            }
        );
    };

    getFunctions = (databaseSelection: DatabaseSelection): Array<TreeItem> =>
        _.map(
            databaseSelection.functions,
            ({ functionLike }: FunctionLikeSelection) => ({
                id: functionLike.functionId,
                label: functionLike.name,
                icon: FUNCTION_KIND_ICON[functionLike.kind],
                active: false,
                actions: undefined,
            })
        );

    getDatabases = ({ databases }: OverallSelection): Array<TreeItem> => {
        const { getActions, onClickShouldExpand, activeEntity } = this.props;

        return _.map(databases, (databaseSelection: DatabaseSelection) => {
            const {
                database,
                database: { databaseName },
                expanded,
            } = databaseSelection;

            const onExpandCollapse = () =>
                this.expandCollapseDatabase(databaseName, expanded);

            const active =
                activeEntity !== undefined &&
                compareSchemaEntities(activeEntity, database);

            const onClick = () => {
                if (onClickShouldExpand(database, { active, expanded })) {
                    onExpandCollapse();
                }
            };

            return {
                id: databaseName,
                label: databaseName,
                active,
                expanded,
                onExpandCollapse,
                children: _.concat(
                    this.getTables(databaseSelection),
                    this.getFunctions(databaseSelection)
                ),
                actions: getActions ? getActions(database) : undefined,
                onClick,
            };
        });
    };

    dispatchChangeSearch = _.debounce(value => {
        this.props.dispatch(schemaTreeChangeSearch(value));
    }, 100);

    handleSearchQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        this.setState(
            {
                liveQuery: value,
            },
            () => {
                this.dispatchChangeSearch(value);
            }
        );
    };

    render() {
        const {
            selection,
            loading,
            lastStructureUpdate,
            structureError,
            searchQuery,
            topRightExtraContent,
        } = this.props;
        const { liveQuery } = this.state;

        let refreshText;
        if (structureError) {
            refreshText = "Retry";
        } else if (lastStructureUpdate) {
            refreshText = <LastUpdated date={lastStructureUpdate} />;
        } else {
            refreshText = "Refresh";
        }

        return (
            <div className="components-schema-tree">
                <div className="title-section">
                    Schema
                    <Tip
                        direction="sw"
                        tooltip={refreshText}
                        className="refresh-btn"
                    >
                        <Button
                            disabled={loading}
                            ghost
                            small
                            icon="sync-alt"
                            onClick={this.handleReload}
                        />
                    </Tip>
                    {topRightExtraContent}
                </div>
                <Input
                    type="text"
                    small
                    input={{
                        name: "search",
                        value: liveQuery,
                        onChange: this.handleSearchQueryChange,
                        placeholder: "Search...",
                    }}
                    icon={{ icon: "search" }}
                    className="search-input"
                />
                <NestedTree
                    emptyState={this.renderEmptyState()}
                    error={this.renderError()}
                    items={this.getDatabases(selection)}
                    className="schema-tree"
                    loading={loading}
                    loadingState={
                        <div className="loading-container">
                            <div>
                                <Loading outlineOnly size="medium" />
                                <div>Loading</div>
                            </div>
                        </div>
                    }
                    disableClick={Boolean(searchQuery)}
                    disableExpansion={Boolean(searchQuery)}
                />
            </div>
        );
    }
}

export default connect(
    (s: ReduxState): StateProps => ({
        loading: s.schema.structureLoading || s.schema.structureInitial,
        structureError: s.schema.structureError,
        structureLoaded: s.schema.structureLoaded,
        structureLoading: s.schema.structureLoading,
        lastStructureUpdate: s.schema.lastStructureUpdate,
        selection: selectSchemaTree(s),
        searchQuery: s.schemaTree.searchQuery,
    })
)(SchemaTree);
