declare var STUDIO_VERSION: string;

import { IconProps } from "view/components/icon";

import { Maybe } from "util/maybe";

import { State } from "data";
import { LatestStudioVersion } from "data/models/remote-version";

import * as React from "react";
import { connect } from "react-redux";

import CircleIcon from "view/components/circle-icon";
import ExtLink from "view/components/external-link";
import SectionHeader from "view/components/section-header";
import { FakeClick } from "view/common/fake-click";
import { InfoCard, Highlight } from "view/common/info-card";

import {
    selectLoadingOrInitial,
    selectPayload,
} from "util/loading-state-machine";
import { logError } from "util/logging";

import "./studio-card.scss";

type StateProps = {
    loading: boolean;
    latestStudioVersion: Maybe<LatestStudioVersion>;
};

class StudioCard extends React.Component<StateProps> {
    renderLatestVersion = (): React.ReactNode => {
        const { latestStudioVersion } = this.props;
        const iconProps: IconProps = {
            icon: "window",
            iconType: "regular",
        };

        let versionText;
        if (latestStudioVersion) {
            versionText = latestStudioVersion.version.toString();
        } else {
            versionText = "Check out the latest build";
        }

        return (
            <div className="latest-version">
                <CircleIcon iconProps={iconProps} size="small" />

                <div className="latest-version-details">
                    <div className="latest-version-number">{versionText}</div>
                    <div className="latest-version-notes">
                        Learn about what was updated in the release notes.
                    </div>
                    <div className="latest-version-link">
                        <ExtLink
                            name="studio-release-notes"
                            category="about-page"
                        >
                            View Release Notes
                        </ExtLink>
                    </div>
                </div>
            </div>
        );
    };

    renderContent = (): React.ReactNode => {
        const iconProps: IconProps = {
            icon: "window",
            iconType: "regular",
        };

        let studioVersion;
        if (STUDIO_VERSION) {
            studioVersion = STUDIO_VERSION;
        } else {
            logError(new Error("Expected Studio version to be defined."));
            studioVersion = "Unknown Version";
        }

        return (
            <div>
                <FakeClick name="studio-current-version">
                    <Highlight>
                        <CircleIcon iconProps={iconProps} size="small" />

                        <div className="current-version-details">
                            <div className="current-version-heading">
                                Installed Version
                            </div>
                            <div className="current-version-number">
                                {studioVersion}
                            </div>
                        </div>
                    </Highlight>
                </FakeClick>

                <FakeClick name="studio-latest-version">
                    <SectionHeader>Latest Version</SectionHeader>

                    {this.renderLatestVersion()}
                </FakeClick>
            </div>
        );
    };

    render() {
        const { loading } = this.props;

        let content;
        if (!loading) {
            content = this.renderContent();
        }

        return (
            <InfoCard
                loading={loading}
                content={content}
                error={undefined}
                hasEmptyState={false}
                title="Studio Version"
                description="Installed version of MemSQL Studio"
                helpLink={{
                    name: "studio-release-notes",
                    category: "about-page",
                }}
            />
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        loading: selectLoadingOrInitial(s.remoteVersion.latestStudioVersion),
        latestStudioVersion: selectPayload(s.remoteVersion.latestStudioVersion),
    })
)(StudioCard);
