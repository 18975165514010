import * as React from "react";
import classnames from "classnames";

import "./size-circle.scss";

type Props = {
    value: number; // 0 <= value <= 1
    maxDiameter: number;
    color: string;
    rightMargin?: boolean;
};

export default class SizeCircle extends React.Component<Props> {
    render() {
        const { value, color, maxDiameter, rightMargin } = this.props;

        if (value < 0 || value > 1) {
            throw new Error("Value has to be between 0 and 1.");
        }

        // We scale the circle based on the area of the circle.
        // We want the maximum diameter to be `STATIC_MAX_DIAMETER`.
        // So, we use A=πr^2 but disregard the constant (A=r^2) to
        // get an appropriate diameter for the circle based on the 0-1
        // `value`.
        const diameter = maxDiameter * Math.sqrt(value);

        const classes = classnames("components-size-circle", {
            "margin-right": rightMargin,
        });

        return (
            <svg
                className={classes}
                width={maxDiameter}
                height={maxDiameter}
                style={{
                    minWidth: maxDiameter,
                    minHeight: maxDiameter,
                }}
            >
                {
                    // Dashed circle's radius is reduced by 5%
                    // because SVG doesn't support inner stroke.
                    // Solid circle should begin to cover dashed at 90%.
                    // Solid circle should completely cover dashed at 100%.
                }
                <circle
                    cx={maxDiameter / 2}
                    cy={maxDiameter / 2}
                    r={(maxDiameter / 2) * 0.95}
                    fill="none"
                    stroke={color}
                    strokeOpacity={0.5}
                    strokeWidth={1}
                    strokeDasharray={3}
                    strokeLinecap="round"
                />

                <circle
                    cx={maxDiameter / 2}
                    cy={maxDiameter / 2}
                    r={diameter / 2}
                    fill={color}
                    strokeWidth={0}
                />
            </svg>
        );
    }
}
