import { TableSort } from "util/sort";
import { SimpleAction, Action } from "./types";
import { Loading } from "util/loading-state-machine";

import { MemsqlEvent } from "data/models";

export type EventsAction = Action<
    "EVENTS",
    Loading<{
        events: Array<MemsqlEvent>;
        deltaTimeS: number;
    }>,
    { message: string }
>;

export type SortEventsAction = SimpleAction<"SORT_EVENTS", TableSort>;

export const sortEvents = (payload: TableSort): SortEventsAction => ({
    type: "SORT_EVENTS",
    error: false,
    payload,
});
