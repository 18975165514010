import { IconProps, IconSize } from "view/components/icon";

import * as React from "react";
import classnames from "classnames";

import SvgSchema from "./svg/schema.inline.svg";
import SvgS3 from "./svg/s3.inline.svg";
import SvgKafka from "./svg/kafka.inline.svg";
import SvgHdfs from "./svg/hdfs.inline.svg";
import SvgFilesystem from "./svg/filesystem.inline.svg";
import SvgAzureBlob from "./svg/azureblob.inline.svg";
import SvgEvents from "./svg/events.inline.svg";
import SvgIngest from "./svg/ingest.inline.svg";
import SvgProfileNode from "./svg/profile-node.inline.svg";
import SvgProfileQuery from "./svg/profile-query.inline.svg";
import SvgExplain from "./svg/explain.inline.svg";
import SvgEmptyTable from "./svg/empty-table.inline.svg";
import SvgUpgrade from "./svg/upgrade.inline.svg";

import Loading from "view/components/loading";
import Icon from "view/components/icon";

import "./circle-icon.scss";

export type CircleIconSize = "small" | "medium" | "large";

const getIconSize = (small: number, medium: number, large: number) => (
    size: CircleIconSize
) => {
    return size === "small" ? small : size === "large" ? large : medium;
};

/**
 * A lot of these sizes are going to be the same - but it is *much* easier to
 * make quick tweaks by having this all spaced out. This is to help with tweaking
 * one icon and not having it affect another one without us realizing it.
 **/

// None of the names of supported custom icons can collide with Font Awesome
// icon names.
const supportedIcons = {
    empty: { component: () => null },

    schema: { component: SvgSchema, getSize: getIconSize(20, 40, 70) },
    s3: { component: SvgS3, getSize: getIconSize(20, 30, 50) },
    kafka: { component: SvgKafka, getSize: getIconSize(14, 30, 50) },
    hdfs: { component: SvgHdfs, getSize: getIconSize(24, 38, 70) },
    filesystem: { component: SvgFilesystem, getSize: getIconSize(14, 30, 50) },
    azureblob: { component: SvgAzureBlob, getSize: getIconSize(14, 30, 50) },
    events: { component: SvgEvents, getSize: getIconSize(20, 40, 70) },
    ingest: { component: SvgIngest, getSize: getIconSize(20, 40, 90) },
    "profile-node": {
        component: SvgProfileNode,
        getSize: getIconSize(30, 60, 88),
    },
    "profile-query": {
        component: SvgProfileQuery,
        getSize: getIconSize(20, 40, 76),
    },
    explain: { component: SvgExplain, getSize: getIconSize(14, 30, 68) },
    "empty-table": {
        component: SvgEmptyTable,
        getSize: getIconSize(20, 40, 70),
    },
    upgrade: { component: SvgUpgrade, getSize: getIconSize(28, 50, 90) },
};

type BaseProps = {
    className?: string;

    // show a loading spinner around the edge of the circle
    loading?: boolean;

    loadingPrimary?: boolean;
    loadingSuccess?: boolean;
    loadingWarning?: boolean;
    loadingError?: boolean;

    coloredBackground?: boolean;

    // size options
    size: CircleIconSize;

    // status icon shows on top of circle icon
    statusIcon?: React.ReactElement<IconProps>;
};

export type CircleIconName = keyof typeof supportedIcons;

type CircleIconProps = { iconProps: IconProps } | { name: CircleIconName };

type Props = BaseProps & CircleIconProps;

export default class CircleIcon extends React.PureComponent<Props> {
    // exported for tests
    static supportedIcons = supportedIcons;

    render() {
        const {
            className,
            size,
            coloredBackground,
            statusIcon,
            loading,
            loadingPrimary,
            loadingSuccess,
            loadingWarning,
            loadingError,
        } = this.props;

        const classes = classnames(
            {
                "components-circle-icon": true,
                "colored-background": coloredBackground,
                [size]: true,
                loading,
            },
            className
        );

        const statusIconCp =
            statusIcon &&
            React.cloneElement(statusIcon, {
                fixedWidth: true,
            });

        let loadingEl;
        if (loading) {
            loadingEl = (
                <Loading
                    outlineOnly
                    size="small"
                    className="loading-outline"
                    primary={loadingPrimary}
                    success={loadingSuccess}
                    warning={loadingWarning}
                    error={loadingError}
                />
            );
        }

        let icon;
        let iconSize: IconSize;
        if (size === "small") {
            iconSize = "sm";
        } else if (size === "medium") {
            iconSize = "2x";
        } else {
            iconSize = "6x";
        }

        if ("name" in this.props) {
            // customizations for each supported custom SVG icon
            const iconInfo = supportedIcons[this.props.name];
            if (iconInfo && "getSize" in iconInfo) {
                icon = <iconInfo.component width={iconInfo.getSize(size)} />;
            } else {
                icon = <iconInfo.component />;
            }
        } else {
            icon = <Icon {...this.props.iconProps} size={iconSize} />;
        }

        return (
            <div className={classes}>
                {loadingEl}
                {icon}
                <div className="status-icon">{statusIconCp}</div>
            </div>
        );
    }
}
