
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import TutorialLoadSQLButton from "view/tutorial/tutorial-load-sql-button";
import {
    SQL_MARKETCAP_DATA_GEN,
    SQL_SEED_TRADES_PROCEDURE,
    SQL_ITER_STOCKS_PROCEDURE
} from "./stock-queries";

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h4" components={components}>{`Step 3: Create data generator functionality`}</MDXTag>
<MDXTag name="p" components={components}>{`The following are several functions used to generate data. The utility function, `}<MDXTag name="inlineCode" components={components} parentName="p">{`marketcap_to_DECIMAL`}</MDXTag>{`, is used by the `}<MDXTag name="inlineCode" components={components} parentName="p">{`seed_trades`}</MDXTag>{` stored procedure to convert a string value for the market capitalization of a stock into a decimal value.`}</MDXTag>
<TutorialLoadSQLButton id="stock-marketcap-function" query={SQL_MARKETCAP_DATA_GEN} text="Paste marketcap_to_DECIMAL function" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
<MDXTag name="p" components={components}>{`The `}<MDXTag name="inlineCode" components={components} parentName="p">{`seed_trades`}</MDXTag>{` stored procedure generates rows of "seed" trade data based on an integer input value. This provides a query to rank the companies by market capitalization limited to the top 200.`}</MDXTag>
<MDXTag name="p" components={components}>{`The stored procedure generates approximately the same number of trades for each company.`}</MDXTag>
<TutorialLoadSQLButton id="stock-seed-procedure" query={SQL_SEED_TRADES_PROCEDURE} text="Paste seed trades procedure" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
<MDXTag name="p" components={components}>{`The stored procedure, `}<MDXTag name="inlineCode" components={components} parentName="p">{`iter_stocks`}</MDXTag>{`, generates trade events using a "random walk" to determine the next price for each company. It will store the current price for each company, then add a random number between -1 and 1 to set the new price for each iteration.`}</MDXTag>
<MDXTag name="p" components={components}>{`Each iteration inserts new records equal to num_shares. When `}<MDXTag name="inlineCode" components={components} parentName="p">{`iter_stocks`}</MDXTag>{` completes, the total number of records in the `}<MDXTag name="inlineCode" components={components} parentName="p">{`trade`}</MDXTag>{` table will be equal to `}<MDXTag name="inlineCode" components={components} parentName="p">{`num_shares`}</MDXTag>{` * `}<MDXTag name="inlineCode" components={components} parentName="p">{`iterations`}</MDXTag>{` + `}<MDXTag name="inlineCode" components={components} parentName="p">{`num_shares`}</MDXTag>{`.`}</MDXTag>
<TutorialLoadSQLButton id="stock-iterstock-procedure" query={SQL_ITER_STOCKS_PROCEDURE} text="Paste iter_stocks procedure" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  