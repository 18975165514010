import { TableID, FunctionID } from "data/models/schema";

export function genTableId(obj: {
    databaseName: string;
    tableName: string;
}): TableID {
    return `${obj.databaseName}.${obj.tableName}`;
}

export function genFunctionId(obj: {
    databaseName: string;
    functionName: string;
}): FunctionID {
    return `${obj.databaseName}.${obj.functionName}`;
}
