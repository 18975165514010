import { Maybe } from "util/maybe";
import BigNumber from "vendor/bignumber.js/bignumber";

export type ActiveProcess = {
    queryText: Maybe<string>;
    processId: BigNumber;
    nodeId: BigNumber;
    elapsedTime: number;
    state: Maybe<string>;
    user: string;
    submitted: Date;
    nodeHost: string;
    nodePort: BigNumber;
    databaseName: Maybe<string>;
};

export class ActiveProcessId {
    nodeId: BigNumber;
    processId: BigNumber;

    constructor({
        nodeId,
        processId,
    }: {
        nodeId: BigNumber;
        processId: BigNumber;
    }) {
        this.nodeId = nodeId;
        this.processId = processId;
    }

    eq(cmp: ActiveProcessId): boolean {
        return this.nodeId.eq(cmp.nodeId) && this.processId.eq(cmp.processId);
    }

    toKey(): string {
        return `${this.processId}@${this.nodeId}`;
    }

    matches(process: ActiveProcess): boolean {
        return (
            this.nodeId.eq(process.nodeId) &&
            this.processId.eq(process.processId)
        );
    }

    static fromActiveProcess(process: ActiveProcess): ActiveProcessId {
        const { nodeId, processId } = process;

        return new ActiveProcessId({ nodeId, processId });
    }
}
