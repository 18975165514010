declare var STUDIO_VERSION: string;

import { Maybe } from "util/maybe";
import { Router } from "router5";
import { FeedbackModalStatus } from "view/common/feedback-modal";
import { DispatchFunction, State } from "data";
import { OnSubmitSuccess } from "view/controllers/connect";
import { Cluster, ClusterId } from "data/models";
import { ButtonClickEvent } from "view/common/button";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { withRoute } from "react-router5";
import classnames from "classnames";

import { getClusters } from "worker/api/clusters";

import InternalLink from "view/components/internal-link";
import ConnectToClusterForm from "view/forms/connect-to-cluster";
import ExtLink from "view/components/external-link";
import Loading from "view/components/loading";
import ClusterProfileIcon from "view/cluster/profile-icon";
import Icon from "view/components/icon";
import FeedbackModal from "view/common/feedback-modal";
import DeleteClusterForm from "view/forms/delete-cluster";
import { Button } from "view/common/button";

import Key from "util/key";

import { selectClusters, selectClustersError } from "data/selectors/clusters";
import * as analytics from "util/segment";

import "./clusters-list.scss";

const CLUSTERS_LIST_ITEM_CLASS = "clusters-list-cluster";

type ClustersListItemStateProps = {
    allowClusterMutations: boolean;
    managedService: boolean;
};

type ClustersListItemProps = ClustersListItemStateProps & {
    cluster: Cluster;
    expanded: boolean;
    onSubmitSuccess: OnSubmitSuccess;
    onClick?: () => void;
    router: Router;
    openDeleteClusterModal?: () => void;
};

// The ClustersListItem component is used in the ClustersList page (/connect) as
// well as in the ConnectToCluster page (/connect/<clusterId>). This is why we
// export this component. When expanded, it renders a ConnectToCluster form
// inside it and when unexpanded, it simply renders some information about the
// cluster prop. The reason we reuse this component is to make it easier to keep
// the styling exactly the same between the 2 pages.
class BaseClustersListItem extends React.Component<ClustersListItemProps> {
    // When people click ENTER on a Cluster List Item, it should run
    // the `onClick` handler for it (basically expand that Cluster Entry).
    handleKeyPress = (evt: React.KeyboardEvent<HTMLDivElement>) => {
        // We want to prevent ENTER clicks that were bubbled up to the div
        // to expand this ClustersListItem.
        if (
            evt.currentTarget.className === CLUSTERS_LIST_ITEM_CLASS &&
            evt.charCode === Key.ENTER &&
            this.props.onClick
        ) {
            this.props.onClick();
        }
    };

    // When the user tries to go back to the clusters list, we check if
    // window.ROOT_REDIRECT is set, in which case we take the user to
    // window.ROOT_REDIRECT. Otherwise, we just navigate to the "connect" route.
    handleClickBackToClustersList = () => {
        if (window.ROOT_REDIRECT) {
            window.location.replace(window.ROOT_REDIRECT);
        } else {
            this.props.router.navigate("connect");
        }
    };

    handleClickDeleteCluster = (evt: ButtonClickEvent) => {
        const { openDeleteClusterModal } = this.props;

        // We stop this event from bubbling up because we don't want this to
        // trigger the event attached to the parent of this button (this
        // component) - which navigates the user to the "connect" page. We only
        // want the modal to open, and not route the user to another page.
        evt.stopPropagation();
        openDeleteClusterModal && openDeleteClusterModal();
    };

    render() {
        const {
            cluster,
            expanded,
            onClick: handleClick,
            onSubmitSuccess,
            allowClusterMutations,
            managedService,
        } = this.props;

        const classes = classnames(CLUSTERS_LIST_ITEM_CLASS, { expanded });
        let tabIndex;
        if (expanded) {
            tabIndex = -1;
        } else {
            tabIndex = 0;
        }

        return (
            <div
                className={classes}
                onClick={handleClick}
                onKeyPress={this.handleKeyPress}
                tabIndex={tabIndex}
                role="button"
            >
                <ClusterProfileIcon
                    className="cluster-icon"
                    profile={cluster.profile}
                    size="2x"
                />

                <div className="cluster-info">
                    <div className="cluster-header">
                        <div className="cluster-text">
                            <div className="cluster-name">{cluster.name}</div>
                            <div className="cluster-description">
                                {cluster.description ||
                                    "This cluster has no description."}
                            </div>
                        </div>

                        {allowClusterMutations && (
                            <div className="cluster-actions">
                                <Button
                                    className="delete-cluster-icon"
                                    ghost
                                    icon="trash-alt"
                                    iconType="regular"
                                    onClick={this.handleClickDeleteCluster}
                                />
                            </div>
                        )}
                    </div>

                    {expanded && (
                        <>
                            <ConnectToClusterForm
                                initialValues={{
                                    username: cluster.defaultUser || "",
                                    password: "",
                                }}
                                form={`ConnectToClusterForm_${cluster.id}`}
                                clusterId={cluster.id}
                                onSubmitSuccess={(result, dispatch, props) => {
                                    onSubmitSuccess(props.clusterId);
                                }}
                                managedService={managedService}
                            />
                            {/*
                            The reason we pass an `onMouseDown` to the InternalLink component is
                            to bypass a potential form blur event that happens when the link is
                            clicked and the connect to cluster form is focused.
                            */}
                            <a
                                className="back-to-clusters-list"
                                onMouseDown={this.handleClickBackToClustersList}
                            >
                                <Icon icon="angle-left" /> Connect to a
                                different cluster
                            </a>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export const ClustersListItem = connect(
    (s: State): ClustersListItemStateProps => ({
        allowClusterMutations: s.clusters.allowClusterMutations,
        managedService: s.clusters.managedService,
    })
)(withRoute(BaseClustersListItem));

type StateProps = {
    clusters: Maybe<Array<Cluster>>;
    error: Maybe<string>;
    allowClusterMutations: boolean;
};

type Props = StateProps & {
    onSubmitSuccess: OnSubmitSuccess;
    dispatch: DispatchFunction;

    router: Router;
};

type ClustersListState = {
    feedbackModalStatus: FeedbackModalStatus;
    currentDeleteClusterId: Maybe<ClusterId>;
};

class ClustersList extends React.Component<Props, ClustersListState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            feedbackModalStatus: "CLOSED",
            currentDeleteClusterId: undefined,
        };
    }

    componentWillMount() {
        this.props.dispatch(getClusters());
    }

    handleClickCluster = (clusterId: string): void => {
        this.props.router.navigate("connect.cluster", {
            clusterId,
        });

        analytics.track("click-cluster", { category: "connect" });
    };

    handleChangeFeedbackModalStatus = (status: FeedbackModalStatus) => {
        this.setState({
            feedbackModalStatus: status,
        });
    };

    handleOpenFeedbackModal = () => {
        this.handleChangeFeedbackModalStatus("OPEN");
    };

    handleOpenDeleteClusterModal = (clusterId: ClusterId) => {
        this.setState({
            currentDeleteClusterId: clusterId,
        });
    };

    handleCloseDeleteClusterModal = () => {
        this.setState({
            currentDeleteClusterId: undefined,
        });
    };

    render() {
        const {
            clusters,
            error: errorMsg,
            onSubmitSuccess,
            allowClusterMutations,
        } = this.props;
        const { feedbackModalStatus, currentDeleteClusterId } = this.state;
        const currentCluster = _.find(clusters, { id: currentDeleteClusterId });
        const clusterProfile = currentCluster
            ? currentCluster.profile
            : undefined;

        if (errorMsg) {
            return <div className="error-msg">{errorMsg}</div>;
        } else if (clusters === undefined) {
            return <Loading size="large" />;
        } else {
            let addNewClusterButton: React.ReactNode = null;
            // We render the add new cluster button if cluster mutations are allowed and if
            // we are rendering the entire clusters list.
            if (allowClusterMutations) {
                addNewClusterButton = (
                    <InternalLink
                        className="add-new-cluster-btn"
                        routeInfo={{ name: "connect.new" }}
                        underlineOnHover={false}
                    >
                        <div className="icon-wrapper">
                            <Icon icon="plus" size="2x" />
                        </div>

                        <div className="add-text">
                            <div className="title">Add New Cluster</div>
                            <div className="description">
                                Add new cluster to MemSQL Studio
                            </div>
                        </div>
                    </InternalLink>
                );
            }

            const inner = (
                <>
                    {addNewClusterButton}

                    <div className="clusters-list">
                        {_.map(clusters, cluster => (
                            <ClustersListItem
                                onSubmitSuccess={onSubmitSuccess}
                                key={cluster.id}
                                cluster={cluster}
                                expanded={false}
                                onClick={() =>
                                    this.handleClickCluster(cluster.id)
                                }
                                openDeleteClusterModal={() => {
                                    this.handleOpenDeleteClusterModal(
                                        cluster.id
                                    );
                                }}
                            />
                        ))}
                    </div>
                </>
            );

            return (
                <>
                    <div className="clusters-list-page">
                        <div className="clusters-list-main">
                            <div className="header">
                                <h2>
                                    <div>
                                        Studio makes it easy to monitor all of
                                        your MemSQL clusters in one place.{" "}
                                        <ExtLink
                                            name="about-studio"
                                            category="connect"
                                        >
                                            Learn more about Studio
                                        </ExtLink>{" "}
                                        or{" "}
                                        <a
                                            tabIndex={0}
                                            role="button"
                                            onClick={
                                                this.handleOpenFeedbackModal
                                            }
                                        >
                                            send feedback
                                        </a>
                                        .
                                    </div>
                                </h2>
                            </div>

                            {inner}
                        </div>

                        <div className="clusters-list-footer">
                            &copy; MemSQL Inc &bull; Studio Version{" "}
                            {STUDIO_VERSION}
                        </div>
                    </div>

                    <FeedbackModal
                        status={feedbackModalStatus}
                        onStatusChange={this.handleChangeFeedbackModalStatus}
                    />

                    {currentDeleteClusterId && (
                        <DeleteClusterForm
                            form={`DeleteClusterForm_${currentDeleteClusterId}`}
                            clusterId={currentDeleteClusterId}
                            clusterProfile={clusterProfile}
                            onClose={this.handleCloseDeleteClusterModal}
                            onSubmitSuccess={this.handleCloseDeleteClusterModal}
                        />
                    )}
                </>
            );
        }
    }
}

export default connect(
    (s: State): StateProps => ({
        clusters: selectClusters(s),
        error: selectClustersError(s),
        allowClusterMutations: s.clusters.allowClusterMutations,
    })
)(withRoute(ClustersList));
