import { State, DispatchFunction } from "data";
import { InjectedRoute } from "react-router5";
import { Maybe } from "util/maybe";

import * as React from "react";
import { connect } from "react-redux";
import { withRoute } from "react-router5";

import DropdownButton from "view/components/dropdown-button";
import { Menu, MenuItem } from "view/common/menu";

import { explainQuery, profileQuery } from "data/actions/explain";
import { selectFirstSelection } from "data/selectors/page-editor";

import { logError } from "util/logging";
import getRoute from "util/get-route";
import * as analytics from "util/segment";

import "./explain-menu.scss";

type StateProps = {
    query: Maybe<string>;
};

type Props = StateProps &
    InjectedRoute & {
        dispatch: DispatchFunction;
        disabled: boolean;
    };

class ExplainMenu extends React.Component<Props> {
    onClickExplainQuery = () => {
        const { router, query, dispatch } = this.props;
        const {
            params: { clusterId },
        } = getRoute(this.props.route);

        if (query) {
            dispatch(explainQuery({ query }));

            analytics.track("query-explain", { category: "query-editor" });

            router.navigate("cluster.explain", { clusterId });
        } else {
            logError(
                new Error(`Cannot run EXPLAIN – expected query to exist.`)
            );
        }
    };

    onClickProfileQuery = () => {
        const { router, query, dispatch } = this.props;
        const {
            params: { clusterId },
        } = getRoute(this.props.route);

        if (query) {
            dispatch(profileQuery({ query }));

            analytics.track("query-profile", { category: "query-editor" });

            router.navigate("cluster.explain", { clusterId });
        } else {
            logError(
                new Error(`Cannot run PROFILE – expected query to exist.`)
            );
        }
    };

    render() {
        const { disabled } = this.props;

        return (
            <DropdownButton
                ghost
                icon="sitemap"
                direction="sw"
                className="explain-menu-btn"
                children={
                    <Menu>
                        <MenuItem
                            action={this.onClickExplainQuery}
                            disabled={disabled}
                        >
                            Explain
                        </MenuItem>
                        <MenuItem
                            action={this.onClickProfileQuery}
                            disabled={disabled}
                        >
                            Profile
                        </MenuItem>
                    </Menu>
                }
            />
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        query: selectFirstSelection(s),
    })
)(withRoute(ExplainMenu));
