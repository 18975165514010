import { Middleware } from "redux";
import {
    SuperAction,
    Thunk,
    GetStateFunction,
    DispatchFunction,
} from "data/actions";

import { Observable } from "rxjs";

import Worker from "worker/index.worker";
import Protocol from "worker/protocol";
import { handlesAction } from "worker/api";

import { logError } from "util/logging";

export const workerMiddleware: Middleware = ({
    getState,
    dispatch,
}: {
    readonly getState: GetStateFunction;
    dispatch: DispatchFunction;
}) => (next: (action: SuperAction) => void) => {
    const worker = new Worker();
    const proto = new Protocol(worker);

    worker.onerror = evt => console.error("WebWorker crashed", evt);

    return (action: SuperAction | Thunk<unknown>) => {
        if (typeof action !== "function" && handlesAction(action.type)) {
            // pass forward the action so it can be seen by other middleware like
            // redux-devtools or loggers
            next(action);

            return proto
                .stream({
                    kind: "request",
                    action: action.type,
                    args: action.payload,
                })
                .catch((err: Error) =>
                    Observable.of({
                        type: action.type,
                        error: true,
                        payload: err,
                    })
                )
                .map((action: SuperAction) => {
                    // Every action that is emitted by the observable is dispatched
                    // and if an error occurs, we catch it and log it so that

                    try {
                        return dispatch(action);
                    } catch (err) {
                        logError(
                            err,
                            `An error occurred while dispatching the action ${
                                action.type
                            } from the worker`
                        );
                    }
                })
                .toPromise();
        } else {
            if (typeof action === "function") {
                // We pass `store.dispatch` to every thunk instead of `next` so that
                // thunks can dispatch actions that get reprocessed by this middleware
                // again and thus can be async too.
                return action(dispatch, getState);
            }

            return next(action);
        }
    };
};
