import * as React from "react";
import classnames from "classnames";

import "./form.scss";

// This represents the <form> element props type.
type Props = Partial<
    React.DetailedHTMLProps<
        React.FormHTMLAttributes<HTMLFormElement>,
        HTMLFormElement
    >
> & {
    children: React.ReactNode;
    className: string;
};

export default class Form extends React.Component<Props> {
    render() {
        const { className, children, ...props } = this.props;

        const classes = classnames(
            {
                "components-form": true,
            },
            className
        );

        return (
            <form {...props} className={classes}>
                {children}
            </form>
        );
    }
}
