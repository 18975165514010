import { Maybe } from "util/maybe";

export const getErrorMessage = (code: Maybe<string>, message: string) => {
    if (code === "PROTOCOL_CONNECTION_LOST") {
        return "We are unable to connect to your cluster. Please check your hostname and port and try again.";
    } else if (code === "ER_ACCESS_DENIED_ERROR") {
        return "The credentials are incorrect. Please verify them and try again.";
    } else {
        return `An error occurred: ${message}`;
    }
};
