import { Maybe } from "util/maybe";

import { RouteInfo } from "router/types";
import { Params as RouteParams } from "router5";
import { DropdownState } from "view/components/dropdown";
import { ReactChildrenArray } from "util/react-children-array";
import { LinkName } from "urls";
import { LicenseInfo } from "data/models";

import * as React from "react";
import _ from "lodash";
import classnames from "classnames";

import InternalLink from "view/components/internal-link";
import ExtLink from "view/components/external-link";
import { CustomScrollbar } from "view/components/custom-scrollbar";

import Icon from "view/components/icon";
import Tip from "view/components/tip";
import Dropdown from "view/components/dropdown";
import { Direction } from "view/components/overlay";
import { DragHandle } from "view/components/drag-handle";

import { formatLicenseType } from "data/models";

import MemsqlLogo from "assets/images/memsql-logo.inline.svg";
import StudioTitle from "assets/images/studio-title.inline.svg";

import "./sidebar.scss";

// this has to be in sync with $sidebar-width in variables.scss
const WIDTH_WIDE = 205;
const WIDTH_NARROW = 72;

type BaseSidebarLinkProps<LinkProps> = LinkProps & {
    // the icon name to use
    icon: string;
    // the text of the link
    name: string;

    // injected by Sidebar
    isNarrow?: boolean;
};

type InternalLinkProps = {
    // route params
    routeName: string;
    routeParams: RouteParams;
};

type ExternalLinkProps = {
    externalLink: LinkName;
};

type SidebarLinkProps =
    | BaseSidebarLinkProps<InternalLinkProps>
    | BaseSidebarLinkProps<ExternalLinkProps>;

export class SidebarLink extends React.Component<SidebarLinkProps> {
    static defaultProps = {
        routeParams: {},
    };

    render() {
        const { name, icon, isNarrow } = this.props;

        const { routeName, routeParams } = this.props as BaseSidebarLinkProps<
            InternalLinkProps
        >;
        const { externalLink } = this.props as BaseSidebarLinkProps<
            ExternalLinkProps
        >;

        let tooltipText = name;
        let externalLinkIcon: React.ReactNode = null;

        // if no route name, we are dealing with an external link
        if (!routeName) {
            tooltipText = `Go to ${name}`;

            externalLinkIcon = (
                <Icon
                    className="layout-main-sidebar-ext-link-icon"
                    icon="external-link-square"
                    iconType="regular"
                />
            );
        }

        const children = (
            <Tip
                className="layout-ext-link-tip"
                disabled={!isNarrow}
                tooltip={tooltipText}
                direction="e"
            >
                <div className="link-icon">
                    <Icon fixedWidth icon={icon} />
                </div>
                <div className="link-name">{name}</div>
                {externalLinkIcon}
            </Tip>
        );

        if (routeName) {
            return (
                <InternalLink
                    category="sidebar"
                    className="layout-main-sidebar-link"
                    routeInfo={{
                        name: routeName,
                        params: routeParams,
                    }}
                    children={children}
                    underlineOnHover={false}
                />
            );
        } else {
            return (
                <ExtLink
                    category="sidebar"
                    className="layout-main-sidebar-link"
                    name={externalLink}
                    children={children}
                    underlineOnHover={false}
                />
            );
        }
    }
}

type SidebarButtonProps = {
    // the icon name to use
    icon: string;
    // the text of the link
    name: string;

    // injected by Sidebar
    isNarrow?: boolean;

    onClick: () => void;
};

// An element that looks like a sidebar link and is laid out like one, but
// performs a custom action instead of navigating somewhere when clicked.
export class SidebarButton extends React.Component<SidebarButtonProps> {
    static defaultProps = {};

    render() {
        const { name, icon, isNarrow, onClick } = this.props;

        return (
            <div
                className="layout-main-sidebar-link"
                onClick={onClick}
                role="button"
            >
                <Tip disabled={!isNarrow} tooltip={name} direction="e">
                    <div className="link-icon">
                        <Icon fixedWidth icon={icon} />
                    </div>
                    <div className="link-name">{name}</div>
                </Tip>
            </div>
        );
    }
}

type SidebarSectionChild = React.ReactElement<
    typeof SidebarLink | typeof SidebarButton
>;

type SidebarSectionProps = {
    title: string;
    isNarrow?: boolean;
    children: SidebarSectionChild | Array<Maybe<SidebarSectionChild>>;
};

export class SidebarSection extends React.Component<SidebarSectionProps> {
    render() {
        const { title, children, isNarrow } = this.props;
        let links = React.Children.toArray(children)
            .filter(_.identity)
            .map(child =>
                React.cloneElement(child as React.ReactElement<any>, {
                    isNarrow,
                })
            );

        return (
            <div className="layout-main-sidebar-section">
                <div className="section-title">{title}</div>
                <div className="section-links">{links}</div>
            </div>
        );
    }
}

export type SidebarProps = {
    title: string;
    subtitle: Maybe<string>;
    titleMenu: React.ReactElement<any>;
    icon: React.ReactNode;
    children: ReactChildrenArray<typeof SidebarSection>;
    routeInfo: RouteInfo;
    licenseInfo: Maybe<LicenseInfo>;
    footer: React.ReactNode;
};

export type SidebarState = {
    resizing: boolean;
    sidebarWidth: number;

    // Keep track of the user having "resized" the sidebar for a considerable
    // amount, which means that they are actually trying to resize the sidebar
    // as opposed to clicking it.
    movedConsiderableAmount: boolean;

    titleMenuOpen: DropdownState;
};

export class Sidebar extends React.Component<SidebarProps, SidebarState> {
    constructor(props: SidebarProps) {
        super(props);
        this.state = {
            resizing: false,
            sidebarWidth: WIDTH_WIDE,
            movedConsiderableAmount: false,
            titleMenuOpen: "CLOSED",
        };
    }

    isNarrow = () => this.state.sidebarWidth < WIDTH_WIDE;

    handleResize = ({ clientX }: MouseEvent) => {
        const newWidth = _.clamp(clientX, WIDTH_NARROW, WIDTH_WIDE);

        this.setState({
            sidebarWidth: newWidth,
        });
    };

    handleResizeComplete = (movedConsiderableAmount: boolean) => {
        const { sidebarWidth } = this.state;

        const closerToWide =
            Math.abs(sidebarWidth - WIDTH_WIDE) <
            Math.abs(sidebarWidth - WIDTH_NARROW);

        let newWidth: number;
        if (movedConsiderableAmount) {
            // If the user performs a drag, we snap the sidebar's width to
            // the closest fixed width (i.e., if the sidebar was dragged
            // close to the narrow width we make it narrow and vice-versa).
            if (closerToWide) {
                newWidth = WIDTH_WIDE;
            } else {
                newWidth = WIDTH_NARROW;
            }
        } else {
            // If the user performs a click, we snap the sidebar's width to
            // the opposite end (i.e., if the sidebar was narrow we make it
            // wide and vice-versa).
            if (closerToWide) {
                newWidth = WIDTH_NARROW;
            } else {
                newWidth = WIDTH_WIDE;
            }
        }

        this.setState({
            sidebarWidth: newWidth,
        });
    };

    handleTitleMenuOpenChange = (titleMenuOpen: DropdownState) => {
        this.setState({ titleMenuOpen });
    };

    renderClusterSwitcherDropdown = (control: React.ReactElement<any>) => {
        const { titleMenu } = this.props;
        const { titleMenuOpen } = this.state;

        const dir: Direction = this.isNarrow() ? "se" : "s";

        return (
            <Dropdown
                onChange={this.handleTitleMenuOpenChange}
                open={titleMenuOpen}
                className="cluster-dropdown-wrapper"
                direction={dir}
                spacing={10}
                control={control}
                children={titleMenu}
                closeOnMenuItemClick={false}
            />
        );
    };

    renderLicenseIcon = (routeInfo: RouteInfo) => {
        const { licenseInfo } = this.props;

        const renderIcon = (iconName: string) => {
            const licenseIconSize = this.isNarrow() ? "sm" : "2x";

            return (
                <InternalLink category="sidebar" routeInfo={routeInfo}>
                    <div className="circle-bg icon-logo">
                        <Icon icon={iconName} size={licenseIconSize} />
                    </div>
                </InternalLink>
            );
        };

        if (licenseInfo) {
            const licenseIcon =
                licenseInfo.type === "free" ? "rocket" : "building";

            return (
                <Tip
                    tooltip={
                        <div className="license-tooltip-title">
                            {formatLicenseType(licenseInfo.type)} License
                        </div>
                    }
                    direction="e"
                >
                    {renderIcon(licenseIcon)}
                </Tip>
            );
        } else {
            return renderIcon("building");
        }
    };

    render() {
        const {
            title,
            subtitle,
            icon,
            children,
            routeInfo,
            footer,
        } = this.props;
        const { sidebarWidth } = this.state;
        const isNarrow = this.isNarrow();

        let classes = classnames({
            "layout-main-sidebar": true,
            narrow: isNarrow,
            wide: !isNarrow,
        });

        let sections = React.Children.map(children, child =>
            React.cloneElement(child as React.ReactElement<any>, { isNarrow })
        );

        let subtitleNode: React.ReactNode = null;
        if (subtitle) {
            subtitleNode = (
                <Tip direction="e" tooltip={subtitle} className="subtitle">
                    {subtitle}
                </Tip>
            );
        }

        const openDropdown = () => this.handleTitleMenuOpenChange("OPEN");

        // isNarrow conditions to prevent render two dropdowns
        // otherwise two dropdowns are rendered on click
        const clusterIcon = isNarrow ? (
            this.renderClusterSwitcherDropdown(
                <div className="icon-tag" onClick={openDropdown}>
                    {icon}
                </div>
            )
        ) : (
            <div className="icon-tag">{icon}</div>
        );

        let topBarTitleNode: React.ReactNode = null;
        let clusterTitle: React.ReactNode = null;
        let clusterDropdownIcon: React.ReactNode = null;
        let clusterDropdown: React.ReactNode = null;

        if (!isNarrow) {
            topBarTitleNode = <StudioTitle className="logo-studio-title" />;

            clusterTitle = (
                <Tip tooltip={title} direction="e">
                    <div className="title">{title}</div>
                </Tip>
            );

            clusterDropdownIcon = (
                <Icon icon="angle-down" className="title-menu-icon" />
            );

            clusterDropdown = this.renderClusterSwitcherDropdown(
                <div className="title-wrapper" onClick={openDropdown}>
                    <div className="title-row">
                        {clusterTitle}
                        {clusterDropdownIcon}
                    </div>
                    {subtitleNode}
                </div>
            );
        }

        let sidebarFooter;
        if (footer) {
            sidebarFooter = <div className="sidebar-footer">{footer}</div>;
        }

        return (
            <div
                className="layout-main-sidebar-outer"
                style={{ width: sidebarWidth }}
            >
                <DragHandle
                    direction="e"
                    onResize={this.handleResize}
                    onResizeComplete={this.handleResizeComplete}
                    zIndexOverlay
                />
                <div className="brand-header-container">
                    <InternalLink
                        className="brand-header"
                        category="sidebar"
                        routeInfo={routeInfo}
                        underlineOnHover={false}
                    >
                        <MemsqlLogo />
                        {topBarTitleNode}
                    </InternalLink>
                </div>

                <CustomScrollbar
                    autoHide
                    dark
                    renderView={props => <div {...props} className={classes} />}
                >
                    <div className="title-section">
                        <div className="title-icons">
                            {this.renderLicenseIcon(routeInfo)}
                            {clusterIcon}
                        </div>

                        {clusterDropdown}
                    </div>

                    {sections}

                    {sidebarFooter}
                </CustomScrollbar>
            </div>
        );
    }
}
