import { Maybe } from "util/maybe";

import { DispatchFunction, State as ReduxState } from "data";
import { Database } from "data/models";
import { StaticConnectionId } from "worker/net/connection-manager";
import { QueryExecutorState } from "worker/net/query-executor";

import { createSelectDatabaseQueryGroupRepr } from "worker/net/query-executor";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Select } from "view/components/select";

import { selectDatabases } from "data/selectors/schema";
import { staticQueryGroup } from "worker/api/connection";

type StateProps = {
    connectionId: StaticConnectionId;
    connectionState: Maybe<QueryExecutorState>;
    selectedDatabase: Maybe<string>;
    databases: Array<Database>;
    structureLoaded: boolean;
};

type Props = StateProps & {
    className: string;
    dispatch: DispatchFunction;
};

type State = {
    // When a user selects a database from the dropdown, we store the database
    // name in state so we can temporarily display it in the dropdown before
    // the query to select the database runs, so that we don't rapidly change
    // what the dropdown says back and forth when the query succeeds, which is
    // usually the case. Reset whenever the connection becomes IDLE again.
    userSelectedDatabase: Maybe<string>;
};

class DatabaseSelect extends React.Component<Props, State> {
    state: State = {
        userSelectedDatabase: undefined,
    };

    static getDerivedStateFromProps(props: Props, _state: State) {
        if (props.connectionState === "IDLE") {
            return { userSelectedDatabase: undefined };
        }

        return null;
    }

    handleSelectDatabase = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        const { dispatch, connectionId, connectionState } = this.props;

        const database = evt.target.value;

        if (connectionState === "IDLE") {
            this.setState({ userSelectedDatabase: database });

            dispatch(
                staticQueryGroup({
                    id: connectionId,
                    queryGroup: createSelectDatabaseQueryGroupRepr(database),
                })
            );
        }
    };

    render() {
        const {
            connectionState,
            selectedDatabase,
            databases,
            className,
            structureLoaded,
        } = this.props;
        const { userSelectedDatabase } = this.state;
        const renderedSelectedDatabase =
            userSelectedDatabase || selectedDatabase || "";

        let anyDatabaseSelected = false;
        const options = databases.map(database => {
            const name = database.databaseName;

            if (name === renderedSelectedDatabase) {
                anyDatabaseSelected = true;
            }

            return (
                <option key={name} value={name}>
                    {name}
                </option>
            );
        });

        if (!anyDatabaseSelected) {
            options.unshift(
                <option
                    key={renderedSelectedDatabase}
                    value={renderedSelectedDatabase}
                >
                    {renderedSelectedDatabase || "Select a database..."}
                </option>
            );
        }

        return (
            <Select
                name="database"
                className={className}
                value={renderedSelectedDatabase}
                onChange={this.handleSelectDatabase}
                disabled={connectionState !== "IDLE" || !structureLoaded}
            >
                {options}
            </Select>
        );
    }
}

export default connect(
    (s: ReduxState): StateProps => ({
        connectionId: s.queryEditor.connectionId,
        connectionState: s.queryEditor.connectionState,
        selectedDatabase: s.queryEditor.selectedDatabase,
        databases: selectDatabases(s),
        structureLoaded: s.schema.structureLoaded,
    })
)(DatabaseSelect);
