declare var OVERRIDE_SERVER_HOST: string;
declare var OVERRIDE_SERVER_PORT: string;

import _ from "lodash";

import { Cluster, ClusterProfile, ClusterId } from "data/models";
import { NonLoggableError } from "util/logging";

const API_HOST = OVERRIDE_SERVER_HOST || location.hostname;
const API_PORT = OVERRIDE_SERVER_PORT || location.port;
const API_URL = `${location.protocol}//${API_HOST}:${API_PORT}/api`;

// This type represents a New Cluster request object, sent through the
// POST /clusters API endpoint to add a new cluster to the Studio config.
export type ClusterRequest = {
    hostname: string;
    port: number;

    username: string;
    password: string;

    name: string;
    profile: ClusterProfile;
    description: string;
};

const safePath = function(parts: Array<string>) {
    return _(parts)
        .map(encodeURIComponent)
        .join("/");
};
const makeRequest = function(
    pathParts: Array<string>,
    data?: Object,
    method?: string
) {
    if (!method) {
        method = data ? "POST" : "GET";
    }

    return fetch(`${API_URL}/${safePath(pathParts)}`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        method,
        body: JSON.stringify(data),
    }).then(
        res => res.json(),
        res => {
            throw new NonLoggableError(res);
        }
    );
};

const backend = {
    getClusters: (): Promise<Array<Cluster>> => {
        return makeRequest(["clusters"]).then(({ clusters }) => clusters);
    },

    addCluster: (clusterObj: ClusterRequest): Promise<Cluster> => {
        return makeRequest(["clusters"], clusterObj, "POST").then(
            ({ cluster }) => cluster
        );
    },

    deleteCluster: (clusterId: ClusterId): Promise<{ success: boolean }> => {
        return makeRequest(["clusters", clusterId], undefined, "DELETE").then(
            result => result
        );
    },

    ping: (): Promise<{ success: boolean }> => {
        return makeRequest(["ping"]).then(result => result);
    },
};

export default backend;
