import { Maybe } from "util/maybe";

import { State as RouteState, Router } from "router5";
import { DispatchFunction, State } from "data";
import { Cluster } from "data/models";

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router5";
import { createRouteNodeSelector } from "redux-router5";

import { selectCurrentCluster } from "data/selectors/clusters";

import Loading from "view/components/loading";
import ClusterLayout from "view/cluster/cluster-layout";

import { getClusters } from "worker/api/clusters";

import "./cluster.scss";

type StateProps = {
    connected: boolean;
    cluster: Maybe<Cluster>;
    route: RouteState;
};

type Props = StateProps & {
    router: Router;
    dispatch: DispatchFunction;
};

class ClusterController extends React.Component<Props> {
    redirectIfDisconnected() {
        const { router, connected, route } = this.props;

        const {
            params: { clusterId },
        } = route;

        if (!connected) {
            const redirectInfo = {
                name: route.name,
                params: route.params,
            };

            router.navigate("connect.cluster", {
                clusterId,
                redirect: JSON.stringify(redirectInfo),
            });
        }
    }

    componentDidMount() {
        this.props.dispatch(getClusters());
        this.redirectIfDisconnected();
    }

    componentDidUpdate() {
        this.redirectIfDisconnected();
    }

    render() {
        const { connected, cluster } = this.props;

        if (connected && cluster) {
            return <ClusterLayout />;
        } else {
            return (
                <div className="cluster-controller-loading-wrapper">
                    <Loading className="loading" size="large" />
                </div>
            );
        }
    }
}

export default compose(
    withRouter,
    connect(
        (s: State): StateProps => {
            const {
                state: { connected },
            } = s.connection;

            const routeNodeSelector = createRouteNodeSelector("cluster");

            return {
                connected,
                cluster: selectCurrentCluster(s),
                route: routeNodeSelector(s).route,
            };
        }
    )
)(ClusterController);
