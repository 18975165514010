import { InputType } from "view/components/text-input";
import { WrappedFieldProps } from "redux-form";

import * as React from "react";
import classnames from "classnames";

import Input from "view/components/text-input";
import Icon from "view/components/icon";
import { Checkbox } from "view/components/checkbox";
import RadioButton from "view/components/radio-button";

import { logError } from "util/logging";

import "./form-element.scss";

/**
 * The FormElement is designed to be used as the rendering component for a
 * Redux-Form Field.
 *
 * Ex:
 * import { Field } from "redux-form";
 * import FormElement from "view/components/form-element";
 *
 * <Field
 *   name="firstName"
 *   label="First Name"
 *   type="text"
 *   component={FormElement}
 *   componentType="input"
 * />
 */

type Props<P> = WrappedFieldProps &
    P & {
        className?: string;
        label?: string;
        message?: string;
        type:
            | InputType
            | "checkbox"
            | "radio"
            | React.ComponentClass<WrappedFieldProps & P>;
    };

export default class FormElement<P> extends React.Component<Props<P>> {
    render() {
        const {
            type,
            className,
            label,
            message,
            input,
            meta,
            meta: { error, ...metaBools },
            ...rest
        } = this.props;

        // We are specifically overriding the onBlur handler such that it calls
        // this.props.onBlur with *no* event argument.  The reason is that
        // Redux-Form incorrectly updates the value of the field onBlur.
        // This is incorrect since in certain cases like autocompletion the
        // browser can fire multiple event-handlers in a single frame. When this
        // happens, the onBlur handle will see the stale input value rather than
        // the new, updated value.
        //
        // Discussion: https://github.com/erikras/redux-form/issues/2768
        // Test we are depending on:
        // https://github.com/erikras/redux-form/blob/38d58d9c9040d86efd3e09b079e7b3109c688f15/src/__tests__/reducer.blur.spec.js#L70
        const fixedInput = {
            ...input,
            onBlur: () => {
                // @ts-ignore
                input.onBlur();
            },
        };

        const classes = classnames(
            {
                "components-form-element": true,
                ...metaBools,
            },
            className
        );

        let inputEl;
        if (typeof type === "string") {
            if (type === "textarea") {
                inputEl = (
                    <Input
                        type="textarea"
                        input={fixedInput}
                        meta={meta}
                        {...rest}
                    />
                );
            } else if (type === "password") {
                inputEl = (
                    <Input
                        type="password"
                        input={fixedInput}
                        meta={meta}
                        {...rest}
                    />
                );
            } else if (type === "text") {
                inputEl = (
                    <Input
                        type="text"
                        input={fixedInput}
                        meta={meta}
                        {...rest}
                    />
                );
            } else if (type === "checkbox") {
                inputEl = (
                    <Checkbox
                        inputProps={{ ...fixedInput, ...meta, ...rest }}
                    />
                );
            } else if (type === "radio") {
                const { checked, value, name, ...restInput } = fixedInput;

                inputEl = (
                    <RadioButton
                        label={label}
                        meta={meta}
                        name={name}
                        value={value}
                        checked={Boolean(checked)}
                        {...rest}
                        {...restInput}
                    />
                );
            } else {
                logError(new Error(`Unexpected FormElement type - ${type}`));
            }
        } else {
            const CustomInput = type as React.ComponentClass<unknown>;
            inputEl = <CustomInput input={fixedInput} meta={meta} {...rest} />;
        }

        let messageEl;
        if (error && meta.touched) {
            messageEl = (
                <div className="message-wrapper">
                    <Icon icon="exclamation-triangle" /> {error}
                </div>
            );
        } else if (message) {
            messageEl = <div className="message-wrapper" children={message} />;
        }

        let inner;
        if (type === "checkbox") {
            inner = (
                <div className="checkbox-wrapper">
                    {inputEl}
                    <div className="content-wrapper">
                        {label && (
                            <label
                                className="checkbox-label"
                                htmlFor={input.name}
                                children={label}
                            />
                        )}
                        <div className="message-wrapper">{messageEl}</div>
                    </div>
                </div>
            );
        } else if (type === "radio") {
            // no label because our RadioButton already has one
            inner = (
                <div className="checkbox-wrapper">
                    {inputEl}
                    <div className="content-wrapper">{messageEl}</div>
                </div>
            );
        } else {
            inner = (
                <>
                    {label && (
                        <div className="label-wrapper">
                            <label htmlFor={input.name} children={label} />
                        </div>
                    )}
                    {inputEl}
                    {messageEl}
                </>
            );
        }

        return <div className={classes}>{inner}</div>;
    }
}
