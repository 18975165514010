import { Maybe } from "util/maybe";
import { TableSort } from "util/sort";

import { StaticConnectionId } from "worker/net/connection-manager";

import { Metrics } from "worker/net/static-connection";
import { QueryGroupRepr } from "worker/net/query-executor";

import {
    QueryExecutorMsg,
    QueryExecutorState,
} from "worker/net/query-executor";

import {
    ConnectQueryEditorAction,
    EditBufferAction,
    CursorInfoAction,
    AppendAndFocusAction,
    SortResultsAction,
    ResetFocusCountAction,
} from "data/actions/query-editor";

import { CursorInfo } from "data/models";

import {
    InitQueryExecutorAction,
    QueryGroupStartAction,
    QueryGroupStopAction,
    QueryExecutorCancelAction,
    QueryExecutorMessageAction,
    ConnectAction,
    ChangeEditorHeightAction,
    ChangeEditorWidthAction,
    NewEditorTabAction,
    CloseEditorTabAction,
    ChangeEditorActiveTabAction,
    ChangeEditorActiveSectionTabAction,
} from "data/actions";

import { FieldList } from "mysqljs";
import { Row } from "worker/net/static-connection";

import _ from "lodash";
import assign from "util/assign";
import { readStudioConfig } from "util/studio-config";

type Actions =
    | ConnectQueryEditorAction
    | EditBufferAction
    | CursorInfoAction
    | AppendAndFocusAction
    | SortResultsAction
    | InitQueryExecutorAction
    | QueryGroupStartAction
    | QueryGroupStopAction
    | QueryExecutorCancelAction
    | QueryExecutorMessageAction
    | ConnectAction
    | ChangeEditorHeightAction
    | ChangeEditorWidthAction
    | NewEditorTabAction
    | CloseEditorTabAction
    | ChangeEditorActiveTabAction
    | ChangeEditorActiveSectionTabAction
    | ResetFocusCountAction;

export const PER_QUERY_ROW_LIMIT = 300;

type QueryEditorRowsResultsBase = {
    type: "rows";
    fields: FieldList;
    rows: Array<Row>;
};

export type QueryEditorRowsResultsLoading = QueryEditorRowsResultsBase & {
    loading: true;
};

export type QueryEditorRowsResultsSuccess = QueryEditorRowsResultsBase & {
    loading: false;
    metrics: Metrics;
};

export type QueryEditorRowsResultsError = QueryEditorRowsResultsBase & {
    loading: false;
    error: true;
};

export type QueryEditorRowsResults =
    | QueryEditorRowsResultsLoading
    | QueryEditorRowsResultsSuccess
    | QueryEditorRowsResultsError;

export type QueryEditorUnknownResults = {
    type: "unknown";
    loading: true;
};

export type QueryEditorExecResults = {
    type: "exec";
    loading: false;
    metrics: Metrics;
};

// This type represents error results for queries that
// errored before they started or for queries that errored
// before any results (rows) came in. If an error occurs
// after some rows have already come in, then the state of
// results will be of type QueryEditorRowsResults with
// "error" set to true. See the QueryEditorRowsResult type.
export type QueryEditorErrorResults = {
    type: "error";
    loading: false;
    summary: string;
};

// Represents the full results obtained from the last query in a group.
export type QueryResults =
    | QueryEditorRowsResults
    | QueryEditorUnknownResults
    | QueryEditorExecResults
    | QueryEditorErrorResults;

// Represents the status of any query in a group.
export type QueryStatus =
    | { status: "success"; metrics: Metrics }
    | { status: "error"; metrics: Metrics; errorMessage: string };

export type EditorSectionTab = "LOGS" | "RESULT";

export type TabState =
    | {
          // Globally unique ID across all tabs that will be opened in a Studio
          // session, which identifies this tab even as other tabs may be opened and
          // closed.
          id: number;
          empty: true;
      }
    | {
          id: number;
          empty: false;

          results: QueryResults;
          sort: TableSort;

          // All queries in the group, including not only successes and
          // failures but also queries that haven't run yet.
          queryGroup: QueryGroupRepr;

          // Only statuses for queries that have finished running.
          queryStatuses: Array<QueryStatus>;

          activeSectionTab: EditorSectionTab;
      };

export type QueryEditorState = {
    connectionId: StaticConnectionId;
    shouldTryConnect: boolean;
    initQueryExecutorError: Maybe<Error>;
    connectionState: Maybe<QueryExecutorState>;
    buffer: string;
    cursorInfo: CursorInfo;
    focus: number;
    canceling: boolean;
    tabs: Array<TabState>;
    activeTabId: number;

    // ID of the tab that results or errors from the static connection should
    // be added to. It may be undefined if the query is not issued directly to
    // a tab (e.g. selecting a database from the header dropdown), and may
    // differ from activeTabId if the user starts a long query in one tab and
    // then switches tabs.
    connectionTabId: Maybe<number>;

    nextTabId: number;
    selectedDatabase: Maybe<string>;

    dimensions: {
        editorWidthFraction: Maybe<number>; // populated on first page open
        editorHeightFraction: number;
    };
};

export const CONNECTION_ID = "QUERY_EDITOR";
export const DEFAULT_EDITOR_HEIGHT_FRACTION = 0.5;

const initialState: QueryEditorState = {
    connectionId: CONNECTION_ID,
    shouldTryConnect: false,
    initQueryExecutorError: undefined,
    connectionState: undefined,
    buffer: "",
    cursorInfo: { index: 0 },
    focus: 0,
    canceling: false,
    tabs: [{ id: 0, empty: true }],
    activeTabId: 0,
    connectionTabId: undefined,
    nextTabId: 1,
    selectedDatabase: undefined,

    dimensions: {
        editorWidthFraction: undefined,
        editorHeightFraction: DEFAULT_EDITOR_HEIGHT_FRACTION,
    },
};

// Modify the TabState with the given tabId. Doesn't do anything if the tabId
// doesn't match any tab. This could happen if the tabId is undefined because
// it's a modification caused by a query not directed at any tab (e.g.
// selecting a database from the header dropdown).
const modifyTabState = (
    state: QueryEditorState,
    tabId: Maybe<number>,
    modifier: (tabState: TabState) => TabState
): QueryEditorState => {
    state = {
        ...state,
        tabs: state.tabs.map(tab => {
            if (tab.id === tabId) {
                return modifier(tab);
            } else {
                return tab;
            }
        }),
    };

    return state;
};

export default (state: QueryEditorState = initialState, action: Actions) => {
    switch (action.type) {
        case "CONNECT": {
            if (action.error) {
                return state;
            }

            const config = action.payload.config;
            if (!config) {
                return state;
            }

            const { clusterId } = config;
            const clusterConfig = readStudioConfig().clusters[clusterId];

            if (clusterConfig) {
                const { queryEditor } = clusterConfig;

                state = assign(state, {
                    buffer: queryEditor ? queryEditor.buffer : "",
                });
            }

            break;
        }

        case "CONNECT_QUERY_EDITOR": {
            state = { ...state, shouldTryConnect: true };
            break;
        }

        case "QUERY_EDITOR_APPEND_AND_FOCUS": {
            state = {
                ...state,
                ...action.payload,
            };

            break;
        }

        case "QUERY_EDITOR_RESET_FOCUS_COUNT": {
            state = {
                ...state,
                focus: 0,
            };

            break;
        }

        case "QUERY_EDITOR_EDIT_BUFFER": {
            state = assign(state, {
                buffer: action.payload.buffer,
            });

            break;
        }

        case "QUERY_EDITOR_CURSOR_INFO": {
            state = assign(state, { cursorInfo: action.payload });

            break;
        }

        case "QUERY_EDITOR_SORT": {
            state = modifyTabState(state, state.activeTabId, tabState => {
                if (tabState.empty) {
                    throw new Error(
                        "Expected active `tabState` to be nonempty when sorting."
                    );
                }

                if (tabState.results.type !== "rows") {
                    throw new Error(
                        "Expected active tabState.results to be of type rows."
                    );
                }

                return {
                    ...tabState,
                    sort: action.payload,
                };
            });

            break;
        }

        case "INIT_QUERY_EXECUTOR": {
            if (action.meta.id !== state.connectionId) {
                break;
            }

            state = {
                ...state,
                initQueryExecutorError: action.error
                    ? action.payload
                    : undefined,
            };

            break;
        }

        case "QUERY_GROUP_START": {
            if (action.payload.id !== state.connectionId) {
                break;
            }

            const { meta } = action.payload.queryGroup;

            if (
                meta &&
                (meta.kind === "SHOW_PLAN" || meta.kind === "SELECT_DATABASE")
            ) {
                // This query group is caused by a menu interaction in the SQL
                // Editor page instead of a query entered into the Editor
                // itself, so we don't display it in the output. We also set
                // connectionTabId to undefined so that clients observing the
                // connectionState being ACTIVE don't think the activity is
                // related to a tab.
                break;
            }

            const { queryGroup } = action.payload;
            const activeSectionTab: EditorSectionTab =
                queryGroup.queries.length > 1 ? "LOGS" : "RESULT";

            state = {
                ...state,
                connectionTabId: state.activeTabId,
            };

            state = modifyTabState(state, state.connectionTabId, tabState => ({
                id: tabState.id,
                empty: false,
                results: {
                    loading: true,
                    type: "unknown",
                },
                queryGroup: action.payload.queryGroup,
                queryStatuses: [],
                sort: undefined,
                activeSectionTab,
            }));

            break;
        }

        case "QUERY_EXECUTOR_CANCEL": {
            if (action.payload.id !== state.connectionId) {
                break;
            }

            // We set canceling in the state to `true` and we always unset it
            // whenever we get back sequenceEnd or error.
            state = { ...state, canceling: true };

            break;
        }

        case "QUERY_EXECUTOR_MESSAGE": {
            if (action.payload.id !== state.connectionId) {
                break;
            }

            state = handleQueryExecutorMessage(state, action.payload.msg);

            break;
        }

        case "QUERY_GROUP_STOP": {
            // make sure we're not loading any more
            if (action.payload.id !== state.connectionId) {
                break;
            }

            state = modifyTabState(state, state.connectionTabId, tabState => {
                if (tabState.empty) {
                    throw new Error(
                        "Expected connection `tabState` to be nonempty when the user stops a query."
                    );
                }

                return {
                    ...tabState,
                    results: {
                        type: "error",
                        loading: false,
                        summary: `Stopped by user at query ${
                            tabState.queryStatuses.length
                        } of ${tabState.queryGroup.queries.length}`,
                    },
                    activeSectionTab: "LOGS",
                };
            });

            break;
        }

        case "QUERY_EDITOR_CHANGE_EDITOR_HEIGHT": {
            state = {
                ...state,
                dimensions: {
                    ...state.dimensions,
                    editorHeightFraction: action.payload.height,
                },
            };

            break;
        }

        case "QUERY_EDITOR_CHANGE_EDITOR_WIDTH": {
            state = {
                ...state,
                dimensions: {
                    ...state.dimensions,
                    editorWidthFraction: action.payload.width,
                },
            };

            break;
        }

        case "QUERY_EDITOR_NEW_TAB": {
            const tabId = state.nextTabId;

            state = {
                ...state,
                tabs: [
                    ...state.tabs,
                    {
                        empty: true,
                        id: tabId,
                    },
                ],
                activeTabId: tabId,
                nextTabId: tabId + 1,
            };

            break;
        }

        case "QUERY_EDITOR_CLOSE_TAB": {
            // update list of tabs to remove the closed one
            const closingTabId = action.payload.tabId;
            const oldTabs = state.tabs;
            const newTabs = oldTabs.filter(tab => tab.id !== closingTabId);

            // check if we closed the active tab
            let { activeTabId } = state;
            if (activeTabId === closingTabId) {
                // we closed the tab that was active; we need to figure out a
                // new tab to be active
                const activeTabIndex = _.findIndex(oldTabs, {
                    id: closingTabId,
                });

                if (activeTabIndex < newTabs.length) {
                    // make the tab in the same position active
                    activeTabId = newTabs[activeTabIndex].id;
                } else {
                    // we closed the last tab; make the new last tab active
                    activeTabId = newTabs[newTabs.length - 1].id;
                }
            }

            state = {
                ...state,
                tabs: newTabs,
                activeTabId,
            };

            break;
        }

        case "QUERY_EDITOR_CHANGE_ACTIVE_TAB": {
            state = {
                ...state,
                activeTabId: action.payload.activeTabId,
            };

            break;
        }

        case "QUERY_EDITOR_CHANGE_ACTIVE_SECTION_TAB": {
            state = modifyTabState(state, state.activeTabId, tabState => {
                if (tabState.empty) {
                    throw new Error(
                        "Expected connection `tabState` to be nonempty when changing section tab."
                    );
                }

                return {
                    ...tabState,
                    activeSectionTab: action.payload.activeTab,
                };
            });

            break;
        }
    }

    return state;
};

const handleQueryExecutorMessage = (
    state: QueryEditorState,
    msg: QueryExecutorMsg
): QueryEditorState => {
    switch (msg.kind) {
        case "queryExecutorState": {
            state = assign(state, { connectionState: msg.state });
            break;
        }

        case "queryMessage": {
            if (
                msg.meta &&
                (msg.meta.kind === "SHOW_PLAN" ||
                    msg.meta.kind === "SELECT_DATABASE")
            ) {
                // This query group is caused by a menu interaction in the SQL
                // Editor page instead of a query entered into the Editor
                // itself, so we don't display it in the output.
                break;
            }

            switch (msg.queryMsg.kind) {
                case "resultSet": {
                    if (!msg.lastInGroup) {
                        break;
                    }

                    const { fields } = msg.queryMsg;

                    state = modifyTabState(
                        state,
                        state.connectionTabId,
                        tabState => {
                            if (tabState.empty) {
                                throw new Error(
                                    "Expected connection `tabState` to be nonempty when receiving resultSet."
                                );
                            }

                            return {
                                ...tabState,
                                results: {
                                    fields,
                                    rows: [],
                                    type: "rows",
                                    loading: true,
                                },
                            };
                        }
                    );
                    break;
                }

                case "rows":
                    if (!msg.lastInGroup) {
                        break;
                    }

                    const { rows } = msg.queryMsg;

                    state = modifyTabState(
                        state,
                        state.connectionTabId,
                        tabState => {
                            if (tabState.empty) {
                                throw new Error(
                                    "Expected connection `tabState` to be nonempty when receiving rows."
                                );
                            }

                            if (tabState.results.type !== "rows") {
                                throw new Error(
                                    "Expected connecton tabState.results to be of type rows."
                                );
                            }

                            return {
                                ...tabState,
                                results: {
                                    ...tabState.results,
                                    rows: [...tabState.results.rows, ...rows],
                                },
                            };
                        }
                    );

                    break;

                case "queryEnd": {
                    const { status, metrics } = msg.queryMsg;

                    if (msg.lastInGroup) {
                        state = { ...state, canceling: false };
                    }

                    state = modifyTabState(
                        state,
                        state.connectionTabId,
                        tabState => {
                            if (tabState.empty) {
                                throw new Error(
                                    "Expected connection `tabState` to be nonempty when receiving queryEnd."
                                );
                            }

                            if (status.state === "error") {
                                if (msg.lastInGroup) {
                                    if (tabState.results.type === "unknown") {
                                        tabState = {
                                            ...tabState,
                                            results: {
                                                type: "error",
                                                loading: false,
                                                summary: status.message,
                                            },
                                            sort: undefined,
                                            activeSectionTab: "LOGS",
                                        };
                                    } else {
                                        if (
                                            !(tabState.results.type === "rows")
                                        ) {
                                            throw new Error(
                                                `Only expected mid-execution errors in "unknown" or "rows" queries. "Exec" queries would be in the "unknown" category at this point.`
                                            );
                                        }

                                        tabState = {
                                            ...tabState,
                                            results: {
                                                type: "rows",
                                                fields: tabState.results.fields,
                                                rows: tabState.results.rows,
                                                error: true,
                                                loading: false,
                                            },
                                            sort: undefined,
                                        };
                                    }
                                }

                                tabState = {
                                    ...tabState,
                                    queryStatuses: [
                                        ...tabState.queryStatuses,
                                        {
                                            status: "error",
                                            metrics,
                                            errorMessage: status.message,
                                        },
                                    ],
                                    activeSectionTab: "LOGS",
                                };
                            } else {
                                if (msg.lastInGroup) {
                                    const { results } = tabState;

                                    if (results.type === "unknown") {
                                        // finished exec query
                                        tabState = {
                                            ...tabState,
                                            results: {
                                                type: "exec",
                                                loading: false,
                                                metrics,
                                            },
                                            sort: undefined,
                                            activeSectionTab: "RESULT",
                                        };
                                    } else {
                                        // finished rows query
                                        if (results.type !== "rows") {
                                            throw new Error(
                                                "Expected results.type to be rows"
                                            );
                                        }

                                        tabState = {
                                            ...tabState,
                                            results: {
                                                type: "rows",
                                                loading: false,
                                                rows: results.rows,
                                                metrics,
                                                fields: results.fields,
                                            },
                                            sort: undefined,
                                            activeSectionTab: "RESULT",
                                        };
                                    }
                                }

                                tabState = {
                                    ...tabState,
                                    queryStatuses: [
                                        ...tabState.queryStatuses,
                                        {
                                            status: "success",
                                            metrics,
                                        },
                                    ],
                                };
                            }

                            return tabState;
                        }
                    );

                    break;
                }
            }
            break;
        }

        case "selectedDatabaseChanged": {
            state = {
                ...state,
                selectedDatabase: msg.database,
            };

            break;
        }
    }

    return state;
};
