import { Maybe } from "util/maybe";

import { Router, State as RouteState } from "router5";
import { State, DispatchFunction } from "data";
import { DatabasesSelection } from "data/selectors/schema";

import { DerivedDatabase, DatabaseName } from "data/models";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRoute } from "react-router5";

import SchemaTable from "view/schema/schema-table";
import SchemaContent from "view/schema/schema-content";
import EmptyTable from "view/schema/table-empty";

import ExtLink from "view/components/external-link";
import { Alert } from "view/components/alert";

import {
    selectSortedDatabases,
    selectAnyDatabasesOfflineWithoutPartitions,
} from "data/selectors/schema";

import { selectHaEnabled } from "data/selectors/cluster-metadata";
import { selectNumAggregators, selectNumLeaves } from "data/selectors/topology";

import { DATABASE_COLUMNS } from "memsql/schema-column-info";

import { logError } from "util/logging";

import "./page-databases.scss";

type StateProps = {
    databases: Maybe<DatabasesSelection>;
    databasesWithPermissionWarn: boolean;

    haEnabled: Maybe<boolean>;

    numAggregators: Maybe<number>;
    numLeaves: Maybe<number>;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
    router: Router;
    route: RouteState;
};

class DatabasesPage extends React.Component<Props> {
    handleClickCell = (databaseName: DatabaseName) => {
        const {
            router,
            route: {
                params: { clusterId },
            },
        } = this.props;

        router.navigate("cluster.databases.tables", {
            clusterId,
            databaseName,
        });
    };

    render() {
        const { databases, databasesWithPermissionWarn } = this.props;

        // should never happen
        if (!databases) {
            logError(
                new Error("DatabasesPage is being rendered without valid data.")
            );
            return null;
        }

        let mainContent;

        if (databases.length === 0) {
            mainContent = (
                <EmptyTable
                    title="Your cluster is empty."
                    description={
                        <ExtLink
                            name="create-database"
                            category="schema-explorer"
                        >
                            How to create a database?
                        </ExtLink>
                    }
                />
            );
        } else {
            let offlineDatabasesAlert;
            if (databasesWithPermissionWarn) {
                offlineDatabasesAlert = (
                    <Alert
                        title={
                            <div className="offline-title">
                                One or more of your databases are offline, or
                                you may not have access to these databases.
                            </div>
                        }
                        message={
                            <>
                                One or more databases are currently in an
                                Offline state because at least one of their
                                partitions is Offline. This issue might be
                                caused by insufficient access to the databases.
                                Please check with the cluster admin to make sure
                                you have been granted access to these databases.
                                <ExtLink
                                    name="database-states"
                                    category="schema-explorer"
                                    className="database-states-help-link"
                                >
                                    Learn more about database states
                                </ExtLink>
                            </>
                        }
                        status="error"
                        className="alert-offline-databases"
                    />
                );
            }

            mainContent = (
                <div className="schema-page-databases-main">
                    {offlineDatabasesAlert}

                    <SchemaTable
                        entityKind="DATABASE"
                        columns={DATABASE_COLUMNS}
                        rows={databases}
                        getRowId={({ database }: DerivedDatabase) =>
                            database.databaseName
                        }
                        onClickCell={this.handleClickCell}
                    />
                </div>
            );
        }

        return <SchemaContent mainContent={mainContent} />;
    }
}

export default compose(
    withRoute,
    connect(
        (s: State): StateProps => ({
            databases: selectSortedDatabases(s),
            haEnabled: selectHaEnabled(s),
            numAggregators: selectNumAggregators(s),
            numLeaves: selectNumLeaves(s),
            databasesWithPermissionWarn: selectAnyDatabasesOfflineWithoutPartitions(
                s
            ),
        })
    )
)(DatabasesPage);
