import { SuperAction } from "data/actions";

import { combineReducers } from "redux";

import topologyReducer from "data/reducers/topology";
import { TopologyState } from "data/reducers/topology";

import clustersReducer from "data/reducers/clusters";
import { ClustersState } from "data/reducers/clusters";

import connectionReducer from "data/reducers/connection";
import { ConnectionState } from "data/reducers/connection";

import clusterMetadataReducer from "data/reducers/cluster-metadata";
import { ClusterMetadataState } from "data/reducers/cluster-metadata";

import consoleReducer from "data/reducers/console";
import { ConsoleState } from "data/reducers/console";

import queryEditorReducer from "data/reducers/query-editor";
import { QueryEditorState } from "data/reducers/query-editor";

import managementViewsReducer from "data/reducers/management-views";
import { ManagementViewsState } from "data/reducers/management-views";

import bottomPanelReducer from "data/reducers/bottom-panel";
import { BottomPanelState } from "data/reducers/bottom-panel";

import systemStatusReducer from "data/reducers/system-status";
import { SystemStatusState } from "data/reducers/system-status";

import logReducer from "data/reducers/log";
import { LogState } from "data/reducers/log";

import schemaReducer from "data/reducers/schema";
import { SchemaState } from "data/reducers/schema";

import schemaTreeReducer from "data/reducers/schema-tree";
import { SchemaTreeState } from "data/reducers/schema-tree";

import eventsReducer from "data/reducers/events";
import { EventsState } from "data/reducers/events";

import pipelinesReducer from "data/reducers/pipelines";
import { PipelinesState } from "data/reducers/pipelines";

import licenseReducer from "data/reducers/license";
import { LicenseState } from "data/reducers/license";

import remoteVersionReducer from "data/reducers/remote-version";
import { RemoteVersionState } from "data/reducers/remote-version";

import explainReducer from "data/reducers/explain";
import { ExplainState } from "data/reducers/explain";

import { router5Reducer } from "redux-router5";
import { RouterState } from "redux-router5";

import hostsReducer from "data/reducers/hosts";
import { HostsState } from "data/reducers/hosts";

import activeProcessesReducer from "data/reducers/active-processes";
import { ActiveProcessesState } from "data/reducers/active-processes";

import tutorialReducer from "data/reducers/tutorial";
import { TutorialState } from "data/reducers/tutorial";

import { reducer as form, FormStateMap } from "redux-form";

export type State = {
    topology: TopologyState;
    clusters: ClustersState;
    connection: ConnectionState;
    clusterMetadata: ClusterMetadataState;
    console: ConsoleState;
    queryEditor: QueryEditorState;
    explain: ExplainState;
    bottomPanel: BottomPanelState;
    managementViews: ManagementViewsState;
    systemStatus: SystemStatusState;
    log: LogState;
    schema: SchemaState;
    events: EventsState;
    schemaTree: SchemaTreeState;
    pipelines: PipelinesState;
    license: LicenseState;
    remoteVersion: RemoteVersionState;
    router: RouterState;
    form: FormStateMap;
    hosts: HostsState;
    activeProcesses: ActiveProcessesState;
    tutorial: TutorialState;
};

const appReducer = combineReducers<State>({
    topology: topologyReducer,
    clusters: clustersReducer,
    connection: connectionReducer,
    clusterMetadata: clusterMetadataReducer,
    console: consoleReducer,
    queryEditor: queryEditorReducer,
    explain: explainReducer,
    managementViews: managementViewsReducer,
    router: router5Reducer,
    bottomPanel: bottomPanelReducer,
    systemStatus: systemStatusReducer,
    log: logReducer,
    schema: schemaReducer,
    events: eventsReducer,
    schemaTree: schemaTreeReducer,
    pipelines: pipelinesReducer,
    license: licenseReducer,
    remoteVersion: remoteVersionReducer,
    form,
    hosts: hostsReducer,
    activeProcesses: activeProcessesReducer,
    tutorial: tutorialReducer,
});

export default (state: Partial<State>, action: SuperAction) => {
    if (state && action.type === "RESET_STATE") {
        const { router, clusters, form } = state;

        // What we are doing here is resetting every single cluster-specific reducer
        // to its initial state whenever the RESET_STATE action is dispatched. Read more
        // about this technique here: https://stackoverflow.com/a/35641992. Basically, we
        // call every reducer that is not cluster-specific with its state set to `undefined`
        // which means that they will simply set their state to its initial state.
        state = {
            router,
            clusters,
            form,
        };
    }

    // We are calling the reducer with missing keys for certain parts of the
    // state, and the Redux type definitions do not allow for that. However, it
    // is perfectly fine to do it as every reducer will, by default, return the
    // initial state.
    // @ts-ignore
    return appReducer(state, action);
};
