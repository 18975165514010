import * as React from "react";

import "./loading-bar.scss";

type Props = {
    width: number | "100%";
};

export function LoadingBar(props: Props) {
    const { width } = props;

    return <div className="components-loading-bar" style={{ width }} />;
}
