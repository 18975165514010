import { Observable } from "rxjs";
import { State } from "data/reducers";
import { State as RouteState } from "router5";
import { Maybe } from "util/maybe";

import Sentry from "util/sentry";
import { forkJoin } from "rxjs/observable/forkJoin";

import { selectLicenseInfo } from "data/selectors/license";
import { selectMemsqlVersion } from "data/selectors/cluster-metadata";

import * as analytics from "util/segment";

declare var STUDIO_VERSION: string;

// This object defines a route transition. During the first
// transition, it can happen that `previousRoute` is not
// defined.
type RouteTransition = {
    previousRoute?: RouteState;
    newRoute: RouteState;
};

// This RegEx matches all literal dots (".")
const ALL_DOTS = /\./g;

export const cleanRouteName = (path: string) =>
    `/${path.replace(ALL_DOTS, "/")}`;

// This Redux store listener fires certain Segment analytics
// actions on route changes. It is careful about there being
// an `analytics` and a `Raven` global variable. These are injected
// at runtime, but only on Production builds.
export default (state$: Observable<State>) => {
    const didChangeRoute$: Observable<RouteTransition> = state$
        .map(
            (state: State): Maybe<RouteTransition> => {
                if (state.router.previousRoute) {
                    return {
                        previousRoute: state.router.previousRoute,
                        newRoute: state.router.route as RouteState,
                    };
                } else if (state.router.route) {
                    return {
                        newRoute: state.router.route as RouteState,
                    };
                }
            }
        )
        .filter((route): route is RouteTransition => Boolean(route))
        .distinctUntilChanged(
            (left: RouteTransition, right: RouteTransition): boolean =>
                left.newRoute.path === right.newRoute.path
        );

    // Whenever the user changes cluster, we identify them with a new cluster
    // license key in Analytics as their User ID.
    const didChangeLicense$: Observable<string> = state$
        .map(
            (state: State): Maybe<string> => {
                const licenseInfo = selectLicenseInfo(state);
                return licenseInfo && licenseInfo.key;
            }
        )
        .filter((key): key is string => Boolean(key))
        .distinctUntilChanged();

    // Whenever the memsql version is changed, we identify them with
    // a new memsql version key in Analytics as memsql_version key.
    const didChangeMemsqlVersion$: Observable<string> = state$
        .map(
            (state: State): Maybe<string> => {
                const version = selectMemsqlVersion(state);

                if (version) {
                    return version.toString();
                }
            }
        )
        .filter((version): version is string => Boolean(version))
        .distinctUntilChanged();

    forkJoin([
        didChangeLicense$.first(),
        didChangeMemsqlVersion$.first(),
    ]).subscribe(([clusterLicenseId, memsqlVersion]) => {
        analytics.identify(clusterLicenseId, {
            memsql_version: memsqlVersion,
            studio_version: STUDIO_VERSION,
            app: "studio",
        });

        Sentry.setLicenseKey(clusterLicenseId);
        Sentry.setMemsqlVersion(memsqlVersion);
    });

    didChangeRoute$.subscribe(({ newRoute }: RouteTransition) => {
        const path = cleanRouteName(newRoute.name);

        // https://segment.com/docs/destinations/google-analytics/#virtual-pageviews
        analytics.page(path);

        Sentry.captureBreadcrumb({
            category: "navigation",
            data: { to: path },
        });
    });
};
