
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import TutorialLoadSQLButton from "view/tutorial/tutorial-load-sql-button";
import { SQL_CREATE_STOCK_TABLES } from "./stock-queries";

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h4" components={components}>{`Step 1: Create Trades Database and Tables`}</MDXTag>
<MDXTag name="p" components={components}>{`Create a database called `}<MDXTag name="inlineCode" components={components} parentName="p">{`trades`}</MDXTag>{` with two tables: `}<MDXTag name="inlineCode" components={components} parentName="p">{`trade`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`company`}</MDXTag>{`. The `}<MDXTag name="inlineCode" components={components} parentName="p">{`trades`}</MDXTag>{` table is a columnstore table containing analytical data about specific trades on a given company and the `}<MDXTag name="inlineCode" components={components} parentName="p">{`company`}</MDXTag>{` table is a small rowstore table that provides metadata about that company.`}</MDXTag>
<TutorialLoadSQLButton id="stock-create-tables" query={SQL_CREATE_STOCK_TABLES} text="Paste Create queries" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  