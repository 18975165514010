import { Maybe } from "util/maybe";
import { CustomScrollbarRef } from "view/components/custom-scrollbar";

import { State } from "data";
import { LogMessageWithID } from "data/reducers/log";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { CustomScrollbar } from "view/components/custom-scrollbar";
import format from "date-fns/format";

import "./tab-log.scss";

type StateProps = {
    messages: Array<LogMessageWithID>;
};

class TabLog extends React.Component<StateProps> {
    scrollback: React.RefObject<CustomScrollbarRef> = React.createRef();

    componentDidMount() {
        this.scrollToLastMessage();
    }

    componentDidUpdate(prevProps: StateProps) {
        const lastMessage: Maybe<LogMessageWithID> =
            prevProps.messages.length >= 1
                ? _.last(prevProps.messages)
                : undefined;

        // There is a new message if the last message in the current `props` is
        // different from the `lastMessage` that is the previous last message
        const newMessage =
            this.props.messages.length >= 1 &&
            lastMessage &&
            _.last(this.props.messages) !== lastMessage;

        if (newMessage) {
            this.scrollToLastMessage();
        }
    }

    scrollToLastMessage() {
        if (this.scrollback.current) {
            this.scrollback.current.scrollToBottom();
        }
    }

    render() {
        const { messages } = this.props;

        return (
            <CustomScrollbar ref={this.scrollback} dark>
                <div className="tab-log">
                    {_.map(
                        messages,
                        ({
                            id,
                            msg: { timestamp, msg, level },
                        }: LogMessageWithID) => (
                            <div className="log-line" key={id}>
                                <span className="time">
                                    {format(timestamp, "HH:mm:ss")}:
                                </span>{" "}
                                <span className={level}>{msg}</span>
                            </div>
                        )
                    )}
                </div>
            </CustomScrollbar>
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        messages: s.log.messages,
    })
)(TabLog);
