import BigNumber from "vendor/bignumber.js/bignumber";

export const plural = (
    s: string,
    n: number | BigNumber,
    overridePlural?: string
): string => {
    if (typeof n === "number") {
        n = new BigNumber(n);
    }

    if (n.eq(1)) {
        return s;
    } else {
        return overridePlural === undefined ? s + "s" : overridePlural;
    }
};
