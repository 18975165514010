import { Maybe } from "util/maybe";
import { SortDirection, TableSort } from "util/sort";
import { TabTitleProps } from "view/components/tab";

import { DispatchFunction, State as ReduxState } from "data";
import { FieldInfo } from "mysqljs";
import { Row } from "worker/net/static-connection";
import { ResultsSelection } from "data/selectors/page-editor";
import {
    Column,
    ColumnId,
    Row as SuperTableRow,
    RowWithHeight as SuperTableRowWithHeight,
} from "view/components/super-table";
import { QueryExecutorState, QueryGroupRepr } from "worker/net/query-executor";
import {
    QueryStatus,
    EditorSectionTab,
    TabState,
} from "data/reducers/query-editor";
import { Entry as InlineBarEntry } from "view/components/inline-bar";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import classnames from "classnames";
import NumberFormatter from "util/number-formatter";

import {
    selectConnectionState,
    selectCurrentEditorResults,
    selectQuerySuccessCount,
    selectQueryErrorCount,
    selectQuerySkippedCount,
    selectQueryGroup,
    selectQueryStatuses,
    selectSort,
    selectCanExportResultsAsCSV,
    selectTabs,
    selectActiveSectionTab,
    selectActiveTabIndex,
    selectConnectionTabId,
    selectRunningTabId,
    selectActiveTabIsRunning,
    selectCanOpenNewTab,
} from "data/selectors/page-editor";
import { EDITOR_MAX_TABS_EXPLANATION } from "view/editor/constants";

import { sortResults, saveResultsAsCSV } from "data/actions/query-editor";
import {
    newEditorTab,
    closeEditorTab,
    changeEditorActiveTab,
    changeEditorActiveSectionTab,
} from "data/actions/query-editor";

import Tip from "view/components/tip";
import Icon from "view/components/icon";
import SuperTable, { addSort } from "view/components/super-table";
import ResultsTableHeader from "view/editor/results-output/results-table-header";
import { DragHandle } from "view/components/drag-handle";
import { EmptyResultsPlaceholder } from "view/editor/results-output/empty-results-placeholder";
import { Tabs, Tab, TabTitle } from "view/components/tab";
import Loading from "view/components/loading";
import CenteringWrapper from "view/components/centering-wrapper";
import { ResizeButtons } from "view/components/resize-buttons";
import LabeledInlineBar from "view/components/labeled-inline-bar";

import { LONG_EM_DASH } from "util/symbols";
import { COLORS } from "util/colors";
import { nextTableSort } from "util/sort";

import "./output-container.scss";

type StateProps = {
    results: Maybe<ResultsSelection>;
    successCount: Maybe<number>;
    errorCount: Maybe<number>;
    skippedCount: Maybe<number>;
    queryGroup: Maybe<QueryGroupRepr>;
    queryStatuses: Maybe<Array<QueryStatus>>;
    sort: TableSort;
    connectionState: Maybe<QueryExecutorState>;
    activeSectionTab: Maybe<EditorSectionTab>;
    canExportResultsAsCSV: boolean;

    tabs: Array<TabState>;
    activeTabIndex: number;
    connectionTabId: Maybe<number>;
    runningTabId: Maybe<number>;
    activeTabIsRunning: boolean;
    canOpenNewTab: boolean;
};

type Props = StateProps & {
    dispatch: DispatchFunction;

    heightFraction: number;
    restoreHeightFraction: number;
    onResizeHeight: (height: number) => void;
    onResizeHeightFraction: (heightFraction: number) => void;
    onResizeComplete?: (movedConsiderableAmount: boolean) => void;
};

const SECTION_TABS: Array<EditorSectionTab> = ["LOGS", "RESULT"];
const SECTION_TAB_TITLES: Array<React.ReactElement<TabTitleProps>> = [
    <TabTitle title="Message Logs" />,
    <TabTitle title="Result" />,
];

class EditorOutputContainer extends React.Component<Props> {
    $el: Maybe<HTMLDivElement>;

    handleClickSaveAsCSV = () => {
        this.props.dispatch(saveResultsAsCSV());
    };

    handleClickError = () => {
        this.props.dispatch(
            changeEditorActiveSectionTab({ activeTab: "LOGS" })
        );
    };

    handleSort = (columnId: ColumnId, sortDir: Maybe<SortDirection>) => {
        this.props.dispatch(sortResults(nextTableSort(columnId, sortDir)));
    };

    renderStatusInfo = (
        results: ResultsSelection,
        successes: Maybe<number>,
        failures: Maybe<number>,
        skipped: Maybe<number>
    ) => {
        const { activeTabIsRunning } = this.props;

        let loadingStatus;
        if (activeTabIsRunning) {
            loadingStatus = (
                <div className={classnames("query-status", "loading")}>
                    <Loading size="small" rightMargin />
                    Loading
                </div>
            );
        }

        let successStatus, errorStatus, skippedStatus;
        if (successes !== undefined && successes !== 0) {
            successStatus = (
                <div className={classnames("query-status", "success")}>
                    <Icon icon="check-circle" rightMargin />
                    {successes} Success
                </div>
            );
        }
        if (failures !== undefined && failures !== 0) {
            errorStatus = (
                <div
                    className={classnames("query-status", "error")}
                    role="button"
                    onClick={this.handleClickError}
                >
                    <Icon icon="exclamation-circle" rightMargin />
                    {failures} Failed
                </div>
            );
        }
        if (skipped !== undefined && skipped !== 0) {
            skippedStatus = (
                <div className="query-status">
                    <Icon icon="step-forward" iconType="regular" rightMargin />
                    {skipped} Skipped
                </div>
            );
        }

        return (
            <div className="query-statuses">
                {errorStatus} {successStatus} {skippedStatus} {loadingStatus}
            </div>
        );
    };

    renderQueryStatusError = () => {
        return (
            <Icon
                icon="exclamation-circle"
                className="error"
                fixedWidth
                rightMargin
            />
        );
    };

    renderQueryStatusSuccess = () => {
        return (
            <Icon
                icon="check-circle"
                className="success"
                fixedWidth
                rightMargin
            />
        );
    };

    renderQueryStatusSkipped = () => {
        return (
            <Icon
                icon="step-forward"
                iconType="regular"
                fixedWidth
                rightMargin
            />
        );
    };

    renderQueryStatusPending = () => {
        return <Icon icon="clock" fixedWidth rightMargin />;
    };

    renderStatusesTable() {
        const { queryGroup, queryStatuses, connectionState } = this.props;

        let columns: Array<Column> = [];
        let rows: Array<SuperTableRowWithHeight> = [];

        if (queryGroup && queryStatuses) {
            // Render a table of statuses of individual queries in a group
            // (whether they succeeded or failed, how many rows they affected,
            // and how much time they took)

            columns = [
                {
                    id: "query",
                    title: "Query",
                    sort: "DISABLED",
                    defaultMinWidth: 250,
                },
                {
                    id: "time",
                    title: "Time",
                    sort: "DISABLED",
                    defaultMaxWidth: 120,
                },
                {
                    id: "affectedRows",
                    title: "Affected Rows",
                    sort: "DISABLED",
                    defaultMaxWidth: 120,
                },
            ];

            const maxDuration = _.max(
                queryStatuses.map(status => {
                    const { startTimeUnix, endTimeUnix } = status.metrics;

                    if (startTimeUnix && endTimeUnix) {
                        return endTimeUnix - startTimeUnix;
                    }
                })
            );

            rows = _.zipWith(
                queryGroup.queries,
                queryStatuses,
                (query, status) => {
                    let queryNode, statusNode, errorNode, affectedRowsNode;
                    let rowHeight = 60;
                    let timeLabel = LONG_EM_DASH;
                    let timeEntries: Array<InlineBarEntry> | "loading" =
                        "loading";

                    if (status) {
                        const {
                            affectedRows,
                            startTimeUnix,
                            endTimeUnix,
                        } = status.metrics;

                        if (status.status === "success") {
                            statusNode = this.renderQueryStatusSuccess();
                        } else {
                            statusNode = this.renderQueryStatusError();
                            rowHeight = 70;

                            const { errorMessage } = status;

                            errorNode = (
                                <Tip
                                    className="error-text"
                                    onClick={this.handleClickError}
                                    tooltip={
                                        <pre className="error-tip">
                                            {errorMessage}
                                        </pre>
                                    }
                                    direction="e"
                                >
                                    {errorMessage}
                                </Tip>
                            );
                        }

                        if (affectedRows === undefined) {
                            affectedRowsNode = "—";
                        } else {
                            affectedRowsNode = affectedRows;
                        }

                        if (endTimeUnix) {
                            const duration = endTimeUnix - startTimeUnix;
                            const value = maxDuration
                                ? duration / maxDuration
                                : 0;

                            timeLabel = NumberFormatter.formatDuration(
                                duration
                            );
                            timeEntries = [
                                {
                                    value,
                                    fill: COLORS["color-indigo-600"],
                                },
                                {
                                    value: 1 - value,
                                    fill: COLORS["color-neutral-200"],
                                },
                            ];
                        }
                    } else if (
                        connectionState === "ACTIVE" ||
                        connectionState === "PAUSED"
                    ) {
                        statusNode = this.renderQueryStatusPending();
                    } else {
                        statusNode = this.renderQueryStatusSkipped();
                    }

                    if (query.text) {
                        queryNode = (
                            <div className="query-cell">
                                <div className="status">{statusNode}</div>
                                <div className="texts">
                                    <Tip
                                        tooltip={
                                            <pre className="query-tip">
                                                {query.text}
                                            </pre>
                                        }
                                        direction="e"
                                        className="query-text"
                                    >
                                        {query.text}
                                    </Tip>
                                    {errorNode}
                                </div>
                            </div>
                        );
                    }

                    const timeNode = (
                        <LabeledInlineBar
                            label={timeLabel}
                            entries={timeEntries}
                            labelPosition="top"
                        />
                    );

                    return {
                        cells: [queryNode, timeNode, affectedRowsNode],
                        rowHeight,
                    };
                }
            );

            return (
                <div className="table-wrapper">
                    <SuperTable
                        onSort={this.handleSort}
                        columns={columns}
                        rows={rows}
                        addNativeTooltipToStrings
                    />
                </div>
            );
        }

        // This shouldn't happen (we shouldn't render this component if there
        // are no queries and statuses)
        return <CenteringWrapper>No logs available</CenteringWrapper>;
    }

    // We pass in results instead of getting it from props so we can type it
    // without the Maybe
    renderResultsTable(results: ResultsSelection) {
        const { sort, canExportResultsAsCSV } = this.props;

        let columns: Array<Column> = [];
        let rows: Array<SuperTableRow> = [];

        if (results.type === "rows") {
            const addCurrentSort = addSort(sort);
            columns = _.map(results.fields, (f: FieldInfo, idx: number) =>
                addCurrentSort({
                    id: idx.toString(),
                    title: (
                        <Tip
                            direction="n"
                            tooltip={f.name}
                            className="column-header"
                        >
                            {f.name}
                        </Tip>
                    ),
                    defaultMinWidth: 100,
                })
            );

            rows = _.map(results.rows, (row: Row) => ({ cells: row }));
        } else if (results.type === "exec") {
            const metrics = results.metrics;

            columns = [
                {
                    id: "insertId",
                    title: "Insert ID",
                    sort: "DISABLED",
                },
                {
                    id: "affectedRows",
                    title: "Affected Rows",
                    sort: "DISABLED",
                },
                {
                    id: "changedRows",
                    title: "Changed Rows",
                    sort: "DISABLED",
                },
            ];

            rows = [
                {
                    cells: _.map(
                        columns,
                        (col: Column<keyof typeof results["metrics"]>) =>
                            metrics[col.id]
                    ),
                },
            ];
        }

        if (columns && columns.length > 0) {
            return (
                <>
                    <ResultsTableHeader
                        results={results}
                        canSaveAsCSV={canExportResultsAsCSV}
                        onClickSaveAsCSV={this.handleClickSaveAsCSV}
                        onClickError={this.handleClickError}
                    />
                    <div className="table-wrapper">
                        <SuperTable
                            onSort={this.handleSort}
                            columns={columns}
                            rows={rows}
                            rowHeight={28}
                            verticallyAlignCells
                            compact
                            addNativeTooltipToStrings
                        />
                    </div>
                </>
            );
        } else if (results.loading) {
            return (
                <CenteringWrapper>
                    <Loading size="large" />
                    <div className="results-loading-description">
                        Query Running...
                    </div>
                </CenteringWrapper>
            );
        } else {
            return <div className="results-none">No result returned.</div>;
        }
    }

    handleResize = ({ clientY }: MouseEvent) => {
        if (this.$el) {
            const height = this.$el.getBoundingClientRect().bottom - clientY;
            this.props.onResizeHeight(height);
        }
    };

    handleTabChange = (activeTab: number) => {
        const { tabs, dispatch } = this.props;

        dispatch(
            changeEditorActiveTab({
                activeTabId: tabs[activeTab].id,
            })
        );
    };

    handleSectionTabChange = (activeTab: number) => {
        this.props.dispatch(
            changeEditorActiveSectionTab({ activeTab: SECTION_TABS[activeTab] })
        );
    };

    handleNewTab = () => {
        this.props.dispatch(newEditorTab());
    };

    handleCloseTab = (id: number) => {
        this.props.dispatch(closeEditorTab({ tabId: id }));
    };

    computeTabStateTitle(tabState: TabState): string {
        if (tabState.empty) {
            return "Empty Results";
        } else {
            const {
                queryGroup: { queries },
                queryStatuses,
            } = tabState;

            let currentQueryIndex = queryStatuses.length;
            if (currentQueryIndex >= queries.length) {
                currentQueryIndex = queries.length - 1;
            }

            return queries[currentQueryIndex].text;
        }
    }

    handleMinimize = () => {
        this.props.onResizeHeightFraction(0);
    };

    handleRestore = () => {
        this.props.onResizeHeightFraction(this.props.restoreHeightFraction);
    };

    handleMaximize = () => {
        this.props.onResizeHeightFraction(1);
    };

    renderResizeButtons() {
        return (
            <ResizeButtons
                className="resize-buttons"
                onMinimize={this.handleMinimize}
                onRestore={this.handleRestore}
                onMaximize={this.handleMaximize}
                height={this.props.heightFraction}
            />
        );
    }

    render() {
        const {
            results,
            successCount,
            errorCount,
            skippedCount,
            activeSectionTab,
            tabs,
            activeTabIndex,
            connectionState,
            connectionTabId,
            runningTabId,
            canOpenNewTab,
        } = this.props;

        const dragHandle = (
            <DragHandle
                direction="n"
                onResize={this.handleResize}
                onResizeComplete={this.props.onResizeComplete}
            />
        );

        let content;
        if (results && activeSectionTab) {
            content = (
                <Tabs
                    activeTab={SECTION_TABS.indexOf(activeSectionTab)}
                    onChange={this.handleSectionTabChange}
                    titles={SECTION_TAB_TITLES}
                    rightBarContent={this.renderStatusInfo(
                        results,
                        successCount,
                        errorCount,
                        skippedCount
                    )}
                >
                    <Tab>{this.renderStatusesTable()}</Tab>
                    <Tab>{this.renderResultsTable(results)}</Tab>
                </Tabs>
            );
        } else {
            content = <EmptyResultsPlaceholder />;
        }

        const tabTitles = tabs.map(tabState => {
            let onClose, closeDisabledMessage;
            if (tabs.length > 1) {
                onClose = () => this.handleCloseTab(tabState.id);

                // is there an ongoing (active or paused) query group?
                if (
                    tabState.id === connectionTabId &&
                    connectionState !== "IDLE"
                ) {
                    // if so, don't allow the tab to be closed
                    closeDisabledMessage =
                        "You cannot close a tab while queries are running. Wait for the query to finish or cancel it.";
                }
            }

            const title = this.computeTabStateTitle(tabState);

            return (
                <TabTitle
                    title={
                        <Tip
                            className="tab-title-name"
                            tooltip={title}
                            direction="ne"
                        >
                            {title}
                        </Tip>
                    }
                    key={tabState.id}
                    fixedWidth
                    loading={tabState.id === runningTabId}
                    onClose={onClose}
                    closeDisabledMessage={closeDisabledMessage}
                />
            );
        });

        let newTabDisabledMessage;
        if (!canOpenNewTab) {
            newTabDisabledMessage = EDITOR_MAX_TABS_EXPLANATION;
        }

        return (
            <div
                className="output-container"
                ref={el => {
                    this.$el = el || undefined;
                }}
            >
                {dragHandle}
                <Tabs
                    nested
                    activeTab={activeTabIndex}
                    onChange={this.handleTabChange}
                    titles={tabTitles}
                    onNewTab={this.handleNewTab}
                    newTabDisabledMessage={newTabDisabledMessage}
                    tabBarClassName="editor-tab-bar"
                    rightBarContent={this.renderResizeButtons()}
                    borderTop
                >
                    <Tab>{content}</Tab>
                </Tabs>
            </div>
        );
    }
}

export default connect(
    (s: ReduxState): StateProps => ({
        results: selectCurrentEditorResults(s),
        successCount: selectQuerySuccessCount(s),
        errorCount: selectQueryErrorCount(s),
        skippedCount: selectQuerySkippedCount(s),
        queryGroup: selectQueryGroup(s),
        queryStatuses: selectQueryStatuses(s),
        sort: selectSort(s),
        connectionState: selectConnectionState(s),
        activeSectionTab: selectActiveSectionTab(s),
        canExportResultsAsCSV: selectCanExportResultsAsCSV(s),
        tabs: selectTabs(s),
        activeTabIndex: selectActiveTabIndex(s),
        connectionTabId: selectConnectionTabId(s),
        runningTabId: selectRunningTabId(s),
        activeTabIsRunning: selectActiveTabIsRunning(s),
        canOpenNewTab: selectCanOpenNewTab(s),
    })
)(EditorOutputContainer);
