import { Maybe } from "util/maybe";
import { TableSort } from "util/sort";
import { ActiveProcess } from "data/models";
import {
    ActiveProcessesAction,
    SortActiveProcessesAction,
    ActiveProcessesEnabledAction,
    ChangeActiveProcessSelectedAction,
    KilledActiveProcessIdsAction,
} from "data/actions";
import { LSM } from "util/loading-state-machine";

import _ from "lodash";

import { ActiveProcessId } from "data/models";
import {
    getInitialState,
    reduce as reduceLSM,
    selectPayload,
} from "util/loading-state-machine";

type Actions =
    | ActiveProcessesAction
    | SortActiveProcessesAction
    | ActiveProcessesEnabledAction
    | ChangeActiveProcessSelectedAction
    | KilledActiveProcessIdsAction;

export type ActiveProcessesState = {
    sort: TableSort;
    activeProcesses: LSM<Array<ActiveProcess>, string>;
    activeProcessesEnabled: Maybe<boolean>;
    selectedProcessIds: Array<ActiveProcessId>;
    killedProcessIds: Array<ActiveProcessId>;
};

const initialState: ActiveProcessesState = {
    sort: {
        columnId: "elapsedTime",
        direction: "desc",
    },
    activeProcesses: getInitialState(),
    activeProcessesEnabled: undefined,
    selectedProcessIds: [],
    killedProcessIds: [],
};

const NEW_PROCESS_THRESHOLD_MS = 1000;

// Given the list of old and new active processes, decide whether we want to
// keep a previously selected active process ID selected.

// We want to filter out selected active process IDs that are no longer active,
// because there would be no way to deselect them, and because those IDs could
// be reused for other processes later that we wouldn't want to keep selected.

// We also want to filter out selected active process IDs whose submitted times
// are much later (1 second) than the previous active process's submitted time,
// since those are probably process IDs that now refer to a different process.
const shouldKeepProcessSelected = (
    oldActiveProcesses: Array<ActiveProcess>,
    newActiveProcesses: Array<ActiveProcess>,
    processId: ActiveProcessId
) => {
    const oldProcess = _.find(oldActiveProcesses, process =>
        processId.matches(process)
    );

    if (!oldProcess) {
        throw new Error(
            "Found process ID not in current active processes list"
        );
    }

    const newProcess = _.find(newActiveProcesses, process =>
        processId.matches(process)
    );

    if (!newProcess) {
        // The process is no longer active
        return false;
    }

    // Also return false if the process was submitted much later than we expect
    return (
        newProcess.submitted.getTime() - oldProcess.submitted.getTime() <
        NEW_PROCESS_THRESHOLD_MS
    );
};

export default (
    state: ActiveProcessesState = initialState,
    action: Actions
) => {
    switch (action.type) {
        case "ACTIVE_PROCESSES": {
            const oldActiveProcessesArray =
                selectPayload(state.activeProcesses) || [];
            const newActiveProcesses = reduceLSM(state.activeProcesses, action);
            const newActiveProcessesArray =
                selectPayload(newActiveProcesses) || [];

            state = {
                ...state,
                activeProcesses: newActiveProcesses,
                selectedProcessIds: state.selectedProcessIds.filter(id =>
                    shouldKeepProcessSelected(
                        oldActiveProcessesArray,
                        newActiveProcessesArray,
                        id
                    )
                ),
                killedProcessIds: [],
            };

            break;
        }

        case "SORT_ACTIVE_PROCESSES": {
            state = {
                ...state,
                sort: action.payload,
            };

            break;
        }

        case "ACTIVE_PROCESSES_ENABLED": {
            state = {
                ...state,
                activeProcessesEnabled: action.payload,
            };

            break;
        }

        case "CHANGE_ACTIVE_PROCESS_SELECTED": {
            const { processId, selected } = action.payload;
            const { selectedProcessIds } = state;
            const previouslySelected = Boolean(
                selectedProcessIds.some(selectedId => selectedId.eq(processId))
            );

            if (!previouslySelected && selected) {
                state = {
                    ...state,
                    selectedProcessIds: [...selectedProcessIds, processId],
                };
            } else if (previouslySelected && !selected) {
                state = {
                    ...state,
                    selectedProcessIds: selectedProcessIds.filter(
                        selectedId => !selectedId.eq(processId)
                    ),
                };
            }

            break;
        }

        case "KILLED_ACTIVE_PROCESS_IDS": {
            const { processIds } = action.payload;

            state = {
                ...state,
                selectedProcessIds: state.selectedProcessIds.filter(
                    selectedId =>
                        !_.some(processIds, killedId => selectedId.eq(killedId))
                ),
                killedProcessIds: [...state.killedProcessIds, ...processIds],
            };

            break;
        }
    }

    return state;
};
