import { DerivedHost, HostRatioMetric } from "data/models";

import * as React from "react";
import _ from "lodash";

import NodeBadge from "view/components/node-badge";
import InternalLink from "view/components/internal-link";
import { GeneralTableColumn } from "view/components/general-table";
import { RatioMetricCell } from "view/common/ratio-metric-cell";
import { renderLoadingError } from "view/components/render-loading-error";

import {
    formatHostMemory,
    getHostMemoryUsage,
    getHostDiskUsage,
} from "data/models";
import NumberFormatter from "util/number-formatter";
import { LONG_EM_DASH } from "util/symbols";

import "./columns-info.scss";

const HOSTS_ADDRESS_COLUMN: GeneralTableColumn<DerivedHost> = {
    id: "hostAddress",
    title: "Host Address",
    formatter: (host: DerivedHost) => (
        <InternalLink
            routeInfo={{
                name: "cluster.hosts.host",
                params: {
                    hostId: host.address,
                },
            }}
            className="host-address-cell"
            category="schema-explorer"
            clusterLink
            onClick={
                /* stop propagation to the cell click event */ e =>
                    e.stopPropagation()
            }
        >
            {host.address}
        </InternalLink>
    ),
    getValue: (host: DerivedHost) => host.address,
};

const HOSTS_NODES_COLUMN: GeneralTableColumn<DerivedHost> = {
    id: "hostNodes",
    title: "Nodes",
    formatter: (host: DerivedHost) => {
        let numLeaves = 0;
        let numChildAggs = 0;
        let numMasterAggs = 0;

        for (let i = 0; i < host.nodes.length; i++) {
            if (host.nodes[i].role === "LEAF") {
                numLeaves++;
            } else if (host.nodes[i].role === "AGGREGATOR") {
                numChildAggs++;
            } else if (host.nodes[i].role === "MASTER_AGGREGATOR") {
                numMasterAggs++;
            }
        }

        let leavesNode;
        let childAggsNode;
        let masterAggsNode;

        if (numLeaves > 0) {
            leavesNode = (
                <NodeBadge count={numLeaves} role="LEAF" classname="badge" />
            );
        }
        if (numChildAggs > 0) {
            childAggsNode = (
                <NodeBadge
                    count={numChildAggs}
                    role="AGGREGATOR"
                    classname="badge"
                />
            );
        }
        if (numMasterAggs > 0) {
            masterAggsNode = (
                <NodeBadge
                    count={numMasterAggs}
                    role="MASTER_AGGREGATOR"
                    classname="badge"
                />
            );
        }

        return (
            <div className="node-badges-cell">
                {masterAggsNode}
                {childAggsNode}
                {leavesNode}
            </div>
        );
    },
    getValue: (host: DerivedHost) => {
        let maxNode = -1;

        for (let i = 0; i < host.nodes.length; i++) {
            if (host.nodes[i].role === "MASTER_AGGREGATOR") {
                return 2;
            } else if (host.nodes[i].role === "AGGREGATOR" && maxNode < 1) {
                maxNode = 1;
            } else if (host.nodes[i].role === "LEAF" && maxNode < 0) {
                maxNode = 0;
            }
        }

        return maxNode;
    },
};

const HOSTS_CPU_COUNT_COLUMN: GeneralTableColumn<DerivedHost> = {
    id: "hostCpuCount",
    title: "CPU Cores",
    formatter: (host: DerivedHost) =>
        host.cpuCount ? host.cpuCount.toNumber() : LONG_EM_DASH,
    getValue: (host: DerivedHost) => {
        if (host.cpuCount === undefined) {
            return Number.NEGATIVE_INFINITY;
        } else {
            return host.cpuCount.toNumber(); // PLAT-3189
        }
    },
};

const HOSTS_CPU_USAGE_COLUMN: GeneralTableColumn<DerivedHost> = {
    id: "hostTotalCpuUsage",
    title: "Host CPU Usage",
    sort: "DISABLED",
    formatter: (host: DerivedHost) => {
        const renderHostCpuUsage = (cpuUsage: number) => (
            <RatioMetricCell labelPosition="top" value={cpuUsage} />
        );

        return renderLoadingError(
            host.liveMonitoring.totalCpuUsagePercentRate,
            renderHostCpuUsage,
            { cell: true }
        );
    },
    getValue: (host: DerivedHost) => {
        // If loading, return undefined. If error, return null.
        if (host.liveMonitoring.totalCpuUsagePercentRate.isSuccess()) {
            return host.liveMonitoring.totalCpuUsagePercentRate.value;
        } else if (host.liveMonitoring.totalCpuUsagePercentRate.isLoading()) {
            return undefined;
        } else {
            return null;
        }
    },
};

const HOSTS_MEMORY_COLUMN: GeneralTableColumn<DerivedHost> = {
    id: "hostMemory",
    title: "System Memory",
    formatter: formatHostMemory,
    getValue: (host: DerivedHost) => {
        if (host.systemMemoryB === undefined) {
            return Number.NEGATIVE_INFINITY;
        } else {
            return host.systemMemoryB.toNumber(); // PLAT-3189
        }
    },
};

const HOSTS_MEMORY_USAGE_COLUMN: GeneralTableColumn<DerivedHost> = {
    id: "hostSystemMemoryUsage",
    title: "Host Memory Usage",
    sort: "DISABLED",
    formatter: (host: DerivedHost) => {
        const renderHostMemoryUsage = ({
            ratio,
            used,
            total,
        }: HostRatioMetric) => {
            const bottomLabel = `${NumberFormatter.formatBytes(
                used
            )}/${NumberFormatter.formatBytes(total)}`;

            return (
                <RatioMetricCell
                    labelPosition="top"
                    value={ratio}
                    bottomLabel={bottomLabel}
                />
            );
        };

        return renderLoadingError(
            getHostMemoryUsage(host),
            renderHostMemoryUsage,
            { cell: true }
        );
    },
    getValue: (host: DerivedHost) => {
        const {
            liveMonitoring: { systemUsedMemoryB, systemTotalMemoryB },
        } = host;

        if (
            !systemUsedMemoryB.isLoading() &&
            !systemUsedMemoryB.isError() &&
            !systemTotalMemoryB.isLoading() &&
            !systemTotalMemoryB.isError() &&
            !systemTotalMemoryB.value.isZero()
        ) {
            return systemUsedMemoryB.value
                .dividedBy(systemTotalMemoryB.value)
                .toNumber();
        }
    },
};

export const HOST_DISK_USAGE_TIP =
    "Host disk usage displays the used and available disk space aggregated across all mount points being used by all MemSQL nodes on this host";

const HOSTS_DISK_USAGE_COLUMN: GeneralTableColumn<DerivedHost> = {
    id: "hostSystemDiskUsage",
    title: "MemSQL Disk Usage",
    sort: "DISABLED",
    description: HOST_DISK_USAGE_TIP,
    formatter: (host: DerivedHost) => {
        const renderHostDiskUsage = ({
            used,
            total,
            ratio,
        }: HostRatioMetric) => {
            const bottomLabel = `${NumberFormatter.formatBytes(
                used
            )}/${NumberFormatter.formatBytes(total)}`;

            return (
                <RatioMetricCell
                    labelPosition="top"
                    value={ratio}
                    bottomLabel={bottomLabel}
                />
            );
        };

        return renderLoadingError(getHostDiskUsage(host), renderHostDiskUsage, {
            cell: true,
        });
    },
    getValue: (host: DerivedHost) => {
        const {
            liveMonitoring: { systemUsedDiskB, systemTotalDiskB },
        } = host;

        if (
            !systemUsedDiskB.isLoading() &&
            !systemUsedDiskB.isError() &&
            !systemTotalDiskB.isLoading() &&
            !systemTotalDiskB.isError() &&
            !systemTotalDiskB.value.isZero()
        ) {
            return systemUsedDiskB.value
                .dividedBy(systemTotalDiskB.value)
                .toNumber();
        }
    },
};

export function getHostsColumns(physicalMonitoringEnabled: boolean) {
    if (physicalMonitoringEnabled) {
        return [
            HOSTS_ADDRESS_COLUMN,
            HOSTS_NODES_COLUMN,
            HOSTS_CPU_COUNT_COLUMN,
            HOSTS_CPU_USAGE_COLUMN,
            HOSTS_MEMORY_USAGE_COLUMN,
            HOSTS_DISK_USAGE_COLUMN,
        ];
    } else {
        return [
            HOSTS_ADDRESS_COLUMN,
            HOSTS_NODES_COLUMN,
            HOSTS_CPU_COUNT_COLUMN,
            HOSTS_MEMORY_COLUMN,
        ];
    }
}

export const ALL_HOSTS_COLUMNS = [
    HOSTS_ADDRESS_COLUMN,
    HOSTS_NODES_COLUMN,
    HOSTS_CPU_USAGE_COLUMN,
    HOSTS_CPU_COUNT_COLUMN,
    HOSTS_MEMORY_COLUMN,
    HOSTS_DISK_USAGE_COLUMN,
];
