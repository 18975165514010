import { Maybe } from "util/maybe";

import { State as ReduxState } from "data";
import { Router, State as RouteState } from "router5";
import { RouteInfo } from "router/types";
import { Cluster } from "data/models";

import * as React from "react";
import _ from "lodash";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRoute } from "react-router5";

import { Menu, MenuItem } from "view/common/menu";
import Modal from "view/components/modal";
import SimpleModalBody from "view/components/simple-modal-body";
import { Button } from "view/common/button";
import Icon from "view/components/icon";

import ClusterProfileIcon from "view/cluster/profile-icon";

import { selectClusters, selectCurrentCluster } from "data/selectors/clusters";
import * as analytics from "util/segment";

import "./cluster-switcher.scss";

type StateProps = {
    clusters: Maybe<Array<Cluster>>;
    currentCluster: Maybe<Cluster>;
    allowClusterMutations: boolean;
};

type Props = StateProps & {
    router: Router;
    route: RouteState;
};

type State = {
    // If set, the user has clicked one of the Menu Items to be taken to another
    // page.
    navigateInfo?: RouteInfo;
};

const SwitcherMenuItem = ({
    icon,
    label,
}: {
    icon: React.ReactElement<typeof Icon | typeof ClusterProfileIcon>;
    label: string;
}) => (
    <div className="switcher-menu-item">
        <div className="icon-wrapper">{icon}</div>
        <div className="label" title={label}>
            {label}
        </div>
    </div>
);

class ClusterSwitcher extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    handleClickCluster = (cluster: Cluster) => {
        this.setState({
            navigateInfo: {
                name: "connect.cluster",
                params: {
                    clusterId: cluster.id,
                },
            },
        });
    };

    onClickLogOut = () => {
        const {
            route: {
                params: { clusterId },
            },
        } = this.props;

        // If `window.ROOT_REDIRECT` is defined, when a user clicks Log Out, we
        // go back to the Connect To Cluster page instead of to the home page
        // (which doesn't exist).
        if (window.ROOT_REDIRECT) {
            this.setState({
                navigateInfo: {
                    name: "connect.cluster",
                    params: {
                        clusterId,
                    },
                },
            });
        } else {
            this.setState({
                navigateInfo: {
                    name: "connect",
                },
            });
        }
    };

    onClickAddNewCluster = () => {
        this.setState({
            navigateInfo: {
                name: "connect.new",
            },
        });
    };

    handleModalProceed = () => {
        const { navigateInfo } = this.state;

        if (!navigateInfo) {
            throw new Error("Expected state.navigateInfo to be defined.");
        }

        let action;
        if (navigateInfo.name === "connect.new") {
            action = "switcher-add-new-cluster";
        } else if (navigateInfo.name === "connect") {
            action = "switcher-log-out";
        } else if (navigateInfo.name === "connect.cluster") {
            action = "switcher-switch-cluster";
        }

        action && analytics.track(action, { category: "sidebar" });

        this.props.router.navigate(
            navigateInfo.name,
            navigateInfo.params || {}
        );
    };

    handleModalCancel = () => {
        this.setState({
            navigateInfo: undefined,
        });
    };

    render() {
        const { clusters, currentCluster, allowClusterMutations } = this.props;
        const { navigateInfo } = this.state;

        if (!clusters || !currentCluster) {
            return null;
        }

        const { hostname, port, id: currentClusterId } = currentCluster;

        if (navigateInfo) {
            return (
                <Modal
                    active
                    onClose={this.handleModalCancel}
                    innerContentPadding
                    actions={
                        <>
                            <Button large onClick={this.handleModalCancel}>
                                Cancel
                            </Button>

                            <Button
                                primary
                                large
                                onClick={this.handleModalProceed}
                            >
                                Continue
                            </Button>
                        </>
                    }
                >
                    <SimpleModalBody
                        title="Are you sure?"
                        description={`This action will end the current session and disconnect
                        you from ${hostname}:${port}. Continue?`}
                    />
                </Modal>
            );
        }

        // array with all clusters except the one the user is currently connected to
        const otherClusters = _.filter(
            clusters,
            cluster => cluster.id !== currentClusterId
        );

        const clusterMenuItems = _.map(otherClusters, cluster => (
            <MenuItem
                action={() => this.handleClickCluster(cluster)}
                key={cluster.id}
            >
                <SwitcherMenuItem
                    icon={
                        <ClusterProfileIcon
                            size="xs"
                            profile={cluster.profile}
                        />
                    }
                    label={cluster.name}
                />
            </MenuItem>
        ));

        let addNewClusterItem: React.ReactNode = null;
        if (allowClusterMutations) {
            addNewClusterItem = (
                <MenuItem action={this.onClickAddNewCluster}>
                    <SwitcherMenuItem
                        icon={<Icon icon="plus" fixedWidth />}
                        label="Add Cluster"
                    />
                </MenuItem>
            );
        }

        return (
            <Menu className="cluster-switcher-menu">
                {clusterMenuItems}
                {addNewClusterItem}
                <MenuItem action={this.onClickLogOut}>
                    <SwitcherMenuItem
                        icon={<Icon icon="sign-out" fixedWidth />}
                        label="Log Out"
                    />
                </MenuItem>
            </Menu>
        );
    }
}

export default compose(
    withRoute,
    connect(
        (s: ReduxState): StateProps => ({
            clusters: selectClusters(s),
            allowClusterMutations: s.clusters.allowClusterMutations,
            currentCluster: selectCurrentCluster(s),
        })
    )
)(ClusterSwitcher);
