import { FieldList, RowResult } from "mysqljs";

import BigNumber from "vendor/bignumber.js/bignumber";

import { TextDecoder } from "text-encoding";

const FIELD_DECODER = new TextDecoder("utf-8");

export const rawResultToArray = (
    fields: FieldList,
    result: RowResult
): Array<string> => {
    let out: Array<string> = [];
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const value = result[field.table][field.name];
        if (value === null) {
            out.push("NULL");
        } else if (value instanceof Uint8Array) {
            out.push(FIELD_DECODER.decode(value));
        } else if (value instanceof BigNumber) {
            out.push(value.toString());
        } else if (value instanceof Date) {
            out.push(value.toString());
        } else {
            out.push(value);
        }
    }
    return out;
};
