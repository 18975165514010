import { Maybe } from "util/maybe";

import { DispatchFunction } from "data";
import { State } from "data/reducers";
import { SchemaFullPayload } from "util/schema/data";
import { State as RouteState } from "router5";

import * as React from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import { schemaRestore } from "data/actions";
import { logFocusError } from "data/actions/log";
import { validateSchemaDataFile } from "util/schema/data";

import FileLoader from "view/common/file-loader";
import DropdownButton from "view/components/dropdown-button";
import { Menu, MenuItem } from "view/common/menu";

import {
    selectSchemaFullPayload,
    selectSchemaExplorerLoaded,
} from "data/selectors/schema";
import { selectRoute } from "data/selectors/routes";

import saveData from "util/save-data";
import { jsonReviver, jsonReplacer } from "util/json";
import * as analytics from "util/segment";

type StateProps = {
    schemaData: Maybe<SchemaFullPayload>;
    schemaLoaded: boolean;
    route: RouteState;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
    className?: string;
};

class SchemaMenu extends React.Component<Props> {
    $fileLoader: Maybe<FileLoader>;

    handleLoadFile = (text: string, filename: string) => {
        const { dispatch } = this.props;

        let payload;

        try {
            payload = JSON.parse(text, jsonReviver);
        } catch (e) {
            dispatch(
                logFocusError(
                    `The file "${filename}" is not valid JSON: ${e.message}`
                )
            );

            return;
        }

        const schemaDataError: Maybe<string> = validateSchemaDataFile(payload);

        if (schemaDataError) {
            dispatch(
                logFocusError(
                    `The file "${filename}" is not valid: ${schemaDataError}.`
                )
            );
            return;
        }

        dispatch(schemaRestore(payload));
    };

    onClickImportData = () => {
        if (this.$fileLoader) {
            this.$fileLoader.show();
        }
    };

    onExportToFile = () => {
        const { schemaData } = this.props;

        if (schemaData) {
            saveData({
                data: JSON.stringify(schemaData, jsonReplacer),
                baseName: "schema-data",
            });

            analytics.track("export-schema-explorer-json");
        }
    };

    render() {
        const {
            schemaLoaded,
            className,
            route: { params },
        } = this.props;

        const menuItems = [
            <MenuItem
                key="save-item"
                action={this.onExportToFile}
                disabled={!schemaLoaded}
            >
                Export Data
            </MenuItem>,
        ];

        if (params.advanced) {
            menuItems.push(
                <MenuItem
                    action={this.onClickImportData}
                    key="import-item"
                    disabled={!schemaLoaded}
                >
                    Import Data
                </MenuItem>
            );
        }

        return (
            <>
                <FileLoader
                    onLoadFile={this.handleLoadFile}
                    ref={$fileLoader => {
                        this.$fileLoader = $fileLoader || undefined;
                    }}
                />
                <DropdownButton
                    ghost
                    icon="ellipsis-v"
                    direction="sw"
                    className={classnames("load-save-btn", className)}
                    children={<Menu>{menuItems}</Menu>}
                />
            </>
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        route: selectRoute(s),
        schemaData: selectSchemaFullPayload(s),
        schemaLoaded: selectSchemaExplorerLoaded(s),
    })
)(SchemaMenu);
