import { Maybe } from "util/maybe";

import { State } from "data";
import { LatestMemsqlVersion } from "data/models/remote-version";

import * as React from "react";
import { connect } from "react-redux";

import CircleIcon from "view/components/circle-icon";
import ExtLink from "view/components/external-link";
import SectionHeader from "view/components/section-header";
import { FakeClick } from "view/common/fake-click";
import { InfoCard, Highlight } from "view/common/info-card";

import {
    selectLoadingOrInitial,
    selectError,
    selectPayload,
} from "util/loading-state-machine";
import { Version } from "util/version";

import { selectMemsqlVersion } from "data/selectors/cluster-metadata";

import "./database-card.scss";

type StateProps = {
    loading: boolean;
    memsqlVersionError: Maybe<string>;

    memsqlVersion: Maybe<Version>;
    latestMemsqlVersion: Maybe<LatestMemsqlVersion>;
};

class DatabaseCard extends React.Component<StateProps> {
    renderLatestVersion = (): React.ReactNode => {
        const { latestMemsqlVersion } = this.props;

        let versionText;
        if (latestMemsqlVersion) {
            versionText = latestMemsqlVersion.version.toString();
        } else {
            versionText = "Check out the latest build";
        }

        return (
            <div className="latest-version">
                <CircleIcon iconProps={{ icon: "database" }} size="small" />

                <div className="latest-version-details">
                    <div className="latest-version-number">{versionText}</div>
                    <div className="latest-version-notes">
                        Learn about what was updated in the release notes.
                    </div>
                    <div className="latest-version-link">
                        <ExtLink name="release-notes" category="about-page">
                            View Release Notes
                        </ExtLink>
                    </div>
                </div>
            </div>
        );
    };

    renderContent = (): React.ReactNode => {
        const { memsqlVersion } = this.props;

        if (!memsqlVersion) {
            throw new Error("Expected MemSQL version to be defined.");
        }

        return (
            <div>
                <FakeClick name="database-current-version">
                    <Highlight>
                        <CircleIcon
                            iconProps={{ icon: "database" }}
                            size="small"
                        />

                        <div className="current-version-details">
                            <div className="current-version-heading">
                                Installed Version
                            </div>
                            <div className="current-version-number">
                                {memsqlVersion.toString()}
                            </div>
                        </div>
                    </Highlight>
                </FakeClick>

                <FakeClick name="database-latest-version">
                    <SectionHeader>Latest Version</SectionHeader>

                    {this.renderLatestVersion()}
                </FakeClick>
            </div>
        );
    };

    render() {
        const { loading, memsqlVersionError } = this.props;

        let content;
        // Note that we only error the whole card if fetching the cluster's MemSQL version fails, not if fetching the
        // latest version fails, in case we're being run in an isolated network. If `latestMemsqlVersion` is an error we
        // will still render content.
        if (!loading && !memsqlVersionError) {
            content = this.renderContent();
        }

        return (
            <InfoCard
                loading={loading}
                content={content}
                error={memsqlVersionError}
                hasEmptyState={false}
                title="MemSQL Version"
                description="This cluster's MemSQL version"
                helpLink={{
                    name: "release-notes",
                    category: "about-page",
                }}
            />
        );
    }
}

function selectMemsqlVersionErrorMessage(s: State): Maybe<string> {
    const error = selectError(s.clusterMetadata.memsqlVersion);
    if (error) {
        return error.message;
    }
}

export default connect(
    (s: State): StateProps => ({
        loading:
            selectLoadingOrInitial(s.clusterMetadata.memsqlVersion) ||
            selectLoadingOrInitial(s.remoteVersion.latestMemsqlVersion),
        memsqlVersionError: selectMemsqlVersionErrorMessage(s),

        memsqlVersion: selectMemsqlVersion(s),
        latestMemsqlVersion: selectPayload(s.remoteVersion.latestMemsqlVersion),
    })
)(DatabaseCard);
