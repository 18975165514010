import { TipProps } from "view/components/tip";
import { IconProps } from "view/components/icon";
import { ExternalLinkInfo } from "view/components/external-link";

import * as React from "react";
import classnames from "classnames";

import Tip from "view/components/tip";
import Icon from "view/components/icon";
import ExtLink from "view/components/external-link";

type Props = {
    children: React.ReactNode;
    className?: string;
    tipProps?: Partial<TipProps>;
    iconProps?: Partial<IconProps>;
    iconName: string;
    link?: ExternalLinkInfo;
};

const IconTip = ({
    children,
    className,
    tipProps,
    iconProps,
    iconName,
    link,
}: Props) => {
    const classes = classnames("components-icon-tip", className);

    const tip = (
        <Tip {...tipProps} className={classes} tooltip={children}>
            <Icon icon={iconName} {...iconProps} />
        </Tip>
    );

    if (link) {
        return <ExtLink {...link}>{tip}</ExtLink>;
    }

    return tip;
};

IconTip.defaultProps = {
    iconName: "info-circle",
};

export default IconTip;
