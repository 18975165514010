import * as React from "react";
import classnames from "classnames";

import "./header.scss";

type Props = {
    className?: string;

    left: React.ReactNode;
    right?: React.ReactNode;
};

export default class Header extends React.Component<Props> {
    render() {
        const { left, right, className } = this.props;

        const classes = classnames(className, "common-header");

        return (
            <div className={classes}>
                <div className="left">{left}</div>

                <div className="right">{right}</div>
            </div>
        );
    }
}
