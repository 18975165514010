import { Nullable } from "util/nullable";

import BigNumber from "vendor/bignumber.js/bignumber";

export const SQL_HOSTS_QUERY = `
    SELECT
        IP_ADDR AS address,
        SYSTEM_CPU_COUNT AS cpuCount,
        SYSTEM_MEMORY_MB AS systemMemoryMib
    FROM
        INFORMATION_SCHEMA.MV_HOSTS_INFORMATION;
`;

export type SQLHostRow = {
    address: string;
    cpuCount: Nullable<BigNumber>;
    systemMemoryMib: Nullable<BigNumber>;
};

export const SQL_HOSTS_LIVE_CPU = `
    SELECT
        ANY_VALUE(totalUsedCumulativeNs) AS totalUsedCumulativeNs,
        ANY_VALUE(hostIpAddress) AS hostIpAddress,
        ANY_VALUE(timestampNs) AS timestampNs,
        ANY_VALUE(numCpus) AS numCpus
    FROM (
        SELECT
            FIRST_VALUE(TOTAL_USED_CUMULATIVE_NS) OVER w AS totalUsedCumulativeNs,
            IP_ADDR AS hostIpAddress,
            FIRST_VALUE(TIMESTAMP_NS) OVER w AS timestampNs,
            NUM_CPUS AS numCpus
        FROM
            INFORMATION_SCHEMA.MV_SYSINFO_CPU WINDOW w AS (PARTITION BY IP_ADDR ORDER BY TIMESTAMP_NS DESC)
    )
    GROUP BY
        hostIpAddress;
`;

export type SQLHostsLiveCPURow = {
    totalUsedCumulativeNs: BigNumber;
    hostIpAddress: string;
    timestampNs: BigNumber;
    numCpus: Nullable<BigNumber>;
};

export const SQL_HOSTS_LIVE_MEMORY = `
    SELECT
        ANY_VALUE(hostIpAddress) AS hostIpAddress,
        ANY_VALUE(hostUsedMemoryB) AS hostUsedMemoryB,
        ANY_VALUE(hostTotalMemoryB) AS hostTotalMemoryB
    FROM (
        SELECT
            IP_ADDR AS hostIpAddress,
            FIRST_VALUE(HOST_USED_B) OVER w AS hostUsedMemoryB,
            FIRST_VALUE(HOST_TOTAL_B) OVER w AS hostTotalMemoryB
        FROM
            INFORMATION_SCHEMA.MV_SYSINFO_MEM WINDOW w AS (PARTITION BY IP_ADDR ORDER BY TIMESTAMP_NS DESC)
    )
    GROUP BY
        hostIpAddress
`;

export type SQLHostsLiveMemoryRow = {
    hostUsedMemoryB: BigNumber;
    hostTotalMemoryB: BigNumber;
    hostIpAddress: string;
};

export const SQL_HOSTS_LIVE_NETWORK_CUMULATIVE = `
    SELECT
        ANY_VALUE(receivedCumulativeB) AS receivedCumulativeB,
        ANY_VALUE(transmittedCumulativeB) AS transmittedCumulativeB,
        ANY_VALUE(hostIpAddress) AS hostIpAddress,
        ANY_VALUE(timestampNs) AS timestampNs,
        ANY_VALUE(interfaceName) AS interfaceName
    FROM (
        SELECT
            FIRST_VALUE(RECEIVED_CUMULATIVE_B) OVER w AS receivedCumulativeB,
            FIRST_VALUE(TRANSMITTED_CUMULATIVE_B) OVER w AS transmittedCumulativeB,
            IP_ADDR AS hostIpAddress,
            INTERFACE AS interfaceName,
            FIRST_VALUE(TIMESTAMP_NS) OVER w AS timestampNs
        FROM
            INFORMATION_SCHEMA.MV_SYSINFO_NET WINDOW w AS (PARTITION BY IP_ADDR, INTERFACE ORDER BY TIMESTAMP_NS DESC)
        )
    GROUP BY
        hostIpAddress, interfaceName;
`;

export type SQLHostsLiveNetworkCumulativeRow = {
    hostIpAddress: string;
    interfaceName: Nullable<string>;
    receivedCumulativeB: Nullable<BigNumber>;
    transmittedCumulativeB: Nullable<BigNumber>;
    timestampNs: Nullable<BigNumber>;
};

export const SQL_HOSTS_LIVE_DISK = `
    SELECT
        hostIpAddress,
        SUM(mountTotalB) AS totalDiskB,
        SUM(mountUsedB) AS usedDiskB
    FROM (
        SELECT
            ANY_VALUE(mountTotalB) AS mountTotalB,
            ANY_VALUE(mountUsedB) AS mountUsedB,
            hostIpAddress,
            mountPoint
        FROM (
            SELECT
                FIRST_VALUE(MOUNT_TOTAL_B) OVER w AS mountTotalB,
                FIRST_VALUE(MOUNT_USED_B) OVER w AS mountUsedB,
                IP_ADDR AS hostIpAddress,
                MOUNT_POINT AS mountPoint
            FROM
                INFORMATION_SCHEMA.MV_SYSINFO_DISK WINDOW w AS (PARTITION BY IP_ADDR, MOUNT_POINT ORDER BY TIMESTAMP_NS DESC)
        )
        GROUP BY
            mountPoint, hostIpAddress
    )
    GROUP BY
        hostIpAddress
`;

export type SQLHostsLiveDiskRow = {
    hostIpAddress: string;
    totalDiskB: Nullable<BigNumber>;
    usedDiskB: Nullable<BigNumber>;
};

// The READ_CUMULATIVE_B and WRITE_CUMULATIVE_B columns in MV_SYSINFO_DISK
// corresponds to how many bytes have been read from disk across all mount
// points (not just the one specified as MOUNT_POINT) per process (per MemSQL
// node). So, we simply group by node and ignore mount points altogether. Note
// that we are still using window functions to make sure we're reading all the
// data from the same row for each node.
export const SQL_NODES_LIVE_DISK_CUMULATIVE = `
    SELECT
        ANY_VALUE(readCumulativeB) AS readCumulativeB,
        ANY_VALUE(writeCumulativeB) AS writeCumulativeB,
        ANY_VALUE(hostIpAddress) AS hostIpAddress,
        ANY_VALUE(port) AS port,
        ANY_VALUE(timestampNs) AS timestampNs
    FROM (
        SELECT
            FIRST_VALUE(READ_CUMULATIVE_B) OVER w AS readCumulativeB,
            FIRST_VALUE(WRITE_CUMULATIVE_B) OVER w AS writeCumulativeB,
            IP_ADDR AS hostIpAddress,
            PORT AS port,
            FIRST_VALUE(TIMESTAMP_NS) OVER w AS timestampNs
        FROM
            INFORMATION_SCHEMA.MV_SYSINFO_DISK WINDOW w AS (PARTITION BY IP_ADDR, PORT ORDER BY TIMESTAMP_NS DESC)
        )
    GROUP BY
        hostIpAddress, port;
`;

export type SQLNodesLiveDiskCumulativeRow = {
    hostIpAddress: string;
    port: BigNumber;
    readCumulativeB: Nullable<BigNumber>;
    writeCumulativeB: Nullable<BigNumber>;
    timestampNs: Nullable<BigNumber>;
};

export const SQL_HOSTS_PHYSICAL_MONITORING_ENABLED = `
    SELECT
        COUNT(*) AS isPhysicalMonitoringEnabled
    FROM
        INFORMATION_SCHEMA.TABLES
    WHERE
        TABLE_NAME = 'MV_SYSINFO_CPU';
`;

export type SQLHostsPhysicalMonitoringEnabledRow = {
    isPhysicalMonitoringEnabled: BigNumber;
};
