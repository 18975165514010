import * as React from "react";
import copyToClipboard from "copy-to-clipboard";

import Toast from "view/components/toast";

type CopyFunc = (text: string) => void;

type Props = {
    toastMessage: string;
    children: (copy: CopyFunc) => React.ReactNode;
};

type State = {
    copied: boolean;
};

export default class CopyToClipboardToast extends React.Component<
    Props,
    State
> {
    state: State = { copied: false };

    handleClickCopy = (text: string) => {
        copyToClipboard(text);

        this.setState({ copied: true });
    };

    handleToastHide = () => {
        this.setState({ copied: false });
    };

    render() {
        const { copied } = this.state;
        const { toastMessage, children } = this.props;

        return (
            <>
                <Toast
                    iconName="clipboard"
                    active={copied}
                    onHide={this.handleToastHide}
                >
                    {toastMessage}
                </Toast>

                {children(this.handleClickCopy)}
            </>
        );
    }
}
