import { Observable } from "rxjs";
import { Store } from "data/store";
import { State } from "data/reducers";

import _ from "lodash";

import { selectMemsqlOnline } from "data/selectors/system-status";
import { initConsoleQueryExecutor } from "data/actions/console";
import { initQueryExecutor } from "worker/api/connection";
import { PER_QUERY_ROW_LIMIT } from "data/reducers/query-editor";

function shouldInitConsole(s: State): boolean {
    return (
        s.console.shouldTryConnect &&
        selectMemsqlOnline(s) &&
        (Boolean(s.console.initQueryExecutorError) ||
            !s.console.queryExecutorState ||
            s.console.queryExecutorState === "TERMINATED")
    );
}

function shouldInitQueryEditor(s: State): boolean {
    return (
        s.queryEditor.shouldTryConnect &&
        selectMemsqlOnline(s) &&
        (Boolean(s.queryEditor.initQueryExecutorError) ||
            !s.queryEditor.connectionState ||
            s.queryEditor.connectionState === "TERMINATED")
    );
}

export function reconnectConsole(state$: Observable<State>, store: Store) {
    const shouldInit$ = state$
        .map(shouldInitConsole)
        .distinctUntilChanged()
        .filter(_.identity);

    state$.sample(shouldInit$).subscribe((s: State) => {
        store.dispatch(initConsoleQueryExecutor(s.console.selectedDatabase));
    });
}

export function reconnectQueryEditor(state$: Observable<State>, store: Store) {
    const shouldInit$ = state$
        .map(shouldInitQueryEditor)
        .distinctUntilChanged()
        .filter(_.identity);

    state$.sample(shouldInit$).subscribe((s: State) => {
        store.dispatch(
            initQueryExecutor({
                id: s.queryEditor.connectionId,
                perQueryRowLimit: PER_QUERY_ROW_LIMIT,
                database: s.queryEditor.selectedDatabase,
                loggerType: "verbose",
            })
        );
    });
}
