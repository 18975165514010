import _ from "lodash";
import BigNumber from "vendor/bignumber.js/bignumber";
import { Version } from "util/version";

import { LEError, LELoading, LESuccess } from "util/loading-error";

// This `replacer` function can be passed to any `JSON.stringify` call so that
// we properly serialize JavaScript Date objects. (Studio internal classes are
// properly serialized in their toJSON methods. Unfortunately, Date objects
// already have a toJSON method that just converts them to a string; if we just
// used that directly, we would not be able to distinguish Dates from strings
// that happen to look like Dates.)
export function jsonReplacer(this: any, key: any, value: any) {
    // The value of `value` is already after toJSON has been called.
    // Fortunately you can get the raw object with this[key].
    const rawValue = this[key];
    if (rawValue instanceof Date) {
        return {
            __type__: "Date",
            __val__: rawValue.toJSON(), // returns just a string
        };
    }

    return value;
}

// This `reviver` function can be passed to any `JSON.parse` call so that we
// properly parse BigNumbers and other Studio internal classes, as well as
// Dates.
export function jsonReviver(_key: any, value: any) {
    if (_.isObject(value) && "__type__" in value) {
        switch (value.__type__) {
            case "BigNumber":
                return new BigNumber(value.__val__);

            case "Version":
                return new Version(value.__val__);

            case "Date":
                return new Date(value.__val__);

            case "LESuccess":
                return new LESuccess(value.__val__);

            case "LELoading":
                return new LELoading();

            case "LEError":
                return new LEError();
        }
    }

    return value;
}
