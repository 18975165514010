
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}><MDXTag name="p" components={components}>{`You can use this data to quickly get started testing the features and functionality of MemSQL.`}</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  