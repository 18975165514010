import * as React from "react";
import format from "date-fns/format";

type Props = {
    date: Date;
};

export default class LastUpdated extends React.PureComponent<Props> {
    render() {
        const { date } = this.props;

        return `Last updated: ${format(date, "h:mm:ss A")}`;
    }
}
