import {
    DerivedPartition,
    PartitionStatus,
    PartitionInstance,
} from "data/models";

import * as React from "react";
import classnames from "classnames";

import Icon from "view/components/icon";

import { isPartitionImpacted } from "data/models";

import { HYPHEN } from "util/symbols";

import "./cluster-metadata.scss";

const PARTITION_INSTANCE_STATUS_ICON: {
    [k in PartitionInstance["state"]]: string
} = {
    online: "check-circle",
    replicating: "clone",
    provisioning: "cog",
    unrecoverable: "exclamation-circle",
    recovering: "sync-alt",
    transition: "exchange-alt",
    pending: "clock",
    offline: "exclamation-triangle",
};

const getPartitionInstanceStatusIconClass = (
    status: PartitionInstance["state"]
) => {
    switch (status) {
        case "online":
            return "success";

        case "recovering":
            return "warning";

        case "unrecoverable":
        case "offline":
            return "error";
    }
};

export const getPartitionInstanceStatusIcon = (
    status: PartitionInstance["state"]
) => {
    const iconColor = getPartitionInstanceStatusIconClass(status);

    let styleProps;
    if (iconColor) {
        styleProps = {
            [iconColor]: true,
        };
    }

    return (
        <Icon
            rightMargin
            iconType="regular"
            icon={PARTITION_INSTANCE_STATUS_ICON[status]}
            {...styleProps}
        />
    );
};

const displayPartitionInstanceState = (state: PartitionInstance["state"]) => {
    switch (state) {
        case "online":
            return "Online";

        case "replicating":
            return "Replicating";

        case "provisioning":
            return "Provisioning";

        case "unrecoverable":
            return "Unrecoverable";

        case "recovering":
            return "Recovering";

        case "transition":
            return "Transition";

        case "pending":
            return "Pending";

        case "offline":
            return "Offline";

        default:
            return state;
    }
};

export const getPartitionInstanceStatusClass = (
    status: PartitionInstance["state"]
) => {
    switch (status) {
        case "unrecoverable":
        case "offline":
            return "error";
    }
};

export const formatPartitionInstanceState = (
    status: PartitionInstance["state"]
) => {
    const icon = getPartitionInstanceStatusIcon(status);
    const text = displayPartitionInstanceState(status);

    const classes = classnames(
        "status-cell",
        getPartitionInstanceStatusClass(status)
    );

    return (
        <div className={classes}>
            {icon} {text}
        </div>
    );
};

export const formatPartitionInstanceRole = (
    role: PartitionInstance["role"]
) => {
    switch (role) {
        case "master":
            return "Master";

        case "slave":
            return "Slave";

        case "detached master":
            return "Detached Master";

        case "detached slave":
            return "Detached Slave";

        default:
            return role;
    }
};

const PARTITION_STATUS_ICON: { [k in PartitionStatus]?: string } = {
    online: "check-circle",
    degraded_recovering: "sync-alt",
    offline_recovering: "sync-alt",
    degraded_unrecoverable: "exclamation-circle",
    offline: "exclamation-triangle",
};

const _getPartitionStatusIcon = (
    status: PartitionStatus,
    impacted: boolean
) => {
    if (status === "online" && impacted) {
        return "exclamation-circle";
    } else {
        return PARTITION_STATUS_ICON[status];
    }
};

const getPartitionStatusIconClass = (
    status: PartitionStatus,
    impacted: boolean
) => {
    if (status === "online" && !impacted) {
        return "success";
    } else if (
        (status === "online" && impacted) ||
        status === "degraded_unrecoverable" ||
        status === "degraded_recovering"
    ) {
        return "warning";
    } else if (status === "offline_recovering" || status === "offline") {
        return "error";
    }
};

export const getPartitionStatusIcon = (
    status: PartitionStatus,
    impacted: boolean
) => {
    const icon = _getPartitionStatusIcon(status, impacted);
    const iconColor = getPartitionStatusIconClass(status, impacted);

    let styleProps;
    if (iconColor) {
        styleProps = {
            [iconColor]: true,
        };
    }

    if (icon) {
        return (
            <Icon rightMargin iconType="regular" icon={icon} {...styleProps} />
        );
    }
};

// This function can also be used to format database statuses.
export const displayPartitionStatus = (
    status: PartitionStatus,
    impacted: boolean
) => {
    let text;
    switch (status) {
        case "online":
            text = "Online";
            break;

        case "degraded_recovering":
            text = "Degraded Recovering";
            break;

        case "degraded_unrecoverable":
            text = "Degraded Unrecoverable";
            break;

        case "offline_recovering":
            text = "Offline Recovering";
            break;

        case "offline":
            text = "Offline";
            break;

        default:
            text = "Unknown";
    }

    if (impacted) {
        text = `${text} ${HYPHEN} Impacted`;
    }

    return text;
};

export const getPartitionStatusClass = (status: PartitionStatus) => {
    switch (status) {
        case "offline_recovering":
        case "offline":
            return "error";
    }
};

export function formatPartitionStatus(partition: DerivedPartition) {
    return _formatPartitionStatus(
        partition.status,
        isPartitionImpacted(partition)
    );
}

export const _formatPartitionStatus = (
    status: PartitionStatus,
    impacted: boolean
) => {
    const text = displayPartitionStatus(status, impacted);
    const icon = getPartitionStatusIcon(status, impacted);

    const classes = classnames("status-cell", getPartitionStatusClass(status));

    return (
        <div className={classes}>
            {icon} {text}
        </div>
    );
};

export const formatPartitionSyncState = (partition: DerivedPartition) => {
    if (partition.syncState === "async") {
        return "Asynchronous";
    } else if (partition.syncState === "sync") {
        return "Synchronous";
    } else if (typeof partition.syncState === "string") {
        return partition.syncState;
    }
};
