import { Maybe } from "util/maybe";
import { Vector } from "util/vector";
import { Rectangle } from "util/rectangle";

import * as React from "react";
import _ from "lodash";
import { NewVector, add, subtract, magnitude } from "util/vector";
import classnames from "classnames";
import { globalDeselect } from "util/global-deselect";

import ResizeDetector from "view/components/resize-detector";

import "./viewport.scss";

type Props = {
    renderChildren: (rect: Maybe<Rectangle>) => React.ReactNode;
    className?: string;
    onClick?: (e: React.MouseEvent) => void;

    // offset that children are rendered at, modulo dragging; the parent may
    // connect it to Redux state. If not supplied, we won't render our children
    // at all, but we'll still render the outer div and any absolute children.
    // This is useful because the parent may mount us to figure out our
    // dimensions before deciding on the initial offset.
    offset: Maybe<Vector>;

    // called at the end of drags for panning
    setOffset: (v: Vector) => void;

    // called when we mount, with our bounding client rect as the argument, so
    // the parent can decide on the initial offset as noted above
    onMountWithClientRect?: (clientRect: ClientRect) => void;

    // Caller is responsible for rendering these as position: absolute.
    absoluteChildren?: React.ReactNode;
};

type DragRepr = {
    // clientX,Y of the mousedown that initiated a drag
    start: Vector;

    // value of offset when mousedown occurred
    startOffset: Vector;

    // most recent calculated value of offset, overrides this.props.offset when
    // it exists (calling setOffset live while panning is too slow,
    // unfortunately)
    currentOffset: Vector;
};

type State = {
    // Dimensions of the viewport DOM element, provided by ResizeObserver
    height: Maybe<number>;
    width: Maybe<number>;

    currentDrag?: DragRepr;

    // Keep track of the user having dragged for a considerable amount, which
    // means that they are trying to pan instead of clicking a node.
    draggedConsiderableAmount: boolean;
};

// This is what we consider to be a long enough mouse movement to distinguish a
// drag event from a click event (in pixels).
const CONSIDERABLE_THRESHOLD = 10;

const fromClientCoords = ({
    clientX,
    clientY,
}: {
    clientX: number;
    clientY: number;
}): Vector => ({
    x: clientX,
    y: clientY,
});

// Render objects in a rectangle that's this factor times the visible width and
// height of the viewport. By choosing 3 we guarantee that all relevant objects
// will still be displayed if the user pans by dragging from any corner of the
// viewport to any other corner.
const RENDER_BOUNDS_FACTOR = 3;

export default class Viewport extends React.Component<Props, State> {
    $div: Maybe<HTMLElement>;

    constructor(props: Props) {
        super(props);

        this.state = {
            height: undefined,
            width: undefined,
            draggedConsiderableAmount: false,
        };
    }

    componentDidMount() {
        const { onMountWithClientRect } = this.props;

        window.addEventListener("mouseup", this.handleMouseUpOutside);

        if (!this.$div) {
            throw new Error(
                "Expected div to be defined after viewport mounted"
            );
        }
        if (this.$div && onMountWithClientRect) {
            onMountWithClientRect(this.$div.getBoundingClientRect());
        }
    }

    // Mimicking SuperTable, since `handleResize` is debounced, if we are
    // unmounted at the right time, the handleResize call might happen *after*
    // the component is unmounted, wasting cycles and causing a warning. We
    // cancel the debounce to avoid this.
    componentWillUnmount() {
        this.handleResize.cancel();
        window.removeEventListener("mouseup", this.handleMouseUpOutside);
    }

    // This will be called immediately when ResizeDetector mounts
    handleResize = _.debounce((width: number, height: number) => {
        this.setState({
            width,
            height,
        });
    }, 50);

    handleMouseDown = (event: React.MouseEvent) => {
        event.preventDefault(); // prevent text selection

        globalDeselect();

        const clientVector = fromClientCoords(event);
        const { offset } = this.props;
        if (!offset) {
            return false;
        }

        this.setState({
            currentDrag: {
                start: clientVector,
                startOffset: offset,
                currentOffset: offset,
            },
            draggedConsiderableAmount: false,
        });
    };

    handleMouseUp = (event: React.MouseEvent) => {
        const clientVector = fromClientCoords(event);
        const { currentDrag } = this.state;

        // We do not invariant-assert that currentDrag is defined because users
        // can always do things like change windows while dragging to cause
        // extremely weird sequences of mouse events.
        if (currentDrag) {
            const { start, startOffset } = currentDrag;

            this.props.setOffset(
                add(startOffset, subtract(clientVector, start))
            );

            this.setState({
                currentDrag: undefined,
            });
        }
    };

    handleMouseMove = (event: React.MouseEvent) => {
        const clientVector = fromClientCoords(event);

        this.setState(prevState => {
            const { currentDrag, draggedConsiderableAmount } = prevState;

            if (currentDrag) {
                const { start, startOffset } = currentDrag;
                const draggedVector = subtract(clientVector, start);

                return {
                    ...prevState,
                    currentDrag: {
                        start,
                        startOffset,
                        currentOffset: add(startOffset, draggedVector),
                    },
                    draggedConsiderableAmount:
                        draggedConsiderableAmount ||
                        magnitude(draggedVector) > CONSIDERABLE_THRESHOLD,
                };
            }

            return prevState;
        });
    };

    handleMouseUpOutside = (event: MouseEvent | React.MouseEvent) => {
        const { currentDrag } = this.state;

        if (
            currentDrag &&
            !(
                this.$div &&
                event.target instanceof Node &&
                this.$div.contains(event.target)
            )
        ) {
            // This happens if the mouse-up did not happen in our div. The
            // client coordinates of this event are not useful to us; just
            // freeze the offset where we had it and stop dragging.
            this.props.setOffset(currentDrag.currentOffset);
            this.setState({
                currentDrag: undefined,
            });
        }
    };

    handleClickCapture = (event: React.MouseEvent) => {
        // This handler is attached to the capture phase, so it will fire before
        // the event reaches our children. If a click was fired as the result of
        // the user releasing a drag, we don't want children to handle the event
        // as a normal click.
        if (this.state.draggedConsiderableAmount) {
            event.stopPropagation();
        }
    };

    computeRendererStyle = () => {
        let { offset } = this.props;

        const { currentDrag } = this.state;
        if (currentDrag) {
            offset = currentDrag.currentOffset;
        }

        if (!offset) {
            return { display: "none" };
        }

        const { x, y } = offset;

        return {
            left: `calc(50% + ${x}px)`,
            top: `calc(50% + ${y}px)`,
        };
    };

    render() {
        const {
            renderChildren,
            className,
            absoluteChildren,
            onClick,
            offset,
        } = this.props;
        const { currentDrag, width, height } = this.state;

        const classes = classnames("components-viewport", className, {
            dragging: !!currentDrag,
        });

        let viewportBounds;
        if (offset && width !== undefined && height !== undefined) {
            // Note that we use this.props.offset while ignoring
            // currentDrag.currentOffset, so we only re-render nodes when
            // panning stops. This means it's possible for the user to pan to
            // part of the map without rendered nodes if they try. But it's not
            // that easy, and re-rendering is fairly expensive, so this is
            // acceptable.
            const { x, y } = offset;
            const boundsWidth = RENDER_BOUNDS_FACTOR * width;
            const boundsHeight = RENDER_BOUNDS_FACTOR * height;

            viewportBounds = {
                position: NewVector(
                    -x - boundsWidth / 2,
                    -y - boundsHeight / 2
                ),
                dimensions: NewVector(boundsWidth, boundsHeight),
            };
        }

        return (
            <div
                onMouseDown={this.handleMouseDown}
                onMouseUp={this.handleMouseUp}
                onMouseMove={this.handleMouseMove}
                onClickCapture={this.handleClickCapture}
                onClick={onClick}
                ref={$div => {
                    this.$div = $div || undefined;
                }}
                className={classes}
            >
                <div className="renderer" style={this.computeRendererStyle()}>
                    {renderChildren(viewportBounds)}
                </div>
                {absoluteChildren}
                <ResizeDetector onResize={this.handleResize} />
            </div>
        );
    }
}
