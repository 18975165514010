
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}><MDXTag name="h4" components={components}>{`Introduction`}</MDXTag>
<MDXTag name="p" components={components}>{`Create a database containing millions of rows of simulated stock trade data, then run sample queries to see how MemSQL performs when doing concurrent reads while data is being written to disk.`}</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  