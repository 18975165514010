import { Maybe } from "util/maybe";
import { ConnectionManagerState } from "worker/net/connection-manager";
import { SuperAction } from "data/actions";
import { ClusterConfig } from "util/studio-config";

import { setStudioConfig } from "util/studio-config";

export default () => (next: (action: SuperAction) => void) => (
    action: SuperAction
) => {
    if (action.type === "CONNECT" && "error" in action && !action.error) {
        const state: ConnectionManagerState = action.payload;

        if (state.config) {
            const { user, password, clusterId } = state.config;

            let autoLogin: Maybe<ClusterConfig["autoLogin"]>;
            if (password === "") {
                autoLogin = {
                    username: user,
                    password,
                };
            }

            setStudioConfig(oldConfig => ({
                clusters: {
                    ...oldConfig.clusters,
                    [clusterId]: {
                        ...(oldConfig.clusters[clusterId] || {
                            queryEditor: {
                                buffer: "",
                            },
                        }),
                        autoLogin,
                    },
                },
            }));
        }
    }

    return next(action);
};
