import * as React from "react";

import { TabBadge } from "view/components/tab";

import "./table-header.scss";

export type TableHeaderProps = {
    title: string;
    count?: number;
};

export class TableHeader extends React.Component<TableHeaderProps> {
    render() {
        const { title, count } = this.props;

        let tabBadge;
        if (count !== undefined && count > 0) {
            tabBadge = <TabBadge count={count} leftMargin active />;
        }

        return (
            <div className="components-table-header">
                {title}
                {tabBadge}
            </div>
        );
    }
}
