import { Maybe } from "util/maybe";
import { ActionCreator, ActionHandler } from "worker/api/helpers";
import { SuperAction } from "data/actions";
import ConnectionManager from "worker/net/connection-manager";
import SagasManager from "worker/api/sagas-manager";

import _ from "lodash";

import * as TopologyApi from "worker/api/topology";
import * as ClusterApi from "worker/api/clusters";
import * as ConnectionApi from "worker/api/connection";
import * as ManagementViewsApi from "worker/api/management-views";
import * as ProxyApi from "worker/api/proxy";
import * as SchemaApi from "worker/api/schema";
import * as EventsApi from "worker/api/events";
import * as ClusterMetadataApi from "worker/api/cluster-metadata";
import * as PipelinesApi from "worker/api/pipelines";
import * as LicenseApi from "worker/api/license";
import * as RemoteVersionApi from "worker/api/remote-version";
import * as ExplainApi from "worker/api/explain";
import * as HostsApi from "worker/api/hosts";
import * as ActiveProcessesApi from "worker/api/active-processes";

export type HandlerContext = {
    manager: ConnectionManager;
    sagas: SagasManager;
};

const apis = [
    TopologyApi,
    ClusterApi,
    ConnectionApi,
    ManagementViewsApi,
    ProxyApi,
    SchemaApi,
    EventsApi,
    ClusterMetadataApi,
    PipelinesApi,
    LicenseApi,
    RemoteVersionApi,
    ExplainApi,
    HostsApi,
    ActiveProcessesApi,
];

// For each API file, there are multiple exported values. We have
// to filter out the values which are action handlers. We do this
// by checking for the `handler` property which is a part of every
// action creator (as per `makeActionCreator`).
const getHandlers = (api: unknown) =>
    _(api)
        .values()
        .filter(v => v.handler)
        .value();

const ApiHandlers: Array<
    ActionCreator<HandlerContext, unknown, SuperAction>
> = _(apis)
    .map(getHandlers)
    .flatten()
    .value();

const findHandler = (
    actionType: string
): Maybe<ActionHandler<unknown, unknown, unknown>> =>
    _(ApiHandlers)
        .filter(ac => ac.handler.actionType === actionType)
        .map(ac => ac.handler.handle)
        .first();

export const handlesAction = (actionType: string): boolean =>
    !!findHandler(actionType);

export const handleAction = (
    actionType: string,
    ctx: HandlerContext,
    args: unknown
) => {
    const handler = findHandler(actionType);

    if (!handler) {
        throw new Error(`Unhandled worker action ${actionType}`);
    }

    return handler(ctx, args);
};
