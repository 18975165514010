import { Action } from "./types";
import { DispatchFunction } from "data/actions";

import { makeWebPing } from "data/memsql-web-api";

export type WebPingAction = Action<
    "WEB_PING",
    { date: Date },
    { date: Date; message: string }
>;

export const webPing = () => (dispatch: DispatchFunction): void => {
    makeWebPing()
        .then(({ success }: { success: boolean }) => {
            if (success) {
                dispatch({
                    type: "WEB_PING",
                    error: false,
                    payload: { date: new Date() },
                });
            } else {
                dispatch({
                    type: "WEB_PING",
                    error: true,
                    payload: { date: new Date(), message: "Ping failed" },
                });
            }
        })
        .catch((err: Error) =>
            dispatch({
                type: "WEB_PING",
                error: true,
                payload: {
                    date: new Date(),
                    message: err.message,
                },
            })
        );
};
