import { Maybe } from "util/maybe";
import { Rectangle } from "util/rectangle";
import { ExecutorInstance, ExplainLayout } from "data/models";
import { LayoutNode, ZoomLevel } from "data/explain/layout";

import * as React from "react";
import _ from "lodash";
import { rectangleBetweenCorners, intersect } from "util/rectangle";

import ExplainNode from "view/explain/node";

import "./layout-renderer.scss";

type Props = {
    layout: ExplainLayout;
    zoomLevel: ZoomLevel;
    highlightedIndex: Maybe<number>;
    onClickNode?: (event: React.MouseEvent, index: number) => void;
    renderBounds: Maybe<Rectangle>;
};

type BracketLineProps = {
    rectangle: Rectangle;
};

// Technically this renders a rectangle, but we use it only with rectangles that
// have zero height or zero width to render horizontal or vertical lines.
class BracketLine extends React.Component<BracketLineProps> {
    render() {
        const {
            rectangle: {
                position: { x, y },
                dimensions: { x: width, y: height },
            },
        } = this.props;

        const style = {
            left: x - 1,
            top: y - 1,
            width: width + 2,
            height: height + 2,
        };

        return <div className="bracket" style={style} />;
    }
}

export default class LayoutRenderer extends React.PureComponent<Props> {
    render() {
        const {
            layout,
            zoomLevel,
            highlightedIndex,
            onClickNode,
            renderBounds,
        } = this.props;

        const sublayout = layout.layout[zoomLevel];

        return (
            <div className="explain-layout-renderer">
                {_.map(
                    sublayout.brackets,
                    ({ parent, bracketY, children }, i) => {
                        const firstChild = children[0];
                        const lastChild = children[children.length - 1];

                        // Rectangles (which are all zero-width or zero-height)
                        // representing the various segments in the bracket that
                        // should be drawn.
                        const bracketRectangles = [
                            // vertical segment from parent to horizontal bracket
                            rectangleBetweenCorners(parent, {
                                x: parent.x,
                                y: bracketY,
                            }),

                            // horizontal segment bracket
                            rectangleBetweenCorners(
                                { x: firstChild.x, y: bracketY },
                                { x: lastChild.x, y: bracketY }
                            ),

                            // vertical segment from horizontal bracket to each
                            // child
                            ...children.map(childVector =>
                                rectangleBetweenCorners(
                                    { x: childVector.x, y: bracketY },
                                    childVector
                                )
                            ),
                        ];

                        return (
                            <div key={i}>
                                {bracketRectangles.map((rect, index) => {
                                    // We do the filtering this way, after computing
                                    // all rectangles, to keep their indexes (which
                                    // we use as React keys) stable.
                                    if (
                                        renderBounds &&
                                        !intersect(rect, renderBounds)
                                    ) {
                                        return;
                                    }

                                    return (
                                        <BracketLine
                                            key={index}
                                            rectangle={rect}
                                        />
                                    );
                                })}
                            </div>
                        );
                    }
                )}

                {sublayout.nodes.map(
                    (
                        layoutNode: LayoutNode<ExecutorInstance>,
                        index: number
                    ) => {
                        if (
                            renderBounds &&
                            !intersect(layoutNode.bounds, renderBounds)
                        ) {
                            return;
                        }

                        const {
                            position: { x: left, y: top },
                            dimensions: { x: width, y: height },
                        } = layoutNode.bounds;
                        const style = { left, top, width, height };

                        let onClick;
                        if (onClickNode) {
                            onClick = (event: React.MouseEvent) =>
                                onClickNode(event, index);
                        }

                        return (
                            <div
                                className="node-wrapper"
                                style={style}
                                key={index}
                            >
                                <ExplainNode
                                    data={layoutNode.data}
                                    zoomLevel={zoomLevel}
                                    highlighted={highlightedIndex === index}
                                    onClick={onClick}
                                />
                            </div>
                        );
                    }
                )}
            </div>
        );
    }
}
