import { Router, Route } from "router5";

import { createRouter, loggerPlugin } from "router5";
import browserPlugin from "router5/plugins/browser";
import listenersPlugin from "router5/plugins/listeners";

type Opts = {
    withBrowser?: boolean;
    withLogger?: boolean;
    routes: Array<Route>;
    defaultRoute?: string;
};

export function newRouter({
    withBrowser,
    withLogger,
    routes,
    defaultRoute,
}: Opts) {
    let router: Router = createRouter(routes, {
        defaultRoute,
        allowNotFound: true,

        // Stringify true values without value.
        queryParams: {
            booleanFormat: "empty-true",
        },

        // Automatically cleanup canDeactivate funcs/bools when the node
        // deactivates
        autoCleanUp: true,
    });

    // required by react-router5
    router.usePlugin(listenersPlugin());

    if (withLogger) {
        router.usePlugin(loggerPlugin);
    }
    if (withBrowser) {
        router.usePlugin(browserPlugin());
    }

    return router;
}
