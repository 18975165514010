import { Maybe } from "util/maybe";
import { TableSort } from "util/sort";

import { State } from "data";
import {
    Node,
    DerivedNode,
    PartitionInstance,
    NodePartitionInstanceStatus,
} from "data/models";

import _ from "lodash";
import { createSelector } from "reselect";

import { GeneralTableColumn } from "view/components/general-table";

import {
    ALL_NODES_PAGE_COLUMNS,
    NODE_PARTITION_INSTANCE_COLUMNS,
} from "view/topology/columns-info";

import { parseNodeAddress } from "data/models";

import { selectRoute } from "data/selectors/routes";
import { selectPartitionInstances } from "data/selectors/cluster-metadata";

import {
    selectLoadingOrInitial,
    selectLastUpdate,
    selectPayload,
    selectError,
} from "util/loading-state-machine";
import { buildRankDictionary } from "util/rank-dictionary";

export const selectTopologyLoading = (s: State): boolean =>
    selectLoadingOrInitial(s.topology.topology);

export const selectTopologyLastUpdate = (s: State): Maybe<Date> =>
    selectLastUpdate(s.topology.topology);

export const selectTopologyError = (s: State): Maybe<string> =>
    selectError(s.topology.topology);

export const selectDerivedNodesLoading = (s: State): boolean =>
    selectTopologyLoading(s) ||
    selectLoadingOrInitial(s.clusterMetadata.partitions);

export const selectDerivedNodesLastUpdate = (s: State): Maybe<Date> =>
    _.max([
        selectTopologyLastUpdate(s),
        selectLastUpdate(s.clusterMetadata.partitions),
    ]);

export const selectDerivedNodesError = (s: State): Maybe<string> =>
    selectTopologyError(s) || selectError(s.clusterMetadata.partitions);

export const selectNodes = (s: State): Maybe<Array<Node>> => {
    const payload = selectPayload(s.topology.topology);

    if (payload) {
        return payload.nodes;
    }
};

export const selectNodesSort = (s: State): TableSort => s.topology.nodesSort;

export const selectNodePartitionInstancesSort = (s: State): TableSort =>
    s.topology.nodePartitionInstancesSort;

export const selectNumAggregators = createSelector(
    selectNodes,
    (nodes): Maybe<number> => {
        if (nodes) {
            return _.filter(
                nodes,
                node =>
                    node.role === "AGGREGATOR" ||
                    node.role === "MASTER_AGGREGATOR"
            ).length;
        }
    }
);

export const selectNumLeaves = createSelector(
    selectNodes,
    (nodes): Maybe<number> => {
        if (nodes) {
            return _.filter(nodes, node => node.role === "LEAF").length;
        }
    }
);

export const selectMasterAggregator = createSelector(
    selectNodes,
    (nodes): Maybe<Node> => {
        if (nodes) {
            return _.find(nodes, node => node.role === "MASTER_AGGREGATOR");
        }
    }
);

export const selectNumUniqueHosts = createSelector(
    selectNodes,
    (nodes): Maybe<number> => {
        if (nodes) {
            return _.uniqBy(nodes, node => node.host).length;
        }
    }
);

export const deriveNodes = createSelector(
    selectNodes,
    selectPartitionInstances,
    (nodes, allPartitionInstances): Maybe<Array<DerivedNode>> => {
        if (nodes && allPartitionInstances) {
            return _.map(nodes, node => {
                const partitionInstances = _.filter(
                    allPartitionInstances,
                    pi => pi.host === node.host && pi.port.eq(node.port)
                );

                return {
                    ...node,
                    partitionInstances,
                    partitionInstanceStatus: getNodePartitionInstanceStatus(
                        partitionInstances
                    ),
                };
            });
        }
    }
);

export const selectSortedDerivedNodes = createSelector(
    deriveNodes,
    selectNodesSort,
    (nodes, sort): Maybe<Array<DerivedNode>> => {
        if (nodes) {
            if (sort) {
                const { direction, columnId } = sort;

                const columnInfo = _.find(ALL_NODES_PAGE_COLUMNS, {
                    id: columnId,
                });

                if (!columnInfo) {
                    throw new Error("Expected column to be defined.");
                }

                return _.orderBy<DerivedNode>(
                    nodes,
                    [columnInfo.getValue],
                    direction
                );
            } else {
                return nodes;
            }
        }
    }
);

export const selectCurrentNodeAddress = (s: State): Maybe<string> =>
    selectRoute(s).params.nodeAddress;

export const selectCurrentNode = createSelector(
    deriveNodes,
    selectCurrentNodeAddress,
    (nodes, nodeAddress): Maybe<DerivedNode> => {
        if (nodes && nodeAddress) {
            const nodeHostPort = parseNodeAddress(nodeAddress);

            if (nodeHostPort) {
                return _.find(
                    nodes,
                    node =>
                        node.host === nodeHostPort.host &&
                        node.port.eq(nodeHostPort.port)
                );
            }
        }
    }
);

export const selectCurrentNodePartitionInstances = createSelector(
    selectCurrentNode,
    (node): Maybe<Array<PartitionInstance>> => {
        if (node) {
            return node.partitionInstances;
        }
    }
);

export const selectSortedNodePartitionInstances = createSelector(
    selectCurrentNodePartitionInstances,
    selectNodePartitionInstancesSort,
    (partitionInstances, sort): Maybe<Array<PartitionInstance>> => {
        if (partitionInstances) {
            if (sort) {
                const { direction, columnId } = sort;

                const columnInfo = _.find(
                    NODE_PARTITION_INSTANCE_COLUMNS,
                    (col: GeneralTableColumn<unknown>) => col.id === columnId
                );

                if (!columnInfo) {
                    throw new Error(
                        `Expected column ${columnId} to be defined on Node Page.`
                    );
                }

                return _.orderBy<PartitionInstance>(
                    partitionInstances,
                    [columnInfo.getValue],
                    direction
                );
            } else {
                return partitionInstances;
            }
        }
    }
);

const RANK_NODE_PI_STATUS = buildRankDictionary([
    "PARTITIONS_ONLINE",
    "PARTITIONS_OFFLINE_RECOVERING",
    "PARTITIONS_OFFLINE",
]);

// This function maps a partition instance to its equivalent node partition
// instance status (which is either PARTITIONS_ONLINE,
// PARTITIONS_OFFLINE_RECOVERING or PARTITIONS_OFFLINE). It takes into account
// the PI's state, role and whether it is a DR replica or not. This function can
// return undefined when it can't figure out the PI status for a given PI.
export function mapPIToNodePIStatus(
    partitionInstance: PartitionInstance
): Maybe<
    "PARTITIONS_ONLINE" | "PARTITIONS_OFFLINE_RECOVERING" | "PARTITIONS_OFFLINE"
> {
    switch (partitionInstance.state) {
        case "online":
            switch (partitionInstance.drReplica) {
                case true:
                    return "PARTITIONS_OFFLINE_RECOVERING";

                case false:
                    switch (partitionInstance.role) {
                        case "master":
                            return "PARTITIONS_ONLINE";

                        case "slave":
                            return "PARTITIONS_OFFLINE_RECOVERING";
                    }
            }
            break;

        case "replicating":
            switch (partitionInstance.drReplica) {
                case true:
                    return "PARTITIONS_ONLINE";

                case false:
                    switch (partitionInstance.role) {
                        case "master":
                            return "PARTITIONS_OFFLINE_RECOVERING";

                        case "slave":
                            return "PARTITIONS_ONLINE";
                    }
            }
            break;

        case "provisioning":
            return "PARTITIONS_OFFLINE";

        case "transition":
        case "pending":
        case "recovering":
            return "PARTITIONS_OFFLINE_RECOVERING";

        case "unrecoverable":
        case "offline":
            return "PARTITIONS_OFFLINE";
    }
}

// This function iterates through all the partition instances in a node and
// calculates their equivalent PI statuses. It then figures out which one has
// the worst PI status and returns state. There are 2 special cases:
// * If the node has no attached partition instances, this function returns
//   PARTITIONS_MISSING.
// * If no valid PI statuses are found, this returns PARTITIONS_UNKNOWN.
// https://docs.google.com/spreadsheets/d/1v8Csw_7bbRx5EZ3XygrYvwJt-QxZ9D1RMJNfpbBUtx0/edit?pli=1#gid=0&range=B54
export function getNodePartitionInstanceStatus(
    partitionInstances: Array<PartitionInstance>
): NodePartitionInstanceStatus {
    const filteredPartitionInstances = _.filter(
        partitionInstances,
        pi => pi.role === "master" || pi.role === "slave"
    );

    if (filteredPartitionInstances.length > 0) {
        const piStatuses: Array<
            | "PARTITIONS_ONLINE"
            | "PARTITIONS_OFFLINE_RECOVERING"
            | "PARTITIONS_OFFLINE"
        > = filteredPartitionInstances
            .map(mapPIToNodePIStatus)
            .filter(
                (
                    piStatus
                ): piStatus is
                    | "PARTITIONS_ONLINE"
                    | "PARTITIONS_OFFLINE_RECOVERING"
                    | "PARTITIONS_OFFLINE" => Boolean(piStatus)
            );

        if (piStatuses.length === 0) {
            return "PARTITIONS_UNKNOWN";
        }

        return _.orderBy(
            piStatuses,
            [piStatus => RANK_NODE_PI_STATUS[piStatus]],
            "desc"
        )[0];
    }

    return "PARTITIONS_ONLINE";
}
