import { Maybe } from "util/maybe";

import * as React from "react";
import classnames from "classnames";

import { globalDeselect } from "util/global-deselect";

import "./drag-handle.scss";

type DragHandleDirection = "n" | "s" | "e" | "w";

export type DragHandleProps = {
    onResize?: (event: MouseEvent) => void;
    onResizeComplete?: (movedConsiderableAmount: boolean) => void;
    direction: DragHandleDirection;

    // Set the drag handle's z-index to match overlays instead of the default
    // drag handle z-index, to make it show above modals. This is desirable for
    // the sidebar's drag handle because it should show over modals that cover
    // the main content area, but not for other drag handles.
    zIndexOverlay?: boolean;
};

export type DragHandleState = {
    // Defined if currently resizing.
    resizingStart: Maybe<{ clientX: number; clientY: number }>;

    // Keep track of the user having dragged the sidebar for a considerable
    // amount, which means that they are actually trying to resize a component
    // as opposed to clicking it.
    movedConsiderableAmount: boolean;
};

export class DragHandle extends React.Component<
    DragHandleProps,
    DragHandleState
> {
    state: DragHandleState = {
        resizingStart: undefined,
        movedConsiderableAmount: false,
    };

    componentDidMount() {
        document.addEventListener("mousemove", this.handleMouseMove);
        document.addEventListener("mouseup", this.handleMouseUp);
    }

    componentWillUnmount() {
        document.removeEventListener("mousemove", this.handleMouseMove);
        document.removeEventListener("mouseup", this.handleMouseUp);
    }

    handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        globalDeselect();

        const { clientX, clientY } = event;

        this.setState({
            resizingStart: { clientX, clientY },
        });
    };

    handleMouseMove = (event: MouseEvent) => {
        const { clientX, clientY } = event;
        const { resizingStart, movedConsiderableAmount } = this.state;

        if (resizingStart) {
            if (this.props.onResize) {
                this.props.onResize(event);
            }

            if (!movedConsiderableAmount) {
                this.setState({
                    movedConsiderableAmount:
                        Math.max(
                            Math.abs(clientX - resizingStart.clientX),
                            Math.abs(clientY - resizingStart.clientY)
                        ) > 5,
                });
            }
        }
    };

    handleMouseUp = () => {
        const { resizingStart, movedConsiderableAmount } = this.state;

        if (resizingStart) {
            if (this.props.onResizeComplete) {
                this.props.onResizeComplete(movedConsiderableAmount);
            }

            globalDeselect();

            this.setState({
                resizingStart: undefined,
                movedConsiderableAmount: false,
            });
        }
    };

    render() {
        const { direction, zIndexOverlay } = this.props;
        const classes = classnames("components-drag-handle", direction, {
            "z-index-overlay": zIndexOverlay,
        });

        return (
            <div className={classes} onMouseDown={this.handleMouseDown}>
                <div className="bar" />
                <div className="handle" />
            </div>
        );
    }
}
