import * as React from "react";

import classnames from "classnames";

import "./select.scss";

type SelectProps = {
    children: React.ReactNode;
};

export class Select extends React.Component<
    React.HTMLProps<HTMLSelectElement>
> {
    render() {
        const { children, className, ...rest } = this.props;

        const classes = classnames(
            {
                "components-select": true,
            },
            className
        );

        return (
            <select className={classes} {...rest}>
                {children}
            </select>
        );
    }
}
