import { Maybe } from "util/maybe";
import { TableSort } from "util/sort";
import {
    SchemaStructure,
    Column,
    Table,
    Database,
    Index,
    ResourceUsageSummary,
    SchemaEntityKind,
} from "data/models";
import { LEError, LESuccess, LELoading } from "util/loading-error";
import { LSM } from "util/loading-state-machine";

import {
    StructureAction,
    StatisticsAction,
    SchemaSortAction,
    SummaryAction,
    SchemaRestoreAction,
    TableLikeSampleAction,
    TableLikeSamplePayload,
    ClearTableLikeSampleAction,
    ColumnarSegmentsAction,
} from "data/actions/schema";

import {
    SQLStatisticsDatabase,
    SQLStatisticsTable,
    SQLStatisticsColumnsColumnar,
} from "worker/api/schema-queries";

import { StatisticsColumn } from "worker/api/schema";

import _ from "lodash";
import BigNumber from "vendor/bignumber.js/bignumber";

import assign from "util/assign";
import innerJoin from "util/joins/inner-join";
import leftJoin from "util/joins/left-join";
import {
    getInitialState,
    reduce as reduceLoadingStateMachine,
} from "util/loading-state-machine";

type Actions =
    | StructureAction
    | StatisticsAction
    | SchemaSortAction
    | SummaryAction
    | SchemaRestoreAction
    | TableLikeSampleAction
    | ClearTableLikeSampleAction
    | ColumnarSegmentsAction;

export type SchemaState = {
    structureInitial: boolean;
    structureLoading: boolean;
    structureLoaded: boolean;
    structureError?: string;
    lastStructureUpdate?: Date;

    statisticsLoading: boolean;
    statisticsLoaded: boolean;
    statisticsError?: string;
    lastStatisticsUpdate?: Date;

    structure: SchemaStructure;
    indexes: Array<Index>;

    sampleData: LSM<TableLikeSamplePayload, { message: string }>;

    sort: { [kind in SchemaEntityKind]?: TableSort };

    summary: LSM<ResourceUsageSummary, string>;

    columnarSegmentsError: Maybe<string>;
};

const initialState: SchemaState = {
    structureInitial: true,
    structureLoading: false,
    structureLoaded: false,

    statisticsLoading: false,
    statisticsLoaded: false,

    sampleData: getInitialState(),

    structure: {
        databases: [],
        tables: [],
        columns: [],
        views: [],
        viewColumns: [],
        aggregates: [],
        storedProcedures: [],
        userDefinedFunctions: [],
    },
    indexes: [],

    summary: getInitialState(),

    sort: {},

    columnarSegmentsError: undefined,
};

export default (state: SchemaState = initialState, action: Actions) => {
    switch (action.type) {
        case "SCHEMA_RESTORE": {
            state = assign(state, {
                structureError: undefined,
                structureInitial: false,
                structureLoading: false,
                structureLoaded: true,
                lastStructureUpdate: new Date(),
                structure: action.payload.structure,
                statisticsError: undefined,
                statisticsLoaded: true,
                statisticsLoading: false,
                lastStatisticsUpdate: new Date(),
                indexes: action.payload.indexes,
            });

            break;
        }

        case "SCHEMA_STRUCTURE": {
            if (action.error) {
                state = assign(state, {
                    structureError: action.payload.message,
                    structureInitial: false,
                    structureLoading: false,
                    structureLoaded: false,
                    structure: initialState.structure,
                });
            } else if (action.payload.loading) {
                state = assign(state, {
                    structureError: undefined,
                    structureInitial: false,
                    structureLoading: true,
                    structureLoaded: false,
                    structure: initialState.structure,
                });
            } else {
                state = assign(state, {
                    structureError: undefined,
                    structureInitial: false,
                    structureLoading: false,
                    structureLoaded: true,
                    structure: action.payload.data,
                    lastStructureUpdate: new Date(),
                });
            }

            break;
        }

        case "SCHEMA_STATISTICS": {
            if (action.error) {
                state = assign(state, {
                    statisticsError: action.payload.message,
                    statisticsLoading: false,
                    statisticsLoaded: false,
                });
            } else if (action.payload.loading) {
                state = assign(state, {
                    statisticsError: undefined,
                    statisticsLoading: true,
                    statisticsLoaded: false,
                    columnarSegmentsError: undefined,
                });
            } else {
                const {
                    columnStats,
                    tableStats,
                    indexes,
                    databaseStats,
                } = action.payload.data;

                const databases = innerJoin(
                    (a: Database, b: SQLStatisticsDatabase) => {
                        if (a.databaseName < b.databaseName) {
                            return -1;
                        } else if (a.databaseName === b.databaseName) {
                            return 0;
                        } else {
                            return 1;
                        }
                    },
                    (
                        database: Database,
                        statsPayload: SQLStatisticsDatabase
                    ) => {
                        let syncRepl;
                        if (statsPayload.syncRepl !== null) {
                            syncRepl = statsPayload.syncRepl.gt(0);
                        }

                        return {
                            ...database,
                            statistics: {
                                partitionCount: statsPayload.partitionCount,
                                syncRepl,
                                remoteName:
                                    statsPayload.remoteName || undefined,
                            },
                        };
                    },
                    state.structure.databases,
                    databaseStats
                );

                // The row count for sharded tables is accurate because we only count the rows in
                // master partitions. The row count for reference tables is incorrect because
                // it includes the row count for every replica of the table, so we have to
                // divide by the number of replicas of this reference table to get an accurate
                // count.
                const tables = innerJoin(
                    (a: Table, b: SQLStatisticsTable) => {
                        if (a.tableId < b.tableId) {
                            return -1;
                        } else if (a.tableId === b.tableId) {
                            return 0;
                        } else {
                            return 1;
                        }
                    },
                    (table: Table, statsPayload: SQLStatisticsTable) =>
                        assign(table, {
                            statistics: {
                                memoryUse: statsPayload.memoryUse,
                                rowCount: table.isSharded
                                    ? statsPayload.rowCount
                                    : statsPayload.rowCount.dividedBy(
                                          statsPayload.numReplicas
                                      ),
                            },
                        }),
                    state.structure.tables,
                    tableStats
                );

                // We default memory tables to 0 disk usage when we get back
                // schema statistics.
                const columns = innerJoin(
                    (a: Column, b: StatisticsColumn) => {
                        if (a.columnId < b.columnId) {
                            return -1;
                        } else if (a.columnId === b.columnId) {
                            return 0;
                        } else {
                            return 1;
                        }
                    },
                    (column: Column, statsPayload: StatisticsColumn) => {
                        let diskUsage: Maybe<BigNumber>;
                        if (column.columnStorage === "INMEMORY_ROWSTORE") {
                            diskUsage = new BigNumber(0);
                        }

                        return {
                            ...column,
                            statistics: {
                                memoryUse: statsPayload.memoryUse,
                                compressedSize:
                                    diskUsage === undefined
                                        ? new LELoading<BigNumber>()
                                        : new LESuccess(diskUsage),
                                uncompressedSize:
                                    diskUsage === undefined
                                        ? new LELoading<BigNumber>()
                                        : new LESuccess(diskUsage),
                            },
                        };
                    },
                    state.structure.columns,
                    columnStats
                );

                state = assign(state, {
                    statisticsError: undefined,
                    statisticsLoaded: true,
                    statisticsLoading: false,
                    lastStatisticsUpdate: new Date(),
                    structure: assign(state.structure, {
                        databases,
                        tables,
                        columns,
                    }),
                    indexes,
                });
            }

            break;
        }

        case "SCHEMA_COLUMNAR_SEGMENTS": {
            if (action.error) {
                state = {
                    ...state,
                    columnarSegmentsError: action.payload,
                    structure: {
                        ...state.structure,
                        columns: _.map(
                            state.structure.columns,
                            (column): Column => {
                                if (
                                    column.statistics &&
                                    column.columnStorage === "COLUMNSTORE"
                                ) {
                                    return {
                                        ...column,
                                        statistics: {
                                            ...column.statistics,
                                            compressedSize: new LEError(),
                                            uncompressedSize: new LEError(),
                                        },
                                    };
                                }

                                return column;
                            }
                        ),
                    },
                };
            } else {
                const columns = leftJoin(
                    (a: Column, b: SQLStatisticsColumnsColumnar) => {
                        if (a.columnId < b.columnId) {
                            return -1;
                        } else if (a.columnId === b.columnId) {
                            return 0;
                        } else {
                            return 1;
                        }
                    },
                    (
                        column: Column,
                        statsPayload: Maybe<SQLStatisticsColumnsColumnar>
                    ) => {
                        if (column.statistics) {
                            if (statsPayload) {
                                return {
                                    ...column,
                                    statistics: {
                                        ...column.statistics,
                                        compressedSize: new LESuccess(
                                            statsPayload.compressedSize
                                        ),
                                        uncompressedSize: new LESuccess(
                                            statsPayload.uncompressedSize
                                        ),
                                    },
                                };
                            } else if (column.columnStorage === "COLUMNSTORE") {
                                // If we don't get back columnar data for some
                                // columnstore table, then we know that table has 0
                                // disk usage.
                                return {
                                    ...column,
                                    statistics: {
                                        ...column.statistics,
                                        compressedSize: new LESuccess(
                                            new BigNumber(0)
                                        ),
                                        uncompressedSize: new LESuccess(
                                            new BigNumber(0)
                                        ),
                                    },
                                };
                            }
                        }

                        return column;
                    },
                    state.structure.columns,
                    action.payload
                );

                state = {
                    ...state,
                    structure: {
                        ...state.structure,
                        columns,
                    },
                };
            }

            break;
        }

        case "SCHEMA_SORT": {
            state = {
                ...state,
                sort: {
                    ...state.sort,
                    [action.payload.entityKind]: action.payload.sort,
                },
            };

            break;
        }

        case "SCHEMA_SUMMARY": {
            state = {
                ...state,
                summary: reduceLoadingStateMachine(state.summary, action),
            };

            break;
        }

        case "TABLE_LIKE_SAMPLE": {
            state = {
                ...state,
                sampleData: reduceLoadingStateMachine(state.sampleData, action),
            };

            break;
        }

        case "CLEAR_TABLE_LIKE_SAMPLE": {
            state = {
                ...state,
                sampleData: getInitialState(),
            };

            break;
        }
    }

    return state;
};
