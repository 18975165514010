// This function clears any selection the user might have on anything in the
// document - it's used while the user is dragging the sidebar's size in order
// to prevent the user from highlighting things while they drag.
export function globalDeselect() {
    if (document.getSelection) {
        const selection = document.getSelection();

        if (selection) {
            // Some older browsers do not have selection.empty()
            // yet and only have selection.removeAllRanges instead.

            if (selection.empty) {
                selection.empty();
            } else if (selection.removeAllRanges) {
                selection.removeAllRanges();
            }
        }
    } else {
        try {
            const selection = window.getSelection();

            if (selection) {
                selection.removeAllRanges();
            }
        } catch (e) {}
    }
}
