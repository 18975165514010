import * as React from "react";

import FeatureCard from "view/components/feature-card";
import CircleIcon from "view/components/circle-icon";

import MVRecordButton from "view/monitor/mv-record-button";

type Props = {
    title: string;
    iconName: keyof typeof CircleIcon.supportedIcons;
    children: React.ReactNode;
};

export default class MvFeatureCard extends React.PureComponent<Props> {
    render() {
        const { title, iconName, children } = this.props;

        return (
            <FeatureCard
                className="mv-feature-card"
                feature={
                    <CircleIcon
                        name={iconName}
                        size="large"
                        coloredBackground
                    />
                }
                title={title}
                body={children}
                actions={<MVRecordButton large>Begin Profiling</MVRecordButton>}
            />
        );
    }
}
