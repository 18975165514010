import * as React from "react";

import CenteringWrapper from "view/components/centering-wrapper";
import CircleIcon from "view/components/circle-icon";
import Icon from "view/components/icon";

import "./table-empty.scss";

type Props = {
    title: React.ReactNode;
    description: React.ReactNode;
};

export default class EmptyTable extends React.Component<Props> {
    render() {
        const { title, description } = this.props;

        return (
            <CenteringWrapper className="schema-table-empty">
                <CircleIcon
                    size="large"
                    name="schema"
                    statusIcon={<Icon icon="plus-circle" primary />}
                    className="icon"
                    coloredBackground
                />

                <div className="title">{title}</div>
                <div className="description">{description}</div>
            </CenteringWrapper>
        );
    }
}
