import { Maybe } from "util/maybe";

import { StaticConnectionId } from "worker/net/connection-manager";
import { DispatchFunction, State as ReduxState } from "data";
import { QueryExecutorState, QueryGroupRepr } from "worker/net/query-executor";
import { QueryStatus } from "data/reducers/query-editor";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Button } from "view/common/button";
import Modal from "view/components/modal";
import Input from "view/components/text-input";

import {
    selectConnectionQueryGroup,
    selectConnectionQueryStatuses,
} from "data/selectors/page-editor";

import { stopQueryGroup, continueQueryGroup } from "worker/api/connection";

import "./query-group-modal.scss";

type StateProps = {
    connectionId: StaticConnectionId;
    queryGroup: Maybe<QueryGroupRepr>;
    queryStatuses: Maybe<Array<QueryStatus>>;
    connectionState: Maybe<QueryExecutorState>;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

class QueryGroupModalContainer extends React.Component<Props> {
    handleContinueGroup = () => {
        const { connectionId, dispatch } = this.props;

        dispatch(
            continueQueryGroup({
                id: connectionId,
                skip: false,
            })
        );
    };

    handleContinueGroupSkipFailures = () => {
        const { connectionId, dispatch } = this.props;

        dispatch(
            continueQueryGroup({
                id: connectionId,
                skip: true,
            })
        );
    };

    handleStopGroup = () => {
        const { connectionId, dispatch } = this.props;

        dispatch(
            stopQueryGroup({
                id: connectionId,
            })
        );
    };

    render() {
        const { connectionState, queryGroup, queryStatuses } = this.props;

        if (!queryGroup || !queryStatuses) {
            return null;
        }

        // Note that whenever a query group is being executed (no matter if it
        // has 1 or more than 1 query), we always render this modal with the
        // latest error message and query to the DOM (we do this so that CSS
        // transitions work). This means that we have to be very careful when
        // reading the current error message and query from the state.

        const queryCount = queryGroup.queries.length;
        const currentQueryIndex = queryStatuses.length - 1;

        let currentQuery, currentError;
        if (currentQueryIndex >= 0) {
            currentQuery = queryGroup.queries[currentQueryIndex].text;

            const currentStatus = queryStatuses[currentQueryIndex];

            if (currentStatus.status === "error") {
                currentError = currentStatus.errorMessage;
            }
        }

        const currentQueryNumber = currentQueryIndex + 1; // 1-indexed, for display

        return (
            <Modal
                active={connectionState === "PAUSED"}
                inline
                innerContentPadding
                actions={
                    <>
                        <Button large onClick={this.handleContinueGroup}>
                            Skip Query {currentQueryNumber}
                        </Button>

                        <Button
                            large
                            onClick={this.handleContinueGroupSkipFailures}
                        >
                            Skip All Failures
                        </Button>

                        <Button primary large onClick={this.handleStopGroup}>
                            Stop
                        </Button>
                    </>
                }
            >
                <div className="query-group-modal-body">
                    <div className="title">
                        Query {currentQueryNumber} of {queryCount} failed
                    </div>
                    <div className="description">
                        What would you like to do?
                    </div>
                    <div className="query-error">{currentError}</div>
                    <Input
                        className="query-text"
                        input={{
                            name: "query",
                            value: currentQuery,
                            readOnly: true,
                        }}
                        type="textarea"
                    />
                </div>
            </Modal>
        );
    }
}

export default connect(
    (s: ReduxState): StateProps => ({
        connectionId: s.queryEditor.connectionId,
        queryGroup: selectConnectionQueryGroup(s),
        queryStatuses: selectConnectionQueryStatuses(s),
        connectionState: s.queryEditor.connectionState,
    })
)(QueryGroupModalContainer);
