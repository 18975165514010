import { Store } from "data/store";
import { Observer } from "rxjs";

import { Observable } from "rxjs/Observable";

export default function observableFromStore(store: Store) {
    return Observable.create((observer: Observer<unknown>) => {
        return store.subscribe(() => {
            observer.next(store.getState());
        });
    });
}
