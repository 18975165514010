import { Maybe } from "util/maybe";

import { TOC } from "view/tutorial/content/toc";

import {
    ChangeTutorialMinimizedStateAction,
    ChangeTutorialClosedStateAction,
    ChangeTutorialLocationAction,
    ChangeTutorialStepAction,
} from "data/actions/tutorial";

import { getNodeFromLocation } from "view/tutorial/types";

import { logError } from "util/logging";
import { readStudioConfig } from "util/studio-config";
import * as analytics from "util/segment";

type Actions =
    | ChangeTutorialMinimizedStateAction
    | ChangeTutorialClosedStateAction
    | ChangeTutorialLocationAction
    | ChangeTutorialStepAction;

export type TutorialState = {
    minimized: boolean;
    closed: boolean;
    location: Array<string>;

    // this is undefined initially and becomes undefined whenever we navigate to
    // another tutorial page (change tutorial location) that has no steps
    step: Maybe<number>;
};

const tutorialInitialState = readStudioConfig().tutorialState;

const initialState: TutorialState = {
    minimized: tutorialInitialState.minimized,
    closed: tutorialInitialState.closed,
    location: [TOC.id],
    step: undefined,
};

// Track the initial state of the tutorial to analytics on app load.
(function({ minimized, closed }: TutorialState) {
    let state;
    if (!closed && !minimized) {
        state = "open";
    } else if (minimized) {
        state = "minimized";
    } else {
        state = "closed";
    }

    analytics.track("tutorial-default-state", {
        category: "tutorial",
        state,
    });
})(initialState);

export default (state: TutorialState = initialState, action: Actions) => {
    switch (action.type) {
        case "CHANGE_TUTORIAL_MINIMIZED_STATE": {
            state = {
                ...state,
                minimized: action.payload.minimized,
            };

            break;
        }

        case "CHANGE_TUTORIAL_CLOSED_STATE": {
            let minimized = state.minimized;

            // Maximize the tutorial if tutorial open
            if (!action.payload.closed) {
                minimized = false;
            }

            state = {
                ...state,
                minimized,
                closed: action.payload.closed,
            };

            break;
        }

        case "CHANGE_TUTORIAL_LOCATION": {
            // We try to get the current tutorial node, and log an error if it
            // is an invalid node. We do this in the reducer instead of a thunk
            // because the worker imports the actions file, and we cannot import
            // TOC (React) in the worker.
            try {
                const currNode = getNodeFromLocation(action.payload.location);
                const step = "steps" in currNode ? 0 : undefined;

                state = {
                    ...state,
                    location: action.payload.location,
                    step,
                };
            } catch (err) {
                logError(err);

                state = {
                    ...state,
                    location: [TOC.id],
                };
            }

            break;
        }

        case "CHANGE_TUTORIAL_STEP": {
            state = {
                ...state,
                step: action.payload.step,
            };

            break;
        }
    }

    return state;
};
