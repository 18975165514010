import * as React from "react";
import classnames from "classnames";

import "./card.scss";

type Props = {
    className?: string;
    children: React.ReactNode;
};

const Card = ({ className, children, ...rest }: Props) => (
    <div className={classnames("components-card", className)} {...rest}>
        {children}
    </div>
);

export default Card;
