import { Maybe } from "util/maybe";
import {
    LoadingError,
    LESuccess,
    LEError,
    LELoading,
} from "util/loading-error";
import { Node } from "data/models";

import BigNumber from "vendor/bignumber.js/bignumber";

import NumberFormatter from "util/number-formatter";
import { LONG_EM_DASH } from "util/symbols";

export type HostLiveMonitoringMetrics = {
    // This corresponds to the TOTAL USED cpu time in the sysinfo tables
    totalCpuUsagePercentRate: LoadingError<number>;

    // This corresponds to the HOST USED MEMORY in the sysinfo tables
    systemUsedMemoryB: LoadingError<BigNumber>;

    // This corresponds to the HOST MEMORY in the sysinfo tables
    systemTotalMemoryB: LoadingError<BigNumber>;

    // bytes per second
    netReceivedRateBpS: LoadingError<number>;
    netTransmittedRateBpS: LoadingError<number>;

    // This corresponds to the HOST DISK in the sysinfo tables (calculated
    // using each node's mountPoint information)
    systemTotalDiskB: LoadingError<BigNumber>;

    // This corresponds to the USED HOST DISK in the sysinfo tables (calculated
    // using each node's mountPoint information)
    systemUsedDiskB: LoadingError<BigNumber>;

    // bytes per second
    diskWriteRateBpS: LoadingError<number>;
    diskReadRateBpS: LoadingError<number>;
};

export type Host = {
    address: string;
    cpuCount: Maybe<BigNumber>;

    // This is a mebibytes value.
    systemMemoryB: Maybe<BigNumber>;

    liveMonitoring: HostLiveMonitoringMetrics;
};

export type DerivedHost = Host & {
    nodes: Array<Node>;
};

export function formatHostMemory(host: Host | DerivedHost) {
    if (host.systemMemoryB) {
        return NumberFormatter.formatBytes(host.systemMemoryB);
    } else {
        return LONG_EM_DASH;
    }
}

export function getLoadingHostLiveMonitoringMetrics(): HostLiveMonitoringMetrics {
    return {
        totalCpuUsagePercentRate: new LELoading(),

        systemUsedMemoryB: new LELoading(),
        systemTotalMemoryB: new LELoading(),

        netReceivedRateBpS: new LELoading(),
        netTransmittedRateBpS: new LELoading(),

        systemTotalDiskB: new LELoading(),
        systemUsedDiskB: new LELoading(),

        diskWriteRateBpS: new LELoading(),
        diskReadRateBpS: new LELoading(),
    };
}

export type HostRatioMetric = {
    ratio: number;
    total: BigNumber;
    used: BigNumber;
};

export const getHostMemoryUsage = (
    host: DerivedHost
): LoadingError<HostRatioMetric> => {
    const {
        liveMonitoring: { systemUsedMemoryB, systemTotalMemoryB },
    } = host;

    if (systemUsedMemoryB.isLoading() && systemTotalMemoryB.isLoading()) {
        return new LELoading();
    } else if (
        systemUsedMemoryB.isLoading() ||
        systemTotalMemoryB.isLoading() ||
        systemUsedMemoryB.isError() ||
        systemTotalMemoryB.isError() ||
        systemTotalMemoryB.value.isZero()
    ) {
        return new LEError();
    } else {
        const percentValue = systemUsedMemoryB.value.dividedBy(
            systemTotalMemoryB.value
        );

        return new LESuccess({
            ratio: percentValue.toNumber(),
            total: systemTotalMemoryB.value,
            used: systemUsedMemoryB.value,
        });
    }
};

export const getHostDiskUsage = (
    host: DerivedHost
): LoadingError<HostRatioMetric> => {
    const {
        liveMonitoring: { systemUsedDiskB, systemTotalDiskB },
    } = host;

    if (systemUsedDiskB.isLoading() && systemTotalDiskB.isLoading()) {
        return new LELoading();
    } else if (
        systemUsedDiskB.isLoading() ||
        systemTotalDiskB.isLoading() ||
        systemUsedDiskB.isError() ||
        systemTotalDiskB.isError() ||
        systemTotalDiskB.value.isZero()
    ) {
        return new LEError();
    } else {
        const percentValue = systemUsedDiskB.value.dividedBy(
            systemTotalDiskB.value
        );

        return new LESuccess({
            ratio: percentValue.toNumber(),
            total: systemTotalDiskB.value,
            used: systemUsedDiskB.value,
        });
    }
};
