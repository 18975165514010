import { TableSort } from "util/sort";
import {
    TopologyAction,
    TopologyPayload,
    SortNodesAction,
    SortNodePartitionInstancesAction,
    NodesLiveMonitoringAction,
} from "data/actions";
import { Node } from "data/models";
import { LSM } from "util/loading-state-machine";
import { LEError } from "util/loading-error";

import _ from "lodash";

import {
    getInitialState,
    reduce as reduceLoadingStateMachine,
    updatePayload,
} from "util/loading-state-machine";
import { formatNodeAddress } from "data/models";

type Actions =
    | TopologyAction
    | SortNodesAction
    | SortNodePartitionInstancesAction
    | NodesLiveMonitoringAction;

export type TopologyState = {
    topology: LSM<TopologyPayload, string>;

    nodesSort: TableSort;
    nodePartitionInstancesSort: TableSort;
};

const initialState: TopologyState = {
    topology: getInitialState(),

    // default order for node topology table
    nodesSort: {
        columnId: "nodeRole",
        direction: "desc",
    },

    nodePartitionInstancesSort: {
        columnId: "partitionInstanceRole",
        direction: "desc",
    },
};

export default (state: TopologyState = initialState, action: Actions) => {
    switch (action.type) {
        case "TOPOLOGY":
            {
                state = {
                    ...state,
                    topology: reduceLoadingStateMachine(state.topology, action),
                };
            }

            break;

        case "SORT_NODES": {
            state = {
                ...state,
                nodesSort: action.payload,
            };

            break;
        }

        case "SORT_NODE_PARTITION_INSTANCES": {
            state = {
                ...state,
                nodePartitionInstancesSort: action.payload,
            };

            break;
        }

        case "NODES_LIVE_MONITORING": {
            if (action.error) {
                state = {
                    ...state,
                    topology: updatePayload(
                        state.topology,
                        (payload: TopologyPayload): TopologyPayload => ({
                            ...payload,
                            nodes: _.map(
                                payload.nodes,
                                (node): Node => ({
                                    ...node,
                                    liveMonitoring: {
                                        totalCpuUsagePercent: new LEError(),
                                        usedMemoryB: new LEError(),
                                        memoryLimitB: new LEError(),
                                        usedDiskB: new LEError(),
                                        totalDiskB: new LEError(),
                                    },
                                })
                            ),
                        })
                    ),
                };
            } else {
                state = {
                    ...state,
                    topology: updatePayload(
                        state.topology,
                        (payload: TopologyPayload): TopologyPayload => ({
                            ...payload,
                            nodes: _.map(payload.nodes, node => ({
                                ...node,
                                liveMonitoring: {
                                    totalCpuUsagePercent:
                                        (formatNodeAddress(node) in
                                            action.payload &&
                                            action.payload[
                                                formatNodeAddress(node)
                                            ].totalCpuUsagePercent) ||
                                        new LEError(),
                                    usedMemoryB:
                                        (formatNodeAddress(node) in
                                            action.payload &&
                                            action.payload[
                                                formatNodeAddress(node)
                                            ].usedMemoryB) ||
                                        new LEError(),
                                    memoryLimitB:
                                        (formatNodeAddress(node) in
                                            action.payload &&
                                            action.payload[
                                                formatNodeAddress(node)
                                            ].memoryLimitB) ||
                                        new LEError(),
                                    totalDiskB:
                                        (formatNodeAddress(node) in
                                            action.payload &&
                                            action.payload[
                                                formatNodeAddress(node)
                                            ].totalDiskB) ||
                                        new LEError(),
                                    usedDiskB:
                                        (formatNodeAddress(node) in
                                            action.payload &&
                                            action.payload[
                                                formatNodeAddress(node)
                                            ].usedDiskB) ||
                                        new LEError(),
                                },
                            })),
                        })
                    ),
                };
            }

            break;
        }
    }

    return state;
};
