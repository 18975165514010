import { State, DispatchFunction } from "data";
import { QueryExecutorState } from "worker/net/query-executor";
import { Maybe } from "util/maybe";
import { Direction } from "view/components/overlay";
import { ButtonOnClickProps } from "view/common/button";

import * as React from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import { Button } from "view/common/button";
import Tip from "view/components/tip";

import { consoleRunQuery } from "data/actions";
import * as analytics from "util/segment";

import "./console-query-button.scss";

type StateProps = {
    consoleQueryExecutorState: Maybe<QueryExecutorState>;
};

type ReduxProps = {
    dispatch: DispatchFunction;
};

type OwnProps = {
    className?: string;
    query: string;
    category: string;
    action: string;
    tipProps: {
        direction?: Direction;
        tooltip: string;
    };
    buttonProps?: ButtonOnClickProps;
};

type Props = StateProps & ReduxProps & OwnProps;

function ConsoleQueryButton({
    query,
    consoleQueryExecutorState,
    dispatch,
    category,
    action,
    tipProps,
    buttonProps,
    className,
}: Props) {
    const onClick = () => {
        dispatch(consoleRunQuery({ query }));

        analytics.track(action, { category });
    };

    const classes = classnames("common-console-query-button", className);

    return (
        <Tip {...tipProps}>
            <Button
                className={classes}
                onClick={onClick}
                small
                ghost
                disabled={consoleQueryExecutorState !== "IDLE"}
                icon="console"
                {...buttonProps}
            />
        </Tip>
    );
}

export default connect(
    (s: State): StateProps => ({
        consoleQueryExecutorState: s.console.queryExecutorState,
    })
)(ConsoleQueryButton);
