import { FieldList } from "mysqljs";
import { QueryError, Metrics, Row } from "worker/net/static-connection";

import { CurrentQueryInfo } from "data/reducers/console";

import _ from "lodash";
import NumberFormatter from "util/number-formatter";

const CELL_WIDTH = 30;

const ASTERISK_HALF_LINE = _.repeat("*", 27);

const separator = (numFields: number) =>
    `+${"-".repeat(CELL_WIDTH * numFields + (numFields - 1))}+`;

const formatCell = (contents: string) =>
    _.truncate(_.padEnd(" " + contents + " ", CELL_WIDTH), {
        length: CELL_WIDTH,
    });

const formatFields = (fields: FieldList) =>
    [
        separator(fields.length),
        "|" + _.map(fields, f => formatCell(f.name)).join("|") + "|",
        separator(fields.length),
    ].join("\n");

const formatRow = (row: Row, queryInfo: CurrentQueryInfo, rowIndex: number) => {
    if (queryInfo.vertical) {
        const fieldPad = queryInfo.longestFieldLength;

        if (fieldPad === undefined) {
            throw new Error(
                "Expected queryInfo.longestFieldLength to be defined"
            );
        }

        return (
            `${ASTERISK_HALF_LINE} ${rowIndex +
                1}. row ${ASTERISK_HALF_LINE}\n` +
            _.map(queryInfo.fields, (field, idx: number) => {
                return `${field.name.padStart(fieldPad)}: ${row[idx]}`;
            }).join("\n")
        );
    } else {
        return "|" + _.map(row, c => formatCell(c)).join("|") + "|";
    }
};

const formatError = (res: QueryError) => res.message;

export const truncateBuffer = (buf: string, maxLength: number) => {
    if (buf.length > maxLength) {
        // trim the buffer to maxLength by slicing a string of maxLength length
        // from the end
        buf = buf.slice(buf.length - maxLength);
        // find the first newline
        const idx = buf.indexOf("\n");
        // trim up to the first newline
        buf = buf.slice(idx);
    }
    return buf;
};

export const bufferWithQuery = (buf: string, query: string) =>
    buf + `\n> ${query}`;

export const bufferWithFields = (buf: string, queryInfo: CurrentQueryInfo) => {
    if (!queryInfo.fields) {
        throw new Error("Expected queryInfo.fields to be defined");
    }

    if (queryInfo.vertical) {
        return buf;
    }

    return buf + "\n" + formatFields(queryInfo.fields);
};

export const bufferWithRow = (
    buf: string,
    row: Row,
    queryInfo: CurrentQueryInfo,
    rowIndex: number
) => buf + "\n" + formatRow(row, queryInfo, rowIndex);

// When printing an error or the success metrics, we should check to see if
// there was an unfinished query output in progress and print its output. This
// can only happen in stored procedures.
export function cleanUpLastQuery(buf: string, queryInfo: CurrentQueryInfo) {
    // As long as rows have been printed, we have to print a separator. A row's
    // query end might be of type "exec" and rows may still have been printed
    // (this happens when calling stored procedures).
    if (queryInfo.rowsPrinted > 0 && queryInfo.fields) {
        if (queryInfo.vertical) {
            buf += "\n";
        } else {
            buf += "\n" + separator(queryInfo.fields.length) + "\n";
        }
    } else if (queryInfo.fields && queryInfo.rowsPrinted === 0) {
        buf += "\nEmpty set\n";
    }

    return buf;
}

export const bufferWithError = (
    buf: string,
    err: QueryError,
    queryInfo: CurrentQueryInfo
) => {
    buf = cleanUpLastQuery(buf, queryInfo);

    return buf + "\n" + formatError(err);
};

export const bufferWithSuccessMetrics = (
    buf: string,
    queryInfo: CurrentQueryInfo,
    metrics: Metrics
) => {
    if (!metrics.endTimeUnix) {
        throw new Error("Expected end time to be set");
    }
    const duration = NumberFormatter.formatDuration(
        metrics.endTimeUnix - metrics.startTimeUnix
    );

    buf = cleanUpLastQuery(buf, queryInfo);

    // We check for one of the metrics' exec fields ("affectedRows") to
    // distinguish an exec query from a rows query.
    if (!("affectedRows" in metrics) && queryInfo.fields) {
        const count = metrics.emittedRows;

        if (count > 0) {
            const rows = count === 1 ? "row" : "rows";

            buf += "\n" + `${count} ${rows} in set (${duration})`;
        } else {
            buf += "\n" + `Empty set (${duration})`;
        }
    } else if ("affectedRows" in metrics) {
        const count = metrics.affectedRows || 0;
        const rows = count === 1 ? "row" : "rows";
        buf += "\n" + `Query OK, ${count} ${rows} affected (${duration})`;
    }

    return buf + "\n";
};
