import { Maybe } from "util/maybe";
import { SortDirection, TableSort } from "util/sort";

import { State, DispatchFunction } from "data";
import { MemsqlEvent } from "data/models/events";
import { ColumnId } from "view/components/super-table";

import * as React from "react";
import { connect } from "react-redux";

import Header from "view/common/header";
import { Button } from "view/common/button";
import Loading from "view/components/loading";
import GeneralTable from "view/components/general-table";
import LastUpdated from "view/components/last-updated";
import GeneralError from "view/common/general-error";
import ExtLink from "view/components/external-link";
import CenteringWrapper from "view/components/centering-wrapper";
import FeatureCard from "view/components/feature-card";
import CircleIcon from "view/components/circle-icon";

import { sortEvents } from "data/actions/events";
import { queryEvents } from "worker/api/events";

import {
    selectEventsLoading,
    selectEventsError,
    selectEventsSort,
    selectSortedEvents,
    selectEventsLastUpdate,
} from "data/selectors/events";

import EVENT_COLUMNS from "view/events/columns-info";

import { nextTableSort } from "util/sort";
import * as analytics from "util/segment";

import "./index.scss";

type StateProps = {
    loading: boolean;
    events: Maybe<Array<MemsqlEvent>>;
    sort: TableSort;
    lastUpdate: Maybe<Date>;
    error: Maybe<string>;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

class EventsPage extends React.Component<Props> {
    handleSort = (columnId: ColumnId, sortDir: Maybe<SortDirection>) => {
        this.props.dispatch(sortEvents(nextTableSort(columnId, sortDir)));
    };

    handleReload = () => {
        this.props.dispatch(queryEvents({}));
    };

    componentDidMount() {
        this.handleReload();
    }

    handleClickReload = () => {
        this.handleReload();

        analytics.trackReload("events");
    };

    render() {
        const { events, sort, error, loading, lastUpdate } = this.props;

        let lastUpdated;
        if (lastUpdate) {
            lastUpdated = (
                <span className="last-updated">
                    <LastUpdated date={lastUpdate} />
                </span>
            );
        }

        const actions = (
            <>
                {lastUpdated}

                <Button
                    disabled={loading}
                    small
                    icon="sync-alt"
                    onClick={this.handleClickReload}
                />
            </>
        );

        let content;
        if (error) {
            content = <GeneralError error={error} />;
        } else if (!events) {
            content = <Loading size="large" />;
        } else if (events.length === 0) {
            content = (
                <CenteringWrapper>
                    <FeatureCard
                        feature={
                            <CircleIcon
                                name="events"
                                size="large"
                                coloredBackground
                            />
                        }
                        title="No Events"
                        body={
                            <>
                                <div>
                                    Cluster events will show up on this page.
                                </div>
                                <div>
                                    <ExtLink
                                        name="lmv-events"
                                        category="events"
                                    >
                                        Learn about events.
                                    </ExtLink>
                                </div>
                            </>
                        }
                    />
                </CenteringWrapper>
            );
        } else {
            content = (
                <GeneralTable
                    onSort={this.handleSort}
                    columns={EVENT_COLUMNS}
                    rows={events}
                    sort={sort}
                />
            );
        }

        return (
            <div className="events-page">
                <Header
                    className="events-header"
                    left="Events"
                    right={actions}
                />

                {content}
            </div>
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        loading: selectEventsLoading(s),
        events: selectSortedEvents(s),
        error: selectEventsError(s),
        sort: selectEventsSort(s),
        lastUpdate: selectEventsLastUpdate(s),
    })
)(EventsPage);
