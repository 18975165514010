import { DispatchFunction, State as ReduxState } from "data";
import { OnSubmitSuccess } from "view/controllers/connect";
import { CollapsableFormError } from "view/common/collapsable-form";
import { Maybe } from "util/maybe";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import FullScreen from "view/components/fullscreen";
import { Button } from "view/common/button";

import {
    AddNewClusterForm,
    ADD_NEW_CLUSTER_FORM_NAME,
} from "view/forms/add-new-cluster";

import {
    getFormSyncErrors,
    isSubmitting,
    getFormError,
    submit,
    destroy,
} from "redux-form";

import "./add-new-cluster.scss";

type StateProps = {
    formErrors: Maybe<CollapsableFormError>;
    submitting: boolean;
    canSubmit: boolean;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
    onSubmitSuccess: OnSubmitSuccess;
    onCancel: () => void;
};

type State = {
    // The AddNewClusterPage can be quite tall which means
    // that we want to scroll it to the top (where the errors
    // are shown) whenever the user submits and hits an error.
    //
    // The `shouldScrollTop` variable tracks whether the
    // page should be scrolled to the top when rendered
    // with errors. The only time when this variable is `true` is
    // when the form has been submitted, but we haven't scrolled
    // the component to the top yet.
    shouldScrollTop: boolean;
};

const syncErrorsSelector = getFormSyncErrors(ADD_NEW_CLUSTER_FORM_NAME);
const submitErrorsSelector = getFormError(ADD_NEW_CLUSTER_FORM_NAME);
const submittingSelector = isSubmitting(ADD_NEW_CLUSTER_FORM_NAME);

class AddNewClusterPage extends React.Component<Props, State> {
    $fullscreen: Maybe<FullScreen>;

    constructor(props: Props) {
        super(props);

        this.state = {
            shouldScrollTop: false,
        };
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        if (nextProps.formErrors && prevState.shouldScrollTop) {
            return { shouldScrollTop: false };
        }

        return null;
    }

    componentDidUpdate() {
        if (this.props.formErrors && this.state.shouldScrollTop) {
            if (this.$fullscreen) {
                this.$fullscreen.scrollToTop();
            }
        }
    }

    handleSubmit = () => {
        const action = submit(ADD_NEW_CLUSTER_FORM_NAME);

        this.setState({ shouldScrollTop: true });

        this.props.dispatch({
            type: action.type,
            error: action.error || false,
            payload: action.payload,
            meta: action.meta,
        });
    };

    handleCancel = () => {
        // Destroy the form such that no data is saved when the user returns to the
        // form.
        const action = destroy(ADD_NEW_CLUSTER_FORM_NAME);

        this.props.dispatch({
            type: action.type,
            error: action.error || false,
            payload: action.payload,
            meta: action.meta,
        });

        this.props.onCancel();
    };

    render() {
        const { dispatch, onSubmitSuccess, canSubmit, submitting } = this.props;

        return (
            <FullScreen
                ref={$fullscreen => {
                    this.$fullscreen = $fullscreen || undefined;
                }}
                actions={
                    <>
                        <Button
                            large
                            onClick={this.handleCancel}
                            tabIndex={-1}
                            disabled={submitting}
                        >
                            Cancel
                        </Button>
                        <Button
                            large
                            disabled={!canSubmit}
                            primary
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </Button>
                    </>
                }
            >
                <div className="add-new-cluster-page">
                    <div className="header">
                        <h1>Connect to a cluster</h1>
                        <h2 className="description">
                            <div>
                                Connect to a MemSQL cluster to start using
                                MemSQL Studio.
                            </div>
                            <div>
                                If you have connected to a cluster previously,
                                you will find it in the dropdown menu.
                            </div>
                        </h2>
                    </div>

                    <div className="form-wrapper">
                        <AddNewClusterForm
                            canSubmit={canSubmit}
                            onSubmitSuccess={result => {
                                onSubmitSuccess(result.clusterId);
                            }}
                            dispatch={dispatch}
                        />
                    </div>
                </div>
            </FullScreen>
        );
    }
}

export default connect(
    (s: ReduxState): StateProps => ({
        formErrors: submitErrorsSelector(s) as Maybe<CollapsableFormError>,
        submitting: submittingSelector(s),
        canSubmit: _.isEmpty(syncErrorsSelector(s)) && !submittingSelector(s),
    })
)(AddNewClusterPage);
