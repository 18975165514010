import { Maybe } from "util/maybe";
import { State as RouteState } from "router5";
import { DispatchFunction } from "data";

import * as React from "react";
import { connect } from "react-redux";
import { createRouteNodeSelector } from "redux-router5";
import { startsWithSegment } from "router5-helpers";

import ConnectController from "view/controllers/connect";
import ClusterController from "view/controllers/cluster";
import { ErrorBoundary } from "view/common/error-boundary";

import { proxyPing, proxyPingStop } from "worker/api/proxy";
import { webPing } from "data/actions";

type Props = {
    dispatch: DispatchFunction;
    route: RouteState;
};

const WEB_PING_INTERVAL = 600000; //10min;

class RouterController extends React.Component<Props> {
    webPingIntervalId: Maybe<number>;

    componentDidMount() {
        this.props.dispatch(proxyPing());
        this.webPingIntervalId = window.setInterval(
            () => this.props.dispatch(webPing()),
            WEB_PING_INTERVAL
        );
    }

    componentWillUnmount() {
        this.props.dispatch(proxyPingStop());
        window.clearInterval(this.webPingIntervalId);
    }

    render() {
        const { route } = this.props;

        const testRoute = startsWithSegment(route);

        let controller;
        if (testRoute("connect")) {
            controller = <ConnectController />;
        } else if (testRoute("cluster")) {
            controller = <ClusterController />;
        } else {
            controller = (
                <div>
                    <h1>404 not found</h1>
                </div>
            );
        }

        return (
            <ErrorBoundary
                generalErrorProps={{
                    errorHeader:
                        "An error occurred, please refresh the page and try again.",
                    error:
                        "If the error persists, please contact the support team.",
                }}
            >
                {controller}
            </ErrorBoundary>
        );
    }
}

export default connect(() => createRouteNodeSelector(""))(RouterController);
