import { Maybe } from "util/maybe";

import { State } from "data";
import { State as RouteState } from "router5";
import { ClusterId } from "data/models";

import { logError } from "util/logging";

// Here, we're telling TypeScript that s.router.route is NOT nullable
// even though it is. This is okay because we know that `selectRoute` is
// only called after the application has been loaded and there is a route in state.
export const selectRoute = (s: State): RouteState => {
    if (!s.router.route) {
        logError(
            new Error("selectRoute was called and there is no route in state")
        );
    }

    return s.router.route as RouteState;
};

export const selectCurrentRouteClusterId = (s: State): Maybe<ClusterId> =>
    selectRoute(s).params.clusterId;
