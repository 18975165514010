// This function returns the fully qualified name ("full.name") for a database
// entity in MemSQL. Note that tables and functions can have backticks in their
// name, which is why we need to be very careful when escaping them.
export default function({
    databaseName,
    name,
}: {
    databaseName: string;
    name: string;
}) {
    const quotedDatabaseName = "`" + databaseName.replace("`", "``") + "`";
    const quotedEntityName = "`" + name.replace("`", "``") + "`";

    return `${quotedDatabaseName}.${quotedEntityName}`;
}
