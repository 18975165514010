import * as React from "react";
import classnames from "classnames";

import Icon, { IconRepr } from "view/components/icon";

import "./text-input.scss";

export type InputType = "text" | "password" | "textarea";

type BaseProps = {
    className?: string;
    small?: boolean;
    meta: Object;
};

type Props = BaseProps &
    (
        | {
              type: "textarea";
              input: React.HTMLProps<HTMLTextAreaElement>;
          }
        | {
              type: "text";
              input: React.HTMLProps<HTMLInputElement>;
              icon?: IconRepr;
          }
        | {
              type: "password";
              input: React.HTMLProps<HTMLInputElement>;
          });

export default class Input extends React.PureComponent<Props> {
    static defaultProps = {
        meta: {},
    };

    render() {
        const { className, small, meta, ...rest } = this.props;

        let classes = classnames(
            {
                "components-input": true,
                small,
                ...meta,
            },
            className
        );

        if (this.props.type === "textarea") {
            // Textareas and text inputs share the same style and interactions,
            // so we render both of them with the same component.
            return (
                <div className={classes}>
                    <textarea
                        {...rest}
                        {...this.props.input}
                        id={this.props.input.name}
                    />
                </div>
            );
        } else {
            let icon;
            if (this.props.type === "text" && this.props.icon) {
                icon = <Icon className="icon" {...this.props.icon} size="sm" />;
                classes = classnames(classes, "with-icon");
            }

            return (
                <div className={classes}>
                    {icon}
                    <input
                        {...rest}
                        {...this.props.input}
                        type={this.props.type}
                        id={this.props.input.name}
                    />
                </div>
            );
        }
    }
}
