import * as React from "react";
import classnames from "classnames";

import "./schema-content.scss";

type Props = {
    className?: string;
    topContent?: React.ReactNode;
    mainContent: React.ReactNode;
};

export default class SchemaContent extends React.Component<Props> {
    render() {
        const { topContent, mainContent, className } = this.props;

        return (
            <div className={classnames("schema-content", className)}>
                {topContent}

                <div className="main-content">{mainContent}</div>
            </div>
        );
    }
}
