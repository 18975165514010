import { Maybe } from "util/maybe";
import { SortDirection, TableSort } from "util/sort";
import { ActiveProcess } from "data/models";
import { State as RouteState } from "router5";
import { State as ReduxState, DispatchFunction } from "data";
import { ColumnId } from "view/components/super-table";
import { ActiveProcessesColumn } from "view/active-processes/active-processes-table";
import { InjectedRoute } from "react-router5";
import { ActiveProcessId } from "data/models";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRoute } from "react-router5";

import Header from "view/common/header";
import LastUpdated from "view/components/last-updated";
import GeneralError from "view/common/general-error";
import Loading from "view/components/loading";
import CenteringWrapper from "view/components/centering-wrapper";
import FeatureCard from "view/components/feature-card";
import CircleIcon from "view/components/circle-icon";
import ExtLink from "view/components/external-link";
import { Button } from "view/common/button";
import Modal from "view/components/modal";
import SimpleModalBody from "view/components/simple-modal-body";
import Input from "view/components/text-input";
import { addSort } from "view/components/super-table";
import { ActiveProcessesTable } from "view/active-processes/active-processes-table";
import { makeCheckboxColumn } from "view/util/checkbox-column";

import {
    selectSort,
    selectSortedActiveProcesses,
    selectSelectedActiveProcessIds,
    selectKilledActiveProcessIds,
    selectCanExplainActiveProcess,
} from "data/selectors/active-processes";
import { selectRoute } from "data/selectors";
import {
    sortActiveProcesses,
    changeActiveProcessSelected,
    killedActiveProcessIds,
} from "data/actions/active-processes";
import { exportCSV } from "data/actions/active-processes-export";
import {
    queryActiveProcesses,
    isActiveProcessesEnabled,
    killQuery,
} from "worker/api/active-processes";
import { dynamicExplainQuery } from "worker/api/explain";
import { ACTIVE_PROCESSES_COLUMNS } from "view/active-processes/columns-info";

import { nextTableSort } from "util/sort";
import {
    selectLastUpdate,
    selectError,
    selectIsInitial,
} from "util/loading-state-machine";
import { logError } from "util/logging";
import getRoute from "util/get-route";
import { LONG_EM_DASH } from "util/symbols";

import "./page-active.scss";

type StateProps = {
    activeProcesses: Maybe<Array<ActiveProcess>>;
    activeProcessesEnabled: Maybe<boolean>;
    selectedProcessIds: Array<ActiveProcessId>;
    killedProcessIds: Array<ActiveProcessId>;
    lastUpdate: Maybe<Date>;
    sort: TableSort;
    error: Maybe<string>;
    loading: boolean;
    canExplain: boolean;
    route: RouteState;
};

type Props = StateProps &
    InjectedRoute & {
        dispatch: DispatchFunction;
    };

type KillQueryModalState = {
    processCount: number;
    queryTexts: Array<string>;
};

type State = {
    // The kill query modal is open when this is not undefined.
    killQueryModalState: Maybe<KillQueryModalState>;
};

class ActiveProcessesPage extends React.Component<Props, State> {
    state: State = {
        killQueryModalState: undefined,
    };

    timeoutId: Maybe<NodeJS.Timeout>;

    componentDidMount() {
        if (this.props.activeProcessesEnabled === undefined) {
            this.props.dispatch(isActiveProcessesEnabled());
        } else if (this.props.activeProcessesEnabled) {
            this.queryActiveProcesses();
        }
    }

    componentDidUpdate() {
        if (this.props.activeProcessesEnabled && this.timeoutId === undefined) {
            this.queryActiveProcesses();
        }
    }

    componentWillUnmount() {
        if (this.timeoutId !== undefined) {
            clearTimeout(this.timeoutId);
        }
    }

    queryActiveProcesses = () => {
        this.props.dispatch(queryActiveProcesses());
        this.timeoutId = setTimeout(this.queryActiveProcesses, 5000);
    };

    handleSort = (columnId: ColumnId, direction: Maybe<SortDirection>) => {
        this.props.dispatch(
            sortActiveProcesses(nextTableSort(columnId, direction))
        );
    };

    handleExplainQuery = () => {
        const {
            dispatch,
            router,
            activeProcesses,
            selectedProcessIds,
            canExplain,
        } = this.props;
        const {
            params: { clusterId },
        } = getRoute(this.props.route);

        if (canExplain) {
            const [id] = selectedProcessIds;
            const selectedProcess = _.find(activeProcesses, process =>
                id.matches(process)
            );

            if (selectedProcess && selectedProcess.queryText) {
                const { queryText, databaseName } = selectedProcess;
                dispatch(
                    dynamicExplainQuery({
                        query: queryText,
                        databaseName,
                    })
                );
                router.navigate("cluster.explain", { clusterId });
            }
        }
    };

    handleToggleSelected = (processId: ActiveProcessId, selected: boolean) => {
        const { killedProcessIds, dispatch } = this.props;

        // We don't allow killed processes to be selected; they're "disabled".
        if (!killedProcessIds.some(id => id.eq(processId))) {
            dispatch(changeActiveProcessSelected({ processId, selected }));
        }
    };

    handleKillQuery = () => {
        const { selectedProcessIds, activeProcesses } = this.props;

        const selectedProcesses = _.filter(activeProcesses, process =>
            _.some(selectedProcessIds, id => id.matches(process))
        );
        const queryTexts = selectedProcesses.map(
            process => process.queryText || LONG_EM_DASH
        );

        this.setState({
            killQueryModalState: {
                processCount: selectedProcessIds.length,
                queryTexts,
            },
        });
    };

    handleCancelKillQuery = () => {
        this.setState({
            killQueryModalState: undefined,
        });
    };

    handleConfirmKillQuery = () => {
        const { selectedProcessIds, dispatch } = this.props;

        selectedProcessIds.forEach(id => {
            dispatch(killQuery(id));
        });

        // This does not kill the queries, but just updates the UI to display
        // them as killed (until the next time we refresh).
        dispatch(killedActiveProcessIds({ processIds: selectedProcessIds }));

        this.setState({
            killQueryModalState: undefined,
        });
    };

    renderContent() {
        const {
            activeProcesses,
            activeProcessesEnabled,
            sort,
            error,
            loading,
            selectedProcessIds,
            killedProcessIds,
            route: {
                params: { advanced: advancedFlag },
            },
        } = this.props;

        if (activeProcessesEnabled === false) {
            return (
                <CenteringWrapper>
                    <FeatureCard
                        feature={<CircleIcon name="upgrade" size="large" />}
                        title="Upgrade to MemSQL 6.8.6 or Later"
                        body={
                            <>
                                <div>
                                    This feature requires memSQL 6.8.6 or later.
                                    Upgrade to the latest version to display
                                    long-runnning processes.
                                </div>
                                <div>
                                    <ExtLink
                                        name="upgrade-studio"
                                        category="active-processes"
                                    >
                                        How to upgrade?
                                    </ExtLink>
                                </div>
                            </>
                        }
                    />
                </CenteringWrapper>
            );
        } else if (loading || activeProcessesEnabled === undefined) {
            return <Loading size="large" />;
        } else if (error) {
            return <GeneralError error={error} />;
        } else if (activeProcesses) {
            const allColumns: Array<ActiveProcessesColumn> = [
                makeCheckboxColumn((_ctx, process) => {
                    if (killedProcessIds.some(id => id.matches(process))) {
                        // don't show the checkbox
                        return undefined;
                    }

                    return {
                        checked: selectedProcessIds.some(id =>
                            id.matches(process)
                        ),
                    };
                }, "DISABLED"),
                ...ACTIVE_PROCESSES_COLUMNS,
            ];

            const columns = sort
                ? _.map(allColumns, addSort(sort))
                : allColumns;

            const processesWithState = activeProcesses
                .map(process => ({
                    ...process,
                    selected: selectedProcessIds.some(id =>
                        id.matches(process)
                    ),
                    killed: killedProcessIds.some(id => id.matches(process)),
                }))
                .filter(activeProcess => {
                    // Only show distributed user (system) processes if the
                    // ?advanced flag is on.
                    if (advancedFlag) {
                        return true;
                    } else {
                        return activeProcess.user !== "distributed";
                    }
                });

            return (
                <ActiveProcessesTable
                    activeProcesses={processesWithState}
                    columns={columns}
                    onSort={this.handleSort}
                    onToggle={this.handleToggleSelected}
                />
            );
        } else {
            logError(new Error("Unexpected state in active processes page"));

            return (
                <GeneralError
                    errorHeader="An error occurred while loading the active processes page"
                    error="Unknown error, please contact support."
                />
            );
        }
    }

    handleExportCSV = () => {
        const { dispatch } = this.props;

        dispatch(exportCSV());
    };

    renderHeaderRight() {
        const {
            lastUpdate,
            activeProcesses,
            selectedProcessIds,
            canExplain,
        } = this.props;

        let lastUpdateNode;
        if (lastUpdate) {
            lastUpdateNode = (
                <span className="last-updated">
                    <LastUpdated date={lastUpdate} />
                </span>
            );
        }

        return (
            <>
                {lastUpdateNode}
                <Button
                    large
                    onClick={this.handleKillQuery}
                    disabled={!selectedProcessIds.length}
                    className="kill-button"
                    danger
                >
                    Kill Query
                </Button>
                <Button
                    large
                    onClick={this.handleExplainQuery}
                    disabled={!canExplain}
                    className="explain-button"
                >
                    Explain Query
                </Button>
                <Button
                    large
                    onClick={this.handleExportCSV}
                    disabled={!activeProcesses}
                >
                    Export as CSV
                </Button>
            </>
        );
    }

    renderKillQueryModal() {
        // Note: We render the modal as if we were going to kill all of the
        // processes that were selected when the modal is open. However, if the
        // user actually confirms they want to kill queries in this modal, we
        // only kill the processes that are still selected in the Redux state.
        // This is because in the background, any processes that have died on
        // their own will no longer be selected in the Redux state, so we won't
        // need to kill them. They may also have been replaced with new
        // processes with the same IDs, which we should not kill.
        const { killQueryModalState } = this.state;

        let processCount = 0,
            queryText = "";
        if (killQueryModalState) {
            processCount = killQueryModalState.processCount;
            queryText = killQueryModalState.queryTexts.join("\n");
        }

        let title, description, killText;
        if (processCount === 1) {
            title = `Kill Query`;
            description = "Are you sure you want to kill the following query?";
            killText = "Yes, Kill Query";
        } else {
            title = `Kill ${processCount} Queries`;
            description =
                "Are you sure you want to kill the following queries?";
            killText = "Yes, Kill Queries";
        }

        return (
            <Modal
                active={Boolean(killQueryModalState)}
                inline
                innerContentPadding
                actions={
                    <>
                        <Button large onClick={this.handleCancelKillQuery}>
                            Cancel
                        </Button>

                        <Button
                            large
                            onClick={this.handleConfirmKillQuery}
                            danger
                        >
                            {killText}
                        </Button>
                    </>
                }
            >
                <SimpleModalBody
                    title={title}
                    description={description}
                    className="kill-query-modal-body"
                >
                    <Input
                        className="query-text"
                        input={{
                            name: "query",
                            value: queryText,
                            readOnly: true,
                            rows: 8,
                        }}
                        type="textarea"
                    />
                </SimpleModalBody>
            </Modal>
        );
    }

    render() {
        return (
            <div className="active-processes-page">
                <Header
                    className="active-processes-header"
                    left="Active Processes"
                    right={this.renderHeaderRight()}
                />

                {this.renderContent()}
                {this.renderKillQueryModal()}
            </div>
        );
    }
}

export default compose(
    withRoute,
    connect(
        (s: ReduxState): StateProps => ({
            activeProcesses: selectSortedActiveProcesses(s),
            activeProcessesEnabled: s.activeProcesses.activeProcessesEnabled,
            lastUpdate: selectLastUpdate(s.activeProcesses.activeProcesses),
            sort: selectSort(s),
            error: selectError(s.activeProcesses.activeProcesses),
            loading: selectIsInitial(s.activeProcesses.activeProcesses),
            canExplain: selectCanExplainActiveProcess(s),
            selectedProcessIds: selectSelectedActiveProcessIds(s),
            killedProcessIds: selectKilledActiveProcessIds(s),
            route: selectRoute(s),
        })
    )
)(ActiveProcessesPage);
