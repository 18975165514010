import { Maybe } from "util/maybe";

import {
    Column,
    TableLike,
    DatabaseName,
    Database,
    SchemaEntity,
} from "data/models";

import { InjectedRoute } from "react-router5";

import * as React from "react";
import _ from "lodash";
import { withRoute } from "react-router5";

import SchemaTree from "view/common/schema-tree";
import { Button } from "view/common/button";
import { MenuItem } from "view/common/menu";
import { DragHandle } from "view/components/drag-handle";

import getRoute from "util/get-route";

import "./schema-tree.scss";

type Props = InjectedRoute & {
    onResizeWidth: (width: number) => void;
    onResizeComplete: (movedConsiderableAmount: boolean) => void;
    onExpand: () => void;
    onCollapse: () => void;
    collapsed: boolean;
    canExpand: boolean;
};

class EditorSchemaTreeContainer extends React.Component<Props> {
    $el: Maybe<HTMLDivElement>;

    handleExploreColumn = (column: Column, e: React.MouseEvent<unknown>) => {
        const { router } = this.props;
        const {
            params: { clusterId },
        } = getRoute(this.props.route);

        e.stopPropagation();

        router.navigate("cluster.databases.columns", {
            clusterId,
            databaseName: column.databaseName,
            tableName: column.tableName,
        });
    };

    getColumnLikeActions = (column: Column) => {
        return (
            <MenuItem action={_.partial(this.handleExploreColumn, column)}>
                View Statistics
            </MenuItem>
        );
    };

    handleExploreTable = (table: TableLike, e: React.MouseEvent<unknown>) => {
        const { router } = this.props;
        const {
            params: { clusterId },
        } = getRoute(this.props.route);

        e.stopPropagation();

        router.navigate("cluster.databases.columns", {
            clusterId,
            databaseName: table.databaseName,
            tableName: table.tableName,
        });
    };

    getTableLikeActions = (table: TableLike) => {
        return (
            <MenuItem action={_.partial(this.handleExploreTable, table)}>
                View Statistics
            </MenuItem>
        );
    };

    handleExploreDatabase = (
        databaseName: DatabaseName,
        e: React.MouseEvent<unknown>
    ) => {
        const { router } = this.props;
        const {
            params: { clusterId },
        } = getRoute(this.props.route);

        e.stopPropagation();

        router.navigate("cluster.databases.tables", {
            clusterId,
            databaseName,
        });
    };

    getDatabaseActions = ({ databaseName }: Database) => (
        <MenuItem action={_.partial(this.handleExploreDatabase, databaseName)}>
            View Statistics
        </MenuItem>
    );

    getActions = (schemaEntity: SchemaEntity) => {
        switch (schemaEntity.kind) {
            case "DATABASE":
                return this.getDatabaseActions(schemaEntity);

            case "TABLE":
            case "VIEW":
                return this.getTableLikeActions(schemaEntity);

            case "TABLE_COLUMN":
                return this.getColumnLikeActions(schemaEntity);
        }
    };

    handleResize = ({ clientX }: MouseEvent) => {
        if (this.$el) {
            this.props.onResizeWidth(
                this.$el.getBoundingClientRect().right - clientX
            );
        }
    };

    render() {
        const {
            collapsed,
            canExpand,
            onExpand,
            onCollapse,
            onResizeComplete,
        } = this.props;

        let content;
        if (collapsed) {
            content = (
                <Button
                    ghost
                    small
                    icon="chevron-double-left"
                    className="expand-button"
                    disabled={!canExpand}
                    onClick={onExpand}
                />
            );
        } else {
            content = (
                <SchemaTree
                    topRightExtraContent={
                        <Button
                            ghost
                            small
                            icon="chevron-double-right"
                            className="collapse-button"
                            onClick={onCollapse}
                        />
                    }
                    getActions={this.getActions}
                    onClickShouldExpand={() => true}
                    category="query-editor"
                />
            );
        }

        return (
            <div
                className="schema-tree-container"
                ref={el => {
                    this.$el = el || undefined;
                }}
            >
                <DragHandle
                    direction="w"
                    onResize={this.handleResize}
                    onResizeComplete={onResizeComplete}
                />
                {content}
            </div>
        );
    }
}

export default withRoute(EditorSchemaTreeContainer);
