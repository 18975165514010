import { Maybe } from "util/maybe";

import { State as ReduxState, DispatchFunction } from "data";
import { State as RouteState } from "router5";

import * as React from "react";
import { connect } from "react-redux";

import Header from "view/common/header";
import { Breadcrumbs, Breadcrumb } from "view/common/breadcrumbs";
import { Button } from "view/common/button";
import LastUpdated from "view/components/last-updated";

import { queryTopology } from "worker/api/topology";
import {
    queryHosts,
    hostsLiveMonitoringStart,
    hostsLiveMonitoringStop,
    isPhysicalMonitoringEnabled,
} from "worker/api/hosts";

import { selectRoute } from "data/selectors/routes";
import {
    selectHostsLoading,
    selectHostsLastUpdate,
    selectIsPhysicalMonitoringEnabled,
} from "data/selectors/hosts";
import * as analytics from "util/segment";

import "./header.scss";

type StateProps = {
    lastUpdate: Maybe<Date>;
    route: RouteState;
    physicalMonitoringEnabled: Maybe<boolean>;
    loading: boolean;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

type State = {
    // Whether this component has called `hostsLiveMonitoringStart` or not.
    // (Note that this call may fail and this variable will still be `true`)
    startedLiveMonitoring: boolean;
};

class HostsHeader extends React.Component<Props> {
    state: State = {
        startedLiveMonitoring: false,
    };

    handleReload = () => {
        this.props.dispatch(queryTopology());
        this.props.dispatch(queryHosts());
    };

    componentDidMount() {
        this.props.dispatch(isPhysicalMonitoringEnabled());
        this.handleReload();
    }

    componentDidUpdate() {
        // If Physical Monitoring is enabled in this cluster, we start live
        // monitoring of physical monitoring data. We only do this once per
        // render of HostsHeader.
        if (
            this.props.physicalMonitoringEnabled &&
            !this.state.startedLiveMonitoring
        ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(
                {
                    startedLiveMonitoring: true,
                },
                () => {
                    this.props.dispatch(hostsLiveMonitoringStart());
                }
            );
        }
    }

    componentWillUnmount() {
        if (this.props.physicalMonitoringEnabled) {
            this.props.dispatch(hostsLiveMonitoringStop());
        }
    }

    handleClickReload = () => {
        this.handleReload();

        analytics.trackReload("hosts");
    };

    getBreadcrumbs = () => {
        const { route } = this.props;
        const {
            params: { clusterId, hostId },
        } = route;

        const breadcrumbs = [
            <Breadcrumb
                key="hosts"
                name="Hosts"
                routeInfo={{
                    name: "cluster.hosts",
                    params: { clusterId },
                }}
            />,
        ];

        if (hostId) {
            breadcrumbs.push(
                <Breadcrumb
                    key="host"
                    name={hostId}
                    iconRepr={{ icon: "host" }}
                    routeInfo={{
                        name: "cluster.databases.host",
                        params: { clusterId, hostId },
                    }}
                />
            );
        }

        return breadcrumbs;
    };

    render() {
        const { loading, lastUpdate } = this.props;

        let lastUpdated;
        if (lastUpdate) {
            lastUpdated = (
                <span className="last-updated">
                    <LastUpdated date={lastUpdate} />
                </span>
            );
        }

        const actions = (
            <>
                {lastUpdated}

                <Button
                    disabled={loading}
                    small
                    icon="sync-alt"
                    onClick={this.handleClickReload}
                />
            </>
        );

        return (
            <Header
                className="hosts-header"
                left={<Breadcrumbs>{this.getBreadcrumbs()}</Breadcrumbs>}
                right={actions}
            />
        );
    }
}

export default connect(
    (s: ReduxState): StateProps => ({
        loading: selectHostsLoading(s),
        lastUpdate: selectHostsLastUpdate(s),
        route: selectRoute(s),
        physicalMonitoringEnabled: selectIsPhysicalMonitoringEnabled(s),
    })
)(HostsHeader);
