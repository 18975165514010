// Based on https://github.com/maslianok/react-resize-detector
// Rewritten to be statically typed + actually remove event handler on unmount

import { Maybe } from "util/maybe";
import { CSSProperties } from "react";

import * as React from "react";
import ResizeObserver from "resize-observer-polyfill";

const styles = {
    position: "absolute",
    width: 0,
    height: 0,
    visibility: "hidden",
    display: "none",
} as CSSProperties;

type Props = {
    onResize: (width: number, height: number) => void;
};

export default class ResizeDetector extends React.PureComponent<Props> {
    $el: Maybe<HTMLDivElement>;
    observer: ResizeObserver;

    constructor(props: Props) {
        super(props);
        this.observer = new ResizeObserver(entries => {
            entries.forEach(entry => {
                const { width, height } = entry.contentRect;
                this.props.onResize(width, height);
            });
        });
    }

    componentDidMount() {
        if (!this.$el) {
            throw new Error("Expected element ref to be defined");
        }

        if (!this.$el.parentElement) {
            throw new Error("Expected element to have parent");
        }

        this.observer.observe(this.$el.parentElement);
    }

    componentWillUnmount() {
        if (!this.$el) {
            throw new Error("Expected element ref to be defined");
        }

        if (!this.$el.parentElement) {
            throw new Error("Expected element to have parent");
        }

        this.observer.unobserve(this.$el.parentElement);
    }

    render() {
        return (
            <div
                style={styles}
                ref={el => {
                    this.$el = el || undefined;
                }}
            />
        );
    }
}
