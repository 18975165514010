import { Maybe } from "util/maybe";

import { State as RouteState } from "router5";
import { State, DispatchFunction } from "data";
import { DerivedNode } from "data/models";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { createRouteNodeSelector } from "redux-router5";
import { endsWithSegment } from "router5-helpers";

import GeneralError from "view/common/general-error";
import Loading from "view/components/loading";
import TopologyHeader from "view/topology/header";
import TopologyPage from "view/topology";
import NodePage from "view/topology/node";

import {
    selectDerivedNodesLoading,
    selectDerivedNodesError,
    deriveNodes,
    selectCurrentNodeAddress,
} from "data/selectors/topology";
import { parseNodeAddress } from "data/models";

import "./topology.scss";
import { logError } from "util/logging";

type StateProps = {
    loading: boolean;
    error: Maybe<string>;
    nodes: Maybe<Array<DerivedNode>>;
    currentNodeAddress: Maybe<string>;
    route: RouteState;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

class TopologyController extends React.Component<Props> {
    renderContent() {
        const { route, loading, error, nodes, currentNodeAddress } = this.props;

        if (error) {
            return (
                <GeneralError
                    errorHeader="An error occurred while loading the data:"
                    error={error}
                />
            );
        }

        if (loading) {
            return <Loading size="large" />;
        }

        if (currentNodeAddress) {
            const nodeHostPort = parseNodeAddress(currentNodeAddress);

            if (!nodeHostPort) {
                return (
                    <GeneralError error="Expected URL node param to be in host:port format." />
                );
            }

            // If we don't find any node with host:port, show an error.
            if (
                !_.some(nodes, {
                    host: nodeHostPort.host,
                    port: nodeHostPort.port,
                })
            ) {
                return (
                    <GeneralError
                        error={`Could not find a node on host:port ${currentNodeAddress}.`}
                    />
                );
            }
        }

        const testRoute = endsWithSegment(route);

        let page;
        if (testRoute("nodes")) {
            page = <TopologyPage />;
        } else if (testRoute("node")) {
            page = <NodePage />;
        } else {
            logError(
                new Error(
                    "The topology controller was rendered with an unexpected route."
                )
            );

            return (
                <GeneralError error="Could not find any route that matches this URL." />
            );
        }

        return page;
    }

    render() {
        return (
            <div className="controller-topology">
                <TopologyHeader />
                {this.renderContent()}
            </div>
        );
    }
}

export default connect(
    (s: State): StateProps => {
        const routeNodeSelector = createRouteNodeSelector("cluster.topology");

        return {
            loading: selectDerivedNodesLoading(s),
            error: selectDerivedNodesError(s),
            nodes: deriveNodes(s),
            currentNodeAddress: selectCurrentNodeAddress(s),

            route: routeNodeSelector(s).route,
        };
    }
)(TopologyController);
