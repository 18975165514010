import { GeneralTableColumn } from "view/components/general-table";

import { PartitionInstance, DerivedNode, NodeRatioMetric } from "data/models";

import * as React from "react";
import classNames from "classnames";
import _ from "lodash";

import Icon from "view/components/icon";
import InternalLink from "view/components/internal-link";
import { RatioMetricCell } from "view/common/ratio-metric-cell";
import { renderLoadingError } from "view/components/render-loading-error";

import {
    humanizeNodeRole,
    getNodePair,
    getPartitionInstanceType,
    getPartitionInstancePartitionName,
    getPartitionInstanceName,
    formatNodeAddress,
    getNodeMemoryUsage,
    getNodeDiskUsage,
} from "data/models";
import {
    getNodeStateLevel,
    formatNodePartitionInstanceStatus,
} from "view/common/models/topology";

import { titleize } from "underscore.string";
import NumberFormatter from "util/number-formatter";
import { LONG_EM_DASH } from "util/symbols";

import {
    PARTITION_INSTANCE_ROLE_COLUMN,
    PARTITION_INSTANCE_STATE_COLUMN,
    PARTITION_INSTANCE_LOG_COLUMN,
} from "memsql/schema-column-info";

import "./columns-info.scss";

const formatNodeState = (node: DerivedNode): React.ReactNode => {
    let icon;
    const nodeStateLevel = getNodeStateLevel(node);
    const classes = classNames("node-state-cell", {
        error: nodeStateLevel === "error",
    });

    switch (node.state) {
        case "online":
            icon = "check-circle";
            break;

        case "offline":
            icon = "exclamation-triangle";
            break;

        case "detached":
        case "attaching":
        default:
            icon = "exclamation-circle";
    }

    return (
        <div className={classes}>
            <Icon
                icon={icon}
                size="sm"
                iconType="regular"
                rightMargin
                {...{ [nodeStateLevel]: true }}
            />
            {titleize(node.state)}
        </div>
    );
};

const NODE_STATE_COLUMN: GeneralTableColumn<DerivedNode> = {
    id: "nodeState",
    title: "State",
    formatter: formatNodeState,
    getValue: (node: DerivedNode) => node.state,
};

const NODE_PARTITION_INSTANCE_STATUS: GeneralTableColumn<DerivedNode> = {
    id: "nodePartitionInstanceStatus",
    title: "Partition Instance Status",
    defaultMinWidth: 250,
    formatter: (node: DerivedNode) => formatNodePartitionInstanceStatus(node),
    getValue: (node: DerivedNode) => node.partitionInstanceStatus,
};

const NODE_ROLE_COLUMN: GeneralTableColumn<DerivedNode> = {
    id: "nodeRole",
    title: "Role",
    formatter: (node: DerivedNode) => humanizeNodeRole(node.role),
    getValue: (node: DerivedNode) => {
        switch (node.role) {
            case "MASTER_AGGREGATOR":
                return 2;

            case "AGGREGATOR":
                return 1;

            case "LEAF":
                return 0;

            default:
                return -1;
        }
    },
};

const NODE_AVAILABILITY_GROUP_COLUMN: GeneralTableColumn<DerivedNode> = {
    id: "nodeAvailabilityGroup",
    title: "Availability Group",
    textAlign: "right",
    formatter: (node: DerivedNode) =>
        node.role === "LEAF" ? node.availabilityGroup.toString() : LONG_EM_DASH,
    getValue: (node: DerivedNode) =>
        node.role === "LEAF" ? node.availabilityGroup.toNumber() : undefined, // PLAT-3189
};

const NODE_AVAILABILITY_PAIR_COLUMN: GeneralTableColumn<DerivedNode> = {
    id: "nodePair",
    title: "Availability Pair",
    formatter: (node: DerivedNode) => {
        const nodePair = getNodePair(node);
        if (nodePair) {
            return (
                <InternalLink
                    routeInfo={{
                        name: "cluster.nodes.node",
                        params: {
                            nodeAddress: nodePair,
                        },
                    }}
                    category="schema-explorer"
                    clusterLink
                    onClick={
                        /* stop propagation to the cell click event */ e =>
                            e.stopPropagation()
                    }
                >
                    {nodePair}
                </InternalLink>
            );
        } else {
            return LONG_EM_DASH;
        }
    },
    getValue: (node: DerivedNode) => getNodePair(node),
};

const NODE_CONNECTIONS_COLUMN: GeneralTableColumn<DerivedNode> = {
    id: "nodeConnections",
    title: "Open Connections",
    textAlign: "right",
    formatter: (node: DerivedNode) => node.openedConnections.toString(),
    // PLAT-3189
    getValue: (node: DerivedNode) => node.openedConnections.toNumber(),
};

const NODE_LATENCY_COLUMN: GeneralTableColumn<DerivedNode> = {
    id: "nodeLatency",
    title: "Average Latency",
    textAlign: "right",
    formatter: (node: DerivedNode) =>
        node.averageLatency
            ? NumberFormatter.formatDuration(node.averageLatency)
            : "—",
    getValue: (node: DerivedNode) => node.averageLatency,
};

const NODE_ADDRESS_COLUMN: GeneralTableColumn<DerivedNode> = {
    id: "nodeHostPort",
    title: "Node Address",
    formatter: (node: DerivedNode) => (
        <InternalLink
            routeInfo={{
                name: "cluster.nodes.node",
                params: {
                    nodeAddress: formatNodeAddress(node),
                },
            }}
            category="schema-explorer"
            clusterLink
            className="node-address-cell"
            onClick={
                /* stop propagation to the cell click event */ e =>
                    e.stopPropagation()
            }
        >
            {formatNodeAddress(node)}
        </InternalLink>
    ),
    // PLAT-3189
    getValue: (node: DerivedNode) => node.port.toNumber(),
};

export const NODE_CPU_USAGE_TIP =
    "Node CPU usage displays the percentage of available CPU being used by the MemSQL process on this node and is limited by cgroup configuration.";

export const NODE_CPU_USAGE_COLUMN: GeneralTableColumn<DerivedNode> = {
    id: "nodeTotalCpuUsage",
    title: "MemSQL CPU Usage",
    sort: "DISABLED",
    description: NODE_CPU_USAGE_TIP,
    formatter: (node: DerivedNode) => {
        const renderNodeCpuUsage = (cpuUsage: number) => (
            <RatioMetricCell labelPosition="top" value={cpuUsage} />
        );

        return renderLoadingError(
            node.liveMonitoring.totalCpuUsagePercent,
            renderNodeCpuUsage,
            { cell: true }
        );
    },
    getValue: (node: DerivedNode) => {
        // If loading, return undefined. If error, return null.
        if (node.liveMonitoring.totalCpuUsagePercent.isSuccess()) {
            return node.liveMonitoring.totalCpuUsagePercent.value;
        } else if (node.liveMonitoring.totalCpuUsagePercent.isLoading()) {
            return undefined;
        } else {
            return null;
        }
    },
};

export const NODE_MEMORY_USAGE_TIP =
    "Node memory usage displays the percentage of available memory being used by the MemSQL process and is limited by cgroup configuration.";

export const NODE_MEMORY_USAGE_COLUMN: GeneralTableColumn<DerivedNode> = {
    id: "nodeMemoryUsage",
    title: "MemSQL Memory Usage",
    sort: "DISABLED",
    description: NODE_MEMORY_USAGE_TIP,
    formatter: (node: DerivedNode) => {
        const renderNodeMemoryUsage = ({
            ratio,
            used,
            total,
        }: NodeRatioMetric) => {
            const bottomLabel = `${NumberFormatter.formatBytes(
                used
            )}/${NumberFormatter.formatBytes(total)}`;

            return (
                <RatioMetricCell
                    labelPosition="top"
                    value={ratio}
                    bottomLabel={bottomLabel}
                />
            );
        };

        return renderLoadingError(
            getNodeMemoryUsage(node),
            renderNodeMemoryUsage,
            { cell: true }
        );
    },
    getValue: (node: DerivedNode) => {
        const nodeMemoryUsage = getNodeMemoryUsage(node);

        // If loading, return undefined. If error, return null.
        if (nodeMemoryUsage.isSuccess()) {
            return nodeMemoryUsage.value.ratio;
        } else if (nodeMemoryUsage.isLoading()) {
            return undefined;
        } else {
            return null;
        }
    },
};

export const NODE_DISK_USAGE_TIP =
    "Node disk usage displays the used and available disk space for each mount point being used by this MemSQL node.";

export const NODE_DISK_USAGE_COLUMN: GeneralTableColumn<DerivedNode> = {
    id: "nodeDiskUsage",
    title: "MemSQL Disk Usage",
    sort: "DISABLED",
    description: NODE_DISK_USAGE_TIP,
    formatter: (node: DerivedNode) => {
        const renderNodeDiskUsage = ({
            ratio,
            used,
            total,
        }: NodeRatioMetric) => {
            const bottomLabel = `${NumberFormatter.formatBytes(
                used
            )}/${NumberFormatter.formatBytes(total)}`;

            return (
                <RatioMetricCell
                    labelPosition="top"
                    value={ratio}
                    bottomLabel={bottomLabel}
                />
            );
        };

        return renderLoadingError(getNodeDiskUsage(node), renderNodeDiskUsage, {
            cell: true,
        });
    },
    getValue: (node: DerivedNode) => {
        const nodeDiskUsage = getNodeDiskUsage(node);

        // If loading, return undefined. If error, return null.
        if (nodeDiskUsage.isSuccess()) {
            return nodeDiskUsage.value.ratio;
        } else if (nodeDiskUsage.isLoading()) {
            return undefined;
        } else {
            return null;
        }
    },
};

export function getNodesPageColumns(physicalMonitoringEnabled: boolean) {
    if (physicalMonitoringEnabled) {
        return [
            NODE_ADDRESS_COLUMN,
            NODE_STATE_COLUMN,
            NODE_PARTITION_INSTANCE_STATUS,
            NODE_ROLE_COLUMN,
            NODE_CPU_USAGE_COLUMN,
            NODE_MEMORY_USAGE_COLUMN,
            NODE_DISK_USAGE_COLUMN,
        ];
    } else {
        return [NODE_ADDRESS_COLUMN, NODE_STATE_COLUMN, NODE_ROLE_COLUMN];
    }
}

export const ALL_NODES_PAGE_COLUMNS = [
    NODE_ADDRESS_COLUMN,
    NODE_STATE_COLUMN,
    NODE_PARTITION_INSTANCE_STATUS,
    NODE_ROLE_COLUMN,
    NODE_CPU_USAGE_COLUMN,
    NODE_MEMORY_USAGE_COLUMN,
    NODE_DISK_USAGE_COLUMN,
    NODE_ADDRESS_COLUMN,
    NODE_STATE_COLUMN,
    NODE_ROLE_COLUMN,
];

export function getHostPageColumns(physicalMonitoringEnabled: boolean) {
    if (physicalMonitoringEnabled) {
        return [
            NODE_ADDRESS_COLUMN,
            NODE_STATE_COLUMN,
            NODE_ROLE_COLUMN,
            NODE_CPU_USAGE_COLUMN,
            NODE_MEMORY_USAGE_COLUMN,
            NODE_DISK_USAGE_COLUMN,
        ];
    } else {
        return [
            NODE_ADDRESS_COLUMN,
            NODE_STATE_COLUMN,
            NODE_ROLE_COLUMN,
            NODE_AVAILABILITY_GROUP_COLUMN,
            NODE_AVAILABILITY_PAIR_COLUMN,
            NODE_CONNECTIONS_COLUMN,
            NODE_LATENCY_COLUMN,
        ];
    }
}

export const ALL_HOST_PAGE_COLUMNS: Array<GeneralTableColumn<DerivedNode>> = [
    NODE_ADDRESS_COLUMN,
    NODE_STATE_COLUMN,
    NODE_ROLE_COLUMN,
    NODE_AVAILABILITY_GROUP_COLUMN,
    NODE_AVAILABILITY_PAIR_COLUMN,
    NODE_CONNECTIONS_COLUMN,
    NODE_LATENCY_COLUMN,
    NODE_CPU_USAGE_COLUMN,
    NODE_MEMORY_USAGE_COLUMN,
    NODE_DISK_USAGE_COLUMN,
];

export const NODE_PARTITION_INSTANCE_COLUMNS: Array<
    GeneralTableColumn<PartitionInstance>
> = [
    {
        id: "partitionInstancePartitionType",
        title: "Type",
        formatter: getPartitionInstanceType,
        getValue: getPartitionInstanceType,
        defaultMinWidth: 220,
    },
    PARTITION_INSTANCE_STATE_COLUMN,
    {
        id: "partitionInstancePartitionId",
        title: "Partition",
        formatter: (pi: PartitionInstance) => (
            <InternalLink
                routeInfo={{
                    name: "cluster.databases.partition",
                    params: {
                        databaseName: pi.databaseName,
                        partitionName: getPartitionInstanceName(pi),
                    },
                }}
                category="schema-explorer"
                clusterLink
                onClick={
                    /* stop propagation to the cell click event */ e =>
                        e.stopPropagation()
                }
            >
                {getPartitionInstancePartitionName(pi)}
            </InternalLink>
        ),
        getValue: (pi: PartitionInstance) =>
            getPartitionInstancePartitionName(pi),
    },
    PARTITION_INSTANCE_ROLE_COLUMN,
    PARTITION_INSTANCE_LOG_COLUMN,
];
