import { Maybe } from "util/maybe";

import jwtDecode from "jwt-decode";

export type ClusterProfile = "PRODUCTION" | "STAGING" | "DEVELOPMENT";

export type ClusterId = string;

export type Cluster = {
    id: ClusterId;
    name: string;
    description: string;
    profile: Maybe<ClusterProfile>;
    hostname: string;
    port: number;

    // Direct connection clusters have a `defaultUser` property that can be used
    // to prefill the username field in the Connect to Cluster form.
    defaultUser?: string;
};

const STAGING_PROFILE = "s";
const DEVELOPMENT_PROFILE = "d";

type ClusterJwt = Partial<{
    exp: string;
    end: string;
    nam: string;
    env: string;
    usr: string;
}>;

export function decodeClusterJwt(jwt: string): Maybe<Cluster> {
    // jwtDecode can raise an error if the argument is not a valid string, and
    // we return undefined if so.
    let decodedJwt: ClusterJwt;
    try {
        decodedJwt = jwtDecode(jwt);
    } catch (err) {
        return undefined;
    }

    let profile: ClusterProfile;
    switch (decodedJwt.env) {
        case STAGING_PROFILE:
            profile = "STAGING";
            break;

        case DEVELOPMENT_PROFILE:
            profile = "DEVELOPMENT";
            break;

        default:
            profile = "PRODUCTION";
    }

    if (!decodedJwt.end) {
        return undefined;
    }

    const splitEndpoint = decodedJwt.end.split(":");
    const name = decodedJwt.nam || splitEndpoint[0];
    const port = Number(splitEndpoint[1]) || 3306;
    const defaultUser = decodedJwt.usr || "";

    return {
        id: jwt,
        name,
        description: "",
        profile,
        hostname: splitEndpoint[0],
        port,
        defaultUser,
    };
}

export function formatClusterProfile(profile: Maybe<ClusterProfile>) {
    switch (profile) {
        case "DEVELOPMENT":
            return "Development";

        case "STAGING":
            return "Staging";

        case "PRODUCTION":
            return "Production";
    }
}
