import * as React from "react";

import { NodeRole } from "data/models/topology";

import classnames from "classnames";

import "./node-badge.scss";

export type BadgeProps = {
    count: number;
    role: NodeRole;
    classname?: string;
};

const NODE_BADGE_ABBREVIATED: { [role in NodeRole]: string } = {
    LEAF: "LEAF",
    AGGREGATOR: "CA",
    MASTER_AGGREGATOR: "MA",
};

const NODE_BADGE_CLASS: { [role in NodeRole]: string } = {
    LEAF: "leaf",
    AGGREGATOR: "aggregator",
    MASTER_AGGREGATOR: "master",
};

export default class NodeBadge extends React.Component<BadgeProps> {
    render() {
        const { count, role, classname } = this.props;
        const classes = classnames(
            "node-badge",
            NODE_BADGE_CLASS[role],
            classname
        );

        return (
            <div className={classes}>
                {count} {NODE_BADGE_ABBREVIATED[role]}
            </div>
        );
    }
}
