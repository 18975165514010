import { Maybe } from "util/maybe";

import { State as ReduxState, DispatchFunction } from "data";
import { State as RouteState } from "router5";

import * as React from "react";
import { connect } from "react-redux";

import Header from "view/common/header";
import { Breadcrumbs, Breadcrumb } from "view/common/breadcrumbs";
import { Button } from "view/common/button";
import LastUpdated from "view/components/last-updated";

import {
    queryTopology,
    nodesLiveMonitoringStart,
    nodesLiveMonitoringStop,
} from "worker/api/topology";
import { queryPartitionData } from "worker/api/cluster-metadata";
import { isPhysicalMonitoringEnabled } from "worker/api/hosts";
import { selectRoute } from "data/selectors/routes";
import {
    selectDerivedNodesLoading,
    selectDerivedNodesLastUpdate,
} from "data/selectors/topology";
import { selectIsPhysicalMonitoringEnabled } from "data/selectors";
import * as analytics from "util/segment";

import "./header.scss";

type StateProps = {
    lastUpdate: Maybe<Date>;
    route: RouteState;
    loading: boolean;
    physicalMonitoringEnabled: Maybe<boolean>;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

type State = {
    // Whether this component has called `nodesLiveMonitoringStart` or not.
    // (Note that this call may fail and this variable will still be `true`)
    startedLiveMonitoring: boolean;
};

class TopologyHeader extends React.Component<Props, State> {
    state: State = {
        startedLiveMonitoring: false,
    };

    handleReload = () => {
        this.props.dispatch(queryTopology());
        this.props.dispatch(queryPartitionData());
    };

    componentDidMount() {
        this.props.dispatch(isPhysicalMonitoringEnabled());
        this.handleReload();
    }

    componentDidUpdate() {
        // If Physical Monitoring is enabled in this cluster, we start live
        // monitoring of physical monitoring data. We only do this once per
        // render of HostsHeader.
        if (
            this.props.physicalMonitoringEnabled &&
            !this.state.startedLiveMonitoring
        ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(
                {
                    startedLiveMonitoring: true,
                },
                () => {
                    this.props.dispatch(nodesLiveMonitoringStart());
                }
            );
        }
    }

    componentWillUnmount() {
        if (this.props.physicalMonitoringEnabled) {
            this.props.dispatch(nodesLiveMonitoringStop());
        }
    }

    handleClickReload = () => {
        this.handleReload();

        analytics.trackReload("topology");
    };

    getBreadcrumbs = () => {
        const { route } = this.props;
        const {
            params: { clusterId, nodeAddress },
        } = route;

        const breadcrumbs = [
            <Breadcrumb
                key="nodes"
                name="Nodes"
                routeInfo={{
                    name: "cluster.nodes",
                    params: { clusterId },
                }}
            />,
        ];

        if (nodeAddress) {
            breadcrumbs.push(
                <Breadcrumb
                    key="host"
                    name={nodeAddress}
                    iconRepr={{ icon: "nodes" }}
                    routeInfo={{
                        name: "cluster.nodes.node",
                        params: { clusterId, nodeAddress },
                    }}
                />
            );
        }

        return breadcrumbs;
    };

    render() {
        const { loading, lastUpdate } = this.props;

        let lastUpdated;
        if (lastUpdate) {
            lastUpdated = (
                <span className="last-updated">
                    <LastUpdated date={lastUpdate} />
                </span>
            );
        }

        const actions = (
            <>
                {lastUpdated}

                <Button
                    disabled={loading}
                    small
                    icon="sync-alt"
                    onClick={this.handleClickReload}
                />
            </>
        );

        return (
            <Header
                className="topology-header"
                left={<Breadcrumbs>{this.getBreadcrumbs()}</Breadcrumbs>}
                right={actions}
            />
        );
    }
}

export default connect(
    (s: ReduxState): StateProps => ({
        loading: selectDerivedNodesLoading(s),
        lastUpdate: selectDerivedNodesLastUpdate(s),
        route: selectRoute(s),
        physicalMonitoringEnabled: selectIsPhysicalMonitoringEnabled(s),
    })
)(TopologyHeader);
