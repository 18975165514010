// This is a separate file from active-processes.tsx because the worker imports
// that file and this file uses browser-only APIs.
import { DispatchFunction, GetStateFunction } from "data";

import { ACTIVE_PROCESSES_COLUMNS } from "view/active-processes/columns-info";

import { selectSortedActiveProcesses } from "data/selectors/active-processes";

import saveData from "util/save-data";
import stringifyCsv from "csv-stringify/lib/es5/sync";
import * as analytics from "util/segment";

export const exportCSV = () => {
    return (dispatch: DispatchFunction, getState: GetStateFunction) => {
        const state = getState();
        const results = selectSortedActiveProcesses(state);

        if (results) {
            const csv = stringifyCsv([
                ACTIVE_PROCESSES_COLUMNS.map(column => column.title),
                ...results.map(result =>
                    ACTIVE_PROCESSES_COLUMNS.map(column =>
                        column.getValue(result)
                    )
                ),
            ]);

            saveData({
                data: csv,
                baseName: "active-processes",
                extension: "csv",
            });

            analytics.track("export-active-processes-csv");
        } else {
            throw new Error("Expected sorted active processes to exist.");
        }
    };
};
