import assign from "util/assign";

import {
    ChangeBottomPanelHeightAction,
    ChangeBottomPanelTabAction,
} from "data/actions/bottom-panel";

import { LogMessageAction } from "data/actions/log";

export type TabTitle = "Console" | "Connection" | "Log";

type Actions =
    | ChangeBottomPanelTabAction
    | ChangeBottomPanelHeightAction
    | LogMessageAction;

// Percentage of total document height value:
export const DEFAULT_HEIGHT = 0.3;

export type BottomPanelState = {
    height: number; // percentage of the screen's height that is currently open from 0 to 1
    activeTab: TabTitle;
    everOpened: boolean;
};

const initialState: BottomPanelState = {
    height: 0,
    activeTab: "Console",

    // We keep track of whether the bottom panel has ever opened so that we
    // highlight the active tab only after the user has opened the panel at
    // least once.
    everOpened: false,
};

const openToDefaultHeightIfClosed = (
    state: BottomPanelState
): BottomPanelState =>
    assign(state, {
        height: state.height === 0 ? DEFAULT_HEIGHT : state.height,
        everOpened: true,
    });

export default (state: BottomPanelState = initialState, action: Actions) => {
    switch (action.type) {
        case "CHANGE_BOTTOM_PANEL_HEIGHT": {
            state = assign(state, {
                height: action.payload.height,
                everOpened: state.everOpened || action.payload.height > 0,
            });

            break;
        }

        case "CHANGE_BOTTOM_PANEL_TAB": {
            state = openToDefaultHeightIfClosed(
                assign(state, {
                    activeTab: action.payload.tab,
                })
            );

            break;
        }

        case "LOG_MESSAGE": {
            // If a message that demands user focus is dispatched, open the Tab
            // to the one that needs focusing, and set the height of the bottom
            // panel to the default height if it is not open.
            if (action.payload.focusTab) {
                state = openToDefaultHeightIfClosed({
                    ...state,
                    activeTab: action.payload.focusTab,
                });
            }

            break;
        }
    }

    return state;
};
