import { AbsoluteTableName, DatabaseName } from "data/models";

import { SimpleAction } from "data/actions/types";

type DatabaseNamePayload = {
    databaseName: DatabaseName;
};

export type ExpandDatabaseAction = SimpleAction<
    "SCHEMA_TREE_EXPAND_DATABASE",
    DatabaseNamePayload
>;

export const expandDatabase = (
    payload: DatabaseNamePayload
): ExpandDatabaseAction => ({
    type: "SCHEMA_TREE_EXPAND_DATABASE",
    error: false,
    payload,
});

export type CollapseDatabaseAction = SimpleAction<
    "SCHEMA_TREE_COLLAPSE_DATABASE",
    DatabaseNamePayload
>;

export const collapseDatabase = (
    payload: DatabaseNamePayload
): CollapseDatabaseAction => ({
    type: "SCHEMA_TREE_COLLAPSE_DATABASE",
    error: false,
    payload,
});

export type ExpandTableAction = SimpleAction<
    "SCHEMA_TREE_EXPAND_TABLE",
    AbsoluteTableName
>;

export const expandTable = (payload: AbsoluteTableName): ExpandTableAction => ({
    type: "SCHEMA_TREE_EXPAND_TABLE",
    error: false,
    payload,
});

export type CollapseTableAction = SimpleAction<
    "SCHEMA_TREE_COLLAPSE_TABLE",
    AbsoluteTableName
>;

export const collapseTable = (
    payload: AbsoluteTableName
): CollapseTableAction => ({
    type: "SCHEMA_TREE_COLLAPSE_TABLE",
    error: false,
    payload,
});

export type SchemaTreeChangeSearchAction = SimpleAction<
    "SCHEMA_TREE_CHANGE_SEARCH",
    string
>;

export const schemaTreeChangeSearch = (
    payload: string
): SchemaTreeChangeSearchAction => ({
    type: "SCHEMA_TREE_CHANGE_SEARCH",
    error: false,
    payload,
});
