import * as React from "react";
import classnames from "classnames";

import "./centering-wrapper.scss";

type Props = {
    className?: string;
    children: React.ReactNode;
};

// Wraps an element such as a FeatureCard on pages that display only one or a few prominently centered components as
// content.
export default class CenteringWrapper extends React.Component<Props> {
    render() {
        const { className, children } = this.props;

        const classes = classnames("components-centering-wrapper", className);

        return <div className={classes}>{children}</div>;
    }
}
