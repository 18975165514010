import { Nullable } from "util/nullable";
import { State as RouteState } from "router5";

export default function(route: Nullable<RouteState>) {
    if (route) {
        return route;
    }

    throw new Error("Expected route to exist.");
}
