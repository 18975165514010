import { Maybe } from "util/maybe";

import { State, DispatchFunction } from "data";
import { InjectedRoute } from "react-router5";

import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import classnames from "classnames";
import { withRoute } from "react-router5";
import { endsWithSegment } from "router5-helpers";
import getRoute from "util/get-route";

import MVRecordButton from "view/monitor/mv-record-button";
import MVLoadSaveData from "view/monitor/mv-load-save-data";
import Tip from "view/components/tip";
import LastUpdated from "view/components/last-updated";
import Icon from "view/components/icon";
import Header from "view/common/header";

import "./mv-header.scss";

export type TabProps<TabId> = {
    id: TabId;
    name: React.ReactNode;
    active: boolean;
    onClick?: (id: TabId) => void;
};

function MvHeaderTab<TabId>({ id, name, active, onClick }: TabProps<TabId>) {
    return (
        <div
            key={String(id)}
            onClick={onClick ? () => onClick(id) : undefined}
            role="button"
            className={classnames("header-tab", { active })}
        >
            {name}
        </div>
    );
}

type FilterId = string;

export type FilterProps = {
    id: FilterId;
    description: string;
    className?: string;
    onDisable: (id: FilterId) => void;
};

const MvHeaderFilter = ({
    id,
    description,
    className,
    onDisable,
}: FilterProps) => (
    <div key={id} className="header-filter">
        <div className="inner">
            <Tip
                className={classnames("description", className)}
                tooltip={description}
                direction="se"
            >
                {description}
            </Tip>
            <Icon
                onClick={() => onDisable(id)}
                className="disable-btn"
                icon="times"
            />
        </div>
    </div>
);

type StateProps = {
    lastUpdate: Maybe<Date>;
};

type Props<TabId> = StateProps &
    InjectedRoute & {
        tabs?: Array<TabProps<TabId>>;
        filters: Array<FilterProps>;
        dispatch: DispatchFunction;
    };

class MvHeader<TabId extends Maybe<string>> extends React.Component<
    Props<TabId>
> {
    render() {
        const { tabs, filters, lastUpdate, router } = this.props;
        const route = getRoute(this.props.route);

        const renderedTabs = _.map(tabs, MvHeaderTab);
        const renderedFilters = _.map(filters, MvHeaderFilter);

        let lastUpdatedNode;
        if (lastUpdate) {
            lastUpdatedNode = (
                <span className="last-updated">
                    <LastUpdated date={lastUpdate} />
                </span>
            );
        }

        const testRoute = endsWithSegment(route);
        const queriesPageActive = testRoute("activities");
        const nodesPageActive = testRoute("nodes");

        return (
            <div className="monitor-mv-header-wrapper">
                <div className="filter-bar">{renderedFilters}</div>
                <Header
                    className="monitor-mv-header"
                    left="Resource Usage"
                    right={
                        <div className="actions">
                            {lastUpdatedNode}

                            <MVRecordButton small />
                            <MVLoadSaveData />
                        </div>
                    }
                />

                <div className="monitor-mv-tabs">
                    <MvHeaderTab
                        id="queries"
                        name="Queries"
                        active={queriesPageActive}
                        onClick={() => {
                            router.navigate("cluster.activities", {
                                ...route.params,
                            });
                        }}
                    />

                    {renderedTabs}

                    <MvHeaderTab
                        id="nodes"
                        name="Nodes"
                        active={nodesPageActive}
                        onClick={() => {
                            router.navigate("cluster.activities.nodes", {
                                ...route.params,
                            });
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        lastUpdate: s.managementViews.lastUpdate,
    })
)(withRoute(MvHeader));
