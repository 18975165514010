
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import TutorialLoadSQLButton from "view/tutorial/tutorial-load-sql-button";
import { SQL_TPCH_CREATE_DATABASE } from "./tpch-queries";

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h4" components={components}>{`Step 1: Create the Database`}</MDXTag>
<MDXTag name="p" components={components}>{`The following queries will create eight tables associated with business data.`}</MDXTag>
<TutorialLoadSQLButton id="tpch-create-database" query={SQL_TPCH_CREATE_DATABASE} text="Paste Create queries" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  