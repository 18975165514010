
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import TutorialLoadSQLButton from "view/tutorial/tutorial-load-sql-button";
import {
    SQL_CALL_SEED_TRADES_PROCEDURE,
    SQL_CALL_ITER_STOCK_PROCEDURE,
} from "./stock-queries";

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h4" components={components}>{`Step 4: Generate trade data`}</MDXTag>
<MDXTag name="p" components={components}>{`Now that you have created the functionality to generate stock ticker data, run the following commands to generate the data.`}</MDXTag>
<TutorialLoadSQLButton id="stock-seed-procedure-call" query={SQL_CALL_SEED_TRADES_PROCEDURE} text="Paste seed trades procedure call" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
<MDXTag name="p" components={components}>{`Note, the following procedure call may take `}<MDXTag name="strong" components={components} parentName="p">{`10 minutes`}</MDXTag>{` depending on your cluster topology.`}</MDXTag>
<TutorialLoadSQLButton id="stock-iter-procedure-call" query={SQL_CALL_ITER_STOCK_PROCEDURE} text="Paste iter_stocks procedure call" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  