import { Maybe } from "util/maybe";
import { Level } from "view/util/level";
import { LoadingError } from "util/loading-error";
import { DerivedRowsThroughputPerSecond } from "data/selectors/cluster-metadata";

import { State } from "data";
import { ResourceUsageSummary } from "data/models";
import { State as RouteState } from "router5";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { DashboardCard, MetricsBlock, Block } from "view/common/dashboard-card";
import { renderLoadingError } from "view/components/render-loading-error";

import { selectPayload } from "util/loading-state-machine";
import {
    selectSchemaSummaryLoading,
    selectSchemaSummaryError,
} from "data/selectors/schema";
import { selectRoute } from "data/selectors/routes";
import {
    derivedRowsThroughputPerSecond,
    selectIsRowsThroughputEnabled,
} from "data/selectors/cluster-metadata";

import NumberFormatter from "util/number-formatter";

import "./cluster-usage-card.scss";

type StateProps = {
    loading: boolean;
    rowsThroughputEnabled: Maybe<boolean>;
    rowsThroughputPerSecond: LoadingError<DerivedRowsThroughputPerSecond>;
    schemaSummary: Maybe<ResourceUsageSummary>;
    schemaError: Maybe<string>;
    route: RouteState;
};

type ClusterUsageCardProps = StateProps & {
    className?: string;
};

class ClusterUsageCard extends React.Component<ClusterUsageCardProps> {
    renderContent = (): Maybe<React.ReactNode> => {
        const {
            schemaSummary,
            rowsThroughputPerSecond,
            rowsThroughputEnabled,
        } = this.props;

        if (!schemaSummary) {
            throw new Error("Expected schemaSummary to be defined.");
        }

        let metricBlocks: Array<{
            title: string;
            label: React.ReactNode;
            status: Level;
        }> = [
            {
                title: "Total Memory Usage",
                label: NumberFormatter.formatBytes(schemaSummary.memoryUsage),
                status: "success",
            },
            {
                title: "Total Disk Usage",
                label: NumberFormatter.formatBytes(schemaSummary.diskUsage),
                status: "success",
            },
        ];

        if (rowsThroughputEnabled) {
            metricBlocks.push({
                title: "Rows Read",
                label: (
                    <div className="throughput-metric-centered">
                        {renderLoadingError(
                            rowsThroughputPerSecond,
                            ({ derivedRowsReadPerSecond }) =>
                                NumberFormatter.formatRateS(
                                    derivedRowsReadPerSecond
                                ),
                            { cell: true }
                        )}
                    </div>
                ),
                status: "success",
            });
            metricBlocks.push({
                title: "Rows Written",
                label: (
                    <div className="throughput-metric-centered">
                        {renderLoadingError(
                            rowsThroughputPerSecond,
                            ({ derivedRowsWritePerSecond }) =>
                                NumberFormatter.formatRateS(
                                    derivedRowsWritePerSecond
                                ),
                            { cell: true }
                        )}
                    </div>
                ),
                status: "success",
            });
        }

        const metricBlocksContent = metricBlocks.map(block => (
            <Block
                key={block.title}
                title={block.title}
                label={block.label}
                status={block.status}
            />
        ));

        return (
            <div className="cluster-usage-card-content">
                <MetricsBlock>{metricBlocksContent}</MetricsBlock>
            </div>
        );
    };

    render() {
        const {
            loading,
            schemaError,
            schemaSummary,
            route: {
                params: { clusterId },
            },
            className,
        } = this.props;

        let content;

        // If there is at least 1 byte of either disk or memory usage
        // in the cluster, then we render the card's normal content.
        if (!schemaError && !loading && schemaSummary) {
            content = this.renderContent();
        }

        return (
            <DashboardCard
                loading={loading}
                content={content}
                pageRouteInfo={{
                    name: "cluster.databases",
                    params: { clusterId },
                }}
                hasEmptyState={false}
                error={schemaError}
                title="Cluster Usage"
                docsRouteInfo={{
                    name: "memory-management",
                    category: "dashboard",
                }}
                className={className}
            />
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        rowsThroughputEnabled: selectIsRowsThroughputEnabled(s),
        rowsThroughputPerSecond: derivedRowsThroughputPerSecond(s),
        loading: selectSchemaSummaryLoading(s),
        schemaSummary: selectPayload(s.schema.summary),
        schemaError: selectSchemaSummaryError(s),
        route: selectRoute(s),
    })
)(ClusterUsageCard);
