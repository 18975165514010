import { TableSort } from "util/sort";
import { Action, SimpleAction } from "./types";
import {
    MvNode,
    MvNodeId,
    MvActivityLowLevel,
    MvActivityLowLevelCumulative,
    MvActivityID,
} from "data/models";
import { MvFilters } from "data/reducers/management-views";
import { MvColumnKey } from "memsql/mv-column-info";

import { Loading } from "util/loading-state-machine";

export type MvChangeFiltersAction = SimpleAction<
    "MV_CHANGE_FILTERS",
    MvFilters
>;

export const mvChangeFilters = (payload: MvFilters): MvChangeFiltersAction => ({
    type: "MV_CHANGE_FILTERS",
    error: false,
    payload,
});

export type MvSortQueriesPayload = {
    sort: TableSort;
    isUserCaused: boolean;
};

export type MvSortQueriesAction = SimpleAction<
    "MV_SORT_QUERIES",
    MvSortQueriesPayload
>;

export const mvSortQueries = (
    payload: MvSortQueriesPayload
): MvSortQueriesAction => ({
    type: "MV_SORT_QUERIES",
    error: false,
    payload,
});

export type MvSortNodesPayload = {
    sort: TableSort;
    isUserCaused: boolean;
};

export type MvSortNodesAction = SimpleAction<
    "MV_SORT_NODES",
    MvSortNodesPayload
>;

export const mvSortNodes = (
    payload: MvSortNodesPayload
): MvSortNodesAction => ({
    type: "MV_SORT_NODES",
    error: false,
    payload,
});

type MvChangeStatsColumnsPayload = {
    column: MvColumnKey;
    op: "ADD" | "REMOVE";
};

export type MvChangeStatsColumns = SimpleAction<
    "MV_CHANGE_STATS_COLUMNS",
    MvChangeStatsColumnsPayload
>;

export const mvChangeStatsColumns = (
    payload: MvChangeStatsColumnsPayload
): MvChangeStatsColumns => ({
    type: "MV_CHANGE_STATS_COLUMNS",
    error: false,
    payload,
});

// The way MV Recording actions work is the following:
//
// When users click on the Finish button, the MvFullStartAction is
// dispatched by the worker with payload = { loading: true }.
// After the initial snapshot is recorded, the worker dispatches
// the MvFullStartAction again but with the snapshot as its payload.
// From this moment on, the `startSnapshot` object is present in the
// Redux state and the user can click on the Finish button to stop
// the recording. When the user does this, the MvFullAction is
// dispatched by the worker with payload = { loading: true }. After
// the second snapshot is recorded, the worker dispatches the
// MvFullAction again but with the final activities (ready for the UI)
// as its payload.
//
// If inbetween the two actions, the user clicks on the Cancel button,
// then the MvCancelRecordingAction is dispatched on the client which
// cancels the ongoing recording.

export type MvFullStartAction = Action<
    "MV_FULL_START",
    Loading<
        {
            activities: { [id in MvActivityID]: MvActivityLowLevelCumulative };
            deltaTimeS: number;
        },
        | {
              fixedInterval: true;
              deltaTimeS: number;
          }
        | {
              fixedInterval: false;
          }
    >,
    { message: string }
>;

export type MvCancelRecordingAction = SimpleAction<"MV_CANCEL_RECORDING", {}>;

export const cancelMvRecording = (): MvCancelRecordingAction => ({
    type: "MV_CANCEL_RECORDING",
    error: false,
    payload: {},
});

export type MvFullRepr = {
    nodes: { [id in MvNodeId]: MvNode };
    activities: Array<MvActivityLowLevel>;
    advancedCounters: boolean;
};

export type MvFullPayload = {
    repr: MvFullRepr;
    deltaTimeS?: number;
};

export type MvFullAction = Action<
    "MV_FULL",
    Loading<MvFullPayload>,
    { message: string }
>;

export const mvFull = (payload: MvFullPayload): MvFullAction => ({
    type: "MV_FULL",
    error: false,
    payload: {
        loading: false,
        data: payload,
    },
});
