import { Maybe } from "util/maybe";
import { MysqlError } from "mysqljs";
import { Observer } from "rxjs";
import { HandlerContext } from "worker/api";
import { QueryGroupRepr, QueryExecutorMsg } from "worker/net/query-executor";
import {
    ExplainLayoutAction,
    ExplainLayoutPayload,
    ExplainSourceType,
} from "data/actions";
import {
    ExplainLayout,
    ExplainClusterInfo,
    ExplainQueryInfo,
    ExplainWarning,
    PlanType,
} from "data/models";

import { Observable } from "rxjs";

import { makeActionCreator } from "worker/api/helpers";

import { generateLayout } from "data/explain/layout";
import {
    parseRawExplain,
    parseClusterInfo,
    parseQueryInfo,
    parseExplainWarnings,
} from "data/explain/parse";

import { logError } from "util/logging";
import { select } from "util/query";
import {
    executorInstanceToDimensions,
    getSpacing,
} from "data/explain/dimensions";

export const parseExplainString = makeActionCreator({
    name: "parseExplainString",

    handle: (
        ctx: HandlerContext,
        {
            rawExplain,
            source,
            sourceType,
        }: {
            rawExplain: string;
            source: string;
            sourceType: ExplainSourceType;
        }
    ): Observable<ExplainLayoutAction> => {
        const loading$ = Observable.of(createExplainLayoutAction({}));

        const compute$ = Observable.create((observer: Observer<unknown>) => {
            try {
                const {
                    layout,
                    clusterInfo,
                    queryInfo,
                    warnings,
                } = generateExplainLayout(rawExplain);
                observer.next(
                    createExplainLayoutAction({
                        payload: {
                            layout,
                            rawJSON: rawExplain,
                            source,
                            clusterInfo,
                            queryInfo,
                            sourceType,
                            warnings,
                        },
                    })
                );
            } catch (error) {
                observer.next(createExplainLayoutAction({ error }));
            }
        });

        return Observable.merge(loading$, compute$);
    },
});

// This creates a EXPLAIN_LAYOUT action. If the layout is not passed in,
// then it creates a loading EXPLAIN_LAYOUT action.
export const createExplainLayoutAction = ({
    payload,
    error,
}: {
    payload?: ExplainLayoutPayload;
    error?: Error;
}): ExplainLayoutAction => {
    if (error) {
        return {
            type: "EXPLAIN_LAYOUT",
            error: true,
            payload: {
                message: error.message,
            },
        };
    } else {
        if (payload) {
            return {
                type: "EXPLAIN_LAYOUT",
                error: false,
                payload: { loading: false, data: payload },
            };
        } else {
            return {
                type: "EXPLAIN_LAYOUT",
                error: false,
                payload: { loading: true },
            };
        }
    }
};

// This function generates a layout from a given raw EXPLAIN/PROFILE string.
// Note that it can throw many types of errors. The errors thrown here have
// messages which are user-friendly and can be show in a UI.
//
// If the type of the layout is known, the caller can pass it in, and it will be
// used as the type of the returned ExplainLayout. If not, this function will
// make a guess itself.
export const generateExplainLayout = (
    rawExplain: string,
    planType?: PlanType
): {
    layout: ExplainLayout;
    clusterInfo: Maybe<ExplainClusterInfo>;
    queryInfo: Maybe<ExplainQueryInfo>;
    warnings: Array<ExplainWarning>;
} => {
    let plan;
    try {
        plan = JSON.parse(rawExplain);
    } catch (err) {
        throw new Error("Failed to parse the provided JSON.");
    }

    const clusterInfo = parseClusterInfo(plan);
    const queryInfo = parseQueryInfo(plan);
    const warnings = parseExplainWarnings(plan);

    const { tree, planType: guessedType } = parseRawExplain(plan);

    return {
        layout: {
            layout: generateLayout(
                tree,
                executorInstanceToDimensions,
                getSpacing
            ),
            planType: planType || guessedType,
        },
        clusterInfo,
        queryInfo,
        warnings,
    };
};

export const generateExplainActionForQuery = ({
    rawJSON,
    planType,
    source,
}: {
    rawJSON: string;
    planType?: PlanType;
    source: string;
}): ExplainLayoutAction => {
    try {
        const {
            layout,
            clusterInfo,
            queryInfo,
            warnings,
        } = generateExplainLayout(rawJSON, planType);

        return createExplainLayoutAction({
            payload: {
                layout,
                rawJSON,
                clusterInfo,
                queryInfo,
                warnings,
                source,
                sourceType: "QUERY",
            },
        });
    } catch (error) {
        return createExplainLayoutAction({
            error,
        });
    }
};

export const messageToExplainLayoutAction = (
    msg: QueryExecutorMsg
): Maybe<ExplainLayoutAction> => {
    if (
        msg.kind === "queryMessage" &&
        msg.meta &&
        msg.meta.kind === "SHOW_PLAN"
    ) {
        const { planType, source } = msg.meta;

        if (
            msg.lastInGroup &&
            msg.queryMsg.kind === "rows" &&
            msg.queryMsg.rows.length
        ) {
            const rawJSON = msg.queryMsg.rows[0][0];

            return generateExplainActionForQuery({ rawJSON, planType, source });
        } else if (
            msg.queryMsg.kind === "queryEnd" &&
            msg.queryMsg.status.state === "error"
        ) {
            return createExplainLayoutAction({
                error: new Error(msg.queryMsg.status.message),
            });
        }
    }
};

export const createExplainQueryGroupRepr = ({
    query,
}: {
    query: string;
}): QueryGroupRepr => ({
    queries: [
        {
            text: `EXPLAIN JSON ${query}`,
            values: [],
        },
    ],

    onError: "STOP",
    limitAllButLast: false,

    meta: {
        kind: "SHOW_PLAN",
        planType: "EXPLAIN",
        source: query,
    },
});

export const createProfileQueryGroupRepr = ({
    query,
}: {
    query: string;
}): QueryGroupRepr => ({
    queries: [
        {
            text: `PROFILE ${query}`,
            values: [],
        },
        {
            text: `SHOW PROFILE JSON`,
            values: [],
        },
    ],

    onError: "STOP",
    limitAllButLast: false,

    meta: {
        kind: "SHOW_PLAN",
        planType: "PROFILE",
        source: query,
    },
});

export const dynamicExplainQuery = makeActionCreator({
    name: "dynamicExplainQuery",

    handle: (
        ctx: HandlerContext,
        {
            query,
            databaseName,
        }: {
            query: string;
            databaseName: Maybe<string>;
        }
    ): Observable<ExplainLayoutAction> => {
        const $loading = Observable.of<ExplainLayoutAction>({
            type: "EXPLAIN_LAYOUT",
            error: false,
            payload: {
                loading: true,
            },
        });

        const $compute = Observable.fromPromise<ExplainLayoutAction>(
            ctx.manager.getPooledConnection().then(conn => {
                // databaseName is not always defined, so sometimes we want to
                // run the explain on the connection immediately, and sometimes
                // we want to do it after explicitly selecting a database.
                let doneSelecting: Promise<unknown> = Promise.resolve();

                if (databaseName) {
                    doneSelecting = select(conn, "USE ?", [databaseName]);
                }

                return doneSelecting
                    .then(() => select(conn, `EXPLAIN JSON ${query}`))
                    .then(rows => {
                        return generateExplainActionForQuery({
                            rawJSON: rows[0].EXPLAIN,
                            planType: "EXPLAIN",
                            source: query,
                        });
                    })
                    .catch(
                        (error: Error | MysqlError): ExplainLayoutAction => {
                            logError(error);

                            return createExplainLayoutAction({ error });
                        }
                    )
                    .finally(() => conn.release());
            })
        );

        return Observable.merge($loading, $compute);
    },
});
