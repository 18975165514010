import { Router } from "router5";
import { Store as ReduxStore } from "redux";
import { State } from "data/reducers";
import { SuperAction } from "data/actions/types";

import { createStore, applyMiddleware } from "redux";

import { composeWithDevTools } from "redux-devtools-extension";
import { router5Middleware } from "redux-router5";
import { workerMiddleware } from "worker/middleware";

import actionsAnalytics from "data/middleware/actions-analytics";
import connectMiddleware from "data/middleware/connect";

import subscribeListeners from "data/listeners";

import reducers from "data/reducers";

export type Store = ReduxStore<State, SuperAction>;

export function newStore(router: Router) {
    let store: Store = createStore(
        reducers,
        composeWithDevTools(
            applyMiddleware(
                router5Middleware(router),
                workerMiddleware,
                actionsAnalytics,
                connectMiddleware
            )
        )
    );

    subscribeListeners(store);

    return store;
}
