import { Maybe } from "util/maybe";

// Performs a left join given 2 arrays (sorted the same way)
// where the second one is the "right" array, which is joined
// against the first one (i.e. rows from the first array are
// kept no matter what).
export default function leftJoin<T, T2, T3>(
    comparator: (first: T, second: T2) => -1 | 0 | 1,
    // The merge function can not take in a second argument which means
    // that it simply has to convert from T to T3.
    merge: (first: T, second: Maybe<T2>) => T3,
    arr1: Array<T>,
    arr2: Array<T2>
): Array<T3> {
    const output: Array<T3> = [];

    let iterator1 = 0;
    let iterator2 = 0;

    while (iterator1 < arr1.length && iterator2 < arr2.length) {
        const comparison = comparator(arr1[iterator1], arr2[iterator2]);

        if (comparison === 0) {
            output.push(merge(arr1[iterator1], arr2[iterator2]));
            iterator1++;
            iterator2++;
        } else {
            if (comparison === -1) {
                output.push(merge(arr1[iterator1], undefined));
                iterator1++;
            } else if (comparison === 1) {
                iterator2++;
            }
        }
    }

    // We want to keep going even if we reached the end of the second
    // array.
    while (iterator1 < arr1.length) {
        output.push(merge(arr1[iterator1], undefined));
        iterator1++;
    }

    return output;
}
