// `buildRankDictionary` takes in an ordered array of strings and returns an
// object that maps each string into its index in the array.
export function buildRankDictionary<T extends string>(
    arr: Array<T>
): { [t in T]?: number } {
    const dict: { [t in T]?: number } = {};

    for (let i = 0; i < arr.length; i++) {
        dict[arr[i]] = i;
    }

    return dict;
}
