import { Maybe } from "util/maybe";

import _ from "lodash";
import BigNumber from "vendor/bignumber.js/bignumber";

import { count } from "util/count";
import { plural } from "util/string";

export type ClusterStatistics = {
    haEnabled: boolean;
};

export type PartitionInstance = {
    ordinal: Maybe<BigNumber>;
    databaseName: string;
    host: string;
    port: BigNumber;
    role: Maybe<
        "master" | "slave" | "detached master" | "detached slave" | string
    >;
    position: Maybe<string>;
    state:
        | "replicating"
        | "online"
        | "provisioning"
        | "unrecoverable"
        | "recovering"
        | "transition"
        | "pending"
        | "offline"
        | string;
    details: string; // loose string, set to empty when state is "offline"
    drReplica: Maybe<boolean>;

    // Whether this partition instance is currently sync/async replicated. We
    // allow for new types of replication in the future by supporting the
    // `string` type. If the sync state is either an empty string or NULL in the
    // engine, we read it as `undefined`.
    syncState: Maybe<"sync" | "async" | string>;
};

type BasePartition = {
    databaseName: string;

    // Whether this partition is a DR replica or not.
    drReplica: boolean;

    // Whether this partition should be sync replicated or not.
    syncReplicated: boolean;
};

export type DataPartition = BasePartition & {
    kind: "data";
    ordinal: BigNumber;
};

export type ReferencePartition = BasePartition & {
    kind: "reference";
};

export type Partition = DataPartition | ReferencePartition;

export type PartitionStatus =
    | "online"
    | "degraded_recovering"
    | "degraded_unrecoverable"
    | "offline_recovering"
    | "offline"
    | "unknown";

export type DatabaseStatus = PartitionStatus;

export type SyncState = "sync" | "async";

export type BaseDerivedPartition = {
    partitionInstances: Array<PartitionInstance>;
    status: PartitionStatus;
    expectedNumSlaves: number;
    syncState: Maybe<SyncState>;
};

export type DerivedPartition = Partition & BaseDerivedPartition;

export type RowsThroughputPayload = {
    rowsAffectedByWrites: BigNumber;
    rowsReturnedByReads: BigNumber;
    readTime: Date;
};

// Returns the number of detached partition instances for a given partition.
export function _countPartitionDetachedSlaves(
    partition: DerivedPartition
): number {
    return count(
        partition.partitionInstances,
        pi => pi.role === "detached slave" || pi.role === "detached master"
    );
}

// Returns a boolean stating whether this partition's number of slaves is
// different from its expected number of slaves.
export function _getPartitionHasSlaveMismatch(partition: DerivedPartition) {
    const slaveCount = count(
        partition.partitionInstances,
        pi => pi.role === "slave"
    );

    return slaveCount !== partition.expectedNumSlaves;
}

// Returns a boolean stating whether this partition is currently being
// asynchronously replicated but should be synchronously replicated.
export function _getPartitionIsIncorrectlyAsync(partition: DerivedPartition) {
    if (partition.syncReplicated) {
        return partition.syncState === "async";
    } else {
        return false;
    }
}

export function isPartitionImpacted(partition: DerivedPartition) {
    return _.some(getPartitionErrors(partition));
}

// We define the impacted state of a partition as one of the following things
// being true about this partition:
// * The partition has a different number of slaves from its expected number of
//   slaves
// * The partition has 1 or more detached partition instances
// * The partition's sync state is async but should be sync (7.0 only)
export function getPartitionErrors(partition: DerivedPartition) {
    const errors: Array<string> = [];

    if (_getPartitionHasSlaveMismatch(partition)) {
        const slaveCount = count(
            partition.partitionInstances,
            pi => pi.role === "slave"
        );

        errors.push(
            `This partition currently has ${slaveCount} ${plural(
                "replica",
                slaveCount
            )}, but is expected to have ${partition.expectedNumSlaves} ${plural(
                "replica",
                partition.expectedNumSlaves
            )}.`
        );
    }

    const numDetachedInstances = _countPartitionDetachedSlaves(partition);
    if (numDetachedInstances > 0) {
        errors.push(
            `This partition currently has ${numDetachedInstances} detached ${plural(
                "replica",
                numDetachedInstances
            )}. Healthy partitions typically do not have detached replicas.`
        );
    }

    if (_getPartitionIsIncorrectlyAsync(partition)) {
        errors.push(
            "This partition's replication state is currently asynchronous but partitions in this database are expected to be synchronously replicated."
        );
    }

    return errors;
}

export function getPartitionStatusMessage(partitionStatus: PartitionStatus) {
    switch (partitionStatus) {
        case "degraded_recovering":
            return "This partition is degraded, but is attempting to recover.";

        case "degraded_unrecoverable":
            return "This partition is degraded, manual action must be taken to recover.";

        case "offline_recovering":
            return "This partition is offline, but is attempting to transition back online.";

        case "offline":
            return "This partition is offline.";

        case "unknown":
            return "This partition's current health status is unknown.";

        case "online":
            return "This partition is healthy.";
    }
}

export function getDatabaseStatusMessage(databaseStatus: DatabaseStatus) {
    switch (databaseStatus) {
        case "degraded_recovering":
            return "This database is currently in a Degraded Recovering state because at least one of its partitions is Degraded and is attempting to recover.";

        case "degraded_unrecoverable":
            return "This database is currently in a Degraded Unrecoverable state because at least one of its partitions is Degraded and is unable to recover.";

        case "offline_recovering":
            return "This database is currently in an Offline Recovering state because at least one of its partitions is Offline and is attempting to recover.";

        case "offline":
            return "This database is currently in an Offline state because at least one of its partitions is Offline. If all your partitions are Offline, please check with the cluster admin to make sure you have been granted access to this database.";

        case "unknown":
            return "This database's current health status is unknown.";

        case "online":
            return "This database is currently Online and its member partitions are Healthy.";
    }
}

export function getPartitionName(partition: Partition | DerivedPartition) {
    if (partition.kind === "data") {
        return `${partition.databaseName}:${partition.ordinal}`;
    } else {
        return partition.databaseName;
    }
}

export function getPartitionType(partition: Partition | DerivedPartition) {
    if (partition.kind === "data") {
        return "Data Partition";
    } else {
        return "Reference Partition";
    }
}

export function getPartitionInstanceName(partitionInstance: PartitionInstance) {
    if (partitionInstance.ordinal !== undefined) {
        return `${partitionInstance.databaseName}:${partitionInstance.ordinal}`;
    } else {
        return partitionInstance.databaseName;
    }
}

export function getPartitionInstancePartitionName(
    partitionInstance: PartitionInstance
) {
    if (partitionInstance.ordinal !== undefined) {
        return `${partitionInstance.databaseName}:${partitionInstance.ordinal}`;
    } else {
        return partitionInstance.databaseName;
    }
}

export function getPartitionInstanceType(pi: PartitionInstance) {
    if (pi.ordinal === undefined) {
        return "Reference Partition Instance";
    } else {
        return "Data Partition Instance";
    }
}
