import * as React from "react";
import classNames from "classnames";

import Icon from "view/components/icon";

import "./alert.scss";

export type AlertStatus = "success" | "warning" | "error" | "info";

type Props = {
    title?: React.ReactNode;
    message: React.ReactNode;
    status: AlertStatus;
    className?: string;
};

const ALERT_ICON: { [status in AlertStatus]: string } = {
    success: "check-circle",
    error: "exclamation-triangle",
    warning: "exclamation-circle",
    info: "info-circle",
};

export function Alert(props: Props) {
    const { title, message, status, className } = props;

    const classes = classNames("components-alert", status, className);

    let alertTitle;
    if (title) {
        alertTitle = <div className="title">{title}</div>;
    }

    const icon = (
        <Icon icon={ALERT_ICON[status]} className="icon" rightMargin />
    );

    return (
        <div className={classes}>
            <div className="left">{icon}</div>
            <div className="right">
                {alertTitle}
                <div className="description">{message}</div>
            </div>
        </div>
    );
}
