import { Maybe } from "util/maybe";
import { Action, SimpleAction } from "data/actions/types";
import { DispatchFunction } from "data";
import { Loading } from "util/loading-state-machine";
import { Vector } from "util/vector";

import { ExplainSectionName } from "data/reducers/explain";
import { ZoomLevel } from "data/explain/layout";
import {
    ExplainLayout,
    ExplainClusterInfo,
    ExplainQueryInfo,
    ExecutorType,
    ExplainWarning,
} from "data/models";

import { staticQueryGroup } from "worker/api/connection";
import { CONNECTION_ID } from "data/reducers/query-editor";
import {
    createExplainQueryGroupRepr,
    createProfileQueryGroupRepr,
} from "worker/api/explain";

export type ExplainSidebarClickAction = {
    type: "EXPLAIN_SIDEBAR_CLICK";
    payload: { executor: ExecutorType };
    error: false;
};

export type ExplainSourceType = "FILE" | "QUERY";

export type ExplainLayoutPayload = {
    layout: ExplainLayout;
    rawJSON: string;

    // filename or query that resulted in this explain; can be displayed to
    // the user but shouldn't be assumed to have any particular meaning
    source: string;
    sourceType: ExplainSourceType;

    clusterInfo: Maybe<ExplainClusterInfo>;
    queryInfo: Maybe<ExplainQueryInfo>;
    warnings: Array<ExplainWarning>;
};

export type ExplainLayoutAction = Action<
    "EXPLAIN_LAYOUT",
    Loading<ExplainLayoutPayload>,
    { message: string }
>;

export type ZoomAction = SimpleAction<"ZOOM", { zoomLevel: ZoomLevel }>;

export type SelectExplainNodeAction = SimpleAction<
    "SELECT_EXPLAIN_NODE",
    { index: Maybe<number>; executor: ExecutorType }
>;

export type ExplainViewportOffsetAction = SimpleAction<
    "EXPLAIN_VIEWPORT_OFFSET",
    { offset: Vector }
>;

export const explainViewportOffset = (payload: {
    offset: Vector;
}): ExplainViewportOffsetAction => ({
    type: "EXPLAIN_VIEWPORT_OFFSET",
    error: false,
    payload,
});

export type ExplainTab = "Actual" | "Estimated" | "Difference";

export const EXPLAIN_TABS: Array<ExplainTab> = [
    "Actual",
    "Estimated",
    "Difference",
];

export type ChangeExplainTabAction = SimpleAction<
    "CHANGE_EXPLAIN_TAB",
    { tab: ExplainTab }
>;

export const changeExplainTab = (payload: {
    tab: ExplainTab;
}): ChangeExplainTabAction => ({
    type: "CHANGE_EXPLAIN_TAB",
    error: false,
    payload,
});

export const explainQuery = ({ query }: { query: string }) => {
    return (dispatch: DispatchFunction) => {
        dispatch({
            type: "EXPLAIN_LAYOUT",
            error: false,
            payload: {
                loading: true,
            },
        });

        dispatch(
            staticQueryGroup({
                id: CONNECTION_ID,
                queryGroup: createExplainQueryGroupRepr({ query }),
            })
        );
    };
};

export const profileQuery = ({ query }: { query: string }) => {
    return (dispatch: DispatchFunction) => {
        dispatch({
            type: "EXPLAIN_LAYOUT",
            error: false,
            payload: {
                loading: true,
            },
        });

        dispatch(
            staticQueryGroup({
                id: CONNECTION_ID,
                queryGroup: createProfileQueryGroupRepr({ query }),
            })
        );
    };
};

type SectionNamePayload = {
    sectionName: ExplainSectionName;
};

export type ExpandSectionAction = SimpleAction<
    "EXPAND_EXPLAIN_SECTION",
    SectionNamePayload
>;

export const expandSection = (
    payload: SectionNamePayload
): ExpandSectionAction => ({
    type: "EXPAND_EXPLAIN_SECTION",
    error: false,
    payload,
});

export type CollapseSectionAction = SimpleAction<
    "COLLAPSE_EXPLAIN_SECTION",
    SectionNamePayload
>;

export const collapseSection = (
    payload: SectionNamePayload
): CollapseSectionAction => ({
    type: "COLLAPSE_EXPLAIN_SECTION",
    error: false,
    payload,
});
