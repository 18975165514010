import { Node, DerivedNode, NodePartitionInstanceStatus } from "data/models";
import { Level } from "view/util/level";

import * as React from "react";

import Icon from "view/components/icon";

import { humanizeNodePartitionInstanceStatus } from "data/models";
import { getWorseLevel } from "view/util/level";

import "./topology.scss";

function getNodePartitionInstanceStatusIconName(
    status: NodePartitionInstanceStatus
) {
    switch (status) {
        case "PARTITIONS_ONLINE":
            return "check-circle";

        case "PARTITIONS_UNKNOWN":
            return "exclamation-circle";

        case "PARTITIONS_OFFLINE_RECOVERING":
        case "PARTITIONS_OFFLINE":
            return "exclamation-triangle";
    }
}

function getNodePartitionInstanceStatusIcon(node: DerivedNode) {
    const iconStatusLevel = getNodePartitionInstanceStatusLevel(node);

    return (
        <Icon
            icon={getNodePartitionInstanceStatusIconName(
                node.partitionInstanceStatus
            )}
            iconType="regular"
            rightMargin
            {...{ [iconStatusLevel]: true }}
        />
    );
}

export function formatNodePartitionInstanceStatus(node: DerivedNode) {
    const className = `status-${getNodePartitionInstanceStatusLevel(node)}`;

    return (
        <div className={className}>
            {getNodePartitionInstanceStatusIcon(node)}
            {humanizeNodePartitionInstanceStatus(node)}
        </div>
    );
}

export const getNodeStateLevel = (node: Node | DerivedNode): Level => {
    switch (node.state) {
        case "online":
            return "success";

        case "offline":
            return "error";

        case "attaching":
        case "detaching":
        default:
            return "warning";
    }
};

export const getNodePartitionInstanceStatusLevel = (
    node: DerivedNode
): Level => {
    switch (node.partitionInstanceStatus) {
        case "PARTITIONS_ONLINE":
            return "success";

        case "PARTITIONS_UNKNOWN":
            return "warning";

        case "PARTITIONS_OFFLINE":
        case "PARTITIONS_OFFLINE_RECOVERING":
            return "error";
    }
};

export const getOverallNodeStateLevel = (node: DerivedNode): Level =>
    getWorseLevel(
        getNodeStateLevel(node),
        getNodePartitionInstanceStatusLevel(node)
    );
