import { Maybe } from "util/maybe";
import { State } from "data";
import { DerivedHost, Node } from "data/models";

import _ from "lodash";
import { createSelector } from "reselect";

import { GeneralTableColumn } from "view/components/general-table";

import { selectRoute } from "data/selectors/routes";
import { selectNodes } from "data/selectors/topology";

import {
    selectLoadingOrInitial,
    selectLastUpdate,
    selectPayload,
    selectError,
} from "util/loading-state-machine";

import { ALL_HOSTS_COLUMNS } from "view/hosts/columns-info";
import { ALL_HOST_PAGE_COLUMNS } from "view/topology/columns-info";

export const selectHostsLoading = (s: State) =>
    selectLoadingOrInitial(s.hosts.hosts) ||
    selectLoadingOrInitial(s.topology.topology);

export const selectHostsLastUpdate = (s: State) =>
    _.max([
        selectLastUpdate(s.hosts.hosts),
        selectLastUpdate(s.topology.topology),
    ]);

export const selectHostsError = (s: State) =>
    selectError(s.hosts.hosts) || selectError(s.topology.topology);

export const selectHosts = (s: State) => {
    const hostsPayload = selectPayload(s.hosts.hosts);

    if (hostsPayload) {
        return hostsPayload.hosts;
    }
};

export const selectHostsSort = (s: State) => s.hosts.hostsSort;
export const selectHostNodesSort = (s: State) => s.hosts.hostNodesSort;

export const deriveHosts = createSelector(
    selectHosts,
    selectNodes,
    (hosts, allNodes): Maybe<Array<DerivedHost>> => {
        if (hosts) {
            return _.map(hosts, host => {
                let nodes: Array<Node> = [];

                if (allNodes) {
                    nodes = _.filter(
                        allNodes,
                        node => node.host === host.address
                    );
                }

                return { ...host, nodes };
            });
        }
    }
);

export const selectSortedHosts = createSelector(
    deriveHosts,
    selectHostsSort,
    (hosts, sort) => {
        if (sort && hosts) {
            const { direction, columnId } = sort;

            const columnInfo = _.find(
                ALL_HOSTS_COLUMNS,
                (col: GeneralTableColumn<unknown>) => col.id === columnId
            );

            if (!columnInfo) {
                throw new Error(
                    `Expected column ${columnId} to be defined while sorting hosts`
                );
            }

            return _.orderBy<DerivedHost>(
                hosts,
                [columnInfo.getValue],
                direction
            );
        } else if (hosts) {
            return hosts;
        }
    }
);

export const selectCurrentHostAddress = (s: State) =>
    selectRoute(s).params.hostId;

export const selectCurrentHost = createSelector(
    deriveHosts,
    selectCurrentHostAddress,
    (hosts, hostAddress): Maybe<DerivedHost> => {
        if (hosts && hostAddress) {
            return _.find(hosts, host => host.address === hostAddress);
        }
    }
);

export const selectSortedHostNodes = createSelector(
    selectCurrentHost,
    selectHostNodesSort,
    (host, sort): Maybe<Array<Node>> => {
        if (host) {
            if (sort) {
                const { direction, columnId } = sort;

                const columnInfo = _.find(
                    ALL_HOST_PAGE_COLUMNS,
                    (col: GeneralTableColumn<unknown>) => col.id === columnId
                );

                if (!columnInfo) {
                    throw new Error(
                        `Expected column ${columnId} to be defined while sorting hosts' nodes`
                    );
                }

                return _.orderBy<Node>(
                    host.nodes,
                    [columnInfo.getValue],
                    direction
                );
            } else {
                return host.nodes;
            }
        }
    }
);

export function selectIsPhysicalMonitoringEnabled(s: State) {
    return s.hosts.physicalMonitoringEnabled;
}
