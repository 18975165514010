import { Maybe } from "util/maybe";

import { State, DispatchFunction } from "data";
import { InjectedRoute } from "react-router5";
import {
    TablesSelection,
    ViewsSelection,
    StoredProceduresSelection,
    UDFsSelection,
    UDAsSelection,
    PipelinesSelection,
} from "data/selectors/schema";
import {
    PartitionsSelection,
    selectPartitionsSort,
} from "data/selectors/cluster-metadata";
import {
    DerivedDatabase,
    DerivedTable,
    DerivedView,
    DerivedPartition,
    TableName,
    StoredProcedure,
    UserDefinedFunction,
    UserDefinedAggregate,
} from "data/models";
import { SortDirection, TableSort } from "util/sort";
import { ColumnId } from "view/components/super-table";
import { AlertStatus } from "view/components/alert";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { withRoute } from "react-router5";

import SchemaTable from "view/schema/schema-table";
import GeneralTable from "view/components/general-table";
import SchemaContent from "view/schema/schema-content";
import PipelinesTable from "view/common/pipelines-table";
import ExtLink from "view/components/external-link";
import { Tab, Tabs, TabTitle } from "view/components/tab";
import Card from "view/components/card";
import {
    TopContent,
    TopContentRow,
    TopContentHeader,
} from "view/components/top-content";
import Loading from "view/components/loading";
import { Alert } from "view/components/alert";
import IconTip from "view/components/icon-tip";

import { displayPartitionStatus } from "view/common/models/cluster-metadata";

import {
    selectSortedTables,
    selectSortedViews,
    selectSortedStoredProcedures,
    selectSortedUDFs,
    selectSortedUDAs,
    selectCurrentDerivedDatabase,
    selectSchemaPipelinesSort,
    selectSchemaSortedPipelines,
} from "data/selectors/schema";

import { selectSortedPartitions } from "data/selectors/cluster-metadata";

import { schemaSort, sortPartitions } from "data/actions";
import { getPartitionName, getDatabaseStatusMessage } from "data/models";

import getRoute from "util/get-route";
import NumberFormatter from "util/number-formatter";
import { LONG_EM_DASH } from "util/symbols";
import { nextTableSort } from "util/sort";

import {
    TABLE_COLUMNS,
    VIEW_COLUMNS,
    STORED_PROCEDURE_COLUMNS,
    UDF_COLUMNS,
    UDA_COLUMNS,
    PARTITION_COLUMNS,
} from "memsql/schema-column-info";

import "./page-tables.scss";

type TopTabState = "Partitions" | "Schema";

type SchemaTabState =
    | "Tables"
    | "Views"
    | "System Views"
    | "Procedures"
    | "Functions"
    | "Aggregates"
    | "Pipelines";

const TOP_TABS: Array<TopTabState> = ["Schema", "Partitions"];

const SCHEMA_TABS: Array<SchemaTabState> = [
    "Tables",
    "Views",
    "Procedures",
    "Functions",
    "Aggregates",
    "Pipelines",
];

const INFORMATION_SCHEMA_TABS: Array<SchemaTabState> = ["System Views"];

type StateProps = {
    tables: TablesSelection;
    views: ViewsSelection;
    storedProcedures: StoredProceduresSelection;
    pipelines: PipelinesSelection;
    pipelinesSort: TableSort;
    userDefinedFunctions: UDFsSelection;
    aggregates: UDAsSelection;
    derivedDatabase: Maybe<DerivedDatabase>;
    partitions: Maybe<PartitionsSelection>;
    partitionsSort: TableSort;
};

type Props = StateProps &
    InjectedRoute & {
        dispatch: DispatchFunction;
        tab: TopTabState | SchemaTabState;
    };

class TablesPage extends React.Component<Props> {
    handleClickCell = (tableName: TableName) => {
        const { router } = this.props;
        const {
            params: { clusterId, databaseName },
        } = getRoute(this.props.route);

        router.navigate("cluster.databases.columns", {
            clusterId,
            databaseName,
            tableName,
        });
    };

    handleClickPartitionCell = (partition: DerivedPartition) => {
        const { router } = this.props;
        const {
            params: { clusterId, databaseName },
        } = getRoute(this.props.route);

        router.navigate("cluster.databases.partition", {
            clusterId,
            databaseName,
            partitionName: getPartitionName(partition),
        });
    };

    handleClickTopTab = (selectIndex: number) => {
        const { router } = this.props;
        const {
            params: { clusterId, databaseName },
        } = getRoute(this.props.route);

        let route;

        if (selectIndex === 0) {
            route = "cluster.databases.tables";
        } else if (selectIndex === 1) {
            route = "cluster.databases.partitions";
        }

        if (route) {
            router.navigate(route, {
                clusterId,
                databaseName,
            });
        }
    };

    handleClickSchemaTab = (schemaIndex: number) => {
        const { router } = this.props;
        const {
            params: { clusterId, databaseName },
        } = getRoute(this.props.route);

        let route;

        if (schemaIndex === 0) {
            route = "cluster.databases.tables";
        } else if (schemaIndex === 1) {
            route = "cluster.databases.views";
        } else if (schemaIndex === 2) {
            route = "cluster.databases.procedures";
        } else if (schemaIndex === 3) {
            route = "cluster.databases.functions";
        } else if (schemaIndex === 4) {
            route = "cluster.databases.aggregates";
        } else if (schemaIndex === 5) {
            route = "cluster.databases.pipelines";
        }

        if (route) {
            router.navigate(route, {
                clusterId,
                databaseName,
            });
        }
    };

    handleSortPipelines = (
        columnId: ColumnId,
        direction: Maybe<SortDirection>
    ) => {
        this.props.dispatch(
            schemaSort({
                entityKind: "PIPELINE",
                sort: nextTableSort(columnId, direction),
            })
        );
    };

    handleSortPartitions = (
        columnId: ColumnId,
        direction: Maybe<SortDirection>
    ) => {
        this.props.dispatch(sortPartitions(nextTableSort(columnId, direction)));
    };

    getTabCount = (tab: SchemaTabState): number => {
        const {
            aggregates,
            userDefinedFunctions,
            storedProcedures,
            tables,
            views,
            pipelines,
        } = this.props;

        switch (tab) {
            case "Aggregates":
                return aggregates.length;

            case "Functions":
                return userDefinedFunctions.length;

            case "Procedures":
                return storedProcedures.length;

            case "Tables":
                return tables.length;

            case "Pipelines":
                return pipelines.length;

            case "Views":
            case "System Views":
                return views.length;

            default:
                throw new Error(`Tab ${tab} is not a valid tab in PageTables.`);
        }
    };

    getTopTabTitles = (tabTitles: Array<TopTabState>) =>
        _.map(tabTitles, tabTitle => <TabTitle title={tabTitle} />);

    getSchemaTabTitles = (tabTitles: Array<SchemaTabState>) =>
        _.map(tabTitles, tabTitle => (
            <TabTitle
                title={tabTitle}
                badgeProps={{
                    count: this.getTabCount(tabTitle),
                    leftMargin: true,
                }}
            />
        ));

    renderInfoTopCard = (derivedDatabase: DerivedDatabase) => {
        if (!derivedDatabase.database.statistics) {
            return null;
        }

        let diskUsageCardInner;
        if (derivedDatabase.derived.diskUsage.isError()) {
            diskUsageCardInner = LONG_EM_DASH;
        } else if (derivedDatabase.derived.diskUsage.isLoading()) {
            diskUsageCardInner = <Loading size="small" />;
        } else {
            diskUsageCardInner = NumberFormatter.formatBytes(
                derivedDatabase.derived.diskUsage.value
            );
        }

        return (
            <Card className="database-info-card">
                <TopContentHeader>Database Info</TopContentHeader>

                <TopContentRow>
                    <TopContent title="Memory Usage" className="top-card">
                        {NumberFormatter.formatBytes(
                            derivedDatabase.derived.memoryUsage
                        )}
                    </TopContent>

                    <TopContent title="Disk Usage" className="top-card">
                        {diskUsageCardInner}
                    </TopContent>

                    <TopContent title="Partition Count" className="top-card">
                        {derivedDatabase.database.statistics.partitionCount.toString()}
                    </TopContent>

                    <TopContent title="Table Count" className="top-card">
                        {derivedDatabase.derived.tableCount}
                    </TopContent>

                    <TopContent title="Replication Type" className="top-card">
                        {derivedDatabase.database.statistics.syncRepl
                            ? "Synchronous"
                            : "Asynchronous"}
                    </TopContent>

                    <TopContent
                        title={
                            <div>
                                DR Replica{" "}
                                <IconTip iconProps={{ leftMargin: true }}>
                                    Whether this database is a replica of
                                    another database or not. This may or may not
                                    be a database name from this cluster.
                                </IconTip>
                            </div>
                        }
                        className="top-card"
                    >
                        {derivedDatabase.database.statistics.remoteName
                            ? `Replica of "${
                                  derivedDatabase.database.statistics.remoteName
                              }"`
                            : "Database is not a secondary replica"}
                    </TopContent>

                    <TopContent
                        title={
                            <div>
                                Nodes Used{" "}
                                <IconTip iconProps={{ leftMargin: true }}>
                                    Number of leaf nodes that store data
                                    partitions for this database over the total
                                    number of leaf nodes in the cluster
                                </IconTip>
                            </div>
                        }
                        className="top-card"
                    >
                        {derivedDatabase.derived.dataPartitionInstanceNodeCount}{" "}
                        / {derivedDatabase.derived.totalLeafCount}
                    </TopContent>
                </TopContentRow>
            </Card>
        );
    };

    renderStatusTopCard = (derivedDatabase: DerivedDatabase) => {
        if (
            !derivedDatabase.derived.impacted &&
            derivedDatabase.derived.status === "online"
        ) {
            return null;
        }

        let status: AlertStatus;
        if (
            derivedDatabase.derived.status === "offline" ||
            derivedDatabase.derived.status === "offline_recovering"
        ) {
            status = "error";
        } else {
            status = "warning";
        }

        let impactedMessage;
        if (derivedDatabase.derived.impacted) {
            impactedMessage = (
                <div className="impacted-message">
                    Warnings exist on some partitions, view impacted member
                    partitions to resolve warnings
                </div>
            );
        }

        let title;
        if (derivedDatabase.derived.status === "offline") {
            title = (
                <div className="errors-card-title error-offline">
                    Your database is offline, or you may not have access to this
                    database.
                </div>
            );
        } else {
            title = (
                <div className="errors-card-title">
                    HEALTH STATUS:{" "}
                    <span className="database-status">
                        {displayPartitionStatus(
                            derivedDatabase.derived.status,
                            derivedDatabase.derived.impacted
                        )}
                    </span>
                </div>
            );
        }

        return (
            <Alert
                className="errors-card"
                status={status}
                title={title}
                message={
                    <>
                        {getDatabaseStatusMessage(
                            derivedDatabase.derived.status
                        )}
                        {impactedMessage}
                        <ExtLink
                            name="database-states"
                            category="schema-explorer"
                            className="database-states-help-link"
                        >
                            Learn more about database states
                        </ExtLink>
                    </>
                }
            />
        );
    };

    render() {
        const {
            tables,
            views,
            storedProcedures,
            derivedDatabase,
            userDefinedFunctions,
            aggregates,
            pipelines,
            pipelinesSort,
            partitions,
            partitionsSort,
        } = this.props;
        let { tab: activeTab } = this.props;

        let schemaTabs;
        let mainTable;
        let databaseInfoTopCard: React.ReactNode = null;
        let databaseStatusTopCard: React.ReactNode = null;

        // The `information_schema` table is special since we only show one tab
        // and that tab is always active (System Views). This tab will show
        // the list of views in the database since we store System Views as
        // normal views in our state/model logic.
        if (
            derivedDatabase &&
            derivedDatabase.database.databaseName === "information_schema"
        ) {
            schemaTabs = INFORMATION_SCHEMA_TABS;
            activeTab = "System Views";
        } else {
            if (derivedDatabase) {
                databaseInfoTopCard = this.renderInfoTopCard(derivedDatabase);
                databaseStatusTopCard = this.renderStatusTopCard(
                    derivedDatabase
                );
            }

            schemaTabs = SCHEMA_TABS;
        }

        const topTabTitles = this.getTopTabTitles(TOP_TABS);
        const schemaTabTitles = this.getSchemaTabTitles(schemaTabs);

        if (activeTab === "System Views") {
            const systemViewsPane = (
                <Tab>
                    <SchemaTable
                        entityKind="VIEW"
                        columns={VIEW_COLUMNS}
                        getRowId={({ view }: DerivedView) => view.tableName}
                        onClickCell={this.handleClickCell}
                        rows={views}
                    />
                </Tab>
            );

            // we pass a no-op callback to onChange since there is only one tab
            mainTable = (
                // eslint-disable-next-line react/jsx-handler-names
                <Tabs activeTab={0} titles={schemaTabTitles} onChange={_.noop}>
                    {systemViewsPane}
                </Tabs>
            );
        } else {
            const schemaTabIndex = _.indexOf(schemaTabs, activeTab);
            const topTabIndex = schemaTabIndex === -1 ? 1 : 0;

            let tablesPane;
            let viewsPane;
            let proceduresPane;
            let userDefinedFunctionsPane;
            let aggregatesPane;
            let partitionsPane;
            let pipelinesPane;

            if (tables.length === 0) {
                tablesPane = (
                    <Tab>
                        <div className="zero-of-entity">
                            There are no tables in this database.{" "}
                            <ExtLink
                                name="create-table"
                                category="schema-explorer"
                            >
                                How to create a table?
                            </ExtLink>
                        </div>
                    </Tab>
                );
            } else {
                tablesPane = (
                    <Tab>
                        <SchemaTable
                            entityKind="TABLE"
                            columns={TABLE_COLUMNS}
                            getRowId={({ table }: DerivedTable) =>
                                table.tableName
                            }
                            onClickCell={this.handleClickCell}
                            rows={tables}
                        />
                    </Tab>
                );
            }

            if (views.length === 0) {
                viewsPane = (
                    <Tab>
                        <div className="zero-of-entity">
                            There are no views in this database.{" "}
                            <ExtLink
                                name="create-view"
                                category="schema-explorer"
                            >
                                How to create a view?
                            </ExtLink>
                        </div>
                    </Tab>
                );
            } else {
                viewsPane = (
                    <Tab>
                        <SchemaTable
                            entityKind="VIEW"
                            columns={VIEW_COLUMNS}
                            getRowId={({ view }: DerivedView) => view.tableName}
                            onClickCell={this.handleClickCell}
                            rows={views}
                        />
                    </Tab>
                );
            }

            if (storedProcedures.length === 0) {
                proceduresPane = (
                    <Tab>
                        <div className="zero-of-entity">
                            There are no Stored Procedures in this database.{" "}
                            <ExtLink
                                name="create-stored-procedure"
                                category="schema-explorer"
                            >
                                How to create a Stored Procedure?
                            </ExtLink>
                        </div>
                    </Tab>
                );
            } else {
                proceduresPane = (
                    <Tab>
                        <SchemaTable
                            entityKind="STORED_PROCEDURE"
                            columns={STORED_PROCEDURE_COLUMNS}
                            getRowId={(sp: StoredProcedure) => sp.name}
                            rows={storedProcedures}
                        />
                    </Tab>
                );
            }

            if (userDefinedFunctions.length === 0) {
                userDefinedFunctionsPane = (
                    <Tab>
                        <div className="zero-of-entity">
                            There are no User Defined Functions (UDF) in this
                            database.{" "}
                            <ExtLink
                                name="create-udf"
                                category="schema-explorer"
                            >
                                How to create a UDF?
                            </ExtLink>
                        </div>
                    </Tab>
                );
            } else {
                userDefinedFunctionsPane = (
                    <Tab>
                        <SchemaTable
                            entityKind="USER_DEFINED_FUNCTION"
                            columns={UDF_COLUMNS}
                            getRowId={(udf: UserDefinedFunction) => udf.name}
                            rows={userDefinedFunctions}
                        />
                    </Tab>
                );
            }

            if (aggregates.length === 0) {
                aggregatesPane = (
                    <Tab>
                        <div className="zero-of-entity">
                            There are no User Defined Aggregate Functions in
                            this database.{" "}
                            <ExtLink
                                name="create-udaf"
                                category="schema-explorer"
                            >
                                How to create a UDAF?
                            </ExtLink>
                        </div>
                    </Tab>
                );
            } else {
                aggregatesPane = (
                    <Tab>
                        <SchemaTable
                            entityKind="USER_DEFINED_AGGREGATE"
                            columns={UDA_COLUMNS}
                            getRowId={(aggregate: UserDefinedAggregate) =>
                                aggregate.name
                            }
                            rows={aggregates}
                        />
                    </Tab>
                );
            }

            if (!partitions) {
                // should never happen
                return null;
            } else if (partitions.length === 0) {
                partitionsPane = (
                    <Tab>
                        <div className="zero-of-entity">
                            There are no partitions in this database.
                        </div>
                    </Tab>
                );
            } else {
                partitionsPane = (
                    <Tab>
                        <GeneralTable
                            rows={partitions}
                            sort={partitionsSort}
                            columns={PARTITION_COLUMNS}
                            onSort={this.handleSortPartitions}
                            onClickCell={this.handleClickPartitionCell}
                        />
                    </Tab>
                );
            }

            if (pipelines.length === 0) {
                pipelinesPane = (
                    <div className="zero-of-entity">
                        There are no pipelines in this database.{" "}
                        <ExtLink
                            name="create-pipeline"
                            category="schema-explorer"
                        >
                            How to create a pipeline?
                        </ExtLink>
                    </div>
                );
            } else {
                pipelinesPane = (
                    <PipelinesTable
                        className="schema-table"
                        sort={pipelinesSort}
                        pipelines={pipelines}
                        onSort={this.handleSortPipelines}
                    />
                );
            }

            mainTable = (
                <Tabs
                    nested
                    activeTab={topTabIndex}
                    titles={topTabTitles}
                    onChange={this.handleClickTopTab}
                >
                    <Tab>
                        <Tabs
                            activeTab={schemaTabIndex}
                            titles={schemaTabTitles}
                            onChange={this.handleClickSchemaTab}
                        >
                            {tablesPane}
                            {viewsPane}
                            {proceduresPane}
                            {userDefinedFunctionsPane}
                            {aggregatesPane}
                            {pipelinesPane}
                        </Tabs>
                    </Tab>
                    <Tab>{partitionsPane}</Tab>
                </Tabs>
            );
        }

        const mainContent = (
            <div className="schema-page-tables-main">
                {databaseStatusTopCard}
                {databaseInfoTopCard}
                {mainTable}
            </div>
        );

        return <SchemaContent mainContent={mainContent} />;
    }
}

export default connect(
    (s: State): StateProps => ({
        tables: selectSortedTables(s),
        views: selectSortedViews(s),
        storedProcedures: selectSortedStoredProcedures(s),
        pipelines: selectSchemaSortedPipelines(s),
        pipelinesSort: selectSchemaPipelinesSort(s),
        partitions: selectSortedPartitions(s),
        partitionsSort: selectPartitionsSort(s),
        userDefinedFunctions: selectSortedUDFs(s),
        aggregates: selectSortedUDAs(s),
        derivedDatabase: selectCurrentDerivedDatabase(s),
    })
)(withRoute(TablesPage));
