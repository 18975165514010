import _ from "lodash";
import BigNumber from "vendor/bignumber.js/bignumber";

import distanceInWords from "date-fns/distance_in_words";
import addMilliseconds from "date-fns/add_milliseconds";

import { BigNumberUtils } from "./bignumber";

const KILOBYTE = 1024;
const MEGABYTE = 1024 * 1024; // mebibyte
const GIGABYTE = 1024 * 1024 * 1024; // gibibyte
const TERABYTE = 1024 * 1024 * 1024 * 1024; // tebibyte

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const TRILLION = 1000000000000;
const BILLION = 1000000000;
const MILLION = 1000000;
const THOUSAND = 1000;

export default class NumberFormatter {
    // Formats a number to the specified number of decimal places.
    static formatNumber(
        value: number | BigNumber,
        decimalPlaces: number = 2
    ): string {
        if (typeof value === "number") {
            value = new BigNumber(value);
        }

        return value.decimalPlaces(decimalPlaces).toFormat();
    }

    // Converts an integer into its most compact representation. Decimal precision is ignored for all integers, n, such that abs(n) < 1000.
    static compactInteger(
        input: number | BigNumber,
        signed?: boolean,
        decimalPlaces?: number
    ): string {
        if (typeof decimalPlaces === "undefined") {
            decimalPlaces = 2;
        }

        if (typeof input === "number") {
            input = new BigNumber(input);
        }

        let sign = "";
        if (signed || input.lt(0)) {
            if (input.gte(0)) {
                sign = "+";
            } else {
                // Since we are manually adding the negative sign for negative
                // numbers, we make the actual number positive.
                input = input.negated();
                sign = "−";
            }
        }

        let formatted;
        if (input.gte(TRILLION)) {
            formatted = `${input
                .dividedBy(TRILLION)
                .decimalPlaces(decimalPlaces)}T`;
        } else if (input.gte(BILLION)) {
            formatted = `${input
                .dividedBy(BILLION)
                .decimalPlaces(decimalPlaces)}B`;
        } else if (input.gte(MILLION)) {
            formatted = `${input
                .dividedBy(MILLION)
                .decimalPlaces(decimalPlaces)}M`;
        } else if (input.gte(THOUSAND)) {
            formatted = `${input
                .dividedBy(THOUSAND)
                .decimalPlaces(decimalPlaces)}K`;
        } else {
            formatted = `${input.decimalPlaces(decimalPlaces)}`;
        }

        return `${sign}${formatted}`;
    }

    /*
        Convert a given number of bytes into a string in an appropriate unit
        based on magnitude.

        This function returns units like KB and MB and GB - however the values
        are computed using a binary base (1024 rather than 1000).

        The most common cases are optimized.

        Example:

        NumberFormatter.formatBytes(1234567)
        Returns: "1 MB"
     */
    static formatBytes(bytes: BigNumber | number) {
        if (typeof bytes === "number") {
            bytes = new BigNumber(bytes);
        }

        if (bytes.isZero()) {
            return "0 B";
        } else if (bytes.lt(KILOBYTE)) {
            return `${Math.round(bytes.toNumber())} B`;
        } else if (bytes.lt(MEGABYTE)) {
            return `${Math.round(bytes.toNumber() / KILOBYTE)} KB`;
        } else if (bytes.lt(GIGABYTE)) {
            return `${Math.round(bytes.toNumber() / MEGABYTE)} MB`;
        } else if (bytes.lt(TERABYTE)) {
            return `${Math.round(bytes.toNumber() / GIGABYTE)} GB`;
        } else {
            return `${BigNumberUtils.roundBigNumber(
                bytes.dividedBy(TERABYTE)
            )} TB`;
        }
    }

    /*
         Formats a duration in milliseconds to a human readable string,
         maintaining a high degree of accuracy. Use in place of
         distanceInWords() if the value is often < 5 seconds.

         Returns `0 ms` if the number is negative or not a finite number.
     */
    static formatDuration(numMs: number | BigNumber): string {
        if (typeof numMs === "number") {
            numMs = new BigNumber(numMs);
        }

        if (numMs.lte(0)) {
            return "0 ms";
        } else if (numMs.lt(SECOND)) {
            return `${NumberFormatter.formatNumber(numMs)} ms`;
        } else if (numMs.lt(MINUTE)) {
            // less than a min - format as X.XX sec
            const numSec = NumberFormatter.formatNumber(
                numMs.dividedBy(SECOND)
            );
            return `${numSec} sec`;
        } else if (numMs.lt(HOUR)) {
            // less than a hour - format as X.XX min
            const numMin = NumberFormatter.formatNumber(
                numMs.dividedBy(MINUTE)
            );
            return `${numMin} min`;
        } else if (numMs.lt(DAY)) {
            // less than a day - format as X.XX hours
            const numHours = NumberFormatter.formatNumber(
                numMs.dividedBy(HOUR)
            );
            return `${numHours} hours`;
        } else {
            // an hour or more - let date-fns handle it
            return distanceInWords(
                new Date(),
                addMilliseconds(new Date(), numMs.toNumber())
            );
        }
    }

    static formatPercent(value: number | BigNumber): string {
        if (typeof value === "number") {
            value = new BigNumber(value);
        }

        return `${NumberFormatter.formatNumber(value.multipliedBy(100))}%`;
    }

    static formatRateS = (metric: BigNumber) => {
        const formattedCompactMetric = NumberFormatter.compactInteger(
            metric,
            false,
            0
        );
        return `${formattedCompactMetric} / sec`;
    };
}
