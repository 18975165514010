import { Maybe } from "util/maybe";
import { State, DispatchFunction } from "data";
import { TableLikeSamplePayload } from "data/actions";
import { DatabaseName, TableName } from "data/models";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import SuperTable from "view/components/super-table";
import Loading from "view/components/loading";
import GeneralError from "view/common/general-error";

import { selectSampleData, selectSampleDataError } from "data/selectors/schema";

import { queryTableLikeSample } from "worker/api/schema";
import { clearTableLikeSample } from "data/actions";

import "./sample-data-tab.scss";

type StateProps = {
    sampleData: Maybe<TableLikeSamplePayload>;
    sampleDataError: Maybe<{ message: string }>;
};

type Props = {
    entityKind: "VIEW" | "TABLE";
    dispatch: DispatchFunction;
    databaseName: DatabaseName;
    tableName: TableName;
};

class SampleDataTab extends React.Component<Props & StateProps> {
    componentDidMount() {
        const { dispatch, databaseName, tableName } = this.props;

        dispatch(
            queryTableLikeSample({
                databaseName,
                tableName,
            })
        );
    }

    componentWillUnmount() {
        this.props.dispatch(clearTableLikeSample());
    }

    render() {
        const { sampleData, sampleDataError, entityKind } = this.props;

        let inner: React.ReactNode = null;
        if (sampleData) {
            const { sampleRows, fields } = sampleData;

            if (sampleRows.length === 0) {
                if (entityKind === "TABLE") {
                    inner = (
                        <div className="sample-data-no-rows">
                            This table has no rows.
                        </div>
                    );
                } else {
                    inner = (
                        <div className="sample-data-no-rows">
                            This view has no rows.
                        </div>
                    );
                }
            } else {
                const columns = _.map(fields, field => ({
                    id: field.name,
                    title: field.name,
                    minWidth: 100,
                }));

                const rows = _.map(sampleRows, (row: Array<string>) => ({
                    cells: row,
                }));

                inner = (
                    <>
                        <SuperTable
                            className="sample-data-table"
                            columns={columns}
                            rows={rows}
                            rowHeight={40}
                        />
                    </>
                );
            }
        } else if (sampleDataError) {
            inner = (
                <GeneralError
                    errorHeader="Could not fetch sample data."
                    error={sampleDataError.message}
                />
            );
        } else {
            inner = <Loading size="large" />;
        }

        return <div className="sample-data-tab">{inner}</div>;
    }
}

export default connect(
    (s: State): StateProps => ({
        sampleData: selectSampleData(s),
        sampleDataError: selectSampleDataError(s),
    })
)(SampleDataTab);
