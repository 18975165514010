import { Maybe } from "util/maybe";

import * as React from "react";
import _ from "lodash";

import distanceInWords from "date-fns/distance_in_words";
import differenceInMilliseconds from "date-fns/difference_in_milliseconds";

type Props = {
    date: Date;
    includeSeconds: boolean;

    // forceFuture will ensure that this component only returns strings
    // representing time in the future. If you pass in a date in the past this
    // component will return "soon"
    forceFuture?: boolean;

    // This prop should be a function that returns a React.ReactNode representing
    // the different in time between the first and second date passed to this
    // function. It defaults to `distanceInWords` from `date-fns`.
    format: (first: Date, second: Date) => React.ReactNode;
};

type State = {
    timeDelta: React.ReactNode;
};

// This component renders a string like "less than a minute ago" or "in 7
// minutes". The formatting of the delta itself can be configured uing the
// `format` prop.
//
// By default, it uses `distanceInWords` from dateFns to produce the string.
//
// This component will recompute itself every second and update accordingly.
export default class TimeDelta extends React.PureComponent<Props, State> {
    tickerId: Maybe<number>;

    static defaultProps = {
        includeSeconds: false,
        format: _.partialRight(distanceInWords, { addSuffix: true }),
    };

    componentWillMount() {
        this.update();
        this.tickerId = window.setInterval(this.update, 1000);
    }

    componentWillReceiveProps(nextProps: Props) {
        this.update(nextProps);
    }

    componentWillUnmount() {
        window.clearInterval(this.tickerId);
    }

    update = (props?: Props) => {
        const { date, forceFuture, format } = props || this.props;
        const now = new Date();

        let timeDelta;
        if (forceFuture && differenceInMilliseconds(date, now) < 0) {
            timeDelta = "soon";
        } else {
            timeDelta = format(now, date);
        }

        this.setState({ timeDelta });
    };

    render() {
        return this.state.timeDelta;
    }
}
