import { Level } from "view/util/level";
import {
    ColumnLike,
    DerivedDatabase,
    DatabaseSummary,
    DataTypeCategory,
} from "data/models";

import { _formatPartitionStatus } from "view/common/models/cluster-metadata";
import { DATA_TYPE_TO_CATEGORY } from "data/models/schema";

const COLUMN_CATEGORY_ICON: { [category in DataTypeCategory]: string } = {
    number: "hashtag",
    datetime: "calendar-alt",
    string: "font",
    json: "json",
    geospatial: "globe",
    enum: "ballot-check",
};

export function formatDatabaseStatus(database: DerivedDatabase) {
    return _formatPartitionStatus(
        database.derived.status,
        database.derived.impacted
    );
}

export function getDatabaseStatusLevel(database: DatabaseSummary): Level {
    if (database.status === "online" && !database.impacted) {
        return "success";
    } else if (
        database.status === "offline_recovering" ||
        database.status === "offline"
    ) {
        return "error";
    } else {
        return "warning";
    }
}

export function getColumnIcon(column: ColumnLike): string {
    return (
        COLUMN_CATEGORY_ICON[DATA_TYPE_TO_CATEGORY[column.dataType]] ||
        "question"
    );
}
