import { Maybe } from "util/maybe";

import { State } from "data";
import { Pipeline } from "data/models";
import { State as RouteState } from "router5";
import { AlertStatus } from "view/components/alert";
import { CircleIconName } from "view/components/circle-icon";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import classnames from "classnames";

import { DashboardCard, MetricsBlock, Block } from "view/common/dashboard-card";

import Icon from "view/components/icon";
import SectionHeader from "view/components/section-header";
import CircleIcon from "view/components/circle-icon";
import { FakeClick } from "view/common/fake-click";

import SvgIngest from "view/components/svg/ingest.inline.svg";
import { plural } from "util/string";

import {
    selectPipelines,
    selectPipelinesError,
    selectPipelinesLoading,
} from "data/selectors/pipelines";
import { formatBatchTimestamp } from "view/common/models/pipelines";
import { selectRoute } from "data/selectors/routes";

import "./pipelines-card.scss";

type PipelineSummaryProps = {
    pipeline: Pipeline;
};

class PipelineSummary extends React.Component<PipelineSummaryProps> {
    render() {
        const {
            pipeline: {
                lastBatch,
                state,
                sourceType,
                pipelineName,
                numFailedBatches,
            },
        } = this.props;

        let details;
        let statusIcon;
        switch (state) {
            case "Running":
                statusIcon = <Icon size="xs" icon="circle" success />;

                if (lastBatch) {
                    let batchState;
                    if (lastBatch.state === "Succeeded") {
                        batchState = "loaded";
                    } else if (lastBatch.state === "Failed") {
                        batchState = "failed";
                    } else if (lastBatch.state === "Canceled") {
                        batchState = "canceled";
                    } else {
                        // We only read successful, failed or canceled batches from
                        // information_schema (we ignore In Progress and No Data
                        // batches), so the following line is just an escape
                        // hatch in case we ever change the backend but don't
                        // update the frontend.
                        batchState = "processed";
                    }

                    const detailsClasses = classnames("details", {
                        errored: lastBatch.state === "Failed",
                        canceled: lastBatch.state === "Canceled",
                    });

                    let lastBatchText;
                    if (lastBatch.timestamp) {
                        lastBatchText = `Last batch ${batchState} at ${formatBatchTimestamp(
                            lastBatch
                        )}`;
                    } else {
                        lastBatchText = `Last batch ${batchState}`;
                    }

                    details = (
                        <div className={detailsClasses}>{lastBatchText}</div>
                    );
                }

                break;

            case "Stopped":
                statusIcon = <Icon size="xs" icon="circle" warning />;
                details = <div className="details stopped">Stopped</div>;

                break;

            case "Error":
                statusIcon = <Icon size="xs" icon="circle" error />;

                details = (
                    <div className="details errored">
                        {numFailedBatches.toString()}{" "}
                        {plural("Error", numFailedBatches)}
                    </div>
                );

                break;
        }

        let comma;
        if (details) {
            comma = ", ";
        }

        let iconName: CircleIconName;
        let sourceTypeView;
        switch (sourceType) {
            case "KAFKA":
                iconName = "kafka";
                sourceTypeView = "Kafka";
                break;

            case "S3":
                iconName = "s3";
                sourceTypeView = "S3";
                break;

            case "AZURE":
                iconName = "azureblob";
                sourceTypeView = "Azure Blob Storage";
                break;

            case "FS":
                iconName = "filesystem";
                sourceTypeView = "Filesystem";
                break;

            case "HDFS":
                iconName = "hdfs";
                sourceTypeView = "HDFS";
                break;

            default:
                iconName = "empty";
        }

        return (
            <div className="pipeline-summary">
                <CircleIcon
                    statusIcon={statusIcon}
                    name={iconName}
                    size="small"
                />
                <div className="info">
                    <div className="pipeline-name">{pipelineName}</div>
                    <div className="pipeline-details">
                        {details}
                        {comma}
                        {sourceTypeView}
                    </div>
                </div>
            </div>
        );
    }
}

type StateProps = {
    loading: boolean;
    pipelines: Maybe<Array<Pipeline>>;
    error: Maybe<string>;
    route: RouteState;
};

type PipelinesCardProps = StateProps & {
    className?: string;
};

class PipelinesCard extends React.Component<PipelinesCardProps> {
    renderStatusBlock = () => {
        const { pipelines } = this.props;

        if (!pipelines) {
            throw new Error("Expected pipelines to be defined");
        }

        const numPipelines = pipelines.length;
        const title = `${numPipelines} ${plural("Pipeline", numPipelines)}`;

        let label;
        let status: AlertStatus;

        const pipelineStateCounts = _.countBy(
            pipelines,
            pipeline => pipeline.state
        );

        const numErrored = pipelineStateCounts["Error"] || 0;
        const numStopped = pipelineStateCounts["Stopped"] || 0;

        if (numErrored > 0) {
            label = (
                <>
                    <Icon icon="exclamation-triangle" rightMargin error />
                    {numErrored} Critical
                </>
            );

            status = "error";
        } else if (numStopped > 0) {
            label = (
                <>
                    <Icon icon="exclamation-circle" rightMargin warning />
                    {numStopped} Stopped
                </>
            );

            status = "warning";
        } else {
            label = (
                <>
                    <Icon icon="check-circle" rightMargin success />
                    Running
                </>
            );

            status = "success";
        }

        return <Block title={title} label={label} status={status} fullWidth />;
    };

    renderContent = (): Maybe<React.ReactNode> => {
        const { loading, pipelines } = this.props;

        if (loading) {
            return;
        }

        if (!pipelines) {
            throw new Error("Expected pipelines to be defined");
        }

        const recentPipelines = _(pipelines)
            .filter((p: Pipeline) => p.lastBatch)
            .orderBy(
                [(p: Pipeline) => p.lastBatch && p.lastBatch.timestamp],
                "desc"
            )
            .take(3)
            .value();

        let recentPipelinesView: React.ReactNode = "—";
        if (recentPipelines.length > 0) {
            recentPipelinesView = _.map(
                recentPipelines,
                (pipeline: Pipeline) => (
                    <PipelineSummary
                        pipeline={pipeline}
                        key={pipeline.pipelineId}
                    />
                )
            );
        }

        return (
            <div className="pipelines-card-content">
                <MetricsBlock>{this.renderStatusBlock()}</MetricsBlock>

                <FakeClick name="pipelines-recent-status">
                    <SectionHeader>Recent Pipeline Status</SectionHeader>

                    {recentPipelinesView}
                </FakeClick>
            </div>
        );
    };

    render() {
        const {
            loading,
            pipelines,
            error,
            className,
            route: {
                params: { clusterId },
            },
        } = this.props;

        let content;

        if (!loading && pipelines && pipelines.length > 0) {
            content = this.renderContent();
        }

        return (
            <DashboardCard
                loading={loading}
                content={content}
                pageRouteInfo={{
                    name: "cluster.pipelines",
                    params: { clusterId },
                }}
                icon={SvgIngest}
                emptyHelpLink={{
                    name: "create-pipeline",
                    category: "dashboard",
                }}
                error={error}
                hasEmptyState
                emptyTitle="No pipelines"
                emptyDescription="There are no pipelines in your cluster."
                title="Pipelines"
                docsRouteInfo={{
                    name: "pipelines-overview",
                    category: "dashboard",
                }}
                className={className}
            />
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        loading: selectPipelinesLoading(s),
        pipelines: selectPipelines(s),
        error: selectPipelinesError(s),
        route: selectRoute(s),
    })
)(PipelinesCard);
