import { Maybe } from "util/maybe";

import { State, DispatchFunction } from "data";
import { LicenseInfo, formatLicenseType } from "data/models";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import Header from "view/common/header";
import { Button } from "view/common/button";
import CircleIcon from "view/components/circle-icon";
import LastUpdated from "view/components/last-updated";
import ExtLink from "view/components/external-link";
import SectionHeader from "view/components/section-header";
import { InfoCard, Highlight } from "view/common/info-card";
import { FakeClick } from "view/common/fake-click";

import {
    renderLicenseUsageHelp,
    renderLicenseUsage,
} from "view/common/license-usage";
import { getLicenseExpirationText } from "view/common/models/license";

import { queryLicense } from "worker/api/license";
import {
    queryLatestMemsqlVersion,
    queryLatestStudioVersion,
} from "worker/api/remote-version";
import {
    selectLicenseLoading,
    selectLicenseInfo,
    selectLicenseError,
    selectLicenseLastUpdate,
} from "data/selectors/license";

import DatabaseCard from "view/about/database-card";
import StudioCard from "view/about/studio-card";

import * as analytics from "util/segment";

import "./index.scss";

type StateProps = {
    loading: boolean;
    error: Maybe<string>;
    licenseInfo: Maybe<LicenseInfo>;
    lastUpdate: Maybe<Date>;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

type LicenseDetails = Array<{
    left: React.ReactNode;
    right: React.ReactNode;
}>;

class AboutPage extends React.Component<Props> {
    // `handleReload` fetches everything that is needed for the entire About
    // page. This way, when the About Page reload button is called everything is
    // reloaded.
    handleReload = () => {
        // queryMemsqlVersion is being dispatched on app load
        this.props.dispatch(queryLicense());
        this.props.dispatch(queryLatestMemsqlVersion());
        this.props.dispatch(queryLatestStudioVersion());
    };

    componentDidMount() {
        this.handleReload();
    }

    handleClickReload = () => {
        this.handleReload();

        analytics.trackReload("about");
    };

    getLicenseDetails = (licenseInfo: LicenseInfo): LicenseDetails => {
        const licenseDetails: LicenseDetails = [];

        if (_.includes(["enterprise", "free"], licenseInfo.type)) {
            licenseDetails.push({
                left: "Usage",
                right: (
                    <>
                        {renderLicenseUsage(licenseInfo)}
                        {renderLicenseUsageHelp({
                            licenseInfo,
                            category: "about-page",
                        })}
                    </>
                ),
            });
        } else if (_.includes(["developer"], licenseInfo.type)) {
            licenseDetails.push({
                left: "Restrictions",
                right:
                    "Not for production use cases and High Availibility is disabled.",
            });
        }

        licenseDetails.push({
            left: "Expiration",
            right: getLicenseExpirationText(licenseInfo),
        });

        return licenseDetails;
    };

    renderLicenseCardContent = (licenseInfo: LicenseInfo) => {
        const licenseDetails = this.getLicenseDetails(licenseInfo);

        return (
            <div className="license-card-content">
                <FakeClick name="license-current">
                    <Highlight>
                        <CircleIcon
                            iconProps={{ icon: "building" }}
                            className="license-icon"
                            size="small"
                        />

                        <div className="license-highlight-details">
                            <div className="license-heading">
                                Current License
                            </div>
                            <div className="license-type">
                                {formatLicenseType(licenseInfo.type)}
                            </div>
                        </div>
                    </Highlight>
                </FakeClick>

                <SectionHeader>Details</SectionHeader>

                <div className="license-details">
                    {_.map(licenseDetails, (detail, idx: number) => (
                        <FakeClick
                            name={`license-detail-${detail.left}`}
                            key={idx}
                        >
                            <div className="license-detail" key={idx}>
                                <div className="left">{detail.left}:</div>
                                <div className="right">{detail.right}</div>
                            </div>
                        </FakeClick>
                    ))}
                </div>

                <ExtLink name="terms-conditions" category="about-page">
                    Terms and Conditions
                </ExtLink>
            </div>
        );
    };

    render() {
        const { error, loading, lastUpdate, licenseInfo } = this.props;

        let lastUpdated;
        if (lastUpdate) {
            lastUpdated = (
                <span className="last-updated">
                    <LastUpdated date={lastUpdate} />
                </span>
            );
        }

        const actions = (
            <>
                {lastUpdated}

                <Button
                    disabled={loading}
                    small
                    icon="sync-alt"
                    onClick={this.handleClickReload}
                />
            </>
        );

        let licenseCard;
        if (licenseInfo) {
            const licenseCardContent = this.renderLicenseCardContent(
                licenseInfo
            );

            licenseCard = (
                <InfoCard
                    title="Licensing"
                    description="This cluster's MemSQL license"
                    helpLink={{
                        name: "licensing",
                        category: "about-page",
                    }}
                    content={licenseCardContent}
                    loading={loading}
                    error={error}
                    hasEmptyState={false}
                />
            );
        }

        return (
            <div className="about-page">
                <Header
                    className="about-header"
                    left="About MemSQL — The No-Limits Database™"
                    right={actions}
                />

                <div className="cards">
                    <DatabaseCard />
                    {licenseCard}
                    <StudioCard />
                </div>
            </div>
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        loading: selectLicenseLoading(s),
        licenseInfo: selectLicenseInfo(s),
        error: selectLicenseError(s),
        lastUpdate: selectLicenseLastUpdate(s),
    })
)(AboutPage);
