import { TableSort } from "util/sort";
import { DispatchFunction, GetStateFunction } from "data";
import { SimpleAction } from "./types";
import { CursorInfo } from "data/models";
import { QueryGroupRepr } from "worker/net/query-executor";
import { ClusterId } from "data/models";
import { EditorSectionTab } from "data/reducers/query-editor";

import _ from "lodash";
import { actions as routerActions } from "redux-router5";

import { staticQueryGroup } from "worker/api/connection";
import {
    selectActiveQueryRange,
    selectEditorCanRunQuery,
    selectEditorCanRunQueryInNewTab,
    selectResultsToExport,
} from "data/selectors/page-editor";

import { extractQueries } from "util/sql-query";
import { logError } from "util/logging";
import saveData from "util/save-data";
import stringifyCsv from "csv-stringify/lib/es5/sync";
import { setStudioConfig } from "util/studio-config";
import { AbsoluteRange } from "util/range";
import * as analytics from "util/segment";

export type ConnectQueryEditorAction = SimpleAction<"CONNECT_QUERY_EDITOR", {}>;

export const connectQueryEditor = (): ConnectQueryEditorAction => {
    return {
        type: "CONNECT_QUERY_EDITOR",
        error: false,
        payload: {},
    };
};

export type EditBufferAction = SimpleAction<
    "QUERY_EDITOR_EDIT_BUFFER",
    { buffer: string }
>;

const updateLocalStorageBuffer = _.debounce(
    (buffer: string, clusterId: ClusterId) => {
        setStudioConfig(oldConfig => ({
            clusters: {
                ...oldConfig.clusters,
                [clusterId]: {
                    ...oldConfig.clusters[clusterId],
                    queryEditor: {
                        buffer,
                    },
                },
            },
        }));
    },
    1000
);

export const editBuffer = (payload: { buffer: string }) => {
    return (dispatch: DispatchFunction, getState: GetStateFunction) => {
        const { config } = getState().connection.state;

        if (config) {
            updateLocalStorageBuffer(payload.buffer, config.clusterId);
        }

        dispatch({
            type: "QUERY_EDITOR_EDIT_BUFFER",
            error: false,
            payload,
        });
    };
};

export type AppendAndFocusAction = SimpleAction<
    "QUERY_EDITOR_APPEND_AND_FOCUS",
    {
        buffer: string;
        cursorInfo: CursorInfo;
        focus: number;
    }
>;

export const appendText = (options: { text: string }) => {
    return (dispatch: DispatchFunction, getState: GetStateFunction) => {
        const { router, queryEditor } = getState();

        // if the amount of spacing added before and after the appended text is
        // changed, please update the calculations in view/common/sql-editor
        // updateSelectionAndFocus regarding highlighting the text
        const currBuffer = queryEditor.buffer;
        const spacing = currBuffer ? "\n\n" : "";

        const newBuffer = currBuffer.concat(spacing, options.text, "\n");

        const start = currBuffer.length + spacing.length;
        const end = currBuffer.length + spacing.length + options.text.length;

        if (router.route) {
            const clusterId = router.route.params.clusterId;
            dispatch(routerActions.navigateTo("cluster.editor", { clusterId }));
        }

        dispatch({
            type: "QUERY_EDITOR_APPEND_AND_FOCUS",
            error: false,
            payload: {
                focus: queryEditor.focus + 1,
                buffer: newBuffer,
                cursorInfo: {
                    index: start,
                    selectedRange: new AbsoluteRange(start, end),
                },
            },
        });
    };
};

export type ResetFocusCountAction = SimpleAction<
    "QUERY_EDITOR_RESET_FOCUS_COUNT",
    {}
>;

export type CursorInfoAction = SimpleAction<
    "QUERY_EDITOR_CURSOR_INFO",
    CursorInfo
>;

export const setCursorInfo = (payload: CursorInfo): CursorInfoAction => ({
    type: "QUERY_EDITOR_CURSOR_INFO",
    error: false,
    payload,
});

export type SortResultsAction = SimpleAction<"QUERY_EDITOR_SORT", TableSort>;

export const sortResults = (payload: TableSort): SortResultsAction => ({
    type: "QUERY_EDITOR_SORT",
    error: false,
    payload,
});

export const runQuery = (options?: { newTab: boolean }) => {
    const newTab = options ? options.newTab : false;

    return (dispatch: DispatchFunction, getState: GetStateFunction) => {
        const state = getState();
        const { buffer, connectionId } = state.queryEditor;
        const activeQueryRange = selectActiveQueryRange(state);
        const canRunQuery = newTab
            ? selectEditorCanRunQueryInNewTab(state)
            : selectEditorCanRunQuery(state);

        if (!canRunQuery) {
            return;
        }

        if (!activeQueryRange) {
            logError(
                new Error(
                    "activeQueryRange has to be defined if canRunQuery is true."
                )
            );

            return;
        }

        const { range, userSelected } = activeQueryRange;
        const queryText = range.extract(buffer);
        let queryTexts;
        if (userSelected) {
            queryTexts = extractQueries(queryText);
        } else {
            // A procedure might actually be a single query, and the parser
            // that parses the entire query editor contents into queries has
            // the context to figure out that it is a single query, but
            // parsing only the text of the procedure itself without context
            // may turn it into multiple queries. Thus, we don't want to
            // re-parse the text of individual parsed queries. Note that
            // this will still fail if the user manually selects the text of
            // a stored procedure, unfortunately.
            queryTexts = [queryText];
        }

        const queryGroup: QueryGroupRepr = {
            queries: queryTexts.map(query => ({
                text: query,
                values: [],
            })),
            onError: "PAUSE",
            limitAllButLast: true,
        };

        if (newTab) {
            dispatch(newEditorTab());
        }

        dispatch(staticQueryGroup({ id: connectionId, queryGroup }));
    };
};

export const saveResultsAsCSV = () => {
    return (dispatch: DispatchFunction, getState: GetStateFunction) => {
        const state = getState();

        const results = selectResultsToExport(state);

        if (results) {
            const csv = stringifyCsv([
                results.fields.map(field => field.name),
                ...results.rows,
            ]);

            saveData({ data: csv, baseName: "results", extension: "csv" });

            analytics.track("export-query-editor-results-csv");
        } else {
            throw new Error(
                "Expected results to be of type rows and to have been fully loaded."
            );
        }
    };
};

export type ChangeEditorHeightAction = SimpleAction<
    "QUERY_EDITOR_CHANGE_EDITOR_HEIGHT",
    {
        height: number;
    }
>;

export const changeEditorHeight = (payload: {
    height: number;
}): ChangeEditorHeightAction => ({
    type: "QUERY_EDITOR_CHANGE_EDITOR_HEIGHT",
    error: false,
    payload,
});

export type ChangeEditorWidthAction = SimpleAction<
    "QUERY_EDITOR_CHANGE_EDITOR_WIDTH",
    {
        width: number;
    }
>;

export const changeEditorWidth = (payload: {
    width: number;
}): ChangeEditorWidthAction => ({
    type: "QUERY_EDITOR_CHANGE_EDITOR_WIDTH",
    error: false,
    payload,
});

export type NewEditorTabAction = SimpleAction<"QUERY_EDITOR_NEW_TAB", {}>;

export const newEditorTab = (): NewEditorTabAction => ({
    type: "QUERY_EDITOR_NEW_TAB",
    error: false,
    payload: {},
});

export type CloseEditorTabAction = SimpleAction<
    "QUERY_EDITOR_CLOSE_TAB",
    { tabId: number }
>;

export const closeEditorTab = (payload: {
    tabId: number;
}): CloseEditorTabAction => ({
    type: "QUERY_EDITOR_CLOSE_TAB",
    error: false,
    payload,
});

export type ChangeEditorActiveTabAction = SimpleAction<
    "QUERY_EDITOR_CHANGE_ACTIVE_TAB",
    {
        activeTabId: number;
    }
>;

export const changeEditorActiveTab = (payload: {
    activeTabId: number;
}): ChangeEditorActiveTabAction => ({
    type: "QUERY_EDITOR_CHANGE_ACTIVE_TAB",
    error: false,
    payload,
});

export type ChangeEditorActiveSectionTabAction = SimpleAction<
    "QUERY_EDITOR_CHANGE_ACTIVE_SECTION_TAB",
    {
        activeTab: EditorSectionTab;
    }
>;

export const changeEditorActiveSectionTab = (payload: {
    activeTab: EditorSectionTab;
}): ChangeEditorActiveSectionTabAction => ({
    type: "QUERY_EDITOR_CHANGE_ACTIVE_SECTION_TAB",
    error: false,
    payload,
});
