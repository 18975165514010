import { Maybe } from "util/maybe";
import { Nullable } from "util/nullable";
import { ColumnID, DataType } from "data/models";

import BigNumber from "vendor/bignumber.js/bignumber";

import { Version } from "util/version";
import SqlString from "sqlstring";

const SQL_EXCLUDE_SYSTEM_DATABASES = (dbColumn: string) =>
    SqlString.format(`WHERE ${SqlString.escapeId(dbColumn)} NOT IN (?)`, [
        ["cluster", "memsql"],
    ]);

// We need to exclude system tables that are hidden in the output of:
// `SHOW TABLES IN information_schema EXTENDED`.
const SQL_EXCLUDE_SYSTEM_TABLES = (dbColumn: string, tableColumn: string) => {
    const escapedDb = SqlString.escapeId(dbColumn);
    const escapedTable = SqlString.escapeId(tableColumn);

    return SqlString.format(
        `WHERE ${escapedDb} != ? AND ${escapedTable} != ?`,
        ["INFORMATION_SCHEMA", "PERFORMANCE_METRICS"]
    );
};

export const SQL_STRUCTURE_DATABASES = `
    SELECT
        SCHEMA_NAME as databaseName
    FROM
        INFORMATION_SCHEMA.SCHEMATA
    ${SQL_EXCLUDE_SYSTEM_DATABASES("SCHEMA_NAME")}
    ORDER BY
        SCHEMA_NAME ASC
`;

export type SQLStructureDatabasesRow = { databaseName: string };

// We neeed to create an empty statistics object for the
// `information_schema` database and then join it with
// the rest of the database statistics' objects, while
// making sure it is properly sorted.
export const SQL_STATISTICS_DATABASE = `
    SELECT * FROM (
        SELECT
            'information_schema' AS databaseName,
            0 AS partitionCount,
            NULL AS syncRepl,
            "" AS remoteName
        UNION ALL
        SELECT
            DATABASE_NAME as databaseName,
            NUM_PARTITIONS AS partitionCount,
            IS_SYNC AS syncRepl,
            REMOTE_NAME AS remoteName
        FROM
            INFORMATION_SCHEMA.DISTRIBUTED_DATABASES
    ) dummy
    ORDER BY
        databaseName ASC;
`;

export type SQLStatisticsDatabase = {
    databaseName: string;
    partitionCount: BigNumber;
    syncRepl: Nullable<BigNumber>; // 0 or 1

    // Empty string if this database is not a DR replica.
    remoteName: string;
};

export const SQL_STRUCTURE_SHOW_TABLES = `
    SHOW TABLES IN ? EXTENDED
`;

// the table name column is called "Tables_in_${databaseName}"
export type SQLStructureShowTablesRow = { [key: string]: string } & {
    Table_type: Maybe<string>;
    distributed: 0 | 1;
    Storage_type: Maybe<string>;
};

// In order to get the table structure of the schema in a single query against
// information_schema, the cluster needs to be on version 6.7.11 of MemSQL or
// higher. Before that, we need to run `SHOW TABLES EXTENDED` against each
// database to get all the information we need.
export const SQL_STRUCTURE_TABLES_SINGLE_QUERY_MIN_VERSION = new Version([
    6,
    7,
    11,
]);

export const SQL_STRUCTURE_TABLES_SINGLE_QUERY = `
    SELECT
        t.table_schema AS databaseName,
        t.table_name AS tableName,
        ANY_VALUE(t.table_type) AS tableType,
        IFNULL(ANY_VALUE(s.partition_type) != "Reference", FALSE) AS isSharded,
        ANY_VALUE(s.storage_type) AS tableStorage
    FROM
        information_schema.tables AS t
        LEFT JOIN information_schema.table_statistics AS s
        ON t.table_schema = s.database_name AND t.table_name = s.table_name
    GROUP BY
        t.table_schema,
        t.table_name;
`;

export type SQLStructureTablesSingleQueryRow = {
    databaseName: string;
    tableName: string;
    tableType: string;
    isSharded: BigNumber; // 0 | 1
    tableStorage: Nullable<string>;
};

export const SQL_STRUCTURE_COLUMNS = `
    SELECT
        CONCAT(TABLE_SCHEMA, '.', TABLE_NAME, '.', COLUMN_NAME) AS columnId,
        TABLE_SCHEMA AS databaseName,
        TABLE_NAME AS tableName,
        COLUMN_NAME AS columnName,
        DATA_TYPE AS dataType,
        COLUMN_TYPE AS subType,
        ORDINAL_POSITION AS ordinalPosition,
        CASE
            WHEN extra LIKE '%computed%' THEN true
            ELSE false
        END as computed,
        COLUMN_DEFAULT as defaultValueSQL,
        IS_NULLABLE = "YES" as isNullable,
        CASE
            WHEN extra LIKE '%auto_increment%' THEN true
            ELSE false
        END as autoIncrement
    FROM
        INFORMATION_SCHEMA.COLUMNS
    ${SQL_EXCLUDE_SYSTEM_TABLES("TABLE_SCHEMA", "TABLE_NAME")}
    ORDER BY
        columnId ASC
`;

export type SQLStructureColumnsRow = {
    databaseName: string;
    tableName: string;
    columnName: string;
    columnId: ColumnID;
    dataType: DataType;
    subType: string;
    ordinalPosition: BigNumber;
    computed: BigNumber; // 0 or 1
    defaultValueSQL: Nullable<string>;
    isNullable: BigNumber; // 0 or 1
    autoIncrement: BigNumber; // 0 or 1
};

export const SQL_STATISTICS_COLUMNS_COLUMNAR = `
    SELECT
        CONCAT(DATABASE_NAME, '.', TABLE_NAME, '.', COLUMN_NAME) AS columnId,
        SUM(COMPRESSED_SIZE) AS compressedSize,
        SUM(UNCOMPRESSED_SIZE) AS uncompressedSize
    FROM
        INFORMATION_SCHEMA.COLUMNAR_SEGMENTS
    GROUP BY
        DATABASE_NAME,
        TABLE_NAME,
        COLUMN_NAME
    ORDER BY
        columnId ASC
`;

export type SQLStatisticsColumnsColumnar = {
    columnId: string;
    compressedSize: BigNumber;
    uncompressedSize: BigNumber;
};

export const SQL_STATISTICS_COLUMNS_MEMORY = `
    SELECT
        CONCAT(DATABASE_NAME, '.', TABLE_NAME, '.', COLUMN_NAME) AS columnId,
        SUM(MEMORY_USE) as memoryUse
    FROM
        INFORMATION_SCHEMA.COLUMN_STATISTICS
    ${SQL_EXCLUDE_SYSTEM_DATABASES("DATABASE_NAME")}
    GROUP BY
        DATABASE_NAME,
        TABLE_NAME,
        COLUMN_NAME
    ORDER BY
        columnId ASC
`;

export type SQLStatisticsColumnsMemory = {
    columnId: string;
    memoryUse: Nullable<BigNumber>;
};

export const SQL_STATISTICS_TABLE = `
    SELECT
        CONCAT(DATABASE_NAME, '.', TABLE_NAME) AS tableId,
        COUNT(*) AS numReplicas,
        SUM(IFNULL(MEMORY_USE, 0)) AS memoryUse,
        SUM(CASE WHEN PARTITION_TYPE != 'Slave' THEN IFNULL(ROWS, 0) ELSE 0 END) AS rowCount
    FROM
        INFORMATION_SCHEMA.TABLE_STATISTICS
    ${SQL_EXCLUDE_SYSTEM_DATABASES("DATABASE_NAME")}
    GROUP BY
        DATABASE_NAME,
        TABLE_NAME
    ORDER BY
        tableId ASC
`;

export type SQLStatisticsTable = {
    tableId: string;

    // This corresponds to the total memory usage of a table across
    // all Slave and Master partitions if HA is enabled. For reference
    // tables, it corresponds to the total memory usage of this table
    // across all MemSQL nodes where this reference table is replicated.
    memoryUse: BigNumber;

    // For reference tables, this corresponds to numReplicas * actual
    // row count of the table, since TABLE_STATISTICS returns one row
    // per MemSQL node where this reference table is replicated.
    rowCount: BigNumber;

    // Only makes sense for reference tables.
    numReplicas: BigNumber;
};

export const SQL_STATISTICS_INDEXES = `
    SELECT
        TABLE_SCHEMA as databaseName,
        STATISTICS.TABLE_NAME AS tableName,
        STATISTICS.INDEX_NAME AS indexName,
        SUM(MEMORY_USE) AS memoryUse,
        INDEX_TYPE AS indexType,
        COLUMN_NAME AS columnName,
        ANY_VALUE(NON_UNIQUE) AS nonUnique
    FROM
        information_schema.STATISTICS,
        information_schema.INDEX_STATISTICS
    WHERE
        STATISTICS.TABLE_SCHEMA = INDEX_STATISTICS.DATABASE_NAME
        AND STATISTICS.TABLE_NAME = INDEX_STATISTICS.TABLE_NAME
        AND STATISTICS.INDEX_NAME = INDEX_STATISTICS.INDEX_NAME
    GROUP BY
        STATISTICS.TABLE_SCHEMA,
        STATISTICS.TABLE_NAME,
        STATISTICS.INDEX_NAME,
        STATISTICS.COLUMN_NAME,
        STATISTICS.INDEX_TYPE
    ORDER BY
        ANY_VALUE(SEQ_IN_INDEX) ASC
`;

export type SQLStatisticsIndex = {
    databaseName: string;
    tableName: string;
    indexName: string;
    memoryUse: BigNumber;
    indexType: string;
    columnName: string;
    nonUnique: BigNumber; // 1 or 0
};

export const SQL_STATISTICS_INDEXES_COLUMNAR = `
    SELECT 
        DATABASE_NAME AS databaseName,
        TABLE_NAME AS tableName,
        INDEX_NAME AS indexName, 
        SUM(COMPRESSED_SIZE) AS totalSize 
    FROM 
        INFORMATION_SCHEMA.MV_COLUMNAR_SEGMENT_INDEX 
    GROUP BY
        DATABASE_NAME,
        TABLE_NAME,
        INDEX_NAME
`;

export type SQLStatisticsIndexColumnar = {
    databaseName: string;
    tableName: string;
    indexName: string;
    totalSize: BigNumber;
};

export const SQL_STATISTICS_HA = `
    SHOW VARIABLES LIKE 'redundancy_level'
`;

export type SQLStatisticsHA = {
    Value: string;
};

/** The following queries are the queries used for the SCHEMA_SUMMARY action. **/
export const SQL_TOTAL_MEMORY_USAGE = `
    SELECT
        IFNULL(SUM(MEMORY_USE), 0) AS memoryUsage
    FROM
        INFORMATION_SCHEMA.TABLE_STATISTICS;
`;

export type SQLTotalMemoryUsageRow = {
    memoryUsage: BigNumber;
};

export const SQL_DATABASE_DISK_USAGE = `
    SELECT
        DATABASE_NAME AS databaseName,
        SUM(COMPRESSED_SIZE) AS diskUsage
    FROM
        INFORMATION_SCHEMA.COLUMNAR_SEGMENTS
    GROUP BY
        DATABASE_NAME
    ORDER BY
        diskUsage DESC;
`;

export type SQLDatabaseDiskUsageRow = {
    databaseName: string;
    diskUsage: BigNumber;
};

export const SQL_TABLE_LIKE_SAMPLE = `
    SELECT
        *
    FROM
        ??.??
    LIMIT
        10
`;

export const SQL_STRUCTURE_FUNCTIONS = `
    SHOW FUNCTIONS IN ?
`;

// the function name column is called "Functions_in_${databaseName}"
export type SQLStructureFunctionRow = { [key: string]: string } & {
    "Function Type": string;
    Definer: string;
};

export const SQL_STRUCTURE_AGGREGATES = `
    SHOW AGGREGATES IN ?
`;

// the aggregate name column is called "Aggregates_in_${databaseName}"
export type SQLStructureAggregateRow = { [key: string]: string };

export const SQL_STRUCTURE_STORED_PROCEDURES = `
    SHOW PROCEDURES IN ?
`;

// The procedure name column is called "Procedures_in_${databaseName}" or called
// "Functions_in_${databaseName}" if MemSQL version is older than 6.7.11.
export type SQLStructureProceduresRow = { [key: string]: string } & {
    Definer: string;

    // The next column is only present on MemSQL versions prior to 6.7.11.
    "Function Type"?: string;
};

export const SQL_DATABASE_NAMES = `
    SELECT
        DATABASE_NAME AS databaseName
    FROM
        INFORMATION_SCHEMA.DISTRIBUTED_DATABASES
`;

export type SQLDatabaseNameRow = {
    databaseName: string;
};
