import * as React from "react";
import classnames from "classnames";

import "./simple-modal-body.scss";

type Props = {
    title: React.ReactNode;
    description: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
};

// Just a component that looks nice as the child of a Modal.

export default class SimpleModalBody extends React.Component<Props> {
    render() {
        const { title, description, children, className } = this.props;

        const classes = classnames("simple-modal-body", className);

        return (
            <div className={classes}>
                <div className="title">{title}</div>
                <div className="description">{description}</div>
                {children}
            </div>
        );
    }
}
