
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import TutorialLoadSQLButton from "view/tutorial/tutorial-load-sql-button";
import { SQL_BOOKS_CREATE_DATABASE } from "./books-queries";

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h4" components={components}>{`Step 1: Create a Database and Table`}</MDXTag>
<MDXTag name="p" components={components}>{`Create a new database and a table that adheres to the schema contained in the file you plan to ingest. For this example, we will create a database `}<MDXTag name="strong" components={components} parentName="p">{`books`}</MDXTag>{` and a table `}<MDXTag name="strong" components={components} parentName="p">{`classic books`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`This is just a sample. You should create a database name and table name and attributes corresponding to the data you plan to ingest.`}</MDXTag></MDXTag>
<TutorialLoadSQLButton id="books-create-tables" query={SQL_BOOKS_CREATE_DATABASE} text="Paste Create queries" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
<MDXTag name="p" components={components}>{`These statements create a new database named `}<MDXTag name="inlineCode" components={components} parentName="p">{`books`}</MDXTag>{` and a new table named `}<MDXTag name="inlineCode" components={components} parentName="p">{`classic_books`}</MDXTag>{`, which has three columns: `}<MDXTag name="inlineCode" components={components} parentName="p">{`title`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="p">{`author`}</MDXTag>{`, and `}<MDXTag name="inlineCode" components={components} parentName="p">{`date`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}>{`Now that the destination database and table have been created, you can create an S3 pipeline.`}</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  