import { Maybe } from "util/maybe";
import { TableSort } from "util/sort";
import { ActiveProcess } from "data/models";
import { State } from "data";

import _ from "lodash";
import { createSelector } from "reselect";

import { ActiveProcessId } from "data/models";
import { ACTIVE_PROCESSES_COLUMNS } from "view/active-processes/columns-info";

import { selectPayload } from "util/loading-state-machine";

export const selectActiveProcesses = (s: State) =>
    selectPayload(s.activeProcesses.activeProcesses);

export const selectSort = (state: State): TableSort =>
    state.activeProcesses.sort;

export const selectSortedActiveProcesses = createSelector(
    selectActiveProcesses,
    selectSort,
    (
        processes: Maybe<Array<ActiveProcess>>,
        sort: TableSort
    ): Maybe<Array<ActiveProcess>> => {
        if (sort && processes) {
            const { direction, columnId } = sort;

            const columnInfo = _.find(
                ACTIVE_PROCESSES_COLUMNS,
                col => col.id === columnId
            );

            if (!columnInfo) {
                throw new Error(
                    "Expected column to be defined when looking for active process sort information."
                );
            }

            return _.orderBy<ActiveProcess>(
                processes,
                [columnInfo.getValue],
                direction
            );
        }

        return processes;
    }
);

export const selectSelectedActiveProcessIds = (
    state: State
): Array<ActiveProcessId> => state.activeProcesses.selectedProcessIds;

export const selectKilledActiveProcessIds = (
    state: State
): Array<ActiveProcessId> => state.activeProcesses.killedProcessIds;

export const selectCanExplainActiveProcess = createSelector(
    selectSelectedActiveProcessIds,
    selectedIds => selectedIds.length === 1
);
