import { IconProps } from "view/components/icon";
import { ExternalLinkInfo } from "view/components/external-link";

import * as React from "react";

import Icon from "view/components/icon";
import ExtLink from "view/components/external-link";

type Props = {
    className?: string;
    iconProps?: Partial<IconProps>;
    iconName: string;
    link: ExternalLinkInfo;
};

const IconLink = ({ className, iconProps, iconName, link }: Props) => (
    <ExtLink {...link}>
        <Icon className={className} icon={iconName} {...iconProps} />
    </ExtLink>
);

IconLink.defaultProps = {
    iconName: "info-circle",
};

export default IconLink;
