import { CustomScrollbarRef } from "view/components/custom-scrollbar";

import _ from "lodash";
import * as React from "react";
import classnames from "classnames";

import { CustomScrollbar } from "view/components/custom-scrollbar";

import Condition from "view/components/condition";

import "./fullscreen.scss";

type Props = {
    children: React.ReactNode;
    className?: string;

    // The contents of the action bar at the bottom of the screen
    actions?: React.ReactNode;
};

export default class FullScreen extends React.Component<Props> {
    $scrollbar: React.RefObject<CustomScrollbarRef> = React.createRef();

    constructor(props: Props) {
        super(props);
        this.handleAnimationEnd = _.debounce(this.handleAnimationEnd, 100);
    }

    handleAnimationEnd = () => {
        // Because the content animation changes the content's height, the
        // height that the Scrollbars used to render will be out of date after
        // the animation, so trigger a rerender with a Resize event.
        window.dispatchEvent(new Event("resize"));
    };

    scrollToTop() {
        if (this.$scrollbar.current) {
            this.$scrollbar.current.scrollToTop();
        }
    }

    render() {
        const { children, actions, className } = this.props;

        const classes = classnames("components-fullscreen", className);

        return (
            <div className={classes}>
                <div className="fullscreen-inner">
                    <CustomScrollbar
                        className="fullscreen-scroll"
                        ref={this.$scrollbar}
                    >
                        <div className="fullscreen-content">
                            <div
                                className="fullscreen-content-inner"
                                onAnimationEnd={this.handleAnimationEnd}
                            >
                                {children}
                            </div>
                        </div>
                    </CustomScrollbar>

                    <Condition check={!!actions}>
                        <div className="fullscreen-actions">
                            <div className="fullscreen-actions-inner">
                                {actions}
                            </div>
                        </div>
                    </Condition>
                </div>
            </div>
        );
    }
}
