import { TableSort } from "util/sort";
import { EventsAction, SortEventsAction } from "data/actions";

import { MemsqlEvent } from "data/models";

type Actions = EventsAction | SortEventsAction;

type InitialState = {
    state: "initial";
};

type LoadingState = {
    state: "loading";
};

type SuccessState = {
    state: "success";

    // these are stored in descending order by eventTime
    events: Array<MemsqlEvent>;
    lastUpdate: Date;

    sort: TableSort;
};

type ErrorState = {
    state: "error";

    error: string;
};

export type EventsState =
    | InitialState
    | LoadingState
    | SuccessState
    | ErrorState;

const initialState: EventsState = {
    state: "initial",
};

export default (state: EventsState = initialState, action: Actions) => {
    switch (action.type) {
        case "EVENTS":
            {
                if (action.error) {
                    state = {
                        state: "error",
                        error: action.payload.message,
                    };
                } else if (action.payload.loading) {
                    state = {
                        state: "loading",
                    };
                } else {
                    state = {
                        state: "success",

                        events: action.payload.data.events,
                        lastUpdate: new Date(),
                        sort: undefined,
                    };
                }
            }

            break;

        case "SORT_EVENTS": {
            if (state.state !== "success") {
                throw new Error("Expected events store's state to be success.");
            }

            state = {
                ...state,
                sort: action.payload,
            };

            break;
        }
    }

    return state;
};
