import { Batch } from "data/models/pipelines";

import { format } from "date-fns";
import { LONG_EM_DASH } from "util/symbols";

export function formatBatchTimestamp(batch: Batch) {
    if (batch.timestamp) {
        return format(batch.timestamp, "HH:mm:ss, YYYY/MM/DD");
    } else {
        return LONG_EM_DASH;
    }
}
