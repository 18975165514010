import { Maybe } from "util/maybe";

import { MvFullRepr } from "data/actions/management-views";

import _ from "lodash";

import { jsonReplacer } from "util/json";
import saveData from "util/save-data";

export const saveMvData = (data: MvFullRepr) => {
    saveData({ data: JSON.stringify(data, jsonReplacer), baseName: "mv-data" });
};

// This is not super comprehensive but it should cover some basic CI's that
// users can run into.
export const validateMvDataFile = (mvData: any): Maybe<string> => {
    if (!_.isPlainObject(mvData)) {
        return "Not a valid JSON object";
    }

    // mvData can be safely assumed to be an Object

    const activities = mvData.activities;
    const nodes = mvData.nodes;
    const advancedCounters = mvData.advancedCounters;

    if (!_.isArray(activities) || activities.length === 0) {
        return "No activities found";
    } else if (!_.isObject(nodes) || _.size(nodes) === 0) {
        return "No nodes found";
    } else if (!_.isBoolean(advancedCounters)) {
        return "No advanced counters found";
    }

    // implicit undefined return which implies no errors
};
