import { Maybe } from "util/maybe";

import { State, DispatchFunction } from "data";
import { InjectedRoute } from "react-router5";
import { DerivedPartition, PartitionInstance } from "data/models";
import { SortDirection, TableSort } from "util/sort";
import { ColumnId } from "view/components/super-table";
import { AlertStatus } from "view/components/alert";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { withRoute } from "react-router5";

import Card from "view/components/card";
import {
    TopContent,
    TopContentRow,
    TopContentHeader,
} from "view/components/top-content";
import GeneralTable from "view/components/general-table";
import { Alert } from "view/components/alert";
import IconTip from "view/components/icon-tip";
import { TableHeader } from "view/components/table-header";

import {
    selectCurrentPartition,
    selectSortedCurrentPartitionInstances,
    selectPartitionInstancesSort,
} from "data/selectors/cluster-metadata";
import {
    getPartitionType,
    getPartitionErrors,
    getPartitionStatusMessage,
    isPartitionImpacted,
} from "data/models";
import { sortPartitionInstances } from "data/actions";

import { PARTITION_INSTANCE_COLUMNS } from "memsql/schema-column-info";
import {
    displayPartitionStatus,
    formatPartitionStatus,
    formatPartitionSyncState,
} from "view/common/models/cluster-metadata";

import { nextTableSort } from "util/sort";
import { logError } from "util/logging";
import { LONG_EM_DASH } from "util/symbols";

import "./page-partition.scss";

type StateProps = {
    partition: Maybe<DerivedPartition>;
    partitionInstances: Maybe<Array<PartitionInstance>>;
    partitionInstancesSort: TableSort;
};

type Props = StateProps &
    InjectedRoute & {
        dispatch: DispatchFunction;
    };

class PagePartition extends React.Component<Props> {
    handlePartitionInstancesSort = (
        columnId: ColumnId,
        direction: Maybe<SortDirection>
    ) => {
        this.props.dispatch(
            sortPartitionInstances(nextTableSort(columnId, direction))
        );
    };

    renderInfoTopCard = (partition: DerivedPartition) => (
        <Card className="partition-info-card">
            <TopContentHeader>Partition Info</TopContentHeader>

            <TopContentRow>
                <TopContent title="Partition Type" className="top-card">
                    {getPartitionType(partition)}
                </TopContent>

                <TopContent title="Partition Status" className="top-card">
                    {formatPartitionStatus(partition)}
                </TopContent>

                <TopContent
                    title={
                        <div>
                            Replica{" "}
                            <IconTip>
                                Whether this partition is part of a database
                                which is a replica of some other database.
                            </IconTip>
                        </div>
                    }
                    className="top-card"
                >
                    {partition.drReplica ? "Yes" : "No"}
                </TopContent>

                <TopContent
                    title="Sync Replication Desired"
                    className="top-card"
                >
                    {partition.syncReplicated ? "Yes" : "No"}
                </TopContent>

                <TopContent
                    title="Current Replication State"
                    className="top-card"
                >
                    {formatPartitionSyncState(partition) || LONG_EM_DASH}
                </TopContent>
            </TopContentRow>
        </Card>
    );

    renderErrorsCard = (partition: DerivedPartition) => {
        const errors = getPartitionErrors(partition);

        if (errors.length === 0 && partition.status === "online") {
            return null;
        }

        let errorsList;
        if (errors.length > 0) {
            errorsList = (
                <div className="errors-list">
                    The following issues are impacting this partition:
                    {errors.map((error, idx) => (
                        <li key={idx}>{error}</li>
                    ))}
                </div>
            );
        }

        let status: AlertStatus;
        if (
            partition.status === "offline" ||
            partition.status === "offline_recovering"
        ) {
            status = "error";
        } else {
            status = "warning";
        }

        return (
            <Alert
                className="errors-card"
                status={status}
                title={
                    <div className="errors-card-title">
                        HEALTH STATUS:{" "}
                        <span className="partition-status">
                            {displayPartitionStatus(
                                partition.status,
                                isPartitionImpacted(partition)
                            )}
                        </span>
                        <IconTip
                            iconProps={{ leftMargin: true }}
                            tipProps={{ direction: "se" }}
                        >
                            This status is being derived from the states of the
                            master partition instance and the most impacted
                            replica partition instance.
                        </IconTip>
                    </div>
                }
                message={
                    <>
                        {getPartitionStatusMessage(partition.status)}
                        {errorsList}
                    </>
                }
            />
        );
    };

    render() {
        const {
            partition,
            partitionInstances,
            partitionInstancesSort,
        } = this.props;

        if (!partition || !partitionInstances) {
            logError(
                new Error("PagePartition is being rendered without valid data.")
            );
            return null;
        }

        let bottomContent;
        if (partitionInstances.length > 0) {
            bottomContent = (
                <>
                    <TableHeader
                        title="Partition Instances"
                        count={partitionInstances.length}
                    />
                    <GeneralTable
                        rows={partitionInstances}
                        onSort={this.handlePartitionInstancesSort}
                        columns={PARTITION_INSTANCE_COLUMNS}
                        sort={partitionInstancesSort}
                    />
                </>
            );
        } else {
            bottomContent = (
                <div className="zero-partition-instances">
                    There are no partition instances for this partition.
                </div>
            );
        }

        return (
            <div className="schema-page-partitions-main">
                {this.renderErrorsCard(partition)}
                {this.renderInfoTopCard(partition)}
                {bottomContent}
            </div>
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        partition: selectCurrentPartition(s),
        partitionInstances: selectSortedCurrentPartitionInstances(s),
        partitionInstancesSort: selectPartitionInstancesSort(s),
    })
)(withRoute(PagePartition));
