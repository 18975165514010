import { DispatchFunction, State } from "data";
import { Maybe } from "util/maybe";
import { State as RouteState } from "router5";
import { Vector } from "util/vector";
import {
    ExplainLayout,
    Cluster,
    ExplainWarning,
    ExplainQueryInfo,
} from "data/models";
import { ZoomLevel } from "data/explain/layout";
import { ExplainTab } from "data/actions";
import { ExplainSectionName } from "data/reducers/explain";

import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRoute } from "react-router5";
import _ from "lodash";
import { center, changeRelativeBasis } from "util/rectangle";
import { negate } from "util/vector";

import InternalLink from "view/components/internal-link";
import Header from "view/common/header";
import Viewport from "view/components/viewport";
import CenteringWrapper from "view/components/centering-wrapper";
import FeatureCard from "view/components/feature-card";
import CircleIcon from "view/components/circle-icon";
import { Button } from "view/common/button";
import Loading from "view/components/loading";
import GeneralError from "view/common/general-error";
import FileLoader from "view/common/file-loader";
import ExtLink from "view/components/external-link";
import RadioToggleButton from "view/components/radio-toggle-button";
import Icon from "view/components/icon";
import { TabBadge } from "view/components/tab";
import Tip from "view/components/tip";

import { ExplainSection } from "view/explain/section";
import ExplainSidebar from "view/explain/sidebar";
import ExplainMenu from "view/explain/menu";
import LayoutRenderer from "view/explain/layout-renderer";
import ZoomOverlay from "view/explain/zoom-overlay";

import { parseExplainString } from "worker/api/explain";

import { expandSection, collapseSection } from "data/actions";
import { isSectionExpanded } from "view/explain/section";
import {
    selectExplainLayout,
    selectExplainLayoutLoading,
    selectExplainLayoutError,
    selectExplainSource,
    selectExplainQueryInfo,
    selectExplainLayoutWarnings,
    selectPlanType,
} from "data/selectors/explain";
import { selectCurrentCluster } from "data/selectors/clusters";
import { explainViewportOffset, changeExplainTab } from "data/actions/explain";
import { selectRoute } from "data/selectors";

import "./page-explain.scss";

type StateProps = {
    layout: Maybe<ExplainLayout>;
    loading: boolean;
    error: Maybe<string>;
    explainSource: Maybe<string>;
    explainQueryInfo: Maybe<ExplainQueryInfo>;
    planType: Maybe<string>;
    zoomLevel: ZoomLevel;
    selectedIndex: Maybe<number>;
    cluster: Maybe<Cluster>;
    offset: Maybe<Vector>;
    tab: ExplainTab;
    route: RouteState;
    warnings: Maybe<Array<ExplainWarning>>;
    expandedSections: Array<ExplainSectionName>;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

type ExplainState = {
    hoveredIndex: Maybe<number>;
};

// Compute the next zoom level from a zoom level in a certain direction
const computeNextZoomLevel = (
    zoomLevel: ZoomLevel,
    direction: "IN" | "OUT"
): ZoomLevel | never => {
    if (direction === "IN") {
        switch (zoomLevel) {
            case "small":
                return "medium";

            case "medium":
                return "large";

            case "large":
                return "large";
        }
    } else {
        switch (zoomLevel) {
            case "small":
                return "small";

            case "medium":
                return "small";

            case "large":
                return "medium";
        }
    }

    throw new Error("Expected computeNextZoomLevel to be exhaustive");
};

const EXPLAIN_TAB_ENTRIES = [
    {
        value: "Actual",
        tipProps: {
            direction: "se",
            tooltip: "Actual row count of each operator.",
        },
    },
    {
        value: "Estimated",
        tipProps: {
            direction: "se",
            tooltip: "Estimated row count of each operator.",
        },
    },
];

const EXPLAIN_TAB_ENTRIES_ADVANCED = [
    ...EXPLAIN_TAB_ENTRIES,
    {
        value: "Difference",
        tipProps: {
            direction: "se",
            tooltip:
                "Actual row count minus estimated row count of each operator.",
        },
    },
];

class ExplainPage extends React.Component<Props, ExplainState> {
    $fileLoader: Maybe<FileLoader>;

    state: ExplainState = {
        hoveredIndex: undefined,
    };

    handleLoadFile = (rawExplain: string, filename: string) => {
        this.props.dispatch(
            parseExplainString({
                rawExplain,
                source: filename,
                sourceType: "FILE",
            })
        );
    };

    handleClickUpload = () => {
        if (this.$fileLoader) {
            this.$fileLoader.show();
        }
    };

    handleZoom = (direction: "IN" | "OUT") => {
        const { dispatch, zoomLevel, layout, offset } = this.props;

        const newZoomLevel = computeNextZoomLevel(zoomLevel, direction);

        if (zoomLevel !== newZoomLevel) {
            dispatch({
                type: "ZOOM",
                error: false,
                payload: { zoomLevel: newZoomLevel },
            });

            if (layout && offset) {
                const oldBounds = layout.layout[zoomLevel].bounds;
                const newBounds = layout.layout[newZoomLevel].bounds;
                // Keep the coordinates displayed at the center (which is the
                // negative of the offset) at the same relative position in the
                // bounding box
                const newOffset = negate(
                    changeRelativeBasis(oldBounds, newBounds, negate(offset))
                );

                dispatch(explainViewportOffset({ offset: newOffset }));
            }
        }
    };

    handleClickNode = (event: React.MouseEvent, index: number) => {
        const { dispatch, layout } = this.props;

        event.preventDefault();
        event.stopPropagation();

        let executor;
        if (layout) {
            executor = layout.layout.large.nodes[index].data.executor;
        }

        dispatch({
            type: "SELECT_EXPLAIN_NODE",
            error: false,
            payload: { index, executor },
        });
    };

    handleClickViewport = (event: React.MouseEvent) => {
        const { dispatch } = this.props;

        event.preventDefault();
        event.stopPropagation();

        dispatch({
            type: "SELECT_EXPLAIN_NODE",
            error: false,
            payload: { index: undefined },
        });
    };

    setOffset = (offset: Vector) => {
        const { dispatch } = this.props;

        dispatch(explainViewportOffset({ offset }));
    };

    handleViewportMount = (rect: ClientRect) => {
        const { offset } = this.props;

        if (!offset) {
            // Pan the origin, where the root node is, to the center-top of the
            // screen, 25% of the way from the top.
            this.setOffset({ x: 0, y: -rect.height / 4 });
        }
    };

    renderHeaderTitle = () => {
        const { explainSource, planType, explainQueryInfo } = this.props;

        const titleParts: Array<string> = [];

        // if we have the original query text we show it, otherwise we show
        // the planType + source (ex: EXPLAIN: filename). v > 7.0 : PLAT-3491
        if (explainQueryInfo) {
            titleParts.push(explainQueryInfo.queryText);
        } else {
            if (planType) {
                titleParts.push(planType);
            }

            if (explainSource) {
                titleParts.push(explainSource);
            }
        }

        const title = titleParts.join(": ") || "Visual Explain";

        return (
            <Tip direction="se" tooltip={title} className="header-title">
                {title}
            </Tip>
        );
    };

    renderUploadButton = (buttonLabel: String) => (
        <>
            <Button
                large
                primary
                onClick={this.handleClickUpload}
                className="upload-button"
            >
                {buttonLabel}
            </Button>
            <FileLoader
                ref={$fileLoader => {
                    this.$fileLoader = $fileLoader || undefined;
                }}
                onLoadFile={this.handleLoadFile}
            />
        </>
    );

    handleHoverInstance = (hoveredIndex: Maybe<number>) => {
        this.setState({ hoveredIndex });
    };

    handleClickInstance = (index: number) => {
        const { dispatch, zoomLevel, layout } = this.props;

        if (layout) {
            dispatch(
                explainViewportOffset({
                    offset: negate(
                        center(layout.layout[zoomLevel].nodes[index].bounds)
                    ),
                })
            );

            // Analytics only
            const executor =
                layout.layout[zoomLevel].nodes[index].data.executor;

            dispatch({
                type: "EXPLAIN_SIDEBAR_CLICK",
                error: false,
                payload: { executor },
            });
        }
    };

    handleChangeTab = (tab: ExplainTab) => {
        const { dispatch } = this.props;

        dispatch(changeExplainTab({ tab }));
    };

    handleWarningCollapse = () => {
        const { expandedSections, dispatch } = this.props;

        const expanded = isSectionExpanded(expandedSections, "Warnings");

        if (expanded) {
            dispatch(collapseSection({ sectionName: "Warnings" }));
        } else {
            dispatch(expandSection({ sectionName: "Warnings" }));
        }
    };

    render() {
        const {
            layout,
            loading,
            error,
            zoomLevel,
            selectedIndex,
            cluster,
            offset,
            planType,
            warnings,
            expandedSections,
            tab,
            route: {
                params: { advanced: advancedFlag },
            },
        } = this.props;

        const { hoveredIndex } = this.state;

        if (!cluster) {
            throw new Error(
                "Expected cluster to exist when rendering ExplainPage"
            );
        }

        let inner;
        let button;
        if (loading) {
            inner = <Loading size="large" />;
        } else if (layout) {
            const zoomOverlay = (
                <ZoomOverlay
                    onZoom={this.handleZoom}
                    zoomInDisabled={zoomLevel === "large"}
                    zoomOutDisabled={zoomLevel === "small"}
                />
            );

            let tabs;
            if (planType === "PROFILE") {
                if (advancedFlag) {
                    tabs = (
                        <RadioToggleButton
                            entries={EXPLAIN_TAB_ENTRIES_ADVANCED}
                            value={tab}
                            onChange={this.handleChangeTab}
                            className="tabs"
                        />
                    );
                } else {
                    tabs = (
                        <RadioToggleButton
                            entries={EXPLAIN_TAB_ENTRIES}
                            value={tab}
                            onChange={this.handleChangeTab}
                            className="tabs"
                        />
                    );
                }
            }

            let highlightedIndex = selectedIndex;
            if (highlightedIndex === undefined) {
                highlightedIndex = hoveredIndex;
            }

            let warningSection;
            if (warnings && warnings.length > 0) {
                const warningMessages = _.map(warnings, warning => {
                    let warningInfoLink;
                    if (warning.linkName) {
                        warningInfoLink = (
                            <ExtLink name={warning.linkName} category="explain">
                                Learn More
                            </ExtLink>
                        );
                    }

                    return (
                        <div className="warning-item" key={warning.description}>
                            <Icon
                                icon="exclamation-circle"
                                iconType="regular"
                                warning
                                leftMargin
                                rightMargin
                                className="warning-icon"
                            />
                            <div className="warning-text">
                                {warning.description} {warningInfoLink}
                            </div>
                        </div>
                    );
                });

                warningSection = (
                    <ExplainSection
                        title={
                            <div className="warning-section-title">
                                Warnings
                                <TabBadge
                                    count={warnings.length}
                                    leftMargin
                                    active
                                />
                            </div>
                        }
                        sectionName="Warnings"
                        expanded={isSectionExpanded(
                            expandedSections,
                            "Warnings"
                        )}
                        onExpandCollapse={this.handleWarningCollapse}
                        className="warning-section"
                    >
                        {warningMessages}
                    </ExplainSection>
                );
            }

            const absoluteChildren = (
                <>
                    {zoomOverlay}
                    {tabs}
                </>
            );

            const viewport = (
                <Viewport
                    className="viewport"
                    absoluteChildren={absoluteChildren}
                    onClick={this.handleClickViewport}
                    offset={offset}
                    setOffset={this.setOffset}
                    onMountWithClientRect={this.handleViewportMount}
                    renderChildren={viewportBounds => (
                        <LayoutRenderer
                            layout={layout}
                            zoomLevel={zoomLevel}
                            highlightedIndex={highlightedIndex}
                            onClickNode={this.handleClickNode}
                            renderBounds={viewportBounds}
                        />
                    )}
                />
            );

            inner = (
                <div className="panes">
                    <div className="left-pane">
                        {warningSection}
                        {viewport}
                    </div>

                    <div className="right-pane">
                        <ExplainSidebar
                            onHoverInstance={this.handleHoverInstance}
                            onClickInstance={this.handleClickInstance}
                        />
                    </div>
                </div>
            );
        } else if (error) {
            button = this.renderUploadButton("Upload Again");
            inner = (
                <CenteringWrapper>
                    <GeneralError error={error} />
                    {button}
                </CenteringWrapper>
            );
        } else {
            // initial state
            const sqlEditorLink = (
                <InternalLink
                    routeInfo={{
                        name: "cluster.editor",
                        params: { clusterId: cluster.id },
                    }}
                >
                    SQL Editor
                </InternalLink>
            );

            button = this.renderUploadButton("Upload");
            inner = (
                <CenteringWrapper>
                    <FeatureCard
                        feature={
                            <CircleIcon
                                name="explain"
                                size="large"
                                coloredBackground
                            />
                        }
                        title="Visualize Your Queries"
                        body={
                            <>
                                <div>
                                    Upload an{" "}
                                    <ExtLink
                                        name="EXPLAIN"
                                        category="visual-explain"
                                    >
                                        EXPLAIN
                                    </ExtLink>{" "}
                                    or{" "}
                                    <ExtLink
                                        name="PROFILE"
                                        category="visual-explain"
                                    >
                                        PROFILE
                                    </ExtLink>{" "}
                                    JSON output file to visualize a query's
                                    plan.
                                </div>
                                {button}
                                <div>
                                    If you do not have a query plan file, you
                                    can generate one from the {sqlEditorLink}.
                                </div>
                            </>
                        }
                    />
                </CenteringWrapper>
            );
        }

        return (
            <div className="explain-page-explain">
                <Header
                    left={this.renderHeaderTitle()}
                    right={<ExplainMenu />}
                />

                {inner}
            </div>
        );
    }
}

export default compose(
    withRoute,
    connect(
        (s: State): StateProps => ({
            layout: selectExplainLayout(s),
            loading: selectExplainLayoutLoading(s),
            error: selectExplainLayoutError(s),
            explainSource: selectExplainSource(s),
            explainQueryInfo: selectExplainQueryInfo(s),
            planType: selectPlanType(s),
            zoomLevel: s.explain.zoomLevel,
            selectedIndex: s.explain.selectedIndex,
            cluster: selectCurrentCluster(s),
            warnings: selectExplainLayoutWarnings(s),
            expandedSections: s.explain.expandedSections,
            offset: s.explain.viewportOffset,
            tab: s.explain.tab,
            route: selectRoute(s),
        })
    )
)(ExplainPage);
