import { TocNode } from "view/tutorial/types";

import * as React from "react";

import TutorialIndex from "./index.mdx";
import TutorialIndexTitle from "./index-title.mdx";
import LoadSampleDataIndex from "./load-sample-data/load-sample-index.mdx";

import BooksStep0 from "./load-sample-data/books-data/step0.mdx";
import BooksStep1 from "./load-sample-data/books-data/step1.mdx";
import BooksStep2 from "./load-sample-data/books-data/step2.mdx";

import StockStep0 from "./load-sample-data/stock-data/step0.mdx";
import StockStep1 from "./load-sample-data/stock-data/step1.mdx";
import StockStep2 from "./load-sample-data/stock-data/step2.mdx";
import StockStep3 from "./load-sample-data/stock-data/step3.mdx";
import StockStep4 from "./load-sample-data/stock-data/step4.mdx";
import StockStep5 from "./load-sample-data/stock-data/step5.mdx";

import TpchStep0 from "./load-sample-data/tpch-data/step0.mdx";
import TpchStep1 from "./load-sample-data/tpch-data/step1.mdx";
import TpchStep2 from "./load-sample-data/tpch-data/step2.mdx";
import TpchStep3 from "./load-sample-data/tpch-data/step3.mdx";
import TpchStep4 from "./load-sample-data/tpch-data/step4.mdx";

export const TOC: TocNode = {
    id: "getting-started",
    breadcrumb: "Getting Started",
    title: <TutorialIndexTitle />,
    component: <TutorialIndex />,
    items: [
        {
            id: "load-data-index",
            component: <LoadSampleDataIndex />,
            breadcrumb: "Load Sample Data",
            title: "Load Data into MemSQL",
            description:
                "Load our sample datasets to quickly start interacting with MemSQL.",
            icon: "arrow-circle-down",
            items: [
                {
                    id: "books-index",
                    breadcrumb: "Ingest Data From S3",
                    title: "Ingest Data From S3",
                    description: "Create and run a pipeline on an S3 bucket",
                    icon: "boxes-alt",
                    steps: [
                        {
                            id: "books-step-0",
                            component: <BooksStep0 />,
                        },
                        {
                            id: "books-step-1",
                            component: <BooksStep1 />,
                        },
                        {
                            id: "books-step-2",
                            component: <BooksStep2 />,
                        },
                    ],
                },
                {
                    id: "stock-index",
                    breadcrumb: "Sample Stock Dataset",
                    title: "Build a Sample Stock Trade Database",
                    description:
                        "Contains millions of simulated stock trade data",
                    icon: "chart-line",
                    steps: [
                        {
                            id: "stock-step-0",
                            component: <StockStep0 />,
                        },
                        {
                            id: "stock-step-1",
                            component: <StockStep1 />,
                        },
                        {
                            id: "stock-step-2",
                            component: <StockStep2 />,
                        },
                        {
                            id: "stock-step-3",
                            component: <StockStep3 />,
                        },
                        {
                            id: "stock-step-4",
                            component: <StockStep4 />,
                        },
                        {
                            id: "stock-step-5",
                            component: <StockStep5 />,
                        },
                    ],
                },
                {
                    id: "tpch-index",
                    breadcrumb: "Sample TPCH Dataset",
                    title: "Ingest TPC-H Dataset From S3",
                    description:
                        "Query the TPC-H dataset to get business-critical metrics",
                    icon: "table",
                    steps: [
                        {
                            id: "tpch-step-0",
                            component: <TpchStep0 />,
                        },
                        {
                            id: "tpch-step-1",
                            component: <TpchStep1 />,
                        },
                        {
                            id: "tpch-step-2",
                            component: <TpchStep2 />,
                        },
                        {
                            id: "tpch-step-3",
                            component: <TpchStep3 />,
                        },
                        {
                            id: "tpch-step-4",
                            component: <TpchStep4 />,
                        },
                    ],
                },
            ],
        },
    ],
};
