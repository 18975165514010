import { MvWithStats } from "data/models";
import { Entry as InlineBarEntry } from "view/components/inline-bar";

import * as React from "react";
import _ from "lodash";
import BigNumber from "vendor/bignumber.js/bignumber";
import Stat from "util/stat";

import Tip from "view/components/tip";
import InlineBar from "view/components/inline-bar";

import { RESOURCE_USAGE_COLUMNS } from "memsql/mv-column-info";

import NumberFormatter from "util/number-formatter";
import { COLORS } from "util/colors";

import "./mv-time-breakdown-column.scss";

type Props = {
    mvWithStats: MvWithStats;

    // The index of the row where this cell is going and the
    // total number of rows are needed for tooltip direction
    // calculation.
    rowIndex: number;
    totalRows: number;
};

type TooltipLineRepr = {
    id: string;
    color: string;
    title: string;
    label: string;
};

export default class MVTimeBreakdownColumn extends React.PureComponent<Props> {
    renderEmptyBar() {
        return (
            <InlineBar
                entries={[{ value: 1, border: COLORS["color-neutral-500"] }]}
            />
        );
    }

    renderTooltipLines = (tooltipLines: Array<TooltipLineRepr>) => {
        return _.map(tooltipLines, line => (
            <div className="tip-line" key={line.id}>
                <div
                    className="stat-color"
                    style={{ backgroundColor: line.color }}
                />
                <div className="stat-title">{line.title}:</div>
                <div className="stat-label">{line.label}</div>
            </div>
        ));
    };

    render() {
        const { mvWithStats, rowIndex, totalRows } = this.props;

        const tooltipLines: Array<TooltipLineRepr> = [];
        const segments: Array<InlineBarEntry> = [];

        // The `total` value for the statistics we are showing is the
        // elapsedTimeMs.
        let total =
            mvWithStats.statistics.elapsedTimeMs instanceof Stat
                ? mvWithStats.statistics.elapsedTimeMs.sum()
                : mvWithStats.statistics.elapsedTimeMs;

        let sum = new BigNumber(0);

        for (let i = 0, l = RESOURCE_USAGE_COLUMNS.length; i < l; ++i) {
            const column = RESOURCE_USAGE_COLUMNS[i];

            if (mvWithStats.statistics[column.id] instanceof Stat) {
                sum = sum.plus(
                    (mvWithStats.statistics[column.id] as Stat).sum()
                );
            } else {
                sum = sum.plus(column.getValue(mvWithStats));
            }
        }

        // Due to double counting in the engine, the actual sum of all the statistics
        // could be larger than the elapsed time for this activity (or for this activity
        // summary, if we are in a high level row). Because of that, we fix the `total`
        // to be equal to the `sum` in those cases.
        if (sum.gt(total)) {
            total = sum;
        }

        if (total.isZero()) {
            return this.renderEmptyBar();
        }

        for (let i = 0, l = RESOURCE_USAGE_COLUMNS.length; i < l; ++i) {
            const column = RESOURCE_USAGE_COLUMNS[i];

            let value;

            // `total` is guaranteed not to be 0, so we can divide by it.
            if (mvWithStats.statistics[column.id] instanceof Stat) {
                value = (mvWithStats.statistics[column.id] as Stat)
                    .sum()
                    .dividedBy(total)
                    .toNumber();
            } else {
                // PLAT-3189
                value = new BigNumber(column.getValue(mvWithStats))
                    .dividedBy(total)
                    .toNumber();
            }

            if (value === 0) {
                continue;
            }

            const formattedPercent = NumberFormatter.formatPercent(value);

            tooltipLines.push({
                id: column.id,
                color: column.color,
                title: column.title,
                label: formattedPercent,
            });

            segments.push({
                value,
                fill: column.color,
            });
        }

        // If the sum of all the metrics for a given activity (or an activity summary)
        // is smaller than the total, it means that we have some "miscellaneous internal
        // waits" in the engine.
        if (sum.lt(total)) {
            const miscTime = total.minus(sum);

            tooltipLines.push({
                id: "misc-time",
                color: COLORS["color-neutral-500"],
                title: "Miscellaneous internal waits",
                label: NumberFormatter.formatPercent(
                    miscTime.dividedBy(total).toNumber()
                ),
            });

            segments.push({
                value: miscTime.dividedBy(total).toNumber(),
                fill: COLORS["color-neutral-500"],
            });
        }

        // If we don't have any information about where this activity spent
        // its time, then we show an empty bar for it.
        if (segments.length === 0) {
            return this.renderEmptyBar();
        }

        // Show tooltip below the row if this cell is near the end of table
        // or above the row
        const tooltipDirection =
            rowIndex > totalRows - 5 && rowIndex > 5 ? "n" : "s";

        return (
            <div className="mv-time-breakdown-column">
                <Tip
                    tooltip={
                        <div className="time-breakdown-tooltip">
                            {this.renderTooltipLines(tooltipLines)}
                        </div>
                    }
                    direction={tooltipDirection}
                    children={<InlineBar entries={segments} />}
                />
            </div>
        );
    }
}
