import { Maybe } from "util/maybe";
import { DispatchFunction, State as ReduxState } from "data";
import { ConnectionStatus } from "data/reducers/system-status";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import classnames from "classnames";

import { Button } from "view/common/button";
import Icon from "view/components/icon";
import GeneralError from "view/common/general-error";
import { ErrorBoundary } from "view/common/error-boundary";
import { TutorialContent } from "view/tutorial/tutorial-content";

import { changeTutorialMinimizedState } from "data/actions";

import "./tutorial-container.scss";

type Props = {
    onTutorialClose: () => void;
};

type StateProps = {
    webConnection?: ConnectionStatus;
    minimized: boolean;
    closed: boolean;
    location: Array<string>;
    step: Maybe<number>;
};

type TutorialContainerProps = Props &
    StateProps & {
        dispatch: DispatchFunction;
    };

class TutorialContainer extends React.Component<TutorialContainerProps> {
    handleCollapse = () => {
        const { dispatch, minimized } = this.props;
        dispatch(changeTutorialMinimizedState({ minimized: !minimized }));
    };

    render() {
        const { webConnection, closed, location, step, minimized } = this.props;

        if (closed) {
            return null;
        }
        const isWebConnectionOffline = webConnection && !webConnection.active;

        const innerClasses = classnames("tutorial-container-inner", {
            minimized,
        });
        const contentClasses = classnames("tutorial-content-wrapper", {
            error: isWebConnectionOffline,
        });
        const toggleIcon = minimized
            ? "angle-double-left"
            : "angle-double-right";

        let content;
        if (isWebConnectionOffline) {
            content = (
                <GeneralError
                    errorHeader="Please check your internet connection."
                    error=""
                />
            );
        } else {
            content = <TutorialContent location={location} step={step} />;
        }

        return (
            <div className="tutorial-container">
                <div className={innerClasses}>
                    <div className="close-button-wrapper">
                        <div
                            role="button"
                            onMouseDown={this.props.onTutorialClose}
                            className="close-button"
                        >
                            <Icon icon="times" iconType="regular" />
                        </div>
                    </div>

                    <div className={contentClasses}>
                        <ErrorBoundary>{content}</ErrorBoundary>
                    </div>
                </div>

                <Button
                    className="tutorial-container-toggle"
                    onClick={this.handleCollapse}
                >
                    <Icon icon={toggleIcon} iconType="regular" />
                </Button>
            </div>
        );
    }
}

export default connect(
    (s: ReduxState): StateProps => ({
        webConnection: s.systemStatus.webConnection,
        minimized: s.tutorial.minimized,
        closed: s.tutorial.closed,
        location: s.tutorial.location,
        step: s.tutorial.step,
    })
)(TutorialContainer);
