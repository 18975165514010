import { Types as MysqlJSType } from "mysqljs";
import Types from "mysqljs/lib/protocol/constants/types";

// Parses a time in hour:minute:second format, possibly with a negative sign in
// front, to a number of seconds.
const parseTimeToSeconds = (time: string): number => {
    let sign = 1;
    let nonnegativeTime = time;
    if (time.startsWith("-")) {
        // A negative sign applies to all components.
        sign = -1;
        nonnegativeTime = time.substring(1);
    }
    const components = nonnegativeTime.split(":").map(Number);
    return sign * (components[0] * 3600 + components[1] * 60 + components[2]);
};

// Convert a string, given its MySQL type, to a JavaScript value that allows it
// to be sorted.
export const typecast = (value: string, type: MysqlJSType) => {
    switch (type) {
        case Types.TINY:
        case Types.SHORT:
        case Types.LONG:
        case Types.INT24:
        case Types.YEAR:
        case Types.FLOAT:
        case Types.DOUBLE:
        case Types.NEWDECIMAL:
        case Types.LONGLONG:
            // TODO(PLAT-2222): This can lose precision for NEWDECIMAL and
            // LONGLONG fields, and will incorrectly sort values in such fields
            // that are large but close together.
            return Number(value);

        case Types.TIME:
        case Types.TIME2:
            // We get times that are zero-padded to two-digit hours, but MemSQL
            // supports TIME instances with hours that are negative or more
            // than 100, which don't sort lexicographically and which date-fns
            // cannot handle. So we have to parse TIMEs ourselves.
            return parseTimeToSeconds(value);

        default:
            // Dates and datetimes are actually formatted so they sort well as
            // strings, and sorting geospatial types is implausible, so treat
            // everything else as a string for sorting purposes.
            return value;
    }
};
