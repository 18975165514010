import { DispatchFunction } from "data";

import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import Icon from "view/components/icon";

import { changeTutorialLocation } from "data/actions";
import { getBreadcrumbs } from "view/tutorial/types";

import "./tutorial-breadcrumbs.scss";

type Props = {
    location: Array<string>;
};

type BreadcrumbsProps = Props & {
    dispatch: DispatchFunction;
};

class TutorialBreadcrumbs extends React.Component<BreadcrumbsProps> {
    handleClick(location: Array<string>) {
        const { dispatch } = this.props;
        dispatch(changeTutorialLocation({ location }));
    }

    render() {
        const { location } = this.props;

        // Remove current node from breadcrumbs
        const breadcrumbLocations = location.slice(0, -1);
        const breadcrumbs = getBreadcrumbs(breadcrumbLocations);

        const breadcrumb = _.map(breadcrumbs, (title, idx) => {
            return (
                <React.Fragment key={title}>
                    <span
                        className="breadcrumb"
                        role="button"
                        onClick={() =>
                            this.handleClick(location.slice(0, idx + 1))
                        }
                    >
                        {title}
                    </span>
                    <Icon
                        icon="chevron-right"
                        size="sm"
                        leftMargin
                        rightMargin
                    />
                </React.Fragment>
            );
        });

        return <div className="tutorial-breadcrumbs">{breadcrumb}</div>;
    }
}

export default connect()(TutorialBreadcrumbs);
