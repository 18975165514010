import { DispatchFunction, State as ReduxState } from "data";
import { SectionProps } from "view/common/collapsable-form/section";

import * as React from "react";
import _ from "lodash";

import { FormSection } from "redux-form";
import { InjectedFormProps } from "redux-form";

import Loading from "view/components/loading";
import Form from "view/components/form";
import Icon from "view/components/icon";

import { SectionValuesList } from "view/common/collapsable-form/section";
import Section from "view/common/collapsable-form/section";

import "./index.scss";

type FormSectionConfig = {
    name: string;
    renderFields: (dispatch: DispatchFunction) => React.ReactNode;
    title: string;
    description: string;
    getFieldValues: (s: ReduxState, sectionName: string) => SectionValuesList;
};

export type CollapsableFormError = string | { section: string; error: string };

export type CollapsableFormProps = {
    dispatch: DispatchFunction;
    canSubmit: boolean;
    formSections: Array<FormSectionConfig>;
};

// FD = FormData (the schema of the form's values)
// P = (Custom Form) Props
type Props<FD, P> = InjectedFormProps<FD, P, CollapsableFormError> &
    CollapsableFormProps;

type State = {
    activeSection: number;
};

export default class CollapsableForm<FD, P> extends React.Component<
    Props<FD, P>,
    State
> {
    constructor(props: Props<FD, P>) {
        super(props);
        this.state = { activeSection: 0 };
    }

    handleSectionChange = (index: number) => {
        if (this.state.activeSection === index) {
            return;
        }

        this.setState({
            activeSection: index,
        });
    };

    handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        // Prevent page reload which is the default behavior after
        // a form submit event.
        e.preventDefault();

        if (this.props.canSubmit) {
            this.props.handleSubmit(e);
        }
    };

    render() {
        const { submitting, error, formSections, dispatch } = this.props;

        if (submitting) {
            return <Loading size="large" />;
        }

        const { activeSection } = this.state;

        let formError: React.ReactNode = null;
        if (error && typeof error === "string") {
            formError = (
                <div className="form-error">
                    <Icon className="icon-error" icon="exclamation-circle" />
                    {error}
                </div>
            );
        }

        return (
            <Form onSubmit={this.handleSubmit} className="collapsable-form">
                {formError}

                {_.map(
                    formSections,
                    (sectionConfig: FormSectionConfig, index: number) => {
                        return (
                            <FormSection<SectionProps>
                                name={sectionConfig.name}
                                component={Section}
                                key={sectionConfig.name}
                                fields={sectionConfig.renderFields(dispatch)}
                                title={sectionConfig.title}
                                description={sectionConfig.description}
                                active={activeSection === index}
                                handleToggle={() =>
                                    this.handleSectionChange(index)
                                }
                                sectionName={sectionConfig.name}
                                index={index + 1}
                                getFieldValues={sectionConfig.getFieldValues}
                                error={
                                    !formError &&
                                    error &&
                                    typeof error !== "string" &&
                                    error.section === sectionConfig.name
                                        ? error.error
                                        : undefined
                                }
                            />
                        );
                    }
                )}

                <button
                    type="submit"
                    className="hidden-submit-button"
                    tabIndex={-1}
                />
            </Form>
        );
    }
}
