import { TableSort } from "util/sort";
import { Action, SimpleAction } from "./types";
import { Version } from "util/version";
import { Loading } from "util/loading-state-machine";
import {
    Partition,
    PartitionInstance,
    RowsThroughputPayload,
} from "data/models";

export type ClusterStatisticsPayload = {
    haEnabled: boolean;
};

export type ClusterStatisticsAction = Action<
    "CLUSTER_STATISTICS",
    Loading<ClusterStatisticsPayload>,
    string
>;

export const buildClusterStatisticsAction = (
    data: ClusterStatisticsPayload
): ClusterStatisticsAction => {
    return {
        type: "CLUSTER_STATISTICS",
        error: false,
        payload: {
            loading: false,
            data,
        },
    };
};

export type MemsqlVersionAction = Action<
    "MEMSQL_VERSION",
    Loading<Version>,
    { message: string }
>;

export type MemsqlPartitionsPayload = {
    partitions: Array<Partition>;
    partitionInstances: Array<PartitionInstance>;
    deltaTimeS: number;
};

export type MemsqlPartitionsAction = Action<
    "MEMSQL_PARTITIONS",
    Loading<MemsqlPartitionsPayload>,
    string
>;

export const buildMemsqlPartitionsAction = (
    data: MemsqlPartitionsPayload
): MemsqlPartitionsAction => {
    return {
        type: "MEMSQL_PARTITIONS",
        error: false,
        payload: {
            loading: false,
            data,
        },
    };
};

export type SortPartitionsAction = SimpleAction<"SORT_PARTITIONS", TableSort>;

export const sortPartitions = (payload: TableSort): SortPartitionsAction => ({
    type: "SORT_PARTITIONS",
    error: false,
    payload,
});

export type SortPartitionInstancesAction = SimpleAction<
    "SORT_PARTITION_INSTANCES",
    TableSort
>;

export const sortPartitionInstances = (
    payload: TableSort
): SortPartitionInstancesAction => ({
    type: "SORT_PARTITION_INSTANCES",
    error: false,
    payload,
});

export type RowsThroughputAction = Action<
    "ROWS_THROUGHPUT",
    Loading<RowsThroughputPayload>,
    string
>;

export type RowsThroughputEnabledAction = SimpleAction<
    "ROWS_THROUGHPUT_ENABLED",
    boolean
>;
