import { Maybe } from "util/maybe";
import { ColumnId } from "view/components/super-table";

// Types and utility functions for managing the sorting of tables by a column.

export type SortDirection = "asc" | "desc"; // what _.sort takes
export type TableSort = Maybe<{
    columnId: ColumnId;
    direction: SortDirection;
}>;

const nextSortOrUnsortDirection = (
    sort: Maybe<SortDirection>
): Maybe<SortDirection> => {
    switch (sort) {
        case "asc":
            return undefined;

        case "desc":
            return "asc";

        default:
            return "desc";
    }
};

export const nextTableSort = (
    columnId: ColumnId,
    direction: Maybe<SortDirection>
): TableSort => {
    const newDirection = nextSortOrUnsortDirection(direction);

    if (newDirection) {
        return { columnId, direction: newDirection };
    }
};
