import { DispatchFunction } from "data";
import { TocItemNode } from "view/tutorial/types";

import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import Icon from "view/components/icon";

import { changeTutorialLocation } from "data/actions";

import "./tutorial-menu.scss";

type Props = {
    items: Array<TocItemNode>;
    location: Array<string>;
};

type MenuProps = Props & {
    dispatch: DispatchFunction;
};

class TutorialMenu extends React.Component<MenuProps> {
    handleClick(location: Array<string>) {
        const { dispatch } = this.props;
        dispatch(changeTutorialLocation({ location }));
    }

    render() {
        const { location, items } = this.props;

        const menuItem = _.map(items, item => {
            const newLocation = location.concat(item.id);

            return (
                <div
                    key={item.id}
                    className="tutorial-menu-item"
                    onClick={() => this.handleClick(newLocation)}
                    role="button"
                >
                    <div className="tutorial-item-header">
                        <Icon
                            className="icon"
                            icon={item.icon}
                            iconType="regular"
                            size="sm"
                            rightMargin
                        />
                        <div className="title">{item.title}</div>
                    </div>
                    <div className="description">{item.description}</div>
                </div>
            );
        });

        return <div className="tutorial-menu">{menuItem}</div>;
    }
}

export default connect()(TutorialMenu);
