import { Maybe } from "util/maybe";

import { Observable, Subscriber } from "rxjs";

type OperatorFunction<T, S> = (source: Observable<T>) => Observable<S>;

export const mapFilter = <T, S>(
    map: (value: T, index: number) => Maybe<S>
): OperatorFunction<T, S> => (source: Observable<T>): Observable<S> =>
    Observable.create((subscriber: Subscriber<unknown>) => {
        let count = 0;
        return source.subscribe((value: T) => {
            let result: Maybe<S>;
            try {
                result = map(value, count++);
            } catch (err) {
                subscriber.error(err);
                return;
            }
            if (result) {
                subscriber.next(result);
            }
        });
    });
