import { Maybe } from "util/maybe";
import { TocNode } from "view/tutorial/types";

import * as React from "react";

import { CustomScrollbar } from "view/components/custom-scrollbar";
import TutorialMenu from "view/tutorial/tutorial-menu";
import TutorialStepper from "view/tutorial/tutorial-stepper";
import TutorialBreadcrumbs from "view/tutorial/tutorial-breadcrumbs";

import { getNodeFromLocation } from "view/tutorial/types";

import "./tutorial-content.scss";

type Props = {
    location: Array<string>;
    step: Maybe<number>;
};

export class TutorialContent extends React.Component<Props> {
    renderCurrNode(currNode: TocNode) {
        const { location, step } = this.props;

        if ("steps" in currNode) {
            const currStep = step || 0;
            return currNode.steps[currStep].component;
        } else if ("items" in currNode) {
            return (
                <>
                    {currNode.component}
                    <TutorialMenu items={currNode.items} location={location} />
                </>
            );
        } else {
            return currNode.component;
        }
    }

    render() {
        const { location, step } = this.props;

        const currNode = getNodeFromLocation(location);
        let stepper;
        if ("steps" in currNode) {
            stepper = (
                <TutorialStepper
                    currentStep={step || 0}
                    stepCount={currNode.steps.length}
                    location={location}
                />
            );
        }

        return (
            <>
                <TutorialBreadcrumbs location={location} />
                <CustomScrollbar autoHide>
                    <div className="tutorial-content">
                        <div className="title">
                            {currNode.title}
                            <hr />
                        </div>
                        {this.renderCurrNode(currNode)}
                    </div>
                </CustomScrollbar>
                {stepper}
            </>
        );
    }
}
