import BigNumber from "vendor/bignumber.js/bignumber";

export function calculateRateMs(
    previousValue: BigNumber,
    currentValue: BigNumber,
    previousDate: Date,
    currentDate: Date
) {
    return currentValue
        .minus(previousValue)
        .dividedBy(currentDate.getTime() - previousDate.getTime());
}

export function calculateRateS(
    previousValue: BigNumber,
    currentValue: BigNumber,
    previousDate: Date,
    currentDate: Date
) {
    return calculateRateMs(
        previousValue,
        currentValue,
        previousDate,
        currentDate
    ).multipliedBy(1000);
}
