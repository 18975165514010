import { ScrollbarProps } from "react-custom-scrollbars";

import * as React from "react";
import _ from "lodash";

import Scrollbars from "react-custom-scrollbars";

import ZIndex from "util/z-index";

type DefaultRenderProps = {
    style: React.CSSProperties;
    dark?: boolean;
};

// This default renders were based on the react-custom-scrollbars v4.2.1
// https://github.com/malte-wessel/react-custom-scrollbars/blob/v4.2.1/src/Scrollbars/defaultRenderElements.js
function renderTrackHorizontalDefault({ style, ...props }: DefaultRenderProps) {
    const finalStyle = {
        ...style,
        right: 2,
        bottom: 2,
        left: 2,
        borderRadius: 3,
    };
    const newProps = _.omit(props, "dark");
    return <div style={finalStyle} {...newProps} />;
}

function renderTrackVerticalDefault({ style, ...props }: DefaultRenderProps) {
    const finalStyle = {
        ...style,
        right: 2,
        bottom: 2,
        top: 2,
        borderRadius: 3,
    };
    const newProps = _.omit(props, "dark");
    return <div style={finalStyle} {...newProps} />;
}

function renderThumbHorizontalDefault({
    style,
    dark,
    ...props
}: DefaultRenderProps) {
    let themeStyle;
    if (dark) {
        themeStyle = {
            borderRadius: "3px",
            backgroundColor: "rgba(255, 255, 255, 0.4)",
        };
    } else {
        themeStyle = {
            borderRadius: "inherit",
            backgroundColor: "rgba(0,0,0,0.2)",
        };
    }
    const finalStyle = {
        ...style,
        ...themeStyle,
        cursor: "pointer",
    };
    return <div style={finalStyle} {...props} />;
}

function renderThumbVerticalDefault({
    style,
    dark,
    ...props
}: DefaultRenderProps) {
    let themeStyle;
    if (dark) {
        themeStyle = {
            borderRadius: "3px",
            backgroundColor: "rgba(255, 255, 255, 0.4)",
        };
    } else {
        themeStyle = {
            borderRadius: "inherit",
            backgroundColor: "rgba(0,0,0,0.2)",
        };
    }
    const finalStyle = {
        ...style,
        ...themeStyle,
        cursor: "pointer",
    };
    return <div style={finalStyle} {...props} />;
}

type CustomScrollbarProps = ScrollbarProps & {
    dark?: boolean;
};

// Wrapper of the third party component "react-custom-scrollbars". We use this
// wrapper component to inject custom MemSQL styles, for instance, to overwrite
// the scrollbar default render injecting the proper z-index value
export const CustomScrollbar = React.forwardRef<
    Scrollbars,
    CustomScrollbarProps
>(
    (
        {
            children,
            renderTrackHorizontal,
            renderTrackVertical,
            renderThumbHorizontal,
            renderThumbVertical,
            dark,
            ...rest
        },
        ref
    ) => (
        <Scrollbars
            ref={ref}
            renderTrackHorizontal={({ style, ...props }) => {
                const finalStyle = {
                    ...style,
                    zIndex: ZIndex.scrollbar,
                };

                if (renderTrackHorizontal) {
                    return renderTrackHorizontal({
                        ...props,
                        style: finalStyle,
                    });
                } else {
                    return renderTrackHorizontalDefault({
                        ...props,
                        dark,
                        style: finalStyle,
                    });
                }
            }}
            renderTrackVertical={({ style, ...props }) => {
                const finalStyle = {
                    ...style,
                    dark,
                    zIndex: ZIndex.scrollbar,
                };

                if (renderTrackVertical) {
                    return renderTrackVertical({
                        ...props,
                        style: finalStyle,
                    });
                } else {
                    return renderTrackVerticalDefault({
                        ...props,
                        dark,
                        style: finalStyle,
                    });
                }
            }}
            renderThumbHorizontal={({ style, ...props }) => {
                const finalStyle = {
                    ...style,
                    zIndex: ZIndex.scrollbar,
                };

                if (renderThumbHorizontal) {
                    return renderThumbHorizontal({
                        ...props,
                        style: finalStyle,
                    });
                } else {
                    return renderThumbHorizontalDefault({
                        ...props,
                        dark,
                        style: finalStyle,
                    });
                }
            }}
            renderThumbVertical={({ style, ...props }) => {
                const finalStyle = {
                    ...style,
                    zIndex: ZIndex.scrollbar,
                };

                if (renderThumbVertical) {
                    return renderThumbVertical({
                        ...props,
                        style: finalStyle,
                    });
                } else {
                    return renderThumbVerticalDefault({
                        ...props,
                        dark,
                        style: finalStyle,
                    });
                }
            }}
            {...rest}
        >
            {children}
        </Scrollbars>
    )
);

export type CustomScrollbarRef = Scrollbars;
