import { Action, SimpleAction } from "./types";

import {
    StaticConnectionId,
    ConnectionManagerState,
} from "worker/net/connection-manager";

import { QueryGroupRepr, QueryExecutorMsg } from "worker/net/query-executor";

export type ConnectAction = Action<
    "CONNECT",
    ConnectionManagerState,
    {
        code?: string;
        message: string;
    }
>;

export type CloseQueryExecutorAction = SimpleAction<"QUERY_EXECUTOR_CLOSE", {}>;

export type ConsoleQueryAction = SimpleAction<
    "CONSOLE_QUERY",
    { query: string; automated: boolean }
>;

export type ConsoleEmptyQueryAction = SimpleAction<"CONSOLE_EMPTY_QUERY", {}>;

export const consoleQueryAction = (payload: {
    query: string;
    automated: boolean;
}): ConsoleQueryAction => ({
    type: "CONSOLE_QUERY",
    error: false,
    payload,
});

export const consoleEmptyQueryAction = (): ConsoleEmptyQueryAction => ({
    type: "CONSOLE_EMPTY_QUERY",
    error: false,
    payload: {},
});

// This action is emitted as the worker tries to init a query executor. It
// clears the error when it attempts to intialize and sets an error if
// initializing fails. Note that we need to put the StaticConnectionId in meta
// because we need to know it even if there's an error.
export type InitQueryExecutorAction = Action<
    "INIT_QUERY_EXECUTOR",
    undefined,
    Error,
    { id: StaticConnectionId }
>;

type QueryExecutorMessageActionPayload = {
    id: StaticConnectionId;
    msg: QueryExecutorMsg;
};

export type QueryExecutorMessageAction = SimpleAction<
    "QUERY_EXECUTOR_MESSAGE",
    QueryExecutorMessageActionPayload
>;

export function queryExecutorMessageAction(
    payload: QueryExecutorMessageActionPayload
): QueryExecutorMessageAction {
    return {
        type: "QUERY_EXECUTOR_MESSAGE",
        error: false,
        payload,
    };
}

export type QueryGroupStartAction = SimpleAction<
    "QUERY_GROUP_START",
    { id: StaticConnectionId; queryGroup: QueryGroupRepr }
>;

export type QueryGroupContinueAction = SimpleAction<
    "QUERY_GROUP_CONTINUE",
    { id: StaticConnectionId; skip: boolean }
>;

export type QueryGroupStopAction = SimpleAction<
    "QUERY_GROUP_STOP",
    { id: StaticConnectionId }
>;

export type QueryExecutorCancelAction = SimpleAction<
    "QUERY_EXECUTOR_CANCEL",
    { id: StaticConnectionId }
>;

export type MemsqlPingAction = Action<"MEMSQL_PING", {}, { message: string }>;

export type MemsqlPingStopAction = Action<
    "MEMSQL_PING_STOP",
    {},
    { message: string }
>;
