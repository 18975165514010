import { TableSort } from "util/sort";
import { Maybe } from "util/maybe";
import {
    HostsAction,
    HostsPayload,
    SortHostsAction,
    SortHostNodesAction,
    HostsLiveMonitoringAction,
    PhysicalMonitoringEnabledAction,
} from "data/actions";
import { Host } from "data/models";
import { LEError } from "util/loading-error";

import _ from "lodash";

import { LSM, updatePayload } from "util/loading-state-machine";
import {
    getInitialState,
    reduce as reduceLoadingStateMachine,
} from "util/loading-state-machine";

type Actions =
    | HostsAction
    | SortHostsAction
    | SortHostNodesAction
    | HostsLiveMonitoringAction
    | PhysicalMonitoringEnabledAction;

export type HostsState = {
    hosts: LSM<HostsPayload, string>;
    hostsSort: TableSort;
    hostNodesSort: TableSort;
    physicalMonitoringEnabled: Maybe<boolean>;
    disableHosts: boolean;
};

const initialState: HostsState = {
    hosts: getInitialState(),

    hostsSort: {
        columnId: "hostMemory",
        direction: "desc",
    },

    hostNodesSort: {
        columnId: "nodeHostPort",
        direction: "asc",
    },

    physicalMonitoringEnabled: undefined,
    disableHosts: window.MANAGED_SERVICE,
};

export default (state: HostsState = initialState, action: Actions) => {
    switch (action.type) {
        case "HOSTS":
            {
                state = {
                    ...state,
                    hosts: reduceLoadingStateMachine(state.hosts, action),
                };
            }

            break;

        case "SORT_HOSTS": {
            state = {
                ...state,
                hostsSort: action.payload,
            };

            break;
        }

        case "SORT_HOST_NODES": {
            state = {
                ...state,
                hostNodesSort: action.payload,
            };

            break;
        }

        case "HOSTS_LIVE_MONITORING": {
            if (action.error) {
                state = {
                    ...state,
                    hosts: updatePayload(
                        state.hosts,
                        (hosts: HostsPayload): HostsPayload => ({
                            hosts: _.map(
                                hosts.hosts,
                                (host): Host => ({
                                    ...host,
                                    liveMonitoring: {
                                        totalCpuUsagePercentRate: new LEError(),
                                        systemUsedMemoryB: new LEError(),
                                        systemTotalMemoryB: new LEError(),
                                        systemTotalDiskB: new LEError(),
                                        systemUsedDiskB: new LEError(),
                                        netReceivedRateBpS: new LEError(),
                                        netTransmittedRateBpS: new LEError(),
                                        diskReadRateBpS: new LEError(),
                                        diskWriteRateBpS: new LEError(),
                                    },
                                })
                            ),
                        })
                    ),
                };
            } else {
                state = {
                    ...state,
                    hosts: updatePayload(
                        state.hosts,
                        (hosts: HostsPayload): HostsPayload => {
                            return {
                                hosts: _.map(hosts.hosts, host => ({
                                    ...host,
                                    liveMonitoring: {
                                        totalCpuUsagePercentRate:
                                            action.payload[host.address]
                                                .totalCpuUsagePercentRate ||
                                            new LEError(),
                                        systemUsedMemoryB:
                                            action.payload[host.address]
                                                .systemUsedMemoryB ||
                                            new LEError(),
                                        systemTotalMemoryB:
                                            action.payload[host.address]
                                                .systemTotalMemoryB ||
                                            new LEError(),
                                        systemTotalDiskB:
                                            action.payload[host.address]
                                                .systemTotalDiskB ||
                                            new LEError(),
                                        systemUsedDiskB:
                                            action.payload[host.address]
                                                .systemUsedDiskB ||
                                            new LEError(),
                                        netReceivedRateBpS:
                                            action.payload[host.address]
                                                .netReceivedRateBpS ||
                                            new LEError(),
                                        netTransmittedRateBpS:
                                            action.payload[host.address]
                                                .netTransmittedRateBpS ||
                                            new LEError(),
                                        diskReadRateBpS:
                                            action.payload[host.address]
                                                .diskReadRateBpS ||
                                            new LEError(),
                                        diskWriteRateBpS:
                                            action.payload[host.address]
                                                .diskWriteRateBpS ||
                                            new LEError(),
                                    },
                                })),
                            };
                        }
                    ),
                };
            }

            break;
        }

        case "PHYSICAL_MONITORING_ENABLED": {
            state = {
                ...state,
                physicalMonitoringEnabled: action.payload,
            };
        }
    }

    return state;
};
