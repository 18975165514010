import { LOADING_NODE, ERROR_NODE } from "view/components/render-loading-error";

// This function will switch on multiple loading error instances and render:
// * A generic loading state if any of the LE instances is loading
// * A generic error state if any of the LE instances is in error state
// * The output of `renderPayload` if all LE instances are in the success state
export function renderMultipleLoadingError(dataArr, renderPayload) {
    const values = [];

    for (let i = 0; i < dataArr.length; i++) {
        const el = dataArr[i];

        if (el.isError()) {
            return ERROR_NODE;
        } else if (el.isLoading()) {
            return LOADING_NODE;
        } else {
            values.push(el.value);
        }
    }

    return renderPayload(values);
}
