
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}><MDXTag name="h4" components={components}>{`Introduction`}</MDXTag>
<MDXTag name="p" components={components}>{`This guide will show you how to load the TPC-H dataset and then query it to get business-critical metrics.`}</MDXTag>
<MDXTag name="p" components={components}>{`The data loading process will take approximately `}<MDXTag name="strong" components={components} parentName="p">{`5 minutes`}</MDXTag>{` with the default configuration.`}</MDXTag>
<MDXTag name="h4" components={components}>{`What is TPC-H?`}</MDXTag>
<MDXTag name="p" components={components}>{`The TPC Benchmark™H (TPC-H) is a decision support benchmark. It consists of a suite of business oriented ad-hoc queries and concurrent data modifications. The queries and the data populating the database have been chosen to have broad industry-wide relevance. This benchmark illustrates decision support systems that examine large volumes of data, execute queries with a high degree of complexity, and give answers to critical business questions.`}</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  