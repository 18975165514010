import { Maybe } from "util/maybe";
import { TableSort } from "util/sort";

import { State } from "data";
import { MemsqlEvent } from "data/models";

import { GeneralTableColumn } from "view/components/general-table";

import { createSelector } from "reselect";
import _ from "lodash";

import EVENT_COLUMNS from "view/events/columns-info";

// The only things that depend on this selector will automatically
// query for events, so we consider the `initial` state to be a
// loading state as well.
export const selectEventsLoading = (s: State): boolean =>
    s.events.state === "loading" || s.events.state === "initial";

export const selectEventsError = (s: State): Maybe<string> =>
    s.events.state === "error" ? s.events.error : undefined;

export const selectEventsSort = (s: State): TableSort =>
    s.events.state === "success" ? s.events.sort : undefined;

export const selectEventsLastUpdate = (s: State): Maybe<Date> =>
    s.events.state === "success" ? s.events.lastUpdate : undefined;

export const selectEvents = (s: State): Maybe<Array<MemsqlEvent>> =>
    s.events.state === "success" ? s.events.events : undefined;

export const selectSortedEvents = createSelector(
    selectEvents,
    selectEventsSort,
    (events, sort): Maybe<Array<MemsqlEvent>> => {
        if (sort && events) {
            const { direction, columnId } = sort;

            const columnInfo = _.find(
                EVENT_COLUMNS,
                (col: GeneralTableColumn<unknown>) => col.id === columnId
            );

            if (!columnInfo) {
                throw new Error("Expected column to be defined.");
            }

            return _.orderBy<MemsqlEvent>(
                events,
                [columnInfo.getValue],
                direction
            );
        } else {
            return events;
        }
    }
);
