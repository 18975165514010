import * as React from "react";
import _ from "lodash";
import classnames from "classnames";

import "./inline-bar.scss";

export type Entry = {
    value: number; // [0-1] value

    // `fill` sets the entry's background color, while
    // `border` sets the border color.
    fill?: string;
    border?: string;
};

type Props = {
    entries: Array<Entry>;
    size: "extra-small" | "small" | "medium";
    className?: string;
    squaredCorners?: boolean;
};

export default class InlineBar extends React.Component<Props> {
    static defaultProps = {
        size: "medium",
    };

    render() {
        const { entries, size, className, squaredCorners } = this.props;
        const classes = classnames("components-inline-bar", size, className, {
            "squared-corners": squaredCorners,
        });

        return (
            <div className={classes}>
                {_.map(entries, (entry, index: number) => (
                    <div
                        className="entry"
                        key={index}
                        style={{
                            width: `${entry.value * 100}%`,
                            background: entry.fill,
                            border: entry.border
                                ? `1px solid ${entry.border}`
                                : undefined,
                        }}
                    />
                ))}
            </div>
        );
    }
}
