import { RouteInfo } from "router/types";

import * as React from "react";
import _ from "lodash";
import classnames from "classnames";
import BigNumber from "vendor/bignumber.js/bignumber";

import InlineBar from "view/components/inline-bar";
import CircleIcon from "view/components/circle-icon";
import InternalLink from "view/components/internal-link";

import "./horizontal-bar-chart.scss";

export type Entry = {
    name: React.ReactNode;
    value: number | BigNumber;
    formatter: (value: number | BigNumber) => string;
    icon?: string;
    routeInfo?: RouteInfo;
};

type Props = {
    entries: Array<Entry>;
    color: string;
    topLabelClassName?: string;
};

export default class HorizontalBarChart extends React.Component<Props> {
    renderEntry(entry: Entry, maxValue: number | BigNumber) {
        const { color, topLabelClassName } = this.props;

        let icon;
        if (entry.icon) {
            icon = (
                <CircleIcon
                    iconProps={{ icon: entry.icon }}
                    size="small"
                    className="chart-icon"
                />
            );
        }

        // we clamp the minimum width of a bar to 1%
        const value = new BigNumber(entry.value);
        const width = Math.max(value.dividedBy(maxValue).toNumber() * 100, 1);

        const bar = (
            <div
                className="bar-container"
                style={{
                    width: `${width}%`,
                }}
            >
                <InlineBar
                    entries={[
                        {
                            value: 1,
                            fill: color,
                        },
                    ]}
                    size="small"
                />
            </div>
        );

        return (
            <div className="entry">
                <div className="left">{icon}</div>

                <div className="right">
                    <div className={classnames("top-label", topLabelClassName)}>
                        {entry.name}
                    </div>

                    <div className="entry-horizontal">
                        {bar}

                        <div className="inline-label">
                            {entry.formatter(entry.value)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { entries } = this.props;

        if (entries.length === 0) {
            return null;
        }

        // We render a set of InlineBarChart components with a *single* entry
        // with a value of 1. These InlineBarChart components are all rendered
        // with different widths that represent their relative size differences.

        let maxValue = entries[0].value;
        if (typeof maxValue === "number") {
            maxValue = new BigNumber(entries[0].value);
        }

        for (let i = 1; i < entries.length; i++) {
            const entry = entries[i];

            let value = entry.value;
            if (typeof value === "number") {
                value = new BigNumber(entry.value);
            }

            maxValue = BigNumber.max(maxValue, value);
        }

        const entryNodes = _.map(entries, (entry, index: number) => {
            const entryNode = this.renderEntry(entry, maxValue);

            if (entry.routeInfo) {
                return (
                    <InternalLink
                        routeInfo={entry.routeInfo}
                        clusterLink
                        className="entry-link"
                        key={index}
                    >
                        {entryNode}
                    </InternalLink>
                );
            } else {
                return entryNode;
            }
        });

        return (
            <div className="components-horizontal-bar-chart">{entryNodes}</div>
        );
    }
}
