import { Maybe } from "util/maybe";
import { Pipeline } from "data/models";
import { ColumnId } from "view/components/super-table";
import { SortDirection, TableSort } from "util/sort";

import * as React from "react";

import GeneralTable from "view/components/general-table";

import PIPELINES_COLUMNS from "view/pipelines/columns-info";

type Props = {
    className?: string;
    pipelines: Array<Pipeline>;
    sort: TableSort;
    onSort: (columnId: ColumnId, sortDir: Maybe<SortDirection>) => void;
};

export default function({ sort, onSort, pipelines, className }: Props) {
    return (
        <GeneralTable
            className={className}
            onSort={onSort}
            columns={PIPELINES_COLUMNS}
            rows={pipelines}
            sort={sort}
        />
    );
}
