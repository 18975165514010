import { Maybe } from "util/maybe";
import { State } from "data";
import {
    LicenseInfo,
    DerivedNode,
    DerivedPartition,
    DatabaseSummary,
} from "data/models";
import { State as RouteState } from "router5";
import { AlertStatus } from "view/components/alert";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { DashboardCard, MetricsBlock, Block } from "view/common/dashboard-card";
import Icon from "view/components/icon";
import { TopContent } from "view/components/top-content";
import InternalLink from "view/components/internal-link";

import {
    renderLicenseUsageHelp,
    renderLicenseUsage,
} from "view/common/license-usage";
import { getDatabaseStatusLevel } from "view/common/models/schema";
import { getOverallNodeStateLevel } from "view/common/models/topology";
import { getLicenseExpirationText } from "view/common/models/license";

import {
    selectDerivedNodesError,
    selectDerivedNodesLoading,
    deriveNodes,
} from "data/selectors/topology";
import { selectClusterMetadataLoading } from "data/selectors/dashboard";
import { selectRoute } from "data/selectors/routes";
import {
    selectHaEnabled,
    selectClusterStatisticsError,
    derivePartitions,
    selectMemsqlVersion,
} from "data/selectors/cluster-metadata";
import {
    selectLicenseLoading,
    selectLicenseError,
    selectLicenseInfo,
} from "data/selectors/license";
import {
    selectSchemaSummaryLoading,
    selectSchemaSummaryError,
    deriveDatabasesMetadata,
} from "data/selectors/schema";

import { plural } from "util/string";
import { Version } from "util/version";

import "./cluster-health-card.scss";

type StateProps = {
    loading: boolean;
    error: Maybe<string>;

    nodes: Maybe<Array<DerivedNode>>;
    databasesSummary: Maybe<Array<DatabaseSummary>>;
    partitions: Maybe<Array<DerivedPartition>>;
    licenseInfo: Maybe<LicenseInfo>;
    memsqlVersion: Maybe<Version>;

    haEnabled: Maybe<boolean>;

    route: RouteState;
};

type ClusterHealthCardProps = StateProps & {
    className?: string;
};

class ClusterHealthCard extends React.Component<ClusterHealthCardProps> {
    renderNodesHealthBlock = () => {
        const {
            nodes,
            route: {
                params: { clusterId },
            },
        } = this.props;

        if (!nodes) {
            throw new Error("Expected derived nodes to be defined.");
        }

        const numNodes = nodes.length;
        const title = `${numNodes} ${plural("Node", numNodes)}`;

        const nodeStateCounts = _.countBy(nodes, getOverallNodeStateLevel);
        const numWarning = nodeStateCounts["warning"] || 0;
        const numError = nodeStateCounts["error"] || 0;

        const routeInfo = {
            name: "cluster.nodes",
            params: {
                clusterId,
            },
        };

        let status: AlertStatus, icon, link, children;
        if (numError > 0) {
            status = "error";
            icon = <Icon icon="exclamation-triangle" error rightMargin />;
            link = (
                <InternalLink
                    category="dashboard"
                    routeInfo={routeInfo}
                    className="link error"
                >
                    {numError} Critical
                </InternalLink>
            );
        } else if (numWarning > 0) {
            status = "warning";
            icon = <Icon icon="exclamation-circle" rightMargin />;
            link = (
                <InternalLink
                    category="dashboard"
                    routeInfo={routeInfo}
                    className="link warning"
                >
                    {numWarning} {plural("Warning", numWarning)}
                </InternalLink>
            );
        } else {
            status = "success";
            icon = <Icon icon="check-circle" success rightMargin />;
            children = "Healthy";
        }

        return (
            <Block
                title={title}
                label={
                    <>
                        {icon}
                        {link}
                        {children}
                    </>
                }
                status={status}
            />
        );
    };

    renderClusterHealthBlock = () => {
        const {
            databasesSummary,
            route: {
                params: { clusterId },
            },
        } = this.props;

        if (!databasesSummary) {
            throw new Error("Expected databasesSummary to be defined.");
        }

        const numDatabases = databasesSummary.length;
        const title = `${numDatabases} ${plural("Database", numDatabases)}`;

        const databaseStatusCounts = _.countBy(
            databasesSummary,
            getDatabaseStatusLevel
        );
        const numWarning = databaseStatusCounts["warning"] || 0;
        const numError = databaseStatusCounts["error"] || 0;

        const routeInfo = {
            name: "cluster.databases",
            params: {
                clusterId,
            },
        };

        let status: AlertStatus, link, icon, children;
        if (numError > 0) {
            status = "error";
            icon = <Icon icon="exclamation-triangle" error rightMargin />;
            link = (
                <InternalLink
                    category="dashboard"
                    routeInfo={routeInfo}
                    className="link error"
                >
                    {numError} Critical
                </InternalLink>
            );
        } else if (numWarning > 0) {
            status = "warning";
            icon = <Icon icon="exclamation-circle" warning rightMargin />;
            link = (
                <InternalLink
                    category="dashboard"
                    routeInfo={routeInfo}
                    className="link warning"
                >
                    {numWarning} {plural("Warning", numWarning)}
                </InternalLink>
            );
        } else {
            status = "success";
            icon = <Icon icon="check-circle" success rightMargin />;
            children = "Healthy";
        }

        return (
            <Block
                title={title}
                label={
                    <>
                        {icon}
                        {link}
                        {children}
                    </>
                }
                status={status}
            />
        );
    };

    renderHaStatus = (): React.ReactNode => {
        const { haEnabled } = this.props;

        if (haEnabled === undefined) {
            throw new Error("Expected topology haEnabled to be defined.");
        }

        if (haEnabled) {
            return (
                <div className="ha-enabled">
                    <Icon
                        icon="check-circle"
                        iconType="regular"
                        size="sm"
                        rightMargin
                        success
                    />
                    On
                </div>
            );
        } else {
            return (
                <>
                    <Icon rightMargin disabled icon="minus-circle" size="sm" />
                    Off
                </>
            );
        }
    };

    renderLicenseInfo = (): React.ReactNode => {
        const { licenseInfo } = this.props;

        if (licenseInfo) {
            return (
                <TopContent title="License Capacity">
                    {renderLicenseUsage(licenseInfo)}
                    {renderLicenseUsageHelp({
                        licenseInfo,
                        category: "dashboard",
                    })}
                </TopContent>
            );
        }
    };

    renderLicenseExpiration = (): React.ReactNode => {
        const { licenseInfo } = this.props;

        if (licenseInfo) {
            return (
                <TopContent title="License Expiration Date">
                    {getLicenseExpirationText(licenseInfo)}
                </TopContent>
            );
        }
    };

    renderMemsqlVersion = (): React.ReactNode => {
        const { memsqlVersion } = this.props;

        if (memsqlVersion) {
            return (
                <TopContent title="MemSQL Version">
                    {memsqlVersion.toString()}
                </TopContent>
            );
        }
    };

    renderContent = (): React.ReactNode => (
        <div className="cluster-health-card-content">
            <MetricsBlock>
                {this.renderNodesHealthBlock()}
                {this.renderClusterHealthBlock()}
            </MetricsBlock>

            <div className="top-info">
                {this.renderLicenseExpiration()}
                {this.renderLicenseInfo()}

                <TopContent title="High Availability">
                    {this.renderHaStatus()}
                </TopContent>

                {this.renderMemsqlVersion()}
            </div>
        </div>
    );

    render() {
        const { loading, error, className } = this.props;

        let content;
        if (!error && !loading) {
            content = this.renderContent();
        }

        return (
            <DashboardCard
                loading={loading}
                content={content}
                error={error}
                hasEmptyState={false}
                title="Cluster Health"
                docsRouteInfo={{
                    name: "cluster-architecture",
                    category: "dashboard",
                }}
                className={className}
            />
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        loading:
            selectDerivedNodesLoading(s) ||
            selectClusterMetadataLoading(s) ||
            selectLicenseLoading(s) ||
            selectSchemaSummaryLoading(s),

        error:
            selectDerivedNodesError(s) ||
            selectClusterStatisticsError(s) ||
            selectLicenseError(s) ||
            selectSchemaSummaryError(s),

        nodes: deriveNodes(s),
        databasesSummary: deriveDatabasesMetadata(s),
        partitions: derivePartitions(s),
        licenseInfo: selectLicenseInfo(s),
        memsqlVersion: selectMemsqlVersion(s),

        haEnabled: selectHaEnabled(s),

        route: selectRoute(s),
    })
)(ClusterHealthCard);
