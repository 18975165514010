// This function counts the number of elements in T for which pred(element)
// returns true. We use this over _.filter().length to avoid allocating a new
// array.
export function count<T>(arr: Array<T>, pred: (t: T) => boolean): number {
    let result = 0;

    for (let i = 0; i < arr.length; i++) {
        if (pred(arr[i])) {
            result++;
        }
    }

    return result;
}
