import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { State } from "data/reducers";

import { Collapse } from "react-collapse";
import Icon from "view/components/icon";

export type SectionStateProps = {
    fieldValues: SectionValuesList;
};

export type SectionValuesList = Array<{
    name: string;
    label: string;
    value: React.ReactNode;
}>;

export type SectionProps = {
    fields: React.ReactNode;
    title: string;
    description: string;
    active: boolean;
    handleToggle: () => void;
    sectionName: string;
    index: number;
    getFieldValues: (state: State, fieldName: string) => SectionValuesList;
    error?: string;
};

class Section extends React.Component<SectionProps & SectionStateProps> {
    renderFieldValues(values: SectionValuesList) {
        return _.map(values, field => (
            <div key={field.name}>
                {field.label}: {field.value || "Undefined"}
            </div>
        ));
    }

    render() {
        const {
            active,
            fields,
            fieldValues: values,
            handleToggle,
            title,
            description,
            index,
            error,
        } = this.props;

        let sectionError;
        let sectionErrorIcon;
        if (error) {
            sectionErrorIcon = (
                <Icon
                    className="section-error-icon"
                    icon="exclamation-circle"
                />
            );

            if (active) {
                sectionError = <div className="section-error">{error}</div>;
            }
        }

        return (
            <div
                className="collapsable-section"
                tabIndex={0}
                onFocus={handleToggle}
            >
                <div className="info" onClick={handleToggle}>
                    <div className="section-index">{index}</div>

                    <div className="info-text">
                        <div className="title">
                            {title} {sectionErrorIcon}
                        </div>
                        <div className="description">{description}</div>

                        {sectionError}

                        {!active && (
                            <div className="field-values">
                                {this.renderFieldValues(values)}
                            </div>
                        )}
                    </div>
                </div>

                <Collapse isOpened={active}>
                    {active && <div>{fields}</div>}
                </Collapse>
            </div>
        );
    }
}

export default connect(
    (state: State, ownProps: SectionProps): SectionStateProps => {
        return {
            fieldValues: ownProps.getFieldValues(state, ownProps.sectionName),
        };
    }
)(Section);
