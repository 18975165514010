import { Maybe } from "util/maybe";
import { Tree, NodeID } from "util/tree";
import { Vector } from "util/vector";
import { Rectangle } from "util/rectangle";

import _ from "lodash";
import { NewVector, add, negate } from "util/vector";
import { center, centerTop, centerBottom } from "util/rectangle";

export type LayoutBracket = {
    parent: Vector; // end of the upward segment from the bracket
    bracketY: number; // coordinate of the horizontal line
    children: Array<Vector>; // end of each downward segment from the bracket
};

export type LayoutNode<T> = {
    bounds: Rectangle;
    data: T;
};

export type ZoomLevel = "small" | "medium" | "large";

export const ZOOM_LEVELS: Array<ZoomLevel> = ["small", "medium", "large"];

export type Layout<T> = {
    nodes: Array<LayoutNode<T>>;
    brackets: Array<LayoutBracket>;
    bounds: Rectangle;
};

export type OverallLayout<T> = { [key in ZoomLevel]: Layout<T> };

const shiftRectangle = (
    { position, dimensions }: Rectangle,
    displacement: Vector
): Rectangle => ({
    position: add(position, displacement),
    dimensions,
});

// This represents the left or right vertical boundary of a laid-out subtree as
// a sequence of vertical segments where the Y coordinate of the bottom of each
// segment is the Y coordinate of the top of the next. It does so by storing the
// top point of each vertical segment as well as the Y coordinate of the bottom
// of the last one.  For example, the Boundary { vertices: [(1, 3), (2, 7)],
// endY: 10 } represents the two vertical segments (1, 3) to (1, 7) and (2, 7)
// to (2, 10).
type Boundary = {
    vertices: Array<Vector>;
    endY: number;
};

const shiftBoundary = (
    { vertices, endY }: Boundary,
    displacement: Vector
): Boundary => ({
    vertices: vertices.map(v => add(v, displacement)),
    endY: endY + displacement.y,
});

// Given two boundaries, compute the minimum horizontal distance from the first
// rightwards to the second. The result can be negative. If the second boundary
// is shifted left by the result, it should be to the right of the first
// boundary and just touch it along some edge (by having the same X coordinate).
export const minDistance = (b1: Boundary, b2: Boundary): number => {
    let i1 = 0;
    let i2 = 0;
    let minDistance = Infinity;

    while (i1 < b1.vertices.length && i2 < b2.vertices.length) {
        const v1 = b1.vertices[i1];
        const v2 = b2.vertices[i2];
        const endY1 =
            i1 < b1.vertices.length - 1 ? b1.vertices[i1 + 1].y : b1.endY;
        const endY2 =
            i2 < b2.vertices.length - 1 ? b2.vertices[i2 + 1].y : b2.endY;

        if (endY1 > v2.y && endY2 > v1.y) {
            minDistance = Math.min(minDistance, v2.x - v1.x);
        }

        if (endY1 < endY2) {
            i1++;
        } else {
            i2++;
        }
    }

    return minDistance;
};

// Construct the boundary that is the same as the first boundary until its
// bottom Y, at which point it becomes the second boundary if that reaches below
// the first boundary's bottom Y.
export const fallback = (
    b1: Maybe<Boundary>,
    b2: Maybe<Boundary>
): Boundary => {
    if (!b1) {
        if (!b2) {
            throw new Error(
                "Expected at least one of two boundaries in fallback to be defined"
            );
        }
        return b2;
    }
    if (!b2) {
        return b1;
    }

    for (let i = 0; i < b2.vertices.length; i++) {
        if (b2.vertices[i].y > b1.endY) {
            if (i <= 0) {
                throw new Error(
                    "Expected fallback boundaries to overlap in Y coordinates"
                );
            }

            // joinPoint is the point on b2 with the same Y-coordinate as that
            // of the bottom point of b1, and is the top point of the part of b2
            // that we want to include.
            const joinPoint = NewVector(b2.vertices[i - 1].x, b1.endY);

            return {
                vertices: [...b1.vertices, joinPoint, ...b2.vertices.slice(i)],
                endY: b2.endY,
            };
        }
    }

    if (b2.endY > b1.endY) {
        const joinPoint = NewVector(
            b2.vertices[b2.vertices.length - 1].x,
            b1.endY
        );
        return {
            vertices: [...b1.vertices, joinPoint],
            endY: b2.endY,
        };
    }

    return b1;
};

// Prepend a segment exactly to a boundary. Assumes the segment doesn't go past
// the boundary's second vertex. The boundary's first segment is extended or
// truncated to match the bottom Y coordinate of the segment.
const preExtend = (
    start: Vector,
    startEndY: number,
    boundary: Maybe<Boundary>
): Boundary => {
    if (boundary) {
        const [bStart, ...bRest] = boundary.vertices;
        return {
            vertices: [start, NewVector(bStart.x, startEndY), ...bRest],
            endY: boundary.endY,
        };
    } else {
        return {
            vertices: [start],
            endY: startEndY,
        };
    }
};

type Sublayout<T> = {
    nodes: Array<LayoutNode<T>>;
    brackets: Array<LayoutBracket>;
    bounds: Rectangle;
    rootBounds: Rectangle;
    leftBoundary: Boundary;
    rightBoundary: Boundary;

    // The number of leaves that are descendants of this node (including
    // itself).
    leafCount: number;
};

// This is O(n) so calling this repeatedly will make our algorithm O(n^2). It is
// possible to optimize it to be constant with standard range query tricks but
// let's not do so prematurely.
function shiftLayout<T>(
    {
        nodes,
        brackets,
        bounds,
        rootBounds,
        leftBoundary,
        rightBoundary,
        leafCount,
    }: Sublayout<T>,
    displacement: Vector
): Sublayout<T> {
    return {
        nodes: nodes.map(({ bounds, data }) => ({
            bounds: shiftRectangle(bounds, displacement),
            data,
        })),
        brackets: brackets.map(({ parent, bracketY, children }) => ({
            parent: add(parent, displacement),
            bracketY: bracketY + displacement.y,
            children: children.map(child => add(child, displacement)),
        })),
        bounds: shiftRectangle(bounds, displacement),
        rootBounds: shiftRectangle(rootBounds, displacement),
        leftBoundary: shiftBoundary(leftBoundary, displacement),
        rightBoundary: shiftBoundary(rightBoundary, displacement),
        leafCount,
    };
}

export type LayoutSpacing = {
    singleEdgeHeight: number;
    bracketHeight: number;
    bracketYOffset: number;
    horizontalGap: number;
};

export function generateLayout<T>(
    tree: Tree<T>,
    dataToDimensions: (data: T, zoomLevel: ZoomLevel) => Vector,
    getSpacing: (zoomLevel: ZoomLevel) => LayoutSpacing
): OverallLayout<T> {
    // We have an array of the zoom levels and want to make a object keyed by
    // zoom levels with values obtained by applying a lambda to each zoom level.
    // Surprisingly there isn't a direct lodash built-in for this...
    // https://github.com/lodash/lodash/issues/2718
    return _.fromPairs(
        ZOOM_LEVELS.map(zoomLevel => {
            const {
                singleEdgeHeight,
                bracketHeight,
                bracketYOffset,
                horizontalGap,
            } = getSpacing(zoomLevel);

            const layoutSubtree = (id: NodeID): Sublayout<T> => {
                const { children, data } = tree.nodes[id];

                const nodeDimensions = dataToDimensions(data, zoomLevel);
                const nodeHeight = nodeDimensions.y;

                // Y offset at which all children should be laid out.
                const childYOffset =
                    nodeHeight +
                    (children.length > 1 ? bracketHeight : singleEdgeHeight);

                let childLayouts: Array<Sublayout<T>> = [];

                children.forEach(child => {
                    let preLayout = shiftLayout(
                        layoutSubtree(child),
                        NewVector(0, childYOffset)
                    );

                    if (childLayouts.length) {
                        // Roughly Walker's algorithm: For each subtree T in
                        // reverse order, shift the current subtree right until
                        // the two don't overlap and there is a satisfactory
                        // gap between them. If this shift occurs, shift all
                        // subtrees after T right by increasing fractions so
                        // they are spread out. For example, if T is the
                        // third-to-last subtree before this one, shift the
                        // second-to-last subtree by 1/3 of this tree's shift
                        // and the last subtree by 2/3 of this tree's shift.
                        for (let i = childLayouts.length - 1; i >= 0; i--) {
                            const layout = childLayouts[i];
                            const gap = minDistance(
                                layout.rightBoundary,
                                preLayout.leftBoundary
                            );

                            // Heuristic to keep large, complex subtrees further
                            // away from each other than closely related
                            // siblings. We have a gap multiplier that defaults
                            // to 1, and is increased by 1 for each of the left
                            // and right subtrees that has more than one leaf
                            // (i.e., is not just a single chain of nodes all
                            // the way down).
                            let gapMultiplier = 1;
                            if (preLayout.leafCount > 1) {
                                gapMultiplier++;
                            }
                            if (layout.leafCount > 1) {
                                gapMultiplier++;
                            }

                            // We then require that the gap between the two
                            // subtrees be at least the multiplier times the
                            // horizontal gap specified for this zoom level.
                            const targetGap = horizontalGap * gapMultiplier;

                            // Note that this gap is a minimum requirement. It
                            // will often be the case that subtrees between the
                            // two subtrees we're considering already forced
                            // them to be further apart than this minimum gap.
                            // In those cases we don't do anything.
                            if (gap < targetGap) {
                                const expansion = targetGap - gap;
                                preLayout = shiftLayout(
                                    preLayout,
                                    NewVector(expansion, 0)
                                );
                                childLayouts = [
                                    ...childLayouts.slice(0, i),
                                    ...childLayouts
                                        .slice(i)
                                        .map((layout, j) =>
                                            shiftLayout(
                                                layout,
                                                NewVector(
                                                    (expansion * j) /
                                                        (childLayouts.length -
                                                            i),
                                                    0
                                                )
                                            )
                                        ),
                                ];
                            }
                        }
                    }

                    childLayouts.push(preLayout);
                });

                const childNodes: Array<LayoutNode<T>> = _.flatten(
                    childLayouts.map(layout => layout.nodes)
                );
                const childBrackets: Array<LayoutBracket> = _.flatten(
                    childLayouts.map(layout => layout.brackets)
                );
                const childMaxHeight: number = _.max(
                    childLayouts.map(layout => layout.bounds.dimensions.y)
                ) as number;
                const childTops = childLayouts.map(layout =>
                    centerTop(layout.rootBounds)
                );

                // Width of the subtree rooted at this node laid out, including
                // both it and its descendants; it's equal to the maximum of the
                // width of this node and of the width of all its descendants.
                let width = nodeDimensions.x;
                let leafCount = 1;
                if (childLayouts.length) {
                    width = Math.max(width, _.max(
                        childLayouts.map(
                            ({ bounds }) =>
                                bounds.position.x + bounds.dimensions.x
                        )
                    ) as number);

                    leafCount = _.sum(
                        childLayouts.map(layout => layout.leafCount)
                    );
                }

                // this node's upper-left x coordinate
                let nodeX = width / 2 - nodeDimensions.x / 2;
                // this node's bounds: centered at the width calculated above
                const nodeBounds: Rectangle = {
                    position: { x: nodeX, y: 0 },
                    dimensions: nodeDimensions,
                };

                // height of the subtree rooted at this node laid out; if this
                // node is a leaf, it's only the leaf's own height
                let height = nodeDimensions.y;

                if (children.length) {
                    // if this node has children, the height reaches until the
                    // bottom of the tallest subtree
                    height = childYOffset + childMaxHeight;

                    childBrackets.push({
                        parent: centerBottom(nodeBounds),
                        bracketY: nodeHeight + bracketYOffset,
                        children: childTops,
                    });
                }

                // Compute this node's left and right boundaries.
                const childrenLeftBoundary = _.reduce(
                    childLayouts.map(layout => layout.leftBoundary),
                    fallback
                );
                const childrenRightBoundary = _.reduceRight(
                    childLayouts.map(layout => layout.rightBoundary),
                    fallback
                );

                const topLeftCorner = NewVector(nodeX, 0);
                const topRightCorner = NewVector(nodeX + nodeDimensions.x, 0);

                let leftBoundary, rightBoundary;
                if (childrenLeftBoundary) {
                    leftBoundary = preExtend(
                        topLeftCorner,
                        nodeHeight,
                        childrenLeftBoundary
                    );
                } else {
                    leftBoundary = {
                        vertices: [topLeftCorner],

                        // Heuristic: we pretend leaf nodes are taller than they
                        // actually are, so that we keep some space below them
                        // and don't push unrelated nodes directly under them in
                        // the layout.
                        endY: nodeHeight + bracketHeight,
                    };
                }
                if (childrenRightBoundary) {
                    rightBoundary = preExtend(
                        topRightCorner,
                        nodeHeight,
                        childrenRightBoundary
                    );
                } else {
                    rightBoundary = {
                        vertices: [topRightCorner],

                        // Same heuristic as above.
                        endY: nodeHeight + bracketHeight,
                    };
                }

                return {
                    nodes: [
                        { bounds: nodeBounds, data: tree.nodes[id].data },
                        ...childNodes,
                    ],
                    brackets: childBrackets,
                    bounds: {
                        position: { x: 0, y: 0 },
                        dimensions: {
                            x: width,
                            y: height,
                        },
                    },
                    rootBounds: nodeBounds,
                    leftBoundary,
                    rightBoundary,
                    leafCount,
                };
            };

            const sublayout = layoutSubtree(tree.root);

            // Shift so the root node is centered at (0, 0)
            const { rootBounds } = sublayout;
            const { nodes, brackets, bounds } = shiftLayout(
                sublayout,
                negate(center(rootBounds))
            );

            return [zoomLevel, { nodes, brackets, bounds }];
        })
    ) as OverallLayout<T>;
}
