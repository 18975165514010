import * as React from "react";
import classnames from "classnames";

import Card from "view/components/card";

import "./feature-card.scss";

type Props = {
    className?: string;

    feature?: React.ReactNode;
    title: React.ReactNode;
    body?: React.ReactNode;
    actions?: React.ReactNode;

    compact?: boolean;
};

export default class FeatureCard extends React.Component<Props> {
    render() {
        const {
            className,
            feature,
            title,
            body,
            actions,
            compact,
            ...props
        } = this.props;

        const classes = classnames(
            {
                "components-feature-card": true,
                compact,
            },
            className
        );

        return (
            <Card {...props} className={classes}>
                <div className="feature">{feature}</div>
                <div className="title">{title}</div>
                <div className="body">{body}</div>
                <div className="actions">{actions}</div>
            </Card>
        );
    }
}
