import { DropdownDirection, DropdownState } from "view/components/dropdown";
import { ButtonOnClickProps } from "view/common/button";

import * as React from "react";
import classnames from "classnames";

import Dropdown from "view/components/dropdown";
import { Button } from "view/common/button";

type Props = Omit<ButtonOnClickProps, "onClick" | "active"> & {
    direction: DropdownDirection;
    spacing: number;
    children: React.ReactNode;
    className?: string;
};

type State = {
    open: DropdownState;
};

export default class DropdownButton extends React.Component<Props, State> {
    static defaultProps = {
        direction: "s",
        spacing: 5,
    };

    constructor(props: Props) {
        super(props);
        this.state = { open: "CLOSED" };
    }

    handleChange = (open: DropdownState) => {
        this.setState({ open });
    };

    render() {
        const { children, direction, spacing, className, ...rest } = this.props;
        const { open } = this.state;

        return (
            <Dropdown
                onChange={this.handleChange}
                open={open}
                direction={direction}
                spacing={spacing}
                control={
                    <Button
                        {...rest}
                        className={classnames(
                            "components-dropdown-button",
                            className
                        )}
                        active={open === "OPEN"}
                        onClick={() => this.handleChange("OPEN")}
                    />
                }
                children={children}
            />
        );
    }
}
