import { IS_MAC } from "util/os";

export const getRunQueryShortcut = () => {
    if (IS_MAC) {
        return "⌘ + Return";
    } else {
        return "Ctrl + Enter";
    }
};

export const getShortRunQueryShortcut = () => {
    if (IS_MAC) {
        return "⌘↵";
    } else {
        return "CTRL↵";
    }
};
