import React from "react";
import classnames from "classnames";

import CenteringWrapper from "view/components/centering-wrapper";

import { COLORS } from "util/colors";

import "./loading.scss";

type LoadingProps = {
    className?: string;
    size: "small" | "medium" | "large";
    outlineOnly?: boolean;

    primary?: boolean;
    success?: boolean;
    warning?: boolean;
    error?: boolean;

    rightMargin?: boolean;
};

export default class Loading extends React.PureComponent<LoadingProps> {
    render() {
        const {
            size,
            outlineOnly,
            className,
            primary,
            success,
            warning,
            error,
            rightMargin,
        } = this.props;

        const classes = classnames(
            {
                "components-loading": true,
                [size]: true,
                outlineOnly,
                primary,
                success,
                warning,
                error,
                "right-margin": rightMargin,
            },
            className
        );

        // must match up with the css!
        let strokeWidth;
        let circleSize;

        if (size === "small") {
            strokeWidth = 2;
            circleSize = 16;
        } else if (size === "medium") {
            strokeWidth = 2;
            circleSize = 40;
        } else {
            // size="large"
            strokeWidth = 3;
            circleSize = 80;
        }

        return (
            <CenteringWrapper className={classes}>
                <svg
                    className="loading-svg"
                    width={circleSize}
                    height={circleSize}
                    viewBox={`0 0 ${circleSize} ${circleSize}`}
                >
                    <defs>
                        <linearGradient
                            id="paint0_linear"
                            x1="4.769"
                            x2="4.769"
                            y1="-.056"
                            y2="24.008"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FF7BFF" />
                            <stop offset=".072" stopColor="#F46BFF" />
                            <stop offset=".266" stopColor="#D945FF" />
                            <stop offset=".458" stopColor="#C527FF" />
                            <stop offset=".646" stopColor="#B611FF" />
                            <stop offset=".829" stopColor="#AD04FF" />
                            <stop offset="1" stopColor="#A0F" />
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear"
                            x1="27.009"
                            x2="27.009"
                            y1="-.001"
                            y2="24.067"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FF7BFF" />
                            <stop offset=".072" stopColor="#F46BFF" />
                            <stop offset=".266" stopColor="#D945FF" />
                            <stop offset=".458" stopColor="#C527FF" />
                            <stop offset=".646" stopColor="#B611FF" />
                            <stop offset=".829" stopColor="#AD04FF" />
                            <stop offset="1" stopColor="#A0F" />
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear"
                            x1="20.839"
                            x2="20.839"
                            y1="23.896"
                            y2=".001"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FF7BFF" />
                            <stop offset=".072" stopColor="#F46BFF" />
                            <stop offset=".266" stopColor="#D945FF" />
                            <stop offset=".458" stopColor="#C527FF" />
                            <stop offset=".646" stopColor="#B611FF" />
                            <stop offset=".829" stopColor="#AD04FF" />
                            <stop offset="1" stopColor="#A0F" />
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear"
                            x1="11.216"
                            x2="11.216"
                            y1="23.987"
                            y2="-.013"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FF7BFF" />
                            <stop offset=".072" stopColor="#F46BFF" />
                            <stop offset=".266" stopColor="#D945FF" />
                            <stop offset=".458" stopColor="#C527FF" />
                            <stop offset=".646" stopColor="#B611FF" />
                            <stop offset=".829" stopColor="#AD04FF" />
                            <stop offset="1" stopColor="#A0F" />
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear"
                            x1="15.319"
                            x2="15.319"
                            y1="23.995"
                            y2="-.023"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FF7BFF" />
                            <stop offset=".072" stopColor="#F46BFF" />
                            <stop offset=".266" stopColor="#D945FF" />
                            <stop offset=".458" stopColor="#C527FF" />
                            <stop offset=".646" stopColor="#B611FF" />
                            <stop offset=".829" stopColor="#AD04FF" />
                            <stop offset="1" stopColor="#A0F" />
                        </linearGradient>
                        <linearGradient
                            id="linear"
                            x1="0%"
                            y1="0%"
                            x2="100%"
                            y2="100%"
                        >
                            <stop offset="0%" stopColor="#AA01FF" />
                            <stop offset="75%" stopColor="#8327F6" />
                            <stop offset="100%" stopColor="#AA01FF" />
                        </linearGradient>
                    </defs>

                    <g transform="translate(24,28)" className="loading-logo">
                        <path
                            fill="url(#paint0_linear)"
                            d="M9.534 1.836C8.734.796 7.81.138 6.702.007a3.14 3.14 0 0 0-.396.034C2.293.673.1 6.338 0 11.999c.101 5.665 2.293 11.325 6.31 11.962.13.02.261.03.396.033 1.109-.13 2.032-.792 2.832-1.829C7.435 19.843 6.415 15.737 6.348 12c.067-3.735 1.083-7.84 3.186-10.163z"
                        />
                        <path
                            fill="url(#paint1_linear)"
                            d="M27.17.034A3.074 3.074 0 0 0 26.773 0h-6.238a4.727 4.727 0 0 1 2.061.7c.49.299.936.683 1.34 1.134C26.04 4.156 27.06 8.26 27.128 12c-.067 3.739-1.087 7.84-3.19 10.166a6.18 6.18 0 0 1-1.34 1.134 4.838 4.838 0 0 1-2.062.7h6.238c.131-.004.266-.013.396-.034 4.017-.64 6.205-6.305 6.31-11.97C33.374 6.336 31.186.67 27.17.034z"
                        />
                        <path
                            fill="url(#paint2_linear)"
                            d="M18.659 20.518a16.11 16.11 0 0 1-.885 1.653.221.221 0 0 0 .02.26c.675.793 1.438 1.333 2.323 1.514.14.03.278.042.422.046a4.727 4.727 0 0 0 2.06-.7c.49-.299.936-.682 1.341-1.133-1.252-1.623-2.209-4.169-3.106-7.064a.113.113 0 0 0-.215 0c-.574 1.922-1.202 3.802-1.96 5.424z"
                        />
                        <path
                            fill="url(#paint3_linear)"
                            d="M9.533 1.834c1.252 1.622 2.209 4.168 3.107 7.064a.113.113 0 0 0 .214 0c.578-1.918 1.202-3.794 1.965-5.416.286-.612.581-1.164.885-1.653a.222.222 0 0 0-.021-.261C15.008.776 14.245.236 13.36.055a2.389 2.389 0 0 0-.421-.047h-.093C11.868 0 7.577 0 6.776 0h-.075c1.108.13 2.031.792 2.832 1.834z"
                        />
                        <path
                            fill="url(#paint4_linear)"
                            d="M22.6.7a4.855 4.855 0 0 0-2.066-.7 2.804 2.804 0 0 0-.421.046c-3.385.696-5.024 6.647-6.555 11.95-1.192 4.13-2.339 7.983-4.025 10.166-.8 1.041-1.724 1.699-2.832 1.83h6.238a2.8 2.8 0 0 0 .421-.047c3.385-.695 5.025-6.647 6.555-11.95 1.192-4.13 2.339-7.982 4.025-10.166A6.36 6.36 0 0 0 22.6.7z"
                        />
                    </g>

                    <circle
                        className="loading-circle"
                        cx={circleSize / 2}
                        cy={circleSize / 2}
                        r={circleSize / 2 - strokeWidth / 2}
                        stroke={COLORS["color-neutral-200"]}
                    />
                    <circle
                        className="loading-circle loading-circle-stroke"
                        cx={circleSize / 2}
                        cy={circleSize / 2}
                        r={circleSize / 2 - strokeWidth / 2}
                        stroke="url(#linear)"
                    />
                </svg>
            </CenteringWrapper>
        );
    }
}
