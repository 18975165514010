
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}><MDXTag name="h4" components={components}>{`Step 3: Verify Pipeline Success`}</MDXTag>
<MDXTag name="p" components={components}>{`Navigate to Pipelines page within Studio (it’s in the left-hand navigation under Ingest). This is where you can see the status of each pipeline job.`}</MDXTag>
<MDXTag name="p" components={components}>{`The entire loading process takes around `}<MDXTag name="strong" components={components} parentName="p">{`10 minutes`}</MDXTag>{`, but you do not need to wait for Pipelines to finish before querying. You can query the data as soon as you have started the loading process; this is part of the powerful functionality of Pipelines.`}</MDXTag>
<MDXTag name="p" components={components}>{`Next, we will show you how to get business-critical metrics.`}</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  