export const SQL_BOOKS_CREATE_DATABASE = `CREATE DATABASE IF NOT EXISTS books;
USE books;

DROP PIPELINE IF EXISTS library;
DROP TABLE IF EXISTS classic_books;
CREATE TABLE classic_books
(
    title VARCHAR(255),
    author VARCHAR(255),
    date VARCHAR(255)
);`;

export const SQL_BOOKS_CREATE_PIPELINE = `CREATE or REPLACE PIPELINE library
AS LOAD DATA S3 'download.memsql.com/library/'
CONFIG '{"region": "us-east-1"}'
INTO TABLE \`classic_books\`
FIELDS TERMINATED BY ',' ENCLOSED BY '"';`;

export const SQL_BOOKS_SELECT_PIPELINE_FILES = `SELECT * FROM information_schema.PIPELINES_FILES;`;

export const SQL_BOOKS_START_PIPELINE_FORE = `START PIPELINE library FOREGROUND;`;

export const SQL_BOOKS_SELECT_STAR = `SELECT * FROM classic_books;`;

export const SQL_BOOKS_ALTER_PIPELINE = `DELETE FROM classic_books;

ALTER PIPELINE library SET OFFSETS EARLIEST;`;

export const SQL_BOOKS_START_PIPELINE_BACK = `START PIPELINE library;`;
