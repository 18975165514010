import { ExplainSectionName } from "data/reducers/explain";

import * as React from "react";
import _ from "lodash";
import classnames from "classnames";

import Icon from "view/components/icon";
import IconTip from "view/components/icon-tip";

import "./section.scss";

export const isSectionExpanded = (
    expandedSections: Array<ExplainSectionName>,
    sectionName: ExplainSectionName
) => _.includes(expandedSections, sectionName);

type ExplainSectionProps = {
    title: React.ReactNode;
    children: React.ReactNode;
    expanded: boolean;
    onExpandCollapse: (sectionName: ExplainSectionName) => void;
    sectionName: ExplainSectionName;
    className?: string;
    description?: string;
};

export class ExplainSection extends React.Component<ExplainSectionProps> {
    handleExpandCollapse = () => {
        const { onExpandCollapse, sectionName } = this.props;

        onExpandCollapse(sectionName);
    };

    render() {
        const {
            title,
            children,
            expanded,
            className,
            description,
        } = this.props;

        const iconClasses = classnames("icon-expand", { expanded });
        const sectionClasses = classnames("explain-section", className);

        return (
            <div className={sectionClasses}>
                <div
                    className="explain-section-title"
                    onClick={this.handleExpandCollapse}
                >
                    <Icon
                        size="sm"
                        className={iconClasses}
                        icon="chevron-right"
                    />
                    {title}
                    {description && (
                        <IconTip
                            iconProps={{
                                size: "sm",
                                leftMargin: true,
                            }}
                            tipProps={{
                                direction: "nw",
                            }}
                        >
                            {description}
                        </IconTip>
                    )}
                </div>

                {expanded && (
                    <div className="explain-section-content">{children}</div>
                )}
            </div>
        );
    }
}
