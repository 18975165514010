import BigNumber from "vendor/bignumber.js/bignumber";

// Implements the "space savings" metric:
// https://en.wikipedia.org/wiki/Data_compression_ratio
export default function(
    compressedSize: BigNumber,
    uncompressedSize: BigNumber
): number | "invalid" {
    if (uncompressedSize.isZero()) {
        if (compressedSize.isZero()) {
            return "invalid";
        } else {
            // Uncompressed size is 0 but compressed size is not 0.
            // Very weird and unexpected. We return -100%.

            return -1;
        }
    }

    return 1 - compressedSize.dividedBy(uncompressedSize).toNumber();
}
