
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import TutorialLoadSQLButton from "view/tutorial/tutorial-load-sql-button";
import { SQL_LOAD_STOCK_DATA } from "./stock-queries";

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h4" components={components}>{`Step 2: Load Data into Company Table`}</MDXTag>
<MDXTag name="p" components={components}>{`This query will pull the companylist.csv file from S3 storage and create a pipeline to ingest the data into the `}<MDXTag name="strong" components={components} parentName="p">{`company`}</MDXTag>{` table.`}</MDXTag>
<TutorialLoadSQLButton id="stock-load-data" query={SQL_LOAD_STOCK_DATA} text="Paste Load Data query" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  