import * as React from "react";

import "./zoom-overlay.scss";

import { Button } from "view/common/button";
import Icon from "view/components/icon";

type ZoomButtonProps = {
    onClick?: () => void;
    children: React.ReactNode;
    disabled?: boolean;
};

const ZoomButton = ({ onClick, children, disabled }: ZoomButtonProps) => (
    <Button className="zoom-button" onClick={onClick} disabled={disabled}>
        {children}
    </Button>
);

type Props = {
    onZoom: (direction: "IN" | "OUT") => void;
    zoomInDisabled?: boolean;
    zoomOutDisabled?: boolean;
};

export default class ZoomOverlay extends React.Component<Props> {
    render() {
        const { onZoom, zoomInDisabled, zoomOutDisabled } = this.props;

        return (
            <div className="zoom-overlay">
                <ZoomButton
                    onClick={() => onZoom("IN")}
                    disabled={zoomInDisabled}
                >
                    <Icon icon="plus" />
                </ZoomButton>
                <ZoomButton
                    onClick={() => onZoom("OUT")}
                    disabled={zoomOutDisabled}
                >
                    <Icon icon="minus" />
                </ZoomButton>
            </div>
        );
    }
}
