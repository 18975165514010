import { Maybe } from "util/maybe";

import { DispatchFunction, State } from "data";

import * as React from "react";
import { connect } from "react-redux";

import Header from "view/common/header";
import { Button } from "view/common/button";
import LastUpdated from "view/components/last-updated";

import { queryTopology } from "worker/api/topology";
import {
    queryPartitionData,
    queryClusterStatistics,
} from "worker/api/cluster-metadata";
import { queryPipelines } from "worker/api/pipelines";
import { querySchemaSummary } from "worker/api/schema";
import { queryLicense } from "worker/api/license";
import {
    queryRowsThroughput,
    isRowsThroughputEnabled,
} from "worker/api/cluster-metadata";

import {
    isDashboardLoading,
    lastDashboardUpdate,
} from "data/selectors/dashboard";
import { selectIsRowsThroughputEnabled } from "data/selectors/cluster-metadata";
import * as analytics from "util/segment";

import "./header.scss";

type StateProps = {
    isDashboardLoading: boolean;
    lastDashboardUpdate: Maybe<Date>;
    rowsThroughputEnabled: Maybe<boolean>;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

const ROWS_THROUGHPUT_INTERVAL = 1000; // ms

class DashboardHeader extends React.Component<Props> {
    rowsThroughputTimeoutId: Maybe<NodeJS.Timeout>;

    handleReload = () => {
        // queryMemsqlVersion is being dispatched on app load
        this.props.dispatch(queryTopology());
        this.props.dispatch(queryPartitionData());
        this.props.dispatch(queryClusterStatistics());
        this.props.dispatch(queryPipelines());
        this.props.dispatch(querySchemaSummary());
        this.props.dispatch(queryLicense());
    };

    componentDidMount() {
        this.handleReload();

        this.props.dispatch(isRowsThroughputEnabled());
    }

    componentDidUpdate() {
        const { rowsThroughputEnabled } = this.props;
        if (
            rowsThroughputEnabled &&
            this.rowsThroughputTimeoutId === undefined
        ) {
            this.queryRowsThroughput();
        }
    }

    componentWillUnmount() {
        if (this.rowsThroughputTimeoutId !== undefined) {
            window.clearInterval(this.rowsThroughputTimeoutId);
        }
    }

    queryRowsThroughput = () => {
        this.props.dispatch(queryRowsThroughput());
        this.rowsThroughputTimeoutId = setTimeout(
            this.queryRowsThroughput,
            ROWS_THROUGHPUT_INTERVAL
        );
    };

    handleClickReload = () => {
        this.handleReload();

        analytics.trackReload("dashboard");
    };

    render() {
        const { isDashboardLoading, lastDashboardUpdate } = this.props;

        let lastUpdated;
        if (lastDashboardUpdate) {
            lastUpdated = (
                <span className="last-updated">
                    <LastUpdated date={lastDashboardUpdate} />
                </span>
            );
        }

        return (
            <Header
                className="dashboard-header"
                left="Dashboard"
                right={
                    <>
                        {lastUpdated}

                        <Button
                            disabled={isDashboardLoading}
                            small
                            icon="sync-alt"
                            onClick={this.handleClickReload}
                        />
                    </>
                }
            />
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        isDashboardLoading: isDashboardLoading(s),
        lastDashboardUpdate: lastDashboardUpdate(s),
        rowsThroughputEnabled: selectIsRowsThroughputEnabled(s),
    })
)(DashboardHeader);
