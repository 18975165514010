import { SimpleAction } from "data/actions/types";
import { DispatchFunction } from "data";

import { setStudioConfig } from "util/studio-config";

export type ChangeTutorialMinimizedStateAction = SimpleAction<
    "CHANGE_TUTORIAL_MINIMIZED_STATE",
    {
        minimized: boolean;
    }
>;

export const changeTutorialMinimizedState = (payload: {
    minimized: boolean;
}) => {
    return (dispatch: DispatchFunction) => {
        setStudioConfig(oldConfig => ({
            tutorialState: {
                ...oldConfig.tutorialState,
                minimized: payload.minimized,
            },
        }));

        dispatch({
            type: "CHANGE_TUTORIAL_MINIMIZED_STATE",
            error: false,
            payload,
        });
    };
};

export type ChangeTutorialClosedStateAction = SimpleAction<
    "CHANGE_TUTORIAL_CLOSED_STATE",
    { closed: boolean }
>;

export const changeTutorialClosedState = (payload: { closed: boolean }) => {
    return (dispatch: DispatchFunction) => {
        setStudioConfig(oldConfig => ({
            tutorialState: {
                ...oldConfig.tutorialState,
                closed: payload.closed,
            },
        }));

        dispatch({
            type: "CHANGE_TUTORIAL_CLOSED_STATE",
            error: false,
            payload,
        });
    };
};

export type ChangeTutorialLocationAction = SimpleAction<
    "CHANGE_TUTORIAL_LOCATION",
    {
        location: Array<string>;
    }
>;

export const changeTutorialLocation = (payload: {
    location: Array<string>;
}): ChangeTutorialLocationAction => ({
    type: "CHANGE_TUTORIAL_LOCATION",
    error: false,
    payload,
});

export type ChangeTutorialStepAction = SimpleAction<
    "CHANGE_TUTORIAL_STEP",
    {
        location: Array<string>;
        step: number;
    }
>;

export const changeTutorialStep = (payload: {
    location: Array<string>;
    step: number;
}): ChangeTutorialStepAction => ({
    type: "CHANGE_TUTORIAL_STEP",
    error: false,
    payload,
});
