import { Store } from "data/store";
import { Router } from "router5";

import * as React from "react";
import { hot } from "react-hot-loader";

import { RouterProvider } from "react-router5";
import { Provider as ReduxProvider } from "react-redux";

import RouterController from "view/controllers/router";

import "typeface-inconsolata";
import "typeface-lato";

import "./scss/globals.scss";

type AppProps = {
    router: Router;
    store: Store;
};

class App extends React.Component<AppProps> {
    render() {
        const { store, router } = this.props;
        return (
            <ReduxProvider store={store}>
                <RouterProvider router={router}>
                    <RouterController />
                </RouterProvider>
            </ReduxProvider>
        );
    }
}

export default hot(module)(App);
