import { RouteInfo } from "router/types";
import { ReactChildrenArray } from "util/react-children-array";

import * as React from "react";
import classnames from "classnames";

import InternalLink from "view/components/internal-link";
import Icon, { IconRepr } from "view/components/icon";

import "./breadcrumbs.scss";

type BreadcrumbsProps = {
    children: ReactChildrenArray<typeof Breadcrumb>;
};

export class Breadcrumbs extends React.Component<BreadcrumbsProps> {
    render() {
        const { children } = this.props;

        const breadcrumbs = React.Children.map(
            children,
            (child, index: number) =>
                React.cloneElement(child as React.ReactElement<any>, {
                    isFirst: index === 0,
                    isLast: index === React.Children.count(children) - 1,
                })
        );

        return <div className="common-breadcrumbs">{breadcrumbs}</div>;
    }
}

// These properties are optional because they are added by the
// `cloneElement` call in the `Breadcrumbs` component as opposed to
// being passed directly to the `Breadcrumb` component.
type BreadcrumbMetaProps = {
    isLast?: boolean;
    isFirst?: boolean;
};

type BreadcrumbProps = BreadcrumbMetaProps & {
    // the text of the link
    name: string;
    subName?: string;
    iconRepr?: IconRepr;
    // route params
    routeInfo?: RouteInfo;
};

export class Breadcrumb extends React.Component<BreadcrumbProps> {
    render() {
        const {
            name,
            subName,
            iconRepr,
            routeInfo,
            isFirst,
            isLast: last,
        } = this.props;

        const classes = classnames({ last }, "common-breadcrumb");

        const title = (
            <div className="tooltip" title={name}>
                {name}
            </div>
        );

        let subNameNode;
        if (subName) {
            subNameNode = <div className="subname">/ {subName}</div>;
        }

        let icon;
        if (iconRepr) {
            icon = <Icon {...iconRepr} rightMargin size="sm" />;
        }

        let inner;

        if (routeInfo) {
            inner = (
                <InternalLink
                    category="breadcrumb"
                    className="link"
                    routeInfo={routeInfo}
                    underlineOnHover={false}
                >
                    {icon}
                    {title}
                    {subNameNode}
                </InternalLink>
            );
        } else {
            inner = (
                <span className="link">
                    {icon}
                    {name}
                </span>
            );
        }

        return (
            <div className={classes}>
                {!isFirst && (
                    <Icon className="icon" size="xs" icon="chevron-right" />
                )}

                {inner}
            </div>
        );
    }
}
