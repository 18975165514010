import { Maybe } from "util/maybe";
import { SortDirection, TableSort } from "util/sort";
import { ColumnId } from "view/components/super-table";

import { State, DispatchFunction } from "data";
import { Router, State as RouteState } from "router5";
import { Node } from "data/models";

import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRoute } from "react-router5";

import Loading from "view/components/loading";
import GeneralTable from "view/components/general-table";
import GeneralError from "view/common/general-error";

import { sortNodes } from "data/actions/topology";

import {
    selectSortedDerivedNodes,
    selectNodesSort,
    selectTopologyError,
} from "data/selectors/topology";
import { formatNodeAddress } from "data/models";
import { selectIsPhysicalMonitoringEnabled } from "data/selectors";

import { getNodesPageColumns } from "view/topology/columns-info";

import { nextTableSort } from "util/sort";

import "./index.scss";

type StateProps = {
    nodes: Maybe<Array<Node>>;
    sort: TableSort;
    error: Maybe<string>;
    physicalMonitoringEnabled: Maybe<boolean>;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
    router: Router;
    route: RouteState;
};

class TopologyPage extends React.Component<Props> {
    handleSort = (columnId: ColumnId, sortDir: Maybe<SortDirection>) => {
        this.props.dispatch(sortNodes(nextTableSort(columnId, sortDir)));
    };

    handleClickCell = (node: Node) => {
        const {
            router,
            route: {
                params: { clusterId },
            },
        } = this.props;

        router.navigate("cluster.nodes.node", {
            clusterId,
            nodeAddress: formatNodeAddress(node),
        });
    };

    render() {
        const { nodes, sort, error, physicalMonitoringEnabled } = this.props;

        let content;
        if (error) {
            content = <GeneralError error={error} />;
        } else if (!nodes || physicalMonitoringEnabled === undefined) {
            content = <Loading size="large" />;
        } else {
            content = (
                <GeneralTable
                    onSort={this.handleSort}
                    columns={getNodesPageColumns(physicalMonitoringEnabled)}
                    rows={nodes}
                    sort={sort}
                    onClickCell={this.handleClickCell}
                    rowHeight={physicalMonitoringEnabled ? 70 : undefined}
                    verticallyAlignCells
                />
            );
        }

        return <div className="topology-page">{content}</div>;
    }
}

export default compose(
    withRoute,
    connect(
        (s: State): StateProps => ({
            nodes: selectSortedDerivedNodes(s),
            error: selectTopologyError(s),
            sort: selectNodesSort(s),
            physicalMonitoringEnabled: selectIsPhysicalMonitoringEnabled(s),
        })
    )
)(TopologyPage);
