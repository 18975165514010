import { LooseObject } from "util/loose-object";

import * as React from "react";
import classNames from "classnames";

import "./radio-button.scss";

type Props = LooseObject & {
    className?: string;
    label: React.ReactNode;
    value: string;
    name: string;
    checked: boolean;
    disabled?: boolean;
};

export default (props: Props) => {
    const { className, label, checked, value, name, disabled, ...rest } = props;

    const classes = classNames("components-radio-button", className, {
        disabled,
        checked,
    });

    const id = `${name}-${value}`;

    return (
        <div className={classes}>
            <input
                id={id}
                type="radio"
                className="input"
                checked={checked}
                name={name}
                value={value}
                disabled={disabled}
                {...rest}
            />

            <label htmlFor={id} className="label">
                <span className="checkmark" />

                <div className="text">{label}</div>
            </label>
        </div>
    );
};
