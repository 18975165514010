
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import TutorialLoadSQLButton from "view/tutorial/tutorial-load-sql-button";
import { 
    SQL_TPCH_LOAD_DATA,
    SQL_TPCH_START_PIPELINES,
} from "./tpch-queries";

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h4" components={components}>{`Step 2: Load Data with Pipelines`}</MDXTag>
<MDXTag name="p" components={components}>{`This part will show you how to pull the TPC-H data from a public S3 bucket into your MemSQL database using Pipelines. Because of the powerful Pipelines functionality, loading TPC-H SF100 (approximately 100 GBs of row files) will take around `}<MDXTag name="strong" components={components} parentName="p">{`10 minutes`}</MDXTag>{`. Once a pipeline has been created, MemSQL will continuously pull data from the bucket.`}</MDXTag>
<MDXTag name="p" components={components}>{`Create the pipelines by executing create pipeline queries. Make sure all the queries are selected in SQL Editor before clicking Run.`}</MDXTag>
<TutorialLoadSQLButton id="tpch-load-data" query={SQL_TPCH_LOAD_DATA} text="Paste Create Pipeline queries" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
<MDXTag name="p" components={components}>{`Start the pipelines by running the start all pipelines query.`}</MDXTag>
<TutorialLoadSQLButton id="tpch-start-pipelines" query={SQL_TPCH_START_PIPELINES} text="Paste Start Pipelines queries" />
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="strong" components={components} parentName="p">{`Run`}</MDXTag>{` (Ctrl+Enter/Cmd+Enter)`}</MDXTag>
<MDXTag name="p" components={components}>{`Once you see Success messages for all the Pipelines created, MemSQL will begin pulling data from the S3 datasource.`}</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  