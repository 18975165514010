import { Maybe } from "util/maybe";
import { TabTitleProps } from "view/components/tab";

import { State, DispatchFunction } from "data";
import { InjectedRoute } from "react-router5";
import { DerivedTable, Table, DerivedColumn, Index } from "data/models";

import { ColumnsSelection, IndexesSelection } from "data/selectors/schema";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { withRoute } from "react-router5";

import SchemaContent from "view/schema/schema-content";
import SchemaTable from "view/schema/schema-table";

import ExtLink from "view/components/external-link";
import IconTip from "view/components/icon-tip";
import { Tab, Tabs, TabTitle } from "view/components/tab";
import SampleDataTab from "view/schema/sample-data-tab";
import ConsoleQueryButton from "view/common/console-query-button";
import Card from "view/components/card";
import {
    TopContent,
    TopContentRow,
    TopContentHeader,
} from "view/components/top-content";
import { renderLoadingError } from "view/components/render-loading-error";
import { renderMultipleLoadingError } from "view/components/render-multiple-loading-error";

import {
    selectSortedColumns,
    selectSortedIndexes,
    selectCurrentDerivedTable,
} from "data/selectors/schema";

import getShowCreateTableQuery from "memsql/get-show-create-table-query";

import { COLUMN_COLUMNS, INDEX_COLUMNS } from "memsql/schema-column-info";

import { ROW_COUNT_TIP } from "view/schema/const";

import NumberFormatter from "util/number-formatter";
import { plural } from "util/string";
import getRoute from "util/get-route";
import calculateCompressionRatio from "util/compression-ratio";
import { LONG_EM_DASH } from "util/symbols";

import "./page-table.scss";

type TabState = "Columns" | "Indexes" | "Sample Data";

const TAB_NAMES: Array<TabState> = ["Columns", "Indexes", "Sample Data"];

const TABS: Array<React.ReactElement<TabTitleProps>> = [
    <TabTitle title="Columns" />,
    <TabTitle title="Indexes" />,
    <TabTitle
        title={
            <span key="sample">
                Sample Data{" "}
                <IconTip
                    tipProps={{ direction: "e" }}
                    iconProps={{ leftMargin: true }}
                >
                    Sample data is limited to 10 rows.
                </IconTip>{" "}
            </span>
        }
    />,
];

type StateProps = {
    columns: ColumnsSelection;

    indexes: IndexesSelection;

    derivedTable: Maybe<DerivedTable>;
};

type Props = StateProps &
    InjectedRoute & {
        dispatch: DispatchFunction;
        tab: TabState;
    };

function formatTableStorageNoun(table: Table): string {
    if (table.tableStorage === "INMEMORY_ROWSTORE") {
        if (table.isSharded) {
            return "Rowstore table";
        } else {
            return "Rowstore reference table";
        }
    } else if (table.tableStorage === "COLUMNSTORE") {
        if (table.isSharded) {
            return "Columnstore table";
        } else {
            return "Columnstore reference table";
        }
    }

    return "Table";
}

class PageTable extends React.Component<Props> {
    handleClickTab = (idx: number) => {
        const { router } = this.props;
        const {
            params: { clusterId, databaseName, tableName },
        } = getRoute(this.props.route);

        let route;

        if (idx === 0) {
            route = "cluster.databases.columns";
        } else if (idx === 1) {
            route = "cluster.databases.indexes";
        } else if (idx === 2) {
            route = "cluster.databases.sample";
        }

        if (route) {
            router.navigate(route, {
                clusterId,
                databaseName,
                tableName,
            });
        }
    };

    renderTopContent = () => {
        const { derivedTable } = this.props;
        const {
            params: { tableName },
        } = getRoute(this.props.route);

        // should never happen
        if (
            !derivedTable ||
            !derivedTable.table ||
            !derivedTable.table.statistics ||
            !tableName
        ) {
            return null;
        }

        const {
            table,
            table: { databaseName, kind },
            derived: { diskUsage, uncompressedSize },
        } = derivedTable;

        let tableRowCount;
        let tableMemoryUsage;
        if (table.statistics) {
            const { rowCount, memoryUse } = table.statistics;

            tableMemoryUsage = (
                <TopContent title="Memory Usage">
                    {NumberFormatter.formatBytes(memoryUse)}
                </TopContent>
            );

            tableRowCount = (
                <TopContent
                    title={
                        <>
                            Row Count
                            <IconTip
                                tipProps={{ direction: "se" }}
                                className="row-count-tip"
                            >
                                {ROW_COUNT_TIP}
                            </IconTip>
                        </>
                    }
                >
                    {NumberFormatter.compactInteger(rowCount)}{" "}
                    {plural("row", rowCount)}
                </TopContent>
            );
        }

        return (
            <Card className="table-info-card">
                <TopContentHeader>
                    Table Info
                    <ConsoleQueryButton
                        tipProps={{
                            direction: "e",
                            tooltip:
                                "Get the SHOW CREATE TABLE output for this table.",
                        }}
                        buttonProps={{
                            marginLeft: true,
                        }}
                        query={getShowCreateTableQuery({
                            databaseName,
                            tableName,
                            kind,
                        })}
                        category="schema-explorer"
                        action="show-create-table"
                    />
                </TopContentHeader>

                <TopContentRow>
                    {tableMemoryUsage}

                    <TopContent title="Disk Usage">
                        {renderLoadingError(diskUsage, val =>
                            NumberFormatter.formatBytes(val)
                        )}
                    </TopContent>

                    {tableRowCount}

                    <TopContent title="Table Type">
                        {formatTableStorageNoun(table)}
                    </TopContent>

                    <TopContent title="Table Compression">
                        {renderMultipleLoadingError(
                            [diskUsage, uncompressedSize],
                            ([diskUsageVal, uncompressedSizeVal]) => {
                                const compressionRatio = calculateCompressionRatio(
                                    diskUsageVal,
                                    uncompressedSizeVal
                                );

                                if (compressionRatio === "invalid") {
                                    return LONG_EM_DASH;
                                } else {
                                    return NumberFormatter.formatPercent(
                                        compressionRatio
                                    );
                                }
                            }
                        )}
                    </TopContent>
                </TopContentRow>
            </Card>
        );
    };

    render() {
        const { columns, indexes, tab } = this.props;
        const {
            params: { databaseName, tableName },
        } = getRoute(this.props.route);

        const activeTab = TAB_NAMES.indexOf(tab);

        let indexesPane;
        let sampleDataPane = null;

        if (indexes.length === 0) {
            indexesPane = (
                <div className="no-indexes">
                    There are no indexes for this table.{" "}
                    <ExtLink name="create-index" category="schema-explorer">
                        How to create an index?
                    </ExtLink>
                </div>
            );
        } else {
            indexesPane = (
                <SchemaTable
                    entityKind="INDEX"
                    columns={INDEX_COLUMNS}
                    getRowId={(index: Index) => index.indexId}
                    rows={indexes}
                />
            );
        }

        if (databaseName && tableName) {
            sampleDataPane = (
                <SampleDataTab
                    entityKind="TABLE"
                    databaseName={databaseName}
                    tableName={tableName}
                />
            );
        }

        const mainTable = (
            <Tabs
                activeTab={activeTab}
                titles={TABS}
                onChange={this.handleClickTab}
                borderTop
            >
                <Tab>
                    <SchemaTable
                        entityKind="TABLE_COLUMN"
                        columns={COLUMN_COLUMNS}
                        getRowId={({ column }: DerivedColumn) =>
                            column.columnId
                        }
                        rows={columns}
                    />
                </Tab>
                <Tab>{indexesPane}</Tab>
                <Tab>{sampleDataPane}</Tab>
            </Tabs>
        );

        const mainContent = (
            <div className="schema-page-table-main">{mainTable}</div>
        );

        const topContent = this.renderTopContent();

        return (
            <SchemaContent
                className="schema-page-table"
                mainContent={mainContent}
                topContent={topContent}
            />
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        columns: selectSortedColumns(s),

        indexes: selectSortedIndexes(s),

        derivedTable: selectCurrentDerivedTable(s),
    })
)(withRoute(PageTable));
