import { Maybe } from "util/maybe";

import { State, DispatchFunction } from "data";
import { Pipeline } from "data/models";
import { SortDirection, TableSort } from "util/sort";
import { ColumnId } from "view/components/super-table";

import * as React from "react";
import { connect } from "react-redux";

import Header from "view/common/header";
import { Button } from "view/common/button";
import Loading from "view/components/loading";
import LastUpdated from "view/components/last-updated";
import GeneralError from "view/common/general-error";
import ExtLink from "view/components/external-link";
import CenteringWrapper from "view/components/centering-wrapper";
import FeatureCard from "view/components/feature-card";
import CircleIcon from "view/components/circle-icon";
import PipelinesTable from "view/common/pipelines-table";

import { sortPipelines } from "data/actions/pipelines";
import { queryPipelines } from "worker/api/pipelines";

import {
    selectPipelinesLoading,
    selectPipelinesError,
    selectPipelinesSort,
    selectSortedPipelines,
    selectPipelinesLastUpdate,
} from "data/selectors/pipelines";

import { nextTableSort } from "util/sort";

import "./index.scss";

type StateProps = {
    loading: boolean;
    pipelines: Maybe<Array<Pipeline>>;
    sort: TableSort;
    lastUpdate: Maybe<Date>;
    error: Maybe<string>;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
};

class PipelinesPage extends React.Component<Props> {
    handleSort = (columnId: ColumnId, sortDir: Maybe<SortDirection>) => {
        this.props.dispatch(sortPipelines(nextTableSort(columnId, sortDir)));
    };

    handleReload = () => {
        this.props.dispatch(queryPipelines());
    };

    componentDidMount() {
        this.handleReload();
    }

    render() {
        const { pipelines, sort, error, loading, lastUpdate } = this.props;

        let lastUpdated;
        if (lastUpdate) {
            lastUpdated = (
                <span className="last-updated">
                    <LastUpdated date={lastUpdate} />
                </span>
            );
        }

        const actions = (
            <>
                {lastUpdated}

                <Button
                    disabled={loading}
                    small
                    icon="sync-alt"
                    onClick={this.handleReload}
                />
            </>
        );

        let content;
        if (error) {
            content = <GeneralError error={error} />;
        } else if (!pipelines) {
            content = <Loading size="large" />;
        } else if (pipelines.length === 0) {
            content = (
                <CenteringWrapper>
                    <FeatureCard
                        feature={
                            <CircleIcon
                                name="ingest"
                                size="large"
                                coloredBackground
                            />
                        }
                        title="Ingest Data with Pipelines"
                        body={
                            <>
                                <div>
                                    MemSQL Pipelines enable you to natively
                                    ingest real-time data from external sources.
                                </div>
                                <div>
                                    <ExtLink
                                        name="create-pipeline"
                                        category="pipelines"
                                    >
                                        Learn how to create a pipeline.
                                    </ExtLink>
                                </div>
                            </>
                        }
                    />
                </CenteringWrapper>
            );
        } else {
            content = (
                <PipelinesTable
                    pipelines={pipelines}
                    sort={sort}
                    onSort={this.handleSort}
                />
            );
        }

        return (
            <div className="pipelines-page">
                <Header left="Pipelines" right={actions} />

                {content}
            </div>
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        loading: selectPipelinesLoading(s),
        pipelines: selectSortedPipelines(s),
        error: selectPipelinesError(s),
        sort: selectPipelinesSort(s),
        lastUpdate: selectPipelinesLastUpdate(s),
    })
)(PipelinesPage);
