import { Maybe } from "util/maybe";

import * as React from "react";

import "./file-loader.scss";

type Props = {
    onLoadFile: (result: string, filename: string) => void;
};

// This is an invisible file-upload element, hidden with CSS, so that we can
// render custom File Upload Input elements that cause a file-choosing
// dialog to open when clicked.
export default class FileLoader extends React.Component<Props> {
    $fileInput: Maybe<HTMLElement>;

    show = () => {
        if (this.$fileInput) {
            this.$fileInput.click();
        }
    };

    handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { onLoadFile } = this.props;
        const reader = new FileReader();

        let file: File;
        if (evt.target.files !== null) {
            file = evt.target.files[0];
        } else {
            throw new Error(
                "Expected evt.target.files to not be null on file upload."
            );
        }

        reader.onload = () => {
            const { result } = reader;
            if (typeof result !== "string") {
                throw new Error(
                    "Expected FileReader.readAsText to read string result"
                );
            }

            onLoadFile(result, file.name);
        };

        reader.readAsText(file);
    };

    render() {
        return (
            <input
                type="file"
                className="file-loader-input"
                onChange={this.handleChange}
                ref={$fileInput => {
                    this.$fileInput = $fileInput || undefined;
                }}
            />
        );
    }
}
