import { Maybe } from "util/maybe";
import { TabTitleProps } from "view/components/tab";

import { State, DispatchFunction } from "data";
import { InjectedRoute } from "react-router5";
import { View, ViewColumn } from "data/models";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { withRoute } from "react-router5";

import SchemaContent from "view/schema/schema-content";
import SchemaTable from "view/schema/schema-table";

import { FakeClick } from "view/common/fake-click";
import SampleDataTab from "view/schema/sample-data-tab";
import IconTip from "view/components/icon-tip";
import { Tab, Tabs, TabTitle } from "view/components/tab";
import ConsoleQueryButton from "view/common/console-query-button";

import {
    selectSortedViewColumns,
    selectCurrentView,
} from "data/selectors/schema";

import getShowCreateTableQuery from "memsql/get-show-create-table-query";

import { VIEW_COLUMN_COLUMNS } from "memsql/schema-column-info";

import { plural } from "util/string";
import getRoute from "util/get-route";

import "./page-view.scss";

type TabState = "Columns" | "Sample Data";

const TAB_NAMES: Array<TabState> = ["Columns", "Sample Data"];

const TABS: Array<React.ReactElement<TabTitleProps>> = [
    <TabTitle title="Columns" />,
    <TabTitle
        title={
            <span key="sample">
                Sample Data{" "}
                <IconTip
                    tipProps={{ direction: "e" }}
                    iconProps={{ leftMargin: true }}
                >
                    Sample data is limited to 10 rows.
                </IconTip>{" "}
            </span>
        }
    />,
];

type StateProps = {
    columns: Array<ViewColumn>;
    view: Maybe<View>;
};

type Props = StateProps &
    InjectedRoute & {
        dispatch: DispatchFunction;
        tab: TabState;
    };

class PageView extends React.Component<Props> {
    handleClickTab = (idx: number) => {
        const { router } = this.props;
        const {
            params: { clusterId, databaseName, tableName },
        } = getRoute(this.props.route);

        let route;

        if (idx === 0) {
            route = "cluster.databases.columns";
        } else if (idx === 1) {
            route = "cluster.databases.sample";
        }

        if (route) {
            router.navigate(route, {
                clusterId,
                databaseName,
                tableName,
            });
        }
    };

    render() {
        const { columns, view, tab } = this.props;
        const {
            params: { databaseName, tableName },
        } = getRoute(this.props.route);

        const columnCount = columns.length;
        const activeTab = TAB_NAMES.indexOf(tab);

        // Should never NOT happen
        let topContent;
        if (databaseName && tableName && view) {
            topContent = (
                <div className="top-content">
                    <FakeClick name="view-summary">
                        <div className="top-content-summary">
                            View {tableName} contains {columnCount}{" "}
                            {plural("column", columnCount)}.
                            <ConsoleQueryButton
                                tipProps={{
                                    tooltip:
                                        "Get the SHOW CREATE VIEW output for this view.",
                                }}
                                query={getShowCreateTableQuery({
                                    databaseName,
                                    tableName,
                                    kind: view.kind,
                                })}
                                category="schema-explorer"
                                action="show-create-view"
                            />
                        </div>
                    </FakeClick>
                </div>
            );
        }

        let mainTable = (
            <Tabs
                activeTab={activeTab}
                titles={TABS}
                onChange={this.handleClickTab}
            >
                <Tab>
                    <SchemaTable
                        entityKind="VIEW_COLUMN"
                        columns={VIEW_COLUMN_COLUMNS}
                        getRowId={(column: ViewColumn) => column.columnId}
                        rows={columns}
                    />
                </Tab>
                <Tab>
                    <SampleDataTab
                        entityKind="VIEW"
                        databaseName={databaseName}
                        tableName={tableName}
                    />
                </Tab>
            </Tabs>
        );

        const mainContent = (
            <div className="schema-page-view-main">{mainTable}</div>
        );

        return (
            <SchemaContent
                className="schema-page-view"
                mainContent={mainContent}
                topContent={topContent}
            />
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        columns: selectSortedViewColumns(s),

        view: selectCurrentView(s),
    })
)(withRoute(PageView));
