import { TableSort } from "util/sort";
import { SimpleAction, Action } from "./types";
import { Loading } from "util/loading-state-machine";

import { Host, HostLiveMonitoringMetrics } from "data/models";

export type HostsPayload = {
    hosts: Array<Host>;
};

export type HostsAction = Action<"HOSTS", Loading<HostsPayload>, string>;

export const buildHostsAction = (payload: HostsPayload): HostsAction => ({
    type: "HOSTS",
    payload: {
        loading: false,
        data: payload,
    },
    error: false,
});

export type SortHostsAction = SimpleAction<"SORT_HOSTS", TableSort>;

export const sortHosts = (payload: TableSort): SortHostsAction => ({
    type: "SORT_HOSTS",
    error: false,
    payload,
});

export type SortHostNodesAction = SimpleAction<"SORT_HOST_NODES", TableSort>;

export const sortHostNodes = (payload: TableSort): SortHostNodesAction => ({
    type: "SORT_HOST_NODES",
    error: false,
    payload,
});

// The live monitoring action may have partial results which will be fixed by
// the reducer.
export type HostsLiveMonitoringActionPayload = {
    [hostAddress: string]: Partial<HostLiveMonitoringMetrics>;
};

export type HostsLiveMonitoringAction = Action<
    "HOSTS_LIVE_MONITORING",
    HostsLiveMonitoringActionPayload,
    string
>;

export type HostsLiveMonitoringStopAction = SimpleAction<
    "HOSTS_LIVE_MONITORING_STOP",
    {}
>;

export type PhysicalMonitoringEnabledAction = SimpleAction<
    "PHYSICAL_MONITORING_ENABLED",
    boolean
>;
