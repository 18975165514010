import { Maybe } from "util/maybe";
import { LicenseInfo } from "data/models";
import { ExternalLinkInfo } from "view/components/external-link";

import * as React from "react";
import classnames from "classnames";

import IconTip from "view/components/icon-tip";

import { formatLicenseUsage } from "data/models";

import "./license-usage.scss";

type LicenseUsageHelpProps = {
    licenseInfo: LicenseInfo;
    category: string;
};

export function renderLicenseUsageHelp({
    licenseInfo: { unitPricing, maximumCapacity },
    category,
}: LicenseUsageHelpProps) {
    // If the license is unlimited memory or unlimited units, no tooltip is
    // needed.
    if (maximumCapacity === "unlimited") {
        return null;
    }

    let helpText: string;
    if (unitPricing) {
        helpText =
            "This usage refers to the currently allocated units out of the total available units as per the license";
    } else {
        helpText =
            "This usage refers to the currently allocated memory out of the total available memory as per the license";
    }

    let link: Maybe<ExternalLinkInfo>;
    if (unitPricing) {
        link = {
            category,
            name: "license-unit-definition",
        };
    }

    return (
        <IconTip
            iconProps={{
                size: "sm",
                leftMargin: true,
            }}
            tipProps={{
                direction: "e",
            }}
            link={link}
            className="license-usage-help-tip"
        >
            {helpText}
        </IconTip>
    );
}

export function renderLicenseUsage(licenseInfo: LicenseInfo) {
    const licenseUsage = formatLicenseUsage(licenseInfo);

    // Prepend a warning icon with a message when the cluster is over its
    // license capacity.
    let warning: React.ReactNode = null;
    if (licenseInfo.maximumCapacity !== "unlimited") {
        if (licenseInfo.usedCapacity > licenseInfo.maximumCapacity) {
            warning = (
                <IconTip
                    iconProps={{
                        size: "sm",
                        rightMargin: true,
                    }}
                    tipProps={{
                        direction: "e",
                    }}
                >
                    This cluster is over its license capacity
                </IconTip>
            );
        }
    }

    const classes = classnames("license-usage", { warning });

    return (
        <div className={classes}>
            {warning}
            {licenseUsage}
        </div>
    );
}
