import { Maybe } from "util/maybe";
import { ReactChildrenArray } from "util/react-children-array";
import { DispatchFunction } from "data";
import { MvNode, MvNodeId, MvWithStats } from "data/models";
import { SortDirection, TableSort } from "util/sort";
import { Column } from "view/components/super-table";
import { MvColumnKey } from "memsql/mv-column-info";

import * as React from "react";
import classnames from "classnames";
import _ from "lodash";

import SuperTable from "view/components/super-table";
import DropdownButton from "view/components/dropdown-button";
import { Menu, MenuItem } from "view/common/menu";
import Icon from "view/components/icon";

import MVColumnPicker from "view/monitor/mv-column-picker";
import MVTimeBreakdownColumn from "view/monitor/mv-time-breakdown-column";

import { COLUMNS } from "memsql/mv-column-info";
import * as analytics from "util/segment";

import "./mv-table.scss";

export type MvContext = {
    nodes: { [key in MvNodeId]: MvNode };
};

export type MvColumn<T> = Column & {
    // The first argument to formatter is the actual Row to format. The
    // second argument is a boolean that signifies whether the row is
    // expanded or not. For nested rows, this boolean is always false.
    formatter: (ctx: MvContext, r: T, expanded: boolean) => React.ReactNode;
    getValue: (r: T) => string | number;
};

const emptyFormatter = () => null;
const emptyValuer = () => "";

const renderExpandCell = (expanded: boolean) => (
    <Icon
        icon="caret-right"
        className={classnames("expand-icon", { expanded })}
        fixedWidth
        role="button"
    />
);

const expandableCollapsed = renderExpandCell(false);
const expandableExpanded = renderExpandCell(true);

const renderActionCell = (actions: React.ReactNode, last: boolean) =>
    actions && (
        <DropdownButton
            small
            ghost
            icon="ellipsis-v"
            direction={last ? "nw" : "sw"}
            tabIndex={-1}
            children={<Menu small>{actions}</Menu>}
        />
    );

const renderTimeBreakdownCell = (
    row: MvWithStats,
    rowIndex: number,
    totalRows: number
) => (
    <MVTimeBreakdownColumn
        mvWithStats={row}
        rowIndex={rowIndex}
        totalRows={totalRows}
    />
);

type ColumnId = keyof MvWithStats | "action" | "timeBrakdown" | "expand";

type Props<RowIDColumn extends string> = {
    rowIdColumn: RowIDColumn;
    onSort: (columnId: string, direction: Maybe<SortDirection>) => void;
    sort: TableSort;
    renderActions: (rowId: string) => ReactChildrenArray<typeof MenuItem>;

    context: MvContext;
    columns: Array<MvColumn<unknown>>;
    selectedStatsColumns: Array<MvColumnKey>;

    rows: Array<MvWithStats & { [id in RowIDColumn]: string }>;
    nestedRows?: { [key: string]: Array<MvWithStats> };

    // This component receives the dispatch function from its renderer instead
    // of connecting to the Redux store since it is cumbersome to represent
    // redux-connected generic components:
    // https://github.com/piotrwitek/react-redux-typescript-guide/issues/55
    dispatch: DispatchFunction;
};

type MVTableState<RowIDColumn extends string> = {
    expandedRowId?: RowIDColumn;
};

export default class MvTable<
    RowIDColumn extends string
> extends React.Component<Props<RowIDColumn>, MVTableState<RowIDColumn>> {
    constructor(props: Props<RowIDColumn>) {
        super(props);
        this.state = {};
    }

    handleExpand = (id: Maybe<RowIDColumn>, column: Column<ColumnId>) => {
        if (id && column.id !== "action") {
            if (this.state.expandedRowId === id) {
                // The currently expanded row was clicked; collapse it;
                this.setState({ expandedRowId: undefined });

                analytics.track("collapse-row", {
                    category: "management-views",
                });
            } else {
                // Expand the clicked row.
                this.setState({ expandedRowId: id });

                analytics.track("expand-row", { category: "management-views" });
            }
        }
    };

    render() {
        const {
            onSort,
            rowIdColumn,
            sort,
            columns,
            context,
            selectedStatsColumns,
            rows,
            nestedRows,
            renderActions,
        } = this.props;
        const { expandedRowId } = this.state;

        const addSort = (column: MvColumn<unknown>) => {
            if (sort) {
                return {
                    ...column,
                    sort:
                        column.sort || sort.columnId === column.id
                            ? sort.direction
                            : undefined,
                };
            } else {
                return column;
            }
        };

        const makeStatsColumn = (column: MvColumnKey) =>
            addSort({
                id: COLUMNS[column].id,
                title: COLUMNS[column].title,
                subTitle: COLUMNS[column].subTitle,
                description: COLUMNS[column].description,
                disableDescriptionIcon: COLUMNS[column].disableDescriptionIcon,
                formatter: COLUMNS[column].formatter,
                getValue: COLUMNS[column].getValue,
                textAlign: "right",
                defaultMinWidth: 120,
            });

        const allColumns: Array<MvColumn<unknown>> = [
            {
                id: "expand",
                title: "",
                defaultMinWidth: 32,
                defaultMaxWidth: 32,
                sort: "DISABLED",
                formatter: emptyFormatter,
                getValue: emptyValuer,
                nonResizable: true,
            },
            ..._.map(columns, addSort),
            {
                id: "timeBreakdown",
                title: "Time Spent",
                description:
                    "Where the query spent its time, as an average percent of wall execution time across all executions during the recording period.",
                disableDescriptionIcon: true,
                defaultMinWidth: 135,
                defaultMaxWidth: 135,
                sort: "DISABLED",
                formatter: emptyFormatter,
                getValue: emptyValuer,
            },
            ..._.map(selectedStatsColumns, makeStatsColumn),
            {
                id: "action",
                title: <MVColumnPicker />,
                defaultMinWidth: 60,
                defaultMaxWidth: 60,
                sort: "DISABLED",
                formatter: emptyFormatter,
                getValue: emptyValuer,
                revealOnHover: true,
                nonResizable: true,
            },
        ];

        const superRows = _.flatMap(rows, (row, mainIdx) => {
            const rowId: string = row[rowIdColumn];
            const expanded = rowId === expandedRowId;
            const expandable =
                nestedRows && nestedRows[rowId] && nestedRows[rowId].length > 0;
            const last = mainIdx === rows.length - 1;

            const mainRow = {
                id: rowId,
                className: classnames("mv-row", { expandable }),
                onClickCell: this.handleExpand,
                cells: _.map(allColumns, c => {
                    if (c.id === "expand") {
                        if (expandable) {
                            if (expanded) {
                                return expandableExpanded;
                            } else {
                                return expandableCollapsed;
                            }
                        }
                    } else if (c.id === "action") {
                        return renderActionCell(renderActions(rowId), last);
                    } else if (c.id === "timeBreakdown") {
                        return renderTimeBreakdownCell(
                            row,
                            mainIdx,
                            rows.length
                        );
                    } else if (c.formatter) {
                        return c.formatter(context, row, expanded);
                    } else {
                        return _.get(row, c.id);
                    }
                }),
            };

            if (expanded && nestedRows && nestedRows[rowId]) {
                const nested = _.map(nestedRows[rowId], (row, nestedIdx) => ({
                    id: `${mainIdx}_${nestedIdx}`,
                    className: "mv-row nested",
                    cells: _.map(allColumns, c => {
                        if (c.id === "timeBreakdown") {
                            return renderTimeBreakdownCell(
                                row,
                                mainIdx,
                                rows.length
                            );
                        } else if (c.formatter) {
                            // The second argument to formatter is a boolean that signifies
                            // whether the row is expanded or not. For nested rows, this
                            // boolean is always false.
                            return c.formatter(context, row, false);
                        } else {
                            return _.get(row, c.id);
                        }
                    }),
                }));
                return [mainRow, ...nested];
            }
            return [mainRow];
        });

        return (
            <div className="monitor-mv-table">
                <SuperTable
                    onSort={onSort}
                    columns={allColumns}
                    rows={superRows}
                    rowHeight={40}
                    clickable
                />
            </div>
        );
    }
}
