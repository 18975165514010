import { DispatchFunction, State } from "data";
import { TabTitle } from "data/reducers/bottom-panel";

import * as React from "react";
import { connect } from "react-redux";

import {
    changeBottomPanelTab,
    changeBottomPanelHeight,
} from "data/actions/bottom-panel";
import { DEFAULT_HEIGHT } from "data/reducers/bottom-panel";

import Icon from "view/components/icon";

import BottomPanel from "view/layouts/main/bottom-panel";

import TabConsole from "view/bottom-panel/tab-console";
import TabConnection from "view/bottom-panel/tab-connection";
import TabLog from "view/bottom-panel/tab-log";
import TabSwitcher from "view/bottom-panel/tab-switcher";

import "./panel.scss";

type StateProps = {
    bottomPanelHeight: number;
    activeTab: TabTitle;
    everOpened: boolean;
};

type Props = StateProps & {
    dispatch: DispatchFunction;
    maxHeight: number;
};

const tabs: { [title in TabTitle]: React.ReactNode } = {
    Console: <TabConsole />,
    Connection: <TabConnection />,
    Log: <TabLog />,
};

class Panel extends React.Component<Props> {
    togglePanelStatus = () => {
        const { dispatch, bottomPanelHeight } = this.props;

        let height: number;
        if (bottomPanelHeight === 0) {
            height = DEFAULT_HEIGHT;
        } else {
            height = 0;
        }

        dispatch(changeBottomPanelHeight({ height }));
    };

    handleClickTab = (tab: TabTitle) => {
        if (tab === this.props.activeTab) {
            this.togglePanelStatus();
        } else {
            this.props.dispatch(changeBottomPanelTab({ tab }));
        }
    };

    render() {
        const {
            bottomPanelHeight,
            dispatch,
            activeTab,
            everOpened,
            maxHeight,
        } = this.props;

        const open = bottomPanelHeight > 0;

        let children;
        if (open) {
            children = tabs[activeTab];
        }

        return (
            <BottomPanel
                maxHeight={maxHeight}
                height={bottomPanelHeight}
                onHeightChange={height => {
                    dispatch(changeBottomPanelHeight({ height }));
                }}
                topBar={
                    <div className="bottom-panel-top-bar">
                        <Icon icon="console" className="console-icon" />
                        <TabSwitcher
                            activeTabTitle={activeTab}
                            onTabChange={this.handleClickTab}
                            everOpened={everOpened}
                        />
                    </div>
                }
                children={children || null}
            />
        );
    }
}

export default connect(
    (s: State): StateProps => ({
        bottomPanelHeight: s.bottomPanel.height,
        activeTab: s.bottomPanel.activeTab,
        everOpened: s.bottomPanel.everOpened,
    })
)(Panel);
