import { Maybe } from "util/maybe";
import { TableSort } from "util/sort";
import { MvColumnKey } from "memsql/mv-column-info";
import {
    MvFullAction,
    MvFullStartAction,
    MvCancelRecordingAction,
    MvChangeFiltersAction,
    MvSortQueriesAction,
    MvSortNodesAction,
    MvChangeStatsColumns,
} from "data/actions";

import {
    MvNodeId,
    MvNode,
    MvActivityType,
    MvActivityName,
    MvActivityLowLevel,
    MvActivityLowLevelCumulative,
    MvActivityID,
} from "data/models";

import assign from "util/assign";
import _ from "lodash";

type Actions =
    | MvFullAction
    | MvFullStartAction
    | MvCancelRecordingAction
    | MvChangeFiltersAction
    | MvSortQueriesAction
    | MvSortNodesAction
    | MvChangeStatsColumns;

export type MvFilters = {
    activityName?: MvActivityName;
    activityType?: MvActivityType;
    nodeId?: MvNodeId;
};

// If `fixedInterval` is set to `true`, then the recording period is known at
// the start.
export type SnapshotMetaData =
    | {
          activities: { [id in MvActivityID]: MvActivityLowLevelCumulative };
          snapshotTime: Date;
          fixedInterval: false;
      }
    | {
          fixedInterval: true;
          deltaTimeS: number;
          snapshotTime: Date;
      };

export type ManagementViewsState = {
    error?: string;

    // lastUpdate is set whenever we update the activities
    // and nodes arrays after we get a MvFullAction
    lastUpdate: Maybe<Date>;

    // Whether the global variable in MemSQL "READ_ADVANCED_COUNTERS"
    // is ON or OFF. See:
    // https://docs.memsql.com/concepts/v6.0/statistics-column-reference/#advanced-statistics
    advancedCounters: boolean;

    filters: MvFilters;
    queriesSort: TableSort;
    nodesSort: TableSort;

    // These fields are set when we load the data from the server
    // They contain the raw results of querying MemSQL, and are refined into
    // other datasets in selectors when we render.
    activities: Array<MvActivityLowLevel>;
    nodes: { [id in MvNodeId]: MvNode };

    selectedStatsColumns: Array<MvColumnKey>;

    recording: boolean;
    startSnapshot?: SnapshotMetaData;
};

const initialState: ManagementViewsState = {
    lastUpdate: undefined,

    filters: {
        activityName: undefined,
        activityType: undefined,
        nodeId: undefined,
    },
    queriesSort: {
        columnId: "activityName",
        direction: "desc",
    },
    nodesSort: {
        columnId: "endpoint",
        direction: "desc",
    },

    // we assume false by default
    advancedCounters: false,

    activities: [],
    nodes: {},

    // default columns, make sure this is sorted
    selectedStatsColumns: ["cpuUsage", "memoryB", "networkB", "diskB"],

    recording: false,
};

export default (
    state: ManagementViewsState = initialState,
    action: Actions
) => {
    switch (action.type) {
        case "MV_CHANGE_FILTERS": {
            state = assign(state, {
                filters: action.payload,
            });
            break;
        }

        case "MV_SORT_QUERIES": {
            state = assign(state, {
                queriesSort: action.payload.sort,
            });
            break;
        }

        case "MV_SORT_NODES": {
            state = assign(state, {
                nodesSort: action.payload.sort,
            });
            break;
        }

        case "MV_CHANGE_STATS_COLUMNS": {
            switch (action.payload.op) {
                case "ADD": {
                    state = assign(state, {
                        selectedStatsColumns: _.sortBy(
                            _.union(state.selectedStatsColumns, [
                                action.payload.column,
                            ])
                        ),
                    });
                    break;
                }

                case "REMOVE": {
                    state = assign(state, {
                        selectedStatsColumns: _.without(
                            state.selectedStatsColumns,
                            action.payload.column
                        ),
                    });
                    break;
                }
            }

            break;
        }

        case "MV_FULL_START": {
            if (action.error) {
                state = assign(state, {
                    error: action.payload.message,
                    recording: false,
                });
            } else if (action.payload.loading) {
                if (action.payload.meta.fixedInterval) {
                    state = {
                        ...state,
                        startSnapshot: {
                            fixedInterval: true,
                            snapshotTime: new Date(),
                            deltaTimeS: action.payload.meta.deltaTimeS,
                        },
                        recording: true,
                    };
                } else {
                    state = {
                        ...state,
                        recording: true,
                    };
                }
            } else {
                state = assign(state, {
                    startSnapshot: {
                        activities: action.payload.data.activities,
                        snapshotTime: new Date(),
                        fixedInterval: false,
                    },
                });
            }

            break;
        }

        case "MV_CANCEL_RECORDING": {
            state = assign(state, {
                recording: false,
                startSnapshot: undefined,
            });

            break;
        }

        case "MV_FULL": {
            if (action.error) {
                state = assign(state, {
                    error: action.payload.message,
                    recording: false,
                });
            } else if (action.payload.loading) {
                state = assign(state, {
                    recording: false,
                });
            } else {
                state = assign(state, action.payload.data.repr, {
                    lastUpdate: new Date(),

                    startSnapshot: undefined,
                    recording: false,
                });
            }

            break;
        }
    }

    return state;
};
