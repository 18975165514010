import { Maybe } from "util/maybe";

import * as React from "react";
import _ from "lodash";
import classnames from "classnames";

import { ResizeButtons } from "view/components/resize-buttons";

import { DEFAULT_HEIGHT } from "data/reducers/bottom-panel";

import { globalDeselect } from "util/global-deselect";

import "./bottom-panel.scss";

const TOP_SNAP = 0.08; // percentage relative to total document height
const BOTTOM_SNAP = 0.08; // percentage relative to total document height

const MINIMIZED_HEIGHT = 35; // pixels value

type Props = {
    children: React.ReactNode;

    height: number;
    onHeightChange: (height: number) => void;
    topBar: React.ReactNode;

    maxHeight: number;
};

type State = {
    resizing: boolean;
    heightBeforeResize?: number;

    // While the mouse is dragging the bottom panel's top bar, this stores the
    // vertical offset between the mouse and the bar's top edge, which we keep
    // constant.
    offset?: number;

    // Keep track of the user having "resized" the panel
    // for a considerable amount, which means that they are
    // actually trying to resize the panel as opposed to
    // clicking it.
    movedConsiderableAmount?: boolean;
};

const heightToPercentage = (absHeight: number, maxHeight: number) => {
    if (maxHeight <= MINIMIZED_HEIGHT) {
        return 1;
    }
    return _.clamp(
        (absHeight - MINIMIZED_HEIGHT) / (maxHeight - MINIMIZED_HEIGHT),
        0,
        1
    );
};

const percentageToHeight = (
    percentHeight: number,
    maxHeight: number
): number => {
    return (
        Math.max(percentHeight * (maxHeight - MINIMIZED_HEIGHT), 0) +
        MINIMIZED_HEIGHT
    );
};

export default class BottomPanel extends React.Component<Props, State> {
    $topBar: Maybe<HTMLElement>;

    state: State = {
        resizing: false,
    };

    handleMouseDown = (e: React.MouseEvent) => {
        globalDeselect();

        if (!this.$topBar) {
            throw new Error("Expected this.$topBar to be defined.");
        }

        this.setState({
            resizing: true,
            heightBeforeResize: this.props.height,
            offset: e.clientY - this.$topBar.getBoundingClientRect().top,
        });
    };

    handleMouseMove = (e: MouseEvent) => {
        const {
            resizing,
            heightBeforeResize,
            movedConsiderableAmount,
            offset,
        } = this.state;

        const { maxHeight } = this.props;

        if (resizing) {
            globalDeselect();

            if (offset === undefined) {
                throw new Error("Expected offset to be defined.");
            }

            const newHeight = heightToPercentage(
                Math.min(maxHeight, maxHeight - e.clientY + offset),
                maxHeight
            );

            if (heightBeforeResize === undefined) {
                throw new Error("Expected heightBeforeResize to be defined.");
            }

            if (!movedConsiderableAmount) {
                this.setState({
                    movedConsiderableAmount:
                        Math.abs(newHeight - heightBeforeResize) >
                        10 / maxHeight,
                });
            }

            this.props.onHeightChange(newHeight);
        }
    };

    handleMouseUp = () => {
        const { height } = this.props;
        const {
            resizing,
            movedConsiderableAmount,
            heightBeforeResize,
        } = this.state;

        if (resizing) {
            // snap to minimized state if the user drags us close to the bottom
            if (height < BOTTOM_SNAP) {
                this.handleMinimize();
            }

            // snap to full screen if the user drags us close to the total height
            if (height > 1 - TOP_SNAP) {
                this.handleMaximize();
            }

            if (!_.isFinite(heightBeforeResize)) {
                throw new Error("Expected heightBeforeResize to be defined.");
            }

            // If it is a really small drag and the height started at 0, the user is
            // simply toggling the bottom panel's state between OPEN and MINIMIZED.
            if (!movedConsiderableAmount) {
                if (heightBeforeResize === 0) {
                    this.handleRestore();
                } else {
                    this.handleMinimize();
                }
            }

            this.setState({
                resizing: false,
                movedConsiderableAmount: false,
            });
        }
    };

    handleMinimize = () => {
        this.props.onHeightChange(0);
    };

    handleRestore = () => {
        this.props.onHeightChange(DEFAULT_HEIGHT);
    };

    handleMaximize = () => {
        this.props.onHeightChange(1);
    };

    componentDidMount() {
        document.addEventListener("mousemove", this.handleMouseMove);
        document.addEventListener("mouseup", this.handleMouseUp);
    }

    componentWillUnmount() {
        document.removeEventListener("mousemove", this.handleMouseMove);
        document.removeEventListener("mouseup", this.handleMouseUp);
    }

    renderActionButtons = () => {
        return (
            <ResizeButtons
                className="resize-buttons"
                onMinimize={this.handleMinimize}
                onRestore={this.handleRestore}
                onMaximize={this.handleMaximize}
                height={this.props.height}
            />
        );
    };

    render() {
        const { children, topBar, height, maxHeight } = this.props;
        const { resizing } = this.state;

        const classes = classnames({
            "layout-main-bottom-panel": true,
            resizing,
            minimized: height === 0,
        });

        return (
            <div
                className={classes}
                ref={$topBar => {
                    this.$topBar = $topBar || undefined;
                }}
                style={{
                    minHeight: percentageToHeight(height, maxHeight),
                }}
                onMouseDown={this.handleMouseDown}
            >
                <div className="top-bar">
                    <div className="top-bar-content">{topBar}</div>

                    {this.renderActionButtons()}
                </div>
                <div
                    className="panel-content"
                    onMouseDown={e => e.stopPropagation()}
                >
                    {children}
                </div>
            </div>
        );
    }
}
