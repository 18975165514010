import { Maybe } from "util/maybe";
import {
    LoadingError,
    LEError,
    LESuccess,
    LELoading,
} from "util/loading-error";
import { SchemaColumn } from "view/schema/schema-table";

import {
    DerivedTable,
    DerivedDatabase,
    Table,
    DerivedView,
    Column,
    DerivedColumn,
    ViewColumn,
    Index,
    StoredProcedure,
    UserDefinedFunction,
    UserDefinedAggregate,
    TableLike,
    DerivedPartition,
    PartitionInstance,
} from "data/models";

import * as React from "react";
import BigNumber from "vendor/bignumber.js/bignumber";

import SizeCircle from "view/components/size-circle";
import Icon from "view/components/icon";
import InternalLink from "view/components/internal-link";
import ConsoleQueryButton from "view/common/console-query-button";
import {
    LOADING_CELL_NODE,
    ERROR_NODE,
} from "view/components/render-loading-error";
import { GeneralTableColumn } from "view/components/general-table";
import { renderMultipleLoadingError } from "view/components/render-multiple-loading-error";

import NumberFormatter from "util/number-formatter";
import calculateCompressionRatio from "util/compression-ratio";
import getShowCreateTableQuery from "memsql/get-show-create-table-query";
import qualifiedName from "memsql/qualified-name";
import showDefinitionColumn from "util/show-definition-column";

import { ROW_COUNT_TIP } from "view/schema/const";
import {
    getPartitionName,
    getPartitionType,
    formatNodeAddress,
} from "data/models";
import {
    formatPartitionInstanceState,
    formatPartitionStatus,
    formatPartitionInstanceRole,
} from "view/common/models/cluster-metadata";
import { formatDatabaseStatus, getColumnIcon } from "view/common/models/schema";
import { displayPartitionStatus } from "view/common/models/cluster-metadata";
import { isPartitionImpacted } from "data/models/cluster-metadata";

import { COLORS } from "util/colors";
import { LONG_EM_DASH } from "util/symbols";
import { buildRankDictionary } from "util/rank-dictionary";

import "./schema-column-info.scss";

const SizeCircleCell: React.SFC<{
    text: React.ReactNode;
    value: number;
    color?: string;
}> = ({ text, value, color = COLORS["color-indigo-600"] }) => (
    <div className="size-circle-cell">
        <div className="circle-container">
            <SizeCircle value={value} color={color} maxDiameter={20} />
        </div>

        <div className="text-container">{text}</div>
    </div>
);

export const DATABASE_COLUMNS: Array<SchemaColumn<DerivedDatabase>> = [
    {
        id: "databaseName",
        title: "Name",
        formatter: ({ database }: DerivedDatabase) => {
            let icon;
            if (database.databaseName === "information_schema") {
                icon = "information-schema";
            } else {
                icon = "database";
            }

            return (
                <>
                    <Icon icon={icon} size="sm" className="entity-name-icon" />
                    <InternalLink
                        routeInfo={{
                            name: "cluster.databases.tables",
                            params: {
                                databaseName: database.databaseName,
                            },
                        }}
                        category="schema-explorer"
                        clusterLink
                        onClick={
                            /* stop propagation to the cell click event */ e =>
                                e.stopPropagation()
                        }
                    >
                        {database.databaseName}
                    </InternalLink>
                </>
            );
        },
        getValue: ({ database }: DerivedDatabase) => database.databaseName,
    },
    {
        id: "databaseStatus",
        title: "Status",
        formatter: formatDatabaseStatus,
        getValue: (database: DerivedDatabase) => {
            return displayPartitionStatus(
                database.derived.status,
                database.derived.impacted
            );
        },
    },
    {
        id: "tableCount",
        title: "Table Count",
        formatter: ({ database, derived }: DerivedDatabase) => {
            if (database.databaseName === "information_schema") {
                return LONG_EM_DASH;
            }

            return (
                <InternalLink
                    routeInfo={{
                        name: "cluster.databases.tables",
                        params: {
                            databaseName: database.databaseName,
                        },
                    }}
                    category="schema-explorer"
                    clusterLink
                    onClick={
                        /* stop propagation to the cell click event */ e =>
                            e.stopPropagation()
                    }
                >
                    {derived.tableCount}
                </InternalLink>
            );
        },
        getValue: ({ database, derived }: DerivedDatabase) => {
            if (database.databaseName === "information_schema") {
                return Number.NEGATIVE_INFINITY;
            }

            return derived.tableCount;
        },
    },
    {
        id: "partitionCount",
        title: "Partition Count",
        formatter: ({ database }: DerivedDatabase) => {
            if (database.databaseName === "information_schema") {
                return LONG_EM_DASH;
            }

            const partitionCount = database.statistics
                ? database.statistics.partitionCount
                : new BigNumber(0);

            return (
                <InternalLink
                    routeInfo={{
                        name: "cluster.databases.partitions",
                        params: {
                            databaseName: database.databaseName,
                        },
                    }}
                    category="schema-explorer"
                    clusterLink
                    onClick={
                        /* stop propagation to the cell click event */ e =>
                            e.stopPropagation()
                    }
                >
                    {partitionCount.toString()}
                </InternalLink>
            );
        },
        getValue: ({ database }: DerivedDatabase) => {
            if (database.databaseName === "information_schema") {
                return Number.NEGATIVE_INFINITY;
            }

            // PLAT-3189
            return database.statistics
                ? database.statistics.partitionCount.toNumber()
                : Number.NEGATIVE_INFINITY;
        },
    },
    {
        id: "memoryUsage",
        title: "Memory Usage",
        formatter: ({ database, derived }: DerivedDatabase) => {
            if (database.databaseName === "information_schema") {
                return LONG_EM_DASH;
            }

            return (
                <SizeCircleCell
                    text={NumberFormatter.formatBytes(derived.memoryUsage)}
                    value={derived.memoryUsagePercent}
                    color={COLORS["color-purple-600"]}
                />
            );
        },
        getValue: ({ database, derived }: DerivedDatabase) => {
            if (database.databaseName === "information_schema") {
                return Number.NEGATIVE_INFINITY;
            }

            return derived.memoryUsage.toNumber();
        },
    },
    {
        id: "diskUsage",
        title: "Disk Usage",
        formatter: ({ database, derived }: DerivedDatabase) => {
            if (database.databaseName === "information_schema") {
                return LONG_EM_DASH;
            }

            if (
                derived.diskUsage.isSuccess() &&
                derived.diskUsagePercent.isSuccess()
            ) {
                return (
                    <SizeCircleCell
                        text={NumberFormatter.formatBytes(
                            derived.diskUsage.value
                        )}
                        value={derived.diskUsagePercent.value}
                        color={COLORS["color-indigo-600"]}
                    />
                );
            } else if (
                derived.diskUsage.isError() ||
                derived.diskUsagePercent.isError()
            ) {
                return ERROR_NODE;
            } else {
                return LOADING_CELL_NODE;
            }
        },
        getValue: ({ database, derived }: DerivedDatabase) => {
            if (database.databaseName === "information_schema") {
                return Number.NEGATIVE_INFINITY;
            }

            if (
                !derived.diskUsage.isLoading() &&
                !derived.diskUsage.isError()
            ) {
                // PLAT-3189
                return derived.diskUsage.value.toNumber();
            }

            // If loading, return undefined. If error, return null.
            return derived.diskUsage.isLoading() ? undefined : null;
        },
    },
];

const getTableRowCount = (table: Table) => {
    if (table.statistics) {
        return table.statistics.rowCount;
    } else {
        return undefined;
    }
};

const getTableMemoryUse = (table: Table) => {
    if (table.statistics) {
        return table.statistics.memoryUse;
    } else {
        // should never happen
        return new BigNumber(0);
    }
};

export const formatTableStorage = (table: Table) => {
    if (table.tableStorage === "INMEMORY_ROWSTORE") {
        if (table.isSharded) {
            return "Rowstore";
        } else {
            return "Rowstore (reference)";
        }
    } else if (table.tableStorage === "COLUMNSTORE") {
        if (table.isSharded) {
            return "Columnstore";
        } else {
            return "Columnstore (reference)";
        }
    }

    return "Unknown";
};

export const getTableLikeIcon = (tableLike: TableLike) => {
    const table = tableLike as Table;

    if (table.tableStorage === "INMEMORY_ROWSTORE") {
        return table.isSharded ? "row" : "row-reference";
    } else if (table.tableStorage === "COLUMNSTORE") {
        return table.isSharded ? "column" : "column-reference";
    }

    // If the table type is neither rowstore nor columnstore, then it must be a
    // View for which we show the Font Awesome `table` icon.
    return "table";
};

export const TABLE_COLUMNS: Array<SchemaColumn<DerivedTable>> = [
    {
        id: "tableName",
        title: "Name",
        formatter: ({ table }: DerivedTable) => (
            <>
                <Icon
                    icon={getTableLikeIcon(table)}
                    size="sm"
                    className="entity-name-icon"
                />
                <InternalLink
                    routeInfo={{
                        name: "cluster.databases.columns",
                        params: {
                            databaseName: table.databaseName,
                            tableName: table.tableName,
                        },
                    }}
                    category="schema-explorer"
                    clusterLink
                    onClick={
                        /* stop propagation to the cell click event */ e =>
                            e.stopPropagation()
                    }
                >
                    {table.tableName}
                </InternalLink>
            </>
        ),
        getValue: ({ table }: DerivedTable) => table.tableName,
    },
    {
        id: "storage",
        title: "Storage",
        formatter: ({ table }: DerivedTable) => formatTableStorage(table),
        getValue: ({ table }: DerivedTable) => table.tableStorage,
    },
    {
        id: "rowCount",
        title: "Row Count",
        description: ROW_COUNT_TIP,
        formatter: ({ table }: DerivedTable) => {
            const rowCount = getTableRowCount(table);

            if (rowCount === undefined) {
                return "—";
            }

            return NumberFormatter.compactInteger(rowCount);
        },
        getValue: ({ table }: DerivedTable) => {
            const rowCount = getTableRowCount(table);

            // We use `Number.NEGATIVE_INFINITY` here to separate `0`s from `unknown`
            // row counts when users sort the table by Row Count.
            if (rowCount === undefined) {
                return Number.NEGATIVE_INFINITY;
            }

            // PLAT-3189
            return rowCount.toNumber();
        },
    },
    {
        id: "memoryUse",
        title: "Memory Use",
        formatter: ({ table, derived }: DerivedTable) => {
            return (
                <SizeCircleCell
                    text={NumberFormatter.formatBytes(getTableMemoryUse(table))}
                    value={derived.memoryUsagePercent}
                    color={COLORS["color-purple-600"]}
                />
            );
        },
        // PLAT-3189
        getValue: ({ table }: DerivedTable) =>
            getTableMemoryUse(table).toNumber(),
    },
    {
        id: "diskUsage",
        title: "Disk Usage",
        formatter: ({ derived }: DerivedTable) => {
            if (
                derived.diskUsage.isSuccess() &&
                derived.diskUsagePercent.isSuccess()
            ) {
                return (
                    <SizeCircleCell
                        text={NumberFormatter.formatBytes(
                            derived.diskUsage.value
                        )}
                        value={derived.diskUsagePercent.value}
                        color={COLORS["color-indigo-600"]}
                    />
                );
            } else if (
                derived.diskUsage.isError() ||
                derived.diskUsagePercent.isError()
            ) {
                return ERROR_NODE;
            } else {
                return LOADING_CELL_NODE;
            }
        },
        getValue: ({ derived }: DerivedTable) => {
            if (
                !derived.diskUsage.isLoading() &&
                !derived.diskUsage.isError()
            ) {
                // PLAT-3189
                return derived.diskUsage.value.toNumber();
            }

            // If loading, return undefined. If error, return null.
            return derived.diskUsage.isLoading() ? undefined : null;
        },
    },
    {
        id: "tableCompression",
        title: "Table Compression",
        formatter: ({
            derived: { diskUsage, uncompressedSize },
        }: DerivedTable) => {
            return renderMultipleLoadingError(
                [diskUsage, uncompressedSize],
                ([diskUsageVal, uncompressedSizeVal]) => {
                    const compressionRatio = calculateCompressionRatio(
                        diskUsageVal,
                        uncompressedSizeVal
                    );
                    if (compressionRatio === "invalid") {
                        return LONG_EM_DASH;
                    } else {
                        return NumberFormatter.formatPercent(compressionRatio);
                    }
                }
            );
        },
        getValue: ({
            derived: { diskUsage, uncompressedSize },
        }: DerivedTable) => {
            if (diskUsage.isSuccess() && uncompressedSize.isSuccess()) {
                const compressionRate = calculateCompressionRatio(
                    diskUsage.value,
                    uncompressedSize.value
                );

                return compressionRate === "invalid" ? null : compressionRate;
            }

            // If loading, return undefined. If error, return null.
            return diskUsage.isLoading() ? undefined : null;
        },
    },
    showDefinitionColumn({
        formatter: ({
            table: { databaseName, tableName, kind },
        }: DerivedTable) => (
            <ConsoleQueryButton
                tipProps={{
                    direction: "sw",
                    tooltip: "Get the SHOW CREATE TABLE output for this table.",
                }}
                query={getShowCreateTableQuery({
                    databaseName,
                    tableName,
                    kind,
                })}
                category="schema-explorer"
                action="show-create-table"
            />
        ),
    }),
];

export const VIEW_COLUMNS: Array<SchemaColumn<DerivedView>> = [
    {
        id: "viewName",
        title: "Name",
        formatter: ({ view }: DerivedView) => (
            <>
                <Icon
                    icon={getTableLikeIcon(view)}
                    size="sm"
                    className="entity-name-icon"
                />
                <InternalLink
                    routeInfo={{
                        name: "cluster.databases.columns",
                        params: {
                            databaseName: view.databaseName,
                            tableName: view.tableName,
                        },
                    }}
                    category="schema-explorer"
                    clusterLink
                    onClick={
                        /* stop propagation to the cell click event */ e =>
                            e.stopPropagation()
                    }
                >
                    {view.tableName}
                </InternalLink>
            </>
        ),
        getValue: ({ view }: DerivedView) => view.tableName,
    },
    {
        id: "numColumns",
        title: "Number of Columns",
        formatter: ({ derived }: DerivedView) => derived.columnCount,
        getValue: ({ derived }: DerivedView) => derived.columnCount,
    },
    showDefinitionColumn({
        formatter: ({
            view: { databaseName, tableName, kind },
        }: DerivedView) => (
            <ConsoleQueryButton
                tipProps={{
                    direction: "sw",
                    tooltip: "Get the SHOW CREATE VIEW output for this view.",
                }}
                query={getShowCreateTableQuery({
                    databaseName,
                    tableName,
                    kind,
                })}
                category="schema-explorer"
                action="show-create-view"
            />
        ),
    }),
];

// `getColumnCompressionRatio` returns:
// * "invalid" if either the compressed size or the uncompressed size are 0 (empty CS table or RS table)
// * `undefined` if either the compressed size or uncompressed size are in the loading (`undefined`) state
// * "error" if either the compressed size or the uncompressed size are in the error ("error") state
// * a `number` corresponding to the compression ratio, otherwise
export const getColumnCompressionRatio = (
    column: Column
): LoadingError<number> | "invalid" => {
    if (column.statistics) {
        if (
            column.statistics.compressedSize.isLoading() ||
            column.statistics.uncompressedSize.isLoading()
        ) {
            return new LELoading();
        } else if (
            column.statistics.compressedSize.isError() ||
            column.statistics.uncompressedSize.isError()
        ) {
            return new LEError();
        } else {
            const compressionRatio = calculateCompressionRatio(
                column.statistics.compressedSize.value,
                column.statistics.uncompressedSize.value
            );

            if (compressionRatio === "invalid") {
                return "invalid";
            } else {
                return new LESuccess(compressionRatio);
            }
        }
    }

    return new LELoading();
};

const getColumnMemoryUse = (column: Column) =>
    column.statistics && column.statistics.memoryUse;

const getColumnDiskUsage = (column: Column): LoadingError<BigNumber> =>
    column.statistics ? column.statistics.compressedSize : new LELoading();

export const COLUMN_COLUMNS: Array<SchemaColumn<DerivedColumn>> = [
    {
        id: "columnName",
        title: "Name",
        formatter: ({ column }: DerivedColumn) => (
            <>
                <Icon
                    icon={getColumnIcon(column)}
                    iconType="regular"
                    size="sm"
                    className="entity-name-icon"
                />
                {column.columnName}
            </>
        ),
        getValue: ({ column }: DerivedColumn) => column.columnName,
    },
    {
        id: "dataType",
        title: "Data Type",
        formatter: ({ column }: DerivedColumn) => column.subType,
        getValue: ({ column }: DerivedColumn) => column.subType,
    },
    {
        id: "computed",
        title: "Computed",
        formatter: ({ column }: DerivedColumn) =>
            column.computed ? "Yes" : "No",
        getValue: ({ column }: DerivedColumn) => column.computed,
    },
    {
        id: "memoryUsage",
        title: "Memory Usage",
        formatter: ({ column, derived }: DerivedColumn) => {
            const memoryUse = getColumnMemoryUse(column);

            if (memoryUse === undefined) {
                return "—";
            }

            return (
                <SizeCircleCell
                    text={NumberFormatter.formatBytes(memoryUse)}
                    value={derived.memoryUsagePercent}
                    color={COLORS["color-purple-600"]}
                />
            );
        },
        getValue: ({ column }: DerivedColumn) => {
            const memoryUse = getColumnMemoryUse(column);

            if (memoryUse === undefined) {
                return Number.NEGATIVE_INFINITY;
            } else {
                // PLAT-3189
                return memoryUse.toNumber();
            }
        },
    },
    {
        id: "diskUsage",
        title: "Disk Usage",
        formatter: ({ column, derived }: DerivedColumn) => {
            const diskUsage = getColumnDiskUsage(column);

            if (diskUsage.isLoading() || derived.diskUsagePercent.isLoading()) {
                return LOADING_CELL_NODE;
            }

            if (diskUsage.isError() || derived.diskUsagePercent.isError()) {
                return ERROR_NODE;
            }

            return (
                <SizeCircleCell
                    text={NumberFormatter.formatBytes(diskUsage.value)}
                    value={derived.diskUsagePercent.value}
                    color={COLORS["color-indigo-600"]}
                />
            );
        },
        getValue: ({ column }: DerivedColumn) => {
            const diskUsage = getColumnDiskUsage(column);

            if (!diskUsage.isLoading() && !diskUsage.isError()) {
                // PLAT-3189
                return diskUsage.value.toNumber();
            }

            // If loading, return undefined. If error, return null.
            return diskUsage.isLoading() ? undefined : null;
        },
    },
    {
        id: "compressionRatio",
        title: "Compression Ratio",
        formatter: ({ column }: DerivedColumn) => {
            const compressionRatio = getColumnCompressionRatio(column);

            if (compressionRatio === "invalid") {
                return LONG_EM_DASH;
            } else if (compressionRatio.isSuccess()) {
                return NumberFormatter.formatPercent(compressionRatio.value);
            } else if (compressionRatio.isError()) {
                return ERROR_NODE;
            } else {
                return LOADING_CELL_NODE;
            }
        },
        getValue: ({ column }: DerivedColumn) => {
            const ratio = getColumnCompressionRatio(column);

            if (ratio === "invalid") {
                return "invalid";
            } else {
                // If loading, return undefined. If error, return null.
                if (ratio.isSuccess()) {
                    return ratio.value;
                } else if (ratio.isLoading()) {
                    return undefined;
                } else {
                    return null;
                }
            }
        },
    },
    {
        id: "default",
        title: "Default",
        formatter: ({ column }: DerivedColumn) => {
            if (column.defaultValueSQL) {
                return (
                    <div className="monospaced">{column.defaultValueSQL}</div>
                );
            } else {
                return "—";
            }
        },
        getValue: ({ column }: DerivedColumn) => column.defaultValueSQL,
    },
];

export const VIEW_COLUMN_COLUMNS: Array<SchemaColumn<ViewColumn>> = [
    {
        id: "columnName",
        title: "Name",
        formatter: (column: ViewColumn) => (
            <>
                <Icon
                    icon={getColumnIcon(column)}
                    size="sm"
                    className="entity-name-icon"
                />
                {column.columnName}
            </>
        ),
        getValue: (column: ViewColumn) => column.columnName,
    },
    {
        id: "dataType",
        title: "Data Type",
        formatter: (column: ViewColumn) => column.subType,
        getValue: (column: ViewColumn) => column.subType,
    },
];

const humanizeIndexType = ({ indexName, indexType, unique }: Index) => {
    const type = indexType;
    const name = indexName;

    if (type === "CLUSTERED COLUMN") {
        return "Columnstore";
    } else if (type === "BTREE" && name === "PRIMARY") {
        return "Primary Key";
    } else if (type === "HASH" && name === "PRIMARY") {
        return "Primary Key (Hash)";
    } else if (type === "HASH" && unique) {
        return "Unique Key (Hash)";
    } else if (unique) {
        return "Unique Key";
    } else if (type === "BTREE") {
        return "Key";
    } else if (type === "SHARD") {
        return "Shard";
    } else if (type === "HASH") {
        return "Hash";
    } else if (type === "FULLTEXT") {
        return "Fulltext";
    } else if (type === "COLUMNSTORE HASH") {
        return "Columnstore Hash";
    }

    return type;
};

export const INDEX_COLUMNS: Array<SchemaColumn<Index>> = [
    {
        id: "indexName",
        title: "Name",
        formatter: (index: Index) => (
            <>
                <Icon icon="index" size="sm" className="entity-name-icon" />
                {index.indexName}
            </>
        ),
        getValue: (index: Index) => index.indexName,
    },
    {
        id: "memoryUsage",
        title: "Memory Usage",
        formatter: (index: Index) =>
            NumberFormatter.formatBytes(index.memoryUse),
        // PLAT-3189
        getValue: (index: Index) => index.memoryUse.toNumber(),
    },
    {
        id: "diskUse",
        title: "Disk Usage",
        formatter: (index: Index) => {
            if (index.diskUse === undefined) {
                return LONG_EM_DASH;
            } else {
                return NumberFormatter.formatBytes(index.diskUse);
            }
        },
        getValue: (index: Index) => {
            if (index.diskUse === undefined) {
                return undefined;
            } else {
                return index.diskUse.toNumber();
            }
        },
    },
    {
        id: "indexType",
        title: "Type",
        formatter: (index: Index) => humanizeIndexType(index),
        getValue: (index: Index) => humanizeIndexType(index),
    },
    {
        id: "indexColumns",
        title: "Columns",
        formatter: (index: Index) => index.columnNames.join(", "),
        getValue: (index: Index) => index.columnNames.join(", "),
    },
    {
        id: "indexUnique",
        title: "Unique",
        formatter: (index: Index) => (index.unique ? "Yes" : "No"),
        getValue: (index: Index) => index.unique,
    },
];

export const STORED_PROCEDURE_COLUMNS: Array<SchemaColumn<StoredProcedure>> = [
    {
        id: "name",
        title: "Name",
        formatter: (sp: StoredProcedure) => (
            <>
                <Icon
                    icon="stored-procedure"
                    size="sm"
                    className="entity-name-icon"
                />
                {sp.name}
            </>
        ),
        getValue: (sp: StoredProcedure) => sp.name,
    },
    {
        id: "definer",
        title: "Definer",
        formatter: (sp: StoredProcedure) => sp.definer,
        getValue: (sp: StoredProcedure) => sp.definer,
    },
    showDefinitionColumn({
        formatter: ({ databaseName, name }: StoredProcedure) => (
            <ConsoleQueryButton
                tipProps={{
                    direction: "w",
                    tooltip:
                        "Get the SHOW CREATE PROCEDURE output for this procedure.",
                }}
                query={`SHOW CREATE PROCEDURE ${qualifiedName({
                    databaseName,
                    name,
                })} \\G`}
                category="schema-explorer"
                action="show-create-function"
            />
        ),
    }),
];

export const UDF_COLUMNS: Array<SchemaColumn<UserDefinedFunction>> = [
    {
        id: "name",
        title: "Name",
        formatter: (udf: UserDefinedFunction) => (
            <>
                <Icon icon="udf" size="sm" className="entity-name-icon" />
                {udf.name}
            </>
        ),
        getValue: (udf: UserDefinedFunction) => udf.name,
    },
    {
        id: "type",
        title: "Type",
        formatter: (udf: UserDefinedFunction) => {
            switch (udf.type) {
                case "SCALAR_VALUED_FUNCTION":
                    return "Scalar-Valued";

                case "TABLE_VALUED_FUNCTION":
                    return "Table-Valued";

                default:
                    return "Unknown";
            }
        },
        getValue: (udf: UserDefinedFunction) => udf.type,
    },
    {
        id: "definer",
        title: "Definer",
        formatter: (udf: UserDefinedFunction) => udf.definer,
        getValue: (udf: UserDefinedFunction) => udf.definer,
    },
    showDefinitionColumn({
        formatter: ({ databaseName, name }: UserDefinedFunction) => (
            <ConsoleQueryButton
                tipProps={{
                    direction: "sw",
                    tooltip:
                        "Get the SHOW CREATE FUNCTION output for this function.",
                }}
                query={`SHOW CREATE FUNCTION ${qualifiedName({
                    databaseName,
                    name,
                })} \\G`}
                category="schema-explorer"
                action="show-create-function"
            />
        ),
    }),
];

export const UDA_COLUMNS: Array<SchemaColumn<UserDefinedAggregate>> = [
    {
        id: "name",
        title: "Name",
        formatter: (uda: UserDefinedAggregate) => (
            <>
                <Icon icon="aggregate" size="sm" className="entity-name-icon" />
                {uda.name}
            </>
        ),
        getValue: (udf: UserDefinedAggregate) => udf.name,
    },
    showDefinitionColumn({
        formatter: ({ databaseName, name }: UserDefinedAggregate) => (
            <ConsoleQueryButton
                tipProps={{
                    direction: "sw",
                    tooltip:
                        "Get the SHOW CREATE AGGREGATE output for this aggregate.",
                }}
                query={`SHOW CREATE AGGREGATE ${qualifiedName({
                    databaseName,
                    name,
                })} \\G`}
                category="schema-explorer"
                action="show-create-function"
            />
        ),
    }),
];

export const PARTITION_COLUMNS: Array<SchemaColumn<DerivedPartition>> = [
    {
        id: "databaseName",
        title: "Partition",
        formatter: derivedPartition => {
            const partitionName = getPartitionName(derivedPartition);

            return (
                <>
                    <Icon
                        icon="chart-pie"
                        size="sm"
                        className="entity-name-icon"
                    />
                    <InternalLink
                        routeInfo={{
                            name: "cluster.databases.partition",
                            params: {
                                databaseName: derivedPartition.databaseName,
                                partitionName,
                            },
                        }}
                        category="schema-explorer"
                        clusterLink
                        onClick={
                            /* stop propagation to the cell click event */ e =>
                                e.stopPropagation()
                        }
                    >
                        {partitionName}
                    </InternalLink>
                </>
            );
        },
        getValue: derivedPartition => getPartitionName(derivedPartition),
    },
    {
        id: "partitionStatus",
        title: "Health Status",
        formatter: formatPartitionStatus,
        getValue: (partition: DerivedPartition) => {
            return displayPartitionStatus(
                partition.status,
                isPartitionImpacted(partition)
            );
        },
    },
    {
        id: "numPartitionInstances",
        title: "Total Partition Instances",
        formatter: derivedPartition => (
            <InternalLink
                routeInfo={{
                    name: "cluster.databases.partition",
                    params: {
                        databaseName: derivedPartition.databaseName,
                        partitionName: getPartitionName(derivedPartition),
                    },
                }}
                category="schema-explorer"
                clusterLink
                onClick={
                    /* stop propagation to the cell click event */ e =>
                        e.stopPropagation()
                }
            >
                {derivedPartition.partitionInstances.length}
            </InternalLink>
        ),
        getValue: ({ partitionInstances }) => partitionInstances.length,
    },
    {
        id: "partitionType",
        title: "Partition Type",
        formatter: derivedPartition => getPartitionType(derivedPartition),
        getValue: derivedPartition => getPartitionType(derivedPartition),
    },
];

export const PARTITION_INSTANCE_ROLE_COLUMN: GeneralTableColumn<
    PartitionInstance
> = {
    id: "partitionInstanceRole",
    title: "Role",
    formatter: partitionInstance =>
        formatPartitionInstanceRole(partitionInstance.role),
    getValue: partitionInstance => {
        switch (partitionInstance.role) {
            case "master":
                return 3;

            case "slave":
                return 2;

            case "detached master":
                return 1;

            case "detached slave":
                return 0;

            default:
                return -1;
        }
    },
};

// In this object, we rank partition instance states so as to be able to compare
// their states from "best" to "worst"
export const RANK_PARTITION_INSTANCE_STATE = buildRankDictionary<string>([
    "online",
    "replicating",
    "provisioning",
    "transition",
    "pending",
    "recovering",
    "unrecoverable",
    "offline",
]);

const getPartitionInstanceRank = (
    state: PartitionInstance["state"]
): Maybe<number> => RANK_PARTITION_INSTANCE_STATE[state];

export const PARTITION_INSTANCE_STATE_COLUMN: GeneralTableColumn<
    PartitionInstance
> = {
    id: "partitionInstanceState",
    title: "State",
    formatter: partitionInstance =>
        formatPartitionInstanceState(partitionInstance.state),
    getValue: partitionInstance =>
        getPartitionInstanceRank(partitionInstance.state),
};

export const PARTITION_INSTANCE_LOG_COLUMN: GeneralTableColumn<
    PartitionInstance
> = {
    id: "partitionInstancePosition",
    title: "Log Position",
    formatter: partitionInstance => partitionInstance.position || LONG_EM_DASH,
    getValue: partitionInstance => partitionInstance.position,
};

export const PARTITION_INSTANCE_COLUMNS: Array<
    SchemaColumn<PartitionInstance>
> = [
    PARTITION_INSTANCE_STATE_COLUMN,
    PARTITION_INSTANCE_ROLE_COLUMN,
    {
        id: "partitionInstanceNodeAddress",
        title: "Node Address",
        formatter: partitionInstance => (
            <InternalLink
                routeInfo={{
                    name: "cluster.nodes.node",
                    params: {
                        nodeAddress: formatNodeAddress(partitionInstance),
                    },
                }}
                category="schema-explorer"
                clusterLink
                onClick={
                    /* stop propagation to the cell click event */ e =>
                        e.stopPropagation()
                }
            >
                {partitionInstance.host}:{partitionInstance.port.toString()}
            </InternalLink>
        ),
        getValue: partitionInstance =>
            `${partitionInstance.host}:${partitionInstance.port}`,
    },
    PARTITION_INSTANCE_LOG_COLUMN,
];
