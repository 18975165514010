import { State as RouteState } from "router5";

declare var STUDIO_COMMIT_HASH: string;

declare global {
    interface Window {
        ROOT_REDIRECT: string;
        ALLOW_CLUSTER_MUTATIONS: boolean;
        MANAGED_SERVICE: boolean;
    }
}

import * as React from "react";
import { render } from "react-dom";

import { newRouter } from "router";
import routes from "router/routes";
import { newStore } from "data/store";
import * as selectors from "data/selectors";

import App from "./app";

import { Promise } from "es6-promise";
import { isSupportedDevice } from "util/supported-device";

import Sentry from "util/sentry";
import { logError } from "util/logging";

Sentry.setup();

// https://github.com/stefanpenner/es6-promise/issues/330
if (!("Promise" in window)) {
    (window as any).Promise = Promise;
} else if (!("finally" in (window as any).Promise.prototype)) {
    (window as any).Promise.prototype.finally = Promise.prototype.finally;
}

// This exports the STUDIO_COMMIT_HASH as a window global so that
// users can simply query it from the JavaScript console if needs be.
(window as any).STUDIO_COMMIT_HASH = STUDIO_COMMIT_HASH;

// Enable withLogger for spam (but easier router debugging)
const router = newRouter({
    defaultRoute: "connect",
    routes,
    withBrowser: true,
    withLogger: false,
});

const store = newStore(router);

(window as any).router = router;
(window as any).store = store;
(window as any).selectors = selectors;

function renderApp(err?: Error, state?: RouteState) {
    if (state) {
        // If `window.ROOT_REDIRECT` is not empty, we need to redirect there
        // instead of loading Studio at all in the `connect` route.
        if (state.name === "connect" && window.ROOT_REDIRECT) {
            window.location.replace(window.ROOT_REDIRECT);
        } else {
            const reactRoot = document.getElementById("react-root");

            if (reactRoot) {
                render(<App router={router} store={store} />, reactRoot);
            } else {
                console.error("Failed to find #react-root");
            }
        }
    } else {
        logError(
            new Error(
                `Unexpected error while starting the router: ${err ||
                    "Unknown error"}.`
            )
        );
    }
}

if (isSupportedDevice()) {
    router.start(renderApp);
}
