export type Vector = {
    x: number;
    y: number;
};

export const NewVector = (x: number, y: number): Vector => ({ x, y });

export const add = (v1: Vector, v2: Vector): Vector => ({
    x: v1.x + v2.x,
    y: v1.y + v2.y,
});

export const subtract = (v1: Vector, v2: Vector): Vector => ({
    x: v1.x - v2.x,
    y: v1.y - v2.y,
});

export const magnitude = ({ x, y }: Vector): number => Math.sqrt(x * x + y * y);

export const scale = (m: number, { x, y }: Vector): Vector => ({
    x: m * x,
    y: m * y,
});

export const negate = (v: Vector) => scale(-1, v);
