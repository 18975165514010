import * as React from "react";

import * as analytics from "util/segment";

type Props = {
    children: React.ReactNode;
    className?: string;
    name: string;
};

// This wraps an element that, when clicked, doesn't have any functionality in
// Studio, to give it an onClick handler that will dispatch an action to
// Analytics, so we see what elements users might expect to be clickable.

type ClickableElement = React.ReactElement<unknown> & {
    onClick: Function;
};

export class FakeClick extends React.Component<Props> {
    handleClick = () => {
        const { name } = this.props;

        analytics.track("fake-click", { category: name });
    };

    render() {
        const { className, children } = this.props;

        return (
            <span className={className} onClick={this.handleClick}>
                {children}
            </span>
        );
    }
}
